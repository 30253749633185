import React, { useEffect, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import {
  Layout,
  TableHead,
  AddHowYouHere,
  HowYouHereRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, Row, Button, Alert } from 'src/modules';

interface IHowYouHere {
  id: number;
  name: string;
  image: string;
  status: string;
}

const HowYouHere: React.FC = () => {
  const [howyouhere, setHowyouhere] = useState<IHowYouHere[]>([]);
  const [showAddNewHowYouHere, setShowAddNewHowYouHere] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onStatusChange = async (i: number): Promise<void> => {
    const list: IHowYouHere[] = howyouhere;
    list[i].status = list[i].status === 'Enable' ? 'Disable' : 'Enable';

    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.put(
        `${API.HOW_YOU_HERE_CHANGE_STATUS}?id=${list[i].id}`,
      );

      if (status === 200) {
        list[i].status = res.data.status;
        setHowyouhere(list);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteItem = (id: number): void => {
    const list: IHowYouHere[] = howyouhere.filter(
      (el: IHowYouHere) => el.id !== id,
    );
    setHowyouhere(list);
  };

  const getData = async () => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.get(API.HOW_YOU_HERE);

      if (status === 200) {
        setHowyouhere(res.data);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateData = (data: IHowYouHere) =>
    setHowyouhere([data, ...howyouhere]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <Dashboard title="How You Here">
        <Row className="justify-content-end mb-4">
          <Col>
            <Button
              color="secondary"
              type="button"
              outline
              onClick={() => setShowAddNewHowYouHere(true)}
            >
              Add New How You Here
            </Button>
          </Col>
          <Col xs="4">{/* <SearchBar /> */}</Col>
        </Row>

        {showAddNewHowYouHere && (
          <AddHowYouHere
            onClose={() => setShowAddNewHowYouHere(false)}
            updateData={updateData}
          />
        )}

        {error && <Alert color="danger">Error: {error.message}</Alert>}

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="1"></Col>
              <Col>Name</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {loading && <Loading />}

            {howyouhere.map((item: IHowYouHere, i: number) => (
              <HowYouHereRow
                key={`${item.name}_${i}`}
                active={howyouhere[i].status === 'Enable'}
                value={i}
                onStatusChange={() => onStatusChange(i)}
                onDeleteItem={onDeleteItem}
                imgSrc={item.image}
                alt={item.image}
                id={item.id}
                name={item.name}
              />
            ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default HowYouHere;
