import request from './request';
import ResponseError from './ResponseError';

export const getMails = async (): Promise<Mail[]> => {
  try {
    const { data } = await request.get(`/mail-settings/`);

    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateMail = async (updatedMail: Mail): Promise<Mail> => {
  try {
    const response: Mail = await request.post(
      `/mail-settings/edit/?id=${updatedMail.id}`,
      updatedMail,
    );

    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
