import React, { memo, useState } from "react";

import { CustomInput } from "src/modules";
import API from "src/data/API";
import { TIncomeType } from "src/models";
import { isTruthy } from "src/utils";

import request from "src/api/request";

interface TaxIncomeAddToIncomeProps {
  id: number;
  type: TIncomeType;
  status: boolean;
}

const TaxIncomeAddToIncome = ({
  id,
  type,
  status,
}: TaxIncomeAddToIncomeProps) => {
  const [local, setLocal] = useState<boolean>(isTruthy(status));

  const onToggle = async (event) => {
    event.stopPropagation();

    try {
      const next = !local;
      setLocal(next);

      await request.get(
        `${API.PATH}${API.USERS_TAX_INCOME_IN_ADD_INCOME}/?id=${id}&type=${type}`
      );
    } catch (error: any) {
      setLocal((next) => !next);
    }
  };

  return (
    <CustomInput
      type="checkbox"
      id={`addtoincome${id}`}
      name="customSwitch"
      className="text-center"
      checked={local}
      onChange={onToggle}
    />
  );
};

export default memo(TaxIncomeAddToIncome);
