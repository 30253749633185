import React, { useEffect, useState } from 'react';

import request from 'src/api/request';
import { CustomInput, Label, Table } from 'src/modules';
import { formatAmount, validCount } from 'src/utils';
import { TDashboardCharts } from 'src/utils/dashboardDataset';

import RowDetailsRefunds from '../RowDetailsRefunds';
import { Loading } from '../ui';

import { LeftCol } from './styled';

type Props = {
  title: string;
  data?: TDashboardCharts;
  showToggle?: boolean;
};

type Breakdown = {
  agent_fee: number;
  agent_fee_with_vat: number;
  date: string;
  refund_amount: number;
  user_id: number;
  user_payout: number;
  year: number;
};

const DashboardTableSubmitted: React.FC<Props> = ({
  title,
  data,
  showToggle,
}) => {
  const [showUsers, setShowUsers] = useState(!showToggle);
  const [showDetails, setDetails] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const [isLoadingData, setLoadingData] = useState(false);
  const [dataBreakDown, setDataBreakDown] = useState<Breakdown[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoadingData(true);
        const { data } = await request.get(
          `/dashboard/hmrc-paid/breakdown?date=${activeDate}`,
        );

        setDataBreakDown(data.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        setDataBreakDown([]);
      }
    }
    if (activeDate) {
      fetchData();
    }
  }, [activeDate]);

  useEffect(() => {
    setDetails(false);
    setLoadingData(false);
    setDataBreakDown([]);
  }, [data]);

  const getPercent = (value: any, total: any) => {
    const result = (100 * parseInt(value)) / parseInt(total);
    if (!validCount(result)) return 0;
    if (result < 1) {
      return result.toFixed(1);
    } else {
      return result.toFixed(0);
    }
  };

  const getBreakdown = (value: any) => {
    setDetails(true);
    setActiveDate(value);
  };

  return (
    <div className="pr-4" style={{ marginTop: 1 }}>
      <Table className="text-center" striped>
        <thead>
          <tr>
            <LeftCol className="fixed-col-striped">
              {showToggle && (
                <Label
                  for={title}
                  className="d-flex align-items-center mr-2 mb-0"
                >
                  <CustomInput
                    type="checkbox"
                    id={title}
                    checked={showUsers}
                    onChange={() => setShowUsers(!showUsers)}
                  />
                  <span className="mr-2">Show users</span>
                </Label>
              )}
            </LeftCol>
            {data?.stackedBar.days.map((item, index) => (
              <td
                key={`${item}-${index}`}
                style={{ cursor: 'pointer' }}
                onClick={() => getBreakdown(item)}
              >
                {item}
              </td>
            ))}
            <td>{showUsers ? `Users | Years` : `Total`}</td>
          </tr>
        </thead>
        <tbody>
          {showDetails && (
            <tr>
              <td colSpan={data?.stackedBar.days.length}>
                {isLoadingData && <Loading />}
                {!isLoadingData &&
                  dataBreakDown.map((dataBr: any, index: number) => {
                    return (
                      <RowDetailsRefunds
                        key={index}
                        value={dataBr}
                      ></RowDetailsRefunds>
                    );
                  })}
                {!isLoadingData &&
                  !dataBreakDown.length &&
                  'No data for period'}
              </td>
            </tr>
          )}
          {data?.stackedBar.datasets.map((item, index) => (
            <tr key={`${item.label}-${index}`}>
              <LeftCol className="text-left">{item.label}</LeftCol>
              {item &&
                item?.data.map((count, di) => (
                  <td key={`count-${count}-${di}`}>
                    {showUsers &&
                      (count ? (
                        `${count} (${item?.usersYear[di]})`
                      ) : (
                        <span className="opacity-2">{0}</span>
                      ))}

                    {!showUsers &&
                      (item.amounts[di] ? (
                        <>
                          {formatAmount(item.amounts[di])}
                          <br />
                          <span
                            style={{
                              fontSize: '10px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {formatAmount(item.agentFee[di])} Fee <br />
                            {formatAmount(item.userPayout[di])} Payout
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="opacity-2">{0}</span>
                          <br />
                          <span style={{ fontSize: '10px' }}>
                            {formatAmount(item.agentFee[di])} Fee <br />
                            {formatAmount(item.userPayout[di])} Payout
                          </span>
                        </>
                      ))}
                  </td>
                ))}
              <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
                <strong>
                  {showUsers ? (
                    <>
                      <span>
                        {item.total} | {item.totalUserYear}
                      </span>
                      <br />
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        ({getPercent(item.total, data?.stackedBar.total)})% | (
                        {getPercent(
                          item.totalUserYear,
                          data?.stackedBar.totalUserYear,
                        )}
                        )%
                      </span>
                    </>
                  ) : (
                    <>
                      {formatAmount(item.totalAmounts)}{' '}
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        (
                        {getPercent(
                          item.totalAmounts,
                          data?.stackedBar.totalAmounts,
                        )}
                        )%
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: '10px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {formatAmount(item.totalAgentFee)} Fee
                        <span style={{ fontSize: '9px', opacity: '.5' }}>
                          (
                          {getPercent(
                            item.totalAgentFee,
                            data?.stackedBar.totalAgentFee,
                          )}
                          )%
                        </span>
                        <br />
                        {formatAmount(item.totalUserPayout)} Payout{' '}
                        <span style={{ fontSize: '9px', opacity: '.5' }}>
                          (
                          {getPercent(
                            item.totalUserPayout,
                            data?.stackedBar.totalUserPayout,
                          )}
                          )%
                        </span>
                      </span>
                    </>
                  )}
                </strong>
              </td>
            </tr>
          ))}
          <tr className="bg-primary">
            <td></td>
            {data?.stackedBar.periodAmountTotals.map((item, index) => (
              <td key={`${item}-${index}`} className={`dashboard-tr-data`}>
                <strong>
                  {showUsers ? (
                    <>
                      <span>
                        {data?.stackedBar.periodTotals[index]} |{' '}
                        {data?.stackedBar.periodUserYearTotals[index]}
                      </span>
                      <br />
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        (
                        {getPercent(
                          data?.stackedBar.periodTotals[index],
                          data?.stackedBar.total,
                        )}
                        )% | (
                        {getPercent(
                          data?.stackedBar.periodUserYearTotals[index],
                          data?.stackedBar.totalUserYear,
                        )}
                        )%
                      </span>
                    </>
                  ) : (
                    <>
                      {formatAmount(item)}
                      <br />
                      <span
                        style={{
                          fontSize: '10px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {formatAmount(
                          data?.stackedBar.periodAgentFeeTotals[index],
                        )}{' '}
                        Fee
                        <br />
                        {formatAmount(
                          data?.stackedBar.periodUserPayoutTotals[index],
                        )}{' '}
                        Payout
                      </span>
                    </>
                  )}
                </strong>
              </td>
            ))}
            <td className="bg-primary">
              <strong>
                {showUsers ? (
                  `${data?.stackedBar.total} | ${data?.stackedBar.totalUserYear}`
                ) : (
                  <>
                    {formatAmount(data?.stackedBar.totalAmounts)} <br />
                    <span
                      style={{
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {formatAmount(data?.stackedBar.totalAgentFee)} Fee
                      <br />
                      {formatAmount(data?.stackedBar.totalUserPayout)} Payout
                    </span>
                  </>
                )}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default DashboardTableSubmitted;
