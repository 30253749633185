import React from 'react';

import { Router } from '@reach/router';

import { Layout, PrivateRoute } from 'src/components';
import { Routes } from 'src/utils';

import AdminUserEdit from './app/admin-user-edit';
import Admins from './app/admins';
import EditAdmin from './app/admins/[id]';
import AddAdmin from './app/admins/add';
import CustomersPerPage from './app/admins/customers-per-page';
import Teams from './app/admins/teams';
import Categories from './app/categories';
import CategoryAdd from './app/category-add';
import ChangeLog from './app/changelog';
import CostSuggestion from './app/cost-suggestion';
import CustomerInformation from './app/customer-information';
import Customers from './app/customers';
import CustomerCreate from './app/customers/create';
import CustomersContingency from './app/customers-contingency';
import Dashboard from './app/dashboard';
// import Shift from './app/dashboard_tt/shift';
// import Staff from './app/dashboard_tt/staff';
import EditProfile from './app/edit-profile';
import Etoro from './app/etoro';
import Expenses from './app/expenses';
import ExternalExpenseFormsInformation from './app/external-expense-forms-information';
import HowYouHere from './app/how-you-here';
import InboxTemplates from './app/inbox-templates';
import InboxTemplateCreate from './app/inbox-templates/create';
import InboxTemplateEdit from './app/inbox-templates/edit';
import JobCategories from './app/job-categories';
import JobSubCategories from './app/job-sub-categories';
import AssistantChanges from './app/logs/assistant-changes';
import MailSettings from './app/mail-settings';
import Messages from './app/messages';
import MessagesUnderReview from './app/messages-under-review';
import NotificationTemplates from './app/notification-templates';
import NotificationTemplatesEdit from './app/notification-templates/edit';
import Notifications from './app/notifications';
import Purchases from './app/purchases';
import QuestionLibrary from './app/question-library';
import QuickReplies from './app/quick-replies';
import SanctionList from './app/sanction-list';
import Settings from './app/settings';
import StatusTags from './app/status-tags';
import UserAccountDeletes from './app/user-account-deletes';
import UserReferrals from './app/user-referrals';
import Utils from './app/utils';
import UTRinstall from './app/utr-install';
import Login from './login';
import Feedback from './app/feedback';
import NPS from './app/nps-list';

const App: React.FC = () => (
  <Layout>
    <Router>
      <PrivateRoute path={Routes.CUSTOMERS} Component={Customers} />
      <PrivateRoute path={Routes.CUSTOMERS_CREATE} Component={CustomerCreate} />
      <PrivateRoute path={Routes.EXPENSES} Component={Expenses} />
      <PrivateRoute path={Routes.STATUS_TAGS} Component={StatusTags} />
      <PrivateRoute path={Routes.ADMIN_USER_EDIT} Component={AdminUserEdit} />
      <PrivateRoute path={Routes.ADMINS} Component={Admins} />
      <PrivateRoute path={Routes.ADD_ADMIN} Component={AddAdmin} />
      <PrivateRoute path={Routes.ADMINS + '/:id'} Component={EditAdmin} />
      <PrivateRoute path={Routes.TEAMS} Component={Teams} />
      <PrivateRoute path={Routes.CATEGORIES} Component={Categories} />
      <PrivateRoute path={Routes.CATEGORY_ADD} Component={CategoryAdd} />
      <PrivateRoute path={Routes.COST_SUGGESTION} Component={CostSuggestion} />
      <PrivateRoute path={Routes.UTR_INSTALL} Component={UTRinstall} />
      <PrivateRoute
        path={Routes.CUSTOMERS_CONTINGENCY}
        Component={CustomersContingency}
      />

      <PrivateRoute
        path={Routes.CUSTOMER_INFORMATION}
        Component={CustomerInformation}
      />
      <PrivateRoute path={Routes.EDIT_PROFILE} Component={EditProfile} />
      <PrivateRoute
        path={Routes.EXTERNAL_EXPENSE_FORMS_INFORMATION}
        Component={ExternalExpenseFormsInformation}
      />
      <PrivateRoute path={Routes.HOW_YOU_HERE} Component={HowYouHere} />
      <PrivateRoute path={Routes.JOB_CATEGORIES} Component={JobCategories} />
      <PrivateRoute
        path={Routes.JOB_SUB_CATEGORIES}
        Component={JobSubCategories}
      />
      <PrivateRoute path={Routes.MAIL_SETTINGS} Component={MailSettings} />
      <PrivateRoute path={Routes.MESSAGES} Component={Messages} />
      <PrivateRoute
        path={Routes.MESSAGES_UNDER_REVIEW}
        Component={MessagesUnderReview}
      />
      <PrivateRoute path={Routes.NOTIFICATIONS} Component={Notifications} />
      <PrivateRoute
        path={Routes.QUESTION_LIBRARY}
        Component={QuestionLibrary}
      />
      <PrivateRoute path={Routes.SETTINGS} Component={Settings} />
      <PrivateRoute
        path={Routes.USER_ACCOUNT_DELETES}
        Component={UserAccountDeletes}
      />
      <PrivateRoute path={Routes.USER_REFERRALS} Component={UserReferrals} />
      <PrivateRoute
        path={Routes.NOTIFCATION_TEMPLATES}
        Component={NotificationTemplates}
      />
      <PrivateRoute
        path={Routes.NOTIFCATION_TEMPLATES_EDIT}
        Component={NotificationTemplatesEdit}
      />
      <PrivateRoute path={Routes.INBOX_TEMPLATES} Component={InboxTemplates} />
      <PrivateRoute
        path={Routes.INBOX_TEMPLATES_EDIT}
        Component={InboxTemplateEdit}
      />
      <PrivateRoute
        path={Routes.INBOX_TEMPLATES_CREATE}
        Component={InboxTemplateCreate}
      />
      <PrivateRoute path={Routes.DASHBOARD_METRICS} Component={Dashboard} />
      <PrivateRoute path={Routes.UTILS} Component={Utils} />
      <PrivateRoute path={Routes.CHANGELOG} Component={ChangeLog} />
      <PrivateRoute path={Routes.QUICK_REPLIES} Component={QuickReplies} />
      <PrivateRoute path={Routes.SANCTION_LIST} Component={SanctionList} />
      <PrivateRoute path={Routes.ETORO} Component={Etoro} />
      <PrivateRoute
        path={Routes.CUSTOMERS_PER_PAGE}
        Component={CustomersPerPage}
      />
      <PrivateRoute
        path={Routes.ASSISTANT_CHANGES}
        Component={AssistantChanges}
      />
      <PrivateRoute
        path={Routes.CUSTOMERS_CONTINGENCY}
        Component={CustomersContingency}
      />

      <PrivateRoute path={Routes.PURCHASES} Component={Purchases} />
      <PrivateRoute path={Routes.FEEDBACK} Component={Feedback} />
      <PrivateRoute path={Routes.NPS} Component={NPS} />

      <Login path={Routes.LOGIN} />
    </Router>
  </Layout>
);

export default App;
