import React, { useEffect, useState } from 'react';

import { useQueryClient, useQuery, useMutation } from 'react-query';
import { Button } from 'reactstrap';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { PostLinkGuestToUser } from 'src/api/message-center';
import { searchUsers } from 'src/api/users';
import Avatar from 'src/components/Avatar';
import { SearchBar } from 'src/components/common';
import { SubscriptionStatus } from 'src/components/CustomerInformationHeaderPie/styles';
import Icon from 'src/components/Icon';
import { ModalBody, Modal, Toastify, observer } from 'src/modules';
import store from 'src/stores';
import { Routes, SUCCESS, capitalize } from 'src/utils';
import checkChatType from 'src/utils/helpers/checkChatType';

import {
  HeaderContainer,
  LeftSide,
  ProfileMessagesStatus,
  ProfilePicture,
  ProfilePictureStatus,
  ProfileName,
  Pipe,
  ProfileStatusText,
  SocialMediaFilters,
  SocialMediaButton,
  RightSide,
  IconButton,
  IconMenu,
  IconPhone,
  TitleModalContainer,
} from './styles';

const SUBSCRIPTION_COLOR_BG = {
  trial: '#930303',
  premium: '#209782',
  essential: '#FAD02C',
};

interface Props {
  isOpenMenu: boolean;
  handleOpenMenu(isOpen: boolean): void;
}

function Header({ isOpenMenu, handleOpenMenu }: Props) {
  const queryClient = useQueryClient();

  const storeMessageCenter = store.messageCenter;

  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState('');
  const [messageTypeId, setMessageTypeId] = useState();

  const {
    data: users = [],
    refetch,
    isFetching,
  } = useQuery('searchUsers', () => searchUsers(query), {
    enabled: false,
  });

  const { mutate: mutateLinkGuestToUser } = useMutation(PostLinkGuestToUser, {
    onSuccess() {
      storeMessageCenter.setLinkedToUser(true);
      storeMessageCenter.setIsGuest(false);
      setModal(false);
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ message }) {
      setModal(false);
      Toastify.toast.error(message);
    },
  });

  useEffect(() => {
    if (query) {
      queryClient.cancelQueries('searchUsers');
      refetch();
    }
  }, [query]);

  const modalToggle = () => setModal(!modal);

  const handleSelect = (id: number) => {
    const user = users.find((item) => item.id === id);

    mutateLinkGuestToUser({
      conversation_id: storeMessageCenter.conversationSelectedId,
      customer_id: user?.id,
    });
  };

  const toggleType = (typeId: number) => {
    if (messageTypeId === typeId) {
      setMessageTypeId('');
    } else {
      setMessageTypeId(typeId);
    }
  };

  useEffect(() => {
    if (messageTypeId) {
      console.log('foi fio');
      storeMessageCenter.setMessageTypeId(messageTypeId);
      storeMessageCenter.getConversationMessages(1);
    }
  }, [messageTypeId]);

  const hasSelected = !!storeMessageCenter.conversationSelectedId;
  return (
    <HeaderContainer
      style={{
        pointerEvents: hasSelected ? 'initial' : 'none',
        opacity: hasSelected ? 1 : 0.5,
      }}
      className="mc-header-chat-content"
    >
      <LeftSide>
        <ProfileMessagesStatus
          href={`${Routes.CUSTOMER_INFORMATION}/?id=${storeMessageCenter.customerSelected.id}`}
          target="_blank"
        >
          <ProfilePicture>
            {storeMessageCenter.customerSelected?.avatar && (
              <Avatar src={storeMessageCenter.customerSelected.avatar} />
            )}
          </ProfilePicture>
          <ProfileName className="mc-header-profile-name">
            {storeMessageCenter.customerSelected.name}{' '}
          </ProfileName>

          <SubscriptionStatus
            id={`subscription`}
            title={capitalize(
              storeMessageCenter.customerSelected?.subscription_plan ?? '',
            )}
            background={
              SUBSCRIPTION_COLOR_BG[
                storeMessageCenter.customerSelected?.subscription_plan
              ]
            }
          >
            {storeMessageCenter.customerSelected?.subscription_plan}
          </SubscriptionStatus>

          <Pipe />
        </ProfileMessagesStatus>
        <SocialMediaFilters>
          <SocialMediaButton onClick={() => toggleType(1)}>
            <Icon name={checkChatType(1)} isActive={messageTypeId === 1} />
          </SocialMediaButton>
          <SocialMediaButton onClick={() => toggleType(2)}>
            <Icon name={checkChatType(2)} isActive={messageTypeId === 2} />
          </SocialMediaButton>
          <SocialMediaButton onClick={() => toggleType(3)}>
            <Icon name={checkChatType(3)} isActive={messageTypeId === 3} />
          </SocialMediaButton>
        </SocialMediaFilters>
      </LeftSide>
      <RightSide>
        {storeMessageCenter.isGuest && (
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary mr-4"
            onClick={modalToggle}
            style={{ height: 20, marginTop: 8 }}
          >
            Link to user
          </Button>
        )}

        <IconButton>
          <IconPhone onClick={() => handleOpenMenu(!isOpenMenu)} />
        </IconButton>
        <IconButton onClick={() => handleOpenMenu(!isOpenMenu)}>
          <IconMenu />
        </IconButton>
      </RightSide>

      <Modal centered isOpen={modal} toggle={modalToggle}>
        <TitleModalContainer>
          <span className="title-modal">
            Find User to link to{' '}
            {storeMessageCenter.customerSelected?.phone_number}
          </span>
        </TitleModalContainer>
        <ModalBody>
          <SearchBar
            data={users?.map((item) => ({ ...item, name: item.full_name }))}
            onChange={setQuery}
            onSelect={handleSelect}
            isLoading={isFetching}
            placeholder="Name, email or UTR"
            icon={faChevronRight}
          />
        </ModalBody>
      </Modal>
    </HeaderContainer>
  );
}

export default observer(Header);
