import React from "react";
import { Link } from "gatsby";
import { Card, Container, Input, Button, Col } from "src/modules";
import { Layout, TableHead, TableRow } from "src/components";
import Dashboard from "src/layouts/Dashboard";

// markup
const CategoryAdd: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="category-add">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">Add new category</Col>
            </TableHead>
            <TableRow>
              <Col xs="3">Name</Col>
              <Col>
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="name"
                  name="name"
                  placeholder="Enter the name"
                />
              </Col>
            </TableRow>
            <TableRow>
              <Col xs="3">Description</Col>
              <Col>
                <Input
                  className="w-50"
                  type="textarea"
                  name="description"
                  placeholder="Enter the description"
                />
              </Col>
            </TableRow>
            <TableRow>
              <Col xs="3"></Col>
              <Col xs="3" className="d-flex">
                <Button
                  type="button"
                  color="transparent"
                  className="btn-outline-primary mr-3"
                >
                  SUBMIT
                </Button>
                <Link to="/categories">
                  <Button
                    type="button"
                    color="transparent"
                    className="btn-outline-secondary"
                  >
                    BACK
                  </Button>
                </Link>
              </Col>
            </TableRow>
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default CategoryAdd;
