export const onCurrencyInputChange = (
    e: any,
    valueAsCurrency: any,
    state: object,
    setState: (state: object) => void,
  ) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: (valueAsCurrency === '0.00'? null: valueAsCurrency),
    });
  };

export const onInputChange = (
    e: any,
    state: object,
    setState: (state: object) => void,
  ) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

export const onCheckboxChange = (
    e: any,
    state: object,
    setState: (state: object) => void,
  ) => {
    const { name, checked } = e.target;

    setState({
      ...state,
      [name]: checked,
    });
  };
