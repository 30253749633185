import React from 'react';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Icon from 'src/components/Icon';
import { INotificationDetails, getNotificationTab } from 'src/models';
import { Card, Col, observer } from 'src/modules';
import { Routes } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';
import { NotificationRead } from '../ui';

// TODO: notification model
interface Props {
  item: INotificationDetails;
  user_id: number;
  setUnreadCount: (isRead: number) => void;
}

const NotificationRowDetails: React.FC<Props> = ({
  item,
  setUnreadCount,
  user_id,
}) => {
  return (
    <Card className="w-100 my-2">
      <TableRow>
        <Col xs="8" className="mt-3 pl-4">
          {item.is_alert && <Icon name="alert" size="sm" />} {item.message}
        </Col>
        <Col xs="2" className="mt-3 text-center">
          {item.sent_datetime}
        </Col>
        <Col xs="2" className="mt-2">
          <div className="d-flex flex-row justify-content-center border-top-0 pb-1">
            <a
              href={`${
                Routes.CUSTOMER_INFORMATION
              }/?id=${user_id}&tab=${getNotificationTab(item.tag)}`}
              target="_blank"
              rel="noreferrer"
            >
              <ButtonIcon
                className="btn rounded-circle btn-icon mr-1 table-button"
                color="secondary"
                outline
              >
                <FontAwesomeIcon icon={faEye} />
              </ButtonIcon>
            </a>
            <NotificationRead
              initStatus={item.is_read}
              id={item.id}
              userId={item.user_id}
              onUpdate={(value) => setUnreadCount(value)}
            />
          </div>
        </Col>
      </TableRow>
    </Card>
  );
};

export default observer(NotificationRowDetails);
