// TODO:
// set on .env

export default class API {
  static PATH = process.env.GATSBY_API_URL;

  static STATS = `/users/counter`;

  static USERS = `/users`;
  static USERS_EDIT = `/users/edit`;
  static USERS_INFORMATION = `/v2/users/get-user-information`;
  static USERS_SET_CALLED = `/users-detail/set-called`;
  static USERS_ELECTRONIC_SIGNATURE = '/electronic-signature/';
  static USERS_ELECTRONIC_SIGNATURE_DOWNLOAD = '/electronic-signature/download';
  static USERS_ADMIN_NOTES = '/admin/get-admin-notes';
  static USERS_ADMIN_NOTES_UPDATE = '/admin-notes/edit';
  static USERS_ADMIN_NOTES_CREATE = '/admin-notes/add-admin-notes';
  static USERS_MESSAGES = '/users/inbox';
  static USERS_MESSAGES_CREATE = '/users/send-new-message/';
  static USERS_MESSAGES_CHANGE_STATUS = '/users/conversation/change-status';
  static USERS_TASK_HISTORY = '/users/task-history';
  // static USERS_TAX_INCOME = '/users/tax-income/listing/';
  static USERS_TAX_INCOME_DATA = '/users/tax-income/get-data/';
  static USERS_TAX_INCOME_UPDATE_STATUS = '/users/tax-income/update-status/';
  static USERS_TAX_INCOME_DOWNLOAD = '/users/tax-income/download/';
  static USERS_TAX_INCOME_EXPORT = '/users/tax-income/export/';
  static USERS_TAX_INCOME_MOVE_TO_YEAR =
    '/users/tax-income/update-income-year/';
  static USERS_TAX_INCOME_ADD = '/users/tax-income/add';
  static USERS_TAX_INCOME_EDIT = '/users/tax-income/edit';
  static USERS_TAX_INCOME_DELETE = '/users/tax-income/remove';
  static USERS_TAX_INCOME_IN_ADD_INCOME = '/users/tax-income/in-add-income';
  static USERS_TAX_INCOME_SUBMIT = '/users/tax-income/submit';
  static USERS_HMRC = '/v2/users/hmrc/';
  static USERS_HMRC_UPDATE = '/users/hmrc/modify-hmrc';
  static USERS_HMRC_PAY_UNPAY = '/users/hmrc/set-pay-unpay';
  static USERS_TAX_EXPENSES = '/customer-expenses/';
  static USERS_TAX_EXPENSES_EDIT = '/customer-expenses/edit';
  static DISALLOW_UPDATE = '/customer-expenses/disallow-update';
  static GET_SELF_EMPLOYEE = '/expenses-form/get-self-employed-info';
  static GET_PAYE_EMPLOYEE = '/expenses-form/get-paye-info';

  static CUSTOMERS_DELETED_ACCOUNT = '/deleted-accounts';

  static ADMINS_TAX_ASSISTANTS = `/admin`;

  static USERS_REFUNDS = `/user-taxes/get-refunds`;
  static USERS_STATUSINFO_ADD = `/users/add-contact-status`;
  static USERS_STATUSINFO_REMOVE = `/users/remove-contact-status`;

  static USERS_TAX_SET_PAID = `/user-taxes/set-paid`;
  static USERS_TAX_SET_BLANK = `/user-taxes/set-blank`;
  static USERS_TAX_EXPENSE_STATUS = `/user-taxes/update-expense-status`;
  static USERS_SET_UTR_STATUS = `/users-detail/set-request-utr-status`;
  static USERS_UPDATE_TAX_AMOUNT = `/users/update-tax-detail`;
  static USERS_UPDATE_TAX_ASSISTANT = '/users/update-tax-assistant';
  static USERS_UPDATE_TOGGLE_TAX_CODE = `/user-taxes/set-tax-code`;
  static USERS_CHANGE_STATUS = `/users/change-status`;
  static USERS_DELETE_ACCOUNT = `/users/delete-account`;
  static USERS_GET_ASSISTANTS = `/users/get-tax-assistant`;

  static USERS_EXPORT = '/users/export';
  static USERS_EXPORT_WISE = '/users/export/wise';
  static USERS_EXPORT_BARCLAYS = '/users/export/barclays';
  static USERS_EXPORT_ALL_PAID = '/users/list-users/export/all-paid';

  static ADMIN_USERS = '/admin';
  static ADMIN_USERS_CREATE = '/admin/create';
  static ADMIN_USERS_UPDATE = '/admin/edit/';
  static ADMIN_USERS_DELETE = '/admin/delete/';
  static ADMIN_USERS_CHANGE_STATUS = '/admin/change-status/';
  static ADMIN_USERS_CHANGE_AVAILABLE_STATUS =
    '/admin/change-available-status/';

  static NOTIFICATIONS = '/notifications';
  static NOTIFICATIONS_LIST = '/notifications/list-notifications';
  static NOTIFICATIONS_READ_UNREAD = '/notifications/read-unread';

  static CATEGORIES = `/categories`;
  static CATEGORIES_CREATE = `/categories/create`;
  static CATEGORIES_UPDATE = `/categories/edit`;
  static CATEGORIES_DELETE = `/categories/delete`;
  static CATEGORIES_STATUS_CHANGE = `/categories/change-status`;

  static USERS_REFERRALS = `/user-referrals`;

  static EXPENSES = `/expenses`;
  static EXPENSES_CREATE = `/expenses/create`;
  static EXPENSES_UPDATE = `/expenses/edit`;
  static EXPENSES_DELETE = `/expenses/delete`;
  static EXPENSES_STATUS_CHANGE = `/expenses/change-status`;

  static QUESTIONS = `/questions`;
  static QUESTIONS_CREATE = `/questions/create`;
  static QUESTIONS_UPDATE = `/questions/edit`;
  static QUESTIONS_DELETE = `/questions/delete`;
  static QUESTIONS_STATUS_CHANGE = `/questions/change-status`;

  static JOB_CATEGORIES = `/job-categories`;
  static JOB_CATEGORIES_CREATE = `/job-categories/create`;
  static JOB_CATEGORIES_UPDATE = `/job-categories/edit`;
  static JOB_CATEGORIES_DELETE = `/job-categories/delete`;
  static JOB_CATEGORIES_STATUS_CHANGE = `/job-categories/change-status`;

  static JOB_SUB_CATEGORIES = `/job-sub-categories`;
  static JOB_SUB_CATEGORIES_CREATE = `/job-sub-categories/create`;
  static JOB_SUB_CATEGORIES_UPDATE = `/job-sub-categories/edit`;
  static JOB_SUB_CATEGORIES_DELETE = `/job-sub-categories/delete`;
  static JOB_SUB_CATEGORIES_STATUS_CHANGE = `/job-sub-categories/change-status`;

  static HOW_YOU_HERE = '/how-you-here';
  static HOW_YOU_HERE_CREATE = '/how-you-here/create';
  static HOW_YOU_HERE_CHANGE_STATUS = '/how-you-here/change-status';
  static HOW_YOU_HERE_EDIT = '/how-you-here/edit';
  static HOW_YOU_HERE_DELETE = '/how-you-here/delete';
}
