import { TaxHistoryResponse, TaxHistoryBenefitsReponse } from 'src/models';

import request from '../request';
import ResponseError from '../ResponseError';

export const editTransaction = async (
  userId: number,
  transactionId: number,
  taxYear: number,
  incomeId: number,
  incomeType: 'employment' | 'self_employed',
  categoryId: number,
  note: string,
  work_percentage: string,
): Promise<TaxHistoryResponse> => {
  try {
    const { data } = await request.post(
      `/users/tax-income/transactions/${transactionId}/edit`,
      {
        _method: 'PUT',
        user_id: userId,
        tax_year: taxYear,
        income_id: incomeId,
        income_type: incomeType,
        reconciliation_type: 'expense',
        category_id: categoryId,
        note,
        work_percentage,
      },
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
