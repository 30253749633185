import React, { useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
ChartJS.register(PointElement, LineElement);
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import { fetchDashboard } from 'src/api/dashboard';
import { Filters } from 'src/components/dashboard';
import DashboardTable from 'src/components/DashboardTable';
import DashboardTableSubmitted from 'src/components/DashboardTableSubmitted';
import Layout from 'src/components/Layout';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { observer } from 'src/modules';
import store from 'src/stores';
import { formatDate } from 'src/utils';
import {
  DashboardType,
  getDashboardDataSet,
  TDashboardCharts,
} from 'src/utils/dashboardDataset';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

const TABS = [
  { label: 'Sign ups', value: 'signups' },
  { label: 'Marketing ROI', value: 'marketingROI' },
  { label: 'Refunds Paid', value: 'submitted' },
  { label: 'Refunds Not Paid', value: 'submittedNotPaid' },
];

const DashboardPage: React.FC = () => {
  const [signupData, setSignupData] = useState<TDashboardCharts>();
  const [agentFeeDownloaded, setAgentFeeDownloaded] =
    useState<TDashboardCharts>();
  const [submittedData, setSubmittedData] = useState<TDashboardCharts>();
  const [submittedNotPaidData, setSubmittedNotPaidData] =
    useState<TDashboardCharts>();

  const { isLoading: isLoadingSignUps } = useQuery(
    [
      'fetchDashboard',
      formatDate(store.dashboard.filters.startDate, 'DD/MM/YYYY'),
      formatDate(store.dashboard.filters.endDate, 'DD/MM/YYYY'),
      store.dashboard.filters.period,
    ],
    fetchDashboard,
    {
      retry: false,
      onSuccess: (result) => {
        const signupsDataSet: TDashboardCharts | undefined =
          getDashboardDataSet(result.signups, DashboardType.SIGNUPS);
        const agentfeeDownloadedSet: TDashboardCharts | undefined =
          getDashboardDataSet(
            result.agentfeeDownloaded,
            DashboardType.AGENT_FEE,
          );
        const submittedSet: TDashboardCharts | undefined = getDashboardDataSet(
          result.submitted,
          DashboardType.SUBMITTED,
        );

        const submittedNotPaidSet: TDashboardCharts | undefined =
          getDashboardDataSet(result.submittedNotPaid, DashboardType.SUBMITTED);

        setSignupData(signupsDataSet);
        setAgentFeeDownloaded(agentfeeDownloadedSet);
        setSubmittedData(submittedSet);
        setSubmittedNotPaidData(submittedNotPaidSet);
        if (!activeTab) setActiveTab('signups');
        // console.log(result);
      },
    },
  );

  const [activeTab, setActiveTab] = useState('');

  const getTabContent = () => {
    switch (activeTab) {
      case 'marketingROI':
        return (
          <DashboardTable
            showToggle
            data={agentFeeDownloaded}
            title="Marketing ROI"
          />
        );

      case 'submitted':
        return (
          <DashboardTableSubmitted
            showToggle
            data={submittedData}
            title="Submitted"
          />
        );

      case 'submittedNotPaid':
        return (
          <DashboardTableSubmitted
            showToggle
            data={submittedNotPaidData}
            title="Submitted Not Paid"
          />
        );

      case 'signups':
        return (
          <>
            <DashboardTable data={signupData} title="Sign ups" />
            {signupData && (
              <>
                <div style={{ marginBottom: '30px' }}>
                  <Line options={optionsLine} data={signupData?.line} />
                </div>
                <Bar options={options} data={signupData?.stackedBar} />
              </>
            )}
            <div className="my-5" style={{ width: '33%' }}>
              {signupData && <Pie data={signupData?.pie} />}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Layout>
      <Dashboard title="Dashboard">
        <Filters />
        {isLoadingSignUps && <Loading />}

        <div className="tab mt-4">
          <ul className="nav nav-tabs">
            {TABS.map((item) => (
              <li key={item.value} className="nav-item position-relative">
                <a
                  onClick={() => setActiveTab(item.value)}
                  className={`nav-link ${
                    activeTab === item.value ? 'active' : ''
                  }`}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          {getTabContent()}
        </div>
      </Dashboard>
    </Layout>
  );
};

export default observer(DashboardPage);
