import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { getSA100, updateSA100 } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  Card,
  CheckboxLabel,
  FormContainer,
  InputForm,
  SubmitButton,
  Subtitle,
  Title,
  Select
} from '../../styles';

import CurrencyInput from 'react-currency-masked-input';

import { FormPersonal } from '../../FormState';

import { onInputChange, onCheckboxChange, onCurrencyInputChange } from '../../utils';

type Props = {
  sa100formId: number;
  taxYear: number,
  taxReturnId: number,
  onClick?: ( e: any) => void,  
};

export const SA100 = ({ 
    sa100formId, taxYear,
    taxReturnId, onClick
}: Props) => {


  const [formSA100State, setformSA100State] = useState<FormPersonalType>(FormPersonal);
  const [repaymentPlanTypeList, setRepaymentPlanTypeList] = useState<any>([]);


  const { data, isLoading, refetch } = useQuery(
    'getSA100',
    () => getSA100(sa100formId),
    {
      enabled: false,
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    if (sa100formId) refetch();

    let plans = [
        { value: '', text: '---'},
        { value: '01', text: '01 - Plan 1'},
        { value: '02', text: '02 - Plan 2'}
    ]

    if( taxYear > 2021)
        plans.push({ value: '04', text: '04 - Plan 4'})

    setRepaymentPlanTypeList(plans);
  }, []);

  useEffect(() => {
    if (sa100formId) refetch();
  }, [sa100formId]);


  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformSA100State(data.data);
      } else {
        setformSA100State(FormPersonal);
      }
    }
  }, [isLoading]);

  const { mutate: mutationSA100 } = useMutation(updateSA100, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  const onSubmitForm = () => {
    const formData = {
      sa100_form_id: sa100formId,
      ...formSA100State,
    };

    mutationSA100(formData);
  };

  return (
    <>
    <div className="row">
        <div className="col-6">
        <Title>SA100</Title> 
      <Subtitle>* Hidden input: sa100_form_id: {sa100formId}</Subtitle>
      <FormContainer>
        <Title>Your Personal Details</Title>

        <InputForm>
          <Label>Date of Birth</Label>
          <Input
            id="date_of_birth"
            name="date_of_birth"
            value={formSA100State.date_of_birth}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="date"
            bsSize="lg"
          />
        </InputForm>

        <Card>
          <Title>New Address</Title>
          <InputForm>
            <Label>New Address Line 1</Label>
            <Input
              id="new_address_line_1"
              name="new_address_line_1"
              value={formSA100State.new_address_line_1}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>New Address Line 2</Label>
            <Input
              id="new_address_line_2"
              name="new_address_line_2"
              value={formSA100State.new_address_line_2}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>New Address Line 3</Label>
            <Input
              id="new_address_line_3"
              name="new_address_line_3"
              value={formSA100State.new_address_line_3}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>New Address Line 4</Label>
            <Input
              id="new_address_line_4"
              name="new_address_line_4"
              value={formSA100State.new_address_line_4}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>New Postcode</Label>
            <Input
              id="new_postcode"
              name="new_postcode"
              value={formSA100State.new_postcode}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>New Address Effective From</Label>
            <Input
              id="new_address_effective_from"
              name="new_address_effective_from"
              value={formSA100State.new_address_effective_from}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="date"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        
        <InputForm>
          <Label>Telephone Number</Label>
          <Input
            name="telephone_number"
            id="telephone_number"
            value={formSA100State.telephone_number}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="text"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>National Insurance Number</Label>
          <Input
            id="national_insurance_number"
            name="national_insurance_number"
            value={formSA100State.national_insurance_number}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="text"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>Taxpayer Status (required)</Label>

          <Select className="form-control-lg form-control form-select" 
            name="taxpayer_status" 
            id="taxpayer_status" 
            required
            value={formSA100State.taxpayer_status}
            onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
            }
            >
                <option value="U">U - Rest of UK</option>
                <option value="C">C - Welsh</option>
                <option value="S">S - Scottish</option>
            </Select>
        </InputForm>


        <Title>Your Tax Return</Title>
        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.employment_schedule}
              checked={formSA100State.employment_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="employment_schedule"
              id="employment_schedule"
            />
            <Label lg>Employment Schedule</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Employment Schedule</Label>
            <Input
              id="number_of_employment_schedules"
              name="number_of_employment_schedules"
              value={formSA100State.number_of_employment_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>

        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.minister_of_religion_schedule}
              checked={formSA100State.minister_of_religion_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="minister_of_religion_schedule"
              id="minister_of_religion_schedule"
            />
            <Label lg>Minister of Relegion Schedule</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Minister of Relegion Schedules</Label>
            <Input
              id="number_of_minister_of_religion_schedules"
              name="number_of_minister_of_religion_schedules"
              value={formSA100State.number_of_minister_of_religion_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.full_self_employment_schedule}
              checked={formSA100State.full_self_employment_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="full_self_employment_schedule"
              id="full_self_employment_schedule"
            />
            <Label lg>Full Self Employment Schedules</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Full Self Employment Schedules</Label>
            <Input
              id="number_of_full_self_employment_schedules"
              name="number_of_full_self_employment_schedules"
              value={formSA100State.number_of_full_self_employment_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.short_self_employment_schedule}
              checked={formSA100State.short_self_employment_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="short_self_employment_schedule"
              id="short_self_employment_schedule"
            />
            <Label lg>Short Self Employment Schedules</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Short Self Employment Schedules</Label>
            <Input
              id="number_of_short_self_employment_schedules"
              name="number_of_short_self_employment_schedules"
              value={formSA100State.number_of_short_self_employment_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.lloyds_underwriter_schedule}
            checked={formSA100State.lloyds_underwriter_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="lloyds_underwriter_schedule"
            id="lloyds_underwriter_schedule"
          />
          <Label lg>Lloyds Underwriter Schedules</Label>
        </CheckboxLabel>
        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.full_partnership_schedule}
              checked={formSA100State.full_partnership_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="full_partnership_schedule"
              id="full_partnership_schedule"
            />
            <Label lg>Full Partnership Schedules</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Full Partnership Schedules</Label>
            <Input
              id="number_of_full_partnership_schedules"
              name="number_of_full_partnership_schedules"
              value={formSA100State.number_of_full_partnership_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.short_partnership_schedule}
              checked={formSA100State.short_partnership_schedule}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="short_partnership_schedule"
              id="short_partnership_schedule"
            />
            <Label lg>Short Partnership Schedules</Label>
          </CheckboxLabel>
          <InputForm>
            <Label>Number of Short Partnership Schedules</Label>
            <Input
              id="number_of_short_partnership_schedules"
              name="number_of_short_partnership_schedules"
              value={formSA100State.number_of_short_partnership_schedules}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.uk_property_schedule}
            checked={formSA100State.uk_property_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="uk_property_schedule"
            id="uk_property_schedule"
          />
          <Label lg>UK Property Schedules</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.foreign_schedule}
            checked={formSA100State.foreign_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="foreign_schedule"
            id="foreign_schedule"
          />
          <Label lg>Foreign Schedules</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.trusts_schedule}
            checked={formSA100State.trusts_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="trusts_schedule"
            id="trusts_schedule"
          />
          <Label lg>Trusts Schedules</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.capital_gains_schedule}
            checked={formSA100State.capital_gains_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="capital_gains_schedule"
            id="capital_gains_schedule"
          />
          <Label lg>Capital Gains Schedules</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.capital_gains_computation_attached}
            checked={formSA100State.capital_gains_computation_attached}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="capital_gains_computation_attached"
            id="capital_gains_computation_attached"
          />
          <Label lg>Capital Gains Computation Attached</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.resident_remittance_schedule}
            checked={formSA100State.resident_remittance_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="resident_remittance_schedule"
            id="resident_remittance_schedule"
          />
          <Label lg>Resident Remittance Schedules</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.additional_information_schedule}
            checked={formSA100State.additional_information_schedule}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="additional_information_schedule"
            id="additional_information_schedule"
          />
          <Label lg>Additional Information Schedules</Label>
        </CheckboxLabel>
        <Title>Student Loan Repayments</Title>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.income_contingent_student_loan_notification}
            checked={formSA100State.income_contingent_student_loan_notification}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="income_contingent_student_loan_notification"
            id="income_contingent_student_loan_notification"
          />
          <Label lg>Income Contingent Student Loan Notification</Label>
        </CheckboxLabel>



        <InputForm>
          <Label>Student Loan Repayment Deducted Amount</Label>
          <CurrencyInput
            id="student_loan_repayment_deducted_amount"
            name="student_loan_repayment_deducted_amount"
            value={formSA100State.student_loan_repayment_deducted_amount}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
            className="form-control-lg form-control"
          />
        </InputForm>



        <InputForm>
          <Label>Postgraduate Loan Repayment Deducted Amount</Label>
          <CurrencyInput
            id="postgraduate_loan_repayment_deducted_amount"
            name="postgraduate_loan_repayment_deducted_amount"
            value={formSA100State.postgraduate_loan_repayment_deducted_amount}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
            className="form-control-lg form-control"
          />
        </InputForm>
        <InputForm>
          <Label>Repayment Plan Type</Label>
            <Select className="form-control-lg form-control form-select" 
            name="repayment_plan_type" 
            id="repayment_plan_type" 
            required
            value={formSA100State.repayment_plan_type}
            onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
            }
            >
                {repaymentPlanTypeList.map((item, i) => (
                    <option key={i} value={item.value}>{item.text}</option>
                ))}
            </Select>

        </InputForm>
        <InputForm>
          <Label>Postgraduate Loan Plan Type</Label>
          <Select className="form-control-lg form-control form-select" 
            name="postgraduate_loan_plan_type" 
            id="postgraduate_loan_plan_type" 
            required
            value={formSA100State.postgraduate_loan_plan_type}
            onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
            }
            >
                <option value="">---</option>
                <option value="03">03 - Postgraduate Loan</option>

            </Select>

        </InputForm>
        <Title>Income</Title>
        <Card>
          <Title>UK Interest and Dividends</Title>
          <InputForm>
            <Label>Taxed Bank Building Society Etc Interest</Label>
            <CurrencyInput
              id="taxed_bank_building_society_etc_interest"
              name="taxed_bank_building_society_etc_interest"
              value={formSA100State.taxed_bank_building_society_etc_interest}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Untaxed UK Interest Etc</Label>
            <CurrencyInput
              id="untaxed_uk_interest_etc"
              name="untaxed_uk_interest_etc"
              value={formSA100State.untaxed_uk_interest_etc}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Untaxed Foreign Interest</Label>
            <CurrencyInput
              id="untaxed_foreign_interest"
              name="untaxed_foreign_interest"
              value={formSA100State.untaxed_foreign_interest}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Company Dividends</Label>
            <CurrencyInput
              id="company_dividends"
              name="company_dividends"
              value={formSA100State.company_dividends}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Unity Trust Etc Dividends</Label>
            <CurrencyInput
              id="unit_trust_etc_dividends"
              name="unit_trust_etc_dividends"
              value={formSA100State.unit_trust_etc_dividends}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Foreign Dividends</Label>
            <CurrencyInput
              id="foreign_dividends"
              name="foreign_dividends"
              value={formSA100State.foreign_dividends}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Tax Taken Off Foreign Dividends</Label>
            <CurrencyInput
              id="tax_taken_off_foreign_dividends"
              name="tax_taken_off_foreign_dividends"
              value={formSA100State.tax_taken_off_foreign_dividends}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
        </Card>
        <Card>
          <Title>State Benefits</Title>
          <InputForm>
            <Label>Annual State Pension</Label>
            <CurrencyInput
              id="annual_state_pension"
              name="annual_state_pension"
              value={formSA100State.annual_state_pension}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>State Pension Lump Sun</Label>
            <CurrencyInput
              id="state_pension_lump_sum"
              name="state_pension_lump_sum"
              value={formSA100State.state_pension_lump_sum}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Tax Taken Off Pension Lump Sun</Label>
            <CurrencyInput
              id="tax_taken_off_pension_lump_sum"
              name="tax_taken_off_pension_lump_sum"
              value={formSA100State.tax_taken_off_pension_lump_sum}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Other Pensions And Retirement Annuities</Label>
            <CurrencyInput
              id="other_pensions_and_retirement_annuities"
              name="other_pensions_and_retirement_annuities"
              value={formSA100State.other_pensions_and_retirement_annuities}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Tax Taken Off Pensions And Retirement Annuities</Label>
            <CurrencyInput
              id="tax_taken_off_pensions_and_retirement_annuities"
              name="tax_taken_off_pensions_and_retirement_annuities"
              value={
                formSA100State.tax_taken_off_pensions_and_retirement_annuities
              }
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Incapacity Benefits</Label>
            <CurrencyInput
              id="incapacity_benefit"
              name="incapacity_benefit"
              value={formSA100State.incapacity_benefit}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Tax Taken Off Incapacity Benefits</Label>
            <CurrencyInput
              id="tax_taken_off_incapacity_benefit"
              name="tax_taken_off_incapacity_benefit"
              value={formSA100State.tax_taken_off_incapacity_benefit}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Job Seekers Allowance</Label>
            <CurrencyInput
              id="job_seekers_allowance"
              name="job_seekers_allowance"
              value={formSA100State.job_seekers_allowance}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Other State Pensions And Benefits</Label>
            <CurrencyInput
              id="other_state_pensions_and_benefits"
              name="other_state_pensions_and_benefits"
              value={formSA100State.other_state_pensions_and_benefits}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
        </Card>

        <Card>
          <Title>Other UK Incomes</Title>
          <Card>
            <Title>Other Taxable Income Details</Title>
            <InputForm>
              <Label>Other Taxable Income</Label>
              <CurrencyInput
                id="other_taxable_income"
                name="other_taxable_income"
                value={formSA100State.other_taxable_income}
                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
                className="form-control-lg form-control"
              />
            </InputForm>
            <InputForm>
              <Label>Tax Taken Off Other Taxable Income</Label>
              <CurrencyInput
                id="tax_taken_off_other_taxable_income"
                name="tax_taken_off_other_taxable_income"
                value={formSA100State.tax_taken_off_other_taxable_income}
                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
                className="form-control-lg form-control"
              />
            </InputForm>
          </Card>
          <InputForm>
            <Label>Allowable Expenses</Label>
            <CurrencyInput
              id="allowable_expenses"
              name="allowable_expenses"
              value={formSA100State.allowable_expenses}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Deemed Income Or Benefits</Label>
            <CurrencyInput
              id="deemed_income_or_benefits"
              name="deemed_income_or_benefits"
              value={formSA100State.deemed_income_or_benefits}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Description Of Other Income</Label>
            <Input
              id="description_of_other_income"
              name="description_of_other_income"
              value={formSA100State.description_of_other_income}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
        </Card>
        <Title>Tax Reliefs</Title>

        <Card>
          <Title>Pensions</Title>
          <InputForm>
            <Label>Payments To Registered Pension Schemes</Label>
            <CurrencyInput
              id="payments_to_registered_pension_schemes"
              name="payments_to_registered_pension_schemes"
              value={formSA100State.payments_to_registered_pension_schemes}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>

              {
                (taxYear == 2023) ? 
                <InputForm>
                    <Label>One Off Registered Pension Schemes Payments</Label>
                    <CurrencyInput
                    id="one_off_registered_pension_schemes_payments"
                    name="one_off_registered_pension_schemes_payments"
                    value={formSA100State.one_off_registered_pension_schemes_payments}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
                    className="form-control-lg form-control"
                    />
                </InputForm>
                : <></>
              }

          <InputForm>
            <Label>Retirement Annuity Contract Payments</Label>
            <CurrencyInput
              id="retirement_annuity_contract_payments"
              name="retirement_annuity_contract_payments"
              value={formSA100State.retirement_annuity_contract_payments}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>



          <InputForm>
            <Label>Employer Pension Scheme Payments</Label>
            <CurrencyInput
              id="employer_pension_scheme_payments"
              name="employer_pension_scheme_payments"
              value={formSA100State.employer_pension_scheme_payments}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Non UK Overseas Pension Scheme Payments</Label>
            <CurrencyInput
              id="non_uk_overseas_pension_scheme_payments"
              name="non_uk_overseas_pension_scheme_payments"
              value={formSA100State.non_uk_overseas_pension_scheme_payments}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
        </Card>
        <Card>
          <Title>Charitable Giving</Title>
          <InputForm>
            <Label>Gift Aid Payments Made In Year</Label>
            <CurrencyInput
              id="gift_aid_payments_made_in_year"
              name="gift_aid_payments_made_in_year"
              value={formSA100State.gift_aid_payments_made_in_year}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>One Off Gift Aid Payments</Label>
            <CurrencyInput
              id="one_off_gift_aid_payments"
              name="one_off_gift_aid_payments"
              value={formSA100State.one_off_gift_aid_payments}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Gift Aid Payments Carried Back To Previous Year</Label>
            <CurrencyInput
              id="gift_aid_payments_carried_back_to_previous_year"
              name="gift_aid_payments_carried_back_to_previous_year"
              value={ formSA100State.gift_aid_payments_carried_back_to_previous_year}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Gift Aid Payments Brought Back From Later Year</Label>
            <CurrencyInput
              id="gift_aid_payments_brought_back_from_later_year"
              name="gift_aid_payments_brought_back_from_later_year"
              value={
                formSA100State.gift_aid_payments_brought_back_from_later_year
              }
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Shares Gifted To Charity</Label>
            <CurrencyInput
              id="shares_gifted_to_charity"
              name="shares_gifted_to_charity"
              value={formSA100State.shares_gifted_to_charity}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Land And Buildings Gifted To Charity</Label>
            <CurrencyInput
              id="land_and_buildings_gifted_to_charity"
              name="land_and_buildings_gifted_to_charity"
              value={formSA100State.land_and_buildings_gifted_to_charity}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Investments Gifted To Non UK Charities</Label>
            <CurrencyInput
              id="investments_gifted_to_non_uk_charities"
              name="investments_gifted_to_non_uk_charities"
              value={formSA100State.investments_gifted_to_non_uk_charities}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
          <InputForm>
            <Label>Donations To Non UK Charities</Label>
            <CurrencyInput
              id="donations_to_non_uk_charities"
              name="donations_to_non_uk_charities"
              value={formSA100State.donations_to_non_uk_charities}
              onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
              className="form-control-lg form-control"
            />
          </InputForm>
        </Card>
        <Card>
          <Title>Blind Persons Allowance</Title>
          <Card>
            <Title>Blind Persons Allowance Details</Title>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA100State.registered_blind}
                checked={formSA100State.registered_blind}
                onChange={(e) =>
                  onCheckboxChange(e, formSA100State, setformSA100State)
                }
                name="registered_blind"
                id="registered_blind"
              />
              <Label lg>Registered Blind</Label>
            </CheckboxLabel>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA100State.surplus_blind_persons_allowance_to_spouse}
                checked={
                  formSA100State.surplus_blind_persons_allowance_from_spouse
                }
                onChange={(e) =>
                  onCheckboxChange(e, formSA100State, setformSA100State)
                }
                name="surplus_blind_persons_allowance_to_spouse"
                id="surplus_blind_persons_allowance_to_spouse"
              />
              <Label lg>Surplus Blind Persons Allowance To Spouse</Label>
            </CheckboxLabel>
            <InputForm>
              <Label>Local Authority Name</Label>
              <Input
                id="local_authority_name"
                name="local_authority_name"
                value={formSA100State.local_authority_name}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
          </Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.surplus_blind_persons_allowance_from_spouse}
              checked={
                formSA100State.surplus_blind_persons_allowance_from_spouse
              }
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="surplus_blind_persons_allowance_from_spouse"
              id="surplus_blind_persons_allowance_from_spouse"
            />
            <Label lg>Surplus Blind Persons Allowance From Spouse</Label>
          </CheckboxLabel>
        </Card>
        <Title>High Income Child Benefit Charge</Title>
        <InputForm>
          <Label>Child Benefit Amount Received</Label>
          <CurrencyInput
            id="child_benefit_amount_received"
            name="child_benefit_amount_received"
            value={formSA100State.child_benefit_amount_received}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
            className="form-control-lg form-control"
          />
        </InputForm>
        <InputForm>
          <Label>Number of Children</Label>
          <Input
            id="number_of_children"
            name="number_of_children"
            value={formSA100State.number_of_children}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="number"
            bsSize="lg"
          />
        </InputForm>



            <InputForm>
                <Label>Date Stopped Receiving All Child Benefit Payments</Label>
                <Input
                id="date_stopped_receiving_all_child_benefit_payments"
                name="date_stopped_receiving_all_child_benefit_payments"
                value={
                    formSA100State.date_stopped_receiving_all_child_benefit_payments
                }
                onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="date"
                    bsSize="lg"
                />
            </InputForm>


        {
            (taxYear == 2021 || taxYear == 2022 || taxYear == 2023 )?

            <>
                <Title>Covid Incorrectly Claimed</Title>

                <InputForm>
                <Label>CJRS Charge</Label>
                <CurrencyInput
                    id="cjrs_charge"
                    name="cjrs_charge"
                    value={formSA100State.cjrs_charge}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
                    className="form-control-lg form-control"
                />
                </InputForm>

                {
                    (taxYear == 2021 || taxYear == 2022 )?
                    <InputForm>
                    <Label>SEISS Charge</Label>
                    <CurrencyInput
                        id="seiss_charge"
                        name="seiss_charge"
                        value={formSA100State.seiss_charge}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
                        className="form-control-lg form-control"
                    />
                    </InputForm>
                    :<></>
                }
            </>

        :<></>
        }

        <Title>Marriage Allowance</Title>
        <InputForm>
          <Label>Spouse First Name</Label>
          <Input
            id="spouse_first_name"
            name="spouse_first_name"
            value={formSA100State.spouse_first_name}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="text"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>Spouse Last Name</Label>
          <Input
            id="spouse_last_name"
            name="spouse_last_name"
            value={formSA100State.spouse_last_name}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="text"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>Spouse NINO</Label>
          <Input
            id="spouse_nino"
            name="spouse_nino"
            value={formSA100State.spouse_nino}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="text"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>Spouse Date of Birth</Label>
          <Input
            id="spouse_date_of_birth"
            name="spouse_date_of_birth"
            value={formSA100State.spouse_date_of_birth}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="date"
            bsSize="lg"
          />
        </InputForm>
        <InputForm>
          <Label>Date of Marriage Or Civil Partnership</Label>
          <Input
            id="date_of_marriage_or_civil_partnership"
            name="date_of_marriage_or_civil_partnership"
            value={formSA100State.date_of_marriage_or_civil_partnership}
            onChange={(e) =>
              onInputChange(e, formSA100State, setformSA100State)
            }
            type="date"
            bsSize="lg"
          />
        </InputForm>
        <Title>Marriage Allowance Transfered In</Title>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.marriage_allowance_transferred_in}
            checked={formSA100State.marriage_allowance_transferred_in}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="marriage_allowance_transferred_in"
            id="marriage_allowance_transferred_in"
          />
          <Label lg>Marriage Allowance Transfered In</Label>
        </CheckboxLabel>
        <Title>Marriage Allowance Transfered Out</Title>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA100State.marriage_allowance_transferred_out}
            checked={formSA100State.marriage_allowance_transferred_out}
            onChange={(e) =>
              onCheckboxChange(e, formSA100State, setformSA100State)
            }
            name="marriage_allowance_transferred_out"
            id="marriage_allowance_transferred_out"
          />
          <Label lg>Marriage Allowance Transfered Out</Label>
        </CheckboxLabel>
        <Title>Finishing Your Tax Return</Title>
        <InputForm>
          <Label>Tax Refunded Or Set Off</Label>
          <CurrencyInput
            id="tax_refunded_or_set_off"
            name="tax_refunded_or_set_off"
            value={formSA100State.tax_refunded_or_set_off}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
            className="form-control-lg form-control"
          />
        </InputForm>
        <Card>
          <Title>Not Paid Enough</Title>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.tax_owed_not_to_be_coded_out}
              checked={formSA100State.tax_owed_not_to_be_coded_out}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="tax_owed_not_to_be_coded_out"
              id="tax_owed_not_to_be_coded_out"
            />
            <Label lg>Tax Owed Not To Be Coded Out</Label>
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.non_paye_income_not_to_be_coded_out}
              checked={formSA100State.non_paye_income_not_to_be_coded_out}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="non_paye_income_not_to_be_coded_out"
              id="non_paye_income_not_to_be_coded_out"
            />
            <Label lg>Non PAYE Income Not To Be Coded Out</Label>
          </CheckboxLabel>
        </Card>
        <Card>
          <Title>Paid Too Much</Title>
          <Card>
            <Title>Payment Details</Title>
            <Card>
              <Subtitle>Bank Account Details</Subtitle>
              <InputForm>
                <Label>Bank Or Building Society Name</Label>
                <Input
                  id="bank_or_building_society_name"
                  name="bank_or_building_society_name"
                  value={formSA100State.bank_or_building_society_name}
                  onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                  }
                  type="text"
                  bsSize="lg"
                />
              </InputForm>
              <InputForm>
                <Label>Account Holder Or Nominee Name</Label>
                <Input
                  id="account_holder_or_nominee_name"
                  name="account_holder_or_nominee_name"
                  value={formSA100State.account_holder_or_nominee_name}
                  onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                  }
                  type="text"
                  bsSize="lg"
                />
              </InputForm>
              <InputForm>
                <Label>Branch Sort Code</Label>
                <Input
                  id="branch_sort_code"
                  name="branch_sort_code"
                  value={formSA100State.branch_sort_code}
                  onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                  }
                  type="text"
                  bsSize="lg"
                />
              </InputForm>
              <InputForm>
                <Label>Account Number</Label>
                <Input
                  id="account_number"
                  name="account_number"
                  value={formSA100State.account_number}
                  onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                  }
                  type="number"
                  bsSize="lg"
                />
              </InputForm>
              <InputForm>
                <Label>Building Society Reference Number</Label>
                <Input
                  id="building_society_reference_number"
                  name="building_society_reference_number"
                  value={formSA100State.building_society_reference_number}
                  onChange={(e) =>
                    onInputChange(e, formSA100State, setformSA100State)
                  }
                  type="number"
                  bsSize="lg"
                />
              </InputForm>
            </Card>
            <Card>
              <Title>Nominee Details</Title>
              <CheckboxLabel>
                <input
                  type="checkbox"
                  value={formSA100State.nominee_name_given}
                  checked={formSA100State.nominee_name_given}
                  onChange={(e) =>
                    onCheckboxChange(e, formSA100State, setformSA100State)
                  }
                  name="nominee_name_given"
                  id="nominee_name_given"
                />
                <Label lg>Nominee Name Given</Label>
              </CheckboxLabel>
              <CheckboxLabel>
                <input
                  type="checkbox"
                  value={formSA100State.nominee_is_tax_adviser}
                  checked={formSA100State.nominee_is_tax_adviser}
                  onChange={(e) =>
                    onCheckboxChange(e, formSA100State, setformSA100State)
                  }
                  name="nominee_is_tax_adviser"
                  id="nominee_is_tax_adviser"
                />
                <Label lg>Nominee Is Tax Adviser</Label>
              </CheckboxLabel>
              <Card>
                <Title>Nominee Address</Title>
                <InputForm>
                  <Label>Nominee Address Line 1</Label>
                  <Input
                    id="nominee_address_line_1"
                    name="nominee_address_line_1"
                    value={formSA100State.nominee_address_line_1}
                    onChange={(e) =>
                      onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="text"
                    bsSize="lg"
                  />
                </InputForm>
                <InputForm>
                  <Label>Nominee Address Line 2</Label>
                  <Input
                    id="nominee_address_line_2"
                    name="nominee_address_line_2"
                    value={formSA100State.nominee_address_line_2}
                    onChange={(e) =>
                      onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="text"
                    bsSize="lg"
                  />
                </InputForm>
                <InputForm>
                  <Label>Nominee Address Line 3</Label>
                  <Input
                    id="nominee_address_line_3"
                    name="nominee_address_line_3"
                    value={formSA100State.nominee_address_line_3}
                    onChange={(e) =>
                      onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="text"
                    bsSize="lg"
                  />
                </InputForm>
                <InputForm>
                  <Label>Nominee Address Short Line</Label>
                  <Input
                    id="nominee_address_short_line"
                    name="nominee_address_short_line"
                    value={formSA100State.nominee_address_short_line}
                    onChange={(e) =>
                      onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="text"
                    bsSize="lg"
                  />
                </InputForm>
                <InputForm>
                  <Label>Nominee Address Postcode</Label>
                  <Input
                    id="nominee_address_postcode"
                    name="nominee_address_postcode"
                    value={formSA100State.nominee_address_postcode}
                    onChange={(e) =>
                      onInputChange(e, formSA100State, setformSA100State)
                    }
                    type="text"
                    bsSize="lg"
                  />
                </InputForm>
              </Card>
            </Card>
          </Card>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.no_bank_or_building_society_account}
              checked={formSA100State.no_bank_or_building_society_account}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="no_bank_or_building_society_account"
              id="no_bank_or_building_society_account"
            />
            <Label lg>No Bank Or Building Society Account</Label>
          </CheckboxLabel>
        </Card>
        <Card>
          <Title>Tax Adviser</Title>
          <InputForm>
            <Label>Tax Adviser</Label>
            <Input
              id="tax_adviser"
              name="tax_adviser"
              value={formSA100State.tax_adviser}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>Tax Adviser Phone Number</Label>
            <Input
              id="tax_adviser_phone_number"
              name="tax_adviser_phone_number"
              value={formSA100State.tax_adviser_phone_number}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="number"
              bsSize="lg"
            />
          </InputForm>
          <Card>
            <Title>Tax Adviser Address</Title>
            <InputForm>
              <Label>Tax Adviser Address Line 1</Label>
              <Input
                id="tax_adviser_address_line_1"
                name="tax_adviser_address_line_1"
                value={formSA100State.tax_adviser_address_line_1}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Tax Adviser Address Line 2</Label>
              <Input
                id="tax_adviser_address_line_2"
                name="tax_adviser_address_line_2"
                value={formSA100State.tax_adviser_address_line_2}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Tax Adviser Address Line 3</Label>
              <Input
                id="tax_adviser_address_line_3"
                name="tax_adviser_address_line_3"
                value={formSA100State.tax_adviser_address_line_3}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Tax Adviser Address Short Line</Label>
              <Input
                id="tax_adviser_address_short_line"
                name="tax_adviser_address_short_line"
                value={formSA100State.tax_adviser_address_short_line}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Tax Adviser Address Postcode</Label>
              <Input
                id="tax_adviser_address_postcode"
                name="tax_adviser_address_postcode"
                value={formSA100State.tax_adviser_address_postcode}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
          </Card>
          <InputForm>
            <Label>Tax Advisers Reference</Label>
            <Input
              id="tax_advisers_reference"
              name="tax_advisers_reference"
              value={formSA100State.tax_advisers_reference}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
        </Card>
        <Card>
          <Title>Signinig Your Form</Title>
          <InputForm>
            <Label>Other Information Space</Label>
            <Input
              id="other_information_space"
              name="other_information_space"
              value={formSA100State.other_information_space}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="textarea"
              bsSize="lg"
            />
          </InputForm>
          <CheckboxLabel>
            <input
              type="checkbox"
              value={formSA100State.provisional_figures}
              checked={formSA100State.provisional_figures}
              onChange={(e) =>
                onCheckboxChange(e, formSA100State, setformSA100State)
              }
              name="provisional_figures"
              id="provisional_figures"
            />
            <Label lg>Provisional Figures</Label>
          </CheckboxLabel>

          {
            (taxYear == 2021 || taxYear == 2022 || taxYear == 2023 )?

                <CheckboxLabel>
                    <input
                    type="checkbox"
                    value={formSA100State.coronavirus_support_payments_included}
                    checked={formSA100State.coronavirus_support_payments_included}
                    onChange={(e) =>
                        onCheckboxChange(e, formSA100State, setformSA100State)
                    }
                    name="coronavirus_support_payments_included"
                    id="coronavirus_support_payments_included"
                    />
                    <Label lg>Coronavirus Support Payments Included</Label>
                </CheckboxLabel>
            :<></>
            }

          <InputForm>
            <Label>Capacity Of Person Signing</Label>
            <Input
              id="capacity_of_person_signing"
              name="capacity_of_person_signing"
              value={formSA100State.capacity_of_person_signing}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>Name Of Person Signing For</Label>
            <Input
              id="name_of_person_signed_for"
              name="name_of_person_signed_for"
              value={formSA100State.name_of_person_signed_for}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <InputForm>
            <Label>Name Of Person Signing</Label>
            <Input
              id="name_of_person_signing"
              name="name_of_person_signing"
              value={formSA100State.name_of_person_signing}
              onChange={(e) =>
                onInputChange(e, formSA100State, setformSA100State)
              }
              type="text"
              bsSize="lg"
            />
          </InputForm>
          <Card>
            <Title>Address Of Person Signing</Title>
            <InputForm>
              <Label>Address Of Person Signing Line 1</Label>
              <Input
                id="address_of_person_signing_line_1"
                name="address_of_person_signing_line_1"
                value={formSA100State.address_of_person_signing_line_1}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Address Of Person Signing Line 2</Label>
              <Input
                id="address_of_person_signing_line_2"
                name="address_of_person_signing_line_2"
                value={formSA100State.address_of_person_signing_line_2}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Address Of Person Signing Line 3</Label>
              <Input
                id="address_of_person_signing_line_3"
                name="address_of_person_signing_line_3"
                value={formSA100State.address_of_person_signing_line_3}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Address Of Person Signing Short Line</Label>
              <Input
                id="address_of_person_signing_short_line"
                name="address_of_person_signing_short_line"
                value={formSA100State.address_of_person_signing_short_line}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>Address Of Person Signing Postcode</Label>
              <Input
                id="address_of_person_signing_postcode"
                name="address_of_person_signing_postcode"
                value={formSA100State.address_of_person_signing_postcode}
                onChange={(e) =>
                  onInputChange(e, formSA100State, setformSA100State)
                }
                type="text"
                bsSize="lg"
              />
            </InputForm>
          </Card>
        </Card>
        <Title>Chargeable Event Gains</Title>
        <InputForm>
          <Label>Chargeable Event Gains</Label>
          <CurrencyInput
            id="chargeable_event_gains"
            name="chargeable_event_gains"
            value={formSA100State.chargeable_event_gains}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA100State, setformSA100State)}
            className="form-control-lg form-control"
          />
        </InputForm>
      </FormContainer>
      <SubmitButton >
        <Button color="primary" onClick={() => onSubmitForm()} disabled>
          Save
        </Button>
      </SubmitButton>
        </div>
    </div>

    </>
  );
};
