import { styled } from 'src/modules';

type FieldProps = {
  width: string;
};

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  button {
    margin-right: 20px;
  }
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: ${(props) => (props.width ? props.width : '100%')};

  label {
    font-weight: 700;
    font-size: 14px;
  }

  span {
    color: ${({ theme }) => theme.colors.red.punch};
  }
`;
