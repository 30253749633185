import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { onInputChange, onCheckboxChange, onCurrencyInputChange } from '../../utils';

import { createSA102, getSA102, updateSA102 } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify, CustomInput } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  CheckboxLabel,
  FormContainer,
  InputForm,
  Subtitle,
  Title,
  TextBody,
  SubmitButton,
} from '../../styles';

import CurrencyInput from 'react-currency-masked-input';

import { FormEmployment } from '../../FormState';

type Props = {
  sa102formId: number;
  taxYear: number,
  taxReturnId: number,
  view:boolean,
  onClick?: ( e: any) => void,
};

export const SA102 = ({
  sa102formId,
  taxYear,
  taxReturnId,
  view,
  onClick
}: Props) => {
  const [formSA102State, setformSA102State] = useState<FormEmploymentType>(FormEmployment);

  const { data, isLoading, refetch } = useQuery(
    'getSA102',
    () => getSA102(sa102formId),
    {
      enabled: false,
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (sa102formId > 0) {
      refetch();
    }
  }, [sa102formId]);

  const { mutate: mutationCreate102 } = useMutation(createSA102, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  const { mutate: mutationUpdateSA102 } = useMutation(updateSA102, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformSA102State(data.data);
      } else {
        setformSA102State(FormEmployment);
      }
    }
  }, [isLoading]);

  const onSubmitForm = () => {
    if (sa102formId === 0) {
      const formData = {
          ...formSA102State,
          tax_return_id: taxReturnId,
      };

      mutationCreate102(formData);
    } else {
      const formData = {
          ...formSA102State,
          sa102_form_id: sa102formId,
      };

      mutationUpdateSA102(formData);
    }
  };

  return (
    <>
        <div className="row">
            <div className="col-lg-6 col-md-12">    
            <Title>SA102
                {(view) ? '- View' : ''}
            </Title>
            <Subtitle>
                * Hidden input: tax_return_id for create form / sa102_form_id for update
                form.
            </Subtitle>
            <FormContainer>
                <Title>Employment</Title>
                <InputForm>
                <Label>Pay From Employment</Label>
                <CurrencyInput
                    value={formSA102State.pay_from_employment}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="pay_from_employment"
                    name="pay_from_employment"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Tax Taken Off Pay</Label>
                <CurrencyInput
                    value={formSA102State.tax_taken_off_pay}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="tax_taken_off_pay"
                    name="tax_taken_off_pay"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Total Class 1 NICable Earnings</Label>
                <CurrencyInput
                    value={formSA102State.total_class_1_nicable_earnings}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="total_class_1_nicable_earnings"
                    name="total_class_1_nicable_earnings"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Tips And Other Payments</Label>
                <CurrencyInput
                    value={formSA102State.tips_and_other_payments}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="tips_and_other_payments"
                    name="tips_and_other_payments"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Employer PAYE Reference (required)</Label>
                <Input
                    type="text"
                    bsSize="lg"
                    value={formSA102State.employer_paye_reference}
                    onChange={(e) =>
                    onInputChange(e, formSA102State, setformSA102State)
                    }
                    id="employer_paye_reference"
                    name="employer_paye_reference"
                />
                </InputForm>
                <InputForm>
                <Label>Employer's Name</Label>
                <Input
                    type="text"
                    bsSize="lg"
                    value={formSA102State.employers_name}
                    onChange={(e) =>
                    onInputChange(e, formSA102State, setformSA102State)
                    }
                    id="employers_name"
                    name="employers_name"
                />
                </InputForm>
                <TextBody>Company Director (required)</TextBody>
                <CheckboxLabel>
                <CustomInput
                    type="select"
                    id="company_director"
                    name="company_director"
                    value={formSA102State.company_director}
                    onChange={(e) =>
                    setformSA102State({
                        ...formSA102State,
                        company_director: e.target.value,
                    })
                    }
                >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </CustomInput>
                </CheckboxLabel>
                <InputForm>
                <Label>Date Ceased Being A Director</Label>
                <Input
                    id="date_ceased_being_a_director"
                    name="date_ceased_being_a_director"
                    value={formSA102State.date_ceased_being_a_director}
                    onChange={(e) =>
                    onInputChange(e, formSA102State, setformSA102State)
                    }
                    type="date"
                    bsSize="lg"
                />
                </InputForm>

                <CheckboxLabel>
                <input
                    type="checkbox"
                    value={formSA102State.close_company}
                    checked={formSA102State.close_company}
                    onChange={(e) =>
                    onCheckboxChange(e, formSA102State, setformSA102State)
                    }
                    name="close_company"
                    id="close_company"
                />
                <Label lg>Close Company</Label>
                </CheckboxLabel>

                {
                    ( taxYear == 2021 || taxYear == 2022 || taxYear == 2023) ?
                    <CheckboxLabel>
                        <input
                        type="checkbox"
                        value={formSA102State.off_payroll_working}
                        checked={formSA102State.off_payroll_working}
                        onChange={(e) =>
                            onCheckboxChange(e, formSA102State, setformSA102State)
                        }
                        name="off_payroll_working"
                        id="off_payroll_working"
                        />
                        <Label lg>Off Payroll Working</Label>
                    </CheckboxLabel>
                    : <></>
                }

                {
                    ( taxYear == 2020 || taxYear == 2021) ? 
                    <CheckboxLabel>
                        <input
                        type="checkbox"
                        value={formSA102State.disguised_remuneration_included_in_pay}
                        checked={formSA102State.disguised_remuneration_included_in_pay}
                        onChange={(e) =>
                            onCheckboxChange(e, formSA102State, setformSA102State)
                        }
                        name="disguised_remuneration_included_in_pay"
                        id="disguised_remuneration_included_in_pay"
                        />
                        <Label lg>Disguised Remuneration Included In Pay</Label>
                    </CheckboxLabel>             
                    : <></>
                }



                <Title>Benefits</Title>
                <InputForm>
                <Label>Company Cars And Vans Benefit</Label>
                <CurrencyInput
                    value={formSA102State.company_cars_and_vans_benefit}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="company_cars_and_vans_benefit"
                    name="company_cars_and_vans_benefit"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Fuel For Cars And Vans</Label>
                <CurrencyInput
                    value={formSA102State.fuel_for_cars_and_vans}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="fuel_for_cars_and_vans"
                    name="fuel_for_cars_and_vans"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Private Medical Dental Insurance</Label>
                <CurrencyInput
                    value={formSA102State.private_medical_dental_insurance}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="private_medical_dental_insurance"
                    name="private_medical_dental_insurance"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Vouchers Credit Cards Excess Mileage Allowance</Label>
                <CurrencyInput
                    value={
                    formSA102State.vouchers_credit_cards_excess_mileage_allowance}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="vouchers_credit_cards_excess_mileage_allowance"
                    name="vouchers_credit_cards_excess_mileage_allowance"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Goods Etc Provided By Employer</Label>
                <CurrencyInput
                    value={formSA102State.goods_etc_provided_by_employer}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="goods_etc_provided_by_employer"
                    name="goods_etc_provided_by_employer"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Accommodation Provided By Employer</Label>
                <CurrencyInput
                    value={formSA102State.accommodation_provided_by_employer}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="accommodation_provided_by_employer"
                    name="accommodation_provided_by_employer"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Other Benefits</Label>
                <CurrencyInput
                    value={formSA102State.other_benefits}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="other_benefits"
                    name="other_benefits"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Expenses Payments Received</Label>
                <CurrencyInput
                    value={formSA102State.expenses_payments_received}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="expenses_payments_received"
                    name="expenses_payments_received"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <Title>Expenses</Title>
                <InputForm>
                <Label>Business Travel And Subsistence</Label>
                <CurrencyInput
                    value={formSA102State.business_travel_and_subsistence}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="business_travel_and_subsistence"
                    name="business_travel_and_subsistence"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Fixed Expenses Deductions</Label>
                <CurrencyInput
                    value={formSA102State.fixed_expenses_deductions}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="fixed_expenses_deductions"
                    name="fixed_expenses_deductions"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Professional Fees And Subscriptions</Label>
                <CurrencyInput
                    value={formSA102State.professional_fees_and_subscriptions}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="professional_fees_and_subscriptions"
                    name="professional_fees_and_subscriptions"
                    className="form-control-lg form-control"
                />
                </InputForm>
                <InputForm>
                <Label>Other Expenses And Capital Allowances</Label>
                <CurrencyInput
                    value={formSA102State.other_expenses_and_capital_allowances}
                    onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA102State, setformSA102State)}
                    id="other_expenses_and_capital_allowances"
                    name="other_expenses_and_capital_allowances"
                    className="form-control-lg form-control"
                />
                </InputForm>
            </FormContainer>

            {(view) ?
            <></> 
            : <SubmitButton>
                    <Button color="primary" onClick={() => onSubmitForm()} disabled>
                    Save
                    </Button>
                </SubmitButton>
            }
      
            </div>    
        </div>   

    </>
  );
};
