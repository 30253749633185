import React, { useState } from 'react';

import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TableRow } from 'src/components';
import { Col, Input, Row, Button } from 'src/modules';

import ButtonIcon from '../ButtonIcon';

type Props = {
  id: number;
  title: string;
  dbKey: string;
  value?: string;
  onEdit: any;
};

const SettingsRow: React.FC<Props> = ({ id, title, dbKey, value, onEdit }) => {
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState({
    title,
    dbKey,
    value,
  });

  const handleChange = (key: string, value: string) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleEdit = () => {
    onEdit({
      id,
      field_name: form.title,
      key_text: form.dbKey,
      value: form.value,
    });
    setEditMode(false);
  };

  return (
    <TableRow className="align-items-center">
      <Col>
        <Input
          type="text"
          name="title_settings"
          value={form.title}
          onChange={(e) => handleChange('title', e.target.value)}
          disabled={!editMode}
        />
      </Col>
      <Col>
        <Input
          type="text"
          name="dbkey_settings"
          value={form.dbKey}
          onChange={(e) => handleChange('dbKey', e.target.value)}
          disabled={!editMode}
        />
      </Col>
      <Col>
        <Input
          type="text"
          name="value_settings"
          value={form.value}
          onChange={(e) => handleChange('value', e.target.value)}
          disabled={!editMode}
        />
      </Col>
      <Col xs="2">
        {editMode ? (
          <Row className="d-flex justify-content-center">
            <Button
              type="button"
              onClick={handleEdit}
              color="transparent"
              className="btn btn-outline-success rounded-circle btn-icon mr-1 ml-1"
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              type="button"
              onClick={() => setEditMode(false)}
              color="transparent"
              className="btn btn-outline-danger rounded-circle btn-icon mr-1 ml-1"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Row>
        ) : (
          <Row className="d-flex justify-content-center">
            <ButtonIcon
              onClick={() => setEditMode(true)}
              color="primary"
              className="table-button"
            >
              <FontAwesomeIcon icon={faPen} />
            </ButtonIcon>
          </Row>
        )}
      </Col>
    </TableRow>
  );
};

export default SettingsRow;
