import React from 'react';

import { IconContent } from './styles';

type ISizes = 'xl' | 'lg' | 'md' | 'sm';

export type IName =
  | 'telegram'
  | 'whatsapp'
  | 'chat'
  | 'sms'
  | 'pencil'
  | 'clip'
  | 'emoji'
  | 'filter'
  | 'sent'
  | 'read'
  | 'search'
  | 'video'
  | 'phone'
  | 'white-phone'
  | 'not-sent'
  | 'file'
  | 'close'
  | 'arrow-left'
  | 'arrow-right'
  | 'in-app'
  | 'microphone'
  | 'muted'
  | 'dots'
  | 'horizontal-phone'
  | 'white-in-app'
  | 'white-whatsapp'
  | 'white-sms'
  | 'white-pencil';

interface Props {
  size?: ISizes;
  name: IName;
  isActive?: boolean;
  noResize?: boolean;
  onClick?: () => void;
}

interface Icons {
  [key: string]: string;
}

const icons: Icons = {
  telegram: '/icons/message-center/telegram.svg',
  sms: '/icons/message-center/sms.svg',
  'white-sms': '/icons/message-center/white-sms.svg',
  chat: '/icons/message-center/chat.svg',
  whatsapp: '/icons/message-center/whatsapp.svg',
  'white-whatsapp': '/icons/message-center/white-whatsapp.svg',
  pencil: '/icons/message-center/pencil.svg',
  'white-pencil': '/icons/message-center/white-pencil.svg',
  clip: '/icons/message-center/clip.svg',
  emoji: '/icons/message-center/emoji.svg',
  filter: '/icons/filter.svg',
  'white-filter': '/icons/message-center/white-filter.svg',
  sent: '/icons/message-center/sent.svg',
  'white-sent': '/icons/message-center/white-sent.svg',
  read: '/icons/message-center/read.svg',
  phone: '/icons/message-center/phone.svg',
  'white-phone': '/icons/message-center/white-phone.svg',
  video: '/icons/message-center/video.svg',
  search: '/icons/message-center/search.svg',
  'not-sent': '/icons/message-center/not-sent.svg',
  file: '/icons/message-center/file.svg',
  close: '/icons/message-center/close.svg',
  'white-close': '/icons/message-center/white-close.svg',
  'arrow-left': '/icons/message-center/arrow-left.svg',
  'arrow-right': '/icons/message-center/arrow-right.svg',
  'in-app': '/icons/message-center/chat.svg',
  'white-in-app': '/icons/message-center/white-chat.svg',
  microphone: '/icons/message-center/microphone.svg',
  muted: '/icons/message-center/muted.svg',
  dots: '/icons/message-center/dots.svg',
  'horizontal-phone': '/icons/message-center/horizontal-phone.svg',
  alert: '/icons/alert.png',
};

const sizes = {
  sm: 16,
  md: 22,
  lg: 32,
  xl: 45,
};

export default function Icon({
  size = 'md',
  name,
  isActive = true,
  noResize = false,
  onClick,
}: Props) {
  return (
    <IconContent
      src={icons[name]}
      size={sizes[size]}
      isActive={isActive}
      noResize={noResize}
      onClick={() => onClick?.()}
    />
  );
}
