import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import {
  deleteQuickReply,
  editQuickReply,
  getQuickReplies,
  postQuickReply,
} from 'src/api/message-center';
import {
  Layout,
  TableHead,
  CustomPagination,
  AddQuickReply,
  QuickRepliesRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Container, Row, Col, Button, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

const QuickReplies: React.FC = () => {
  const [repliesList, setRepliesList] = useState([]);
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState(null);
  const [showAddQuickReply, setShowAddQuickReply] = useState(false);
  const [replyValue, setReplyValue] = useState('');

  const { isLoading, refetch } = useQuery('quick-reply', getQuickReplies, {
    onSuccess: (result) => {
      setRepliesList(result.data);
      setHasPagination(result.last_page > 1);
      setPagRes(result);
    },
    onError: ({ message }) => {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutatePostQuickReply } = useMutation(
    () => postQuickReply(replyValue),
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);
        refetch();
      },
      onError({ message }) {
        Toastify.toast.error(message);
      },
    },
  );

  const { mutate: mutateEditQuickReply } = useMutation(editQuickReply, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ message }) {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutateDeleteQuickReply } = useMutation(deleteQuickReply, {
    onSuccess() {
      refetch();
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ message }) {
      Toastify.toast.error(message);
    },
  });

  const onCreateReply = () => {
    mutatePostQuickReply();
  };

  const onUpdateReply = (id: number, title: string) => {
    mutateEditQuickReply({ id, title });
  };

  const onDeleteReply = (id: number) => {
    mutateDeleteQuickReply({ id });
  };

  const onChangeStatus = (item: any) => {
    const replyUpdate: [] = [];

    repliesList.map((reply: any) => {
      if (reply.id === item.id) {
        reply.status = reply.status === 'Enable' ? 'Disable' : 'Enable';
      }

      replyUpdate.push(reply);
    });

    console.log('repliesList', replyUpdate);

    setRepliesList(replyUpdate);
  };

  const onChangePage = () => {};

  return (
    <Layout>
      <Dashboard title="Quick Replies">
        <Row className="justify-content-end mb-4">
          <Col xs="12">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setShowAddQuickReply(true);
              }}
              outline
            >
              Add New Quick Reply
            </Button>
          </Col>
        </Row>

        {showAddQuickReply && (
          <AddQuickReply
            onClose={() => {
              setShowAddQuickReply(false);
            }}
            createReply={() => onCreateReply()}
            setReplyValue={setReplyValue}
            replyValue={replyValue}
          />
        )}

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Question</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {isLoading && <Loading />}

            {repliesList.map((item) => (
              <QuickRepliesRow
                key={item.id}
                active={item.status === 'Enable'}
                value={item.id}
                onChangeStatus={() => onChangeStatus(item)}
                reply={item.title}
                updateReply={(reply) => onUpdateReply(item.id, reply)}
                deleteReply={() => onDeleteReply(item.id)}
              />
            ))}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => onChangePage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default QuickReplies;
