import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  column-gap: 130px;
`;

export const NotesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  > div {
    height: 116px;
    width: 100%;
    max-width: 446px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
`;

export const SplitSection = styled.div``;

export const FooterTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
  margin-left: 14px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .split-range-label {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    right: 5px;
  }
`;

export const SliptRange = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 366px;
  height: 2px;
  background: #d8d8d8;
  border-radius: 5px;

  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border: 2px solid #191424;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
    background-color: #fff;
    background-image: url('/icons/code-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const PrevNextFields = styled.div`
  display: flex;
  column-gap: 20px;
  margin: 0px 20px 30px;
`;

export const OtherInformations = styled.div`
  padding: 0 15px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const IncomeHeaderImage = styled.img`
  width: 70px;
  border-radius: 5px;
`;

export const StartDateLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
`;

export const HeaderSelectSpan = styled.span`
  position: relative;
  top: 5px;
`;

export const IncomeHeaderButtonsSection = styled.div`
  display: flex;
`;

export const AddCancelSection = styled.div`
  display: flex;
  margin-left: 15px;
  width: 100%;
  margin-top: 50px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #f31b1b;
  height: 38px;
  border: none;
  border-radius: 48px;
  padding: 0 24px;
  color: '#fff';
  margin-left: 20px;

  > span {
    font-weight: 600;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

export const CisSection = styled.div`
  margin-top: 25px;
`;

export const AddProofButton = styled.label`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 18px;
  margin-bottom: 40px;
  max-width: 200px;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StartDateSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputDate = styled.input`
  border: none;
  background: transparent;
`;
