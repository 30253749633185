import React from "react";

const Loading: React.FC = () => (
  <div className="overflow-hidden">
    <div className="w-100 mw-100 loading">
      <div className="loading-bar"></div>
    </div>
  </div>
);

export default Loading;
