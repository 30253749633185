import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'src/hooks';
import { TTaxAssistantRole } from 'src/models/ITaxAssistant';
import { Toastify } from 'src/modules';
import { Storage } from 'src/services';
import { ERRORS } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import {
  Wrapper,
  StyledInput,
  StyledRow,
  Label,
  StyledSelect,
  ImageInput,
  StyledTextArea,
  Submit,
} from './styles';

type Props = {
  initialData: AddAdminData;
  onSubmit: (data: AddAdminData) => void;
  loading?: boolean;
  isEdit?: boolean;
};

const Form = ({ onSubmit, initialData, loading, isEdit }: Props) => {
  const { form, handleChange, hasChanged, setForm } =
    useForm<AddAdminData>(initialData);

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) handleChange('image', file);
  };

  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (isEdit) {
        onSubmit(form);
      } else if (form?.password === form?.confirmPassword) {
        onSubmit(form);
      } else if (form?.password !== form?.confirmPassword) {
        Toastify.toast.error(ERRORS.CONFIRM_PASSWORD);
      }
    },
    [form],
  );

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const onPageLoad = async () => {
    const user = await Storage.getUser();

    const permissions = handleSuperAdminRole(user?.role_id);
    setIsSuperAdmin(permissions);
  };

  useEffect(() => {
    if (isEdit) setForm(initialData);
  }, [initialData]);

  useEffect(() => {
    onPageLoad();
  }, []);

  return (
    <Wrapper className="admins-form">
      <StyledRow>
        <Label htmlFor="role">Role:</Label>
        <StyledSelect
          name="role"
          id="role"
          value={form?.role_id?.toString()}
          onChange={(e) => handleChange('role_id', e.target.value)}
        >
          <option value={TTaxAssistantRole.TAX_ASSISTANT}>Assistant</option>
          <option value={TTaxAssistantRole.ADMIN}>Admin</option>
          <option value={TTaxAssistantRole.SUPER_ADMIN}>Super Admin</option>
          <option value={TTaxAssistantRole.MARKETING}>Marketing</option>
          <option value={TTaxAssistantRole.DEVS}>Devs</option>
          <option value={TTaxAssistantRole.MANAGER}>Manager</option>
        </StyledSelect>
      </StyledRow>
      <StyledRow>
        <Label htmlFor="name">Name:</Label>
        <StyledInput
          name="name"
          id="name"
          type="text"
          value={form?.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
      </StyledRow>
      {(!isEdit || isSuperAdmin) && (
        <StyledRow>
          <Label htmlFor="email">E-mail:</Label>
          <StyledInput
            name="email"
            id="email"
            type="email"
            value={form?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </StyledRow>
      )}
      <StyledRow>
        <Label htmlFor="image">Image:</Label>
        <ImageInput
          name="image"
          id="image"
          type="file"
          onChange={imageHandle}
        />
      </StyledRow>
      <StyledRow>
        <Label htmlFor="phone">Phone:</Label>
        <StyledInput
          name="phone"
          id="phone"
          value={form?.phone_number}
          onChange={(e) => handleChange('phone_number', e.target.value)}
        />
      </StyledRow>
      <StyledRow>
        <Label htmlFor="whatsapp">Whatsapp:</Label>
        <StyledInput
          name="phone"
          id="whatsapp"
          value={form?.whatsapp_no}
          onChange={(e) => handleChange('whatsapp_no', e.target.value)}
        />
      </StyledRow>
      <StyledRow>
        <Label htmlFor="address">Address:</Label>
        <StyledTextArea
          name="address"
          id="address"
          rows={3}
          value={form?.address}
          onChange={(e) => handleChange('address', e.target.value)}
        ></StyledTextArea>
      </StyledRow>
      <StyledRow>
        <Label htmlFor="latitude">Latitude:</Label>
        <StyledInput
          name="latitude"
          id="latitude"
          type="number"
          value={form?.latitude}
          onChange={(e) => handleChange('latitude', e.target.value)}
        />
      </StyledRow>
      <StyledRow>
        <Label htmlFor="longitude">Longitude:</Label>
        <StyledInput
          name="longitude"
          id="longitude"
          type="number"
          value={form?.longitude}
          onChange={(e) => handleChange('longitude', e.target.value)}
        />
      </StyledRow>
      <StyledRow>
        <Label htmlFor="note">Note:</Label>
        <StyledTextArea
          name="note"
          id="note"
          rows={3}
          value={form?.note}
          onChange={(e) => handleChange('note', e.target.value)}
        ></StyledTextArea>
      </StyledRow>
      <StyledRow>
        <Label htmlFor="password">Password:</Label>
        <StyledInput
          name="password"
          id="password"
          type="password"
          autoComplete="new-password"
          value={form?.password}
          onChange={(e) => handleChange('password', e.target.value)}
        />
      </StyledRow>
      {!isEdit && (
        <StyledRow>
          <Label htmlFor="password">Confirm Password:</Label>
          <StyledInput
            name="password"
            id="password"
            type="password"
            autoComplete="new-password"
            value={form?.confirmPassword}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
          />
        </StyledRow>
      )}
      <Submit disabled={!hasChanged || loading} onClick={handleSubmit}>
        Submit
      </Submit>
    </Wrapper>
  );
};

export default Form;
