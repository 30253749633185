import { window } from 'browser-monads';

import Keys from './keys';

const Storage: Storage = window.localStorage;

export default class StorageService {
  static clearWholeStorage(): void {
    Storage.clear();
  }

  static async setToken(token: string): Promise<void> {
    const item = JSON.stringify(token);
    await Storage.setItem(Keys.TOKEN, item);
  }

  static getToken(): string {
    const value = Storage.getItem(Keys.TOKEN);
    return value ? JSON.parse(value) : '';
  }

  static setRedirectPath(url: string): void {
    const item = JSON.stringify(url);
    Storage.setItem(Keys.URL_PATH, item);
  }

  static getRedirectPath(): string {
    const value = Storage.getItem(Keys.URL_PATH);
    return value ? JSON.parse(value) : '';
  }

  static setTheme(theme: string): void {
    const item = JSON.stringify(theme);
    Storage.setItem(Keys.THEME, item);
  }

  static getTheme(): string {
    const value = Storage.getItem(Keys.THEME);
    return value ? JSON.parse(value) : '';
  }

  static clearRedirectPath(): void {
    Storage.removeItem(Keys.URL_PATH);
  }

  static setUser(user: User): void {
    const item = JSON.stringify(user);
    Storage.setItem(Keys.USER, item);
  }

  static getUser(): User {
    const value = Storage.getItem(Keys.USER);
    return value ? JSON.parse(value) : '';
  }

  static setRequestTime(time: number): void {
    const item = JSON.stringify(time);
    Storage.setItem(Keys.LAST_USER_REQUEST_TIME, item);
  }

  static getRequestTime(): number {
    const value = Storage.getItem(Keys.LAST_USER_REQUEST_TIME);
    return value ? JSON.parse(value) : 0;
  }

  static setItem = async (key: string, value: any) => {
    const item = JSON.stringify(value);
    await Storage.setItem(key, item);
  };

  static getItem = async (key: string) => {
    const item = await Storage.getItem(key);
    if (!item) return;
    return JSON.parse(item);
  };
}
