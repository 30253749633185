import { styled } from 'src/modules';

export const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const CharactersLeft = styled.p`
  margin-top: 12px;
  text-align: right;
`;
