import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useQuery } from 'react-query';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { getDeletedAccountByName } from 'src/api/deleted-accounts.api';
import { Layout, TableHead, CustomPagination } from 'src/components';
import { SearchBar } from 'src/components/common';
import { Loading } from 'src/components/ui';
import UserAccountDeletesRow from 'src/components/UserAccountDeletesRow';
import { useYear } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import {
  Card,
  Col,
  Container,
  Alert,
  useQueryParam,
  NumberParam,
} from 'src/modules';
import store from 'src/stores';
import {
  SearchBarContainer,
  SearchBarTitle,
} from 'src/styles/pages/user-accounts-delete/styles';

const UserAccountDeletes: React.FC = () => {
  // Mobx store
  const deletedAccounts = store.deletedAccounts;

  const [year] = useYear();
  console.log('year ', year);

  // States
  const [searchSubmited, setSearchSubmited] = useState(false);
  const [deletedAccountSelected, setDeletedAccountSelected] = useState([]);
  const [deletedAccountList, setDeletedAccountList] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pagRes, setPagRes] = useState({
    next_page: 0,
    total_pages: 0,
    next_page_url: '',
    previous_page_url: '',
  });

  const fetchDeletedAccounts = async (
    page: number,
    tax_year: number,
  ): Promise<void> => {
    try {
      setLoading(true);
      await deletedAccounts.getDeletedAccounts(page, tax_year);
      deletedAccounts.deletedAccounts;
      setDeletedAccountList(deletedAccounts.deletedAccounts.data);

      setPagRes({
        next_page: deletedAccounts.deletedAccounts.next_page,
        total_pages: deletedAccounts.deletedAccounts.total_pages,
        next_page_url: '',
        previous_page_url: '',
      });

      setTotalPages(deletedAccounts.deletedAccounts.total_pages);
    } catch (error: any) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const changePage = (pageNumber: number): void => {
    fetchDeletedAccounts(pageNumber, year);

    setPagRes({
      next_page: pageNumber + 1,
      total_pages: totalPages,
      next_page_url: '',
      previous_page_url: '',
    });
  };

  useEffect(() => {
    fetchDeletedAccounts(1, year);
  }, []);

  const { isFetching, refetch } = useQuery(
    ['deleted-account', searchSubmited],
    () => getDeletedAccountByName(query),
    {
      enabled: false,
      onSuccess: (response) => {
        setDeletedAccountList(response.data);
        setSearchSubmited(false);
        setDeletedAccountSelected([]);
      },
    },
  );

  useEffect(() => {
    if (searchSubmited) {
      refetch();
    }
  }, [searchSubmited]);

  const handleSelect = (id: number) => {
    const accountSelected = deletedAccounts.deletedAccounts.data.filter(
      (item: any) => item.id === id,
    );

    setDeletedAccountSelected(accountSelected);
  };

  return (
    <Layout>
      <Dashboard title="Deleted Accounts">
        {apiError && <Alert color="danger">Error: {apiError.message}</Alert>}

        <SearchBarContainer>
          <SearchBarTitle>Search for deleted account: </SearchBarTitle>
          <SearchBar
            data={deletedAccounts.deletedAccounts.data.map((item: any) => ({
              ...item,
              name: item.full_name,
            }))}
            onSubmit={() => setSearchSubmited(true)}
            onChange={setQuery}
            onSelect={handleSelect}
            isLoading={loading || isFetching}
            placeholder="Name, email or UTR"
            icon={faChevronRight}
          />
        </SearchBarContainer>
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="5">Name</Col>
              <Col xs="3">Status</Col>
              {/* <Col xs="3">Requested Date</Col> */}
              <Col xs="4">Delete Date</Col>
            </TableHead>
            {(loading || isFetching) && <Loading />}
            {deletedAccountSelected.length > 0
              ? deletedAccountSelected.map((item: any, index) => (
                  <UserAccountDeletesRow
                    key={`deleted-account-${index}`}
                    name={`${item.full_name}`}
                    status={item.status}
                    deleteDate={moment(item.approve_date).format(
                      'Do MMM, YYYY',
                    )}
                    id={item.id}
                    fetchDeletedAccounts={fetchDeletedAccounts}
                    /* requestedDate={moment(created_date).format(
                        'Do MMM, YYYY',
                      )} */
                  />
                ))
              : !loading &&
                deletedAccountList.map((item: any, index: number) => (
                  <UserAccountDeletesRow
                    key={`deleted-account-${index}`}
                    name={`${item.full_name}`}
                    status={item.status}
                    deleteDate={moment(item.approve_date).format(
                      'Do MMM, YYYY',
                    )}
                    id={item.id}
                    fetchDeletedAccounts={fetchDeletedAccounts}
                    /* requestedDate={moment(created_date).format(
                        'Do MMM, YYYY',
                      )} */
                  />
                ))}

            {(deletedAccountSelected.length > 0 ||
              deletedAccountList.length > 0) && (
              <CustomPagination res={pagRes} changePage={changePage} />
            )}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default UserAccountDeletes;
