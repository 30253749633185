import React, { useState } from 'react';

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { XCircle } from 'react-feather';
import { useMutation } from 'react-query';

import { updateIncomeYear } from 'src/api/message-center';
import Icon from 'src/components/Icon';
import { Toastify } from 'src/modules';
import { getYearsList, SUCCESS } from 'src/utils';

import { getFileName } from '../../utils';

import {
  ActionButton,
  Back,
  Backdrop,
  ButtonsContent,
  CloseButtom,
  Container,
  Content,
  FileName,
  ImgPreview,
  Next,
  FilePreview,
  SlideContent,
  CarrousselContent,
  BottomContent,
  YearsContent,
  YearsContentText,
  OptionsContent,
  OptionButton,
  CloseYearsContent,
  YearsContentHeader,
} from './styles';
import { Link } from 'gatsby';

interface IProps {
  isOpen: boolean;
  fileType: string;
  contentWidth: number;
  currentFileIndex: number;
  setIsOpen: (isOpen: boolean) => void;
  photosList: FilesOrPhotosType[];
  filesList: FilesOrPhotosType[];
  itemSelected: FilesOrPhotosType;
}

export default function DocumentPreview({
  isOpen,
  fileType,
  contentWidth,
  currentFileIndex,
  photosList,
  filesList,
  itemSelected,
  setIsOpen,
}: IProps) {
  if (!isOpen) return null;

  const [currentSlide, setCurrentSlide] = useState(currentFileIndex);
  const [showYearsContent, setShowYearsContent] = useState(false);
  const [yearSelected, setYearSelected] = useState(0);

  const filesData = fileType === 'Pdf' ? filesList : photosList;
  const yearsList = getYearsList();

  const { mutate: mutateUpdateIncomeYear } = useMutation(updateIncomeYear, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      setShowYearsContent(false);
    },
    onError({ message }) {
      Toastify.toast.error(message);
    },
  });

  function handleCurrentSlide(direction: string) {
    if (direction === 'next') {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }

  const onAssociateIncomeToYear = () => {
    if (yearSelected !== 0)
      mutateUpdateIncomeYear({ id: itemSelected.id, taxYear: yearSelected });
    else Toastify.toast.info('Select an year');
  };

  return (
    <Container isOpen={isOpen}>
      <Backdrop />
      <Content>
        <CloseButtom onClick={() => setIsOpen(false)}>
          <XCircle size={26} />
        </CloseButtom>
        <CarrousselContent>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={filesData.length}
            visibleSlides={1}
            currentSlide={currentFileIndex}
            dragEnabled={false}
          >
            <Slider>
              {filesData.map((file, index) => (
                <Slide
                  key={String(index)}
                  index={index}
                  style={{ width: contentWidth }}
                >
                  <SlideContent>
                    {fileType === 'Photo' ? (
                      <ImgPreview src={file.path} />
                    ) : (
                      <FilePreview src={file.path} />
                    )}
                  </SlideContent>
                </Slide>
              ))}
            </Slider>
            <Back onClick={() => handleCurrentSlide('back')}>
              <Icon name="arrow-left" />
            </Back>
            <Next onClick={() => handleCurrentSlide('next')}>
              <Icon name="arrow-right" />
            </Next>
          </CarouselProvider>
        </CarrousselContent>
        <BottomContent>
          <FileName contentWidth={contentWidth}>
            {getFileName(filesData[currentSlide].path)}
          </FileName>
          <ButtonsContent>
            <ActionButton onClick={() => setShowYearsContent(true)}>
              2020/21
            </ActionButton>
            <ActionButton onClick={() => onAssociateIncomeToYear()}>
              Income
            </ActionButton>
            <a href={itemSelected.path} target="_blank" rel="noreferrer">
              <ActionButton>Download</ActionButton>
            </a>
          </ButtonsContent>
          <YearsContent isOpen={showYearsContent}>
            <YearsContentHeader>
              <YearsContentText>Send Tax Year</YearsContentText>
              <CloseYearsContent onClick={() => setShowYearsContent(false)}>
                X
              </CloseYearsContent>
            </YearsContentHeader>
            <OptionsContent>
              {yearsList.map((year) => (
                <OptionButton key={year} onClick={() => setYearSelected(year)}>
                  {year}
                </OptionButton>
              ))}
            </OptionsContent>
          </YearsContent>
        </BottomContent>
      </Content>
    </Container>
  );
}
