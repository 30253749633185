import { QueryFunctionContext } from 'react-query';

import request from '../request';

type Dashboard = {
  agentfeeDownloaded: DashboardData[];
  signups: DashboardData[];
  submitted: DashboardData[];
  submittedNotPaid: DashboardData[];
};

export const fetchDashboard = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string, string, Filters['period']]
>): Promise<Dashboard> => {
  const [, startDate, endDate, period] = queryKey;

  const { data: signups } = await request.get(
    `/dashboard/how-you-here/sign-ups?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: agentfeeDownloaded } = await request.get(
    `/dashboard/amounts/agentfee-downloaded?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: submitted } = await request.get(
    `/dashboard/hmrc-paid?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: submittedNotPaid } = await request.get(
    `/dashboard/hmrc-payout?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  return {
    signups: signups?.data,
    agentfeeDownloaded: agentfeeDownloaded?.data,
    submitted: submitted?.data,
    submittedNotPaid: submittedNotPaid?.data,
  };
};

export const getStaffDashboard = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string, string, Filters['period']]
>): Promise<DashboardStaffData> => {
  const [, startDate, endDate, period] = queryKey;

  // Get all valid admins to be used for filtering the data
  const { data: admins } = await request.get(`/admin?page=1&per_page=200`);

  const { data: staff } = await request.get(
    `/dashboard/assistants/tags?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: hmrc } = await request.get(
    `/dashboard/assistants/hmrc?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: messages } = await request.get(
    `/dashboard/assistants/messages?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: income } = await request.get(
    `/dashboard/assistants/tax?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const { data: doa } = await request.get(
    `/dashboard/assistants/deed?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const data = {
    admins: admins?.data,
    staff: staff,
    hmrc: hmrc,
    messages: messages,
    income: income,
    doa: doa,
  };

  return data;
};

export const getStaffShift = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string, string, Filters['period']]
>): Promise<DashboardStaffShiftData> => {
  const [, startDate, endDate, period] = queryKey;

  // Get all valid admins to be used for filtering the data
  const { data: admins } = await request.get(`/admin?page=1&per_page=200`);

  const dataValue = await request.get(
    `/dashboard/assistants/shifts?start_date=${startDate}&end_date=${endDate}&period=${period}`,
  );

  const data = {
    admins: admins?.data,
    shifts: dataValue.data,
  };

  return data;
};

export const getStaffDetails = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string, string, number]
>): Promise<DashboardStaffDetails> => {
  const [, startDate, endDate, assistant_id] = queryKey;

  const { data: details } = await request.get(
    `/dashboard/assistants/tags-user?start_date=${startDate}&end_date=${endDate}&assistant_id=${assistant_id}`,
  );
  const data = { details: details };

  return data;
};

export const geRefundsDetails = async (date: any) => {
  const data = await request.get(`/dashboard/hmrc-paid/breakdown?date=${date}`);

  return data;
};

export const analyticsDeedDownload = async () => {
  const { data } = await request.get(`/dashboard/analytics/deed-download`);

  return data;
};
