import request from "./request";
import ResponseError from "./ResponseError";

export const getAdminNotesById = async (
  userId: number
): Promise<AdminNotesById> => {
  try {
    const { data } = await request.get(
      `admin/get-admin-notes/?user_id=${userId}`
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const createAdminNote = async ({
  notes,
  user_id,
}: AdminNotesCreate): Promise<AdminNotesCreateResponse> => {
  try {
    const { data } = await request.post(`/admin-notes/add-admin-notes`, {
      notes,
      user_id,
    });
    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateAdminNoteById = async (
  note: Omit<AdminNotesTypes, "admin_name" | "created_at">
): Promise<void> => {
  try {
    const { data } = await request.post(`/admin-notes/edit`, { note });
    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
