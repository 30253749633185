import React from 'react';

import { highlightText } from 'src/utils';

import { Item, Email } from './styled';

export type Item = {
  name: string;
  email: string;
  id: number;
  utr_no?: string;
  referral_code: string;
};

type Props = {
  item: Item;
  query: string;
  onSelect: (value: number | string) => void;
  isUserReferrals: boolean;
  showReferralCode: boolean;
};

const SearchBar = ({
  item: { name, email, id, referral_code },
  query = '',
  onSelect,
  isUserReferrals,
  showReferralCode,
}: Props) => (
  <Item
    onClick={() => onSelect(isUserReferrals ? name : id)}
    className="search-bar-search-result-item"
  >
    <strong dangerouslySetInnerHTML={{ __html: highlightText(query, name) }} />
    {!showReferralCode && (
      <Email
        dangerouslySetInnerHTML={{ __html: highlightText(query, email) }}
      />
    )}
    {showReferralCode && (
      <>
        <span> - {referral_code}</span>
      </>
    )}
  </Item>
);

export default SearchBar;
