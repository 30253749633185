export { default as IconCalled } from './IconCalled';
export { default as IconCustomerStatus } from './IconCustomerStatus';
export { default as IconDeleteOrDisableCustomer } from './IconDeleteOrDisableCustomer';
export { default as IconPaidStatus } from './IconPaidStatus/IconPaidStatus';
export { default as IconTaxAssistant } from './IconTaxAssistant';
export { default as IconTaxCode } from './IconTaxCode';
export { default as IconUTR } from './IconUTR';
export { default as StatusInfo } from './StatusInfo';
export { default as StatusInfoItem } from './StatusInfoItem';
export { default as TaxRefundAmount } from './TaxRefundAmount';
