import styled from 'styled-components';

type Props = {
  scale: number;
  rotate: number;
};

export const Container = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const SectionImage = styled.div`
  overflow: hidden;
`;

export const Image = styled.img<Props>`
  width: 100%;
  height: 100%;
  max-width: 1154px;
  max-height: 802px;

  transform: scale(${(props) => props.scale})
    rotate(${(props) => props.rotate}deg);
`;

export const SectionImagesList = styled.div`
  border: 4px solid #333;
  border-radius: 8px;
  max-width: 124px;
  margin-bottom: 46px;
`;

export const ImagesListItem = styled.div`
  border: 2px solid #333;

  > img {
    max-width: 124px;
    max-height: 72px;
  }
`;

export const SectionImageButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageButton = styled.button`
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
  background-color: #57e4cd;
`;
