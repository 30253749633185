import React from 'react';

import { Col, Row } from 'src/modules';
import { Routes } from 'src/utils';
import { CustomerInformation } from 'src/utils/types/Customer';

import { LabelData, AddressWrapper } from '../../styles';

import { HiddenInput, SectionInput } from './styles';

type Props = {
  customer: CustomerInformation;
  isLoading: boolean;
  disabled: boolean;
  updateInfo(item: any): void;
  onFindAddress(): void;
};

const PersonalInformationPie: React.FC<Props> = ({
  customer,
  isLoading,
  disabled,
  updateInfo,
  onFindAddress,
}) => {
  return (
    <>
      <Row style={{ gap: 60, width: '100%' }}>
        {!isLoading && (
          <>
            <Col>
              <SectionInput>
                <LabelData for="first_name" isPIE>
                  First Name:
                </LabelData>

                <HiddenInput
                  id="first_name"
                  type="text"
                  defaultValue={customer?.first_name}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="middle_name" isPIE>
                  Middle Name :
                </LabelData>

                <HiddenInput
                  id="middle_name"
                  type="text"
                  defaultValue={customer?.middle_name}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>
              <SectionInput>
                <LabelData for="last_name" isPIE>
                  Last Name:
                </LabelData>

                <HiddenInput
                  id="last_name"
                  type="text"
                  defaultValue={customer?.last_name}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="dob" isPIE>
                  DOB:
                </LabelData>

                <HiddenInput
                  id="dob"
                  type="text"
                  defaultValue={customer?.dob}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="phone" isPIE>
                  Phone:
                </LabelData>

                <HiddenInput
                  id="phone"
                  type="text"
                  defaultValue={customer?.phone}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="ddi" isPIE>
                  DDI:
                </LabelData>

                <HiddenInput
                  id="ddi"
                  type="text"
                  defaultValue={customer?.ddi}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="address" isPIE>
                  Address:
                </LabelData>

                <HiddenInput
                  id="address"
                  type={disabled ? 'text' : 'textarea'}
                  defaultValue={customer?.address}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="postal_code" isPIE>
                  Postal Code:
                </LabelData>

                <AddressWrapper className="d-flex">
                  <SectionInput>
                    <HiddenInput
                      id="postal_code"
                      type="text"
                      defaultValue={customer?.postal_code}
                      disabled={disabled}
                      onChange={(e) => updateInfo(e.target)}
                      inputDisabled={disabled}
                    />
                    <button
                      className={` ${disabled ? 'd-none' : ''}`}
                      onClick={onFindAddress}
                    >
                      Find Adress
                    </button>
                  </SectionInput>
                </AddressWrapper>
              </SectionInput>

              <SectionInput>
                <LabelData for="account_holder_name" isPIE>
                  Bank name:
                </LabelData>

                <AddressWrapper className="d-flex">
                  <SectionInput>
                    <HiddenInput
                      id="account_holder_name"
                      type="text"
                      defaultValue={customer?.account_holder_name}
                      disabled={disabled}
                      onChange={(e) => updateInfo(e.target)}
                      inputDisabled={disabled}
                    />
                  </SectionInput>
                </AddressWrapper>
              </SectionInput>

              <SectionInput>
                <LabelData for="account_holder_name" isPIE>
                  Account number:
                </LabelData>

                <AddressWrapper className="d-flex">
                  <SectionInput>
                    <HiddenInput
                      id="account_number"
                      type="text"
                      defaultValue={customer?.account_number}
                      disabled={disabled}
                      onChange={(e) => updateInfo(e.target)}
                      inputDisabled={disabled}
                    />
                  </SectionInput>
                </AddressWrapper>
              </SectionInput>
            </Col>

            <Col>
              <SectionInput>
                <LabelData for="email" isPIE>
                  Email:
                </LabelData>
                <HiddenInput
                  id="email"
                  type="email"
                  defaultValue={customer?.email}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="created-on" isPIE>
                  Created On:
                </LabelData>
                <HiddenInput
                  id="created-on"
                  type="text"
                  defaultValue={customer?.created_date}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="updated-on" isPIE>
                  Last logged in:
                </LabelData>
                <HiddenInput
                  id="updated-on"
                  type="text"
                  defaultValue={customer?.modified_date}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="status" isPIE>
                  Status:
                </LabelData>
                <HiddenInput
                  id="status"
                  type="text"
                  defaultValue={customer?.status}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="last access" isPIE>
                  Last access:
                </LabelData>
                <HiddenInput
                  id="last access"
                  type="text"
                  defaultValue={customer?.last_login}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="city" isPIE>
                  City:
                </LabelData>
                <HiddenInput
                  id="city"
                  type="text"
                  defaultValue={customer?.city}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="address" isPIE>
                  Repayment (sort code)
                </LabelData>

                <HiddenInput
                  id="account_sort_code"
                  type={disabled ? 'text' : 'textarea'}
                  defaultValue={customer?.account_sort_code}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>
            </Col>

            <Col>
              <SectionInput>
                <LabelData for="child-benefit" isPIE>
                  Child benefit:
                </LabelData>
                <HiddenInput
                  id="child-benefit"
                  type="text"
                  defaultValue="No"
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>
              <SectionInput>
                <LabelData for="student-loan" isPIE>
                  Student loan:
                </LabelData>
                <HiddenInput
                  id="student-loan"
                  type="text"
                  defaultValue="Yes"
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="utr_no" isPIE>
                  UTR Number:
                </LabelData>

                <HiddenInput
                  id="utr_no"
                  type="text"
                  defaultValue={customer?.utr_no}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="insurance_no" isPIE>
                  NI Number:
                </LabelData>

                <HiddenInput
                  id="insurance_no"
                  type="text"
                  defaultValue={customer?.insurance_no}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="ltd_code" isPIE>
                  LTD owner:
                </LabelData>

                <HiddenInput
                  id="ltd_code"
                  type="text"
                  defaultValue={'No'}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="service_type" isPIE>
                  Service Type:
                </LabelData>

                <HiddenInput
                  id="service_type"
                  type="text"
                  defaultValue={customer?.service_type}
                  disabled={disabled}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="app_version" isPIE>
                 App Version:
                </LabelData>

                <HiddenInput
                  id="app_version"
                  type="text"
                  defaultValue={customer?.device_log?.version}
                  disabled={disabled}
                  inputDisabled={disabled}
                />
              </SectionInput>
            </Col>

            <Col>
              <SectionInput>
                <LabelData for="referralCode" isPIE>
                  Referral Code:
                </LabelData>
                <HiddenInput
                  id="referralCode"
                  type="text"
                  defaultValue={customer?.referral_code}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="referred_by" isPIE>
                  Referred By :
                </LabelData>
                <a
                  href={`${Routes.CUSTOMER_INFORMATION}/?id=${customer?.referred_by}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {customer?.referred_by_name || customer?.referred_by}
                </a>
              </SectionInput>

              <SectionInput>
                <LabelData for="push-notifications" isPIE>
                  Push notifications:
                </LabelData>
                <HiddenInput
                  id="push-notifications"
                  type="text"
                  defaultValue={customer?.is_push_notification ? 'Yes' : 'No'}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="marketing-email" isPIE>
                  Marketing emails:
                </LabelData>
                <HiddenInput
                  id="marketing-email"
                  type="text"
                  defaultValue={customer?.is_accept_marketing_purposes ? 'Yes' : 'No'}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="is-sms" isPIE>
                  Is SMS:
                </LabelData>
                <HiddenInput
                  id="is-sms"
                  type="text"
                  defaultValue={customer?.is_sms ? 'Yes' : 'No'}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="device-os" isPIE>
                  Device OS:
                </LabelData>
                <HiddenInput
                  id="device-os"
                  type="text"
                  defaultValue={customer?.device}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>

              <SectionInput>
                <LabelData for="how-you-here" isPIE>
                  How you here:
                </LabelData>
                <HiddenInput
                  id="how-you-here"
                  type="text"
                  defaultValue={customer?.how_you_here}
                  disabled={true}
                  inputDisabled={disabled}
                />
              </SectionInput>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default PersonalInformationPie;
