import React, { FC, useState, Children, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import { Title, Content, Wrapper, Header } from './styled';

type Props = {
  title: string;
  expandable?: boolean;
  isLoading?: boolean;
  dimensions?: {
    width: number;
    height: number;
  };
};

const Section: FC<Props> = ({
  title,
  children,
  isLoading,
  expandable = false,
  dimensions = {
    width: 200,
    height: 140,
  },
}) => {
  const [showMore, setShowMore] = useState(false);
  const toggle = () => setShowMore((prevState) => !prevState);

  const [count, setCount] = useState(0);

  const getCount = () => {
    const areaWidth = window.innerWidth - 115;
    setCount(Math.floor(areaWidth / dimensions.width));
  };

  window.onresize = getCount;

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {expandable && (
          <button
            type="submit"
            color="primary"
            className="btn btn-primary"
            onClick={toggle}
          >
            {showMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </Header>
      {isLoading ? (
        <Content>
          {[...new Array(count)].map((_, index) => (
            <Skeleton
              key={`item-${index}`}
              height={dimensions.height}
              width={dimensions.width}
            />
          ))}
        </Content>
      ) : (
        <Content>
          {expandable
            ? Children.toArray(children).filter((child, index) =>
                showMore ? child : index < count,
              )
            : children}
        </Content>
      )}
    </Wrapper>
  );
};

export default Section;
