const pallete = {
  pink: {
    light: '#e366be',
    main: '#e83ab4',
    dark: '#dc3ead',
  },
  red: {
    light: '#e84c4c',
    main: '#ee1c1c',
    dark: '#df0000',
    punch: '#DD3C27',
    cosmos: '#FFD7DA',
    bittersweet: '#FC5A5A',
  },
  yellow: {
    regular: '#ffdd05',
    variant: '#dec000',
    variant2: '#c4aa00',
    variant3: '#d1b500',
    golden: '#FFE600',
  },
  green: {
    light: '#1ed61e',
    main: '#1abc1a',
    dark: '#148f14',
    ocean: '#33A176',
    algae: '#92DDC0',
  },
  white: {
    default: '#ffffff',
    catskill: '#f4f6fa',
    gost: '#f1f1f5',
    mainGost: '#FAFAFB',
  },
  gray: {
    ghost: '#CED4DA',
    ghostTwo: '#B5B5BE',
    geyser: '#dee2e6',
    regent: '#818c97',
    iron: '#E0E3E6',
    shark: '#212529',
    light: '#F1F1F1',
    quartz: '#D5D5DC',
  },
  blue: {
    fiord: '#3a4d5f',
    cyan: '#1F9BCF',
  },
  black: {
    default: '#000000',
    nightRider: '#333333',
    russian: '#171725',
  },
};

export const theme = {
  colors: {
    button: pallete.yellow.regular,
    hoveredButton: pallete.yellow.variant,
    activeButton: pallete.yellow.variant3,
    hoveredButtonBorder: pallete.yellow.variant3,
    activeButtonBorder: pallete.yellow.variant2,
    primary: pallete.yellow.regular,
    inputBackground: pallete.gray.light,
    ...pallete,
  },
  spacing: {
    xs: 5,
    s: 10,
    m: 18,
    l: 24,
    xl: 40,
    xxl: 50,
  },
  radius: {
    xs: 5,
    s: 10,
    m: 15,
    l: 20,
  },
  fontSizes: {
    title: {
      s: 12,
      m: 16,
      l: 18,
    },
    subitle: {
      xs: 10,
      s: 12,
    },
    body: {
      m: 15,
    },
  },
};
