import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addClient, fetchReferralsByName } from 'src/api/referrals';
import { useYear } from 'src/hooks';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Toastify,
} from 'src/modules';
import { openOnNewTab, Routes } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import { SearchBar } from '../common';
import TableRow from '../TableRow';
import UserReferralsRowDetails from '../UserReferralsRowDetails';

// TODO: notification model
interface Props {
  item: UserReferralsTypes;
}

const UserReferralsRow = ({ item }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [userReferralSelected, setUserReferralSelected] =
    useState<UserReferralsTypes>();
  const [year] = useYear();
  const [query, setQuery] = useState('');

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const { data, refetch, isFetching } = useQuery(
    'fetchReferralsByName',
    () => fetchReferralsByName(query),
    {
      enabled: false,
      onError: (error: any) => {
        Toastify.toast.error(error.message);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [query]);

  const { mutate } = useMutation(() => addClient(item.id, referralCode), {
    onSettled: () => {
      toggleModal();
    },
    onSuccess: () => {
      Toastify.toast.success('Sucessfully added');
    },
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  const [showUserReferralsDetails, setShowNotificationDetails] =
    useState(false);

  const showAndHideDetails = () => {
    setShowNotificationDetails(
      (showUserReferralsDetails) => !showUserReferralsDetails,
    );
  };

  const onSelectUser = (id: number) => {
    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${id}&tab=referrals&tax_year=${year}`,
    );
  };

  const handleSelect = (id: number) => {
    const selected = data.data.filter((item: any) => item.id === id);

    setUserReferralSelected(selected[0]);
    setReferralCode(selected[0]?.referral_code);
  };

  return (
    <>
      <TableRow className={showUserReferralsDetails ? 'bg-gray-selected' : ''}>
        <Col
          xs="4"
          className="mt-2 pl-4 d-flex flex-column link"
          onClick={() => onSelectUser(item.id)}
        >
          <p className="mb-0">{item.full_name}</p>
          <p className="mb-0">{item.email}</p>
          <p className="mb-0 text-muted">{item.referral_code}</p>
        </Col>
        <Col
          xs="2"
          className="d-flex justify-content-center align-items-center"
        >
          {item.total_clients || ''}
        </Col>
        <Col
          xs="2"
          className="d-flex justify-content-center align-items-center"
        >
          {item.referred_earning}
        </Col>
        <Col
          xs="2"
          className="d-flex flex-column align-items-center justify-content-center link"
          onClick={() => onSelectUser(item.referral_by.id)}
        >
          <p className="mb-0">{item.referral_by.full_name}</p>
          <p className="mb-0 text-muted">{item.referral_by.referral_code}</p>
        </Col>
        <Col
          xs="2"
          className="d-flex justify-content-center align-items-center"
        >
          <Row className="d-flex justify-content-center align-items-center">
            <div>
              <Button onClick={toggleModal} size="sm" color="primary">
                Add Client
              </Button>
            </div>
            <ButtonIcon onClick={showAndHideDetails} className="table-button">
              <FontAwesomeIcon icon={faChevronDown} />
            </ButtonIcon>
          </Row>
        </Col>
      </TableRow>
      {showUserReferralsDetails && (
        <Container
          className="pt-2 pb-1 pl-3 pr-3 w-100 mw-100 bg-gray-selected"
          style={{ marginTop: '-1.1rem' }}
        >
          <UserReferralsRowDetails item={item} onSelectUser={onSelectUser} />
        </Container>
      )}
      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader>Add client</ModalHeader>
        <ModalBody>
          <Row className="mb-3">
            <Col xs="3">
              <Label>Referral code:</Label>
            </Col>
            <Col xs="9">
              <Input
                value={
                  userReferralSelected
                    ? userReferralSelected.referral_code
                    : referralCode
                }
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <Label>Search</Label>
            </Col>
            <Col xs="9">
              <SearchBar
                data={
                  data &&
                  data.data.map((item: any) => ({
                    ...item,
                    name: item.full_name,
                  }))
                }
                onSelect={handleSelect}
                onChange={setQuery}
                isLoading={isFetching}
                showReferralCode
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} size="sm" color="secondary">
            Cancel
          </Button>
          <Button onClick={() => mutate()} size="sm" color="primary">
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UserReferralsRow;
