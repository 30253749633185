export const calcWork = (amount: number, splitValue: number) => {
  const result = (amount * splitValue) / 100;

  return result;
};

export const calcPersonal = (total: number, percentValue: number) => {
  const result = total - percentValue;

  return result;
};
