import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Modal, ModalBody, ModalHeader, observer, Table } from 'src/modules';
import { formatDate } from 'src/utils';
import { AssistantsData, StaffDetailModal } from 'src/utils/staffDataset';
import { Loading } from '../ui';
import store from 'src/stores';
import { getStaffDetails } from 'src/api/dashboard';

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  item: AssistantsData;
};

const StaffDetailsModal: React.FC<Props> = ({ openModal, setOpenModal, item }) => {

  const [data, setData] = useState<any>(null);

  const { isLoading } = useQuery(
    [
      'getStaffDetails',
      formatDate(store.dashboard.filters.startDate, 'DD/MM/YYYY'),
      formatDate(store.dashboard.filters.endDate, 'DD/MM/YYYY'),
      item.id
    ],
    getStaffDetails,
    {
      retry: false,
      onSuccess: (res: StaffDetailModal[]) => {
        setData(res);
      },
    },
  );


  return (
    <>
      {isLoading && <Loading />}
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>Details</ModalHeader>
        <ModalBody>
          <Table className="text-center" style={{ marginBottom: 100 }}>
            <tbody>
            {data?.details.map((item: StaffDetailModal, index: number) => (
                <tr key={`${item.label}-${index}`}>
                  <td>{item.label}</td>
                  <td>
                    {formatDate(
                      item.date,
                      'YYYY-MM-DD HH:mm:ss',
                      'DD/MM/YYYY - HH:mm',
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default observer(StaffDetailsModal);
