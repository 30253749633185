import React from 'react';

import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CardActions,
  CardList,
  CardName,
  Container,
  DeleteTeamButton,
  Header,
  ImgAssistantPhoto,
} from './styles';

type Props = {
  teamId: number;
  teamName: string;
  onShowModalEditTeam: (id: number, teamName: string) => void;
  onShowModalDeleteTeam: (id: number) => void;
  onShowModalSearchUser: (
    teamId: number,
    teamName: string,
    team: TeamsData,
  ) => void;
  onShowModalDeleteAssistantTeam: (assistantId: number, teamId: number) => void;
  assistants: AssistantsTeamData[];
  team: TeamsData;
  adminList: AdminData[];
};

const Teams: React.FC<Props> = ({
  teamId,
  teamName,
  onShowModalEditTeam,
  onShowModalDeleteTeam,
  onShowModalSearchUser,
  onShowModalDeleteAssistantTeam,
  assistants,
  team,
  adminList,
}) => {

  const getAssistantInfo = (assistant_id: number) => {
    if (!adminList) return null;
    const assistant = adminList.find((admin) => admin.id === assistant_id);
    return assistant;
  };

  return (
    <Container>
      <Header>
        <CardName>
          <span className="card-name-text">{teamName}</span>
        </CardName>
        <CardActions>
          <FontAwesomeIcon
            icon={faPlus}
            onClick={() => onShowModalSearchUser(teamId, teamName, team)}
          />
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => onShowModalEditTeam(teamId, teamName)}
          />
          <DeleteTeamButton
            disabled={assistants.length > 0}
            onClick={() => onShowModalDeleteTeam(teamId)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </DeleteTeamButton>
        </CardActions>
      </Header>
      {assistants.length > 0 &&
        assistants.map((item: AssistantsTeamData) => {
          return (
            <CardList key={item.id}>
              <div className="assistant-name">
                <ImgAssistantPhoto
                  src={getAssistantInfo(item.id)?.image}
                  alt="Assistant Photo"
                />
                <span>{item.name}</span>
              </div>
              <div className="delete-assistant">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() =>
                    onShowModalDeleteAssistantTeam(item.id, teamId)
                  }
                />
              </div>
            </CardList>
          );
        })}
    </Container>
  );
};

export default Teams;
