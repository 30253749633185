import React from 'react';

import { StaffChartData } from 'src/utils/staffDataset';
import { arraySumParam } from 'src/utils/Utils';

type Props = {
  data: StaffChartData;
};

const StaffRowTotal: React.FC<Props> = ({ data }: Props) => {
  return (
    <tr>
      <td></td>
      {data?.byDate.map((item, index) => (
        <td key={index}>
          <span style={{ fontSize: '10px' }}>
            {arraySumParam(item.assistants, 'read')} Read <br />
            {arraySumParam(item.assistants, 'sent')} Sent
          </span>
        </td>
      ))}
      <td
        className={data.byGroup.length % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}
      >
        <span style={{ fontSize: '10px' }}>
          {arraySumParam(data?.byGroup as any, 'totalRead')} Read <br />
          {arraySumParam(data?.byGroup as any, 'totalSent')} Sent
        </span>
      </td>
    </tr>
  );
};

export default StaffRowTotal;
