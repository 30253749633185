import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { updatePAYE } from 'src/api/incomes';
import { getTaxIncomeListing } from 'src/api/users';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { PayeButton } from 'src/styles/pages/components/PayeButton';
import { formatCurrency, SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeHeaderButtons,
  IncomeInputsContainer,
  HeaderSelectInput,
  ViewImageSection,
  OtherInformationsInputSection,
} from '../../styles';
import { EmployerNameSection } from '../TabAll/styles';
import {
  Logo,
  SaveCancelSection,
  StartDateLabel,
} from '../TabSelfEmployed/styles';
import ViewDocs from '../ViewDocs';

type HeaderPayeType = {
  id: number;
  employer_name: string;
  logo: string;
  ref: string;
  gross_amount: string;
  tax_amount: string;
  is_company_director: number;
  has_benefits: number;
  benefits_amount: string;
  created_at: string;
};

const addTransactionFields = {
  client_name: '',
  date: null,
  amount: '',
  files: [],
};

function TabPAYE() {
  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const incomes = store.incomes;
  const [payeHeaderList, setPayeHeaderList] = useState<HeaderPayeType[]>([]);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListing(userId, year, 'employment'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { mutate: mutationUpdatePAYE } = useMutation('updatePAYE', updatePAYE, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ error }) {
      Toastify.toast.error(error);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      list.forEach((item, index) => {
        list[index].editMode = false;
        list[index].addTransactionFields = { ...addTransactionFields };
        list[index].editTransaction = [...incomes.incomeTransactions];
      });

      setPayeHeaderList(list);
    }
  }, [data, incomes.incomeTransactions]);

  const onUpdatePayeHeader = (editMode: boolean, index: number, id: number) => {
    const newPayeHeaderList = payeHeaderList;

    if (editMode) {
      newPayeHeaderList[index].editMode = false;
      setPayeHeaderList([...newPayeHeaderList]);

      if (newPayeHeaderList[index].id === id) {
        const itemEdited = newPayeHeaderList[index];

        const formData = new FormData();

        formData.append('customer_id', userId);
        formData.append('tax_year', year);
        formData.append('employer_name', itemEdited.employer_name);
        formData.append('ref', itemEdited.ref);
        formData.append('gross_amount', itemEdited.gross_amount);
        formData.append('tax_amount', itemEdited.tax_amount);

        formData.append('additional_earnings', itemEdited.additional_earnings);
        formData.append('is_company_director', itemEdited.is_company_director);
        formData.append('flow_type', itemEdited.flow_type);
        formData.append('has_benefits', itemEdited.has_benefits);
        formData.append('_method', 'PUT');

        mutationUpdatePAYE({ id: newPayeHeaderList[index].id, formData });
      }
    } else {
      newPayeHeaderList[index].editMode = true;
      setPayeHeaderList([...newPayeHeaderList]);
    }
  };

  const onShowDocuments = (index: number, showDocuments: boolean) => {
    const newPayeHeaderList = payeHeaderList;

    newPayeHeaderList[index].showDocuments = !showDocuments;
    setPayeHeaderList([...newPayeHeaderList]);
  };

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const newPayeHeaderList = [...payeHeaderList];

    const removeDuplicatedPound = target.value.replace(/(£)+/, '£');
    const valueWithoutPound = removeDuplicatedPound.replace('£', '');

    newPayeHeaderList[index][target.name] = valueWithoutPound;
    setPayeHeaderList(newPayeHeaderList);
  };

  const onCancelUpdateItem = (index: number) => {
    const newPayeHeaderList = [...payeHeaderList];

    newPayeHeaderList[index].editMode = false;
    setPayeHeaderList([...newPayeHeaderList]);
  };

  return (
    <div>
      {!isLoading &&
        payeHeaderList.map((item, index) => {
          return (
            <IncomeContainer key={item.id}>
              <IncomeHeader>
                <div>
                  <Logo src={handleLogo('employment', '')} alt="" />
                </div>
                <IncomeInputsContainer>
                  <IncomeHeaderFields>
                    <Row>
                      <Col>
                        <EmployerNameSection>
                          <EditInputPie
                            id="employer_name"
                            label="Employer name:"
                            name="employer_name"
                            type="text"
                            value={item.employer_name}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </EmployerNameSection>
                      </Col>

                      <Col>
                        <EditInputPie
                          id="ref"
                          label="Employer Paye"
                          name="ref"
                          type="text"
                          value={!item.ref ? (item.ref = '') : item.ref}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Gross amount:"
                          id={`gross_amount_${index}`}
                          name="gross_amount"
                          type="text"
                          value={`£${
                            item.gross_amount ? item.gross_amount : 0
                          }`}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Tax amount:"
                          id={`tax_amount${index}`}
                          name="tax_amount"
                          type="text"
                          value={`£${item.tax_amount ? item.tax_amount : 0}`}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <OtherInformationsInputSection hasBenefits>
                          <label htmlFor="director-position">
                            Has benefits
                          </label>
                          <HeaderSelectInput
                            id="has_benefits"
                            name="has_benefits"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option value={true} selected={item.has_benefits}>
                              Yes
                            </option>

                            <option value={false} selected={!item.has_benefits}>
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Additional earnings:"
                          id="additional_earnings"
                          name="additional_earnings"
                          type="text"
                          value={
                            item.additional_earnings === null
                              ? (item.additional_earnings = 0)
                              : item.additional_earnings
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="director-position">
                            Is company director:
                          </label>
                          <HeaderSelectInput
                            id="is_company_director"
                            name="is_company_director"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value={true}
                              selected={item.is_company_director}
                            >
                              Yes
                            </option>

                            <option
                              value={false}
                              selected={!item.is_company_director}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>

                      <Col>
                        <div>
                          <StartDateLabel htmlFor="flow_type" className="title">
                            Flow type
                          </StartDateLabel>{' '}
                          <br />
                          <select
                            id="flow_type"
                            name="flow_type"
                            onChange={(event) => onInputChange(index, event)}
                          >
                            <option value={item.flow_type}>
                              {item.flow_type}{' '}
                            </option>
                            <option value="automatic">Automatic</option>
                            <option value="p45">P45</option>
                            <option value="p60">P60</option>
                            <option value="payslip">payslip</option>
                          </select>
                        </div>
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Status:"
                          id="status"
                          name="status"
                          type="text"
                          value={
                            !item.status ? (item.status = '') : item.status
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={false}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EditInputPie
                          label="Total Expenses:"
                          id="total_expenses"
                          name="total_expenses"
                          type="text"
                          value={formatCurrency(item.total_expenses)}
                          onChange={() => {}}
                          editMode={false}
                          disabled
                        />
                      </Col>
                    </Row>
                  </IncomeHeaderFields>
                  <IncomeHeaderButtons>
                    {item.flow_type !== 'automatic' ? (
                      <SaveCancelSection>
                        <PayeButton
                          onClick={() =>
                            onUpdatePayeHeader(item.editMode, index, item.id)
                          }
                        >
                          {item.editMode ? 'Save' : 'Edit'}
                        </PayeButton>
                        <PayeButton onClick={() => onCancelUpdateItem(index)}>
                          Cancel
                        </PayeButton>
                      </SaveCancelSection>
                    ) : null}
                    <PayeButton
                      onClick={() => onShowDocuments(index, item.showDocuments)}
                    >
                      Show Documents
                    </PayeButton>
                  </IncomeHeaderButtons>
                </IncomeInputsContainer>
              </IncomeHeader>

              <div>
                {item.showDocuments && (
                  <div>
                    <ViewImageSection>
                      <ViewDocs docs={item.document} />
                    </ViewImageSection>
                  </div>
                )}
              </div>
            </IncomeContainer>
          );
        })}
    </div>
  );
}

export default observer(TabPAYE);
