import React, { forwardRef } from 'react';

import { Link } from 'gatsby';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, motion } from 'src/modules';

type BasicProps = {
  linkTo: string;
  icon: IconProp;
  isOpen: boolean;
  title: string;
};

interface PropsWithBadge extends BasicProps {
  hasBadge: true;
  badgeCount: number;
}

interface PropsWithoutBadge extends BasicProps {
  hasBadge: false;
  badgeCount?: never;
}

type Props = PropsWithBadge | PropsWithoutBadge;

const SideBarItem = forwardRef<HTMLLIElement, Props>(
  ({ linkTo, icon, isOpen, title, hasBadge, badgeCount }, ref) => {
    const animationConfig = {
      duration: 0.3,
      type: 'tween',
      delay: 0.2,
      ease: 'easeOut',
    };

    return (
      <li className="sidebar-li" ref={ref}>
        <Link to={linkTo} activeClassName="link-active">
          <div className="d-flex flex-row justify-content-start">
            <div className="p-2 sidebar-icon">
              <FontAwesomeIcon icon={icon} />
            </div>
            {isOpen && (
              <>
                <motion.div
                  animate={{ opacity: 1 }}
                  transition={animationConfig}
                  className="p-2 opacity-0"
                >
                  {title}
                </motion.div>
                {hasBadge && (
                  <div className={isOpen ? 'p-2' : 'badge-close'}>
                    <Badge color="danger" pill>
                      {badgeCount}
                    </Badge>
                  </div>
                )}
              </>
            )}
          </div>
        </Link>
      </li>
    );
  },
);

export default SideBarItem;
