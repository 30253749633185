import { ButtonBack, ButtonNext } from 'pure-react-carousel';

import { styled } from 'src/modules';

interface Props {
  isOpen?: boolean;
  contentWidth?: number;
}

export const Container = styled.div<Props>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  display: flex;
  inset: 0;
  position: absolute;

  button {
    cursor: pointer;
    outline: 0 !important;
    border: 0;
  }
`;

export const Backdrop = styled.div`
  opacity: 0.85;
  background: ${({ theme }) => theme.colors.black.default};
  inset: 0;
  position: absolute;
`;

export const Content = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  z-index: 999;
`;

export const CloseButtom = styled.button`
  color: white;
  display: inline-block;
  background: transparent;
  position: absolute;
  top: 2rem;
  right: 1rem;
  transition: transform 0.2s ease-in;
  z-index: 9999;

  :hover {
    color: ${({ theme }) => theme.colors.gray.geyser};
    transform: scale(1.1) rotateZ(90deg);
  }
`;

export const CarrousselContent = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilePreview = styled.iframe`
  width: 80%;
  height: 400px;
`;

export const ImgPreview = styled.img`
  width: 33%;
  max-height: 370px;
  object-fit: fill;
`;

export const Next = styled(ButtonNext)`
  position: absolute;
  background: transparent;
  right: 0;
  top: 0;
  bottom: 0;

  :hover {
    transform: scale(1.1);
  }
`;

export const Back = styled(ButtonBack)`
  position: absolute;
  background: transparent;
  left: 0;
  top: 0;
  bottom: 0;

  :hover {
    transform: scale(1.1);
  }
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const FileName = styled.span<Props>`
  color: ${({ theme }) => theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSizes.title.m}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: ${({ contentWidth = 0 }) => contentWidth - 50}px;
`;

export const ButtonsContent = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.l}px;
`;

export const ActionButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black.default};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100px;
  margin: 0 ${({ theme }) => theme.spacing.s}px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  font-weight: 600;
  transition: all 0.2s ease-in;

  :hover {
    background: ${({ theme }) => theme.colors.hoveredButton};
  }

  :focus {
    background: ${({ theme }) => theme.colors.hoveredButton};
  }
`;

export const YearsContent = styled.div<Props>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 350px;
  background: ${({ theme }) => theme.colors.white.default};
  padding: ${({ theme }) => theme.spacing.s}px;
  margin-top: ${({ theme }) => theme.spacing.l}px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  position: absolute;
  bottom: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const YearsContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  width: 100%;
`;

export const OptionsContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  width: 100%;
`;

export const CloseYearsContent = styled.button`
  width: 20px;
  height: 20px;
  background: transparent;

  :hover {
    transform: scale(1.1);
  }
`;

export const OptionButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black.default};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100px;
  margin-bottom: ${({ theme }) => theme.spacing.s}px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  font-weight: 600;
  transition: all 0.2s ease-in;

  :hover {
    background: ${({ theme }) => theme.colors.hoveredButton};
  }

  :focus {
    background: ${({ theme }) => theme.colors.hoveredButton};
  }
`;

export const YearsContentText = styled.span`
  color: ${({ theme }) => theme.colors.black.default};
  margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;
