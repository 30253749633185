import { styled } from 'src/modules';

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 55%;
  margin-bottom: 20px;
`;

export const SearchBarTitle = styled.span`
  display: inline-block;
  width: 50%;
`;
