import styled from 'styled-components';

export const SplitSection = styled.div`
  max-width: 400px;
`;

export const FooterTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 12px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .split-range-label {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    right: 5px;
  }
`;

export const SliptRange = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 366px;
  height: 2px;
  background: #d8d8d8;
  border-radius: 5px;

  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border: 2px solid #191424;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
    background-color: #fff;
    background-image: url('/icons/code-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const Select = styled.select`
  height: 56px;
  max-width: 200px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 16px;
`;

export const PrevNextFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-right: 20px;
`;

export const NotesAndSplitSection = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 50px;
`;

export const TextArea = styled.textarea`
  width: 500px;
`;

export const CategorySection = styled.div`
  margin-left: 20px;
`;

export const CategoryIdLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
`;
