import React from 'react';

import { AssistantsData } from 'src/utils/staffDataset';

type Props = {
  item: AssistantsData;
  index: number;
};

const StaffMessagesRow: React.FC<Props> = ({ item, index }: Props) => {
  return (
    <>
      <tr>
        <td className="text-left">{item.assistant}</td>
        {item &&
          item?.data.map((count, di) => (
            <td key={`count-${count}-${di}`}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {count ? count : <span className="opacity-2">{0}</span>}
                </span>
                <span style={{ fontSize: '10px' }}>
                  {item.read[di]} Read <br /> {item.sent[di]} Sent
                </span>
              </div>
            </td>
          ))}
        <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{item.total}</span>
            <span style={{ fontSize: '10px' }}>
              {item.totalRead} Read <br /> {item.totalSent} Sent
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default StaffMessagesRow;
