import React from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffRowHMRCSplit from '../StaffRowHMRCSplit';
import StaffRowTotalSplit from '../StaffRowTotalSplit';

type Props = {
  dataHMRC?: StaffChartData;
  type: string;
};

const HMRCSplitTable: React.FC<Props> = ({ dataHMRC, type }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        HMRC {type}
      </h1>
      <Table className="text-center" style={{ marginBottom: 100 }}>
        <thead>
          <tr>
            <td></td>
            {dataHMRC?.byDate.map((item, index) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {dataHMRC?.byGroup.map((item, index) => (
            <StaffRowHMRCSplit
              type={type}
              item={item}
              index={index}
              key={`${item.assistant}-${index}`}
            />
          ))}
          <StaffRowTotalSplit
            type={type}
            data={dataHMRC as any}
          ></StaffRowTotalSplit>
        </tbody>
      </Table>
    </>
  );
};

export default HMRCSplitTable;
