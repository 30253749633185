import styled from 'styled-components';

export const FiltersSection = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const FilterButton = styled.button`
  height: 35px;
  border: 1px solid #d8d8d8;
  border-radius: 46px;
  color: #191424;
  background-color: #fff;
  padding: 0 14px;
  font-weight: 600;

  &:focus {
    background-color: #191424;
    color: #fff;
    border: none;
  }
`;

export const FiltersInputSection = styled.div`
  display: flex;
  padding-top: 30px;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 20px;
`;

export const SearchInput = styled.input`
  width: 100%;
  max-width: 366px;
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 20px;
  padding: 18px;
  font-size: 18px;

  &::placeholder {
    font-size: 18px;
  }
`;

export const DateRangeInput = styled.input`
  width: 100%;
  max-width: 190px;
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 20px;
  padding: 18px;
  font-size: 18px;

  &::placeholder {
    font-size: 18px;
  }
`;

export const Logo = styled.img`
  width: 70px;
  border-radius: 5px;
`;

export const EmployerNameSection = styled.div`
  width: 250px;
`;
