import { action, observable, makeObservable } from 'mobx';

export default class ThemeStore {
  @observable
  currentTheme = '';

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentTheme = (theme: string) => {
    this.currentTheme = theme;
  };
}
