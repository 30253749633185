import styled from 'styled-components';

export const SearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin-top: 10%;

  .styled__Wrapper-iROdhl {
    height: 50px;
    font-size: 18px;
  }

  .styled__StyledInput-fLZoKH {
    font-size: 16px;

    ::placeholder {
      font-size: 16px;
    }
  }

  .styled__ResultsContainer-frvPtD {
    top: calc(3rem + 2px);
  }
`;

export const GotoCustomersButton = styled.button`
  background-color: ${({ theme }) => theme.colors.gray.geyser};
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray.shark};
`;
