import React, { memo, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { ICustomerTaxAssistants } from 'src/models';
import {
  Button,
  Col,
  Container,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from 'src/modules';
import { filterDevsOut } from 'src/utils/helpers/handleSuperAdminRole';

import TableHead from '../TableHead';
import TableRow from '../TableRow';
import { Loading, Avatar } from '../ui';

type Props = {
  id: number;
  image: string;
  name: string | number;
};

const IconTaxAssistant: React.FC<Props> = ({ id, image, name }) => {
  const [modal, setModal] = useState(false);
  const [personChanged, setPersonChanged] = useState<boolean>(false);
  const [checkedYears, setCheckedYears] = useState<string[]>([]);
  const [assistants, setAssistants] = useState<ICustomerTaxAssistants>();
  const [dropdowns, setDropdowns] = useState<Array<boolean>>([]);
  const [loading, setLoading] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => setShowTooltip((prevState) => !prevState);

  const toggleDropdown = (index: number) => {
    const temp = [...dropdowns];
    temp[index] = !temp[index];
    setDropdowns(temp);
  };

  const loadAssistants = async () => {
    try {
      const { data, status } = await request.get(`/admin?page=1&per_page=200`);

      if (status === 200) {
        const admins = filterDevsOut(data?.data);
        return admins;
      }
    } catch (error: any) {
      throw new ResponseError(error);
    }

    return false;
  };

  const showModal = async () => {
    setModal(true);
    setLoading(true);

    try {
      const assistantsData = await loadAssistants();
      const { data: res, status } = await request.get(
        `${API.USERS_GET_ASSISTANTS}?user_id=${id}`,
      );

      if (status === 200) {
        setAssistants({
          ...res,
          tax_assistants: assistantsData || res.tax_assistants,
        });
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const changeAssistantDropdown = ({ person, taxYear }): void => {
    const newTaxInfo = assistants.tax_info.map((el) => {
      if (taxYear === el.tax_year) el = { ...el, ...person };
      return el;
    });
    setAssistants({ ...assistants, tax_info: newTaxInfo });
    setPersonChanged(true);
    setCheckedYears([]);
  };

  const oneClickPersonChange = ({ e, taxYear }): void => {
    const isChecked: boolean = e.target.checked;

    const newCheckedYears = checkedYears.filter((el) => el !== taxYear);
    if (isChecked) newCheckedYears.push(taxYear);
    setCheckedYears(newCheckedYears);

    if (isChecked) {
      const newTaxInfo = assistants.tax_info.map((el, i: number) => {
        if (taxYear === el.tax_year && i > 0) {
          const person = assistants.tax_info[0];
          el = { ...el, ...person, tax_year: taxYear };
        }
        return el;
      });
      setAssistants({ ...assistants, tax_info: newTaxInfo });
    }
  };

  const onSubmit = async () => {
    const payload = { assistants: {}, user_id: assistants.user_id };
    assistants.tax_info.forEach((el) => {
      payload.assistants[el.tax_year] = el.id;
    });

    setLoading(true);

    try {
      const { status } = await request.post(
        API.USERS_UPDATE_TAX_ASSISTANT,
        payload,
      );

      if (status === 200) {
        setModal(false);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <>
        <Tooltip
          placement="top"
          isOpen={showTooltip}
          toggle={toggleTooltip}
          target={`avatar-${id}`}
        >
          {name}
        </Tooltip>

        <Avatar
          id={`avatar-${id}`}
          name="Tax Assistant Avatar"
          image={image}
          onClick={showModal}
        />
      </>

      {modal && (
        <Modal centered isOpen={true} toggle={() => setModal(false)}>
          <ModalHeader toggle={() => setModal(false)}>
            Assign Tax Assistant
          </ModalHeader>
          <ModalBody>
            <Container className="mw-100">
              <TableHead className="pt-1 pb-1 pl-1 no-gutters">
                <Col xs="3">Year</Col>
                <Col>Assigned Tax Assistant</Col>
              </TableHead>
              {assistants?.tax_info.map((item, index) => (
                <TableRow
                  key={`year${item.tax_year}${index}`}
                  className="no-gutters py-2"
                >
                  <Col xs="3">{item.tax_year}</Col>
                  <Col>
                    <Dropdown
                      isOpen={dropdowns[index]}
                      toggle={() => toggleDropdown(index)}
                    >
                      <DropdownToggle
                        className="justify-content-between"
                        caret
                        style={{ minWidth: 200 }}
                      >
                        {assistants.tax_info[index].name}
                      </DropdownToggle>
                      <DropdownMenu className="icon-tax-assistant-dropdown">
                        {assistants.tax_assistants.map((person, index) => (
                          <DropdownItem
                            key={`p${person.name}_${index}`}
                            onClick={() =>
                              changeAssistantDropdown({
                                person,
                                taxYear: item.tax_year,
                              })
                            }
                          >
                            <img
                              className="mr-2 icon-tax-assistant-dropdown-image"
                              src={person.image}
                            />
                            {person.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  {personChanged && index > 0 && (
                    <Col key={index + item.tax_year}>
                      <CustomInput
                        id={index + item.tax_year}
                        name="customSwitch"
                        type="checkbox"
                        checked={checkedYears.includes(item.tax_year)}
                        onChange={(e) => {
                          oneClickPersonChange({ e, taxYear: item.tax_year });
                        }}
                      />
                    </Col>
                  )}
                </TableRow>
              ))}
            </Container>
          </ModalBody>
          <ModalFooter>
            {loading && (
              <div className="w-100 mb-2 overflow-hidden">
                <Loading />
              </div>
            )}
            <Button color="secondary" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={onSubmit}>
              Apply
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default memo(IconTaxAssistant);
