import React from 'react';

import { TypeSelect } from './styles'

type Props = {
    selecting: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    filter: string;
    options: {
        label: string;
        value: string;
    }[]
}

const FilterSelect: React.FC<Props> = ({ selecting, filter, options }) => {
    return (
        <TypeSelect onChange={selecting}>
            {options.map((option) => {
                if (option.value == filter)
                    return <option value={option.value}>{option.label}</option>
            })}
            {options.map((option) => {
                if (option.value != filter)
                    return <option value={option.value}>{option.label}</option>
            })}
        </TypeSelect>
    );
}

export default (FilterSelect);
