import React from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffRowDetails from '../StaffRowDetails';
import StaffRowTotal from '../StaffRowTotal';

type Props = {
  data?: StaffChartData;
};

const TagTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        Tags added
      </h1>
      <Table
        className="text-center table table-striped"
        style={{ marginBottom: 100 }}
      >
        <thead>
          <tr>
            <td></td>
            {data?.byDate.map((item, index) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {data?.byGroup.map((item, index) => (
            <StaffRowDetails
              item={item}
              index={index}
              key={`${item.assistant}-${index}`}
            />
          ))}
          <StaffRowTotal data={data as any}></StaffRowTotal>
        </tbody>
      </Table>
    </>
  );
};

export default TagTable;
