import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { getSA110, updateSA110 } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  CheckboxLabel,
  FormContainer,
  InputForm,
  Title,
  SubmitButton,
} from '../../styles';

import CurrencyInput from 'react-currency-masked-input';

import { FormSA110 } from '../../FormState';

import { onInputChange, onCheckboxChange, onCurrencyInputChange } from '../../utils';

type Props = { 
  sa110formId: number,
  taxYear: number,
  taxReturnId: number,
  onClick?: ( e: any) => void,
};

export const SA110 = ({ 
    sa110formId,
    taxYear,
    taxReturnId, 
    onClick
 }: Props) => {

  const [formSA110State, setformSA110State] = useState<FormSA110Type>(FormSA110);

  const { data, isLoading, refetch } = useQuery(
    'getSA110',
    () => getSA110(sa110formId),
    {
      enabled: false,
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    if (sa110formId) refetch();
  }, [sa110formId]);

  useEffect(() => {
    if (sa110formId) refetch();
  }, []);

  const { mutate: mutationSA110 } = useMutation(updateSA110, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformSA110State(data.data);
      } else {
        setformSA110State(FormSA110);
      }
    }
  }, [isLoading]);

  const onSubmitForm = () => {
    const formData = {
      sa110_form_id: sa110formId,
      ...formSA110State,
    };

    mutationSA110(formData);
  };

  return (
    <>
      <Title>SA110</Title>
      <FormContainer>
        <Title>Self Assessment</Title>
        <InputForm>
          <Label>Total Tax Etc Due (required)</Label>
          <CurrencyInput
            value={formSA110State.total_tax_etc_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="total_tax_etc_due"
            name="total_tax_etc_due"
          />
        </InputForm>
        <InputForm>
          <Label>Student Loan Repayment Due</Label>
          <CurrencyInput
            value={formSA110State.student_loan_repayment_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="student_loan_repayment_due"
            name="student_loan_repayment_due"
          />
        </InputForm>
        <InputForm>
          <Label>Postgraduate Loan Repayment Due</Label>
          <CurrencyInput
            value={formSA110State.postgraduate_loan_repayment_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="postgraduate_loan_repayment_due"
            name="postgraduate_loan_repayment_due"
          />
        </InputForm>
        <InputForm>
          <Label>Class 4 NICs Due</Label>
          <CurrencyInput
            value={formSA110State.class_4_nics_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="class_4_nics_due"
            name="class_4_nics_due"
          />
        </InputForm>
        <InputForm>
          <Label>Class 2 NICs Due</Label>
          <CurrencyInput
            value={formSA110State.class_2_nics_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="class_2_nics_due"
            name="class_2_nics_due"
          />
        </InputForm>
        <InputForm>
          <Label>Capital Gains Tax Due</Label>
          <CurrencyInput
            value={formSA110State.capital_gains_tax_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="capital_gains_tax_due"
            name="capital_gains_tax_due"
          />
        </InputForm>
        <InputForm>
          <Label>Pension Charges Due</Label>
          <CurrencyInput
            value={formSA110State.pension_charges_due}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="pension_charges_due"
            name="pension_charges_due"
          />
        </InputForm>
        <Title>Underpaid Tax</Title>
        <InputForm>
          <Label>Underpaid Tax For Earlier Years Included In Code</Label>
          <CurrencyInput
            value={
              formSA110State.underpaid_tax_for_earlier_years_included_in_code
            }
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="underpaid_tax_for_earlier_years_included_in_code"
            name="underpaid_tax_for_earlier_years_included_in_code"
          />
        </InputForm>
        <InputForm>
          <Label>Underpaid Tax For Year Included In Future Code</Label>
          <CurrencyInput
            value={
              formSA110State.underpaid_tax_for_year_included_in_future_code
            }
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="underpaid_tax_for_year_included_in_future_code"
            name="underpaid_tax_for_year_included_in_future_code"
          />
        </InputForm>
        <InputForm>
          <Label>Outstanding Debt Coded Out Amount</Label>
          <CurrencyInput
            value={formSA110State.outstanding_debt_coded_out_amount}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="outstanding_debt_coded_out_amount"
            name="outstanding_debt_coded_out_amount"
          />
        </InputForm>
        <Title>Payments On Accounts</Title>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formSA110State.claim_to_reduce_payments_on_account}
            checked={formSA110State.claim_to_reduce_payments_on_account}
            onChange={(e) =>
              onCheckboxChange(e, formSA110State, setformSA110State)
            }
            name="claim_to_reduce_payments_on_account"
            id="claim_to_reduce_payments_on_account"
          />
          <Label lg>Claim To Reduce Payments On Account</Label>
        </CheckboxLabel>
        <InputForm>
          <Label>First Payment On Account</Label>
          <CurrencyInput
            value={formSA110State.first_payment_on_account}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="first_payment_on_account"
            name="first_payment_on_account"
          />
        </InputForm>
        <Title>Surplus Allowances</Title>
        <InputForm>
          <Label>Surplus Blind Persons Allowance</Label>
          <CurrencyInput
            value={formSA110State.surplus_blind_persons_allowance}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="surplus_blind_persons_allowance"
            name="surplus_blind_persons_allowance"
          />
        </InputForm>
        <InputForm>
          <Label>Surplus Married Couples Allowance</Label>
          <CurrencyInput
            value={formSA110State.surplus_married_couples_allowance}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="surplus_married_couples_allowance"
            name="surplus_married_couples_allowance"
          />
        </InputForm>
        <Title>Adjustments To Tax Due</Title>
        <InputForm>
          <Label>Increase In Tax From Adjustment To Earlier Years</Label>
          <CurrencyInput
            value={
              formSA110State.increase_in_tax_from_adjustment_to_earlier_years
            }
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="increase_in_tax_from_adjustment_to_earlier_years"
            name="increase_in_tax_from_adjustment_to_earlier_years"
          />
        </InputForm>
        <InputForm>
          <Label>Decrease In Tax From Adjustment To Earlier Years</Label>
          <CurrencyInput
            value={
              formSA110State.decrease_in_tax_from_adjustment_to_earlier_years
            }
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="decrease_in_tax_from_adjustment_to_earlier_years"
            name="decrease_in_tax_from_adjustment_to_earlier_years"
          />
        </InputForm>
        <InputForm>
          <Label>Next Years Repayment Claimed Now</Label>
          <CurrencyInput
            value={formSA110State.next_years_repayment_claimed_now}
            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA110State, setformSA110State)}
            className="form-control-lg form-control"
            id="next_years_repayment_claimed_now"
            name="next_years_repayment_claimed_now"
          />
        </InputForm>
        <Title>Any Other Information Space</Title>
        <InputForm>
          <Label>Any Other Information Space</Label>
          <Input
            type="textarea"
            bsSize="lg"
            value={formSA110State.any_other_information_space}
            onChange={(e) =>
              onInputChange(e, formSA110State, setformSA110State)
            }
            id="any_other_information_space"
            name="any_other_information_space"
          />
        </InputForm>
      </FormContainer>
      <SubmitButton>
        <Button onClick={() => onSubmitForm()} color="primary" disabled>
        Save
        </Button>
      </SubmitButton>
    </>
  );
};
