import requestHMRC from '../requestHMRC';

export const getTaxReturn = async (user_id: number, tax_year: number) => {
  // debug request base url
  console.log('requestHMRC.defaults.baseURL:', requestHMRC.defaults.baseURL);
  const { data } = await requestHMRC.get(
    `/tax-returns/?user_id=${user_id}&tax_year=${tax_year}`,
  );

  return data;
};

export const showTaxReturn = async (tax_return_id: number) => {
  const { data } = await requestHMRC.get(
    `/tax-returns/show?tax_return_id=${tax_return_id}`,
  );

  return data;
};

export const updateTaxReturn = async (dataForm: any) => {
  const { data } = await requestHMRC.put('/tax-returns/update', dataForm);

  return data;
};

export const createTaxReturn = async (dataForm: any) => {
  const { data } = await requestHMRC.post('/tax-returns/create', dataForm);

  return data;
};

export const getSA100 = async (sa100_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa100-forms/show?sa100_form_id=${sa100_form_id}`,
  );

  return data;
};

export const updateSA100 = async (dataForm: any) => {
  const { data } = await requestHMRC.put('/sa100-forms/update', dataForm);

  return data;
};

export const getSA101 = async (sa101_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa101-forms/show?sa101_form_id=${sa101_form_id}`,
  );

  return data;
};

export const createSA101 = async (dataForm: any) => {
  const { data } = await requestHMRC.post('sa101-forms/create', dataForm);

  return data;
};

export const updateSA101 = async (dataForm: any) => {
  const { data } = await requestHMRC.put('/sa101-forms/update', dataForm);

  return data;
};

export const getSA102 = async (sa102_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa102-forms/show?sa102_form_id=${sa102_form_id}`,
  );

  return data;
};

export const createSA102 = async (dataForm: any) => {
  const { data } = await requestHMRC.post('sa102-forms/create', dataForm);

  return data;
};

export const updateSA102 = async (dataForm: any) => {
  const { data } = await requestHMRC.put('/sa102-forms/update', dataForm);

  return data;
};

export const deleteSA102 = async (dataForm: any) => {
  const { data } = await requestHMRC.post('/sa102-forms/delete', dataForm);

  return data;
};

export const restoreSA102 = async (sa102_form_id: number) => {
  const { data } = await requestHMRC.put(
    `/sa102-forms/restore?sa102_form_id=${sa102_form_id}`,
    {},
  );

  return data;
};

export const getSA103F = async (sa103_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa103f-forms/show?sa103f_form_id=${sa103_form_id}`,
  );

  return data;
};

export const createSA103F = async (dataForm: any) => {
  const { data } = await requestHMRC.post('sa103f-forms/create', dataForm);

  return data;
};

export const updateSA103F = async (dataForm: any) => {
  const { data } = await requestHMRC.put('sa103f-forms/update', dataForm);

  return data;
};

export const deleteSA103F = async (dataForm: any) => {
  const { data } = await requestHMRC.post('/sa103f-forms/delete', dataForm);

  return data;
};

export const restoreSA103F = async (sa103_form_id: number) => {
  const { data } = await requestHMRC.put(
    `/sa103f-forms/restore?sa103f_form_id=${sa103_form_id}`,
    {},
  );

  return data;
};

export const getSA103S = async (sa103S_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa103s-forms/show?sa103s_form_id=${sa103S_form_id}`,
  );

  return data;
};

export const createSA103S = async (dataForm: any) => {
  const { data } = await requestHMRC.post('sa103s-forms/create', dataForm);

  return data;
};

export const updateSA103S = async (dataForm: any) => {
  const { data } = await requestHMRC.put('sa103s-forms/update', dataForm);

  return data;
};

export const deleteSA103S = async (dataForm: any) => {
  const { data } = await requestHMRC.post('/sa103s-forms/delete', dataForm);

  return data;
};

export const restoreSA103S = async (sa103s_form_id: number) => {
  const { data } = await requestHMRC.put(
    `/sa103s-forms/restore?sa103s_form_id=${sa103s_form_id}`,
    {},
  );

  return data;
};

export const getSA110 = async (sa110_form_id: number) => {
  const { data } = await requestHMRC.get(
    `/sa110-forms/show?sa110_form_id=${sa110_form_id}`,
  );

  return data;
};

export const updateSA110 = async (dataForm: any) => {
  const { data } = await requestHMRC.put('sa110-forms/update', dataForm);

  return data;
};

export const calculateTax = async (tax_return_id: number) => {
  const { data } = await requestHMRC.post('/calculate-tax-and-update-sa110', {
    tax_return_id,
  });

  return data;
};

export const submitTaxReturn = async (dataForm: any) => {
  const { data } = await requestHMRC.post(
    '/tax-returns/submit-tax-return ',
    dataForm,
  );

  return data;
};

export const calculateTaxAndSubmit = async (dataForm: any) => {

  const resp = await requestHMRC.post('/calculate-tax-and-update-sa110', { tax_return_id: dataForm.tax_return_id });    
  const { data } = await requestHMRC.post('/tax-returns/submit-tax-return ', dataForm );
  
  return data;
};