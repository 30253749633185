import { action, observable, makeObservable, runInAction } from 'mobx';

import { CostSuggestionsApi } from 'src/api';

export default class CostSuggestionsStore {
  @observable
  suggestions: Suggestion[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  getSuggestions = async (): Promise<void> => {
    const { data } = await CostSuggestionsApi.getSuggestions();

    runInAction(() => {
      this.suggestions = data;
    });
  };

  @action
  editSuggestions = async (
    newSuggestions: SuggestionValues[],
  ): Promise<void> => {
    const { data } = await CostSuggestionsApi.editSuggestions(newSuggestions);

    runInAction(() => {
      this.suggestions = this.suggestions.map((suggestion, index) => ({
        ...suggestion,
        ...data[index],
      }));
    });
  };
}
