import styled from 'styled-components';

type Props = {
  background?: string;
};

export const Container = styled.div`
  overflow-x: auto;
  margin-top: 30px;
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid #111;

  .headerColumn {
    display: flex;
    padding-bottom: 10px;
  }
`;

export const TableBody = styled.div`
  &:nth-child(even) {
    background-color: #f6f9fc;
  }

  &:hover {
    background-color: #57e4cd4d;
  }

  .bodyColumn {
    display: flex;
    padding: 16px 0;
    cursor: pointer;
  }
`;

export const Circle = styled.div<Props>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => props.background};
`;

export const Select = styled.select`
  padding: 2px 10px;
`;

export const Actions = styled.div`
  display: flex;
  column-gap: 20px;
  margin: 0 auto;
`;

export const SectionAddNewItem = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Checkbox = styled.input`
  width: 32px;
  height: 32px;
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
`;
