import React, { useState } from 'react';

import { AssistantsData } from 'src/utils/staffDataset';

import { TagsCell, TagsColName, TagsColValue, TagsRow } from './styles';

type Props = {
  item: AssistantsData;
  index: number;
};

const StaffRowDoA: React.FC<Props> = ({ item, index }: Props) => {
  return (
    <>
      <tr style={{ cursor: 'pointer' }}>
        <td className="text-left">{item.assistant}</td>
        {item &&
          item?.data.map((count, di) => (
            <td key={`count-${count}-${di}`}>
              {count ? count : <span className="opacity-2">{0}</span>}
            </td>
          ))}
        <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
          <strong>{item.total}</strong>
        </td>
      </tr>
    </>
  );
};

export default StaffRowDoA;
