import React from 'react';

import { useQuery } from 'react-query';

import { getLinks } from 'src/api/documents';
import { Layout } from 'src/components';
import Dashboard from 'src/layouts/Dashboard';
import { Card } from 'src/modules';

const UTRinstall: React.FC = () => {
  const { data: link, isLoading } = useQuery('links', getLinks);

  return (
    <Layout>
      <Dashboard title="UTR Install">
        <Card className="p-3">
          <a href="#download-file">
            <h4>1. Download the file</h4>
          </a>
          <a href="#install-extension">
            <h4>2. Install the Extension on Chrome</h4>
          </a>
          <a href="#login-uk">
            <h4>3. Login on GOV UK</h4>
          </a>
          <a href="#login-extension">
            <h4>4. Login on the Extension</h4>
          </a>
          <a href="#start-using">
            <h4>5. Start using it</h4>
          </a>
        </Card>
        <div style={{ maxWidth: 1400 }}>
          <section id="download-file">
            <Card className="p-3">
              <h2 className="mb-4">1. Download the file.</h2>
              <div className="ml-5">
                <p className="text-lg mb-3">
                  Download the file that we will use in the extension and unzip
                  it. Download{' '}
                  <a href={!isLoading && link.url_utr_bot} download>
                    here
                  </a>
                  .
                </p>
                <div className="w-50 mt-2">
                  <a href={!isLoading && link.url_utr_bot} download>
                    <img
                      src="/images/download.png"
                      style={{ width: '200px' }}
                    />
                  </a>
                </div>
              </div>
            </Card>
          </section>
          <section id="install-extension">
            <Card className="p-3">
              <h2 className="mb-4">2. Install the extension on Chrome.</h2>
              <div className="ml-5">
                <p className="text-lg mb-3">
                  on Chrome click the options icon, hover over More Tools {'> '}
                  Extensions.
                </p>
                <div className="w-100 mt-4 d-flex flex-row">
                  <div className="w-50">
                    <img className="w-100" src="/images/threedots.png" />
                  </div>
                  <div className="w-50">
                    <img className="w-100" src="/images/extension.png" />
                  </div>
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="ml-5">
                <p className="text-lg mb-3">
                  Ensure that the "Developer mode" checkbox in the top
                  right-hand corner is checked.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/developer_mode.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="ml-5">
                <p className="text-lg mb-3">Click the Load unpacked button.</p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/load_unpacked.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  Select the folder you've downloaded and unzipped.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/select_directory.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="ml-5">
                <p className="text-lg mb-3">
                  You should can see the new extension installed.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/utr_extension.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  Click in the extension button and pin the UTR - Tommy Stax.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/pin-extension.png" />
                </div>
              </div>
            </Card>
          </section>
          <section id="login-uk">
            <Card className="p-3">
              <h2 className="mb-4">3. Login on GOV UK.</h2>
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  Open the Gov UTR page on a new tab and make sure you are
                  logged in.
                  <br />
                  <a
                    href="https://www.tax.service.gov.uk/shortforms/form/SA1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.tax.service.gov.uk/shortforms/form/SA1
                  </a>
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/gov_login.png" />
                </div>
              </div>
            </Card>
          </section>
          <section id="login-extension">
            <Card className="p-3">
              <h2 className="mb-4">4. Login on the extension.</h2>
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  Click on the extension icon and login using your CMS
                  credentials.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/extension_login.png" />
                </div>
              </div>
            </Card>
          </section>
          <section id="start-using">
            <Card className="p-3">
              <h2 className="mb-4">5. Start using it.</h2>
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  On the customer page, click on the extension and the customer
                  ID will appear, click on get data.
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/get_data.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  You will be redirected to the Gov UTR with all information
                  pre-filled.
                </p>
                <div className="w-50 mt-2">
                  <img
                    className="w-100"
                    src="/images/costumer_information.png"
                  />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  Check if the information is correct, scroll down and click
                  "next" and then "submit".
                </p>
                <div className="w-50 mt-2">
                  <img className="w-100" src="/images/next.png" />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <div className="w-100 ml-5">
                <p className="text-lg mb-3">
                  From now on on any customer page on the CMS you'll be able to
                  do this autmatically.
                </p>
              </div>
            </Card>
          </section>
        </div>
      </Dashboard>
    </Layout>
  );
};

export default UTRinstall;
