import { AuthApi } from 'src/api';
import { action, computed, observable, makeObservable } from 'src/modules';
import { Storage } from 'src/services';

const INITIAL_USER_DATA = {
  id: 0,
  name: '',
  email: '',
  image: '',
  role_id: 0,
  user_rule: '',
  is_2fa_configured: false,
};

const INITIAL_2FA_DATA = {
  QR_image: '',
  secret_key: '',
};

export default class AuthStore {
  @observable
  userData: User = INITIAL_USER_DATA;

  @observable
  twoFAData: Setup2faResponse = INITIAL_2FA_DATA;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getUserName(): string {
    return this.userData.name;
  }

  @action
  getUser = async () => {
    if (this.userData.email) return this.userData;

    const user = await Storage.getUser();

    this.userData = user;

    return user;
  };

  @action
  setUser = (user: User): void => {
    Storage.setUser(user);

    this.userData = user;
  };

  @action
  login = async (credentials: Credentials): Promise<void> => {
    const { access_token, user } = await AuthApi.login(credentials);

    this.setUser(user);

    Storage.setToken(access_token);
  };

  @action
  setup2fa = async (): Promise<void> => {
    const data = await AuthApi.setup2fa();

    this.twoFAData = data;
  };

  @action
  setOtp = async (otp: string): Promise<void> => {
    const { access_token } = await AuthApi.setOtp(otp);

    Storage.setToken(access_token);
  };

  @action
  fetchMe = async (): Promise<User> => {
    const response = await AuthApi.fetchMe();

    this.userData = response;
    await Storage.setUser(response);
    return response;
  };

  @action
  logout = (): void => {
    Storage.clearWholeStorage();

    this.userData = INITIAL_USER_DATA;
    this.twoFAData = INITIAL_2FA_DATA;
  };

  @action
  clearStore = (): void => {
    this.userData = INITIAL_USER_DATA;
    this.twoFAData = INITIAL_2FA_DATA;
  };
}
