//@TODO: Refactor this entire file

export type TNotification =
  | "Inbox"
  | "E-Signature"
  | "Income"
  | "Expense"
  | "HMRC Process";

export enum ENotification {
  "Inbox" = 1,
  "E-Signature" = 6,
  "Income" = 7,
  "Expense" = 8,
  "HMRC" = 9,
}

export enum ENotificationTabLink {
  "Inbox" = "inbox",
  "E-Signature" = "esignature",
  "Income" = "tax_income",
  "Expense" = "tax_expense",
  "Expenses" = "tax_expense",
  "HMRC" = "hmrc",
  "HMRC Process" = "hmrc",
  "Tax assistant assign" = "task_history",
  "Tax Return" = "task_history",
}

export const getNotificationTab = (value: TNotification) =>
  ENotificationTabLink[value] || "details";
