import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Toastify } from 'src/modules';

import './FormsTab.css';
import { FormSelectedButton } from './styles';
import { SectionContainer } from 'src/components/SectionContainer';

import { TaxReturnList } from './Tabs/TaxReturnList';
import { TaxReturnForm } from './Tabs/TaxReturnForm';
import { FormsList } from './Tabs/FormsList';
import { TaxCalculator } from './Tabs/TaxCalculator';

import { SA100 } from './Tabs/SA100';
import { SA110 } from './Tabs/SA110';
import { SA102 } from './Tabs/SA102';
import { SA101 } from './Tabs/SA101';
import { SA103f } from './Tabs/SA103f';
import { SA103s } from './Tabs/SA103s';

import { SUCCESS } from 'src/utils';

import { 
    getTaxReturn, 
    deleteSA102, 
    deleteSA103F, 
    deleteSA103S,
    restoreSA102,
    restoreSA103S,
    restoreSA103F,
    calculateTax,
    submitTaxReturn,
    calculateTaxAndSubmit
} from 'src/api/forms-tab';

const types = ['Tax Return List', 'Add Tax Return', 'Forms List', 'SA100', 'SA102', 'SA103f', 'SA103s', 'SA110'];

const FormsTab: React.FC = ({ data: userData}) => {
    
    const [userId, setUserId]   = useQueryParam('id', NumberParam);
    const [taxYear, setTaxYear] = useQueryParam('tax_year', NumberParam);
    
    const [active, setActive]           = useState(types[0]);
    const [taxReturnData, setTaxReturnData] = useState<any>({});
    const [taxReturnId, setTaxReturnId] = useState(0);

    const [sa100Id, setSa100Id] = useState(0);
    const [sa101Id, setSa101Id] = useState(0);
    const [sa102Id, setSa102Id] = useState(0);
    const [sa110Id, setSa110Id] = useState(0);
    const [sa103fId, setSa103fId] = useState(0);
    const [sa103sId, setSa103sId] = useState(0); 
    
    const [sa102IsView, setSa102IsView] = useState<boolean>(false);
    const [sa103fIsView, setSa103fIsView] = useState<boolean>(false);
    const [sa103sIsView, setSa103sIsView] = useState<boolean>(false); 

    const { data, isLoading, refetch } = useQuery(
        'getTaxReturn',
        async () => {
            const { data } = await getTaxReturn(userId, taxYear);
            return data;
        },
        {
            enabled: true,
            onError: ({ message }) => {
                Toastify.toast.error(message);
            },
        },
    );

    const { mutate: mutationDeleteSA102 } = useMutation(deleteSA102, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationDeleteSA103F } = useMutation(deleteSA103F, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationDeleteSA103S } = useMutation(deleteSA103S, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationRestoreSA102 } = useMutation(restoreSA102, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationRestoreSA103S } = useMutation(restoreSA103S, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationRestoreSA103F } = useMutation(restoreSA103F, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationCalculateTax } = useMutation(calculateTax, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationSubmitTaxReturn } = useMutation(submitTaxReturn, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });

    const { mutate: mutationCalculateTaxAndSubmit } = useMutation(calculateTaxAndSubmit, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
    });


    function onActionClick( event:any )
    {
        
        if(event.target.id === 'editTaxReturn') // 
        {
            setActive('Add Tax Return');
            setTaxReturnId(event.target.value);
        } 
        else if(event.target.id === 'viewTaxReturn')
        {
            setActive('Add Tax Return');
            setTaxReturnId(event.target.value);
        } 
        else if(event.target.id === 'listTaxReturn')
        {
            setActive('Tax Return List');
            setTaxReturnId(event.target.value);
            refetch()
        } 
        else if(event.target.id === 'listFormsTaxReturn')
        {
            setActive('Forms List');
            setTaxReturnId(event.target.value);
        } 
        else if(event.target.id === 'edit_sa100_form') // SA100        
        {
            setActive('SA100');
            setSa100Id(event.target.value);
        } 
        else if(event.target.id === 'add_sa101_form')    // SA101    
        {
            setActive('SA101');
            setSa101Id(0);
        } 
        else if(event.target.id === 'edit_sa101_form')        
        {
            setActive('SA101');
            setSa101Id(event.target.value);
        } 
        else if(event.target.id === 'add_sa102_form')   // SA102    
        {
            setActive('SA102');
            setSa102IsView(false);
            setSa102Id(0);
        } 
        else if(event.target.id === 'edit_sa102_form')        
        {
            setActive('SA102');
            setSa102IsView(false);
            setSa102Id(event.target.value);
        } 
        else if(event.target.id === 'view_sa102_form')        
        {
            // view
            setActive('SA102');
            setSa102IsView(true);
            setSa102Id(event.target.value);
        } 
        else if(event.target.id === 'delete_sa102_form')        
        {
            const id = event.target.value;
            if (id > 0) {
                const formData = {
                    _method: 'DELETE',
                    sa102_form_id: id,
                };
          
                mutationDeleteSA102(formData);
            }

            setActive('Forms List');

        } 
        else if(event.target.id === 'restore_sa102_form')        
        {
            const id = event.target.value;
            if (id > 0) {          
                mutationRestoreSA102(id);
            }
            setActive('Forms List');

        } 

        else if(event.target.id === 'add_sa103s_form')   // SA103s    
        {
            setActive('SA103s');
            setSa103sIsView(false);
            setSa103sId(0);
        } 
        else if(event.target.id === 'edit_sa103s_form')     
        {
            setActive('SA103s');
            setSa103sIsView(false);
            setSa103sId(event.target.value);
        } 
        else if(event.target.id === 'view_sa103s_form')     
        {
            //view
            setActive('SA103s');
            setSa103sIsView(true);
            setSa103sId(event.target.value);
        } 
        else if(event.target.id === 'delete_sa103s_form')     
        {
            const id = event.target.value;
            if (id > 0) {
                const formData = {
                    _method: 'DELETE',
                    sa103s_form_id: id,
                };
          
                mutationDeleteSA103S(formData);
            }

            setActive('Forms List');
        } 
        else if(event.target.id === 'restore_sa103s_form')        
        {
            const id = event.target.value;
            if (id > 0) {          
                mutationRestoreSA103S(id);
            }
            setActive('Forms List');

        } 
        else if(event.target.id === 'add_sa103f_form') // SA103f     
        {
            setActive('SA103f');
            setSa103fIsView(false);
            setSa103fId(0);
        } 
        else if(event.target.id === 'edit_sa103f_form')      
        {
            setActive('SA103f');
            setSa103fIsView(false);
            setSa103fId(event.target.value);
        } 
        else if(event.target.id === 'view_sa103f_form')      
        {
            //view
            setActive('SA103f');
            setSa103fIsView(true);
            setSa103fId(event.target.value);
        } 
        else if(event.target.id === 'delete_sa103f_form')      
        {
            const id = event.target.value;
            if (id > 0) {
                const formData = {
                    _method: 'DELETE',
                    sa103f_form_id: id,
                };
          
                mutationDeleteSA103F(formData);
            }
            setActive('Forms List');
        } 
        else if(event.target.id === 'restore_sa103f_form')        
        {
            const id = event.target.value;
            if (id > 0) {          
                mutationRestoreSA103F(id);
            }
            setActive('Forms List');

        } 
        else if(event.target.id === 'edit_sa110_form') // SA110          
        {
            setActive('SA110');
            setSa110Id(event.target.value);
        }

        else if(event.target.id === 'calculate_tax')         
        {
            setTaxReturnId(event.target.value);
            if (taxReturnId > 0)        
                mutationCalculateTax(taxReturnId);            
        }
        else if(event.target.id === 'TaxCalculator')          
        {
            setActive('TaxCalculator');
            setTaxReturnId(event.target.value);          
        }

        else if(event.target.id === 'submit_in_live')          
        {
            setTaxReturnId(event.target.value);
            if (taxReturnId > 0){
                const formData = {
                    tax_return_id: taxReturnId,
                };
                mutationSubmitTaxReturn(formData);
            }                 

        }

        else if(event.target.id === 'submit_real')    
        {
            setTaxReturnId(event.target.value);
            if (taxReturnId > 0){
                const formData = {
                    tax_return_id: taxReturnId,
                    is_test_in_live: '0'
                };
                mutationCalculateTaxAndSubmit(formData);
            } 
        }

    
    }

    function onNewTaxReturn()
    {
        setActive("Add Tax Return");
        setTaxReturnId(0);
    }

    function onListTaxReturn()
    {
        setActive('Tax Return List');
        setTaxReturnId(0);
        refetch()
    }

    function onGoBack()
    {
        if(taxReturnId > 0)
            setActive('Forms List');
    }

    return (
        <>
            <FormSelectedButton  onClick={onListTaxReturn} active={active} types="Tax Return List">
                Tax Return List
            </FormSelectedButton>
            <FormSelectedButton  onClick={onNewTaxReturn} active={active} types="Add Tax Return">
                Add Tax Return
            </FormSelectedButton>
            {
                taxReturnId > 0 && (
                    <FormSelectedButton  onClick={onGoBack} active={active} types="Go Back">
                        Go Back
                    </FormSelectedButton>
                )
            }

            <SectionContainer active={active} type="Tax Return List">
                {
                    !isLoading && <TaxReturnList  onClick={onActionClick} dataList={data}/>
                }
            </SectionContainer>

            <SectionContainer active={active} type="Add Tax Return"> 
                <TaxReturnForm
                    userId={userId!} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}
                />
            </SectionContainer>

            { /* forms list */ }
            <SectionContainer active={active} type="Forms List">
                <FormsList taxReturnId={taxReturnId} setTaxReturnData={setTaxReturnData} onClick={onActionClick}/>                
            </SectionContainer>

            <SectionContainer active={active} type="SA100" >
                { sa100Id > 0 && <SA100 sa100formId={sa100Id} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/> }
            </SectionContainer>

            <SectionContainer active={active} type="SA101" >
                <SA101 sa101formId={sa101Id} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/>
            </SectionContainer>

            <SectionContainer active={active} type="SA102" >
                <SA102 view={sa102IsView} sa102formId={sa102Id} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/>
            </SectionContainer>

            <SectionContainer active={active} type="SA103s" >
                <SA103s view={sa103sIsView} sa103SformId={sa103sId} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/>
            </SectionContainer>

            <SectionContainer active={active} type="SA103f" >
                <SA103f view={sa103fIsView} sa103FformId={sa103fId} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/>
            </SectionContainer>

            <SectionContainer active={active} type="SA110" >
                { sa110Id > 0 && <SA110 sa110formId={sa110Id} taxYear={taxYear!} taxReturnId={taxReturnId} onClick={onActionClick}/> }
            </SectionContainer>

            <SectionContainer active={active} type="TaxCalculator" >               
                <TaxCalculator userData={userData} taxReturnId={taxReturnId}/>
            </SectionContainer>

        </>
    );
};

export default FormsTab;
