import styled from 'styled-components';

export const BackgroundStatus = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white.default};
  opacity: 80%;
  height: 100%;
  width: 100vw;
  z-index: 2;
`;

export const StatusContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextStatus = styled.div`
  position: absolute;
  top: 50px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.red.main};
  width: 100%;
  max-width: 200px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white.default};
`;
