import { styled } from 'src/modules';

const getBGColor = (color) => {
  switch (color) {
    case 'primary':
      return `var(--primary)`;
    case 'danger':
    case 'bug':
    case 'fix':
    case 'red':
      return `var(--red)`;
    default:
      return `var(--primary)`;
  }
};
const getColor = (color) => {
  switch (color) {
    case 'danger':
    case 'bug':
    case 'fix':
    case 'red':
      return `white`;
    default:
      return `black`;
  }
};

export const DateTitle = styled.h4``;

export const LogBadge = styled.span`
  display: inline-block;
  font-size: 0.85em;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  margin-bottom: 5px;
  background-color: ${({ color }) => getBGColor(color)};
  color: ${({ color }) => getColor(color)};
`;
