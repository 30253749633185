import React from 'react';

import { Layout } from 'src/components';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Container } from 'src/modules';

// markup
const ExternalExpenseFormsInformation: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="External Expense Forms">
        <Card>
          <Container className="mw-100 p-3">
            <div className="d-flex justify-content-center">
              <h3>SelfEmployment Expenses Form Detail</h3>
            </div>
            <h4 className="mt-4">Personal Information:</h4>
            <div className="border-bottom pb-4 mb-3">
              <div className="d-flex justify-content-between mt-4">
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Full Name</b>
                  </p>
                  <p className="m-0">Mr Jason Stanley Worboyes</p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Email</b>
                  </p>
                  <p className="m-0">jasonw609@gmail.com</p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>UTR Number</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>National Insurance</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Date of Birth</b>
                  </p>
                  <p className="m-0">14-05-1974</p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Address</b>
                  </p>
                  <p className="m-0">57 Chalk Pit Avenue, Orpington, Kent</p>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Postal Code</b>
                  </p>
                  <p className="m-0">BR53JJ</p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Tax Year</b>
                  </p>
                  <p className="m-0">2021</p>
                </div>
                <div className="w-25"></div>
              </div>
            </div>
            <div className="d-flex flex-column border-bottom">
              <div className="d-flex flex-column mb-4">
                <p className="m-0 mb-2">
                  <b>Do you have PAYE employment expenses?</b>
                </p>
                <p className="m-0">No</p>
              </div>
              <div className="d-flex flex-column mb-4">
                <p className="m-0 mb-2">
                  <b>
                    During the past year, have you visited more than one
                    location?
                  </b>
                </p>
                <p className="m-0">No</p>
              </div>
              <div className="d-flex flex-column mb-4">
                <p className="m-0 mb-2">
                  <b>
                    Did you use a vehicle or public transport to get to those
                    sites?
                  </b>
                </p>
                <p className="m-0">No</p>
              </div>
              <div className="d-flex flex-column mb-3">
                <p className="m-0 mb-2">
                  <b>How did you get to work?</b>
                </p>
                <p className="m-0">Own vehicle</p>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="border-bottom pt-3">
                <p>
                  <b>Public Transport Cost:</b>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-3 border-bottom">
                <p>
                  <b>Public Costs Total: </b>£0.00
                </p>
              </div>
              <div className="border-bottom pt-3">
                <p>
                  <b>Private Transport Cost:</b>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-3 border-bottom">
                <p>
                  <b>Private Costs Total: </b>£0.00
                </p>
              </div>
              <div className="border-bottom pt-3">
                <p>
                  <b>Tools & Material Cost:</b>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-3 border-bottom">
                <p>
                  <b>Tools & Material Costs Total: </b>£0.00
                </p>
              </div>
              <div className="border-bottom pt-3">
                <p>
                  <b>Clothes Cost:</b>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-3 border-bottom">
                <p>
                  <b>Clothes Costs Total: </b>£0.00
                </p>
              </div>
            </div>
            <h4 className="mt-4">Telephone, Stationery & Office:</h4>
            <div className="border-bottom d-flex flex-column pb-4 mb-3">
              <div className="d-flex mt-4">
                <div className="w-50">
                  <p className="m-0 mb-2">
                    <b>Monthly Landline / Broadband Cost</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
                <div className="w-50">
                  <p className="m-0 mb-2">
                    <b>Percentage(%) of use landline / broadband for work</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
              </div>
              <div className="w-50 mt-4">
                <p className="m-0 mb-2">
                  <b>Monthly Mobile Cost</b>
                </p>
                <p className="m-0">-</p>
              </div>
              <div className="w-50 mt-4">
                <p className="m-0 mb-2">
                  <b>Monthly Cost Notes</b>
                </p>
                <p className="m-0">-</p>
              </div>
              <div className="w-50 mt-4">
                <p className="m-0 mb-2">
                  <b>Monthly Mobile Insurance Cost</b>
                </p>
                <p className="m-0">-</p>
              </div>
              <div className="w-50 mt-4">
                <p className="m-0 mb-2">
                  <b>Monthly Mobile Insurance Cost Notes</b>
                </p>
                <p className="m-0">-</p>
              </div>
              <div className="w-50 mt-4">
                <p className="m-0 mb-2">
                  <b>Percentage(%) of use Mobile for work</b>
                </p>
                <p className="m-0">-</p>
              </div>
              <div className="d-flex mt-4">
                <div className="w-50">
                  <p className="m-0 mb-2">
                    <b>Cost for any new Laptops / Computers</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
                <div className="w-50">
                  <p className="m-0 mb-2">
                    <b>Percentage(%) of use your laptop / computer</b>
                  </p>
                  <p className="m-0">-</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3 border-bottom">
              <p>
                <b>Telephone, Stationery & Office Costs Total: </b>£0.00
              </p>
            </div>
            <h4 className="mt-4">Food & Other Expenses:</h4>
            <div className="w-25 mt-4">
              <p className="m-0 mb-2">
                <b>Public liability insurance</b>
              </p>
              <p className="m-0">-</p>
            </div>
            <div className="d-flex justify-content-end mt-3 pt-3 border-bottom border-top">
              <p>
                <b>Food & Other Costs Total: </b>£0.00
              </p>
            </div>
            <div className="d-flex flex-column mt-3">
              <div className="d-flex flex-column mb-4">
                <p className="m-0 mb-2">
                  <b>Do you have PAYE employment expenses?</b>
                </p>
                <p className="m-0">No</p>
              </div>
              <p className="m-0 mb-2">Bank Detail</p>
              <div className="d-flex justify-content-between border-bottom pb-2">
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Account Holder Name</b>
                  </p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Account Sort Code</b>
                  </p>
                </div>
                <div className="w-25">
                  <p className="m-0 mb-2">
                    <b>Account Number</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3 border-bottom">
              <p>
                <b>Grand Total: </b>£0.00
              </p>
            </div>
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default ExternalExpenseFormsInformation;
