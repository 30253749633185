import React, { useState } from 'react';

import { getCustomersContingency } from 'src/api/customer.api';
import { Layout } from 'src/components';
import ContingencyList from 'src/components/ContingencyList';
import { usePagination } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';

function CustomersContingency() {
  const [data, setData] = useState();

  const { changePage, changePerPage, hasPagination, isLoading, pagRes } =
    usePagination(getCustomersContingency, setData, 'customersContingency');

  return (
    <Layout>
      <Dashboard title="Customers Contingency">
        <ContingencyList
          data={!isLoading && data}
          changePage={changePage}
          changePerPage={changePerPage}
          hasPagination={hasPagination}
          pagRes={pagRes}
          isLoading={isLoading}
        />
      </Dashboard>
    </Layout>
  );
}

export default CustomersContingency;
