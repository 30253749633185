import React, { useState, useEffect } from 'react';

import { NumberParam, useQueryParam } from 'use-query-params';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import {
  Layout,
  TableHead,
  AddJobSubCategory,
  CustomPagination,
  JobSubCategoriesRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import {
  Container,
  Row,
  Col,
  Card,
  CustomInput,
  Button,
  Alert,
} from 'src/modules';

// markup
const JobSubCategories: React.FC = () => {
  const { query } = useFilters();

  const [jobsubcategories, setJobsubcategories] = useState([]);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState(null);
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [jobCategoryOptions, setJobCategoryOptions] = useState([]);
  const [jobCategorySelected, setJobCategorySelected] = useQueryParam(
    'c',
    NumberParam,
  );

  const getJobCategories = async (): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.get(API.JOB_CATEGORIES);

      if (status === 200) {
        const arraySize = res.total_pages - res.next_page + 1;
        const pagesArray = [...Array(arraySize).keys()].map(
          (i) => i + res.next_page,
        );

        const promises = pagesArray.map(
          async (item) =>
            await request.get(`${API.JOB_CATEGORIES}?page=${item}`),
        );

        const getAllPageRes = await Promise.all(promises);
        const options = [...res.data];
        getAllPageRes.forEach((el) => options.push(el.data));
        setJobCategoryOptions(options);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async (): Promise<void> => {
    let url: string = API.JOB_SUB_CATEGORIES + query;
    if (!jobCategorySelected) url += '?c=11';

    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.get(url);

      if (status === 200) {
        setJobsubcategories(res.data);
        setTotalPages(res.total_pages);
        setPagRes({
          next_page: res.next_page,
          total_pages: res.total_pages,
          next_page_url: '',
          previous_page_url: '',
        });
        setHasPagination(res.has_more);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobCategories();
  }, []);

  useEffect(() => {
    getData();
  }, [page, jobCategorySelected]);

  const jobCategorySelectedHandle = (e): void => {
    setJobCategorySelected(
      e.target.value.length < 1 ? undefined : e.target.value,
    );
  };

  const changePage = (pageNumber: number): void => {
    setJobsubcategories([]);
    setPage(pageNumber);
    setPagRes({
      next_page: pageNumber + 1,
      total_pages: totalPages,
      next_page_url: '',
      previous_page_url: '',
    });
  };

  const statusChangeFetch = async (url) => {
    setLoading(true);
    setError(null);

    try {
      await request.put(url);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const onStatusChange = async (item) => {
    await statusChangeFetch(
      `${API.JOB_SUB_CATEGORIES_STATUS_CHANGE}/${item.id}`,
    );
    const jobSubCategoriesUpdate = [];
    jobsubcategories.map((job) => {
      if (job.id === item.id) {
        job.status = job.status === 'Enabled' ? 'Disabled' : 'Enabled';
      }
      jobSubCategoriesUpdate.push(job);
    });
    setJobsubcategories(jobSubCategoriesUpdate);
  };

  const [showAddcategory, setShowAddCategory] = useState(false);

  const createFetch = async (url, body) => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.post(url, body);

      if (
        status === 200 &&
        res.job_category_id === Number(jobCategorySelected)
      ) {
        const jobSubCategoriesUpdate = [res];
        jobsubcategories.map((item) => {
          jobSubCategoriesUpdate.push(item);
        });
        setJobsubcategories(jobSubCategoriesUpdate);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  function create(e) {
    e.preventDefault();
    const formChildrens = [...e.target];
    const newSubJob = {
      job_category_id: undefined,
      name: '',
    };
    formChildrens.map((item, index) => {
      if (item.value !== '') {
        if (item.getAttribute('id') === 'jobCategoriesSelect') {
          newSubJob.job_category_id = Number(item.value);
        }
        if (item.getAttribute('name') === 'name') {
          newSubJob.name = item.value;
        }
      }
    });
    if (newSubJob.name !== '' && newSubJob.job_category_id !== undefined) {
      createFetch(`${API.JOB_SUB_CATEGORIES_CREATE}`, newSubJob);
    }
  }

  const deleteJob = async (id: number): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const { status } = await request.delete(
        `${API.JOB_SUB_CATEGORIES_DELETE}?id=${id}`,
      );

      if (status === 200) {
        const jobSubCategoriesUpdate = jobsubcategories.filter(
          (job) => job.id !== id,
        );
        setJobsubcategories(jobSubCategoriesUpdate);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateJob = async (
    name: string,
    categoryId: number,
    id: number,
  ): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const { status } = await request.post(
        `${API.JOB_SUB_CATEGORIES_UPDATE}?id=${id}`,
        {
          job_category_id: categoryId,
          name: name,
        },
      );

      if (status === 200) {
        const jobSubCategoriesUpdate = jobsubcategories.map((item) => {
          if (id === item.id) {
            item.job_category_id = categoryId;
            item.name = name;
          }
          return item;
        });

        setJobsubcategories(jobSubCategoriesUpdate);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Dashboard title="Job Sub Categories">
        <Row className="justify-content-end mb-4">
          <Col xs="8">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setShowAddCategory(true);
              }}
              outline
            >
              Add New Job Sub Category
            </Button>
          </Col>
          <Col xs="4">
            <CustomInput
              value={jobCategorySelected || ''}
              type="select"
              id="expenseSelect"
              name="customSelect"
              onChange={jobCategorySelectedHandle}
            >
              <option value="">Select...</option>
              {jobCategoryOptions.map((item, index) => (
                <option key={`searchOpt${index}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </CustomInput>
          </Col>
        </Row>

        {showAddcategory && (
          <AddJobSubCategory
            optionsSelect={jobCategoryOptions}
            onClose={() => {
              setShowAddCategory(false);
            }}
            createJob={(e) => create(e)}
          />
        )}

        {error && <Alert color="danger">Error: {error.message}</Alert>}

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Sub Category</Col>
              <Col xs="3">Category</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {loading && <Loading />}

            {jobsubcategories.map((item, index) => (
              <JobSubCategoriesRow
                key={`${item.name}_${index}`}
                active={item.status === 'Enable'}
                value={item.id}
                onStatusChange={() => onStatusChange(item)}
                idCategory={item.job_category_id}
                subCategoryName={item.name}
                updateJob={(name, categoryId) =>
                  updateJob(name, categoryId, item.id)
                }
                deleteJob={() => deleteJob(item.id)}
                categoryOptions={jobCategoryOptions}
              />
            ))}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => changePage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default JobSubCategories;
