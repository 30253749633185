import styled from 'styled-components';

interface Props {
  modalIsOpen?: boolean;
  sideMenuOpen?: boolean;
}

export const Container = styled.div<Props>`
  visibility: ${(props) => (props.modalIsOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.modalIsOpen ? 1 : 0)};
  transition: visibility 0.3s;
  background: ${({ theme }) => theme.colors.black.default};
  z-index: 10;
`;

export const ShadowContainer = styled.div<Props>`
  display: ${(props) => (props.modalIsOpen ? 'block' : 'none')};
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.black.default};
  opacity: 0.7;
  z-index: 11;
`;

export const ModalContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 6rem;
  left: ${(props) => (props.sideMenuOpen ? '420px' : '140px')};
  height: ${({ modalIsOpen }) => (modalIsOpen ? 517 : 130)}px;
  width: 352px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white.default};
  padding: 28px 20px;
  transition: all 0.3s ease-in-out;
  z-index: 12;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ModalTitleText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.title.s}px;
  color: black !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ModalTitleButton = styled.button`
  position: relative;
  left: 5px;
  font-size: ${({ theme }) => theme.fontSizes.title.s}px;
  border: none;
  background: transparent;
  margin-bottom: 12px;
`;

export const AssistantListContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-top: 5px;
`;

export const AssistantList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0 0;
  cursor: pointer;
`;

export const AssistantName = styled.span`
  font-size: 20px;
  margin-left: 15px;
  color: black !important;
`;
