import React from 'react';

import { getInitials } from 'src/utils';

type Props = {
  onClick?: any;
  name: string;
  image?: string;
  className?: string;
  id?: string;
};

const Avatar: React.FC<Props> = ({ onClick, name, image, className, id }) => {
  return (
    <div
      id={id}
      className={'avatar-customer mr-2 ' + (className ? className : '')}
      onClick={onClick}
      style={onClick && { cursor: 'pointer' }}
    >
      {!image && (
        <div className="avatar-customer-initials d-flex justify-content-center align-items-center text-muted">
          {getInitials(name)}
        </div>
      )}
      {image && <img title={name} alt={name} src={image} />}
    </div>
  );
};

export default Avatar;
