import React, { memo, useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import { listStatusTags } from 'src/api/status-tags';
import API from 'src/data/API';
import { TStatusInfoLabel, IStatusInfo } from 'src/models';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from 'src/modules';

import StatusInfoItem from './StatusInfoItem';

type Props = {
  user_id: number;
  info?: IStatusInfo[];
  year: number;
};

const StatusInfo: React.FC<Props> = ({ info, year, user_id }) => {
  const [local, setLocal] = useState<IStatusInfo[]>(info);
  const [hasDropdown, setHasDropdown] = useState(info.length > 2);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const { data: tags = [] } = useQuery(['listStatusTags'], listStatusTags);

  const showAddModal = () => {
    setModal(true);
  };

  const isActive = (label: TStatusInfoLabel) => {
    if (!local || !local.length) return false;

    for (let index = 0; index < local.length; index++) {
      if (label === local[index].status_label) return true;
    }
  };

  const addNewStatus = async (id: number) => {
    const status_label_ids = [id].filter((e) => e); // check if label/id is not empty

    try {
      const { data: res, status } = await request.post(
        API.USERS_STATUSINFO_ADD,
        {
          user_id,
          tax_year: year,
          status_label_ids,
        },
      );

      if (status === 200) {
        const { id, status_label } = res.contact_status[0];

        setLocal((prevState) => [...prevState, { id, status_label }]);
        setHasDropdown(local.length > 2);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const onDelete = async (item: IStatusInfo) => {
    const backup = [...local];
    const temp = local.filter((status) => item.id !== status.id);
    setLocal(temp);

    try {
      const { status } = await request.get(
        `${API.USERS_STATUSINFO_REMOVE}?id=${item.id}`,
      );

      if (status !== 200) {
        setLocal(backup);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getItems = () => {
    if (local.length <= 2) {
      return local;
    }

    return local.slice(0, 1);
  };

  const getDropdown = () => {
    if (local.length <= 2) return [];
    return local.slice(2);
  };

  return (
    <>
      {getItems().map((item: IStatusInfo, index: number) => (
        <StatusInfoItem
          key={`info_${index}`}
          item={item}
          onRemove={() => {
            onDelete(item);
          }}
        />
      ))}
      {hasDropdown && (
        <div className="d-flex justify-content-between" style={{ width: 144 }}>
          <Dropdown
            size="sm"
            className="mb-1"
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              color="primary"
              style={{ minHeight: 25, height: 25 }}
              size="sm"
              caret
            >
              More ...
            </DropdownToggle>
            <DropdownMenu className="shadow pl-3 pr-2 py-3">
              {getDropdown().map((item: IStatusInfo, index: number) => (
                <StatusInfoItem
                  key={`info_${index}`}
                  item={item}
                  onRemove={() => {
                    onDelete(item);
                  }}
                />
              ))}
            </DropdownMenu>
          </Dropdown>
          <StatusInfoItem inline onAdd={showAddModal} />
        </div>
      )}
      {!hasDropdown && (
        <div className="d-flex justify-content-end w-100">
          <StatusInfoItem inline={true} onAdd={showAddModal} />
        </div>
      )}

      <Modal centered isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>New Request Status</ModalHeader>
        <ModalBody className="d-flex flex-wrap">
          {tags.map((item) => (
            <Button
              key={item.status_label_name}
              outline={isActive(item.status_label_name as TStatusInfoLabel)}
              className="m-1"
              color="primary"
              onClick={() => {
                addNewStatus(item.id);
              }}
            >
              {item.status_label_name}
            </Button>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(StatusInfo);
