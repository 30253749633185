import { NumberParam, useQueryParam } from 'use-query-params';

import { getCurrentFiscalYear } from '../utils';

function useYear() {
  const [year, setYear] = useQueryParam('tax_year', NumberParam);
  const taxYear = year || getCurrentFiscalYear();

  return [taxYear, setYear] as const;
}

export default useYear;
