import React from 'react';

import { Link } from 'gatsby';

import { Layout } from 'src/components';
import Dashboard from 'src/layouts/Dashboard';
import { Card } from 'src/modules';
import { DateTitle, LogBadge } from 'src/styles/pages/changelog/styles';
import { Routes } from 'src/utils';

const ChangeLog: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="Changelog">
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>06 Oct 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Message center is Live</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>13 Oct 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Message center testing at the production server</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>05 Oct 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Message center testing at the develop server</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>29 Sep 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Assistants Teams page preview- (WIP)</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Several minor fixes and performance improvements</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>21 Sep 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Assistants changes log page</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Hubspot 72 hour rule sync</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>20 Sep 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                Disable HMRC Submission if the Customer has the tag: Do Not
                Submit
              </li>
              <li>Filter option: "Sign Up Date" on Sort dropdown</li>
              <li>Improved API time (V2) for User Information and HMRC</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Bug on NI on the current Mobile App</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>19 Sep 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Recreate the PDF file when UTR is added to a Customer</li>
              <li>Error message when a Customer has been deleted</li>
              <li>2 new Tax Status filters at the overview page</li>
              <ul>
                <li>Income SignedOff</li>
                <li>Expense SignedOff</li>
              </ul>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Bug on NI on the current Mobile App</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>16 Sep 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Quick filters on Dashboard</li>
              <li>E-Signature checkmark on Tab</li>
              <li>
                Background processes for Message Center (under the hood only)
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Bug when adding a tag on the overview page</li>
              <li>Several fixes on the CMS and its API</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>25 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Hubspot new rules</li>
              <ul>
                <li>
                  n72hr_rule - TRUE if 72 hs after signup the user has not
                  uploaded Income docs or has not started expenses
                </li>
                <li>
                  inactive_client - TRUE if 120hs without any user activity
                  (e.g. added income or expenses, no inbox messages sent
                </li>
                <li>
                  retention_needed - TRUE if the customer has the status tag
                  "Cancelled Claim" added
                </li>
              </ul>
              <li>Line chart on Dashboard Sign ups</li>
              <li>Home reverted to customers</li>
              <li>Deed of Assignment data on Staff (WIP)</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Assistants log for auto-assign (WIP)</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>19 Aug 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>HMRC tab data not refreshing when dropdown year changes</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>18 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>UTR Bot updated for PAYE fields</li>
              <li>Dashboard Refunds breakdown per day</li>
              <li>User Disabled or Deleted page detection and alert</li>
              <li>New Home with search bar only</li>
              <li>Tax Calc Tab - Beta</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Fix/update counter after mark notification as read #439</li>
              <li>Fix Assistants limit on notifications filter</li>
              <li>Assistants limit increased to 200</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>12 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard percentage on totals</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Tax Income and Expenses auto update buttons and Traffic Lights
                when clicking Submit {`->`} Checked {`->`} Signed Off
              </li>
              <li>
                Auto complete for adding user on Customer's page Referrals tab
              </li>
              <li>
                Chronological order issue -for some clients- on Task History Tab
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>11 Aug 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Status Tags instability due to updates on its rules and logs
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>09 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Percentage on Dashboard totals</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Tax Expenses multiple employers dropdown issue fixed</li>
              <li>
                Error -for some clients- when adding Status Tag on overview page
              </li>
              <li>Error when removing Status tag on Customer page</li>
              <li>
                Order of inbox messages for a few clients within an short time
                window
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>04 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Badges with status for all years on customer's page</li>
              <li>
                Green tick at E-Signature's tab if the user has contract file
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Hubspot Sync script for all clients (it'll take time to
                propagate)
              </li>
              <li>
                Tax Expenses dropdown fixed for some clients with more than one
                Employment per year
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>03 Aug 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard periodicity dropdowns</li>
              <li>
                New and Returning tags on clients
                <ul>
                  <li>
                    Returning: if the user has any past years marked as Paid
                  </li>
                  <li>New: if the user has no past years marked as Paid</li>
                </ul>
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Staff timezone</li>
              <li>Tags sync with Hubspot</li>
              <li>Expenses totals not adding up for some clients</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>20 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Parking transport was not shoing on expenses</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>25 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Performance page available</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>20 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Employment Type tag on user's page</li>
              <li>Shifts data input</li>
              <li>
                New properties sync to Hubspot, Current Year Status, Last tax
                Return
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>19 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Autocomplete on new message templates in the Inbox</li>
              <li>Add Client on referrals have a search option now</li>
              <li>
                There is now an option to remove referrals from the referrals
                list of a customer (bin icon)
              </li>
              <li>Staff - Everyone checkbox</li>
              <li>
                The CMS Marketing role allows external access to the Dashboard
                only
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Hubspot concat owner that was updating on CMS changes</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>13 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard HMRC Submit and Not paid tabs added</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Staff HMRC Sumit and Modify data fix</li>
              <li>Notification for when the user edit Income or Expenses</li>
              <li>Error response improved when user is deleted</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>08 July 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Referrals loads more when scrolling down, changed to 100 per
                page
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>07 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                Notification trigger when Customer or Admin update Expenses
              </li>
              <li>
                Deed Of Assignment file name: full_name + deed-of-assignment +
                tax_year.pdf
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Notification date improvenments</li>
              <li>Tax Income filters rename and Others fixed</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>06 July 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Income Filters on Tax Income</li>
              <li>Staff split of HMRC Submit and Modify</li>
              <li>New CMS role for Manager</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Tax Income addition when Add to Income is untick</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>30 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard dates periodicity option available</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>29 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff charts stacked by catgerory</li>
              <li>
                Assistants list sort by columns for: Name, Role, Availability
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Notification read without page reload on Task History</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>28 June 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>New Tab Expenses Dropdown for PAYE data loading</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>27 June 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                UTR bot undefined data -{' '}
                <Link to={Routes.UTR_INSTALL}>please re-install</Link>
              </li>
              <li>Loading icon on messages read icon</li>
              <li>Available toggle shortcurt removed to Assistants</li>
              <li>Loading bar on New Tab Expenses</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>24 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff - Income and Expenses submitted</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Date format to 24h on Tags report</li>
              <li>Tax Income items percentage calc preventing edit</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>23 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff bar chart</li>
              <li>Deleted accounts can now be enabled by Assistants</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Inbox loading error on the App</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>20 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Toggle Assistant availability at overview page header</li>
              <li>Hubspot Customers' properties now have multiple year</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>16 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff - Data in Tabs</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Print button on Expenses</li>
              <li>
                HMRC amount mismatch between CMS and App for some customers
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>15 June 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                UTR bot install updated to remove dots and commas from Address,
                please re-download!
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>13 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>UTR bot install instructions page</li>
              <li>Search Filter and Restore deleted accounts from Overview</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>10 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff - Messages per assistant</li>
              <li>Wise export columns updates</li>
              <li>Hubspot status tags</li>
              <li>Customer page now shows Referred by Name instead of ID</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Inbox templates limit removed</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>9 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Staff - HMRC per assistant</li>
              <li>Dev note: Server switch to Vercel</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>API flow tests</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>8 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Extra fields on Wise Export</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Duplicated emails script on Hubspot</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>6 June 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                Cancel Payment Due button is now a badge (X) on the Payment
                Button
              </li>
              <li>
                Auto-assign a random assistant when Users sign up to the App and
                have uploaded at least 1 item in income and the expenses is
                greater than 0
              </li>
              <li>
                When an assistant is auto-assigned a "Quote" tag is also added
                to the new user
              </li>
              <li>Delete user button shows a popup to confirm the action</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Dark mode on Expenses Ratio</li>
              <li>Infinity value on Expenses Ratio formula</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>1 June 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Tax code error check improved</li>
              <li>Status tags error check improved</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>31 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                Expenses percentage ratio to Income (edit) - 1st stage to remove
                the need to export a spreadsheet
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>64-8 form auto fill on e-signature</li>
              <li>User Details proof of ID permission fixed</li>
              <li>Hubspot duplicated emails glitch</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>31 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                Hubspot new fileds (Tax Status and Conatct Owner) to match the
                CMS data
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Fixed issue on filters due to new Dynamic Status Tags</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>26 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dynamic Status Tags</li>
              <li>
                HMRC Submit has extra security checks to certify amounts saved
                are correct
              </li>
              <li>Hubspot integration in Beta</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>User referrals tab loads more on scroll</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>23 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>New Tax Expenses - testing stage</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>19 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Admin utils page with VAT export</li>
              <li>Marketing and Devs roles introduced</li>
              <li>Local Database Anonymisation for all developers</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>More improvenments Error messages</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>17 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                New API for Paid Status enforcing accounts auth and security
              </li>
              <li>
                Backend validation on serveral places based on Assistant Role
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Referrals users have link to their page</li>
              <li>Improvenment on some Error messages</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>13 May 2022</DateTitle>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Referral list on Customer tab</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>12 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Total and colors on Dashboard tables</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Improved notifications count loading time on overview page
              </li>
              <li>Referrals page search</li>
              <li>Theme selection is now saved</li>
              <li>Redirect users to login page when session is expired</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>10 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>How you here added to Filters</li>
              <li>Assistants avatars size optimisation</li>
              <li>Add a referral on the CMS - Customer referrals tab</li>
              <li>Notification popup</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Redirect Assistants to login page on account error/expired
              </li>
              <li>Inbox messages not displaying the correct "Read by"</li>
              <li>Disallow Expenses error fixed</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>05 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>
                UTR extension updated to new Google's requirements, manifest 3.0
              </li>
              <li>Referrals tab edit and delete for payout</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Fixed an issue causing HMRC refund amout to change when a
                referral payout was added. (This same behaviour happened in the
                old CMS)
              </li>
              <li>
                When income moves year, it now updates the income date to match
                the new year
              </li>
              <li>Income submit button updated without the need for reload</li>
              <li>Changed status tag from AddTookRefund to TookRefund</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>04 May 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard Marketing ROI</li>
              <li>Assistants image resize and pattern on upload</li>
              <li>Customer search optimisations</li>
              <li>Filters reload the page on change</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Disallow expenses</li>
              <li>User Referrals pagination</li>
              <li>Tax History Tax Code history (:action)</li>
              <li>Reset filters assign the correct URL and Dropdown values</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>28 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Added Penalty status</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>29 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Dashboard AgentFee - WIP</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Add referral on Customer Referral tab</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>28 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Added Penalty status</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>27 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Log Assistant on HMRC Refund changes</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Inbox infinite scroll loading more messages</li>
              <li>Paid Status icon bug on missing Cancel Payment Due button</li>
              <li>Print button on expenses tab</li>
              <li>Delete user on Details tab</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>26 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Crop and rotate images on Add Income</li>
              <li>500 items per page on overview</li>
              <li>Export of all customers for VAT purposes</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Server time fixed for BST</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>25 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Log Assistant for Deletion of Customers</li>
              <li>Log Assistant for Paid status</li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                Mileage checker UKVehicleData API fixed for previous year calc
              </li>
              <li>
                App Expenses fixed a bug preventing users to add Vehicle data
              </li>
              <li>Server time fixed for BST</li>
              <li>Year set as 0 on some new 2023 customers</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>19 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Optimised Search, 10x faster than before</li>
              <li>TaxCode status added</li>
              <li>Delete user now saves a log</li>
              <li>Early Payout Push notification disabled</li>
              <li>
                When a client requests UTR on the App, it's added to their Task
                History
              </li>
              <li>
                When a user is edtting income and add a new file, a notification
                is added on the CMS, previously it was only for new Income
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>
                If the user answers NO to the question:
                <br />
                During the last year, have you visited more than one location?
                <br />
                Transport costs are not added to the totals
              </li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>13 April 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>Changelog started</li>
              <li>
                HMRC Paid button disabled if the year status is Paid and the
                assistant has no credentials
              </li>
              <li>Default year set to 2022</li>
              <li>
                Client details udpate shows error reason if it cannot be updated
              </li>
              <li>
                Notification added when Client (edit) upload an income file
              </li>
              <li>Client UTR request on the App added to Task History</li>
              <li>Client deleted log</li>
              <li>TaxCode status tag added</li>
              <li>
                Assistant credentials required to request export and set as Paid
              </li>
            </ul>
            <LogBadge color="fix">Fixes</LogBadge>
            <br />
            <ul>
              <li>Exported Expenses for PAYE was always ZERO</li>
              <li>New year was set as 0 in some cases</li>
            </ul>
          </div>
        </Card>
        <Card className="p-3 align-items-start">
          <div>
            <DateTitle>07 March 2022</DateTitle>
            <LogBadge>Feature</LogBadge>
            <br />
            <ul>
              <li>CMS is live 🎉</li>
            </ul>
          </div>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default ChangeLog;
