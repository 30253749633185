import React, { useEffect, useState } from 'react';

import { Layout, TableHead, MailSettingsRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, observer } from 'src/modules';
import store from 'src/stores';

// markup
const MailSettings: React.FC = () => {
  const [mailSettings] = useState(store.mailSettings);
  const [loading, setLoading] = useState(true);

  const fetchMails = async () => {
    try {
      await mailSettings.getMails();
    } catch {
      // showAlert({ message: ERRORS.GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMails();
  }, []);

  return (
    <Layout>
      <Dashboard title="Mail Settings">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="4">About</Col>
              <Col xs="3">Subject & From</Col>
              <Col xs="3">Emails</Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>
            {loading && <Loading />}
            {mailSettings.mails.map((mail: Mail) => (
              <MailSettingsRow key={mail.id} id={mail.id} />
            ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default observer(MailSettings);
