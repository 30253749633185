import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { listAdmins } from 'src/api/admin';
import {
  addAssistantToTeam,
  createTeam,
  deleteTeam,
  editTeam,
  getListTeams,
  removeAssistantTeam,
} from 'src/api/teams';
import { Layout } from 'src/components';
import Teams from 'src/components/admins/Teams';
import { SearchBar } from 'src/components/common';
import { Loading } from 'src/components/ui';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Col,
  Row,
  Toastify,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'src/modules';
import { SUCCESS } from 'src/utils/enums/messages.enum';

const ExpensesPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSearchUserModal, setShowSearchUserModal] = useState(false);
  const [showDeleteAssistantModal, setShowDeleteAssistantModal] =
    useState(false);
  const [teamIdSelected, setTeamIdSelected] = useState(0);
  const [teamSelected, setTeamSelected] = useState<TeamsData>();
  const [assistantIdSelected, setAssistantIdSelected] = useState(0);
  const [adminList, setAdminList] = useState<AdminData[]>([]);

  const {
    data: listTeams,
    isLoading,
    refetch,
  } = useQuery(['listTeams'], getListTeams, {
    onError: ({ message }: any) => {
      Toastify.toast.error(message);
    },
  });

  const { query } = useFilters();

  const {
    data: adminsListResult = [],
    isLoading: isLoadingAdmins,
    isFetching: isFetchingAdmins,
  } = useQuery(['listAdmins', query], listAdmins, {
    onSuccess: (result) => {
      setAdminList(result);
      // console.log(result);
    },
    onError: ({ response }: any) => {
      Toastify.toast.error(response);
    },
  });

  /* To avoid add the assistant twice in the same team */
  useEffect(() => {
    const adminsListFiltered = adminsListResult.filter(
      (item) =>
        !teamSelected?.assistants?.some(
          (assistant) => assistant.id === item.id,
        ),
    );

    setAdminList(adminsListFiltered);
  }, [teamSelected, adminsListResult]);

  const { mutate: mutateCreateTeam } = useMutation(createTeam, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      setShowModal(false);
      refetch();
    },
    onError: ({ message }: any) => {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutateEditTeam } = useMutation(editTeam, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      setShowEditModal(false);
      refetch();
    },
    onError: ({ message }: any) => {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutateDeleteTeam } = useMutation(deleteTeam, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      setShowDeleteModal(false);
      refetch();
    },
    onError: ({ message }: any) => {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutateAddAssistantToTeam } = useMutation(addAssistantToTeam, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      setShowSearchUserModal(false);
      refetch();
    },
    onError: ({ message }: any) => {
      Toastify.toast.error(message);
    },
  });

  const { mutate: mutateDeleteAssistantTeam } = useMutation(
    removeAssistantTeam,
    {
      onSuccess: () => {
        Toastify.toast.success(SUCCESS.GENERIC);
        setShowDeleteAssistantModal(false);
        refetch();
      },
      onError: ({ message }: any) => {
        Toastify.toast.error(message);
      },
    },
  );

  const handleSelect = (id: number) => {
    const admin = adminList.find((admin) => admin.id === id);

    mutateAddAssistantToTeam({
      assistant_id: admin?.id,
      team_id: teamIdSelected,
    });
  };

  const showModalCreateTeam = () => {
    setShowEditModal(false);
    setShowEditModal(false);
    setShowModal(true);
  };

  const onShowModalEditTeam = (id: number, teamName: string) => {
    setTeamIdSelected(id);
    setTeamName(teamName);
    setShowEditModal(true);
  };

  const onShowModalDeleteTeam = (id: number) => {
    setTeamIdSelected(id);
    setShowDeleteModal(true);
  };

  const onShowModalSearchUser = (
    teamId: number,
    teamName: string,
    team: TeamsData,
  ) => {
    setTeamIdSelected(teamId);
    setTeamName(teamName);
    setShowSearchUserModal(true);
    setTeamSelected(team);
  };

  const onShowModalDeleteAssistantTeam = (
    assistantId: number,
    teamId: number,
  ) => {
    setAssistantIdSelected(assistantId);
    setTeamIdSelected(teamId);
    setShowDeleteAssistantModal(true);
  };

  const onCreateOrTeam = () => {
    if (showEditModal) {
      mutateEditTeam({ id: teamIdSelected, teamLabel: teamName });
    } else {
      mutateCreateTeam(teamName);
    }
  };

  const onDeleteTeam = () => {
    mutateDeleteTeam({ id: teamIdSelected });
  };

  const onDeleteAssistantTeam = () => {
    mutateDeleteAssistantTeam({
      assistant_id: assistantIdSelected,
      team_id: teamIdSelected,
    });
  };

  const modalToggle = () => {
    if (showEditModal) {
      setShowEditModal(false);
    } else {
      setShowModal(!showModal);
    }
  };

  const modalDeleteToggle = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const modalSearchUserToggle = () => {
    setShowSearchUserModal(!showSearchUserModal);
  };

  const modalDeleteAssistantToggle = () => {
    setShowDeleteAssistantModal(!showDeleteAssistantModal);
  };

  return (
    <Layout>
      <Dashboard title="Assistants Teams">
        <Row>
          <Col xs="12" className="d-flex justify-content-end ">
            <Button
              color="primary"
              type="button"
              onClick={() => showModalCreateTeam()}
            >
              Create a Team
            </Button>
          </Col>
        </Row>

        {isLoading && <Loading />}

        <Row style={{ marginLeft: 20, marginTop: 30 }}>
          {!isLoading &&
            listTeams.data.map((item: TeamsData) => {
              return (
                <Teams
                  key={item.id}
                  teamId={Number(item.id)}
                  teamName={item.team_label}
                  onShowModalEditTeam={onShowModalEditTeam}
                  onShowModalDeleteTeam={onShowModalDeleteTeam}
                  onShowModalSearchUser={onShowModalSearchUser}
                  onShowModalDeleteAssistantTeam={
                    onShowModalDeleteAssistantTeam
                  }
                  assistants={item.assistants}
                  team={item}
                  adminList={adminList}
                />
              );
            })}
        </Row>

        <Modal
          centered
          isOpen={showEditModal ? showEditModal : showModal}
          toggle={modalToggle}
        >
          <ModalHeader toggle={modalToggle}>
            {showEditModal ? 'Edit Team Name' : 'Create a Team'}
          </ModalHeader>
          <ModalBody className="d-flex flex-wrap">
            <Input
              bsSize="lg"
              type="text"
              name="name"
              placeholder="Team Name"
              onChange={(e) => setTeamName(e.target.value)}
              value={teamName ?? ''}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={modalToggle}>
              Cancel
            </Button>
            <Button className="m-1" color="primary" onClick={onCreateOrTeam}>
              {showEditModal ? 'Edit' : 'Create'}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal centered isOpen={showDeleteModal} toggle={modalDeleteToggle}>
          <ModalHeader toggle={modalDeleteToggle}>
            Confirm Team Delete: {teamName}
          </ModalHeader>

          <ModalFooter>
            <Button color="secondary" onClick={modalDeleteToggle}>
              Cancel
            </Button>
            <Button className="m-1" color="primary" onClick={onDeleteTeam}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          centered
          isOpen={showSearchUserModal}
          toggle={modalSearchUserToggle}
        >
          <ModalHeader toggle={modalSearchUserToggle}>
            Add an Assistant to {teamName}
          </ModalHeader>
          <ModalBody className="d-flex flex-wrap">
            <SearchBar
              data={adminList}
              onSelect={handleSelect}
              isLoading={isLoadingAdmins || isFetchingAdmins}
              placeholder="Name, email or UTR"
              icon={faChevronRight}
            />
          </ModalBody>
        </Modal>

        <Modal
          centered
          isOpen={showDeleteAssistantModal}
          toggle={modalDeleteAssistantToggle}
        >
          <ModalHeader toggle={modalDeleteAssistantToggle}>
            Confirm Team Delete: {'test'}
          </ModalHeader>

          <ModalFooter>
            <Button color="secondary" onClick={modalDeleteAssistantToggle}>
              Cancel
            </Button>
            <Button
              className="m-1"
              color="primary"
              onClick={onDeleteAssistantTeam}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Dashboard>
    </Layout>
  );
};

export default ExpensesPage;
