import { styled } from 'src/modules';

interface Props {
  showSources?: boolean;
  sendMessage?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 510px;
  min-height: 100px;
  background: ${(props) => props.theme.colors.white.catskill};
  padding: ${(props) => props.theme.spacing.m}px;

  & button {
    outline: 0 !important;
    border: 0 !important;
    cursor: pointer;
  }
`;

export const SectionQuickReply = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuickReplyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 40px;
`;

export const FilesAttachedContainer = styled.div`
  display: flex;
`;

export const ListFilesAttached = styled.ul`
  margin: 0 auto;
`;

export const ItemFilesAttached = styled.li`
  list-style-type: none;
  position: relative;
  right: 10px;
  top: -50px;

  .close {
    position: relative;
    left: 5px;
    cursor: pointer;
  }
`;

export const QuickReplyItem = styled.button<Props>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black.default};
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  margin-right: ${({ theme }) => theme.spacing.s}px;
  height: 33px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.sendMessage ? 0.3 : 'initial')};
`;

export const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.s}px;
  position: relative;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  background: ${(props) => props.theme.colors.white.default};
  border: 1px solid ${(props) => props.theme.colors.gray.geyser};
  border-radius: ${({ theme }) => theme.radius.xs}px;
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  margin-left: ${({ theme }) => theme.spacing.s}px;
`;

export const Input = styled.input`
  display: flex;
  flex-grow: 1;
  background: none !important;
  border: none !important;
  color: ${({ theme }) => theme.colors.black.russian} !important;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.regent} !important;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -42px;
  right: 5px;
`;

export const ButtonIcon = styled.button`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacing.xs}px;
  background: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const SourceContainer = styled.div<Props>`
  visibility: ${(props) => (props.showSources ? 'visible' : 'hidden')};
  display: flex;
  position: absolute;
  align-items: center;
  margin-bottom: ${({ showSources }) => (showSources ? 90 : 0)}px;
  overflow: hidden;
  height: 42px;
  width: ${({ showSources }) => (showSources ? 120 : 40)}px;
  padding: 0 ${({ theme }) => theme.spacing.xs}px;
  background: ${(props) => props.theme.colors.gray.iron};
  opacity: ${(props) => (props.showSources ? 1 : 0)};
  border-radius: ${({ theme }) => theme.radius.m}px;
  margin-left: ${({ showSources }) => (showSources ? -110 : 0)}px;
  transition-property: visibility, margin-bottom, width, opacity, margin-left;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s, 0s, 0.2s, 0s, 0.2s;
`;

export const SendButton = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 32px;
  background: ${({ theme }) => theme.colors.white.mainGost};
  border: 1px solid ${({ theme }) => theme.colors.gray.quartz};
  border-radius: 5px;
  cursor: pointer;
  opacity: ${(props) => (props.sendMessage ? 0.3 : 'initial')};
  pointer-events: ${(props) => (props.sendMessage ? 'none' : 'initial')};
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.red.bittersweet};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.red.bittersweet};
  font-size: 12px;
  cursor: pointer;
`;

export const SectionProgressBar = styled.div`
  position: relative;
  top: 16px;
  margin-left: 42px;
`;

export const Progressbar = styled.progress`
  appearance: none;
  height: 6px;
  width: 100%;

  ::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.red.bittersweet};
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: none !important;
  border: none !important;
  color: ${({ theme }) => theme.colors.black.russian} !important;
  outline: none;
  overflow: hidden;
  width: 100%;
  line-height: 1.1;
  max-height: 450px;
  min-height: 50px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.regent} !important;
    line-height: 20px;
  }
`;
