import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
`;

export const ApplyFiltersButton = styled.button`
  display: flex;
  background: #57e4cd;
  color: #191424;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 48px;
  padding: 6px 24px;
  margin-right: 30px;
  width: 150px;

  &:last-child {
    margin-right: initial;
  }
`;

export const DebitCreditButtonSection = styled.div`
  display: flex;
`;

export const ReconciledOptionsSection = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const DebitCreditButton = styled.button`
  display: flex;
  background: #57e4cd;
  color: #191424;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 48px;
  padding: 6px 24px;
  margin-right: 30px;
  max-width: 100px;
  margin-top: 20px;

  &:last-child {
    margin-right: initial;
  }
`;
