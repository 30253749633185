import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const Wrapper: React.FC<Props> = ({ children }) => (
  <div className={"wrapper"}>{children}</div>
);

export default Wrapper;
