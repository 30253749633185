import { arraySum, formatDate } from 'src/utils';

import {
  handleExcludeDev,
  handleSuperAdminRole,
} from './helpers/handleSuperAdminRole';
import { sortArrayByKey } from './Utils';

export type AssistantsData = {
  id: number;
  assistant: string;
  total: number;
  totalSubmit?: number;
  totalModify?: number;
  totalRead?: number;
  totalSent?: number;
  role_id?: number;
  values: Array<any>;
  data: Array<number>;
  date?: string;
  read?: any;
  sent?: any;
  times?: any;
  normalizeValue?: number | any;
};

export type AssistantsTotalData = {
  id: number;
  assistant: string;
  total: number;
  normalizeValue?: number | any;
};

type DateAxis = {
  label: string;
  assistants: Array<AssistantsData>;
};

export type StaffChartData = {
  byDate: Array<DateAxis>;
  byGroup: Array<AssistantsData>;
};

export type StaffDetailModal = {
  label: string;
  date: string;
};

/**
 * Check if the member is not a Super Admin
 * We're only adding to the list of Admins and Assistants roles 2 | 3
 * @param assistant
 * @param admins
 * @returns TRUE if the assistant is not a Super Admin
 */
const isValidAssistant = (
  assistant: AdminData,
  admins: Array<AssistantsData>,
): boolean => {
  for (let i = 0; i < admins.length; i++) {
    if (
      assistant.id === admins[i].id &&
      !handleSuperAdminRole(admins[i].role_id as number)
    ) {
      //   console.log(admins[i].role_id, admins[i].name);
      return true;
    }
  }
  return false;
};

const isValidAssistantExcludeDev = (
  assistant: AdminData,
  admins: Array<AssistantsData>,
): boolean => {
  for (let i = 0; i < admins.length; i++) {
    if (
      assistant.id === admins[i].id &&
      !handleExcludeDev(admins[i].role_id as number)
    ) {
      return true;
    }
  }
  return false;
};

// Return an Array filtered with valid Assistants only
const getAssistants = (
  data: Array<AssistantsData>,
  admins: Array<AdminData>,
  everyone?: boolean,
) => {
  const arr: Array<AssistantsData> = [];
  for (let i = 0; i < data.length; i++) {
    if (everyone) {
      if (isValidAssistantExcludeDev(data[i] as any, admins as any)) {
        arr.push(data[i]);
      }
    } else {
      if (isValidAssistant(data[i] as any, admins as any)) {
        arr.push(data[i]);
      }
    }
  }
  return arr;
};

const findIndex = (
  uniqueAssistants: Set<number>,
  assistant: AssistantsData,
) => {
  return [...uniqueAssistants].indexOf(assistant.id);
};

const findIndexTotalData = (
  uniqueAssistants: Set<number>,
  assistant: AssistantsTotalData,
) => {
  return [...uniqueAssistants].indexOf(assistant.id);
};

const groupByAssistant = (data: Array<DateAxis>) => {
  const uniqueAssistants = new Set<number>();
  // loop thru all dats and all assistants to be sure everyone is added in case assistant is not in a day's list
  for (let day = 0; day < data.length; day++) {
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      uniqueAssistants.add(assistants[a].id);
    }
  }

  // Prepare Y Axis Dataset grouped by Assistant
  const arr: Array<AssistantsData> = [];
  for (let day = 0; day < data.length; day++) {
    const date = data[day].label;
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      const index = findIndex(uniqueAssistants, assistants[a]);
      if (day === 0)
        arr[index] = {
          assistant: '',
          id: 0,
          total: 0,
          date: '',
          data: [],
          values: [],
        };
      if (arr[index]) {
        arr[index].assistant = assistants[a].assistant;
        arr[index].date = date;
        arr[index].id = assistants[a].id;
        arr[index].values[day] = assistants[a].values;
        arr[index].data[day] = assistants[a].total;
      }
    }
  }

  // Get period total for each assistant
  for (let i = 0; i < arr.length; i++) {
    const total = arraySum(arr[i].data);
    arr[i].total = total;
  }
  const ordered = sortArrayByKey(arr, 'total', 'DESC');
  //   console.log(ordered);

  return ordered;
};

const groupByAssistantDoA = (data: Array<DateAxis>) => {
  const uniqueAssistants = new Set<number>();
  // loop thru all dats and all assistants to be sure everyone is added in case assistant is not in a day's list
  for (let day = 0; day < data.length; day++) {
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      uniqueAssistants.add(assistants[a].id);
    }
  }

  // Prepare Y Axis Dataset grouped by Assistant
  const arr: Array<AssistantsData> = [];
  for (let day = 0; day < data.length; day++) {
    const date = data[day].label;
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      const index = findIndex(uniqueAssistants, assistants[a]);
      if (day === 0)
        arr[index] = {
          assistant: '',
          id: 0,
          total: 0,
          date: '',
          data: [],
          values: [],
        };
      if (arr[index]) {
        arr[index].assistant = assistants[a].assistant;
        arr[index].date = date;
        arr[index].id = assistants[a].id;
        arr[index].values[day] = assistants[a].values;
        arr[index].data[day] = assistants[a].total;
      } else {
        console.log('arr[index]', arr[index]);
      }
    }
  }

  // Get period total for each assistant
  for (let i = 0; i < arr.length; i++) {
    const total = arraySum(arr[i].data);
    arr[i].total = total;
  }
  const ordered = sortArrayByKey(arr, 'total', 'DESC');
  //   console.log(ordered);

  return ordered;
};

const groupByAssistantMessages = (data: Array<DateAxis>) => {
  // console.log(data)
  const uniqueAssistants = new Set<number>();

  // loop thru all dats and all assistants to be sure everyone is added in case assistant is not in a day's list
  for (let day = 0; day < data.length; day++) {
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      uniqueAssistants.add(assistants[a].id);
    }
  }

  // Prepare Y Axis Dataset grouped by Assistant
  const arr: Array<AssistantsData> = [];
  for (let day = 0; day < data.length; day++) {
    const date = data[day].label;
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      const index = findIndex(uniqueAssistants, assistants[a]);
      if (day === 0)
        arr[index] = {
          assistant: '',
          id: 0,
          total: 0,
          date: '',
          data: [],
          values: [],
          read: [],
          sent: [],
        };
      arr[index].assistant = assistants[a].assistant;
      arr[index].date = date;
      arr[index].id = assistants[a].id;
      arr[index].values[day] = assistants[a].values;
      arr[index].data[day] = assistants[a].total;
      arr[index].read[day] = assistants[a].read;
      arr[index].sent[day] = assistants[a].sent;
    }
  }

  // Get period total for each assistant
  for (let i = 0; i < arr.length; i++) {
    const total = arraySum(arr[i].data);
    const totalRead = arraySum(arr[i].read);
    const totalSent = arraySum(arr[i].sent);
    arr[i].total = total;
    arr[i].totalRead = totalRead;
    arr[i].totalSent = totalSent;
  }
  const ordered = sortArrayByKey(arr, 'total', 'DESC');
  //   console.log(ordered);

  return ordered;
};

const groupByAssistantShifts = (data: Array<DateAxis>) => {
  // console.log(data)
  const uniqueAssistants = new Set<number>();

  // loop thru all dats and all assistants to be sure everyone is added in case assistant is not in a day's list
  for (let day = 0; day < data.length; day++) {
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      uniqueAssistants.add(assistants[a].id);
    }
  }

  // Prepare Y Axis Dataset grouped by Assistant
  const arr: Array<AssistantsData> = [];
  for (let day = 0; day < data.length; day++) {
    const date = data[day].label;
    const assistants = data[day].assistants;
    for (let a = 0; a < assistants.length; a++) {
      const index = findIndex(uniqueAssistants, assistants[a]);
      if (day === 0)
        arr[index] = {
          assistant: '',
          id: 0,
          total: 0,
          date: '',
          data: [],
          values: [],
          times: [],
        };
      arr[index].assistant = assistants[a].assistant;
      arr[index].date = date;
      arr[index].id = assistants[a].id;
      arr[index].values[day] = assistants[a].values;
      arr[index].data[day] = assistants[a].total;
      arr[index].times[day] = assistants[a].times;
    }
  }

  // Get period total for each assistant
  for (let i = 0; i < arr.length; i++) {
    const total = arraySum(arr[i].data);
    arr[i].total = total;
  }
  const ordered = sortArrayByKey(arr, 'total', 'DESC');
  //   console.log(ordered);

  return ordered;
};

export const getStaffDataset = ({
  staff,
  admins,
  everyone,
}: DashboardStaffData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < staff.length; s++) {
    data[s] = {
      label: staff[s].date,
      assistants: getAssistants(
        staff[s].data as AssistantsData[],
        admins,
        everyone,
      ),
    };
  }
  const grouped = groupByAssistant(data);
  return { byDate: data, byGroup: grouped };
};

export const getStaffHMRC = ({
  hmrc,
  admins,
}: DashboardStaffData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < hmrc.length; s++) {
    data[s] = {
      label: hmrc[s].date,
      assistants: getAssistants(hmrc[s].data as AssistantsData[], admins),
    };
  }
  //   console.log(data);
  const grouped = groupByAssistant(data);
  return { byDate: data, byGroup: grouped };
};

export const getStaffDoA = ({
  doa,
  admins,
}: DashboardStaffData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < doa.length; s++) {
    data[s] = {
      label: doa[s].date,
      assistants: getAssistants(doa[s].data as AssistantsData[], admins),
    };
  }
  //   console.log(data);
  const grouped = groupByAssistantDoA(data);
  return { byDate: data, byGroup: grouped };
};

export const getStaffMessages = ({
  messages,
  admins,
}: DashboardStaffData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < messages.length; s++) {
    data[s] = {
      label: messages[s].date,
      assistants: getAssistants(messages[s].data as AssistantsData[], admins),
    };
  }
  const grouped = groupByAssistantMessages(data);
  return { byDate: data, byGroup: grouped };
};

export const getStaffDataShift = ({
  shifts,
  admins,
}: DashboardStaffShiftData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < shifts.length; s++) {
    data[s] = {
      label: shifts[s].date,
      assistants: getAssistants(shifts[s].data as AssistantsData[], admins),
    };
  }
  const grouped = groupByAssistantShifts(data);
  return { byDate: data, byGroup: grouped };
};

export const getStaffIncome = ({
  income,
  admins,
}: DashboardStaffData): StaffChartData => {
  const data: Array<DateAxis> = [];
  for (let s = 0; s < income.length; s++) {
    data[s] = {
      label: income[s].date,
      assistants: getAssistants(income[s].data as AssistantsData[], admins),
    };
  }
  //   console.log(data);
  const grouped = groupByAssistant(data);
  return { byDate: data, byGroup: grouped };
};

export const getTotalForChart = (
  values: Array<Array<AssistantsTotalData>>,
): AssistantsTotalData[] => {
  const uniqueAssistants = new Set<number>();
  const arr: Array<AssistantsTotalData> = [];
  values.map((v) => {
    v.map((v: AssistantsTotalData) => {
      const index = findIndexTotalData(uniqueAssistants, v);
      if (index < 0) {
        uniqueAssistants.add(v.id);
        const obj = {
          assistant: v.assistant,
          total: v.total,
          id: v.id,
          normalizeValue: v.normalizeValue,
        };
        arr.push(obj);
      } else {
        arr[index].total = arr[index].total + v.total;
        arr[index].normalizeValue =
          arr[index].normalizeValue + v.normalizeValue;
      }
    });
  });
  return arr;
};

export const normalizeDataChart = (
  values: Array<AssistantsTotalData>,
): AssistantsTotalData[] => {
  //const min = Math.min.apply(null, values.map(item => item.total));
  const max = Math.max.apply(
    null,
    values.map((item) => item.total),
  );
  const arr: Array<any> = [];

  values.map((item) => {
    const normalizeValue = item.total / max;
    const obj = {
      assistant: item.assistant,
      total: item.total,
      normalizeValue: normalizeValue,
      id: item.id,
    };
    arr.push(obj);
  });

  return arr;
};

export const normalizeDataChartSplit = (
  values: Array<AssistantsTotalData>,
  labelSplit: string,
): AssistantsTotalData[] => {
  //const min = Math.min.apply(null, values.map(item => item.total));
  const max = Math.max.apply(
    null,
    values.map((item) => item.total),
  );
  const arr: Array<any> = [];
  values.map((item: any) => {
    const normalizeValue = item.total / max;
    const obj = {
      assistant: item.assistant,
      total: item[labelSplit],
      normalizeValue: normalizeValue,
      id: item.id,
    };
    arr.push(obj);
  });

  return arr;
};
