import styled from 'styled-components';

import { TaxIncomeColor, TaxIncomeTypes } from 'src/utils';

type BadgeProps = {
  type: string;
};

const getTypeColor = (props: BadgeProps): string => {
  switch (props.type) {
    case TaxIncomeTypes.Other:
      return TaxIncomeColor.Other;
    case TaxIncomeTypes.Self:
      return TaxIncomeColor.Self;
    case TaxIncomeTypes.Paye:
      return TaxIncomeColor.Paye;
    case TaxIncomeTypes.Refunded:
      return TaxIncomeColor.Refunded;
    default:
      return TaxIncomeColor.Paye;
  }
};

export const Badge = styled.span<BadgeProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white.default};
  background-color: ${getTypeColor};
  padding: 3px 8px 3px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 0.2rem;
  font-size: 70%;
  font-weight: 600;
  cursor: pointer;
`;
