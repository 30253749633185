export enum TCustomersSort {
  'name_a_z' = 'Name Ascending(A-Z)',
  'name_z_a' = 'Name Descending(Z-A)',
  'email_a_z' = 'Email Ascending(A-Z)',
  'email_z_a' = 'Email Descending(Z-A)',
  'utr_1_9' = 'UTR Ascending(1-9)',
  'utr_9_1' = 'UTR Descending(9-1)',
  'date_1_9' = 'Access Date Ascending',
  'date_9_1' = 'Access Date Descending',
  'sort_by_signup_date' = 'Sign up date',
}
