function compareSortData(a: any, b: any, fieldName: string) {
  let nameA = a[fieldName];
  let nameB = b[fieldName];

  if (nameA !== null && nameB !== null) {
    if (typeof nameA === 'string') {
      nameA = a[fieldName].toLowerCase();
      nameB = b[fieldName].toLowerCase();
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
}

export const sortList = (descOrder: boolean, list: any, fieldName: string) => {
  let ordered;

  if (descOrder) {
    ordered = list
      .sort((a: any, b: any) => compareSortData(a, b, fieldName))
      .reverse();
  } else {
    ordered = list.sort((a: any, b: any) => compareSortData(a, b, fieldName));
  }

  return ordered;
};
