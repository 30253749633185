import React, { memo } from 'react';

import { navigate } from 'gatsby';

import { ICustomer } from 'src/models';
import { highlightText, stringHasValue, Routes } from 'src/utils';

type Props = {
  item: ICustomer;
  query: string;
  isReferralSearch?: boolean;
  onSetReferralId?(id: number): void;
};

function createMarkup(value: any) {
  return { __html: value };
}

const SearchBarItem: React.FC<Props> = ({
  item,
  query,
  isReferralSearch = false,
  onSetReferralId,
}) => {
  const name = highlightText(query, item.full_name);

  const hasEmail = stringHasValue(item.email);
  const hasUTR = stringHasValue(item.utr_no);

  let details = '';
  if (hasEmail && hasUTR) details = `${item.email} - ${item.utr_no}`;
  else if (hasEmail && !hasUTR) details = `${item.email}`;
  else if (!hasEmail && hasUTR) details = `${item.utr_no}`;

  const emailUTR = details ? highlightText(query, details) : '';

  const onSearchItem = () => {
    if (isReferralSearch) {
      if (item && onSetReferralId) {
        const id = item?.id || 0;
        onSetReferralId(id);
      }
    } else {
      navigate(
        `${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tax_year=${item.tax_year}&tab=details`,
      );
    }
  };

  return (
    <li onClick={onSearchItem}>
      <div>
        <strong dangerouslySetInnerHTML={createMarkup(name)} />
      </div>
      <div
        className="text-muted"
        dangerouslySetInnerHTML={createMarkup(emailUTR)}
      />
    </li>
  );
};

export default memo(SearchBarItem);
