import { action, observable } from 'mobx';

import { AdminNotesApi } from 'src/api';

export default class AdminNotesStore {
  @observable
  adminNotes: AdminNotesById = {
    data: [],
    message: '',
  };

  @action
  getAdminNotesById = async (userId: number): Promise<void> => {
    const response = await AdminNotesApi.getAdminNotesById(userId);

    this.adminNotes.data = response.data;
    this.adminNotes.message = response.message;
  };

  @action
  createAdminNote = async (note: AdminNotesCreate): Promise<void> => {
    await AdminNotesApi.createAdminNote(note);
  };

  @action
  updateAdminNoteById = async (
    note: Omit<AdminNotesTypes, 'admin_name'>,
  ): Promise<void> => {
    await AdminNotesApi.updateAdminNoteById(note);
  };
}
