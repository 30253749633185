import React, { useEffect, useState, ChangeEvent, useRef } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import {
  addTransaction,
  updateSelfEmployed,
  updateTransaction,
} from 'src/api/incomes';
import { getTaxIncomeListing } from 'src/api/users';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { PayeButton } from 'src/styles/pages/components/PayeButton';
import { formatCurrency, SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeHeaderButtons,
  IncomeInputsContainer,
  HeaderSelectInput,
  TransactionsSection,
  ViewImageSection,
  OpenContentButton,
  OpenContentContainer,
  ContentContainer,
  OtherInformationsInputSection,
} from '../../styles';
import AddTransactionForm from '../AddTransactionForm';
import {
  AllEditTransactionFormHandle,
  default as EditTransactionForm,
} from '../EditTransactionForm';
import SelfEmployedTransactionsList from '../SelfEmpTransactionsList';
import ViewDocs from '../ViewDocs';
import ViewImage from '../ViewImage';

import {
  EmployerNameSection,
  IncomeGrossSection,
  IncomeTaxSection,
  IncomeNetSection,
  InputDate,
  Logo,
  OtherInformations,
  SaveCancelSection,
  StartDateLabel,
  StartDateSection,
  StudentLoanTypeSection,
  SubmitButton,
  Text,
} from './styles';

const addTransactionFields = {
  client_name: '',
  date: null,
  amount: '',
  files: [],
  cis: null,
};

const filesList: any[] = [];

function TabSelfEmployed() {
  // @refs

  const editTransactionRef = useRef<AllEditTransactionFormHandle>(null);

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const incomes = store.incomes;

  interface HeaderSelfEmployedItem {
    id: number;
    customer_id: number;
    income_type_id: number;
    income_type: string;
    tax_year: number;
    self_employment_type: string;
    description: string | null;
    employer_name: string;
    logo: string | null;
    document: any[];
    start_date: string;
    benefits: {
      has_student_loan: boolean;
      student_loan_type: string | null;
      refund_last_year: string | null;
      has_child_benefits: boolean;
      amount_child_benefits: number | null;
      total_children_received_benefits: number | null;
      still_receiving_benefits: boolean;
    } | null;
    job_category: {
      id: number;
      name: string;
      status: string;
      image_white: string;
      image_black: string;
      created_date: string;
      created_by: number;
      created_ip: number;
      is_visible: number;
      modified_date: string;
      deleted_at: string | null;
    } | null;
    job_sub_category: {
      id: number;
      name: string;
      image_white: string | null;
      image_black: string | null;
      job_category_id: number;
      status: string;
      icon: string | null;
      created_date: string;
      created_by: number;
      created_ip: number;
      is_visible: number;
      modified_date: string;
      deleted_at: string | null;
    } | null;
    total_expenses: number;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    gross_amount: number | string;
    net_amount: number | string;
    tax_amount: number | string;
    editMode: boolean;
    addTransactionFields: {
      client_name: string;
      date: string | null;
      amount: string;
      files: any[];
      cis: string | null;
    };
    transactionList: any[];
    editTransaction: any[];
    openContent: boolean;
    showDocuments: boolean;
  }

  type FieldRules = {
    required: boolean;
    errorMessage?: string;
  };

  type ValidateFields = {
    [key: string]: string | number | boolean | null; // Os campos podem ser de vários tipos
  };

  type Rules = {
    [key: string]: FieldRules;
  };

  // eslint-disable-next-line
  const [headerSelfEmployedList, setHeaderSelfEmployedList] = useState<HeaderSelfEmployedItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [incomeIdSelected, setIncomeIdSelected] = useState(0);
  const [proofDocuments, setProofDocuments] = useState([]);
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [editTransactionButtonClicked, setEditTransactionButtonClicked] =
    useState(false);

  const [openContentParams, setOpenContentParams] = useState({});

  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListing(userId ?? 0, year, 'self_employed'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationUpdateSelfEmployed } = useMutation(
    'updateSelfEmployed',
    updateSelfEmployed,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);
      },
      onError({ error }) {
        Toastify.toast.error(error);
      },
    },
  );

  const { mutate: mutationAddTransaction, isSuccess } = useMutation(
    'addTransaction',
    addTransaction,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setOpenAddTransactionForm(false);
          incomes.refetchTotals();
          incomes.refetchSummary();
        }, 2000);
      },
      onError({ response }) {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (currentTransactionIndex && isSuccess) {
      const list = [...headerSelfEmployedList];

      list[currentTransactionIndex].addTransactionFields.client_name = '';
      list[currentTransactionIndex].addTransactionFields.date = '';
      list[currentTransactionIndex].addTransactionFields.amount = '';
      list[currentTransactionIndex].addTransactionFields.cis = '';

      setHeaderSelfEmployedList(list);
      store.incomes.refetchIncomeTransactions();
    }
  }, [currentTransactionIndex, isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      list.forEach((item, index) => {
        list[index].editMode = false;
        list[index].addTransactionFields = { ...addTransactionFields };
        list[index].transactionList = [...incomes.incomeTransactions];
        list[index].editTransaction = [...incomes.incomeTransactions];
      });

      setHeaderSelfEmployedList(list);
    }
  }, [data, incomes.incomeTransactions]);

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...headerSelfEmployedList];

    list[index][target.name] = target.value;
    setHeaderSelfEmployedList(list);
  };

  const onUpdateSelfImploymentHeaderInfos = (
    editMode: boolean,
    index: number,
    id: number,
  ) => {
    setCurrentTransactionIndex(index);
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    if (editMode) {
      newHeaderSelfEmployedList[index].editMode = false;
      setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
      setEditButtonClicked(true);

      if (newHeaderSelfEmployedList[index].id === id) {
        const itemEdited = newHeaderSelfEmployedList[index];

        let formData = {};

        if (!itemEdited.benefits.has_student_loan) {
          formData = {
            income_type_self_employment_id: itemEdited.id,
            self_employment_type: itemEdited.self_employment_type,
            employer_name: itemEdited.employer_name,
            start_date: itemEdited.start_date,
            has_student_loan: itemEdited.benefits.has_student_loan,
            refund_last_year: itemEdited.benefits.refund_last_year,
            has_child_benefits: itemEdited.benefits.has_child_benefits,
            still_receiving_benefits:
              itemEdited.benefits.still_receiving_benefits,
          };
        } else {
          formData = {
            income_type_self_employment_id: itemEdited.id,
            self_employment_type: itemEdited.self_employment_type,
            employer_name: itemEdited.employer_name,
            start_date: itemEdited.start_date,
            has_student_loan: itemEdited.benefits.has_student_loan,
            refund_last_year: itemEdited.benefits.refund_last_year,
            student_loan_type: itemEdited.benefits.student_loan_type,
            has_child_benefits: itemEdited.benefits.has_child_benefits,
            still_receiving_benefits:
              itemEdited.benefits.still_receiving_benefits,
          };
        }

        mutationUpdateSelfEmployed(formData);
      }
    } else {
      newHeaderSelfEmployedList[index].editMode = true;
      setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
      setEditButtonClicked(false);
    }
  };

  const onCancelAddTransaction = (index: number) => {
    setOpenAddTransactionForm(false);

    const list = [...headerSelfEmployedList];

    list[index].addTransactionFields.client_name = '';
    list[index].addTransactionFields.date = new Date().toISOString();
    list[index].addTransactionFields.amount = '0';

    setProofDocuments([]);
    setHeaderSelfEmployedList(list);
  };

  const onAddTransaction = (index: number, income_id: number) => {
    const fields = headerSelfEmployedList[index];

    const formErrors = validateFields(
      headerSelfEmployedList[index].addTransactionFields,
      {
        client_name: { required: true },
        amount: { required: true },
        date: { required: true },
      },
    );

    const formData = new FormData();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('client_name', fields.addTransactionFields.client_name);
      formData.append('income_id', income_id);
      formData.append('income_type', 'self_employed');
      formData.append('reconciliation_type', 'income');
      formData.append('date', fields.addTransactionFields.date);
      formData.append('amount', fields.addTransactionFields.amount);
      formData.append('files', proofDocuments);

      if (fields.addTransactionFields.cis) {
        formData.append('cis', fields.addTransactionFields.cis);
      }

      mutationAddTransaction(formData);

      const list = [...headerSelfEmployedList];

      list[index].addTransactionFields.client_name = '';
      list[index].addTransactionFields.date = new Date();
      list[index].addTransactionFields.amount = 0;
    }
  };

  const validateFields = (fields: ValidateFields, rules: Rules) => {
    const formErrors: { [key: string]: string } = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onOpenContent = (
    index: number,
    openContent: boolean,
    income_id: number,
  ) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].openContent = !openContent;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
    setIncomeIdSelected(income_id);
  };

  const onShowDocuments = (index: number, showDocuments: boolean) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].showDocuments = !showDocuments;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  };

  // Check possible delete comment lines
  /* const onChangeSplitRange = (value: string, index: number) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;
    newHeaderSelfEmployedList[index].split = Number(value);
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  }; */

  const onCancelUpdateItem = (index: number) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].editMode = false;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  const onInputAddTransactionChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...headerSelfEmployedList];

    const name = target.name as keyof typeof addTransactionFields;
    list[index].addTransactionFields[name] = target.value;
    setHeaderSelfEmployedList(list);
  };

  const onChangeFieldTransaction = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    item: object,
  ) => {
    const target = event.target;
    const list = [...headerSelfEmployedList];

    list[index].editTransaction[currentTransactionIndex][target.name] =
      target.value;

    setHeaderSelfEmployedList(list);
  };

  const {
    mutate: mutationUpdateTransaction,
    isSuccess: isSuccessUpdateTransaction,
  } = useMutation('updateTransaction', updateTransaction, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ error }) {
      Toastify.toast.error(error);
    },
  });

  useEffect(() => {
    if (isSuccessUpdateTransaction) {
      const { index, income_id } = openContentParams;

      setTimeout(() => {
        onOpenContent(index, true, income_id);
        setEditTransactionButtonClicked(false);
      }, 2000);
    }
  }, [isSuccessUpdateTransaction]);

  const onUpdateTransaction = (index: number) => {
    const list = [...headerSelfEmployedList];

    setEditTransactionButtonClicked(true);
    setOpenContentParams({
      index,
      openContent: false,
      income_id:
        list[index].editTransaction[currentTransactionIndex]?.income_id,
    });

    const formData = new FormData();

    formData.append('_method', 'PUT');
    formData.append('user_id', userId);
    formData.append('tax_year', year);
    formData.append('reconciliation_type', 'income');
    formData.append(
      'income_id',
      list[index].editTransaction[currentTransactionIndex]?.income_id,
    );
    formData.append(
      'income_type',
      list[index].editTransaction[currentTransactionIndex]?.income_type,
    );

    formData.append(
      'cis',
      list[index].editTransaction[currentTransactionIndex]?.CIS,
    );

    mutationUpdateTransaction({
      transaction_id: list[index].editTransaction[currentTransactionIndex].id,
      formData,
    });
  };

  let newDocsList: any[] = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <div>
      {!isLoading &&
        headerSelfEmployedList?.length > 0 &&
        headerSelfEmployedList.map((item, index) => {
          return (
            <IncomeContainer key={index}>
              <IncomeHeader>
                <div>
                  <Logo
                    src={handleLogo('self_employed', item.self_employment_type)}
                    alt=""
                  />
                </div>
                <IncomeInputsContainer>
                  <IncomeHeaderFields>
                    <Row>
                      <Col>
                        <EmployerNameSection>
                          <EditInputPie
                            id="employer_name"
                            label="Employer name:"
                            name="employer_name"
                            type="text"
                            value={item.employer_name}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </EmployerNameSection>

                        <StartDateSection>
                          <StartDateLabel htmlFor="start_date">
                            Start Date
                          </StartDateLabel>
                          <InputDate
                            type="date"
                            name="start_date"
                            id="start_date"
                            max="2023-05-17"
                            value={item.start_date}
                            defaultValue={item.start_date}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />
                        </StartDateSection>

                        <IncomeGrossSection>
                          <EditInputPie
                            id="income_gross"
                            label="Income - Gross:"
                            name="income_gross"
                            type="text"
                            value={formatCurrency(item.gross_amount)}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </IncomeGrossSection>

                        <IncomeTaxSection>
                          <EditInputPie
                            id="income_tax"
                            label="Income - Tax:"
                            name="income_tax"
                            type="text"
                            value={formatCurrency(item.tax_amount)}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </IncomeTaxSection>

                        <IncomeNetSection>
                          <EditInputPie
                            id="income_net"
                            label="Income - Net:"
                            name="income_net"
                            type="text"
                            value={formatCurrency(item.net_amount)}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </IncomeNetSection>
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="stillReceivingBenefits">
                            Still receiving benefits:
                          </label>
                          <HeaderSelectInput
                            id="still_receiving_benefits"
                            name="still_receiving_benefits"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value="true"
                              selected={
                                item.benefits?.still_receiving_benefits === true
                              } // Check if item.benefits exists before accessing
                            >
                              Yes
                            </option>
                            <option
                              value="false"
                              selected={
                                item.benefits?.still_receiving_benefits ===
                                false
                              } // Check if item.benefits exists before accessing
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Total expenses:"
                          id="total_expenses"
                          name="total_expenses"
                          type="text"
                          value={formatCurrency(item?.total_expenses)}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={false}
                          disabled
                          maxWidth="160px"
                        />

                        <OtherInformationsInputSection>
                          <label htmlFor="student_loan_type">
                            Student loan type
                          </label>
                          <HeaderSelectInput
                            id="student_loan_type"
                            name="student_loan_type"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={
                              !item.editMode ||
                              !headerSelfEmployedList[index]?.benefits
                                ?.has_student_loan
                            }
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value={item?.benefits?.student_loan_type || ''}
                              selected={!!item?.benefits?.student_loan_type}
                            >
                              {item?.benefits?.student_loan_type || 'Select'}
                            </option>

                            <option value="plan_1">Plan 1</option>
                            <option value="plan_2">Plan 2</option>
                            <option value="both_plans">Both Plans</option>
                            <option value="unsure">Unsure</option>
                          </HeaderSelectInput>
                          {!headerSelfEmployedList[index]?.benefits
                            ?.has_student_loan && (
                            <Text>
                              this field will only be enabled if the field "has
                              student loan" is set to Yes
                            </Text>
                          )}
                        </OtherInformationsInputSection>
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="studentLoan">Has student loan</label>
                          <HeaderSelectInput
                            id="has_student_loan"
                            name="has_student_loan"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value="true"
                              selected={
                                item.benefits?.has_child_benefits === true
                              }
                            >
                              Yes
                            </option>

                            <option
                              value="false"
                              selected={
                                item.benefits?.has_child_benefits === false
                              }
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Refund last year:"
                          id="refund_last_year"
                          name="refund_last_year"
                          type="text"
                          value={item.benefits?.refund_last_year}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="childBenefits">Child Benefits:</label>
                          <HeaderSelectInput
                            id="has_child_benefits"
                            name="has_child_benefits"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value="true"
                              selected={
                                item?.benefits?.has_child_benefits === true
                              }
                            >
                              Yes
                            </option>

                            <option
                              value="false"
                              selected={
                                item?.benefits?.has_child_benefits === false
                              }
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Number of children:"
                          id="total_children_received_benefits"
                          name="total_children_received_benefits"
                          type="text"
                          value={
                            item?.benefits?.total_children_received_benefits
                              ? item?.benefits?.total_children_received_benefits
                              : 0
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                          maxWidth="160px"
                        />
                      </Col>
                    </Row>
                  </IncomeHeaderFields>

                  <IncomeHeaderButtons>
                    <SaveCancelSection>
                      <PayeButton
                        onClick={() =>
                          onUpdateSelfImploymentHeaderInfos(
                            item.editMode,
                            index,
                            item.id,
                          )
                        }
                      >
                        {item.editMode ? 'Save' : 'Edit'}
                      </PayeButton>
                      <PayeButton onClick={() => onCancelUpdateItem(index)}>
                        Cancel
                      </PayeButton>
                    </SaveCancelSection>

                    <PayeButton
                      onClick={() => onShowDocuments(index, item.showDocuments)}
                    >
                      Show Documents
                    </PayeButton>
                  </IncomeHeaderButtons>
                </IncomeInputsContainer>
              </IncomeHeader>

              <div>
                {item.showDocuments && (
                  <div>
                    <ViewImageSection>
                      <ViewDocs docs={item.document} />
                    </ViewImageSection>
                  </div>
                )}
              </div>

              <ContentContainer openContent={item.openContent}>
                <TransactionsSection>
                  {item.openContent && item.id === incomeIdSelected && (
                    <>
                      <TransactionsSection>
                        <h3>Transactions</h3>
                        <SelfEmployedTransactionsList
                          incomeType="self_employed"
                          incomeId={item.id}
                          setOpenAddTransactionForm={setOpenAddTransactionForm}
                          openAddTransactionForm={openAddTransactionForm}
                          successAddTransaction={isSuccess}
                          setCurrentTransactionIndex={
                            setCurrentTransactionIndex
                          }
                          onClick={(id: number) => {
                            editTransactionRef.current?.setSelectedTransaction(
                              id,
                            );
                          }}
                        />
                      </TransactionsSection>

                      <ViewImageSection>
                        <ViewImage
                          transactions={
                            incomes.incomeTransactions &&
                            incomes.incomeTransactions[
                              incomes.indexTransactionSelected
                            ]
                          }
                        />
                      </ViewImageSection>
                    </>
                  )}
                </TransactionsSection>

                <AddTransactionForm
                  openAddTransactionForm={openAddTransactionForm}
                  item={item}
                  index={index}
                  onInputAddTransactionChange={onInputAddTransactionChange}
                  errors={errors}
                  onAddTransaction={onAddTransaction}
                  onCancelAddTransaction={onCancelAddTransaction}
                  onUploadProof={onUploadProof}
                  newDocsList={newDocsList}
                  onRemoveProof={onRemoveProof}
                />

                <EditTransactionForm
                  item={item}
                  index={index}
                  onChangeFieldTransaction={onChangeFieldTransaction}
                  isIncomeTabSelfEmployed
                  ref={editTransactionRef}
                />

                <OtherInformations>
                  <Row>
                    <StudentLoanTypeSection>
                      <Col>
                        <EditInputPie
                          label="Student loan type:"
                          id="student_loan_type"
                          name="student_loan_type"
                          type="text"
                          value={item.benefits?.student_loan_type}
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled
                        />

                        <EditInputPie
                          label="Refund last year:"
                          id="refund_last_year"
                          name="refund_last_year"
                          type="text"
                          value={
                            item.benefits?.refund_last_year
                              ? item.benefits?.refund_last_year
                              : 0
                          }
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled
                        />

                        <OtherInformationsInputSection>
                          <label htmlFor="self_employment_type">
                            Self Employment Type
                          </label>
                          <HeaderSelectInput
                            id="self_employment_type"
                            name="self_employment_type"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value={item.self_employment_type}
                              selected={
                                item.self_employment_type === 'freelancer'
                              }
                            >
                              {item.self_employment_type}
                            </option>

                            <option value="freelancer" selected>
                              Freelancer
                            </option>
                            <option value="gig_worker">Gig Worker</option>
                            <option value="small_business">
                              Small Business
                            </option>
                            <option value="cis">Cis</option>
                            <option value="other">Other</option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Status:"
                          id="status"
                          name="status"
                          type="text"
                          value={
                            !item.status ? (item.status = '') : item.status
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={false}
                          disabled
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Amount child benefits:"
                          id="amount_child_benefits"
                          name="amount_child_benefits"
                          type="text"
                          value={item.benefits?.amount_child_benefits || 0}
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled={!item.editMode}
                        />

                        <EditInputPie
                          label="Total children received:"
                          id="total_children_received_benefits"
                          name="total_children_received_benefits"
                          type="text"
                          value={
                            item.benefits?.total_children_received_benefits
                          }
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Has student loan:"
                          id="has_student_loan"
                          name="has_student_loan"
                          type="text"
                          value={item.benefits?.has_student_loan ? 'Yes' : 'No'}
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled={!item.editMode}
                        />

                        <EditInputPie
                          label="Other:"
                          id="has_child_benefits"
                          name="has_child_benefits"
                          type="text"
                          value={
                            item.benefits?.has_child_benefits ? 'Yes' : 'No'
                          }
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled
                        />
                      </Col>
                      <Col>
                        <EditInputPie
                          label="Still receiving benefits:"
                          id="still_receiving_benefits"
                          name="still_receiving_benefits"
                          type="text"
                          value={
                            item.benefits?.still_receiving_benefits
                              ? 'Yes'
                              : 'No'
                          }
                          editMode={false}
                          onChange={(event) => onInputChange(index, event)}
                          disabled
                        />
                      </Col>
                    </StudentLoanTypeSection>
                  </Row>
                  <Row style={{ marginTop: 40 }}>
                    <SubmitButton
                      onClick={() => onUpdateTransaction(index)}
                      disabled={editTransactionButtonClicked}
                    >
                      Submit
                    </SubmitButton>
                  </Row>
                </OtherInformations>
              </ContentContainer>

              <OpenContentContainer>
                <OpenContentButton
                  onClick={() =>
                    onOpenContent(index, item.openContent, item.id)
                  }
                >
                  {item.openContent ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}
                </OpenContentButton>
              </OpenContentContainer>
            </IncomeContainer>
          );
        })}
    </div>
  );
}

export default observer(TabSelfEmployed);
