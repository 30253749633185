import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';
import { useMutation, useQueryClient } from 'react-query';

import { editAdmin } from 'src/api/admin';
import { Layout } from 'src/components';
import { Form } from 'src/components/admins';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { Toastify } from 'src/modules';
import { ERRORS, Routes } from 'src/utils';

type Props = {
  id: string;
};

const Edit = ({ id }: Props) => {
  const { query } = useFilters();
  const [user, setUser] = useState<AdminData>({} as AdminData);
  const queryClient = useQueryClient();

  const { mutate: editAdminMutation, isLoading } = useMutation(editAdmin, {
    onSuccess() {
      queryClient.invalidateQueries(['listAdmins']);
      navigate(Routes.ADMINS);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const handleSubmit = (form: AddAdminData) => {
    const formattedData = {
      id: Number(id),
      email: form?.email,
      role_id: form?.role_id,
      name: form?.name,
      phone_number: form?.phone_number,
      whatsapp_no: form?.whatsapp_no,
      address: form?.address,
      latitude: form?.latitude,
      longitude: form?.longitude,
      note: form?.note,
      password: form?.password,
    };

    if (form?.image) formattedData.image = form.image;
    editAdminMutation(formattedData);
  };

  useEffect(() => {
    const data =
      queryClient
        .getQueryData<AdminData[]>(['listAdmins', query])
        ?.find((item) => item.id.toString() === id) ?? ({} as AdminData);

    console.log(`DATA: ${JSON.stringify(data)}`);

    if (data) {
      setUser({ ...data, image: undefined });
    }
  }, []);

  return (
    <Layout>
      <Dashboard title="Edit Admin">
        <Form
          onSubmit={handleSubmit}
          initialData={user}
          loading={isLoading}
          isEdit
        />
      </Dashboard>
    </Layout>
  );
};

export default Edit;
