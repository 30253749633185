import request from '../request';
import ResponseError from '../ResponseError';

export const fetchTemplates = async (): Promise<NotificationTemplatesList> => {
  try {
    const { data } = await request.get(`/notification-templates`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getById = async (
  id: number,
): Promise<NotificationTemplateSingle> => {
  try {
    const { data } = await request.get(
      `/notification-templates/edit/?id=${id}`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
export const updateTemplate = async (
  data: NotificationTemplateUpdate,
): Promise<void> => {
  try {
    await request.post(`/notification-templates/edit/?id=${data.id}`, {
      message: data.message,
      admin_message: data.admin_message,
    });
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
