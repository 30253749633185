import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from 'react-query';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import { listStatusTags } from 'src/api/status-tags';
import { setYearClosed } from 'src/api/users';
import API from 'src/data/API';
import { useYear } from 'src/hooks';
import {
  IStatusInfo,
  TStatusInfoLabel,
  TIsPaidStatus,
  ICustomer,
} from 'src/models';
import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  observer,
  Toastify,
} from 'src/modules';
import store from 'src/stores';
import defaultTheme from 'src/theme/default-theme';
import {
  getYearsList,
  getQueryParam,
  validCount,
  CustomerTabs,
  PaidStatusPage,
  Routes,
  TaxStatus,
} from 'src/utils';
import { handleLabels } from 'src/utils/helpers/handleLabels';

import ButtonIcon from '../ButtonIcon';
import { IconDeleteOrDisableCustomer } from '../customers';
import IconCalled from '../customers/IconCalled';
import IconPaidStatus from '../customers/IconPaidStatus/IconPaidStatus';
import IconTaxAssistant from '../customers/IconTaxAssistant';
import IconTaxCode from '../customers/IconTaxCode';
import IconUTR from '../customers/IconUTR';
import StatusInfoItem from '../customers/StatusInfoItem';
import CustomersProgress from '../CustomersProgress';
import { Avatar } from '../ui';

import {
  CurrentYearStatusLabel,
  EmployeeTypeLabel,
  Sanctioned,
  SanctionedContainer,
  YearsLabel,
  YearsToolTip,
} from './styles';

type Props = {
  item: ICustomer;
  taxYear: number;
  onSelectTab(tab: string): void;
  isLoadingInformation: boolean;
  refetchCustomerInformation(): void;
};

const CustomerInformationHeader: React.FC<Props> = ({
  item,
  taxYear,
  onSelectTab,
  isLoadingInformation,
  refetchCustomerInformation,
}) => {
  const [, setLoading] = useState<boolean>(false);
  const [data, setData] = useState(undefined);
  const [paidStatus, setPaidStatus] = useState<TIsPaidStatus>(
    item.is_paid_status,
  );
  const [yearSelected, setYearSelected] = useState(0);
  const [openYearModal, setOpenYearModal] = useState(false);

  useEffect(() => {
    setData(item.status_info);
    setPaidStatus(item.is_paid_status);
  }, [item]);

  const [year, setYear] = useYear();

  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const showAddModal = () => setModal(true);

  const hasNotifications = validCount(item.notifications_count);

  const onClickPaidStatus = (value: TIsPaidStatus) => setPaidStatus(value);

  // @TODO: Move to mobx

  const { data: tags = [] } = useQuery(['listStatusTags'], listStatusTags);

  const { mutate: mutateYearClosed } = useMutation(
    () => setYearClosed(item.id, item.tax_year, !item.year_closed),
    {
      onSuccess: (response) => {
        refetchCustomerInformation();
        setOpenYearModal(false);
        Toastify.toast.success(response.message);
      },
      onError: ({ response }) => {
        setOpenYearModal(false);
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const addNewStatus = async (id: number) => {
    try {
      setLoading(true);
      const status_label_ids = [id].filter((e) => e); // check if label/id is not empty
      const res = await request.post(`${API.PATH}${API.USERS_STATUSINFO_ADD}`, {
        user_id: item.id,
        tax_year: taxYear,
        status_label_ids,
      });

      const newData: IStatusInfo[] = [...data, ...res.data.contact_status];
      const filterId: number[] = [];
      const filterData: IStatusInfo[] = [];
      newData.forEach((el: IStatusInfo) => {
        if (filterId.includes(el.id)) return;
        filterId.push(el.id);
        filterData.push(el);
      });
      setData(filterData);

      setModal(false);
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  // @TODO: Move to mobx
  const onDelete = async (item: IStatusInfo) => {
    try {
      await request.get(
        `${API.PATH}${API.USERS_STATUSINFO_REMOVE}?id=${item.id}`,
      );

      const newData: IStatusInfo[] = data.filter(
        (d: IStatusInfo) => d.id !== item.id,
      );
      setData(newData);
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleYearColor = (taxStatus: string) => {
    switch (taxStatus) {
      case TaxStatus.Paid:
        return defaultTheme.colors.green.main;
      case TaxStatus.IncomeReceived:
        return defaultTheme.colors.orange.main;
      case TaxStatus.ExpenseReceived:
        return defaultTheme.colors.orange.main;
      case TaxStatus.IncomeCompleted:
        return defaultTheme.colors.yellow.main;
      case TaxStatus.ExpenseCompleted:
        return defaultTheme.colors.yellow.main;
      case TaxStatus.Pending:
        return defaultTheme.colors.gray.regent;
      case TaxStatus.Signed:
        return defaultTheme.colors.gray.regent;
      case TaxStatus.InProgress:
        return defaultTheme.colors.green.light;
      default:
        break;
    }
  };

  const onCloseOrReopenModal = () => {
    mutateYearClosed();
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-wrap">
          {data &&
            data.map((dataItem: IStatusInfo, index: number) => {
              return (
                <div className="mr-2" key={`hb${index}`}>
                  <StatusInfoItem
                    item={{
                      id: dataItem.id,
                      status_label: dataItem.status_label,
                      days: dataItem.days,
                    }}
                    onRemove={() => onDelete(dataItem)}
                  />
                </div>
              );
            })}
          <StatusInfoItem onAdd={showAddModal} inline={true} />
        </div>
        <div className="d-flex">
          <Button
            onClick={() => setOpenYearModal(!openYearModal)}
            type="submit"
            color="primary"
          >
            {item.year_closed
              ? `Reopen Year ${item.tax_year}`
              : `Close Year ${item.tax_year}`}
          </Button>
          <IconDeleteOrDisableCustomer
            user={item}
            isDeleteButton
            isIconDelete={false}
          />
        </div>
      </div>
      <Row className="justify-content-between align-items-center">
        <Col xs="12" md="auto">
          <div className="d-flex align-items-center">
            <Avatar name={item.full_name} image={item.avatar} />
            <div className="ml-2" style={{ position: 'relative' }}>
              <p className="mb-0 font-size-lg" style={{ display: 'flex' }}>
                {item.full_name}
                <EmployeeTypeLabel employeeType={item.employee_type}>
                  {handleLabels(item.employee_type)}
                </EmployeeTypeLabel>
                <CurrentYearStatusLabel>
                  {item.current_year_status}
                </CurrentYearStatusLabel>
              </p>
              <p className="mb-0 text-muted">
                {item.utr_no}-{item.postal_code}
              </p>
              {item?.sanctioned && (
                <SanctionedContainer>
                  <Sanctioned>SANCTIONED</Sanctioned>
                </SanctionedContainer>
              )}
            </div>
          </div>
        </Col>
        <Col>
          <CustomersProgress
            onSelectTab={onSelectTab}
            item={item}
            paidStatus={paidStatus}
          />
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          {item.years_status &&
            item.years_status.map((year) => (
              <YearsLabel
                key={year.tax_year}
                backgroundColor={handleYearColor(year.tax_status)}
                onMouseMove={() => setYearSelected(year.tax_year)}
                onMouseOut={() => setYearSelected(0)}
              >
                {year.tax_year}
                <YearsToolTip
                  visible={yearSelected === year.tax_year ? true : false}
                >
                  {year.tax_status}
                </YearsToolTip>
              </YearsLabel>
            ))}
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          {!isLoadingInformation && (
            <IconTaxAssistant
              id={item.id}
              name={item.tax_assistant_name}
              image={item.tax_assistant_image}
            />
          )}
          <IconCalled
            id={item.id}
            called={item.is_called}
            iconDisabled={isLoadingInformation}
          />
          <ButtonIcon
            badge={item.notifications_count}
            outline={!hasNotifications}
            color={!hasNotifications ? 'secondary' : 'primary'}
            id="notifications"
            tooltip="Notifications"
            onClick={() => onSelectTab(CustomerTabs.TASK_HISTORY)}
            disabled={isLoadingInformation}
          >
            <FontAwesomeIcon icon={faBell} />
          </ButtonIcon>
          <IconPaidStatus
            id={item.id}
            year={item.tax_year}
            is_paid_status={paidStatus}
            tax_status={item.tax_status}
            type={PaidStatusPage.INTERNAL}
            allYears={false}
            iconDisabled={isLoadingInformation}
            onClickPaidStatus={onClickPaidStatus}
          />
          <IconUTR
            id={item.id}
            year={item.tax_year}
            requested_utr={item.requested_utr}
            iconDisabled={isLoadingInformation}
          />
          <IconTaxCode
            id={item.id}
            year={item.tax_year}
            complete={item.tax_code_complete}
            iconDisabled={isLoadingInformation}
          />
        </Col>
        <Col xs="12" md="auto">
          <div className="d-flex">
            <div className="xs-12">
              <Form>
                <FormGroup className="mb-0">
                  <Label for="yearEnding">Year Ending</Label>
                  <div className="d-flex">
                    <Input
                      type="select"
                      name="year"
                      value={year}
                      onChange={(event) =>
                        setYear(getQueryParam(event.target.value))
                      }
                    >
                      {getYearsList().map((item, index) => {
                        return <option key={`y${index}`}>{item}</option>;
                      })}
                    </Input>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Modal centered isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>New Request Status</ModalHeader>
        <ModalBody className="d-flex flex-wrap">
          {tags.map((item) => (
            <Button
              key={item.status_label_name}
              className="m-1"
              color="primary"
              onClick={() => {
                addNewStatus(item.id);
              }}
            >
              {item.status_label_name}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal centered isOpen={openYearModal}>
        <ModalHeader>
          {item.year_closed
            ? `Reopen Year ${item.tax_year} ?`
            : `Close Year ${item.tax_year} ?`}
        </ModalHeader>
        <ModalFooter>
          <Button color="primary" onClick={onCloseOrReopenModal}>
            Confirm
          </Button>
          <Button color="secondary" onClick={() => setOpenYearModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default observer(CustomerInformationHeader);
