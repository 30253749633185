import React, { useCallback, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Container,
  Input,
  Row,
  Label,
  Col,
  Button,
  Form,
  CustomInput,
} from 'src/modules';

type Props = {
  onClose: () => void;
  createExpense: (categoryId: number, description: string) => void;
  optionsSelect: Pick<Category, 'id' | 'name'>[];
  loading?: boolean;
};

const AddExpense = ({
  onClose,
  createExpense,
  optionsSelect,
  loading,
}: Props) => {
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (categoryId && description) createExpense(categoryId, description);

      setCategoryId(null);
      setDescription(null);
    },
    [categoryId, description],
  );

  return (
    <Container className="mw-100 mb-3">
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-center">
          <Col xs={1}>
            <Label>Category</Label>
          </Col>
          <Col>
            <CustomInput
              bsSize="lg"
              type="select"
              id="categoriesSelect"
              name="categoriesSelect"
              onChange={(e) => setCategoryId(parseInt(e.target.value))}
            >
              <option value="">Select...</option>
              {optionsSelect.map((item, index: number) => {
                return (
                  <option key={`job-category-${index}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </CustomInput>
          </Col>
          <Col xs={1}>
            <Label>Expense Type</Label>
          </Col>
          <Col>
            <Input
              bsSize="lg"
              type="text"
              name="name"
              placeholder="Enter the name"
              onChange={(e) => setDescription(e.target.value)}
              value={description ?? ''}
            />
          </Col>
          <Col xs={2}>
            <div className="d-flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-4"
                disabled={!categoryId || !description}
              >
                {loading ? 'LOADING...' : 'SUBMIT'}
              </Button>
              <Button
                type="reset"
                color="transparent"
                onClick={onClose}
                className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddExpense;
