import { CustomerHmrcTypes } from 'src/utils/types/Customer';

import { getCurrentFiscalYear } from '../utils';

import request from './request';
import ResponseError from './ResponseError';

export const getHmrc = async (
  userId: number,
  taxYear: number,
): Promise<CustomerHmrcTypes> => {
  try {
    let year = taxYear;
    if (!year || isNaN(year)) {
      year = getCurrentFiscalYear();
    }
    const { data } = await request.get(
      `/v2/users/hmrc/?user_id=${userId}&tax_year=${taxYear}`,
    );

    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setPayUnpay = async (
  userId: number,
  taxYear: number,
  action: string,
): Promise<void> => {
  try {
    await request.post(`/users/hmrc/set-paid-unpaid`, {
      user_id: userId,
      tax_year: taxYear,
      action,
    });
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateHmrc = async (
  userId: number,
  taxYear: number,
  data: Omit<UpdateHMRC, 'all_years' | 'action'>,
): Promise<void> => {
  try {
    await request.post(
      `/users/hmrc/modify-hmrc?user_id=${userId}&tax_year=${taxYear}`,
      data,
    );
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setPayAll = async (
  userId: number,
  taxYear: number,
  all_years: boolean,
  notification: boolean,
  email: boolean,
): Promise<void> => {
  try {
    const response: any = await request.post(`/user-taxes/set-paid`, {
      user_id: userId,
      tax_year: taxYear,
      all_years,
      send_notification: notification,
      send_email: email,
    });
    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
