export enum ERRORS {
  GENERIC_ERROR = 'Something went wrong. Try again later.',
  LOGIN = 'Verify your credentials and try again.',
  CATEGORIES = 'There has been an error trying to fetch the categories.',
  PASSWORD_VALIDATIONS = 'Make sure your password has at least 8 characters, one number, one uppercase and on lowercase letter.',
  CONFIRM_PASSWORD = 'Passwords must be equal',
  PUSHER = 'Channel could not be opened. Please, try again later.',
  DATE_RANGE = 'The selected dates range are invalid.',
  DATE_OUT_OF_RANGE = 'The selected date is out of range for the year.',
  USER_DONT_HAVE_CREDTENTIALS = "You don't have permission to process this action",
}

export enum SUCCESS {
  GENERIC = 'Operation successfully completed!',
  CLIENT_CREATED = 'Client successfully created!',
}

export enum ChatType {
  'in-app' = 'in-app',
  'white-in-app' = 'white-in-app',
  whatsapp = 'whatsapp',
  'white-whatsapp' = 'white-whatsapp',
  sms = 'sms',
  'white-sms' = 'white-sms',
}

export enum MessageType {
  IN_APP = 1,
  WHATSAPP = 3,
  SMS = 2,
}

export enum QuickRepliesCenter {
  THANKS_FOR_MESSAGING = 'Thanks for messaging',
  DOCUMENT_RECEIVED = 'Documents received',
  ID_CONFIRMED = 'ID Confirmed',
  NEXT_STEPS = 'Next Steps',
}
