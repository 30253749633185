import React, { useEffect, useState, ChangeEvent, useRef } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { addTransaction } from 'src/api/incomes';
import { getTaxIncomeListingAll } from 'src/api/users';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeInputsContainer,
  HeaderSelectInput,
  TransactionsSection,
  ViewImageSection,
  OpenContentButton,
  OpenContentContainer,
  ContentContainer,
  OtherInformationsInputSection,
} from '../../styles';
import AddTransactionForm from '../AddTransactionForm';
import EditTransactionForm from '../EditTransactionForm';
import TransactionsListAll from '../TransactionsListAll';
import ViewImage from '../ViewImage';

import {
  default as AllEditTransactionForm,
  AllEditTransactionFormHandle,
} from './Components/AllEditTransactionForm';
import AllTransactions from './Components/AllTransactions';
import Filters from './Components/Filters';
import { EmployerNameSection, Logo } from './styles';

type FilterClicked = {
  id: number;
  fields: [
    {
      name: string;
      clicked: boolean;
    },
  ];
};

type HeaderPayeType = {
  id: number;
  employer_name: string;
  logo: string;
  ref: string;
  gross_amount: string;
  tax_amount: string;
  is_company_director: number;
  has_benefits: number;
  benefits_amount: string;
  created_at: string;
};

const addTransactionFields = {
  client_name: '',
  date: null,
  amount: '',
  files: [],
};

const filesList = [];

function TabAll() {
  // @refs
  const editTransactionRef = useRef<AllEditTransactionFormHandle>(null);

  // @hooks
  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  // @states
  const [allHeaderList, setAllHeaderList] = useState<HeaderPayeType[]>([]);
  const [incomeIdSelected, setIncomeIdSelected] = useState(0);
  const [searchInputsValue, setSearchInputsValue] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [filterClicked, setFilterClicked] = useState<FilterClicked[]>([]);
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [proofDocuments, setProofDocuments] = useState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [searchTransactions, setSearchTransactions] = useState(false);
  const [searchByDebit, setSearchByDebit] = useState(false);
  const [searchByCredit, setSearchByCredit] = useState(false);
  const [searchByReconciled, setSearchByReconciled] = useState('');
  const [filterSelected, setFilterSelected] = useState(false);
  const [indexTransaction, setIndexTransaction] = useState(undefined);

  // @TODO: check why useStores is not working and implement it
  const { incomes } = store;
  useEffect(() => {
    const newList: any[] = [];

    allHeaderList.map((list, index: number) => {
      newList.push({
        id: index,
        fields: [
          { name: 'search', clicked: false },
          { name: 'date-range', clicked: false },
          { name: 'transaction_type', clicked: false },
          { name: 'reconciled', clicked: false },
        ],
      });
    });

    setFilterClicked(newList);
  }, [allHeaderList]);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListingAll(userId, year),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationAddTransaction, isSuccess } = useMutation(
    'addTransaction',
    addTransaction,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setOpenAddTransactionForm(false);
        }, 3000);
      },
      onError({ response }) {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (indexTransaction && isSuccess) {
      const list = [...allHeaderList];

      list[indexTransaction].addTransactionFields.client_name = '';
      list[indexTransaction].addTransactionFields.date = '';
      list[indexTransaction].addTransactionFields.amount = '';
      list[indexTransaction].addTransactionFields.cis = '';

      setAllHeaderList(list);
      store.incomes.refetchIncomeTransactions();
    }
  }, [indexTransaction, isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      list.forEach((item, index) => {
        list[index].editMode = false;
        list[index].addTransactionFields = { ...addTransactionFields };
        list[index].editTransaction = [...incomes.incomeTransactions];
      });

      setAllHeaderList(list);
    }
  }, [data, incomes.incomeTransactions]);

  const onOpenContent = (
    index: number,
    openContent: boolean,
    income_id: number,
  ) => {
    const newAllHeaderList = allHeaderList;

    newAllHeaderList[index].openContent = !openContent;

    setAllHeaderList([...newAllHeaderList]);
    setIncomeIdSelected(income_id);

    setSearchInputsValue('');
    setInitialDate('');
    setFinalDate('');
    setApplyFilters(false);
    setSearchTransactions(false);
    setSearchByDebit(false);
    setSearchByCredit(false);
    setSearchByReconciled('');
  };

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const newAllHeaderList = [...allHeaderList];

    newAllHeaderList[index][target.name] = target.value;
    setAllHeaderList(newAllHeaderList);
  };

  const onSearchByDebit = () => {
    setSearchByDebit(true);
    setSearchByCredit(false);
  };

  const onSearchByCredit = () => {
    setSearchByCredit(true);
    setSearchByDebit(false);
  };

  const onSearchByReconciled = (value: string) => {
    setSearchByReconciled(value);
  };

  const onIncomeType = (income_type_id: number) => {
    switch (income_type_id) {
      case 1:
        return 'employment';
      case 3:
        return 'self_employed';
      default:
        break;
    }
  };

  const onInputAddTransactionChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...allHeaderList];

    list[index].addTransactionFields[target.name] = target.value;
    setAllHeaderList(list);
  };

  const onAddTransaction = (
    index: number,
    income_id: number,
    income_type_id: number,
  ) => {
    setIndexTransaction(index);
    const fields = allHeaderList[index];

    const formErrors = validateFields(
      allHeaderList[index].addTransactionFields,
      {
        client_name: { required: true },
        amount: { required: true },
      },
    );

    const formData = new FormData();

    const incomeType = onIncomeType(income_type_id);

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('client_name', fields.addTransactionFields.client_name);
      formData.append('income_id', income_id);

      formData.append('income_type', incomeType);
      formData.append('reconciliation_type', 'income');
      formData.append('date', fields.addTransactionFields.date);
      formData.append('amount', fields.addTransactionFields.amount);
      formData.append('files', proofDocuments);

      formData.append(
        'cis',
        !fields.addTransactionFields.cis ? 0 : fields.addTransactionFields.cis,
      );

      mutationAddTransaction(formData);
    }
  };

  const validateFields = (fields, rules) => {
    const formErrors = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  const onCancelAddTransaction = (index: number) => {
    setOpenAddTransactionForm(false);

    const list = [...allHeaderList];

    list[index].addTransactionFields.client_name = '';
    list[index].addTransactionFields.date = new Date();
    list[index].addTransactionFields.amount = 0;

    setProofDocuments([]);
    setAllHeaderList(list);
  };

  const onChangeFieldTransaction = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    id: number,
  ) => {
    const target = event.target;

    const list = [...allHeaderList];

    list[index].editTransaction[index][target.name] = target.value;
    setAllHeaderList(list);
  };

  let newDocsList = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <div>
      <IncomeContainer>
        <Filters
          setSearchTransactions={setSearchTransactions}
          setSearchInputsValue={setSearchInputsValue}
          setApplyFilters={setApplyFilters}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          onSearchByDebit={onSearchByDebit}
          setSearchByDebit={setSearchByDebit}
          onSearchByCredit={onSearchByCredit}
          setSearchByCredit={setSearchByCredit}
          onSearchByReconciled={onSearchByReconciled}
          setSearchByReconciled={setSearchByReconciled}
          setFilterSelected={setFilterSelected}
        />

        <AllTransactions
          openAddTransactionForm={openAddTransactionForm}
          setOpenAddTransactionForm={setOpenAddTransactionForm}
          keyword={searchTransactions && searchInputsValue.toString()}
          date_range_start={applyFilters && initialDate.toString()}
          date_range_end={applyFilters && finalDate.toString()}
          type={'credit'}
          reconciliation_type="income"
          reconciliation_status={
            searchByReconciled !== '' && searchByReconciled
          }
          onClick={(id) =>
            editTransactionRef?.current.setSelectedTransaction(id)
          }
          filterSelected={filterSelected}
        />

        <ViewImageSection>
          {!incomes.isLoadingAllTransaction && (
            <ViewImage
              transactions={
                incomes.allTransactions[incomes.indexTransactionSelected]
              }
            />
          )}
        </ViewImageSection>

        {incomes.allTransactions.length > 0 && (
          <AllEditTransactionForm
            transactions={incomes.allTransactions}
            sourceList={data}
            ref={editTransactionRef}
            onChangeFieldTransaction={onChangeFieldTransaction}
          />
        )}
      </IncomeContainer>

      {!isLoading &&
        allHeaderList.map((item: any, index: number) => {
          return (
            <IncomeContainer key={index}>
              <IncomeHeader>
                <div>
                  <Logo
                    src={handleLogo(
                      onIncomeType(item.income_type_id),
                      item.self_employment_type,
                    )}
                    alt=""
                  />
                </div>
                <IncomeInputsContainer>
                  <IncomeHeaderFields>
                    <Row>
                      <Col>
                        <EmployerNameSection>
                          <EditInputPie
                            id="employer_name"
                            label="Employer name:"
                            name="employer_name"
                            type="text"
                            value={item.employer_name}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </EmployerNameSection>
                        {/* here */}

                        <EditInputPie
                          id="ref"
                          label="Employer Paye"
                          name="ref"
                          type="text"
                          value={item.ref}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Start date:"
                          id="created_at"
                          name="created_at"
                          type="text"
                          value={item.created_at}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />

                        <EditInputPie
                          label="Gross total:"
                          id="gross_amount"
                          name="gross_amount"
                          type="text"
                          value={item.gross_amount}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Tax total:"
                          id="tax_amount"
                          name="tax_amount"
                          type="text"
                          value={item.tax_amount}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>
                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="director-position">
                            Director position:
                          </label>
                          <HeaderSelectInput
                            id="is_company_director"
                            name="is_company_director"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value={1}
                              selected={item.is_company_director}
                            >
                              Yes
                            </option>

                            <option
                              value={0}
                              selected={item.is_company_director}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>
                    </Row>
                  </IncomeHeaderFields>
                </IncomeInputsContainer>
              </IncomeHeader>

              <ContentContainer openContent={item.openContent}>
                <TransactionsSection>
                  {item.openContent && item.id === incomeIdSelected && (
                    <TransactionsSection>
                      <h3>Transactions</h3>

                      <TransactionsListAll
                        incomeType={onIncomeType(item.income_type_id)}
                        incomeId={item.id}
                        openAddTransactionForm={openAddTransactionForm}
                        setOpenAddTransactionForm={setOpenAddTransactionForm}
                        keyword={
                          searchTransactions && searchInputsValue.toString()
                        }
                        date_range_start={
                          applyFilters && initialDate.toString()
                        }
                        date_range_end={applyFilters && finalDate.toString()}
                        type={
                          searchByDebit ? 'debit' : searchByCredit && 'credit'
                        }
                        reconciliation_status={
                          searchByReconciled !== '' && searchByReconciled
                        }
                      />
                    </TransactionsSection>
                  )}
                </TransactionsSection>

                <AddTransactionForm
                  openAddTransactionForm={openAddTransactionForm}
                  item={item}
                  index={index}
                  onInputAddTransactionChange={onInputAddTransactionChange}
                  errors={errors}
                  onAddTransaction={onAddTransaction}
                  onCancelAddTransaction={onCancelAddTransaction}
                  onUploadProof={onUploadProof}
                  newDocsList={newDocsList}
                  onRemoveProof={onRemoveProof}
                />

                <ViewImageSection>
                  {incomes.incomeTransactions.length > 0 && (
                    <ViewImage
                      transactions={
                        incomes.incomeTransactions[
                          incomes.indexTransactionSelected
                        ]
                      }
                    />
                  )}
                </ViewImageSection>

                <EditTransactionForm
                  item={item}
                  index={index}
                  onChangeFieldTransaction={onChangeFieldTransaction}
                />
              </ContentContainer>

              <OpenContentContainer>
                <OpenContentButton
                  onClick={() =>
                    onOpenContent(index, item.openContent, item.id)
                  }
                >
                  {item.openContent ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}
                </OpenContentButton>
              </OpenContentContainer>
            </IncomeContainer>
          );
        })}
    </div>
  );
}

export default observer(TabAll);
