import styled from 'styled-components';

type InputProps = {
  inputDisabled: boolean;
};

export const SectionInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HiddenInput = styled.input<InputProps>`
  border: ${(props) => (props.inputDisabled ? 'none' : '1px solid #ccc')};
  border-radius: 2px;
  position: relative;
  left: 0;
  bottom: 10px;
  height: 30px;
  width: 100%;
  max-width: 230px;
  background: transparent;
`;
