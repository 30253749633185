import React from 'react';

import { useMutation } from 'react-query';

import { enableUserAccount } from 'src/api/users';
import { Col, Button, Toastify } from 'src/modules';
import { ERRORS, SUCCESS } from 'src/utils';

import TableRow from '../TableRow';

type Props = {
  name: string;
  status: string;
  requestedDate?: string;
  deleteDate: string;
  id: number;
  fetchDeletedAccounts(page: number): void;
};

const UserAccountDeletesRow: React.FC<Props> = ({
  name,
  status,
  requestedDate,
  deleteDate,
  id,
  fetchDeletedAccounts,
}) => {
  const { mutate: mutateEnableUserAccount } = useMutation(enableUserAccount, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        fetchDeletedAccounts(1);
      }, 4000);
    },
    onError: () => {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const onReEnableUserAccount = (userId: number) => {
    mutateEnableUserAccount(userId);
  };

  return (
    <TableRow className="align-items-center px-3 py-2">
      <Col xs="5" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {name}

        <Button
          type="submit"
          color="primary"
          className="btn btn-primary mr-4 re-enable-button"
          onClick={() => onReEnableUserAccount(id)}
        >
          Re-Enable
        </Button>
      </Col>
      <Col xs="3">{status}</Col>
      {/* <Col xs="3 pl-4">{requestedDate}</Col> */}
      <Col xs="4">{deleteDate}</Col>
    </TableRow>
  );
};

export default UserAccountDeletesRow;
