import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { showTaxReturn } from 'src/api/forms-tab';
import { Loading } from 'src/components/ui';
import {
  Table,
  Card,
  Button,
  Toastify,
  Modal,
  ModalHeader,
  ModalFooter,
  Row,
} from 'src/modules';

import { Title, CardContainer } from '../../styles';

type Props = {
  taxReturnId: number;
  onClick?: (e: any) => void;
  setTaxReturnData?: (taxReturnData: any) => void;
};

const FORMS_DEFAULT = {
  id: 0,
  sa100_form: { id: 0 },
  sa101_form: [],
  sa102_forms: [],
  sa103f_forms: [],
  sa103s_forms: [],
  sa110_form: { id: 0 },
  deleted_forms: {
    sa101_form: [],
    sa102_forms: [],
    sa103f_forms: [],
    sa103s_forms: [],
  },
};

type TDataList = {
  id: number;
  sa100_form: {
    id: number;
  };
  sa101_form: {
    id: number;
  };
  sa102_forms: any[];
  sa103f_forms: any[];
  sa103s_forms: any[];
  sa110_form: {
    id: number;
  };
  deleted_forms: {
    sa101_form: any[];
    sa102_forms: any[];
    sa103f_forms: any[];
    sa103s_forms: any[];
  };
};

export const FormsList = ({
  taxReturnId,
  onClick,
  setTaxReturnData,
}: Props) => {
  // @states
  const [dataList, setDataList] = useState<TDataList>(FORMS_DEFAULT);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formSelected, setFormSelected] = useState({ id: 0, name: '' });
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const toggleDeleteModal = () => setShowDeleteModal((oldState) => !oldState);
  const toggleSubmitModal = () => setShowSubmitModal((oldState) => !oldState);

  // @queries
  const { data, isLoading, refetch } = useQuery(
    'showTaxReturn',
    () => showTaxReturn(taxReturnId),
    {
      enabled: false,
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setDataList(data.data);
      } else {
        setDataList(FORMS_DEFAULT);
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (taxReturnId) refetch();
  }, [taxReturnId]);

  useEffect(() => {
    if (taxReturnId) refetch();
  }, []);

  function onActionClick(action: string, id: number) {
    if (setTaxReturnData) setTaxReturnData(data);
    if (onClick) onClick({ target: { id: action, value: id } });
    setTimeout(() => {
      refetch();
    }, 2000);
  }

  function onActionDeleteClick(action: string, id: number) {
    setShowDeleteModal(!showDeleteModal);
    setFormSelected({ id, name: action });
  }

  const handleDeleteForm = (action: string, id: number) => {
    onActionClick(action, id);
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="formsList">
        <div className="groupList justify-content-between my-2">
          <Title>Tax Return - Draft</Title>
          <Button
            color={
              data?.data?.request_status == 'submission_success'
                ? 'secondary'
                : 'danger'
            }
            disabled={data?.data?.request_status == 'submission_success'}
            onClick={toggleSubmitModal}
          >
            Submit Tax Return (REAL)
          </Button>
        </div>
        <Card>
          <CardContainer>
            <div className="groupList mb-15">
              {dataList.sa101_form && dataList.sa101_form.id ? (
                <></>
              ) : (
                <Button
                  color="primary"
                  onClick={() => onActionClick('add_sa101_form', dataList.id)}
                >
                  Add Additional Information
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => onActionClick('add_sa102_form', 0)}
              >
                Add Employment
              </Button>
              <Button
                color="primary"
                onClick={() => onActionClick('add_sa103f_form', 0)}
              >
                Add Self-employment (Full)
              </Button>
              <Button
                color="primary"
                onClick={() => onActionClick('add_sa103s_form', 0)}
              >
                Add Self-employment (Short)
              </Button>
            </div>

            <h3>Forms</h3>
            {/* JSON.stringify(dataList) */}
            <Table>
              <thead>
                <th>Forms</th>
                <th>Edit</th>
              </thead>
              <tbody>
                <tr>
                  <td>Tax Return</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() =>
                        onActionClick('editTaxReturn', dataList.id)
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td>Main Tax Return</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() =>
                        onActionClick('edit_sa100_form', dataList.sa100_form.id)
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
                {dataList.sa101_form && dataList.sa101_form.id ? (
                  <tr>
                    <td>Additional Information </td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() =>
                          onActionClick(
                            'edit_sa101_form',
                            dataList.sa101_form.id,
                          )
                        }
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>Additional Information </td>
                    <td></td>
                  </tr>
                )}
                {dataList.sa102_forms && dataList.sa102_forms.length > 0 ? (
                  dataList.sa102_forms.map((item: any) => (
                    <tr>
                      <td>
                        Employment -{' '}
                        {item.employers_name ??
                          (item.employer_paye_reference || '')}
                      </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('edit_sa102_form', item.id)
                            }
                          >
                            View
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Employment </td>
                    <td>
                      {' '}
                      <Button
                        color="primary"
                        onClick={() => onActionClick('add_sa102_form', 0)}
                      >
                        Add Employment
                      </Button>{' '}
                    </td>
                  </tr>
                )}
                {dataList.sa103f_forms && dataList.sa103f_forms.length > 0 ? (
                  dataList.sa103f_forms.map((item: any) => (
                    <tr>
                      <td>
                        Self-employment (Full) -{' '}
                        {item.business_description || ''}{' '}
                      </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('edit_sa103f_form', item.id)
                            }
                          >
                            View
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Self-employment (Full) </td>
                    <td>
                      {' '}
                      <Button
                        color="primary"
                        onClick={() => onActionClick('add_sa103f_form', 0)}
                      >
                        Add Self-employment (Full)
                      </Button>
                    </td>
                  </tr>
                )}
                {dataList.sa103s_forms && dataList.sa103s_forms.length > 0 ? (
                  dataList.sa103s_forms.map((item: any) => (
                    <tr>
                      <td>
                        Self-employment (Short) -{' '}
                        {item.business_description || ''}{' '}
                      </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('edit_sa103s_form', item.id)
                            }
                          >
                            View
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Self-employment (Short) </td>
                    <td>
                      {' '}
                      <Button
                        color="primary"
                        onClick={() => onActionClick('add_sa103s_form', 0)}
                      >
                        Add Self-employment (Short)
                      </Button>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Tax Calculation Summary</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() =>
                        onActionClick('edit_sa110_form', dataList.sa110_form.id)
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardContainer>
        </Card>
        <Card>
          <CardContainer>
            <h3>Deleted Forms</h3>
            <Table>
              <thead>
                <th>Forms</th>
                <th>View / Restore</th>
              </thead>
              <tbody>
                {dataList.deleted_forms.sa102_forms &&
                dataList.deleted_forms.sa102_forms.length > 0 ? (
                  dataList.deleted_forms.sa102_forms.map((item: any) => (
                    <tr>
                      <td>Employment </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('view_sa102_form', item.id)
                            }
                          >
                            View
                          </Button>
                          <Button
                            color="danger"
                            onClick={() =>
                              onActionClick('restore_sa102_form', item.id)
                            }
                          >
                            Restore
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Employment </td>
                    <td> None</td>
                  </tr>
                )}
                {dataList.deleted_forms.sa103f_forms &&
                dataList.deleted_forms.sa103f_forms.length > 0 ? (
                  dataList.deleted_forms.sa103f_forms.map((item: any) => (
                    <tr>
                      <td>Self-employment (Full) </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('view_sa103f_form', item.id)
                            }
                          >
                            View
                          </Button>
                          <Button
                            color="danger"
                            onClick={() =>
                              onActionClick('restore_sa103f_form', item.id)
                            }
                          >
                            Restore
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Self-employment (Full) </td>
                    <td> None</td>
                  </tr>
                )}
                {dataList.deleted_forms.sa103s_forms &&
                dataList.deleted_forms.sa103s_forms.length > 0 ? (
                  dataList.deleted_forms.sa103s_forms.map((item: any) => (
                    <tr>
                      <td>Self-employment (Short) </td>
                      <td>
                        <div className="groupList">
                          <Button
                            color="primary"
                            onClick={() =>
                              onActionClick('view_sa103s_form', item.id)
                            }
                          >
                            View
                          </Button>
                          <Button
                            color="danger"
                            onClick={() =>
                              onActionClick('restore_sa103s_form', item.id)
                            }
                          >
                            Restore
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Self-employment (Short) </td>
                    <td> None</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardContainer>
        </Card>
      </div>

      <Modal centered isOpen={showDeleteModal} toggle={toggleDeleteModal}>
        <ModalHeader>Delete form {formSelected.id}?</ModalHeader>
        <ModalFooter>
          {isLoading && (
            <div className="w-100 mb-2 overflow-hidden">
              <Loading />
            </div>
          )}
          <Button
            color="primary"
            onClick={() => handleDeleteForm(formSelected.name, formSelected.id)}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={showSubmitModal} toggle={toggleSubmitModal}>
        <ModalHeader>Proceed with HMRC submission?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onActionClick('submit_real', taxReturnId);
              toggleSubmitModal();
            }}
          >
            Submit
          </Button>
          <Button color="secondary" onClick={() => setShowSubmitModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
