import React, { useEffect, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { getExpenseTransactions, deleteTransaction } from 'src/api/incomes';
import CustomPagination from 'src/components/CustomPagination';
import { Toastify } from 'src/modules';
import store from 'src/stores';
import { formatDate, SUCCESS } from 'src/utils';

import { handleStatusColor } from '../../..';
import { NewItemButton } from '../../../styles';
import {
  Actions,
  Circle,
  Container,
  SectionAddNewItem,
  TableBody,
  TableHeader,
} from '../../SelfEmpTransactions/TransactionsList/styles';
import SelfEmpTransactionsForm from '../TransactionForm';

import {
  GrossSection,
  CategorySection,
  StatusSection,
  DateSection,
} from './styles';

function SelfEmployedTransactionsList({
  incomeType,
  incomeId,
  setOpenAddTransactionForm,
  openAddTransactionForm,
  allHeaderList,
  incomeIdSelected,
  userId,
  year,
  transactionsListState,
  setTransactionsListState,
  selectedItem,
  setSelectedItem,
  setCurrentTransactionIndex,
  isSuccess,
  refetchList,
}) {
  const incomes = store.incomes;
  const expenses = store.expenses;

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  const { isLoading, refetch } = useQuery(
    ['getExpenseTransactions', incomeType, incomeId, nextPage],
    () => getExpenseTransactions(incomeType, incomeId, nextPage),
    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);
        incomes.setIncomeTransactions(result.data);
        incomes.setPayeOrSelfEmployedTotal(result.totals);

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more || total_pages !== 1);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  useEffect(() => {
    refetchList(refetch);
  }, [isSuccess]);

  useEffect(() => {
    if (transactionsListState.length > 0)
      setSelectedItem(transactionsListState[0]);
  }, [transactionsListState]);

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
  }, []);

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        refetch();
        expenses.refetchTotals();
        expenses.refetchSummary();
      }, 1000);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  useEffect(() => {
    const transactionsList = transactionsListState;

    if (!isLoading) {
      transactionsList.forEach(
        (item, index) => (transactionsList[index].checked = false),
      );

      setTransactionsListState(transactionsList);
    }
  }, [isLoading]);

  /* const onCheckboxChange = (event: any, index: number) => {
    const list = [...transactionsList];

    list[index].checked = event.target.checked;
    setTransactionsListState(list);
  }; */

  return (
    <>
      <Container>
        <TableHeader>
          <Row>
            <Col className="headerColumn"></Col>
            <Col className="headerColumn">Merchant</Col>
            <Col className="headerColumn">Date</Col>
            <Col className="headerColumn">Gross</Col>

            <Col className="headerColumn" lg={2}>
              Category
            </Col>

            <Col className="headerColumn" lg={2}></Col>
          </Row>
        </TableHeader>

        {!isLoading &&
          transactionsListState.map((item, i) => {
            return (
              <TableBody
                key={i}
                onClick={() => {
                  incomes.setIndexTransactionSelected(i);
                  setCurrentTransactionIndex(i);
                }}
              >
                <Row>
                  <Col className="bodyColumn">
                    <StatusSection>
                      <Circle background={handleStatusColor(item.status)} />
                    </StatusSection>
                  </Col>
                  <Col className="bodyColumn">{item.merchant_name}</Col>
                  <Col className="bodyColumn">
                    <DateSection>{formatDate(item.created_at)}</DateSection>
                  </Col>
                  <Col className="bodyColumn">
                    <GrossSection>{item.amount + item.CIS}</GrossSection>
                  </Col>
                  <Col className="bodyColumn">
                    <CategorySection>
                      {item.category_id.category}
                    </CategorySection>
                  </Col>

                  <Col lg={2} className="bodyColumn">
                    <Actions>
                      <BsFillTrashFill
                        color="red"
                        size="20"
                        onClick={() => onDeleteTransaction(item.id)}
                      />
                    </Actions>
                  </Col>
                </Row>
              </TableBody>
            );
          })}

        {!isLoading && transactionsListState.length < 1 && (
          <span>No transactions</span>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}
      </Container>

      <SectionAddNewItem>
        <NewItemButton
          onClick={() => setOpenAddTransactionForm(!openAddTransactionForm)}
        >
          <AiOutlinePlus size={18} />
        </NewItemButton>
      </SectionAddNewItem>

      {transactionsListState.length > 0 && !openAddTransactionForm ? (
        <SelfEmpTransactionsForm
          selectedItem={selectedItem}
          transactionsListState={transactionsListState}
          allHeaderList={allHeaderList}
          incomeIdSelected={incomeIdSelected}
          userId={userId}
          year={year}
          refetch={refetch}
        />
      ) : null}
    </>
  );
}

export default SelfEmployedTransactionsList;
