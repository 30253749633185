import { styled } from 'src/modules';

export const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const CharactersLeft = styled.p`
  margin-top: 12px;
  text-align: right;
`;

export const AddCustomerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 4px;
  padding: 0.25rem 0.7rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  width: 90%;
`;

export const ReEnableSection = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray.shark};
  opacity: 0.5;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.default};
`;

export const ReEnableSectionContainer = styled.div`
  position: relative;

  .re-enable-button {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 3;
  }
`;
