import React, { memo, useEffect, useState } from 'react';

import moment from 'moment';
import { useQuery } from 'react-query';
import { Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import { listStatusTags } from 'src/api/status-tags';
import { getExportCustomers } from 'src/api/users';
import API from 'src/data/API';
import { useYear, useFilters } from 'src/hooks';
import {
  ITaxAssistant,
  TCustomersSort,
  TEmployeeType,
  TIsPaidStatus,
  TStatus,
  UTRSTatusLabels,
  IHowYouHere,
  TTaxAssistantRole,
} from 'src/models';
import {
  Toastify,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import {
  getCurrentFiscalYear,
  getYearsList,
  getQueryParam,
  notNullOrUndefined,
  ERRORS,
  formatDateFullDate,
} from 'src/utils';
import {
  filterDevsOut,
  handleSuperAdminRole,
} from 'src/utils/helpers/handleSuperAdminRole';

const subscriptionPlanList = [
  {
    id: 1,
    name: 'essential',
  },
  {
    id: 2,
    name: 'premium',
  },
  {
    id: 3,
    name: 'trial',
  },
  {
    id: 4,
    name: 'expired',
  },
];

const serviceTypeList = [
  {
    id: 1,
    value: 'DIFY',
  },
  {
    id: 2,
    value: 'DIY',
  },
];

interface CustomersPageFiltersProps {
  showActionButtons: boolean;
  selectedCustomers: number[];
  onApplyFilters(): void;
  onClearFilters(): void;
  onPaidSelectedItems(year: number): void;
  totalEntries: number;
  isPIE: boolean;
}

const STATUS: TStatus[] = ['Enable', 'Disable', 'Delete'];
const CALLED = [
  { label: 'Client called', value: 1 },
  { label: 'Not called yet', value: 0 },
];

const EMPLOYMENT_TYPE: TEmployeeType[] = ['Employed', 'SelfEmployed'];

const PAYMENT: TIsPaidStatus[] = ['Blank', 'Paid', 'PaymentDue'];

const UTRSTATUS = [];
for (const key in UTRSTatusLabels) {
  UTRSTATUS.push({ label: UTRSTatusLabels[key], value: key });
}

const TAX_CODE = [
  { value: 0, label: 'Outstanding' },
  { value: 1, label: 'Complete' },
];

// @TODO:
// Fix this ugly inheritance
// See TTaxStatus.tsx
const TAX_STATUS = [
  { value: 'Pending', label: 'Signed Up' },
  { value: 'IncomeReceived', label: 'Income Received' },
  { value: 'IncomeSignedOff', label: 'Income SignedOff' },
  { value: 'ExpenseReceived', label: 'Expense Received' },
  { value: 'ExpenseSignedOff', label: 'Expense SignedOff' },
  { value: 'InProgress', label: 'HMRC In Progress' },
  { value: 0, label: 'HMRC Early Payout Pending' },
  { value: 1, label: 'HMRC Early Payout Interested' },
  { value: 2, label: 'HMRC Early Payout Not Interested' },
  { value: 'Paid', label: 'Paid' },
];

const SORT = [];
for (const key in TCustomersSort) {
  SORT.push({ label: TCustomersSort[key], value: key });
}

const HMRC = [
  { label: 'Draft', value: 'draft' },
  { label: 'Submitting', value: 'submitting' },
  { label: 'Submission success', value: 'submission_success' },
  { label: 'Submission error', value: 'submission_error' },
];

const CustomersPageFilters = ({
  showActionButtons,
  onApplyFilters,
  onClearFilters,
  selectedCustomers,
  onPaidSelectedItems,
  totalEntries,
  isPIE,
}: CustomersPageFiltersProps) => {
  const { query, clearQuery } = useFilters();

  const [assistantsList, setAssistantsList] = useState<ITaxAssistant[]>([]);
  const [howYouHereList, setHowYouHereList] = useState<IHowYouHere[]>([]);

  // Only role_id = TTaxAssistantRole.SUPER_ADMIN can change the status to Paid
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const [status, setStatus] = useQueryParam('status', StringParam);
  const [is_called, setCalled] = useQueryParam('is_called', NumberParam);
  const [tax_assistant_id, setAssistant] = useQueryParam(
    'tax_assistant_id',
    NumberParam,
  );
  const [tax_status, setTaxStatus] = useQueryParam('tax_status', StringParam);
  const [tax_code_complete, setTaxCode] = useQueryParam(
    'tax_code_complete',
    StringParam,
  );
  const [employee_type, setEmployee] = useQueryParam(
    'employee_type',
    StringParam,
  );
  const [status_info_label, setStatusInfo] = useQueryParam(
    'status_label',
    StringParam,
  );
  const [is_paid_status, setPaidStatus] = useQueryParam(
    'is_paid_status',
    StringParam,
  );
  const [requested_utr, setUTR] = useQueryParam('requested_utr', StringParam);
  const [howYouHere, setHowYouHere] = useQueryParam(
    'how_you_here',
    StringParam,
  );
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [subscription_plan, setSubscriptionPlan] = useQueryParam(
    'plans',
    StringParam,
  );

  const [serviceType, setServiceType] = useQueryParam(
    'service_type',
    StringParam,
  );

  const [hmrcStatus, setHmrcStatus] = useQueryParam(
    'hmrc_status',
    StringParam,
  );

  const [year, setYear] = useYear();
  const [exportStatus, setExportStatus] = useState('');
  const [showExportStatus, setShowExportStatus] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [exportFrom, setExportFrom] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [exportTo, setExportTo] = useState(moment().format('YYYY-MM-DD'));
  const [showExportCustomers, setShowExportCustomers] = useState(false);

  const { data: tags = [] } = useQuery(['listStatusTags'], listStatusTags);

  const exportFromFormatted = formatDateFullDate(exportFrom);
  const exportToFormatted = formatDateFullDate(exportTo);

  const { refetch, isFetching } = useQuery(
    'exportCustomers',
    () => getExportCustomers(year, exportFromFormatted, exportToFormatted),
    {
      enabled: false,
      onSuccess: (response) => {
        (window as any).open(response.url, 'exported');

        setShowExportCustomers(false);
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  const onExportCustomers = async (): Promise<void> => {
    refetch();
  };

  const onShowExportCustomers = () => {
    setShowExportCustomers((showExportCustomers) => !showExportCustomers);
  };

  const onShowExportStatus = async (): Promise<void> => {
    setShowExportStatus((showExportStatus) => !showExportStatus);
  };

  const onExportStatus = async (): Promise<void> => {
    if (exporting) return;

    const daysDiff = moment(exportTo).diff(moment(exportFrom), 'days');
    if (daysDiff > 15) {
      Toastify.toast.error(`Can't export more than 15 days`);
      return;
    }

    setExporting(true);
    const body = {
      tax_status,
      sort: 'name_a_z',
      from: exportFrom,
      to: exportTo,
    };
    try {
      const { data } = await request.post(
        `${API.PATH}${API.USERS_EXPORT}`,
        body,
      );
      (window as any).open(data.url, 'exported_status');
    } catch (error) {
      Toastify.toast.error(
        `${ERRORS.GENERIC_ERROR} - Try again with a shorter date range`,
      );
    } finally {
      setExporting(false);
    }
  };

  const onWiseExport = async (): Promise<void> => {
    const { data } = await request.post(
      `${API.PATH}${
        API.USERS_EXPORT_WISE
      }?tax_year=${year}&user_ids=${selectedCustomers.join()}`,
    );
    (window as any).open(data.url, 'wise');
  };

  const onWiseExportAll = async (): Promise<void> => {
    let queryString = `${query}&sort=name_a_z`;
    if (queryString.indexOf('tax_year') < 0) {
      queryString += `&tax_year=${year}`;
    }

    const { data } = await request.post(
      `${API.PATH}${API.USERS_EXPORT_WISE}${queryString}`,
    );
    (window as any).open(data.url, 'wise');
  };

  const loadUser = async () => {
    const userData = await Storage.getUser();
    const credentials = handleSuperAdminRole(userData.role_id);

    setIsSuperAdmin(credentials);
  };

  const clearFieldValue = (valueName: any) => {
    return query === '' ? '' : valueName;
  };

  // @TODO: Move to mobx
  const loadData = async () => {
    try {
      const { data } = await request.get(`/admin?page=1&per_page=200`);
      const removeDevs = filterDevsOut(data?.data);

      setAssistantsList(removeDevs);
    } catch (error: any) {
      throw new ResponseError(error);
    }

    store.customer
      .getHowYouHere()
      .then((response) => setHowYouHereList(response.data));
  };

  const clearFilters = () => {
    clearQuery();
    onClearFilters();
  };

  useEffect(() => {
    for (let i = 0; i < TAX_STATUS.length; i++) {
      if (TAX_STATUS[i].value === tax_status) {
        setExportStatus(TAX_STATUS[i].label);
        break;
      }
    }
  }, [tax_status]);

  useEffect(() => {
    loadData();
    loadUser();
  }, []);

  const renderExportFromToInputs = () => {
    return (
      <>
        <span className="mr-1">From: </span>
        <input
          min={moment().subtract(1, 'month').format('YYYY-MM-DD')}
          max={moment().format('YYYY-MM-DD')}
          type="date"
          className="p-1 mr-2"
          value={exportFrom}
          onChange={(e) => setExportFrom(e.target.value)}
        />

        <span className="mr-1">To: </span>
        <input
          min={moment().subtract(1, 'month').format('YYYY-MM-DD')}
          max={moment().format('YYYY-MM-DD')}
          type="date"
          className="p-1 mr-2"
          value={exportTo}
          onChange={(e) => setExportTo(e.target.value)}
        />
      </>
    );
  };

  return (
    <div className="mb-3 select-filters">
      <Form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="d-flex mb-2 flex-wrap">
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="status"
                  value={clearFieldValue(status)}
                  className={status ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setStatus(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Status</option>
                  <option disabled></option>
                  {STATUS.map((item, index) => {
                    return (
                      <option key={`st${index}`} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="is_called"
                  value={clearFieldValue(is_called)}
                  className={notNullOrUndefined(is_called) ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setCalled(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Called</option>
                  <option disabled></option>
                  {CALLED.map((item, index) => {
                    return (
                      <option key={`ca${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="tax_assistant_id"
                  value={clearFieldValue(tax_assistant_id)}
                  className={tax_assistant_id ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setAssistant(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Assistant</option>
                  <option disabled></option>
                  {assistantsList.map((item, index) => {
                    return (
                      <option value={item.id} key={`a${index}`}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="year"
                  value={clearFieldValue(year)}
                  className={
                    year && year !== getCurrentFiscalYear() ? 'bg-primary' : ''
                  }
                  onChange={(event) =>
                    setYear(getQueryParam(event.target.value))
                  }
                >
                  {getYearsList().map((item, index) => {
                    return <option key={`y${index}`}>{item}</option>;
                  })}
                </Input>
              </FormGroup>

              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="plans"
                  value={clearFieldValue(subscription_plan)}
                  className={subscription_plan ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setSubscriptionPlan(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Subscription</option>
                  <option disabled></option>
                  {subscriptionPlanList.map((item, index) => {
                    return (
                      <option value={item.name} key={`a${index}`}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="service_type"
                  value={clearFieldValue(serviceType)}
                  className={serviceType ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setServiceType(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Service Type</option>
                  {serviceTypeList.map((item, index) => {
                    return (
                      <option value={item.value} key={`a${index}`}>
                        {item.value}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>

            <div className="d-flex">
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="how-you-here"
                  value={clearFieldValue(howYouHere)}
                  className={howYouHere ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setHowYouHere(getQueryParam(event.target.value))
                  }
                >
                  <option value="">How you here</option>
                  <option disabled></option>
                  {howYouHereList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="sort"
                  value={clearFieldValue(sort)}
                  className={sort ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setSort(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Sort</option>
                  <option disabled></option>
                  {SORT.map((item, index) => {
                    return (
                      <option key={`sort${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="hmrc_status"
                  value={clearFieldValue(hmrcStatus)}
                  className={hmrcStatus ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setHmrcStatus(getQueryParam(event.target.value))
                  }
                >
                  <option value="">HMRC Status</option>
                  <option disabled></option>
                  {HMRC.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <Button
                color="primary"
                className="mx-2"
                onClick={onApplyFilters}
                style={{
                  background: isPIE ? '#57E4CD' : '#ffdd05',
                  border: isPIE ? '#57E4CD' : '#ffdd05',
                }}
              >
                Go
              </Button>
              <Button
                color="primary"
                onClick={clearFilters}
                style={{
                  background: isPIE ? '#57E4CD' : '#ffdd05',
                  border: isPIE ? '#57E4CD' : '#ffdd05',
                }}
              >
                All
              </Button>

              {isSuperAdmin && (
                <Button
                  color="primary"
                  className="ml-2"
                  onClick={onShowExportCustomers}
                  style={{
                    background: isPIE ? '#57E4CD' : '#ffdd05',
                    border: isPIE ? '#57E4CD' : '#ffdd05',
                  }}
                >
                  Export
                </Button>
              )}
              {isSuperAdmin && tax_status && (
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={onShowExportStatus}
                >
                  Export Status
                </Button>
              )}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="d-flex mb-2 flex-wrap">
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="employee_type"
                  value={clearFieldValue(employee_type)}
                  className={employee_type ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setEmployee(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Employment</option>
                  <option disabled></option>
                  {EMPLOYMENT_TYPE.map((item, index) => {
                    return (
                      <option key={`et${index}`} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="tax_status"
                  value={clearFieldValue(tax_status)}
                  className={tax_status ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setTaxStatus(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Tax Status</option>
                  <option disabled></option>
                  {TAX_STATUS.map((item, index) => {
                    return (
                      <option key={`ts${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="tax_code_complete"
                  value={clearFieldValue(tax_code_complete)}
                  className={tax_code_complete ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setTaxCode(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Tax Code</option>
                  <option disabled></option>
                  {TAX_CODE.map((item, index) => {
                    return (
                      <option key={`tc${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>

            <div className="d-flex">
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="status_info_label"
                  value={clearFieldValue(status_info_label)}
                  className={status_info_label ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setStatusInfo(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Sales Status</option>
                  <option disabled></option>
                  {tags.map((item, index) => {
                    return (
                      <option key={`req${index}`} value={item.id}>
                        {item.status_label_name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="is_paid_status"
                  value={clearFieldValue(is_paid_status)}
                  className={is_paid_status ? 'bg-primary' : ''}
                  onChange={(event) =>
                    setPaidStatus(getQueryParam(event.target.value))
                  }
                >
                  <option value="">Payment</option>
                  <option disabled></option>
                  {PAYMENT.map((item, index) => {
                    return (
                      <option key={`p${index}`} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0" style={{ marginRight: 10 }}>
                <Input
                  type="select"
                  name="requested_utr"
                  className={requested_utr ? 'bg-primary' : ''}
                  value={clearFieldValue(requested_utr)}
                  onChange={(event) =>
                    setUTR(getQueryParam(event.target.value))
                  }
                >
                  <option value="">UTR Status</option>
                  <option disabled></option>
                  {UTRSTATUS.map((item, index) => {
                    return (
                      <option key={`utr${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>
          </div>
        </div>
      </Form>
      <Row>
        <Col
          md="auto"
          style={{
            display: showActionButtons ? 'block' : 'none',
            marginTop: 10,
          }}
        >
          <Button
            color="primary"
            style={{
              background: isPIE ? '#57E4CD' : '#ffdd05',
              border: isPIE ? '#57E4CD' : '#ffdd05',
            }}
          >
            Send Notification
          </Button>
        </Col>
        {isSuperAdmin && (
          <Col
            md="auto"
            style={{
              display: showActionButtons ? 'block' : 'none',
              marginTop: 10,
            }}
          >
            <Button
              disabled={!isSuperAdmin}
              color="primary"
              onClick={() => onPaidSelectedItems(year)}
              style={{
                background: isPIE ? '#57E4CD' : '#ffdd05',
                border: isPIE ? '#57E4CD' : '#ffdd05',
              }}
            >
              Paid
            </Button>
          </Col>
        )}
        {isSuperAdmin && (
          <Col
            md="auto"
            style={{
              display: showActionButtons ? 'block' : 'none',
              marginTop: 10,
            }}
          >
            <Button
              color="primary"
              onClick={onWiseExport}
              style={{
                background: isPIE ? '#57E4CD' : '#ffdd05',
                border: isPIE ? '#57E4CD' : '#ffdd05',
              }}
            >
              Wise Export {`(${selectedCustomers?.length})`}
            </Button>
          </Col>
        )}
        {isSuperAdmin && (
          <Col
            md="auto"
            style={{
              display: showActionButtons ? 'block' : 'none',
              marginTop: 10,
            }}
          >
            <Button
              color="primary"
              onClick={onWiseExportAll}
              style={{
                background: isPIE ? '#57E4CD' : '#ffdd05',
                border: isPIE ? '#57E4CD' : '#ffdd05',
              }}
            >
              Wise Export - All {totalEntries > 0 ? `(${totalEntries})` : ''}
            </Button>
          </Col>
        )}
        {tax_status && showExportStatus && (
          <Col md="auto">
            <Row className="align-items-center">
              {renderExportFromToInputs()}

              <Button color="primary" onClick={onExportStatus}>
                {exporting ? 'Exporting...' : `Export ${exportStatus}`}
              </Button>
            </Row>
          </Col>
        )}

        <Modal
          centered
          isOpen={showExportCustomers}
          toggle={onShowExportCustomers}
        >
          <ModalHeader toggle={onShowExportCustomers}></ModalHeader>
          <ModalBody className="d-flex flex-wrap justify-content-center">
            <Col md="auto">
              <Row className="align-items-center">
                {renderExportFromToInputs()}

                <Button color="primary" onClick={onExportCustomers}>
                  {isFetching ? 'Exporting...' : 'Export'}
                </Button>
              </Row>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onShowExportCustomers}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
    </div>
  );
};

export default memo(CustomersPageFilters);
