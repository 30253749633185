import React from 'react';
import { Button } from 'src/modules';

type Props = {
    row: any;
    onClick?: ( e: any) => void;
};

export const TaxReturnRow = ( { onClick, row }:Props ) => {

    function YesNo(value:any)
    {
        if(!value || value === false || value === 'false') return 'No';
        else if(value === true || value === 'true') return 'Yes';
        else return '';
    }

    function onActionClick( action:string, id:number )
    {
        if( onClick ) onClick({ target:{ id:action, value: id }});
    }

    return (
        <>
            <tr>
                <td>{row.created_at}</td>
                <td>{row.request_status}</td>
                <td>{YesNo(row.amended_return)}</td>
                <td>{YesNo(row.welsh_return)}</td>
                <td>{YesNo(row.individual_declaration)}</td>
                <td>{row.request_datetime}</td>
                <td>
                    <div className='groupList'>
                        {
                            row.request_status == 'draft' ? 
                            //<Button color="primary" onClick={() => onActionClick('editTaxReturn', row.id)}>Edit</Button> 
                            null : 
                            <Button color="primary" onClick={() => onActionClick('viewTaxReturn', row.id)}>View</Button>
                        }                                        
                        <Button color="primary" onClick={() => onActionClick('listFormsTaxReturn', row.id)}>List Forms</Button>
                        <Button color="primary" onClick={() => onActionClick('TaxCalculator', row.id)}>Tax Calculator</Button>
                    </div>
                </td>
            </tr>
        </>
    )
}

