import React, { useState } from 'react';

import { Button } from 'reactstrap';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonIcon } from 'src/components';
import { Input, InputGroup, InputGroupAddon } from 'src/modules';
import { formatCurrency } from 'src/utils';

type Props = {
  originalValue: number;
  disallowedValue: number;
  onEdit: (value: number) => void;
};

const Editable: React.FC<Props> = ({
  originalValue,
  disallowedValue,
  onEdit,
}) => {
  const [selected, setSelected] = useState(false);
  const [newValue, setNewValue] = useState<number>(originalValue);
  const [inputValue, setInputValue] = useState(disallowedValue);

  const cleanUp = () => {
    setInputValue(0);
    setSelected(false);
  };

  const handleEdit = () => {
    cleanUp();
    setNewValue(inputValue);
    onEdit(inputValue);
  };

  return selected ? (
    <InputGroup className="w-100">
      <Input value={formatCurrency(originalValue)} disabled />
      <Input
        value={inputValue}
        type="number"
        lang="en-GB"
        onChange={(e: any) => setInputValue(Number(e.target.value))}
      />
      <InputGroupAddon addonType="append">
        <Button onClick={handleEdit}>
          <FontAwesomeIcon icon={faCheck} color="green" />
        </Button>
      </InputGroupAddon>
      <InputGroupAddon addonType="append">
        <Button onClick={cleanUp}>
          <FontAwesomeIcon icon={faTimes} color="red" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  ) : (
    <div className="d-flex align-items-center">
      <p className="m-0">
        {`${formatCurrency(newValue)} | `}
        <span className="text-danger">{formatCurrency(disallowedValue)}</span>
      </p>
      <ButtonIcon
        color="secondary"
        outline
        small
        onClick={() => setSelected((prevState) => !prevState)}
      >
        £
      </ButtonIcon>
    </div>
  );
};

export default Editable;
