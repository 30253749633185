import { useState, useMemo, Dispatch, SetStateAction } from 'react';

import isEqual from 'lodash.isequal';

const useForm = <T>(
  initialValue: T,
): {
  form: T;
  handleChange: (key: keyof T, value: string | File) => void;
  hasChanged: boolean;
  setForm: Dispatch<SetStateAction<T>>;
} => {
  const [form, setForm] = useState(initialValue);

  const handleChange = (key: keyof T, value: string | File) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const hasChanged = useMemo(
    () => !isEqual(initialValue, form),
    [initialValue, form],
  );

  return { form, handleChange, hasChanged, setForm };
};

export default useForm;
