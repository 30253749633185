import React, { useEffect, useState } from 'react';

import { Button, Input, Label } from 'src/modules';

import {
    Card,
    InputForm,
    Subtitle,
    Title,
  } from '../../styles';


type tTaxAvoidance = {
    order: number;
    id: number|null;
    tax_avoidance_scheme_reference_number: string;
    expected_advantage_tax_year:string;
}

const DEFAULT_TAX_AVOIDANCE = {
    order: 0,
    id: null,
    tax_avoidance_scheme_reference_number: '',
    expected_advantage_tax_year:''
}

type Props = {
    item: tTaxAvoidance,
    onRemove? : (e:any) => void,
    onUpdate? : (e:tTaxAvoidance) => void,
};

export const TaxAvoidanceCard = ( { item, onRemove, onUpdate } :Props ) => {


    const [ taxAvoidance, setTaxAvoidance ] = useState<tTaxAvoidance>(DEFAULT_TAX_AVOIDANCE);

    useEffect( () => {
        setTaxAvoidance(item);
    }, [])

    useEffect( () => {
        if( taxAvoidance.id != item.id || taxAvoidance.order != item.order || 
            taxAvoidance.expected_advantage_tax_year != item.expected_advantage_tax_year || 
            taxAvoidance.tax_avoidance_scheme_reference_number != item.tax_avoidance_scheme_reference_number )
            setTaxAvoidance(item);
    }, [item])

    useEffect( () => {
        if(onUpdate) onUpdate(taxAvoidance);
    }, [taxAvoidance])

    function onClick()
    {
        if(onRemove) onRemove(taxAvoidance);
    }

    function onChange( e:any )
    {
        const { name, value } = e.target;
        const newTaxAvoidance = {
          ...taxAvoidance,
          [name]: value,
        }
        setTaxAvoidance(newTaxAvoidance);        
    }

    return (
        <>

            <Card>
                <Title>Tax Avoidance Scheme {taxAvoidance.order}</Title>
                <Subtitle>
                    * Hidden input: tax_avoidance_schemes[0][id] is null for new entry / Tax Avoidance Scheme ID for updating existing entry.
                </Subtitle>
                <InputForm>
                    <Label>Tax Avoidance Scheme Reference Number</Label>
                    <Input
                    id="tax_avoidance_scheme_reference_number"
                    name="tax_avoidance_scheme_reference_number"
                    value={taxAvoidance.tax_avoidance_scheme_reference_number}
                    onChange={onChange}
                    />
                </InputForm>
                <InputForm>
                    <Label>Expected Advantage Tax Year</Label>
                    <Input
                    id="expected_advantage_tax_year"
                    name="expected_advantage_tax_year"
                    value={taxAvoidance.expected_advantage_tax_year}
                    onChange={onChange}
                    />
                </InputForm> 

                <br />
                <Button onClick={onClick} color="danger">Remove</Button>

            </Card>

        </>
    )

}