import React, { useEffect, useState, ChangeEvent } from 'react';

import { TbFileSpreadsheet } from 'react-icons/tb';
import { useQuery } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import { AddLogoButton } from '../../styles';

import { uploadProofAddress } from './../../../../../api/users/index';

type stateType = {
  user_id: number;
  document: File | null;
};

const InitialState: stateType = {
  user_id: 3,
  document: null,
};

const UploadProofAddress = () => {
  const [userId] = useQueryParam('id', NumberParam);

  const [form, setForm] = useState<stateType>(InitialState);

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) handleChange('document', file);
  };

  const handleChange = (key: string, value: string | File) => {
    setForm((form) => ({ ...form, [key]: value }));
  };

  const send_data = async () => {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('document', form.document);

    try {
      const data = await uploadProofAddress(formData);

      Toastify.toast.success(SUCCESS.GENERIC);

      return data;
    } catch ({ message }) {
      Toastify.toast.error(message);
    }
  };

  return (
    <>
      <div>
        {form.document ? (
          <img
            src={URL.createObjectURL(form.document)}
            className="img-thumbnail"
            width={200}
            alt="Proof Address"
          />
        ) : null}
        <br />
        <input
          type="file"
          onChange={imageHandle}
          hidden
          id="file_proof_address"
          name="file_proof_address"
        />
        <AddLogoButton
          htmlFor="file_proof_address"
          style={{ marginBottom: 40 }}
        >
          <TbFileSpreadsheet size={24} />
          <span>Add Proof Address</span>
        </AddLogoButton>
        <br />
        <button
          className="btn btn-primary"
          onClick={send_data}
          disabled={!form.document}
        >
          Upload
        </button>
      </div>
    </>
  );
};

export default UploadProofAddress;
