import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const TypeSelect = styled.select`
  margin-bottom: 25px;
  height: 30px;
  width: 250px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
`;
