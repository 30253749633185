import moment from 'moment-timezone';

/**
 * @description this function is for screen purposes only
 * @returns date in MM/DD/YYYY format
 *
 * @todo check why this function does not return
 * the date in DD/MM/YYYY format, since that is
 * the format supposed to be shown in the screen
 */
export const formatToScreenDate = (date?: Date | string): string => {
  if (!date) return '';

  const month = new Date(date).getMonth() + 1;

  return `${month < 10 ? '0' + month : month}/${new Date(
    date,
  ).getDate()}/${new Date(date).getFullYear()} `;
};

//-> 15:23
export const formatToScreenHour = (date: Date | string): string => {
  return `${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
};

export const conversationTime = (date: Date | string): string => {
  const output = 'DD/MM HH:mm';
  const utc = moment.utc(date).toDate();
  return moment(utc).tz('Europe/London').format(output);
};

export const conversationTimeYear = (sent_datetime: Date | string): string => {
  const inputFormat = 'DD/MM/YYYY HH:mm';
  const outputFormat = 'DD/MM/YYYY HH:mm';

  const utcMoment = moment(sent_datetime, inputFormat).tz('Europe/London');

  if (utcMoment.isValid()) {
    return utcMoment.format(outputFormat);
  } else {
    return 'Invalid Date';
  }
};

export const formatDate = (
  date?: string | Date,
  input = 'DD/MM/YYYY',
  output = 'DD/MM/YYYY',
): string => {
  if (!date) return '';
  return moment(new Date(date), input).format(output);
};

export const formatDateFullDate = (
  date: string | Date,
  input = [
    'YYYY-MM-DD HH:mm:ss',
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD',
    'DD/MM/YYYY',
  ],
  output = 'DD/MM/YYYY',
): string => {
  if (!date) return '';
  return moment(date, input).format(output);
};

export const getTaxYearStart = (year: number): string => `${year - 1}-04-06`;

export const getTaxYearEnd = (year: number): string => `${year}-04-05`;

export const getCurrentFiscalYear = (): number => {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const isBeforeApril = currentDate.isBefore(getTaxYearEnd(currentYear));
  const fiscalYear = isBeforeApril ? currentYear : currentYear + 1;

  return fiscalYear;
};

export const getYearsList = () => {
  const firstYear = 2015;
  const currentFiscalYear = getCurrentFiscalYear();
  const years = [];

  for (let year = currentFiscalYear; year >= firstYear; year--) {
    years.push(year);
  }

  return years;
};

// format to YYYY/MM/DD
export const formatDateISO = () => new Date().toISOString().slice(0, 10);
