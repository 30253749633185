import React, { useEffect } from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffMessagesRow from '../StaffMessagesRow';
import StaffRowTotalMessages from '../StaffRowTotalMessages';

type Props = {
  dataMessages?: StaffChartData;
};

const MessagesTable: React.FC<Props> = ({ dataMessages }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        Messages
      </h1>
      <Table className="text-center" style={{ marginBottom: 100 }}>
        <thead>
          <tr>
            <td></td>
            {dataMessages?.byDate.map((item, index) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {dataMessages?.byGroup.map((item, index) => (
            <StaffMessagesRow
              item={item}
              index={index}
              key={`${item.assistant}-${index}`}
            />
          ))}
          <StaffRowTotalMessages
            data={dataMessages as any}
          ></StaffRowTotalMessages>
        </tbody>
      </Table>
    </>
  );
};

export default MessagesTable;
