import React from 'react';

import { Container } from './styles';

const PhotoOrFileImage = (props: any) => {
  return props.item.files.map((file: any) => (
    <Container key={file.id} isAssistant={props.item.is_assistant}>
      <img src={file.path} alt="file attached" className="attached-img" />
    </Container>
  ));
};

export default PhotoOrFileImage;
