import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Header = styled.div`
  height: 24px;
  width: 100%;
  background: #ced4da;
  border-radius: 4px 4px 0 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 398px;
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: 24px;
  border-radius: 12px;
  padding: 20px;
`;

export const CardTitle = styled.div`
  font-size: 22px;
  font-weight: 600px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 62px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 120px;
`;

export const HeaderItem = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #191424;
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  column-gap: 120px;
`;

export const BodyItem = styled.span`
  font-size: 16px;
  color: #191424;
  position: relative;
  right: 26px;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

export const Line = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 2px solid #888;
  padding-bottom: 10px;
`;
