import React, { ChangeEvent, memo, useState } from 'react';

import { Link } from 'gatsby';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICustomer, TIsPaidStatus } from 'src/models';
import { Col, CustomInput } from 'src/modules';
import {
  CustomerTabs,
  Routes,
  validCount,
  PaidStatusPage,
  openOnNewTab,
} from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import IconCalled from '../customers/IconCalled';
import IconCustomerStatus from '../customers/IconCustomerStatus';
import IconDeleteOrDisableCustomer from '../customers/IconDeleteOrDisableCustomer';
import IconPaidStatus from '../customers/IconPaidStatus/IconPaidStatus';
import IconTaxAssistant from '../customers/IconTaxAssistant';
import IconTaxCode from '../customers/IconTaxCode';
import IconUTR from '../customers/IconUTR';
import StatusInfo from '../customers/StatusInfo';
import TaxRefundAmount from '../customers/TaxRefundAmount';
import CustomersProgress from '../CustomersProgress';
import TableRow from '../TableRow';
import { Avatar } from '../ui';

type Props = {
  item: ICustomer;
  index: number;
  onSelectCustomer: any;
  onSelectedCustomers: any;
};

/*
assistant click
list admins
assing  Update tax assistant by year
/users/update-tax-assistant
*/

const CustomersRow: React.FC<Props> = ({
  item,
  index,
  onSelectCustomer,
  onSelectedCustomers,
}) => {
  const hasNotifications = validCount(item.notifications_count);

  const [deleted, setDeleted] = useState(false);
  const [paidStatus, setPaidStatus] = useState<TIsPaidStatus>(
    item.is_paid_status,
  );

  // hide this row
  const onCustomerDeleted = () => {
    setDeleted(true);
  };

  const onSelectTab = (tab: string) => {
    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tab=${tab}&tax_year=${item.tax_year}`,
    );
  };

  const onClickPaidStatus = (value: TIsPaidStatus) => setPaidStatus(value);

  return (
    <TableRow
      className={
        'no-gutters py-3 align-items-center ' +
        (deleted && 'opacity-2 pointer-events-none')
      }
    >
      <Col className="col-index text-center">{index + 1}</Col>
      <Col className="col-index">
        <CustomInput
          type="checkbox"
          id={`input-${item.id}`}
          name="customSwitch"
          checked={item.selected || false}
          onChange={(e: ChangeEvent) => {
            onSelectCustomer(item);
            onSelectedCustomers(e, item.id);
          }}
        />
      </Col>
      <Col className="col-name">
        <div className="link" onClick={() => onSelectTab(CustomerTabs.DETAILS)}>
          <div className="d-flex align-items-center">
            <Avatar name={item.full_name} image={item.avatar} />
            {item.full_name}
            <br />
            {item.email}
            <br />
            {item.utr_no}
          </div>
        </div>
      </Col>

      <Col className="col-progress justify-content-center">
        <CustomersProgress
          onSelectTab={onSelectTab}
          item={item}
          paidStatus={paidStatus}
        />
      </Col>

      <Col className="col-status text-center d-flex flex-column align-items-center">
        <StatusInfo
          user_id={item.id}
          info={item.status_info}
          year={item.tax_year}
        />
      </Col>

      <Col className="col-auto d-flex justify-content-center">
        <IconTaxAssistant
          id={item.id}
          year={item.tax_year}
          name={item.tax_assistant_name}
          image={item.tax_assistant_image}
          assistant_id={item.tax_assistant_id}
        />

        <IconCalled id={item.id} called={item.is_called} year={item.tax_year} />

        <Link
          to={`${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tax_year=${item.tax_year}&tab=task_history`}
        >
          <ButtonIcon
            badge={item.notifications_count}
            outline={!hasNotifications}
            color={!hasNotifications ? 'secondary' : 'primary'}
            id="notifications"
            tooltip="Notifications"
          >
            <FontAwesomeIcon icon={faBell} />
          </ButtonIcon>
        </Link>
        <IconPaidStatus
          id={item.id}
          year={item.tax_year}
          is_paid_status={item.is_paid_status}
          tax_status={item.tax_status}
          type={PaidStatusPage.OVERVIEW}
          onClickPaidStatus={onClickPaidStatus}
        />

        <IconUTR
          id={item.id}
          year={item.tax_year}
          requested_utr={item.requested_utr}
        />

        <IconTaxCode
          id={item.id}
          year={item.tax_year}
          complete={item.tax_code_complete}
        />
      </Col>

      <Col className="col-tax text-center">
        <TaxRefundAmount
          id={item.id}
          tax_id={item.tax_id}
          year={item.tax_year}
          amount={item.refund_amount}
          agent_fee={item.agent_fee}
          agent_fee_type={item.agent_fee_type}
          agent_fee_percentage={item.agent_fee_percentage}
          tax_stauts={item.tax_status}
        />
      </Col>

      <Col className="col-active text-center">
        <IconCustomerStatus id={item.id} status={item.status} />
      </Col>

      <Col className="col-action text-center d-flex justify-content-center">
        <IconDeleteOrDisableCustomer
          user={item}
          onRemoved={onCustomerDeleted}
          isIconDelete
        />
      </Col>
    </TableRow>
  );
};

export default memo(CustomersRow);
