import React, { useState } from 'react';

import { CustomInput, Label, Table } from 'src/modules';
import { formatAmount, validCount } from 'src/utils';
import { TDashboardCharts } from 'src/utils/dashboardDataset';

import { LeftCol } from './styled';

type Props = {
  title: string;
  data?: TDashboardCharts;
  showToggle?: boolean;
};

const DashboardTable: React.FC<Props> = ({ title, data, showToggle }) => {
  const [showUsers, setShowUsers] = useState(!showToggle);

  const getPercent = (value: any) => {
    const total: any = showUsers
      ? data?.stackedBar.total
      : data?.stackedBar.totalAmounts;
    const result = (100 * parseInt(value)) / parseInt(total);
    if (!validCount(result)) return 0;
    if (result < 1) {
      return result.toFixed(1);
    } else {
      return result.toFixed(0);
    }
  };

  return (
    <div className="pr-4" style={{ marginTop: 1 }}>
      <Table className="text-center" striped>
        <thead>
          <tr>
            <LeftCol className="fixed-col-striped">
              {showToggle && (
                <Label
                  for={title}
                  className="d-flex align-items-center mr-2 mb-0"
                >
                  <CustomInput
                    type="checkbox"
                    id={title}
                    checked={showUsers}
                    onChange={() => setShowUsers(!showUsers)}
                  />
                  <span className="mr-2">Show users</span>
                </Label>
              )}
            </LeftCol>
            {data?.stackedBar.days.map((item, index) => (
              <td key={`${item}-${index}`}>{item}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {data?.stackedBar.datasets.map((item, index) => (
            <tr key={`${item.label}-${index}`}>
              <LeftCol className="text-left">{item.label}</LeftCol>
              {item &&
                item?.data.map((count, di) => (
                  <td key={`count-${count}-${di}`}>
                    {showUsers &&
                      (count ? count : <span className="opacity-2">{0}</span>)}

                    {!showUsers &&
                      (item.amounts[di] ? (
                        formatAmount(item.amounts[di])
                      ) : (
                        <span className="opacity-2">{0}</span>
                      ))}
                  </td>
                ))}
              <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
                <strong>
                  {showUsers ? (
                    <>
                      <span>{item.total}</span>
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        ({getPercent(item.total)})%
                      </span>
                    </>
                  ) : (
                    <>
                      <span>{formatAmount(item.totalAmounts)}</span>
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        ({getPercent(item.totalAmounts)})%
                      </span>
                    </>
                  )}
                </strong>
              </td>
            </tr>
          ))}
          <tr className="bg-primary">
            <td></td>
            {data?.stackedBar.periodAmountTotals.map((item, index) => (
              <td key={`${item}-${index}`} className={`dashboard-tr-data`}>
                <strong>
                  {showUsers ? (
                    <>
                      <span>{data?.stackedBar.periodTotals[index]}</span>
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        ({getPercent(data?.stackedBar.periodTotals[index])})%
                      </span>
                    </>
                  ) : (
                    <>
                      <span>{formatAmount(item)}</span>
                      <span style={{ fontSize: '11px', opacity: '.5' }}>
                        {' '}
                        ({getPercent(item)})%
                      </span>
                    </>
                  )}
                </strong>
              </td>
            ))}
            <td className="bg-primary">
              <strong>
                {showUsers
                  ? data?.stackedBar.total
                  : formatAmount(data?.stackedBar.totalAmounts)}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default DashboardTable;
