import { action, observable } from 'mobx';

import { NotificationsApi } from 'src/api';

export default class NotificationsStore {
  @observable
  notifications: NotificationsTypes = {
    data: [],
    has_more: false,
    last_page: 0,
    message: '',
    next_page: 0,
    next_page_url: '',
    per_page: 0,
    previous_page_url: '',
    total_entries: 0,
    total_pages: 0,
  };

  @action
  fetchNotifications = async (query?: string): Promise<NotificationsTypes> => {
    const data = await NotificationsApi.fetchNotifications(query);
    this.notifications = data;
    return data;
  };
}
