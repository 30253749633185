import styled from 'styled-components';

type Props = {
  background?: string;
  color?: string;
  selected?: boolean;
  backgroundColor?: string;
};

export const CurrentStatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .selected {
    background-color: #191424;
    color: #fff;
    border: none;
  }
`;

export const StatusButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #191424;
  height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 48px;
  padding: 10px;
`;

export const Button = styled.button<Props>`
  background: ${(Props2) => Props2.background};
  color: ${(Props2) => Props2.color};
  height: 40px;
  width: 102px;
  border: none;
  border-radius: 48px;
  font-size: 18px;
  font-weight: 600;
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 20px;
`;

export const SectionButton = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
