import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Layout, TableHead, TableRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Container, Input, Button, Col } from 'src/modules';
import store from 'src/stores';

const NotificationTemplateEdit: React.FC = () => {
  // Get URL params
  const [id] = useQueryParam('id', NumberParam);

  // Mobx store
  const notificationTemplates = store.notificationTemplates;

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [adminMessage, setAdminMessage] = useState<string>('');

  const fetchTemplate = async (): Promise<void> => {
    try {
      setLoading(true);
      await notificationTemplates.getById(Number(id));
      setMessage(notificationTemplates.current.data.message);
      setAdminMessage(notificationTemplates.current.data.admin_message);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async (): Promise<void> => {
    try {
      setLoading(true);
      const data: NotificationTemplateUpdate = {
        id: Number(id),
        message: message,
        admin_message: adminMessage || '',
      };

      await notificationTemplates.updateTemplate(data);
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  const onGoBack = () => navigate(-1);

  useEffect(() => {
    fetchTemplate();
  }, []);

  return (
    <Layout>
      <Dashboard title="Notification Templates">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">Edit Notification Template</Col>
            </TableHead>
            {loading ? (
              <Loading />
            ) : (
              <>
                <TableRow className="align-items-center">
                  <Col xs="3">Title</Col>
                  <Col xs="4">
                    <Input
                      disabled
                      type="text"
                      name="title"
                      value={notificationTemplates.current.data.title}
                    />
                  </Col>
                </TableRow>
                <TableRow className="align-items-center">
                  <Col xs="3">Tag</Col>
                  <Col xs="4">
                    <Input
                      type="text"
                      disabled
                      name="tag"
                      value={notificationTemplates.current.data.tag}
                    />
                  </Col>
                </TableRow>
                <TableRow className="align-items-center">
                  <Col xs="3">Message</Col>
                  <Col xs="4">
                    <Input
                      type="textarea"
                      name="message"
                      placeholder="Enter the message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Col>
                </TableRow>
                <TableRow className="align-items-center">
                  <Col xs="3">Admin Message</Col>
                  <Col xs="4">
                    <Input
                      type="textarea"
                      name="admin_message"
                      placeholder="Enter the admin message"
                      value={adminMessage}
                      onChange={(e) => setAdminMessage(e.target.value)}
                    />
                  </Col>
                </TableRow>

                <TableRow className="align-items-center">
                  <Col xs="3"></Col>
                  <Col xs="3" className="d-flex">
                    <Button
                      type="button"
                      color="primary"
                      className="mr-3"
                      onClick={onSave}
                    >
                      SUBMIT
                    </Button>
                    <Button type="button" color="primary" onClick={onGoBack}>
                      BACK
                    </Button>
                  </Col>
                </TableRow>
              </>
            )}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default NotificationTemplateEdit;
