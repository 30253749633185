import { QueryFunctionContext } from 'react-query';

import request from '../request';
import ResponseError from '../ResponseError';

export const fetchCustomerExpenses = async (
  userId: number,
  taxYear: number,
): Promise<TaxIncomeListTypes> => {
  const { data } = await request.get(
    `/users/tax-income/listing/?user_id=${userId}&tax_year=${taxYear}`,
  );
  return data;
};

export const submitExpense = async (
  userId: number,
  taxYear: number,
): Promise<void> => {
  const data = {
    user_id: userId,
    tax_year: taxYear,
  };
  await request.post(`/expenses/submit`, data);
};

export const listExpenses = async ({
  queryKey,
}: QueryFunctionContext): Promise<ListExpensesResponse> => {
  const [, nextPage] = queryKey;

  const { data } = await request.get(`/expenses?page=${nextPage}`);

  return data;
};

export const editExpense = async ({
  id,
  categoryId,
  description,
}: {
  id: number;
  categoryId: number;
  description: string;
}) => {
  try {
    const { data } = await request.post(`/expenses/edit?id=${id}`, {
      cat_id: categoryId,
      description,
    });

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const deleteExpense = async ({
  id,
}: {
  id: number;
}): Promise<{ message: string; id: number }> => {
  try {
    const { data } = await request.delete(`/expenses/delete?id=${id}`);

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const createExpense = async ({
  categoryId,
  description,
}: {
  categoryId: number;
  description: string;
}): Promise<Expense> => {
  try {
    const { data } = await request.post(`/expenses/create`, {
      cat_id: categoryId,
      description,
    });

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
