import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Input,
  Button,
  Col,
  observer
} from "src/modules";
import { Layout, TableHead, TableRow } from "src/components";
import Dashboard from "src/layouts/Dashboard";
import store from "src/stores";

// markup
const CostSuggestion: React.FC = () => {
  const { costSuggestions } = store;

  const [editedSuggestions, setEditedSuggestions] = useState<SuggestionValues[]>([]);

  const fetchSuggestions = async () => {
    await costSuggestions.getSuggestions();
  }

  useEffect(() => {
    fetchSuggestions()
  }, [])

  useEffect(() => {
    setEditedSuggestions(costSuggestions.suggestions.map(
      ({ start_value, low_value, high_value, end_value }) => ({ start_value, low_value, high_value, end_value })
    ))
  }, [costSuggestions.suggestions])

  const handleValueChange = (id: number, key: keyof SuggestionValues, value: string) => {
    setEditedSuggestions(prevState => prevState.map((suggestion, index) => index === id ? { ...suggestion, [key]: value } : suggestion))
  }

  const handleSubmit = React.useCallback(async () => {
    await costSuggestions.editSuggestions(editedSuggestions)
  }, [editedSuggestions, costSuggestions])

  return (
    <Layout>
      <Dashboard title="Cost Suggestion">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">Cost Suggestions</Col>
            </TableHead>
            <TableRow>
              <Col xs="3">Cost Suggestion Name</Col>
              <Col xs="1">Start Value</Col>
              <Col xs="1">Low Value</Col>
              <Col xs="1">High Value</Col>
              <Col xs="1">End Value</Col>
            </TableRow>
            {costSuggestions.suggestions.map(({ key_text, field_name }, index) => (
              <TableRow key={key_text}>
                <Col xs="3">{field_name}</Col>
                <Col xs="1">
                  <Input 
                  type="text" 
                  name="description" 
                  value={editedSuggestions[index]?.start_value.toString() || ''}
                  onChange={e => handleValueChange(index, 'start_value', e.target.value)}
                />
                </Col>
                <Col xs="1">
                  <Input 
                  type="text" 
                  name="description" 
                  value={editedSuggestions[index]?.low_value.toString() || ''}
                  onChange={e => handleValueChange(index, 'low_value', e.target.value)}
                />
                </Col>
                <Col xs="1">
                  <Input 
                  type="text" 
                  name="description" 
                  value={editedSuggestions[index]?.high_value.toString() || ''}
                  onChange={e => handleValueChange(index, 'high_value', e.target.value)}
                />
                </Col>
                <Col xs="1">
                  <Input 
                  type="text" 
                  name="description" 
                  value={editedSuggestions[index]?.end_value.toString() || ''}
                  onChange={e => handleValueChange(index, 'end_value', e.target.value)}
                />
                </Col>
              </TableRow>
            ))}

            <TableRow>
              <Col xs="3"></Col>
              <Col xs="3" className="d-flex">
                <Button type="button" color="primary" className="mr-3" onClick={handleSubmit}>
                  SUBMIT
                </Button>
                <Link to="/#">
                  <Button type="button" color="primary">
                    BACK
                  </Button>
                </Link>
              </Col>
            </TableRow>
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default observer(CostSuggestion);
