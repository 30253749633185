import React, { useState } from 'react';

import { Link } from 'gatsby';
import { useQueryClient } from 'react-query';

import {
  faChevronDown,
  faChevronUp,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { useYear } from 'src/hooks';
import { INotificationDetails, INotificationItem } from 'src/models';
import { Container, Col, Badge, observer } from 'src/modules';
import { CustomerTabs, openOnNewTab, Routes } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import NotificationRowDetails from '../NotificationRowDetails';
import TableHead from '../TableHead';
import TableRow from '../TableRow';
import { Loading } from '../ui';

// TODO: notification model
type Props = {
  item: NotificationsData;
};

const NotificationRow: React.FC<Props> = ({ item }) => {
  const queryClient = useQueryClient();
  const [year] = useYear();

  const [loading, setLoading] = useState<boolean>(false);
  const [showNotificationDetails, setShowNotificationDetails] =
    useState<boolean>(false);
  const [notificationDetails, setNotificationDetails] = useState<
    INotificationDetails[]
  >([]);
  const [localItem, setLocalItem] = useState(item);

  const showAndHideDetails = async () => {
    try {
      setLoading(true);
      const isShow = !showNotificationDetails;
      setShowNotificationDetails(isShow);

      if (isShow) {
        const { data } = await request.get(
          `${API.PATH}${API.NOTIFICATIONS_LIST}?user_id=${item.id}`,
        );

        setNotificationDetails(data.data);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const setUnreadCount = async (isUnread: number): Promise<void> => {
    await queryClient.invalidateQueries('notificationsCounter');

    let unreadCount = Number(localItem.unread_count);
    if (isUnread) unreadCount += 1;
    else unreadCount -= 1;

    if (unreadCount < 0) unreadCount = 0;

    setLocalItem({
      ...localItem,
      unread_count: unreadCount,
    });
  };

  const onSelectTab = (tab: string) => {
    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tab=${tab}&tax_year=${year}`,
    );
  };

  return (
    <>
      <TableRow
        className={`${
          showNotificationDetails ? 'bg-gray-selected' : null
        } align-items-center`}
      >
        <Col
          xs="8"
          className="pl-4 link"
          onClick={() => onSelectTab(CustomerTabs.DETAILS)}
        >
          {localItem.full_name} ({localItem.email})
        </Col>
        <Col xs="2">
          <div className="justify-content-center text-center">
            <Badge color="danger" pill>
              {localItem.unread_count}
            </Badge>
          </div>
        </Col>
        <Col xs="2">
          <div className="d-flex flex-row justify-content-center border-top-0 p-1 hello">
            <Link
              to={`${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tab=task_history`}
              target="_blank"
            >
              <ButtonIcon
                outline={true}
                color="secondary"
                className="table-button"
              >
                <FontAwesomeIcon icon={faEye} />
              </ButtonIcon>
            </Link>
            <ButtonIcon
              onClick={() => showAndHideDetails()}
              className="table-button"
            >
              <FontAwesomeIcon
                icon={showNotificationDetails ? faChevronUp : faChevronDown}
              />
            </ButtonIcon>
          </div>
        </Col>

        {loading && (
          <Col xs={12}>
            <Loading />
          </Col>
        )}
      </TableRow>
      {showNotificationDetails && (
        <Container
          className={
            'mw-100 py-3 px-4 ' +
            (showNotificationDetails ? 'bg-gray-selected' : '')
          }
          style={{ marginTop: '-1.1rem' }}
        >
          {notificationDetails?.length > 0 && (
            <TableHead>
              <Col xs="8" className="pl-2">
                Notification
              </Col>
              <Col xs="2" className="text-center">
                Date
              </Col>
              <Col xs="2" className="text-center">
                Action
              </Col>
            </TableHead>
          )}
          {notificationDetails.map((el: any, i: number) => (
            <NotificationRowDetails
              user_id={item.id}
              item={el}
              setUnreadCount={setUnreadCount}
              key={i}
            />
          ))}
        </Container>
      )}
    </>
  );
};

export default observer(NotificationRow);
