import React, { useState } from 'react';

import { navigate } from 'gatsby';

import { Layout, ButtonLoader } from 'src/components';
import { useForm } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { Button, Input, Toastify } from 'src/modules';
import store from 'src/stores';
import { Field, FormRow } from 'src/styles/pages/customers/create.styles';
import { ERRORS, isValidEmail, isValidPassword, SUCCESS } from 'src/utils';
import { CustomerCreateData } from 'src/utils/types/Customer';

const initialValues = {
  prefix: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  insurance_no: '',
  dob: '',
  postal_code: '',
  address: '',
  phone: '',
  password: '',
  confirm_password: '',
};
const CustomerCreate: React.FC = () => {
  const customers = store.customer;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [validName, setValidName] = useState<boolean>(true);

  const { form, handleChange, setForm } =
    useForm<CustomerCreateData>(initialValues);

  const handlePasswordMatch = () => {
    const valid = isValidPassword(form.password);
    setValidPassword(valid);
    if (!valid) return;

    const isEmpty = form.password === '' || form.confirm_password === '';

    if (isEmpty) {
      setPasswordMatch(false);
      return false;
    } else {
      const match = form.password === form.confirm_password;
      setPasswordMatch(match);
      return match;
    }
  };

  const handleNameValidation = () => {
    const valid = form.first_name.trim().length >= 2;
    setValidName(valid);
    return valid;
  };

  const handleEmailValidation = () => {
    const valid = isValidEmail(form.email.trim());
    setValidEmail(valid);
    return valid;
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const samePassword = handlePasswordMatch();
      const validName = handleNameValidation();
      const validEmail = handleEmailValidation();

      const validForm = samePassword && validName && validEmail;

      if (validForm) {
        delete form?.confirm_password;
        await customers.createCustomer(form);
        Toastify.toast.success(SUCCESS.CLIENT_CREATED);
        setForm(initialValues);
      }
    } catch {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return (
    <Layout>
      <Dashboard title={`Add New Customer`}>
        <FormRow>
          <Field width="10%">
            <label>Prefix</label>
            <Input
              value={form.prefix}
              onChange={(e) => handleChange('prefix', e.target.value)}
              bsSize="lg"
              type="text"
              name="prefix"
              maxLength={10}
              placeholder="Ex: Mr/Ms"
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field width="30%">
            <label>First name</label>
            <Input
              value={form.first_name || ''}
              onChange={(e) => handleChange('first_name', e.target.value)}
              bsSize="lg"
              type="text"
              maxLength={100}
              name="first_name"
            />
            {!validName && <span>First name is mantadory</span>}
          </Field>
          <Field width="30%">
            <label>Middle name</label>
            <Input
              value={form.middle_name}
              onChange={(e) => handleChange('middle_name', e.target.value)}
              bsSize="lg"
              type="text"
              maxLength={100}
              name="middle_name"
            />
          </Field>
          <Field width="30%">
            <label>Last name</label>
            <Input
              value={form.last_name}
              onChange={(e) => handleChange('last_name', e.target.value)}
              bsSize="lg"
              type="text"
              maxLength={100}
              name="last_name"
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field width="30%">
            <label>Email</label>
            <Input
              value={form.email}
              onChange={(e) => handleChange('email', e.target.value)}
              bsSize="lg"
              type="text"
              name="email"
              maxLength={320}
              placeholder="email@example.com"
            />
            {!validEmail && <span>Email is mantadory</span>}
          </Field>
          <Field width="20%">
            <label>Phone Number</label>
            <Input
              value={form.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
              bsSize="lg"
              type="text"
              name="phone"
              maxLength={20}
              placeholder="0781234567"
            />
          </Field>
          <Field width="20%">
            <label>Date of Birth</label>
            <Input
              value={form.dob}
              onChange={(e) => handleChange('dob', e.target.value)}
              bsSize="lg"
              type="text"
              name="dob"
              maxLength={10}
              placeholder="DD/MM/AAAA"
            />
          </Field>
          <Field width="20%">
            <label>National Insurance Number</label>
            <Input
              value={form.insurance_no}
              onChange={(e) => handleChange('insurance_no', e.target.value)}
              bsSize="lg"
              type="text"
              name="insurance_no"
              maxLength={12}
              placeholder="AA000000X"
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field width="20%">
            <label>Postal Code</label>
            <Input
              value={form.postal_code}
              onChange={(e) => handleChange('postal_code', e.target.value)}
              bsSize="lg"
              type="text"
              maxLength={12}
              name="postal_code"
              placeholder="N4 2XX"
            />
          </Field>
          <Field width="20%">
            <label>Address</label>
            <Input
              value={form.address}
              onChange={(e) => handleChange('address', e.target.value)}
              bsSize="lg"
              maxLength={500}
              type="textarea"
              name="address"
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field width="20%">
            <label>Password</label>
            <Input
              value={form.password}
              onChange={(e) => handleChange('password', e.target.value)}
              bsSize="lg"
              type="password"
              name="password"
              maxLength={100}
              placeholder="Min 8 chars, 1 Lowercase, 1 Uppercase, 1 Number"
            />
            {!validPassword && (
              <span>Min 8 chars, 1 Lowercase, 1 Uppercase, 1 Number</span>
            )}
          </Field>
          <Field width="20%">
            <label>Confirm password</label>
            <Input
              value={form.confirm_password}
              onChange={(e) => handleChange('confirm_password', e.target.value)}
              bsSize="lg"
              maxLength={100}
              type="password"
              name="confirm_password"
            />

            {!passwordMatch && <span>Passwords don't match</span>}
          </Field>
        </FormRow>

        <FormRow>
          <Button color="primary" onClick={onSubmit}>
            {isLoading ? <ButtonLoader /> : 'SUBMIT'}
          </Button>
          <Button onClick={onCancel}>CANCEL</Button>
        </FormRow>
      </Dashboard>
    </Layout>
  );
};

export default CustomerCreate;
