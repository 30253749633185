import React, { useEffect, useState, ChangeEvent } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { addTransaction, updatePAYE } from 'src/api/incomes';
import { getExpensesCategories, getTaxIncomeListing } from 'src/api/users';
import {
  calcPersonal,
  calcWork,
} from 'src/components/CustomerInformationTabs/Common';
import {
  FooterTitle,
  SliptRange,
  SplitContainer,
  SplitSection,
} from 'src/components/CustomerInformationTabs/ExpensesTab/Components/SelfEmpTransactions/TransactionForm/styles';
import ViewDocs from 'src/components/CustomerInformationTabs/IncomesTab/Components/ViewDocs';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { PayeButton } from 'src/styles/pages/components/PayeButton';
import { SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeHeaderButtons,
  IncomeInputsContainer,
  HeaderSelectInput,
  TransactionsSection,
  ViewImageSection,
  OpenContentButton,
  OpenContentContainer,
  ContentContainer,
  Button,
  Select,
  OtherInformations,
  OtherInformationsInputSection,
} from '../../styles';
import { ProofDetails, ProofItem } from '../AddPAYE/styles';
import { EmployerNameSection } from '../TabAll/styles';
import {
  AddTransactionSection,
  Title,
  TransactionInputContainer,
  UploadContainer,
} from '../TabProperty/styles';
import TransactionsList from '../Transactions/TransactionsList';
import ViewImage from '../ViewImage';

import {
  AddCancelSection,
  AddLogoButton,
  AddProofContainer,
  CancelTransactionButton,
  CategoryIdLabel,
  EmploymentLogo,
  FlowTypeLabel,
  OtherInformationsContainer,
  UpdateCancelSection,
} from './styles';

type HeaderPayeType = {
  id: number;
  employer_name: string;
  logo: string;
  ref: string;
  gross_amount: string;
  tax_amount: string;
  is_company_director: number;
  has_benefits: number;
  benefits_amount: string;
  created_at: string;
  open_form_transaction: boolean;
};

const addTransactionFields = {
  client_name: '',
  date: null,
  amount: '',
  files: [],
  category_id: 0,
  work_percentage: 50,
};

const filesList = [];

let refetchList: () => void;

function TabPAYE() {
  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const incomes = store.incomes;
  const expenses = store.expenses;

  const [payeHeaderList, setPayeHeaderList] = useState<HeaderPayeType[]>([]);
  const [incomeIdSelected, setIncomeIdSelected] = useState(0);
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);

  const [proofDocuments, setProofDocuments] = useState([]);
  const [errors, setErrors] = useState({});

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListing(userId, year, 'employment'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { data: dataCategories, isLoading: isLoadingCategories } = useQuery(
    'getExpensesCategories',
    () => getExpensesCategories('employment'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationUpdatePAYE } = useMutation('updatePAYE', updatePAYE, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);

      expenses.refetchTotals();
      expenses.refetchSummary();
    },
    onError({ error }) {
      Toastify.toast.error(error);
    },
  });

  const { mutate: mutationAddTransaction, isSuccess } = useMutation(
    'addTransaction',
    addTransaction,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setOpenAddTransactionForm(false);
          refetchList();
          expenses.refetchTotals();
          expenses.refetchSummary();
        }, 1000);
      },
      onError({ error }) {
        Toastify.toast.error(error);
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      //list.forEach((item, index) => (list[index].editMode = false));

      list.forEach((item, index) => {
        list[index].editMode = false;
        list[index].addTransactionFields = { ...addTransactionFields };
        list[index].open_form_transaction = false;
      });
      setPayeHeaderList(list);
    }
  }, [data]);

  const onUpdatePayeHeader = (editMode: boolean, index: number, id: number) => {
    const newPayeHeaderList = payeHeaderList;

    if (editMode) {
      newPayeHeaderList[index].editMode = false;
      setPayeHeaderList([...newPayeHeaderList]);

      if (newPayeHeaderList[index].id === id) {
        const itemEdited = newPayeHeaderList[index];

        const formData = new FormData();

        formData.append('customer_id', userId);
        formData.append('tax_year', year);
        formData.append('employer_name', itemEdited.employer_name);
        formData.append('ref', itemEdited.ref);
        formData.append('gross_amount', itemEdited.gross_amount);
        formData.append('tax_amount', itemEdited.tax_amount);

        formData.append('additional_earnings', itemEdited.additional_earnings);
        formData.append('is_company_director', itemEdited.is_company_director);
        formData.append('flow_type', itemEdited.flow_type);
        formData.append('has_benefits', itemEdited.has_benefits);
        formData.append('_method', 'PUT');

        mutationUpdatePAYE({ id: newPayeHeaderList[index].id, formData });
      }
    } else {
      newPayeHeaderList[index].editMode = true;
      setPayeHeaderList([...newPayeHeaderList]);
    }
  };

  const onOpenContent = (
    index: number,
    openContent: boolean,
    income_id: number,
  ) => {
    const newPayeHeaderList = payeHeaderList;

    newPayeHeaderList[index].openContent = !openContent;
    setPayeHeaderList([...newPayeHeaderList]);
    setIncomeIdSelected(income_id);
  };

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const newPayeHeaderList = [...payeHeaderList];

    const removeDuplicatedPound = target.value.replace(/(£)+/, '£');
    const valueWithoutPound = removeDuplicatedPound.replace('£', '');

    newPayeHeaderList[index][target.name] = valueWithoutPound;
    setPayeHeaderList(newPayeHeaderList);
  };

  const onCancelUpdateItem = (index: number) => {
    const newPayeHeaderList = [...payeHeaderList];

    newPayeHeaderList[index].editMode = false;
    setPayeHeaderList([...newPayeHeaderList]);
  };

  const onInputAddTransactionChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...payeHeaderList];

    list[index].addTransactionFields[target.name] = target.value;
    setPayeHeaderList(list);
  };

  const onAddTransaction = (index: number, income_id: number) => {
    const fields = payeHeaderList[index];

    const formErrors = validateFields(
      payeHeaderList[index].addTransactionFields,
      {
        client_name: { required: true },
        amount: { required: true },
        // files: { required: true },
      },
    );

    const formData = new FormData();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('client_name', fields.addTransactionFields.client_name);
      formData.append('income_id', income_id);
      formData.append('income_type', 'employment');
      formData.append('reconciliation_type', 'expense');
      formData.append('date', fields.addTransactionFields.date);
      formData.append('amount', fields.addTransactionFields.amount);
      formData.append(
        'work_percentage',
        fields.addTransactionFields.work_percentage,
      );
      formData.append('category_id', fields.addTransactionFields.category_id);

      formData.append('files', proofDocuments);

      mutationAddTransaction(formData);

      const list = [...payeHeaderList];

      list[index].addTransactionFields.client_name = '';
      list[index].addTransactionFields.date = new Date();
      list[index].addTransactionFields.amount = 0;
    }
  };

  const validateFields = (fields, rules) => {
    const formErrors = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  /* const onChangeFieldTransaction = (
    transactionId: number,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const target = event.target;
    const list = [...incomes.incomeTransactions];

    list[index][target.name] = target.value;
  }; */

  const onShowDocuments = (index: number, showDocuments: boolean) => {
    const newPayeHeaderList = payeHeaderList;

    newPayeHeaderList[index].showDocuments = !showDocuments;
    setPayeHeaderList([...newPayeHeaderList]);
  };

  const renderError = (field: any) => (
    <span style={{ color: 'red' }}>{field}</span>
  );

  const onCancelAddTransaction = (index: number) => {
    setOpenAddTransactionForm(false);

    const list = [...payeHeaderList];

    list[index].addTransactionFields.client_name = '';
    list[index].addTransactionFields.date = new Date();
    list[index].addTransactionFields.amount = 0;

    setProofDocuments([]);
    setPayeHeaderList(list);
  };

  let newDocsList = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <div>
      {!isLoading &&
        payeHeaderList.map((item, index) => {
          const workResult = calcWork(
            item.addTransactionFields.amount,
            item.addTransactionFields.work_percentage,
          );

          const personalResult = calcPersonal(
            item.addTransactionFields.amount,
            workResult,
          );

          return (
            <>
              <IncomeContainer key={item.id}>
                <IncomeHeader>
                  <div>
                    <EmploymentLogo src={handleLogo('employment', '')} alt="" />
                  </div>
                  <IncomeInputsContainer>
                    <IncomeHeaderFields>
                      <Row>
                        <Col>
                          <EmployerNameSection>
                            <EditInputPie
                              id="employer_name"
                              label="Employer name:"
                              name="employer_name"
                              type="text"
                              value={item.employer_name}
                              onChange={(event) => onInputChange(index, event)}
                              editMode={item.editMode}
                              disabled={!item.editMode}
                            />
                          </EmployerNameSection>
                        </Col>

                        <Col>
                          <EditInputPie
                            id="ref"
                            label="Employer Paye"
                            name="ref"
                            type="text"
                            value={item.ref}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Gross amount:"
                            id={`gross_amount_${index}`}
                            name="gross_amount"
                            type="text"
                            value={`£${
                              item.gross_amount ? item.gross_amount : 0
                            }`}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Tax amount:"
                            id={`tax_amount${index}`}
                            name="tax_amount"
                            type="text"
                            value={`£${item.tax_amount ? item.tax_amount : 0}`}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Status:"
                            id="status"
                            name="status"
                            type="text"
                            value={
                              !item.status ? (item.status = '') : item.status
                            }
                            onChange={(event) => onInputChange(index, event)}
                            editMode={false}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <EmployerNameSection>
                            <OtherInformationsInputSection>
                              <label htmlFor="director-position">
                                Has benefit
                              </label>
                              <HeaderSelectInput
                                id="has_benefits"
                                name="has_benefits"
                                onChange={(event) =>
                                  onInputChange(index, event)
                                }
                                editMode={item.editMode}
                                disabled={!item.editMode}
                              >
                                <option
                                  value={true}
                                  selected={item.has_benefits}
                                >
                                  Yes
                                </option>

                                <option
                                  value={false}
                                  selected={!item.has_benefits}
                                >
                                  No
                                </option>
                              </HeaderSelectInput>
                            </OtherInformationsInputSection>
                          </EmployerNameSection>
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Additional earnings:"
                            id="additional_earnings"
                            name="additional_earnings"
                            type="text"
                            value={
                              item.additional_earnings === null
                                ? (item.additional_earnings = 0)
                                : item.additional_earnings
                            }
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <OtherInformationsInputSection>
                            <label htmlFor="director-position">
                              Is company director:
                            </label>
                            <HeaderSelectInput
                              id="is_company_director"
                              name="is_company_director"
                              onChange={(event) => onInputChange(index, event)}
                              editMode={item.editMode}
                              disabled={!item.editMode}
                            >
                              <option
                                value={true}
                                selected={item.is_company_director}
                              >
                                Yes
                              </option>

                              <option
                                value={false}
                                selected={!item.is_company_director}
                              >
                                No
                              </option>
                            </HeaderSelectInput>
                          </OtherInformationsInputSection>
                        </Col>

                        <Col>
                          <div>
                            <FlowTypeLabel
                              htmlFor="flow_type"
                              className="title"
                            >
                              Flow type
                            </FlowTypeLabel>{' '}
                            <br />
                            <select
                              id="flow_type"
                              name="flow_type"
                              onChange={(event) => onInputChange(index, event)}
                            >
                              <option value={item.flow_type}>
                                {item.flow_type}{' '}
                              </option>
                              <option value="automatic">Automatic</option>
                              <option value="p45">P45</option>
                              <option value="p60">P60</option>
                              <option value="payslip">payslip</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </IncomeHeaderFields>
                    <IncomeHeaderButtons>
                      {item.flow_type !== 'automatic' ? (
                        <UpdateCancelSection>
                          <PayeButton
                            onClick={() =>
                              onUpdatePayeHeader(item.editMode, index, item.id)
                            }
                          >
                            {item.editMode ? 'Save' : 'Edit'}
                          </PayeButton>
                          <PayeButton onClick={() => onCancelUpdateItem(index)}>
                            Cancel
                          </PayeButton>
                        </UpdateCancelSection>
                      ) : null}

                      <div>
                        <PayeButton
                          onClick={() =>
                            onShowDocuments(index, item.showDocuments)
                          }
                        >
                          Show Documents
                        </PayeButton>
                      </div>
                    </IncomeHeaderButtons>
                  </IncomeInputsContainer>
                </IncomeHeader>

                <div>
                  {item.showDocuments && (
                    <div>
                      <ViewImageSection>
                        <ViewDocs docs={item.document} />
                      </ViewImageSection>
                    </div>
                  )}
                </div>

                <ContentContainer openContent={item.openContent}>
                  {item.openContent && item.id === incomeIdSelected && (
                    <>
                      <TransactionsSection>
                        <h3>Transactions</h3>
                        <TransactionsList
                          incomeType="employment"
                          incomeId={item.id}
                          setOpenAddTransactionForm={setOpenAddTransactionForm}
                          openAddTransactionForm={openAddTransactionForm}
                          allHeaderList={payeHeaderList}
                          incomeIdSelected={incomeIdSelected}
                          userId={userId}
                          year={year}
                          isSuccess={isSuccess}
                          refetchList={(refetch: () => void) =>
                            (refetchList = refetch)
                          }
                        />
                      </TransactionsSection>

                      <ViewImageSection>
                        <ViewImage
                          transactions={
                            incomes.incomeTransactions &&
                            incomes.incomeTransactions[
                              incomes.indexTransactionSelected
                            ]
                          }
                        />
                      </ViewImageSection>
                    </>
                  )}

                  <AddTransactionSection
                    style={{
                      display: openAddTransactionForm ? 'block' : 'none',
                    }}
                  >
                    <Title>Add transaction</Title>
                    <Row>
                      <Col>
                        <TransactionInputContainer>
                          <label htmlFor="client_name">Client name</label>
                          <input
                            type="text"
                            value={item.addTransactionFields.client_name}
                            name="client_name"
                            id="client_name"
                            onChange={(event) =>
                              onInputAddTransactionChange(index, event)
                            }
                          />
                        </TransactionInputContainer>

                        {errors.client_name && renderError(errors.client_name)}

                        <TransactionInputContainer>
                          <label htmlFor="date">Date</label>
                          <input
                            type="date"
                            value={item.addTransactionFields.date}
                            name="date"
                            id="date"
                            onChange={(event) =>
                              onInputAddTransactionChange(index, event)
                            }
                          />
                        </TransactionInputContainer>

                        {errors.date && renderError(errors.date)}

                        <TransactionInputContainer>
                          <label htmlFor="amount">Amount</label>
                          <input
                            type="number"
                            value={item.addTransactionFields.amount}
                            name="amount"
                            id="amount"
                            onChange={(event) =>
                              onInputAddTransactionChange(index, event)
                            }
                          />
                        </TransactionInputContainer>

                        {errors.amount && renderError(errors.amount)}

                        <SplitSection>
                          <Row>
                            <FooterTitle>Split</FooterTitle>
                          </Row>
                          <Row className="justify-content-center">
                            <FooterTitle>
                              {item.addTransactionFields.work_percentage || 0} %
                            </FooterTitle>
                          </Row>

                          <SplitContainer>
                            <SliptRange
                              type="range"
                              id="work_percentage"
                              name="work_percentage"
                              className="split-range-label"
                              min={0}
                              max={100}
                              value={
                                item.addTransactionFields.work_percentage || 0
                              }
                              onChange={(event) =>
                                onInputAddTransactionChange(index, event)
                              }
                            />
                          </SplitContainer>

                          <Row className="justify-content-between">
                            <FooterTitle>
                              Personal £
                              {item.addTransactionFields.amount
                                ? personalResult.toFixed(2)
                                : 0}{' '}
                            </FooterTitle>
                            <FooterTitle>
                              £
                              {item.addTransactionFields.amount
                                ? workResult.toFixed(2)
                                : 100}{' '}
                              Work
                            </FooterTitle>
                          </Row>
                        </SplitSection>

                        <Row>
                          <AddCancelSection>
                            <Button
                              onClick={() => onAddTransaction(index, item.id)}
                              style={{ position: 'relative' }}
                            >
                              <span>Add</span>
                            </Button>
                            <CancelTransactionButton
                              onClick={() => onCancelAddTransaction(index)}
                            >
                              <span>Cancel</span>
                            </CancelTransactionButton>
                          </AddCancelSection>
                        </Row>
                      </Col>

                      <Col>
                        <div>
                          <CategoryIdLabel
                            htmlFor="category_id"
                            className="title"
                          >
                            Category
                          </CategoryIdLabel>{' '}
                          <br />
                          <Select
                            id="category_id"
                            name="category_id"
                            onChange={(event) =>
                              onInputAddTransactionChange(index, event)
                            }
                          >
                            <option>Select an option</option>
                            {!isLoadingCategories &&
                              dataCategories.data.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.category}{' '}
                                </option>
                              ))}
                          </Select>
                        </div>

                        <AddProofContainer>
                          <input
                            type="file"
                            onChange={(event) => onUploadProof(event)}
                            hidden
                            id="documents_list"
                            name="documents_list"
                            multiple
                          />

                          <AddLogoButton htmlFor="documents_list">
                            <TbFileSpreadsheet size={24} />
                            <span>Add proof</span>
                          </AddLogoButton>

                          {errors.files && renderError(errors.files)}

                          <UploadContainer>
                            <Row>
                              {newDocsList.length > 0 &&
                                newDocsList.map((proof, i) => {
                                  return (
                                    <ProofItem key={i}>
                                      <ProofDetails>
                                        <div className="image-size">
                                          <span>{proof.name}</span>
                                          <span>{proof.size}KB </span>
                                        </div>

                                        <div>
                                          <span
                                            className="remove"
                                            onClick={() => onRemoveProof(i)}
                                          >
                                            Remove
                                          </span>
                                        </div>
                                      </ProofDetails>
                                    </ProofItem>
                                  );
                                })}
                            </Row>
                          </UploadContainer>
                        </AddProofContainer>
                      </Col>
                    </Row>
                  </AddTransactionSection>

                  {/* change item.others to new endpoint */}
                  <OtherInformations>
                    <Row>
                      <OtherInformationsContainer
                        style={{
                          display: 'flex',
                          width: '50%',
                          marginTop: 100,
                        }}
                      >
                        <Col>
                          <EditInputPie
                            label="Benefits:"
                            id="benefits"
                            name="benefits"
                            type="text"
                            value={item.others?.benefits}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Goods / Assets:"
                            id="goodsAssets"
                            name="goodsAssets"
                            type="text"
                            value={item.others?.goodsAssets}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Credit Mileage:"
                            id="creditMileage"
                            name="creditMileage"
                            type="text"
                            value={item.others?.creditMileage}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Accomodation:"
                            id="accomodation"
                            name="accomodation"
                            type="text"
                            value={item.others?.accomodation}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Money For Vehicle:"
                            id="moneyForVehicle"
                            name="moneyForVehicle"
                            type="text"
                            value={item.others?.moneyForVehicle}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Money For Fuel:"
                            id="moneyForFuel"
                            name="moneyForFuel"
                            type="text"
                            value={item.others?.moneyForFuel}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Medical Dental:"
                            id="medicalDental"
                            name="medicalDental"
                            type="text"
                            value={item.others?.medicalDental}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />
                        </Col>

                        <Col>
                          <EditInputPie
                            label="Pension:"
                            id="pension"
                            name="pension"
                            type="text"
                            value={item.others?.pension}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />

                          <EditInputPie
                            label="Student Loan:"
                            id="studentLoan"
                            name="studentLoan"
                            type="text"
                            value={item.others?.studentLoan}
                            editMode={false}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />
                        </Col>
                      </OtherInformationsContainer>

                      {/* <div style={{ display: 'flex', width: '50%' }}>
                      <Col>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 20,
                          }}
                        >
                          <OtherInformationsInputSection>
                            <label htmlFor="taxRefund" style={{ fontSize: 18 }}>
                              Tax refund
                            </label>
                            <Input
                              id="taxRefund"
                              type="text"
                              value={'Francis'}
                            />
                          </OtherInformationsInputSection>

                          <OtherInformationsInputSection>
                            <label
                              htmlFor="taxRefundDate"
                              style={{ visibility: 'hidden' }}
                            >
                              Tax refund
                            </label>
                            <Input
                              id="taxRefundDate"
                              type="text"
                              value={'05/04/2022'}
                              disabled
                            />
                          </OtherInformationsInputSection>
                        </div>

                        <SubmitButton>Submit</SubmitButton>
                      </Col>
                    </div> */}
                    </Row>
                  </OtherInformations>
                </ContentContainer>

                <OpenContentContainer>
                  <OpenContentButton
                    onClick={() =>
                      onOpenContent(index, item.openContent, item.id)
                    }
                  >
                    {item.openContent ? (
                      <IoIosArrowUp size={20} />
                    ) : (
                      <IoIosArrowDown size={20} />
                    )}
                  </OpenContentButton>
                </OpenContentContainer>
              </IncomeContainer>
            </>
          );
        })}
    </div>
  );
}

export default observer(TabPAYE);
