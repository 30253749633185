import { styled } from 'src/modules';

interface Props {
  selected?: boolean;
  isRead?: boolean;
  numberUnreadMessages: number;
}

interface ContentProps {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  border-radius: 12px;
  margin: 6px 0 0;
  cursor: pointer;

  background-color: ${({ theme, selected, isRead, numberUnreadMessages }) =>
    selected
      ? theme.colors.gray.ghostTwo
      : theme.colors.gray.light && !isRead && numberUnreadMessages > 0
      ? theme.colors.yellow.golden
      : theme.colors.gray.light};
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomerContent = styled.div<ContentProps>`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  width: 100%;
  /* max-width: 330px; */
  border: none;
  padding: 15px;
  overflow: hidden;
`;

export const NumberChatUnread = styled.div<Props>`
  pointer-events: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -10px;
  border-radius: 14px;
  color: ${({ theme }) => theme.colors.white.default};
  font-weight: bold;
  min-width: 27px;
  min-height: 27px;
  padding: 2px 10px;
`;

export const AvatarDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.s}px;
  justify-content: center;
`;

export const AvatarName = styled.div<Props>`
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white.default
      : props.theme.colors.black.nightRider || props.isRead
      ? props.theme.colors.black.nightRider
      : props.theme.colors.white.default};
  font-weight: bold;
`;

export const AvatarChat = styled.div<Props>`
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white.default
      : props.theme.colors.black.nightRider || props.isRead
      ? props.theme.colors.black.nightRider
      : props.theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSizes.subitle.s}px;
  font-weight: 300;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-height: 50px;

  > br {
    display: none;
  }
`;

export const RightSide = styled.div<ContentProps>`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ChatTime = styled.div<Props>`
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white.default
      : props.theme.colors.black.nightRider || props.isRead
      ? props.theme.colors.black.nightRider
      : props.theme.colors.white.default};
  font-size: 12px;
  margin-top: 3px;
`;

export const AvatarAndSubscriptionSection = styled.div`
  display: flex;
`;
