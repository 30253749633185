import styled from 'styled-components';

type Props = {
  isTabAll?: boolean;
};

export const SplitSection = styled.div`
  margin-top: 50px;
`;

export const FooterTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .split-range-label {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    right: 5px;
  }
`;

export const SliptRange = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 366px;
  height: 2px;
  background: #d8d8d8;
  border-radius: 5px;

  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border: 2px solid #191424;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
    background-color: #fff;
    background-image: url('/icons/code-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const MileageSection = styled.div`
  display: flex;
`;

export const MileageItem = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: ${(props) => (!props.isTabAll ? '20px' : 'initial')};

  @media (min-width: 2050px) {
    margin-left: 20px;
  }

  > label {
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    min-width: 300px;
  }
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  max-width: 500px;

  > label {
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    width: 100%;
    max-width: 150px;
  }
`;

export const NotesSplitSection = styled.div`
  display: flex;
  column-gap: 120px;
`;

export const SubmitSection = styled.div`
  margin-top: 30px;
`;
