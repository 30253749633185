import styled from 'styled-components';

interface Props {
  backgroundColor: string;
}

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 116px;
`;

export const Headercontainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionTotal = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const SectionLabelButtons = styled.div`
  margin-top: 10px;
`;

export const ToggleButton = styled.button<Props>`
  background-color: ${(props) => props.backgroundColor};
  height: 26px;
  min-width: 60px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: 12px;
  outline: none;
  :focus {
    outline: none;
  }
`;
