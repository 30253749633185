import { QueryFunctionContext } from 'react-query';

import request from '../request';

export const fetchAssistants = async (): Promise<AssistantData[]> => {
  const { data } = await request.get('admin');
  return data.data;
};

export const fetchAssistantsChanges = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, nextPage] = queryKey;

  const { data } = await request.get(
    `/admin/assistants-changes?page=${nextPage}&per_page=100`,
  );
  return data;
};
