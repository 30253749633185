import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import { useQuery, useMutation } from 'react-query';
import { NumberParam, useQueryParam, StringParam } from 'use-query-params';

import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  changeAvailabilityStatus,
  changeLoginStatus,
  listAdmins,
} from 'src/api/admin';
import { Layout, TableHead } from 'src/components';
import { AdminRow } from 'src/components/admins';
import { SearchBar } from 'src/components/common';
import { Loading } from 'src/components/ui';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { TTaxAssistantRole } from 'src/models';
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Toastify,
} from 'src/modules';
import { Storage } from 'src/services';
import { ERRORS, Routes } from 'src/utils/enums';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';
import { sortList } from 'src/utils/helpers/sortList';

const AdminUser: React.FC = () => {
  const [, setSearchParam] = useQueryParam('search', StringParam);
  const [, setRoleParam] = useQueryParam('role_id', NumberParam);
  const [roleQuery, setRoleQuery] = useState<number>(
    TTaxAssistantRole.TAX_ASSISTANT,
  );

  const [superAdmin, setSuperAdmin] = useState(false);
  const [adminsList, setAdminsList] = useState<AdminData[]>([]);
  const [descOrder, setDescOrder] = useState(false);

  const { query } = useFilters();

  const { data: admins = [], isLoading } = useQuery(
    ['listAdmins', query],
    listAdmins,
    {
      onError() {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const { mutate: changeAvailabilityMutation } = useMutation(
    changeAvailabilityStatus,
  );

  const { mutate: changeLoginMutation } = useMutation(changeLoginStatus);

  const roleParamHandle = () => {
    if (roleQuery <= 1) setRoleParam(undefined);
    else setRoleParam(roleQuery);
  };

  const handleSearch = (query: string) => {
    setSearchParam(query);
  };

  const handleSelect = (id: number) => {
    setSearchParam(admins.find((admin) => admin.id === id)?.email);
  };

  useEffect(() => {
    const getUser = async () => {
      const user = await Storage.getUser();
      const isSuperAdmin = handleSuperAdminRole(user?.role_id);

      setSuperAdmin(isSuperAdmin);
    };

    getUser();
  }, []);

  const onSortData = (descOrder: boolean, list: any, fieldName: string) => {
    const ordered = sortList(descOrder, list, fieldName);

    setAdminsList(ordered);
    setDescOrder(descOrder);
  };

  return (
    <Layout>
      <Dashboard title="Admins">
        <Row className="justify-content-end mb-4">
          <Col xs="5">
            {TTaxAssistantRole.SUPER_ADMIN && (
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(Routes.ADD_ADMIN)}
                >
                  Add New Admins/Tax Assistants
                </Button>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(Routes.TEAMS)}
                  style={{ position: 'relative', left: 25 }}
                >
                  Teams
                </Button>
              </div>
            )}
          </Col>
          <Col xs="3">
            <InputGroup>
              <CustomInput
                type="select"
                id="roleSelect"
                name="customSelect"
                value={roleQuery}
                onChange={(e) => setRoleQuery(parseInt(e.target.value))}
              >
                <option value={TTaxAssistantRole.TAX_ASSISTANT}>
                  Assistant
                </option>
                <option value={TTaxAssistantRole.ADMIN}>Admin</option>
                <option value={TTaxAssistantRole.SUPER_ADMIN}>
                  Super Admin
                </option>
                <option value={TTaxAssistantRole.MARKETING}>Marketing</option>
                <option value={TTaxAssistantRole.DEVS}>Devs</option>
                <option value={TTaxAssistantRole.MANAGER}>Manager</option>
              </CustomInput>

              <InputGroupAddon addonType="append" color="primary">
                <Button color="primary" type="button" onClick={roleParamHandle}>
                  Go
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col xs="4">
            <SearchBar
              data={admins}
              placeholder="Search for admin..."
              onSelect={handleSelect}
              onChange={handleSearch}
            />
          </Col>
        </Row>

        <Card>
          <Container className="mw-100">
            <TableHead>
              {/* <Col xs="1" className="pl-1"></Col> */}
              <Col
                xs="3"
                className="pl-3"
                style={{ cursor: 'pointer' }}
                onClick={() => onSortData(!descOrder, admins, 'name')}
              >
                Name
                <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
              </Col>
              <Col xs="3" className="pl-0">
                Email
              </Col>
              <Col
                xs="1"
                className="pl-1"
                style={{ cursor: 'pointer' }}
                onClick={() => onSortData(!descOrder, admins, 'role_id')}
              >
                Role
                <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
              </Col>
              <Col xs="1" className="pl-1 text-center">
                Login{' '}
              </Col>

              <Col
                xs="2"
                className="pl-1 text-center"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  onSortData(!descOrder, admins, 'available_status')
                }
              >
                Available
                <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
              </Col>
              <Col xs="1" className="pl-1 text-center">
                Action
              </Col>
            </TableHead>

            {isLoading && <Loading />}

            {adminsList.length
              ? adminsList?.map((item, index) => (
                  <AdminRow
                    admin={item}
                    key={`admin-${item.name}_${index}`}
                    onStatusChange={() => changeLoginMutation({ id: item.id })}
                    onAvailableChange={() =>
                      changeAvailabilityMutation({ id: item.id })
                    }
                    superAdmin={superAdmin}
                  />
                ))
              : admins?.map((item, index) => (
                  <AdminRow
                    admin={item}
                    key={`admin-${item.name}_${index}`}
                    onStatusChange={() => changeLoginMutation({ id: item.id })}
                    onAvailableChange={() =>
                      changeAvailabilityMutation({ id: item.id })
                    }
                    superAdmin={superAdmin}
                  />
                ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default AdminUser;
