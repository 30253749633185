import React, { memo, useEffect, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';

import ButtonIcon from '../ButtonIcon';

type Props = {
  id: number;
  year: number;
  complete: boolean;
  iconDisabled: boolean;
};

type TaxCodeOptions = 'blank' | false | true;

const IconTaxCode: React.FC<Props> = ({ id, year, complete, iconDisabled }) => {
  const [local, setLocal] = useState<TaxCodeOptions>(complete);

  useEffect(() => {
    setLocal(complete);
  }, [complete]);

  const onToggle = async () => {
    // toggle to faster visual effect
    const next = !local;
    setLocal(next);

    try {
      const { status } = await request.post(API.USERS_UPDATE_TOGGLE_TAX_CODE, {
        user_id: id,
        tax_year: year,
      });

      if (status !== 200) {
        setLocal(!next);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
    }
  };

  //  blank = “Blank”
  //  false = outstanding
  //  true = complete
  const getTooltip = () => {
    if (local === 'blank') return 'Blank';
    if (local === true) return 'Complete';
    return 'Oustanding';
  };

  const getColor = () => {
    if (local === false) return 'secondary';
    if (local === 'blank') return 'secondary';
    return 'primary';
  };

  const getOutline = () => {
    if (local === 'blank') return true;
    return false;
  };

  return (
    <ButtonIcon
      tooltip={getTooltip()}
      id={`icon-tax-code-${id}`}
      color={getColor()}
      outline={getOutline()}
      onClick={onToggle}
      disabled={iconDisabled}
    >
      <small>Tax Code</small>
    </ButtonIcon>
  );
};

export default memo(IconTaxCode);
