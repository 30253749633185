import { useEffect } from 'react';
import {window} from "browser-monads";

export default function useKeyPress(key:string, onPress:Function) {

	const onKeyup = (event:KeyboardEvent) => {
		if (event.key === key)
			onPress();
	}

	useEffect(() => {
		window.addEventListener('keyup', onKeyup);

		return () => {
			window.removeEventListener('keyup', onKeyup)
		};
	}, []);
}