import { styled } from 'src/modules';

type Props = {
  rangeValue?: number;
};

export const Wrapper = styled.div<Props>`
  width: 135px;
  height: 20px;
  background-color: #ddd;
  border-radius: 20px;
`;

export const Progress = styled.div<Props>`
  width: ${(props) => props.rangeValue}%;
  max-width: 150px;
  height: 20px;
  background-color: #209782;
  border-radius: 20px;
`;
