import styled from 'styled-components';

interface Props {
  filterModalIsOpen?: boolean;
  sideMenuOpen?: boolean;
}

export const Container = styled.div<Props>`
  visibility: ${(props) => (props.filterModalIsOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.filterModalIsOpen ? 1 : 0)};
  transition: visibility 0.3s;
  background: ${({ theme }) => theme.colors.black.default};
`;

export const ShadowContainer = styled.div<Props>`
  display: ${(props) => (props.filterModalIsOpen ? 'block' : 'none')};
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.black.default};
  opacity: 0.7;
  z-index: 11;
`;

export const ModalContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 195px;
  left: ${(props) => (props.sideMenuOpen ? '400px' : '170px')};
  height: 220px;
  width: 260px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white.default};
  padding: 16px 14px;
  z-index: 12;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.title.s}px;
`;

export const HeaderButton = styled.div`
  position: relative;
  /* left: 5px; */
  font-size: ${({ theme }) => theme.fontSizes.title.s}px;
  border: none;
  background: transparent;
  margin-bottom: 12px;
`;

export const FilterButtons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;

  .btn-primary {
    width: 100%;
    max-width: 70px;
    height: 38px;
    border-radius: 10px;
    font-weight: 600;
  }
`;

export const SectionSocialMedia = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white.gost};
  width: 150px;
  height: 40px;
  border-radius: 15px;
  position: relative;
  top: 20px;
`;

export const SocialMediaButton = styled.button`
  background: transparent;
  border: none;
  height: 40px;
  width: 40px;
`;

export const ButtonText = styled.span`
  font-weight: 600;
`;
