import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';

import { updateTransaction } from 'src/api/incomes';
import { getExpensesCategories } from 'src/api/users';
import { TextArea } from 'src/components/common';
import { SplitSection } from 'src/components/CustomerInformationTabs/IncomesTab/Components/TabProperty/styles';
import {
  FooterTitle,
  SliptRange,
  SplitContainer,
} from 'src/components/CustomerInformationTabs/IncomesTab/Components/TabSelfEmployed/styles';
import {
  PrevNextInputSection,
  PrevNextSection,
  Button,
  Input,
  Select,
} from 'src/components/CustomerInformationTabs/IncomesTab/styles';
import { useYear } from 'src/hooks';
import {
  NumberParam,
  observer,
  Row,
  Toastify,
  useQueryParam,
} from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import { CategoryIdLabel } from '../../../TabPAYE/styles';
import {
  MileageItem,
  MileageSection,
} from '../../../Transactions/TransactionForm/styles';

import {
  CategorySection,
  FooterSection,
  SubmitSection,
  YearSection,
  PrevNextFields,
  CategoryMileageSection,
} from './styles';

type Props = {
  transactions: Transaction[];
  sourceList: any;
};

export type AllEditTransactionFormHandle = {
  setSelectedTransaction(id: number): void;
};

let incomeType = '';

const AllEditTransactionForm = forwardRef<AllEditTransactionFormHandle, Props>(
  ({ item, sourceList }, ref) => {
    const [year] = useYear();
    const [userId] = useQueryParam('id', NumberParam);

    const expenses = store.expenses;

    const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
    const [incomeSourceSelected, setIncomeSourceSelected] = useState(
      sourceList?.[0]?.id,
    );
    const [splitValue, setSplitValue] = useState(
      expenses.allTransactions[currentTransactionIndex]?.work_percentage,
    );
    const [note, setNote] = useState('');
    const [submited, setSubmited] = useState(false);
    const [categorySelected, setCategorySelected] = useState(
      item[currentTransactionIndex]?.category_id?.id,
    );

    const sourceId =
      incomeSourceSelected || item[currentTransactionIndex]?.income_id;

    if (sourceList) {
      incomeType = sourceList.find((src) => src.id === sourceId)?.[
        'self_employment_type'
      ]
        ? 'self_employed'
        : 'employment';
    }

    useEffect(() => {
      setSplitValue(
        expenses.allTransactions[currentTransactionIndex]?.work_percentage,
      );
    }, [currentTransactionIndex]);

    useEffect(() => {
      if (splitValue < 1) {
        setSplitValue(100);
      }

      if (note === '') {
        setNote(item[currentTransactionIndex]?.note);
      }
    }, [item[currentTransactionIndex]?.work_percentage]);

    const { data: dataCategories } = useQuery(
      ['getExpensesCategories', incomeType],
      () => getExpensesCategories(incomeType),
      {
        enabled: !!incomeType,
        onError: ({ response }) => {
          Toastify.toast.error(response.data.message);
        },
      },
    );

    const { mutate: mutationUpdateTransaction } = useMutation(
      'updateTransaction',
      updateTransaction,
      {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
          expenses.refetchExpensesTransactions();
        },
        onError({ error }) {
          Toastify.toast.error(error);
        },
      },
    );

    const handlePrevClick = () => {
      if (currentTransactionIndex > 0) {
        setCurrentTransactionIndex(currentTransactionIndex - 1);
      }
    };

    const handleNextClick = () => {
      if (currentTransactionIndex < expenses.allTransactions.length - 1) {
        setCurrentTransactionIndex(currentTransactionIndex + 1);
      }
    };

    const onUpdateTransaction = () => {
      setSubmited(true);

      const formData = new FormData();

      formData.append('_method', 'PUT');
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('income_id', sourceId);
      formData.append('income_type', incomeType);
      formData.append('reconciliation_type', 'expense');
      formData.append('work_percentage', splitValue);
      formData.append('notes', note);
      formData.append(
        'category_id',
        categorySelected || item[currentTransactionIndex]?.category_id?.id,
      );

      mutationUpdateTransaction({
        transaction_id: item[currentTransactionIndex].id,
        formData,
      });
    };

    const handleSelectedItemChange = (value: number) => {
      setSplitValue(value);
    };

    useImperativeHandle(
      ref,
      () => ({
        setSelectedTransaction(id: number) {
          const transactionIndex = expenses.allTransactions.findIndex(
            (transaction) => transaction.id === id,
          );

          setCurrentTransactionIndex(transactionIndex);
        },
      }),
      [expenses.allTransactions],
    );

    return (
      <div>
        <PrevNextSection>
          <Button onClick={() => handlePrevClick()}>
            <BsArrowLeft size={18} />
            <span>Previous</span>
          </Button>

          <Row>
            <PrevNextFields>
              {!expenses.isLoadingAllTransaction && (
                <>
                  <PrevNextInputSection>
                    <label htmlFor="merchant_name">Merchant name</label>
                    <Input
                      id="merchant_name"
                      name="merchant_name"
                      type="text"
                      value={
                        item[currentTransactionIndex]?.description ||
                        item[currentTransactionIndex]?.merchant_name ||
                        ''
                      }
                      onChange={(event) => {}}
                      readOnly
                      disabled
                    />
                  </PrevNextInputSection>

                  <PrevNextInputSection>
                    <label htmlFor="created_at">Date</label>
                    <Input
                      id="created_at"
                      name="created_at"
                      type="text"
                      value={formatDate(
                        item[currentTransactionIndex]?.created_at,
                      )}
                      readOnly
                      disabled
                      onChange={(event) => {}}
                    />
                  </PrevNextInputSection>

                  <PrevNextInputSection>
                    <label htmlFor="gross">Amount</label>
                    <Input
                      id="gross"
                      name="amount"
                      type="text"
                      value={
                        item[currentTransactionIndex]?.amount +
                          item[currentTransactionIndex]?.CIS || 0
                      }
                      onChange={(event) => {}}
                      readOnly
                      disabled
                      style={{ maxWidth: 100 }}
                    />
                  </PrevNextInputSection>

                  <PrevNextInputSection>
                    <label htmlFor="net">NET</label>
                    <Input
                      id="net"
                      name="net"
                      type="text"
                      value={item[currentTransactionIndex]?.amount || 0}
                      onChange={(event) => {}}
                      readOnly
                      disabled
                      style={{ maxWidth: 100 }}
                    />
                  </PrevNextInputSection>

                  <PrevNextInputSection>
                    <label htmlFor="tax">TAX</label>
                    <Input
                      id="tax"
                      name="tax"
                      type="text"
                      value={item[currentTransactionIndex]?.CIS || 0}
                      onChange={(event) => {}}
                      readOnly
                      disabled
                    />
                  </PrevNextInputSection>

                  <PrevNextInputSection>
                    <label htmlFor="source">Source</label>
                    <Select
                      id="source"
                      onChange={(e) => {
                        setIncomeSourceSelected(Number(e.target.value));
                        setSubmited(false);
                      }}
                    >
                      {sourceList &&
                        sourceList.map((src) => {
                          return (
                            <option
                              key={src.id}
                              value={src.id}
                              selected={
                                src.id ===
                                item[currentTransactionIndex]?.income_id
                              }
                            >
                              {src.employer_name}
                            </option>
                          );
                        })}
                    </Select>
                  </PrevNextInputSection>

                  <YearSection>
                    <label htmlFor="year">Year</label>
                    <Select id="year" disabled>
                      <option value="2020"> {year} </option>
                    </Select>
                  </YearSection>

                  <CategoryMileageSection>
                    <CategorySection>
                      <CategoryIdLabel htmlFor="category_id" className="title">
                        Category
                      </CategoryIdLabel>{' '}
                      <br />
                      <Select
                        key={item[currentTransactionIndex]?.id}
                        id={`category_id_${item[currentTransactionIndex]?.id}`}
                        name="category_id"
                        onChange={(e) =>
                          setCategorySelected(Number(e.target.value))
                        }
                      >
                        {item[currentTransactionIndex]?.category_id ===
                          null && <option>Select an option</option>}

                        {item[currentTransactionIndex]?.category_id && (
                          <option>
                            {
                              item[currentTransactionIndex]?.category_id
                                .category
                            }
                          </option>
                        )}

                        {dataCategories?.data && (
                          <>
                            <option />
                            {dataCategories.data.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.category}
                              </option>
                            ))}
                          </>
                        )}
                      </Select>
                    </CategorySection>

                    {item[currentTransactionIndex]?.mileage && (
                      <MileageSection>
                        <MileageItem isTabAll>
                          <label htmlFor="address-from">Address From</label>
                          <Input
                            value={
                              item[currentTransactionIndex].mileage
                                .from_address +
                              ' - ' +
                              item[currentTransactionIndex].mileage
                                .from_postcode
                            }
                            readOnly
                            disabled
                          />
                        </MileageItem>

                        <MileageItem>
                          <label htmlFor="address-to">Address To</label>
                          <Input
                            value={
                              item[currentTransactionIndex].mileage.to_address +
                              ' - ' +
                              item[currentTransactionIndex].mileage.to_postcode
                            }
                            readOnly
                            disabled
                          />
                        </MileageItem>
                      </MileageSection>
                    )}
                  </CategoryMileageSection>
                </>
              )}
            </PrevNextFields>
          </Row>

          <Button onClick={() => handleNextClick()} disabled={item.length < 2}>
            <BsArrowRight size={18} />
            <span>Next</span>
          </Button>
        </PrevNextSection>

        <FooterSection>
          <PrevNextInputSection className="w-50">
            <label htmlFor="notes">Notes</label>
            <TextArea
              id="notes"
              value={note || ''}
              onChange={(e) => setNote(e.target.value)}
              rows={10}
              style={{ maxWidth: 450 }}
            />
          </PrevNextInputSection>

          <PrevNextInputSection className="w-50" style={{ maxWidth: 500 }}>
            <SplitSection>
              <Row>
                <FooterTitle>Split</FooterTitle>
              </Row>
              <Row className="justify-content-center">
                <FooterTitle>{splitValue || 0} %</FooterTitle>
              </Row>

              <SplitContainer>
                <SliptRange
                  type="range"
                  id="work-percentage"
                  className="split-range-label"
                  min={0}
                  max={100}
                  step={1}
                  value={splitValue}
                  onChange={(e) =>
                    handleSelectedItemChange(Number(e.target.value))
                  }
                />
              </SplitContainer>

              <Row className="justify-content-between">
                <FooterTitle>
                  Personal{' '}
                  {formatCurrency(
                    `${
                      (1 - parseInt(splitValue || 0) / 100) *
                      (item[currentTransactionIndex]?.amount +
                        item[currentTransactionIndex]?.CIS || 0)
                    }`,
                  )}
                </FooterTitle>
                <FooterTitle>
                  {formatCurrency(
                    `${
                      (parseInt(splitValue || 0) / 100) *
                      (item[currentTransactionIndex]?.amount +
                        item[currentTransactionIndex]?.CIS || 0)
                    }`,
                  )}{' '}
                  Work{' '}
                </FooterTitle>
              </Row>
            </SplitSection>
          </PrevNextInputSection>
        </FooterSection>

        <SubmitSection>
          <Button
            onClick={() => onUpdateTransaction()}
            style={{ fontWeight: 'bold' }}
          >
            Submit
          </Button>
        </SubmitSection>
      </div>
    );
  },
);

export default observer(AllEditTransactionForm);
