import styled from 'styled-components';

type Props = {
  openAddTransactionForm?: boolean;
};

export const AddCancelSection = styled.div`
  display: flex;
  margin-left: 15px;
  width: 100%;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #57e4cd;
  height: 38px;
  border: none;
  border-radius: 48px;
  padding: 0 24px;
  position: relative;

  > span {
    font-weight: 600;
  }
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const CancelButton = styled(AddButton)`
  background-color: #f31b1b;
  padding: 0 24px;
  margin-left: 20px;

  > span {
    font-weight: 600;
  }
`;

export const AddLogoButton = styled.label`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 30px;
  max-width: 200px;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const AddTransactionSection = styled.div<Props>`
  display: ${(props) => (props.openAddTransactionForm ? 'block' : 'none')};
`;
