import styled from 'styled-components';

import { Row } from 'src/components/common';

const StyledRow = styled(Row)`
  justify-content: space-around;
  width: 100%;
  padding: 20px 150px;
`;

const ImageContainer = styled.div`
  display: block;
  min-height: 300px;
  max-height: calc(100vh - 450px);
  overflow: auto;
`;

const Image = styled.img``;

export { StyledRow, ImageContainer, Image };
