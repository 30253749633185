import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  column-gap: 130px;
`;

export const NotesSection = styled.div`
  > div {
    height: 116px;
    width: 100%;
    max-width: 446px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 10px;
  }
`;

export const SplitSection = styled.div``;

export const FooterTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .split-range-label {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    right: 5px;
  }
`;

export const SliptRange = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 366px;
  height: 2px;
  background: #d8d8d8;
  border-radius: 5px;

  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border: 2px solid #191424;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
    background-color: #fff;
    background-image: url('/icons/code-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const PrevNextFields = styled.div`
  display: flex;
  column-gap: 20px;
  margin: 0px 20px 30px;
`;

export const OtherInformations = styled.div`
  padding: 0 15px;
`;

export const TaxRefundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    height: 56px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 14px;
    margin-right: 20px;
  }
`;

export const AddTransactionSection = styled.div``;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 600px;
  color: #191424;
  margin-bottom: 30px;
`;

export const TransactionInputContainer = styled.div`
  margin-bottom: 30px;

  > label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    height: 53px;
    width: 100%;
    max-width: 366px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 14px;
    margin-right: 20px;
  }
`;

export const TransactionSelect = styled.select`
  height: 53px;
  width: 100%;
  max-width: 366px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

export const UploadContainer = styled.div`
  display: flex;
  margin-top: 60px;

  > img {
    height: 86px;
    width: 86px;
    border-radius: 4px;
    margin-right: 12px;
  }

  .upload-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .img-details {
      display: flex;
      flex-direction: column;
    }

    .remove-upload {
      color: red;
    }
  }
`;
