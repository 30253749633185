export const getInitials = (name = ''): string => {
  if (!name) return '';
  const space = name?.lastIndexOf(' ');
  const last = name.substring(space + 1, 1);
  const first = name.substring(0, 1);

  return `${first}${last}`;
};

export const getValidData = (value: any) => {
  if (!value || value === 'null') {
    return '';
  }
  return value;
};

export const getFirstLetter = (text: string): string => {
  if (!text) return '';

  const letter = text.substring(0, 1);

  return `${letter}`;
};

export const capitalize = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);
