import React, { ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  noPadding?: boolean;
  className?: string;
}

const Content: React.FC<IProps> = ({ children, noPadding, className }) => {
  let _className = 'content';
  if (noPadding) _className += ' no-padding';
  if (className) _className += ' ' + className;

  return <div className={_className}>{children}</div>;
};

export default Content;
