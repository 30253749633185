import { action, observable } from 'mobx';

import { ReferralsApi } from 'src/api';

export default class ReferralsStore {
  @observable
  fetchData: ReferralsFetchTypes = {
    data: {
      id: 0,
      full_name: '',
      email: '',
      referral_code: '',
      referred_earning: '',
      total_clients: 0,
      referral_by: {
        id: 0,
        full_name: '',
        referral_code: '',
      },
      referrals: [],
      total_referral: '',
      paid_referral: '',
    },
    has_more: false,
    last_page: 0,
    message: '',
    next_page: 0,
    next_page_url: '',
    previous_page_url: '',
    total_earning: 0,
    total_entries: 0,
    total_pages: 0,
    total_referring: 0,
  };

  @action
  fetchReferrals = async (
    userId: number,
    page?: number,
  ): Promise<ReferralsFetchTypes> => {
    const response = await ReferralsApi.fetchReferrals(userId, page);
    this.fetchData = response;

    return response;
  };

  @action
  addTaxRefund = async (data: ReferralsAddPayout): Promise<void> => {
    await ReferralsApi.addTaxRefund(data);
  };

  @action
  addClientEarning = async (data: ReferralsAddEarning): Promise<void> => {
    await ReferralsApi.addClientEarning(data);
  };

  @action
  fetchClientsEarnings = async (
    userId: number,
  ): Promise<ReferralsClientsEarning[]> => {
    const response = await ReferralsApi.fetchClientsEarnings(userId);

    return response;
  };

  @action
  editRefundPayout = async (data: ReferralsAddPayout): Promise<void> => {
    const response = await ReferralsApi.editRefundPayout(data);

    return response;
  };

  @action
  deleteRefundPayout = async (userId: number): Promise<void> => {
    await ReferralsApi.deleteRefundPayout(userId);
  };
}
