import React from 'react';

import { navigate } from 'gatsby';
import { useMutation, useQueryClient } from 'react-query';

import { addAdmin } from 'src/api/admin';
import { Layout } from 'src/components';
import { Form } from 'src/components/admins';
import Dashboard from 'src/layouts/Dashboard';
import { TTaxAssistantRole } from 'src/models/ITaxAssistant';
import { Toastify } from 'src/modules';
import { ERRORS, Routes } from 'src/utils';

const Add = () => {
  const queryClient = useQueryClient();

  const { mutate: addAdminMutation, isLoading } = useMutation(addAdmin, {
    onSuccess() {
      queryClient.invalidateQueries(['listAdmins']);
      navigate(Routes.ADMINS);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const handleSubmit = (form: AddAdminData) => {
    addAdminMutation({
      role_id: form.role_id?.toString(),
      name: form.name,
      email: form.email,
      phone_number: form.phone_number,
      whatsapp_no: form.whatsapp_no,
      latitude: form.latitude,
      longitude: form.longitude,
      address: form.address,
      note: form.note,
      password: form.password,
      image: form.image,
    });
  };

  return (
    <Layout>
      <Dashboard title="Add Admin">
        <Form
          onSubmit={handleSubmit}
          initialData={{
            role_id: TTaxAssistantRole.TAX_ASSISTANT.toString(),
            name: '',
            email: '',
            phone_number: '',
            whatsapp_no: '',
            latitude: '',
            longitude: '',
            address: '',
            note: '',
            image: undefined,
            password: '',
          }}
          loading={isLoading}
        />
      </Dashboard>
    </Layout>
  );
};

export default Add;
