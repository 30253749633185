import request from '../request';

export const getLetters = async () => {
  const { data } = await request.get(`/letters`);

  return data;
};

export const getTemplates = async () => {
  const { data } = await request.get(`/letters/templates`);

  return data;
};

type SaveToPostLetter = {
  template_id: string;
  customer_id: number;
  year: number;
};

export const saveToPostLetter = async ({
  template_id,
  customer_id,
  year,
}: SaveToPostLetter) => {
  const { data } = await request.post(
    `/letters/save-to-post/?template_id=${template_id}&customer_id=${customer_id}&year=${year}`,
  );

  return data;
};
