export type TUtrStatus =
  | "NotRequested"
  | "NeedUTR"
  | "Requested"
  | "RequestCanceled"
  | "Provided";

export enum UTRSTatusLabels {
  "NotRequested" = "Not Requested",
  "NeedUTR" = "Outstanding",
  "Requested" = "UTR Ordered",
  "RequestCanceled" = "Request Canceled",
  "Provided" = "Complete",
}
