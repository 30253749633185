import request from '../request';
import ResponseError from '../ResponseError';

export const addClient = async (id: number, code: string): Promise<any> => {
  const { data } = await request.post(`/user-referrals/add-client`, {
    user_id: id,
    referral_code: code,
  });

  return data;
};

export const fetchReferrals = async (
  userId: number,
  page?: number,
): Promise<any> => {
  try {
    const { data } = await request.get(
      `/user-referrals?user_id=${userId}&page=${page}&per_page=100`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const addTaxRefund = async (data: ReferralsAddPayout) => {
  try {
    await request.post('user-referrals/add-refund-payout', data);
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const addClientEarning = async (data: ReferralsAddEarning) => {
  try {
    await request.post('user-referrals/user-client/add', data);
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const fetchClientsEarnings = async (
  id: number,
): Promise<ReferralsClientsEarning[]> => {
  try {
    const { data } = await request.get(
      `/user-referrals/user-client/show?user_id=${id}`,
    );
    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const listReferrals = async (query = ''): Promise<any> => {
  const { data } = await request.get(`/user-referrals${query}`);

  return data;
};

export const editRefundPayout = async (data: ReferralsAddPayout) => {
  const { data: response } = await request.post(
    `/user-referrals/edit-refund-payout`,
    data,
  );

  return response;
};

export const deleteRefundPayout = async (userId: number) => {
  await request.post(`/user-referrals/delete-refund-payout/`, {
    user_id: userId,
  });
};

export const deleteClient = async (data: any) => {
  await request.delete(`/user-referrals/remove-client/`, {
    data: {
      user_id: data.userId,
      client_id: data.clientId,
    },
  });
};

export const fetchReferralsByName = async (name: string): Promise<any> => {
  if (!name || name.length < 1) {
    return;
  }
  try {
    const { data } = await request.get(`/user-referrals?search=${name}`);

    return data.data || data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const fetchAllReferrals = async () => {
  try {
    const { data } = await request.get(`/user-referrals`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
