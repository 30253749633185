import React from "react";
import { Input, Table } from "src/modules";
import { Layout } from "src/components";
import Dashboard from "src/layouts/Dashboard";
// markup

const EditProfile: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="edit-profile">
        <Table hover>
          <tbody>
            <tr>
              <td className="w-25 pl-4">Name</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="name"
                  name="name"
                  placeholder="Enter your name"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Email</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Display Photo</td>
              <td className="w-75">
                <button type="button" className="btn btn-outline-secondary">
                  BROWSE PICTURE
                </button>
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Phone Number</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Address</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  type="textarea"
                  name="textarea"
                  placeholder="Enter your address"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Longitude</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="number"
                  name="longitude"
                  placeholder="Enter your longitude"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Latitude</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="number"
                  name="latitude"
                  placeholder="Enter your latitude"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Password</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4">Confirm Password</td>
              <td className="w-75">
                <Input
                  className="w-50"
                  bsSize="lg"
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm your password"
                />
              </td>
            </tr>
            <tr>
              <td className="w-25 pl-4"></td>
              <td className="w-75">
                <button type="button" className="btn btn-primary">
                  SUBMIT
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Dashboard>
    </Layout>
  );
};

export default EditProfile;
