import styled, { keyframes } from 'styled-components';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.25rem 0.7rem;
  color: #3a4d5f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.ghost};
  border-radius: 0.2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
`;

export const Submit = styled.button`
  min-width: 40px;
  min-height: 1.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.button};
  border: 1px solid transparent;
  border-radius: 0.2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &&:hover {
    background-color: ${({ theme }) => theme.colors.hoveredButton};
    border-color: ${({ theme }) => theme.colors.hoveredButtonBorder};
  }

  &&:active {
    background-color: ${({ theme }) => theme.colors.activeButton};
    border-color: ${({ theme }) => theme.colors.activeButtonBorder};
  }

  &&:focus {
    outline: 0;
  }
`;

export const ResultsContainer = styled.div`
  z-index: 100;
  position: absolute;
  left: 0;
  top: calc(1.8125rem + 2px);
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white.default};
  border: solid thin ${({ theme }) => theme.colors.gray.geyser};
  box-shadow: 4px 4px 16px -4px rgba(0, 0, 0, 0.36);
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon).attrs({
  icon: faSpinner,
})`
  animation-name: ${rotate};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
