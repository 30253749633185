import styled, { css } from 'styled-components';

interface DragProps {
  isDragging: boolean;
  isOpenMenu: boolean;
}

export const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  /* background: ${(props) => props.theme.colors.white.catskill}; */
`;

export const DragBar = styled.div<DragProps>`
  flex-shrink: 0;
  width: 5px;
  background-color: #d1d5db;
  cursor: col-resize;
  transition: all 0.15s 0.15s ease-in-out;

  ${({ isOpenMenu }) =>
    !isOpenMenu &&
    css`
      display: none;
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: #63b3ed;
    `};
`;
