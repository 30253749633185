import React, { useState } from 'react';

import { Link } from 'gatsby';
import { useMutation, useQuery } from 'react-query';

import {
  getFeedback,
  saveFeedbackComment,
  updateFeedback,
} from 'src/api/feedback.api';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { TextArea } from 'src/components/common';
import Icon from 'src/components/Icon';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Toastify,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'src/modules';
import { TypeFeedbackContainer } from 'src/styles/pages/feedback/feedback.styles';
import { Content, StyledCol } from 'src/styles/pages/notifications/styles';
import { ERRORS, formatDateFullDate, SUCCESS } from 'src/utils';

const PLACEHOLDER_DATA = {
  data: [],
  total_entries: 0,
  total_pages: 0,
  last_page: 0,
  next_page: 0,
  has_more: false,
  next_page_url: '',
  previous_page_url: '',
  message: '',
};

const Feedback: React.FC = () => {
  const [hasPagination, setHasPagination] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [typeSelected, setTypeSelected] = useState('');
  const [editingItemId, setEditingItemId] = useState(undefined);

  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);

  const {
    data: feedbacks = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['getFeedback', nextPage, typeSelected], getFeedback, {
    placeholderData: PLACEHOLDER_DATA,
    onSuccess: ({
      next_page,
      total_pages,
      next_page_url,
      previous_page_url,
    }) => {
      setHasPagination(total_pages > 1);
      setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
    },
    onError: ({ response }) => {
      Toastify.toast.error(response);
    },
  });

  const { mutate: mutationUpdateFeedback } = useMutation(updateFeedback, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);

      refetch();
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const { mutate: saveComment } = useMutation(
    async ({ id, comment }: { id: number; comment: string }) =>
      await saveFeedbackComment(id, comment),
    {
      onSuccess() {
        setEditingItemId(undefined);
        refetch();
      },
    },
  );

  const onCompleteStatus = (id: number) => {
    mutationUpdateFeedback(id);
  };

  return (
    <Layout>
      <Dashboard title="Users Feedback">
        <TypeFeedbackContainer>
          <Input
            type="select"
            onChange={(e) => setTypeSelected(e.target.value)}
          >
            <option value="">Filter by type</option>
            <option value=""></option>
            <option value="bug">Bug</option>
            <option value="feature">Feature</option>
            <option value="feedback">Feedback</option>
          </Input>
        </TypeFeedbackContainer>
        <Content>
          <TableHead className="flex-nowrap py-2">
            <StyledCol xs="1 text-center">User id</StyledCol>
            <StyledCol xs="1 text-center">Email</StyledCol>
            <StyledCol xs="1 text-center">Type</StyledCol>
            <StyledCol xs="1 text-center">Description</StyledCol>
            <StyledCol xs="1 text-center">Image</StyledCol>
            <StyledCol xs="1 text-center">Source</StyledCol>
            <StyledCol xs="1 text-center">Flow</StyledCol>
            <StyledCol xs="1 text-center">Feedback Category</StyledCol>
            <StyledCol xs="1 text-center">Status</StyledCol>
            <StyledCol xs="1 text-center">Comment</StyledCol>
            <StyledCol xs="1 text-center">Created</StyledCol>
            <StyledCol xs="1 text-center">Complete</StyledCol>
          </TableHead>

          {isFetching && <Loading />}

          {!isLoading &&
            feedbacks.data.map((item) => (
              <TableRow className="flex-nowrap" key={item.id}>
                <StyledCol xs="1 text-center"> {item.user_id}</StyledCol>
                <StyledCol xs="1 text-center">
                  <p>{item.email}</p>
                </StyledCol>
                <StyledCol xs="1 text-center"> {item.type}</StyledCol>
                <StyledCol xs="1 text-center">
                  {' '}
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />{' '}
                </StyledCol>
                <StyledCol xs="1 text-center">
                  {item.upload_image && (
                    <Link to={item.upload_image} target="_blank">
                      Open image
                    </Link>
                  )}
                </StyledCol>
                <StyledCol xs="1 text-center"> {item.source}</StyledCol>
                <StyledCol xs="1 text-center">
                  {' '}
                  {item.flow && item.flow.replaceAll('_', ' ')}
                </StyledCol>
                <StyledCol xs="1 text-center"> {item.category}</StyledCol>
                <StyledCol xs="1 text-center">{item.status}</StyledCol>
                <StyledCol xs="1">
                  {item.comment}
                  <Icon name="pencil" onClick={() => setEditingItemId(item)} />
                </StyledCol>
                <StyledCol xs="1 text-center">
                  {formatDateFullDate(item.created_at)}
                </StyledCol>
                <StyledCol xs="1 d-flex justify-content-center">
                  {item.status === 'pending' && (
                    <Button
                      color="primary"
                      onClick={() => onCompleteStatus(item.id)}
                    >
                      Complete
                    </Button>
                  )}
                </StyledCol>
              </TableRow>
            ))}
        </Content>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
      <Modal
        isOpen={editingItemId !== undefined}
        toggle={() => setEditingItemId(undefined)}
      >
        <ModalHeader toggle={() => setEditingItemId(undefined)}>
          Edit Comment
        </ModalHeader>
        <ModalBody>
          <TextArea
            rows={3}
            value={editingItemId?.comment}
            style={{ width: '100%' }}
            onChange={(e) =>
              setEditingItemId({ ...editingItemId, comment: e.target.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            color="primary"
            onClick={() =>
              saveComment({
                id: editingItemId?.id,
                comment: editingItemId?.comment,
              })
            }
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default Feedback;
