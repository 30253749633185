import axios from 'axios';

import {
  addAuthHeaders,
  verifyExpiredToken,
  successResponse,
} from './interceptors';

// API URLS
const messageCenterURL = process.env.GATSBY_MESSAGECENTER_URL;

// DEFAULT TIMEOUT
const TIMEOUT = 30 * 1000;

const requestMessageCenter = axios.create({
  baseURL: messageCenterURL,
  timeout: TIMEOUT,
});

requestMessageCenter.interceptors.request.use(addAuthHeaders);
requestMessageCenter.interceptors.response.use(
  successResponse,
  verifyExpiredToken,
);

export default requestMessageCenter;
