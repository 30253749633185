import React from 'react';

import { Provider } from 'mobx-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import store from './src/stores';
import { theme } from './src/global/styles/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default ({ element }) => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient} contextSharing>
      <Provider store={store}>{element}</Provider>
    </QueryClientProvider>
  </ThemeProvider>
);
