import React from 'react';

import { navigate } from 'gatsby';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col } from 'src/modules';
import { Routes } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

type Props = {
  id: number;
  title: string;
  message: string;
  admin_message: string;
  tag: string;
};

const NotificationTemplatesRow: React.FC<Props> = ({
  id,
  title,
  message,
  admin_message,
  tag,
}) => {
  const onEdit = (id: number) =>
    navigate(`${Routes.NOTIFCATION_TEMPLATES_EDIT}/?id=${id}`);
  return (
    <TableRow className="align-items-center">
      <Col xs="1">{id}</Col>
      <Col xs="1">{title}</Col>
      <Col xs="4">{message}</Col>
      <Col xs="3">{admin_message}</Col>
      <Col xs="1">{tag}</Col>
      <Col xs="1">
        <ButtonIcon
          color="primary"
          onClick={() => onEdit(id)}
          className="table-button"
        >
          <FontAwesomeIcon icon={faPen} />
        </ButtonIcon>
      </Col>
    </TableRow>
  );
};

export default NotificationTemplatesRow;
