import React, { ChangeEvent, useState } from 'react';

import { navigate } from 'gatsby';
import { useMutation, useQueryClient } from 'react-query';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteAdmin, editAdmin } from 'src/api/admin';
import { useFilters, useForm } from 'src/hooks';
import { TTaxAssistantRole } from 'src/models';
import { Col, Input, Row, CustomInput, Toastify } from 'src/modules';
import { ERRORS, Routes, SUCCESS } from 'src/utils';

import ButtonIcon from '../../ButtonIcon';

type Props = { admin: AdminData } & {
  onStatusChange?: any;
  onAvailableChange: any;
  superAdmin: boolean;
};

const AdminRow = ({
  admin: {
    id,
    role_id,
    name,
    email,
    phone_number,
    address,
    image: imgSrc,
    status,
    available_status,
  },
  onStatusChange,
  onAvailableChange,
  superAdmin,
}: Props) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const { form, handleChange } = useForm({
    id,
    name,
    email,
    phone_number,
    address,
    role_id,
    newPassword: '',
    image: undefined,
  });

  const { query } = useFilters();
  const queryClient = useQueryClient();

  const toggleBtn = async (): Promise<void> => {
    if (!disabled) {
      const { image, newPassword, ...rest } = form;
      const formattedData = {
        ...rest,
        password: newPassword?.length > 5 ? newPassword : undefined,
      };

      if (image) formattedData.image = image;
      editAdminMutation(formattedData);
    }
    setDisabled(!disabled);
  };

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) handleChange('image', file);
  };

  const { mutate: editAdminMutation } = useMutation(editAdmin, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
    onSettled() {
      queryClient.refetchQueries(['listAdmins', query]);
    },
  });

  const { mutate: deleteAdminMutation } = useMutation(deleteAdmin, {
    onSuccess(data) {
      queryClient.setQueryData<AdminData[]>(
        ['listAdmins', query],
        (cache) => cache?.filter((item) => item.id !== data.id) ?? [],
      );

      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const handleTypeUser = (roleId: number) => {
    switch (roleId) {
      case TTaxAssistantRole.SUPER_ADMIN:
        return 'Super Admin';
      case TTaxAssistantRole.ADMIN:
        return 'Admin';
      case TTaxAssistantRole.TAX_ASSISTANT:
        return 'Assistant';
      case TTaxAssistantRole.MARKETING:
        return 'Marketing';
      case TTaxAssistantRole.DEVS:
        return 'Dev';
      case TTaxAssistantRole.MANAGER:
        return 'Manager';
      default:
        break;
    }
  };

  return (
    <Row className="pb-3 pt-3 align-items-center">
      <Col xs="1">
        {disabled ? (
          <img className="admin-image ml-4" alt={name} src={imgSrc} />
        ) : (
          <Input type="file" onChange={imageHandle} />
        )}
      </Col>
      <Col xs="2">
        <Input
          type="text"
          className="p-0 m-0"
          disabled={disabled}
          value={form.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('name', e.target.value)
          }
        />
      </Col>
      <Col xs="3">
        <Row>
          <Col xs="3">Email</Col>
          <Col>
            <Input
              type="text"
              className="p-0 m-0"
              disabled={disabled}
              value={form.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange('email', e.target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs="3">Phone</Col>
          <Col>
            <Input
              type="text"
              className="p-0 m-0"
              disabled={disabled}
              value={form.phone_number}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange('phone_number', e.target.value)
              }
            />
          </Col>
        </Row>

        {!disabled && (
          <Row>
            <Col xs="3">Password</Col>
            <Col>
              <Input
                type="text"
                className="p-0 m-0"
                disabled={disabled}
                value={form.newPassword}
                onChange={(e) => handleChange('newPassword', e.target.value)}
              />
            </Col>
          </Row>
        )}

        {!disabled && superAdmin && (
          <Row>
            <Col xs="3">Role</Col>
            <Col>
              <Input
                type="select"
                id={`input_admin${id}`}
                name={`input_admin${id}`}
                value={form.role_id}
                className={''}
                onChange={(event) => {
                  handleChange('role_id', event.target.value);
                }}
              >
                <option value={TTaxAssistantRole.TAX_ASSISTANT}>
                  Assistant
                </option>
                <option value={TTaxAssistantRole.ADMIN}>Admin</option>
                <option value={TTaxAssistantRole.SUPER_ADMIN}>
                  Super Admin
                </option>
              </Input>
            </Col>
          </Row>
        )}
      </Col>

      {superAdmin && (
        <Col xs="1" className="d-flex align-items-center ">
          <span>{handleTypeUser(Number(role_id))}</span>
        </Col>
      )}

      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${email}`}
          name="customSwitch"
          defaultChecked={status === 'Enable'}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`available${email}`}
          name="customSwitch"
          defaultChecked={!!available_status}
          onChange={onAvailableChange}
        />
      </Col>
      <Col xs="1 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            color={'primary'}
            className="table-button"
            onClick={() => navigate(`${Routes.ADMINS}/${id}`)}
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            color={'primary'}
            className="table-button"
            onClick={() => deleteAdminMutation({ id })}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminRow;
