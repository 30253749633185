import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Container,
  Input,
  Row,
  CustomInput,
  Col,
  Button,
  Form,
} from "src/modules";

// markup
const AddQuestion: React.FC = ({ onClose, createQuestion, yearsOptions }) => {
  return (
    <Container className="mw-100 mb-3">
      <Form onSubmit={(e) => createQuestion(e)}>
        <Row className="align-items-center">
          <Col xs="1">Question</Col>
          <Col>
            <Input bsSize="lg" type="textarea" name="question" />
          </Col>
          <Col xs="1">Year</Col>
          <Col xs="3">
            <CustomInput bsSize="lg" type="select" id="year" name="year">
              <option value="">Select...</option>
              {yearsOptions.map((item, index: number) => {
                return (
                  <option key={`year-${index}`} value={item}>
                    {item}
                  </option>
                );
              })}
            </CustomInput>
          </Col>
          <Col xs="2">
            <div className="d-flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-4"
              >
                SUBMIT
              </Button>
              <Button
                type="reset"
                color="transparent"
                onClick={onClose}
                className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddQuestion;
