import React from 'react';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

import { TableColumn, WrapperActions } from './styles';

type Props = {
  data: any;
  onEdit(id: number): void;
  onDelete(id: number): void;
};

const InboxTemplateRow: React.FC<Props> = ({ data, onEdit, onDelete }) => {
  return (
    <TableRow className="align-items-center">
      <TableColumn xs="1">{data.id}</TableColumn>
      <TableColumn xs="3">{data.title}</TableColumn>
      <TableColumn xs="6">
        {data.message.split('\n').map((text: string, index: number) => (
          <p key={`message-line-${index}`}>
            {text}
            <br />
          </p>
        ))}
      </TableColumn>
      <TableColumn xs="2">
        <WrapperActions>
          <ButtonIcon
            color="primary"
            onClick={() => onEdit(data.id)}
            className="table-button"
          >
            <FontAwesomeIcon icon={faPen} />
          </ButtonIcon>
          <ButtonIcon
            color="primary"
            onClick={() => onDelete(data.id)}
            className="table-button"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </WrapperActions>
      </TableColumn>
    </TableRow>
  );
};

export default InboxTemplateRow;
