import React, { useEffect, useState, useCallback, ReactNode } from 'react';

import { document, window } from 'browser-monads';
import { Link, navigate } from 'gatsby';
import { Bell, Settings, User } from 'react-feather';
import { useQuery, useQueryClient } from 'react-query';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';

import {
  getNotificationsCounter,
  getNotifications,
} from 'src/api/notifications';
import ResponseError from 'src/api/ResponseError';
import { searchUsersAutoComplete } from 'src/api/users';
import { SearchBar } from 'src/components/common';
import { TTaxAssistantRole, INotificationItem } from 'src/models';
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Button,
  ButtonGroup,
  Spinner,
  Toastify,
  observer,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { ERRORS, Routes, openOnNewTab } from 'src/utils';

type DropdownProps = {
  children: ReactNode;
  count: number;
  showBadge: boolean;
  isLoading: boolean;
  onClick?(): void;
};

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  isLoading,
  onClick,
}: DropdownProps) => (
  <UncontrolledDropdown nav inNavbar className="mr-2" onClick={onClick}>
    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
      <Bell className="align-middle" size={18} />
      <div className="nav-badge">
        {showBadge && count > 0 ? (
          <span className="indicator">{count}</span>
        ) : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} new notifications
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center mt-3 mb-3">
          <Spinner />
        </div>
      ) : (
        <ListGroup>{children}</ListGroup>
      )}
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">
          <Link to={Routes.NOTIFICATIONS}>Show all notifications</Link>
        </span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

type DropdownItemProps = {
  title: string;
  description: string;
  time: string;
  spacing?: boolean;
};

const NavbarDropdownItem: React.FC<DropdownItemProps> = ({
  title,
  description,
  time,
  spacing = false,
}) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>
        <Bell size={18} className="text-warning" />
      </Col>
      <Col xs={10} className={spacing ? 'pl-2' : ''}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

interface Props {
  onToggle: any;
  title: string;
  counter?: number;
}

type TThemeMode = 'light' | 'dark' | undefined;

const getNotificationDescr = (item: INotificationItem) => {
  let descr = '';
  if (item.email) descr += item.email;

  return descr;
};

const NavbarComponent = ({ onToggle, title }: Props) => {
  // queryClient
  const queryClient = useQueryClient();

  // stores
  const auth = store.auth;
  const themeStore = store.theme;
  const pieLayoutStore = store.pieLayout;

  // states
  const [savedTheme, setSavedTheme] = useState('');
  const [theme, setTheme] = useState<TThemeMode>();
  const [auto, setAuto] = useState(savedTheme === '' ? true : false);
  const [query, setQuery] = useState('');
  const [user, setUser] = useState<User>();
  const [currentTheme, setCurrentTheme] = useState(savedTheme);
  const [fetchNotificationsCount, setFetchNotificationsCount] = useState(0);

  // add a button to set it
  Storage.setItem('layout', 'PIE');

  const {
    data: users = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    'searchUsersAutoComplete',
    () => searchUsersAutoComplete(query),
    {
      enabled: false,
    },
  );

  const {
    data: notifications = [],
    refetch: fetchNotifications,
    isLoading: isLoadingNotifications,
  } = useQuery('notifications', () => getNotifications('7'), {
    enabled: false,
  });

  const { data: counter = { total: '' }, refetch: refetchCounter } = useQuery(
    'notificationsCounter',
    getNotificationsCounter,
  );

  useEffect(() => {
    if (counter.total > 0 && fetchNotificationsCount % 2 === 1) {
      fetchNotifications();
    }
  }, [fetchNotificationsCount, counter]);

  useEffect(() => {
    themeStore.setCurrentTheme(savedTheme);

    Storage.getItem('layout').then((result) => {
      if (result === 'PIE') pieLayoutStore.setIsPIE(true);
      else pieLayoutStore.setIsPIE(false);
    });
  }, []);

  const loadUser = async () => {
    const userData = await Storage.getUser();
    setUser(userData);

    Sentry.setUser({ email: userData?.email, id: String(userData?.id) });
  };

  const addThemeListener = useCallback(async () => {
    try {
      // listen for changes on the system theme
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event: any) => {
          const newColorScheme: TThemeMode = event.matches ? 'dark' : 'light';
          document.body.setAttribute('data-theme', newColorScheme);
          Storage.setTheme(newColorScheme);
          setTheme(newColorScheme);
        });

      const saved = Storage.getTheme();

      setSavedTheme(saved);
      changeThemeTo(saved);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  }, []);

  const changeThemeTo = (mode: string) => {
    Storage.setTheme(mode);
    setCurrentTheme(mode);
    themeStore.setCurrentTheme(mode);

    if (!mode) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        document.body.setAttribute('data-theme', 'light');
      }

      setAuto(true);
      return;
    }

    setAuto(false);
    document.body.setAttribute('data-theme', mode);
  };

  const onLogout = () => {
    auth.logout();

    navigate(Routes.LOGIN);
  };

  // Load counter and create the Web Worker
  // Keep this in the useEffect unless you are sure the connection is closed on page unload
  // Return MUST close connection
  useEffect(() => {
    addThemeListener();

    // Check Web Workers are supported
    if (typeof window.SharedWorker === 'undefined') {
      throw 'Your browser does not support SharedWorkers';
    }

    // Create the Worker
    const worker = new SharedWorker('/worker.js');

    // Listen to the message event
    // This is where we will receive the data from the worker
    // and update the UI
    worker.port.onmessage = function (evt) {
      refetchCounter();
      Toastify.toast.info(evt.data.message, {
        onClick: () => navigate(Routes.NOTIFICATIONS),
      });
    };

    // If we get an error, log it out and close the worker.
    worker.onerror = function () {
      Toastify.toast.error(ERRORS.PUSHER);
      worker.port.close();
    };

    // Start the worker.
    worker.port.start();

    // Close connection on page unload
    return () => worker.port.close();
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (query) {
      queryClient.cancelQueries('searchUsersAutoComplete');
      queryClient.clear();
      refetch();
    }
  }, [query]);

  const handleSelect = (id: number) => {
    const user = users.find((item) => item.id === id);

    // TODO:
    // Fiscal yeard hardcoded as 2022 as requested by Tommy on 13/04/2022
    // Remove hardcoded 2022 when fiscal year peak is finished
    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${user?.id}&tax_year=2022&tab=details`,
    );

    // openOnNewTab(
    //   `${Routes.CUSTOMER_INFORMATION}/?id=${user?.id}&tax_year=${
    //     user?.tax_year ?? year
    //   }&tab=details`,
    // );
  };

  const handleSubmit = (query: string) => {
    navigate(`${Routes.CUSTOMERS}/?search=${query}`);
  };

  const userIsNotMarketing =
    user && user.role_id !== TTaxAssistantRole.MARKETING;

  const handleDropDownOpen = () => {
    setFetchNotificationsCount((prevState) => prevState + 1);
  };

  return (
    <Navbar expand>
      {userIsNotMarketing && (
        <span className="sidebar-toggle d-flex mr-2" onClick={onToggle}>
          <i className="hamburger align-self-center" />
        </span>
      )}

      <div className="pl-2 page-title">{title}</div>

      <div className="d-flex col justify-content-end mr-4">
        <div className="w-100 position-relative" style={{ maxWidth: 400 }}>
          {userIsNotMarketing && (
            <SearchBar
              data={users?.map((item) => ({ ...item, name: item.full_name }))}
              onSubmit={handleSubmit}
              onChange={setQuery}
              onSelect={handleSelect}
              isLoading={isLoading || isFetching}
              placeholder="Name, email or UTR"
              icon={faChevronRight}
              setQueryNavBar={setQuery}
            />
          )}
        </div>
      </div>

      <div>
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            {userIsNotMarketing && (
              <NavbarDropdown
                count={Number(counter.total)}
                showBadge={true}
                isLoading={isLoadingNotifications}
                onClick={handleDropDownOpen}
              >
                {notifications.map((item: INotificationItem) => (
                  <NavbarDropdownItem
                    key={`notification-${item.id}`}
                    title={item?.full_name}
                    description={getNotificationDescr(item)}
                    time={item.sent_datetime}
                  />
                ))}
              </NavbarDropdown>
            )}

            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  {user?.image ? (
                    <img
                      src={user?.image.replace('big', 'thumb')}
                      className="avatar img-fluid rounded-circle mr-2"
                      alt={user?.name}
                    />
                  ) : (
                    <User style={{ marginRight: 4 }} />
                  )}
                  <span>{user?.name}</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                {userIsNotMarketing && (
                  <DropdownItem
                    onClick={() => navigate(Routes.ADMIN_USER_EDIT)}
                  >
                    <User size={18} className="align-middle mr-2" />
                    Profile
                  </DropdownItem>
                )}

                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>Sign out</DropdownItem>
                <DropdownItem divider />
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <Button
                      onClick={() => changeThemeTo('light')}
                      className={
                        'icon-theme-light' +
                        (!auto && currentTheme === 'light'
                          ? ' active-theme'
                          : '')
                      }
                    >
                      <i className="icon-theme_light" />
                    </Button>

                    <Button
                      onClick={() => changeThemeTo('dark')}
                      className={
                        'icon-theme-dark' +
                        (!auto && currentTheme === 'dark'
                          ? ' active-theme'
                          : '')
                      }
                    >
                      <i className="icon-theme_dark" />
                    </Button>

                    <Button
                      className={
                        'icon-theme-auto' + (auto ? ' active-theme' : '')
                      }
                      onClick={() => changeThemeTo('')}
                    >
                      <div className="icon-theme-auto-grad">A</div>
                    </Button>
                  </ButtonGroup>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default observer(NavbarComponent);
