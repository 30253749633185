import React, { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createStatusTag } from 'src/api/status-tags';
import {
  Container,
  Input,
  Row,
  Col,
  Button,
  Form,
  Toastify,
} from 'src/modules';
import { ERRORS, SUCCESS } from 'src/utils';

type Props = {
  onClose: () => void;
};

const NewStatusTagForm = ({ onClose }: Props) => {
  const [title, setTitle] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate: create, isLoading } = useMutation(createStatusTag, {
    onSuccess() {
      queryClient.invalidateQueries(['listStatusTags']);
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (title) create({ title });

      setTitle(null);
    },
    [title],
  );

  return (
    <Container className="mw-100 mb-3">
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-center">
          <Col xs={7}>
            <Input
              bsSize="lg"
              type="text"
              name="name"
              placeholder="Enter the new tag's title"
              onChange={(e) => setTitle(e.target.value)}
              value={title ?? ''}
            />
          </Col>
          <Col xs={2} className="ml-auto">
            <div className="d-flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-4"
                disabled={!title}
              >
                {isLoading ? 'LOADING...' : 'SUBMIT'}
              </Button>
              <Button
                type="reset"
                color="transparent"
                onClick={() => onClose()}
                className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NewStatusTagForm;
