import React, { useState } from 'react';

import { TbFileSpreadsheet } from 'react-icons/tb';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Toastify } from 'src/modules';
import { SUCCESS, formatDate } from 'src/utils';

import { AddLogoButton, DateRangeInput, Select } from '../../styles';

import { uploadProofId } from './../../../../../api/users/index';

type FormType = {
  user_id: number;
  document: string | File | null;
  user_live_photo: string | File | null;
  id_proof: 'driving_licence' | 'passport';
  id_expires: string;
};

const INITIAL_STATE: FormType = {
  user_id: 3,
  document: null,
  user_live_photo: null,
  id_proof: 'driving_licence',
  id_expires: '20/06/2024',
};

const UploadProof = () => {
  const [userId] = useQueryParam('id', NumberParam);

  const [form, setForm] = useState<FormType>(INITIAL_STATE);

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) handleChange(e.target.name, file);
  };

  const handleChange = (key: string, value: string | File) => {
    setForm((form) => ({ ...form, [key]: value }));
  };

  const send_data = async () => {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('document', form.document);
    formData.append('user_live_photo', form.user_live_photo);
    formData.append('id_proof', form.id_proof);
    formData.append(
      'id_expires',
      formatDate(form.id_expires, 'yyyy-MM-dd', 'DD/MM/yyyy'),
    );

    try {
      const data = await uploadProofId(formData);

      Toastify.toast.success(SUCCESS.GENERIC);

      return data;
    } catch ({ message }) {
      Toastify.toast.error(message);
    }
  };

  return (
    <div>
      <br />
      {/* Selfie Upload Input */}
      {form.user_live_photo ? (
        <img
          src={URL.createObjectURL(form.user_live_photo)}
          className="img-thumbnail"
          width={200}
          alt="Proof Address"
        />
      ) : null}
      <input
        type="file"
        onChange={imageHandle}
        id="user_live_photo"
        name="user_live_photo"
        hidden
      />
      <AddLogoButton htmlFor="user_live_photo">
        <TbFileSpreadsheet size={24} />
        <span>Add Proof - Selfie</span>
      </AddLogoButton>

      {/* Document Upload Input */}
      {form.document ? (
        <img
          src={URL.createObjectURL(form.document)}
          className="img-thumbnail"
          width={200}
          alt="Proof Address"
        />
      ) : null}
      <input
        type="file"
        onChange={imageHandle}
        id="document"
        name="document"
        hidden
      />
      <AddLogoButton htmlFor="document">
        <TbFileSpreadsheet size={24} />
        <span>Add Proof - Document</span>
      </AddLogoButton>

      <Select onChange={(e) => handleChange('id_proof', e.target.value)}>
        <option value="driving_licence">Driving Licence</option>
        <option value="passport">Passport</option>
      </Select>

      <DateRangeInput
        id="finalDate"
        name="finalDate"
        type="date"
        value={form?.id_expires}
        onChange={(event) => handleChange('id_expires', event.target.value)}
      />

      <br />
      <button
        className="btn btn-primary"
        onClick={send_data}
        disabled={!form.document || !form.user_live_photo}
      >
        Upload
      </button>
    </div>
  );
};

export default UploadProof;
