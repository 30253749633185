import { action, observable } from 'mobx';

import { NotifcationTemplatesApi } from 'src/api';

export default class NotificationTemplatesStore {
  @observable
  templates: NotificationTemplatesTypes[] = [];

  @observable
  current: NotificationTemplateSingle = {
    data: {
      id: 0,
      title: '',
      message: '',
      admin_message: '',
      dynamic_message_keywords: '',
      dynamic_admin_message_keywords: '',
      tag: '',
      display_order: 0,
      status: '',
      created_date: '',
      modified_date: '',
      created_ip: 0,
      deleted_at: '',
    },
    message: '',
  };

  @action
  fetchTemplates = async (): Promise<void> => {
    const { data } = await NotifcationTemplatesApi.fetchTemplates();

    this.templates = data;
  };

  @action
  getById = async (id: number): Promise<void> => {
    const data = await NotifcationTemplatesApi.getById(id);
    this.current = data;
  };

  @action
  updateTemplate = async (data: NotificationTemplateUpdate): Promise<void> => {
    await NotifcationTemplatesApi.updateTemplate(data);

    this.fetchTemplates();
  };
}
