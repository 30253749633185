import React from "react";
import { Link } from "gatsby";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Col, Input, Row } from "src/modules";
import TableRow from "../TableRow";
import ButtonIcon from "../ButtonIcon";

type Props = {
  username: string;
  email: string;
  taxYear: string;
  payeSelf: string;
};

const ExternalExpenseFormsRow: React.FC<Props> = ({
  username,
  email,
  taxYear,
  payeSelf,
}) => {
  return (
    <TableRow className="align-items-center">
      <Col xs="3">
        <Input type="text" name="username" value={username} disabled />
      </Col>
      <Col xs="2">
        <Input type="number" name="utr" value="123" disabled />
      </Col>
      <Col xs="2">
        <Input type="text" name="email" value={email} disabled />
      </Col>
      <Col xs="2">
        <Input type="text" name="taxYear" value={taxYear} disabled />
      </Col>
      <Col xs="2">
        <Input type="text" name="payeSelf" value={payeSelf} disabled />
      </Col>
      <Col xs="1">
        <Row className="d-flex justify-content-center">
          <Link to="/external-expense-forms-information">
            <ButtonIcon>
              <FontAwesomeIcon icon={faEye} />
            </ButtonIcon>
          </Link>
        </Row>
      </Col>
    </TableRow>
  );
};

export default ExternalExpenseFormsRow;
