import React, { useEffect, useState } from 'react';

import { Link, navigate } from 'gatsby';

import { ButtonLoader } from 'src/components';
import { Logo } from 'src/components/ui';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Toastify,
  observer,
} from 'src/modules';
import store from 'src/stores';
import { Routes, ERRORS } from 'src/utils';

type Props = {
  path: string;
};

const LoginPage: React.FC<Props> = () => {
  const auth = store.auth;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onLogin = async () => {
    setIsLoading(true);
    try {
      await auth.login({
        email,
        password,
      });
    } catch {
      Toastify.toast.error(ERRORS.LOGIN);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const wrapper = document.getElementById('gatsby-focus-wrapper');
    wrapper &&
      wrapper.classList.add(
        'd-flex',
        'justify-content-center',
        'align-items-center',
      );

    return () => {
      wrapper &&
        wrapper.classList.remove(
          'd-flex',
          'justify-content-center',
          'align-items-center',
        );
    };
  }, []);

  useEffect(() => {
    const { id, is_2fa_configured } = auth.userData;
    // if user is logged in and 2fa is not configured, redirect to 2fa setup
    if (id && !is_2fa_configured) {
      navigate(Routes.SETUP_2FA);
    } else if (id) {
      navigate(Routes.OTP);
    }
  }, [auth.userData]);

  useEffect(() => {
    auth.clearStore();
  }, []);

  return (
    <div className="w-50" style={{ maxWidth: '600px' }}>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{ width: 90, height: 90 }}
              >
                <Logo size={90} />
              </div>
            </div>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <small>
                  <Link to="/reset-password">Forgot password?</Link>
                </small>
              </FormGroup>
              <div>
                <CustomInput
                  type="checkbox"
                  id="rememberMe"
                  label="Remember me next time"
                  defaultChecked
                />
              </div>
              <div className="text-center mt-3">
                <Button
                  color="primary"
                  size="lg"
                  onClick={onLogin}
                  disabled={isLoading}
                >
                  {!isLoading ? 'Sign in' : <ButtonLoader />}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
      <Toastify.ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default observer(LoginPage);
