import React from 'react';

import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { TaxHistory as TaxHistoryApi } from 'src/api';
import { useFilters } from 'src/hooks';
import { ITaxHistoryBenefitsData } from 'src/models';
import { formatCurrency, formatToScreenDate } from 'src/utils';

import {
  Container,
  Header,
  Card,
  CardTitle,
  Content,
  List,
  ListHeader,
  HeaderItem,
  ListBody,
  BodyItem,
  BodyContent,
  Line,
  BenefitsContainer,
} from './styles';

const BENEFITS_MAPPING: Partial<Record<keyof ITaxHistoryBenefitsData, string>> =
  {
    car: 'Car',
    fuel: 'Fuel',
    private_insurance: 'Private insurance',
    mileage: 'Mileage',
    goods_assets: 'Goods assets',
    accommodation: 'Accommodation',
    other: 'Other',
    other_pensions_and_retirement_annuities:
      'Other pensions and retirement annuities',
    incapacity_benefit: 'Incapacity benefit',
    job_allowance: 'Job allowance',
    seiss_net_paid: 'Seiss net paid',
    total_benefits_amount: 'Total benefits',
  };

type Props = {
  userId: number;
};

function TaxHistory({ userId }: Props) {
  const {} = useFilters();

  const { data: taxReturnsList = [] } = useQuery(
    ['getTaxHistory', userId],
    async () => {
      const data = await TaxHistoryApi.getTaxHistory(userId);

      return data;
    },
  );

  const { data: taxHistoryBenefits = [] } = useQuery(
    ['getTaxHistoryBenefits', userId],
    async () => {
      const data = await TaxHistoryApi.getTaxHistoryBenefits(userId);

      return data;
    },
  );

  return (
    <Container>
      <Header />
      <Content>
        <Card>
          <CardTitle>Tax returns</CardTitle>

          <List>
            <ListHeader>
              <Col xs="2">
                <HeaderItem>Tax year</HeaderItem>
              </Col>
              <Col xs="1">
                <HeaderItem>Amount</HeaderItem>
              </Col>
              <Col xs="1">
                <HeaderItem>Submitted</HeaderItem>
              </Col>
              <Col xs="1">
                <HeaderItem>Received</HeaderItem>
              </Col>
            </ListHeader>
            <ListBody>
              {taxReturnsList.map((item, index) => {
                return (
                  <BodyContent key={index}>
                    <Col xs="2">{item.tax_year}</Col>
                    <Col xs="1">£{item.refund_amount}</Col>
                    <Col xs="1">{formatToScreenDate(item.submitted)}</Col>
                    <Col xs="1">{item.received}</Col>
                  </BodyContent>
                );
              })}
            </ListBody>
          </List>
        </Card>

        <Card>
          <CardTitle>Benefits</CardTitle>

          <BenefitsContainer>
            {taxHistoryBenefits.map((benefit) => (
              <div key={`benefit-${benefit.tax_year}`}>
                {Object.keys(benefit).map((key, index) => {
                  const isFirstItem = index === 1;
                  const mappedKey =
                    BENEFITS_MAPPING[key as keyof ITaxHistoryBenefitsData];

                  return mappedKey ? (
                    <Row key={key}>
                      {
                        <Col>
                          {isFirstItem && (
                            <HeaderItem>{benefit.tax_year}</HeaderItem>
                          )}
                        </Col>
                      }
                      <Col>
                        <HeaderItem>{mappedKey}</HeaderItem>
                      </Col>
                      <Col>
                        <BodyItem className="d-flex justify-content-end">
                          {formatCurrency(
                            benefit[key as keyof ITaxHistoryBenefitsData],
                          )}
                        </BodyItem>
                      </Col>
                    </Row>
                  ) : null;
                })}
                <Line />
              </div>
            ))}
          </BenefitsContainer>
        </Card>
      </Content>
    </Container>
  );
}

export default TaxHistory;
