import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';

import { listAdmins } from 'src/api/admin';
import {
  Layout,
  TableHead,
  CustomPagination,
  NotificationRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import {
  observer,
  NumberParam,
  useQueryParam,
  StringParam,
  Toastify,
} from 'src/modules';
import store from 'src/stores';
import {
  TopWrapper,
  StyledSelect,
  WrapperContent,
  Styledbutton,
  Content,
  StyledCol,
  StyledAlert,
} from 'src/styles/pages/notifications/styles';
import { ERRORS } from 'src/utils';
import { filterDevsOut } from 'src/utils/helpers/handleSuperAdminRole';

const pageDefaultValues: PaginationResponse = {
  next_page: 0,
  total_pages: 0,
  next_page_url: '',
  previous_page_url: '',
};

const Notification: React.FC = () => {
  // stores
  const notifications = store.notifications;

  const { data: assistants = [] } = useQuery(['listAdmins', ''], listAdmins, {
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  // params
  const [tag, setTag] = useQueryParam('tag', NumberParam);
  const [employeeType, setEmployeeType] = useQueryParam(
    'employee_type',
    StringParam,
  );
  const [taxAssistantId, setTaxAssistantId] = useQueryParam(
    'tax_assistant_id',
    NumberParam,
  );
  const [, setPage] = useQueryParam('page', NumberParam);

  // states
  const [filter, setFilter] = useState<number>(tag || 0);
  const [assistantFilter, setAssistantFilter] = useState(taxAssistantId || 0);
  const [employmentFilter, setEmploymentFilter] = useState(employeeType || '');
  const [pagRes, setPagRes] = useState<PaginationResponse>(pageDefaultValues);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // filters
  const { query } = useFilters();

  const enumDropdownOptions = [
    { value: 1, label: 'Inbox' },
    { value: 6, label: 'E-Signature' },
    { value: 7, label: 'Income' },
    { value: 8, label: 'Expense' },
    { value: 9, label: 'HMRC' },
  ];

  const employmentOptions = [
    { value: 'paye', label: 'Paye' },
    { value: 'self', label: 'Self' },
  ];

  const onPageLoad = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await notifications.fetchNotifications();

      setPagRes({
        next_page: response.next_page,
        total_pages: response.total_pages,
        next_page_url: '',
        previous_page_url: '',
      });
    } catch {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataFetch = async () => {
    try {
      setLoading(true);
      setPage(undefined);
      const response = await notifications.fetchNotifications(query);

      setPagRes({
        next_page: response.next_page,
        total_pages: response.total_pages,
        next_page_url: '',
        previous_page_url: '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onResetFilter = async () => {
    setPage(undefined);
    setTaxAssistantId(undefined);
    setEmployeeType(undefined);
    setTag(undefined);
    setFilter(0);
    setAssistantFilter(0);
    setEmploymentFilter('Employment');

    try {
      setLoading(true);
      const response = await notifications.fetchNotifications();

      setPagRes({
        next_page: response.next_page,
        total_pages: response.total_pages,
        next_page_url: '',
        previous_page_url: '',
      });
    } finally {
      setLoading(false);
    }
  };

  const changePage = async (pageNumber: number) => {
    setPage(pageNumber);

    try {
      setLoading(true);
      const response = await notifications.fetchNotifications(
        `?${query}&page=${pageNumber}`,
      );

      setPagRes({
        next_page: response.next_page,
        total_pages: response.total_pages,
        next_page_url: '',
        previous_page_url: '',
      });
    } finally {
      setLoading(false);
    }
  };

  // change select states
  const onChangeSelect = (value: number) => {
    value > 0 ? setTag(value) : setTag(undefined);
    setFilter(value);
    setPage(undefined);
  };

  const onChangeAssistantId = (value: number) => {
    value > 0 ? setTaxAssistantId(value) : setTaxAssistantId(undefined);
    setAssistantFilter(value);
    setPage(undefined);
  };

  const onChangeEmployment = (value: string) => {
    value !== 'employment'
      ? setEmployeeType(value)
      : setEmployeeType(undefined);
    setEmploymentFilter(value);
    setPage(undefined);
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  return (
    <Layout>
      <Dashboard title="Notifications">
        <TopWrapper>
          {/* ASSISTANTS FILTER */}
          <StyledSelect
            type="select"
            id="employmentSelect"
            name="customSelect"
            value={assistantFilter}
            onChange={(e: any) => onChangeAssistantId(e.target.value)}
          >
            <option value={0}>Tax Assistant</option>
            {filterDevsOut(assistants).map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </StyledSelect>

          {/* EMPLOYMENT FILTER */}
          <StyledSelect
            type="select"
            id="employmentSelect"
            name="customSelect"
            value={employmentFilter}
            onChange={(e: any) => onChangeEmployment(e.target.value)}
          >
            <option value={`employment`}>Employment</option>
            {employmentOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </StyledSelect>

          {/* TAG FILTER */}
          <StyledSelect
            type="select"
            id="expenseSelect"
            name="customSelect"
            value={filter}
            onChange={(e: any) => onChangeSelect(e.target.value)}
          >
            <option value={0}>ALL</option>
            {enumDropdownOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </StyledSelect>

          <Styledbutton color="primary" type="button" onClick={onResetFilter}>
            RESET
          </Styledbutton>
          <Styledbutton color="primary" type="button" onClick={getDataFetch}>
            GO
          </Styledbutton>
        </TopWrapper>

        {error && (
          <StyledAlert color="danger">Error: {error?.message}</StyledAlert>
        )}

        <WrapperContent>
          <Content>
            <TableHead>
              <StyledCol xs="8">Name</StyledCol>
              <StyledCol xs="2 text-center">Unread</StyledCol>
              <StyledCol xs="2 text-center">Action</StyledCol>
            </TableHead>

            {loading && <Loading />}

            {notifications.notifications.data.map((item) => (
              <NotificationRow key={`notification-${item.id}`} item={item} />
            ))}
          </Content>
        </WrapperContent>

        {notifications.notifications.has_more && (
          <CustomPagination res={pagRes} changePage={changePage} />
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(Notification);
