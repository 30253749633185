import { window } from 'browser-monads';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';

function useFilters() {
  const [params, setParams] = useQueryParams({});

  const [status] = useQueryParam('status', StringParam);
  const [is_called] = useQueryParam('is_called', NumberParam);
  const [tax_assistant_id] = useQueryParam('tax_assistant_id', NumberParam);
  const [tax_status] = useQueryParam('tax_status', StringParam);
  const [taxcode] = useQueryParam('taxcode', NumberParam);
  const [tax_code_complete] = useQueryParam('tax_code_complete', StringParam);
  const [employee_type] = useQueryParam('employee_type', StringParam);
  const [status_label] = useQueryParam('status_label', StringParam);
  const [is_paid_status] = useQueryParam('is_paid_status', StringParam);
  const [requested_utr] = useQueryParam('requested_utr', StringParam);
  const [income_expense_status, setIncomeExpenseStatus] = useQueryParam(
    'income_expense_status',
    StringParam,
  ); // TIncomeExpenseStatus
  const [how_you_here] = useQueryParam('how_you_here', StringParam);

  const [sort] = useQueryParam('sort', StringParam);
  const [year] = useQueryParam('year', StringParam);
  const [page] = useQueryParam('page', StringParam);
  const [per_page] = useQueryParam('per_page', StringParam);

  const query =
    window.location && window.location.search ? window.location.search : '';

  const clearQuery = () => {
    setParams({}, 'replace');
  };

  return {
    clearQuery,
    query,
    status,
    is_called,
    tax_assistant_id,
    tax_status,
    taxcode,
    income_expense_status,
    setIncomeExpenseStatus,
    tax_code_complete,
    employee_type,
    status_label,
    is_paid_status,
    requested_utr,
    how_you_here,
    sort,
    year,
    page,
    per_page,
  };
}

export default useFilters;
