import React, { useState, useEffect, useCallback } from 'react';

import { AiOutlineBell } from 'react-icons/ai';
import { useQuery } from 'react-query';

import ModalStatusPie from 'src/components/common/SalesStatus';
import { useYear } from 'src/hooks';
import { TIsPaidStatus, ICustomer, type IStatusInfo } from 'src/models';
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  observer,
  useQueryParam,
  ModalBody,
  Modal,
  ModalHeader,
} from 'src/modules';
import store from 'src/stores';
import defaultTheme from 'src/theme/default-theme';
import { getYearsList, getQueryParam, TaxStatus } from 'src/utils';
import { handleLabels } from 'src/utils/helpers/handleLabels';

import { IconDeleteOrDisableCustomer, IconTaxAssistant } from '../customers';
import NewCustomerProgressPie from '../NewCustomerProgressPie';
import { Avatar } from '../ui';

import {
  CustomerProgressPieContainer,
  DeleteAndCloseYearContainer,
  EmployeeTypeLabel,
  Sanctioned,
  SanctionedContainer,
  SectionName,
  StatusYearsContainer,
  YearsLabel,
  YearsToolTip,
  SubscriptionStatus,
  JobCategorySection,
  JobCategorySpan,
  SubAndServiceTypeSection,
  ServiceType,
  StatusInfoContainer,
  StatusInfoPie,
  ActionsStatusButton,
} from './styles';

const SUBSCRIPTION_COLOR_BG = {
  ['trial']: '#930303',
  premium: '#209782',
  essential: '#FAD02C',
  expired: '#930303',
};

const SUBSCRIPTION_TEXT = {
  ['trial']: 'Trial',
  premium: 'Premium',
  essential: 'Essential',
  expired: 'Expired Trial',
};

type Props = {
  item: ICustomer;
  taxYear: number;
  onSelectTab(tab: string): void;
  isLoadingInformation: boolean;
  refetchCustomerInformation(): void;
  progress: string[];
};

const CustomerInformationHeader: React.FC<Props> = ({
  item,
  isLoadingInformation,
  refetchCustomerInformation,
  progress,
}) => {
  const customer = store.customer;
  const { isPIE } = store.pieLayout;

  const [data, setData] = useState<IStatusInfo[] | undefined>(undefined);
  const [paidStatus, setPaidStatus] = useState<TIsPaidStatus>(
    item.is_paid_status,
  );
  const [yearSelected, setYearSelected] = useState(0);
  const [openYearModal, setOpenYearModal] = useState(false);

  useEffect(() => {
    setData(item.status_info);
    setPaidStatus(item.is_paid_status);
  }, [item]);

  const [userId] = useQueryParam<number>('id');
  const [year, setYear] = useYear();
  const [employmentTypeList, setEmploymentTypeList] = useState<string[]>([]);
  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setModalOpen((prevState) => !prevState);
  }, []);
  const { data: userData, refetch: refetchUserData } = useQuery(
    ['user', userId],
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (!modalOpen) {
      refetchUserData().then(() => {
        refetchCustomerInformation();
      });
    }
  }, [modalOpen, refetchUserData, refetchCustomerInformation, year]);

  const handleYearColor = (taxStatus: string) => {
    switch (taxStatus) {
      case TaxStatus.Paid:
        return defaultTheme.colors.green.main;
      case TaxStatus.IncomeReceived:
        return defaultTheme.colors.orange.main;
      case TaxStatus.ExpenseReceived:
        return defaultTheme.colors.orange.main;
      case TaxStatus.IncomeCompleted:
        return defaultTheme.colors.yellow.main;
      case TaxStatus.ExpenseCompleted:
        return defaultTheme.colors.yellow.main;
      case TaxStatus.Pending:
        return defaultTheme.colors.gray.regent;
      case TaxStatus.Signed:
        return defaultTheme.colors.gray.regent;
      case TaxStatus.InProgress:
        return defaultTheme.colors.green.light;
      default:
        break;
    }
  };

  const checkEmploymentType = (employmentType: any) => {
    if (employmentType) {
      if (employmentType.employment.length > 0) {
        const validEmployment = employmentType.employment.find(
          (item: any) => item.job_sub_category,
        );

        if (validEmployment) {
          return validEmployment.job_sub_category?.name;
        }
      }

      if (employmentType.self_employment.length > 0) {
        const validSelfEmployment = employmentType.self_employment.find(
          (item: any) => item.job_sub_category,
        );

        if (validSelfEmployment) {
          return validSelfEmployment.job_sub_category?.name;
        }
      }
    }

    return '';
  };

  const hasEmploymentType = checkEmploymentType(item.employment_type);

  const openEmploymentTypeModal = (employmentType: any) => {
    const newEmploymentTypeList: string[] = [];

    if (employmentType.employment.length > 0) {
      employmentType.employment.map((item: any) => {
        if (item.job_sub_category)
          newEmploymentTypeList.push(item.job_sub_category?.name);
      });
    }

    if (employmentType.self_employment.length > 0) {
      employmentType.self_employment.map((item: any) => {
        if (item.job_sub_category)
          newEmploymentTypeList.push(item.job_sub_category?.name);
      });
    }

    setEmploymentTypeList(newEmploymentTypeList);
  };

  useEffect(() => {
    if (employmentTypeList.length > 0) {
      modalToggle();
    }
  }, [employmentTypeList]);

  return (
    <div>
      <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Col lg={2}>
          <SectionName>
            <Row>
              <Avatar name={item.full_name} image={item.avatar} />
              <SubAndServiceTypeSection>
                <SubscriptionStatus
                  id={`subscription`}
                  title={SUBSCRIPTION_TEXT[item.subscription_plan] ?? ''}
                  background={
                    SUBSCRIPTION_COLOR_BG[item.subscription_plan] ?? ''
                  }
                >
                  {SUBSCRIPTION_TEXT[item.subscription_plan] ?? ''}
                </SubscriptionStatus>

                {item.service_type && (
                  <ServiceType isDIY={item.service_type === 'DIY'}>
                    {item.service_type}
                  </ServiceType>
                )}
              </SubAndServiceTypeSection>
            </Row>
            <div className="ml-2" style={{ position: 'relative' }}>
              <p className="fullName">{item.full_name}</p>
              <p className="mb-0 text-muted">
                {item.utr_no}-{item.postal_code}
              </p>
              {item?.sanctioned && (
                <SanctionedContainer>
                  <Sanctioned>SANCTIONED</Sanctioned>
                </SanctionedContainer>
              )}
            </div>
          </SectionName>
        </Col>

        <Col
          lg={8}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {hasEmploymentType !== '' && (
            <JobCategorySection
              onClick={() => openEmploymentTypeModal(item.employment_type)}
            >
              {checkEmploymentType(item.employment_type)}...
            </JobCategorySection>
          )}
          <div>
            <EmployeeTypeLabel employeeType={item.employee_type}>
              {handleLabels(item.employee_type)}
            </EmployeeTypeLabel>
          </div>
          <div>
            <CustomerProgressPieContainer>
              {progress.length > 0 &&
                progress.map((status, i) => {
                  return <NewCustomerProgressPie key={i} status={status} />;
                })}
            </CustomerProgressPieContainer>
          </div>
          <div>
            <StatusInfoContainer>
              {item &&
                item.status_info &&
                item.status_info
                  .slice(0, 3)
                  .map((info) => (
                    <StatusInfoPie key={info.id}>
                      {info.status_label}
                    </StatusInfoPie>
                  ))}
              {item && item.status_info && item.status_info.length > 3 && (
                <StatusInfoPie>+ {item.status_info.length - 3} </StatusInfoPie>
              )}
              <ActionsStatusButton onClick={() => toggleModal()}>
                <span className="text">...</span>
              </ActionsStatusButton>
            </StatusInfoContainer>

            {modalOpen && (
              <ModalStatusPie
                modalToggle={toggleModal}
                userId={item.id}
                refetchUserData={refetchCustomerInformation}
              />
            )}
          </div>
          <StatusYearsContainer>
            {item.years_status &&
              item.years_status.map((year) => (
                <YearsLabel
                  key={year.tax_year}
                  backgroundColor={handleYearColor(year.tax_status)}
                  onMouseMove={() => setYearSelected(year.tax_year)}
                  onMouseOut={() => setYearSelected(0)}
                >
                  {year.tax_year}
                  <YearsToolTip
                    visible={yearSelected === year.tax_year ? true : false}
                  >
                    {year.tax_status}
                  </YearsToolTip>
                </YearsLabel>
              ))}
          </StatusYearsContainer>
          <div>
            <Label for="yearEnding">Year Ending</Label>
            <div className="d-flex">
              <Input
                type="select"
                name="year"
                value={year}
                onChange={(event) => setYear(getQueryParam(event.target.value))}
              >
                {getYearsList().map((item, index) => (
                  <option key={`y${index}`}>{item}</option>
                ))}
              </Input>
            </div>
          </div>

          <div>
            {!isLoadingInformation && (
              <IconTaxAssistant
                id={item.id}
                name={item.tax_assistant_name}
                image={item.tax_assistant_image}
              />
            )}
          </div>
        </Col>

        <Col lg={2}>
          <DeleteAndCloseYearContainer>
            <IconDeleteOrDisableCustomer
              user={item}
              isDeleteButton
              isIconDelete={false}
            />
            <Button
              disabled
              onClick={() => setOpenYearModal(!openYearModal)}
              type="submit"
              style={{
                background: isPIE ? '#57E4CD' : '#FFDD05',
                borderColor: isPIE ? '#57E4CD' : '#FFDD05',
              }}
            >
              {item.year_closed
                ? `Reopen Year ${item.tax_year}`
                : `Close Year ${item.tax_year}`}
            </Button>
            {customer.customerData?.notifications_count > 0 && (
              <Button
                onClick={() => {}}
                type="submit"
                style={{
                  background: isPIE ? '#57E4CD' : '#FFDD05',
                  borderColor: isPIE ? '#57E4CD' : '#FFDD05',
                }}
              >
                <span>
                  <AiOutlineBell size={20} />{' '}
                  {customer.customerData.notifications_count} Notifications
                </span>
              </Button>
            )}
          </DeleteAndCloseYearContainer>
        </Col>

        <Modal centered isOpen={modal} toggle={modalToggle}>
          <ModalHeader toggle={modalToggle}>Job Categories</ModalHeader>
          <ModalBody className="d-flex flex-wrap">
            {employmentTypeList.map((list, index) => {
              return <JobCategorySpan key={index}>{list}</JobCategorySpan>;
            })}
          </ModalBody>
        </Modal>
      </Row>
    </div>
  );
};

export default observer(CustomerInformationHeader);
