import { styled } from 'src/modules';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-left: 10px;
`;

export const LogoBankContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  margin-left: 10px;
  width: 100%;

  > img {
    margin-right: 15px;
  }

  .clicked {
    -ms-transform: scale(2.5);
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    z-index: 1;
  }
`;

export const DeleteBankAccountSection = styled.div``;

export const PassportLink = styled.a`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  > span {
    margin-right: 16px;
    color: #209782;
    font-weight: 600;
  }
`;
