import { QueryFunctionContext } from 'react-query';

import request from './request';

export const getFeedback = async ({ queryKey }: QueryFunctionContext) => {
  const [, nextPage, typeSelected] = queryKey;

  const { data } = await request.get(
    `/feedback/list?page=${nextPage}&type=${typeSelected}`,
  );

  return data;
};

export const updateFeedback = async (id: number) => {
  const { data } = await request.post(`/feedback/update-status`, {
    id,
    status: 'complete',
  });

  return data;
};

export const saveFeedbackComment = async (id: number, comment: string) => {
  const { data } = await request.post(`/feedback/update-status`, {
    id,
    comment,
  });

  return data;
};
