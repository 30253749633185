import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Toastify,
  observer,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { Routes, ERRORS } from 'src/utils';

type Props = {
  path: string;
};

const OtpPage: React.FC<Props> = () => {
  const [otp, setOtp] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const auth = store.auth;

  const onLogin = async () => {
    setIsLoading(true);
    try {
      await auth.setOtp(otp);

      const path = Storage.getRedirectPath();
      path
        ? navigate(`/app/${path}`)
        : navigate(`${Routes.CUSTOMERS}/?tax_year=2022`);
      Storage.clearRedirectPath();
    } catch {
      Toastify.toast.error(ERRORS.LOGIN);
    } finally {
      setIsLoading(false);
    }
  };

  const onReset2FA = () => {
    navigate(`${Routes.SETUP_2FA}`);
  };

  useEffect(() => {
    if (otp.length === 6) {
      onLogin();
    }
  }, [otp]);

  useEffect(() => {
    const wrapper = document.getElementById('gatsby-focus-wrapper');
    wrapper &&
      wrapper.classList.add(
        'd-flex',
        'justify-content-center',
        'align-items-center',
      );

    return () => {
      wrapper &&
        wrapper.classList.remove(
          'd-flex',
          'justify-content-center',
          'align-items-center',
        );
    };
  }, []);

  return (
    <div className="w-50" style={{ maxWidth: '600px' }}>
      <Card>
        <CardBody>
          <div className="m-sm-4 align-items-center d-flex flex-column">
            <p className="text-center">
              Enter the code from your 2FA app to complete login.
            </p>
            <FormGroup>
              <Input
                className="mt-3"
                bsSize="lg"
                type="number"
                name="otp"
                placeholder="Enter your one time password"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </FormGroup>
            <Row>
              <Button
                className="mt-1 mr-1"
                color="primary"
                size="lg"
                onClick={onLogin}
                disabled={isLoading}
              >
                Login
              </Button>
              <Button
                className="mt-1"
                color="secondary"
                size="lg"
                onClick={onReset2FA}
              >
                Reset 2FA
              </Button>
            </Row>
          </div>
        </CardBody>
      </Card>
      <Toastify.ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default observer(OtpPage);
