import React from 'react';

import { AiFillCheckCircle } from 'react-icons/ai';

import { Col, Row } from 'src/modules';
import { CustomerBankInformation } from 'src/utils/types/Customer';

import { LabelData } from '../../styles';

import { BankInfoItem, Container, LogoBankContainer } from './styles';

type Props = {
  bank: CustomerBankInformation;
};

const BankInformationPie = ({ bank }: Props) => {
  // const onDeleteBankAccount = (id: number) => {};

  return (
    <Container>
      <Row>
        <LogoBankContainer>
          <div>
            <img src={bank.bank_image_url} alt="Bank Account Logo" />
          </div>
          <AiFillCheckCircle size={20} color="#209782" />
        </LogoBankContainer>
      </Row>
      <Row>
        <Col>
          <BankInfoItem>
            <LabelData for="bank" isPIE>
              Bank:
            </LabelData>
            <span>{bank.bank_name}</span>
          </BankInfoItem>

          <BankInfoItem>
            <LabelData for="account-type" isPIE>
              Account type:
            </LabelData>
            <span>{bank.account_type}</span>
          </BankInfoItem>

          <BankInfoItem>
            <LabelData for="token-issued" isPIE>
              Token issued:
            </LabelData>
            <span>{bank.token_issued}</span>
          </BankInfoItem>
        </Col>

        <Col>
          <BankInfoItem>
            <LabelData for="name-on-account" isPIE>
              Name on account:
            </LabelData>
            <span>{bank.name_on_account}</span>
          </BankInfoItem>

          <BankInfoItem>
            <LabelData for="connection-date-time" isPIE>
              Connection date/time
            </LabelData>
            <span>{bank.connection_date}</span>
          </BankInfoItem>

          {/* <DeleteBankAccountSection>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => onDeleteBankAccount(data.id)}
              color="red"
              size="lg"
            />
          </DeleteBankAccountSection> */}
        </Col>
      </Row>
    </Container>
  );
};

export default BankInformationPie;
