import React from 'react';

import { TIncomeType } from 'src/models';
import { Button, ButtonGroup, Col, Input, Row } from 'src/modules';

type Props = {
  type: TIncomeType;
  data: TaxIncomeNewData;
  index: number;
  onChange: any;
  onRemoveItem: any;
  canEdit: boolean;
};

//@TODO: Verify this types...
const TaxIncomeNewItem: React.FC<Props> = ({
  type,
  canEdit,
  data,
  index,
  onChange,
  onRemoveItem,
}) => {
  const toggleTax = () => {
    const value = !data.add_tax;
    let tax_percentage: number | string = value ? data.tax_percentage : 0;
    let tax_amount: number | string = '';

    if (value) {
      tax_percentage = 0;
    }
    if ((value && !data.tax_amount) || data.tax_amount === '') {
      tax_amount = (Number(data.gross_amount) * Number(tax_percentage)) / 100;
    }

    onChange(index, { ...data, add_tax: value, tax_amount, tax_percentage });
    onSetPercentage(Number(data.tax_percentage));
  };

  const onSetPercentage = (tax_percentage: number) => {
    const taxPercentage = Number(tax_percentage) / 100;

    let grossAmount = data.gross_amount;
    let netAmount = data.net_amount;
    let taxAmount;

    if (grossAmount && grossAmount > 0) {
      taxAmount = Number(grossAmount) * taxPercentage;
      netAmount = Number(grossAmount - taxAmount);
    } else {
      if (netAmount && netAmount > 0) {
        grossAmount = Number(netAmount) * (100 / (100 - taxPercentage * 100));
        taxAmount = Number(grossAmount - netAmount);
      }
    }

    onChange(index, {
      ...data,
      tax_percentage,
      gross_amount: grossAmount,
      tax_amount: taxAmount,
      net_amount: netAmount,
    });
  };

  const onChangeTaxAmount = (index: number, tax_amount: string) => {
    const grossAmount = Number(data.gross_amount);
    const taxAmount = Number(tax_amount);

    const netAmount = grossAmount - taxAmount;

    let taxPercentage = (netAmount * 100) / grossAmount || 0;
    if (taxAmount === 0 || !tax_amount) taxPercentage = 0;

    onChange(index, {
      ...data,
      net_amount: netAmount,
      tax_amount: taxAmount,
      tax_percentage: taxPercentage,
    });
  };

  const OnChangeTitle = (index: number, text: string) =>
    onChange(index, { ...data, title: text });

  const onChageGrossValue = (index: number, text: string) => {
    const taxPercentage = Number(data.tax_percentage) || 0;
    const grossAmount = Number(text);

    const hasPercentage = !isNaN(taxPercentage) && taxPercentage > 0;

    const taxAmount = hasPercentage ? grossAmount * (taxPercentage / 100) : 0;
    const netAmount = hasPercentage ? grossAmount - taxAmount : 0;
    onChange(index, {
      ...data,
      tax_percentage: taxPercentage,
      gross_amount: grossAmount,
      net_amount: netAmount,
      tax_amount: taxAmount,
    });
  };

  const onChangeNetValue = (index: number, netAmount: number) => {
    const taxPercentage = Number(data.tax_percentage);
    const hasPercentage = !isNaN(taxPercentage) && taxPercentage > 0;

    const grossAmount = hasPercentage
      ? Number(netAmount) * (100 / (100 - taxPercentage))
      : data.gross_amount;
    const taxAmount = hasPercentage ? Number(grossAmount - netAmount) : '';

    onChange(index, {
      ...data,
      tax_percentage: taxPercentage,
      gross_amount: grossAmount,
      tax_amount: taxAmount,
      net_amount: netAmount,
    });
  };

  return (
    <Row className="px-2" style={{ position: 'relative' }}>
      <Col className="p-1">
        <Input
          disabled={!canEdit}
          type="text"
          placeholder="Employer Name"
          value={data.title || ''}
          onChange={(e) => OnChangeTitle(index, e.target.value)}
        />
      </Col>
      {type === 'Paye' && (
        <Col className="p-1">
          <Input
            disabled={!canEdit}
            type="text"
            placeholder="Paye Ref"
            value={data.paye_ref || ''}
            onChange={(e) =>
              onChange(index, { ...data, paye_ref: e.target.value })
            }
          />
        </Col>
      )}
      <Col className="p-1" xs="2">
        <Input
          disabled={!canEdit}
          type="number"
          placeholder="Gross"
          value={data.gross_amount || ''}
          onChange={(e) => onChageGrossValue(index, e.target.value)}
        />
      </Col>

      {type === 'Paye' && (
        <Col className="p-1">
          <Input
            disabled={!canEdit}
            type="number"
            placeholder="Tax"
            value={data.tax_amount || ''}
            onChange={(e) =>
              onChange(index, { ...data, tax_amount: e.target.value })
            }
          />
        </Col>
      )}
      {type === 'Self' && (
        <Col className="p-1" xs="2">
          <Input
            type="number"
            placeholder="NET"
            value={data.net_amount || ''}
            onChange={(e) => onChangeNetValue(index, e.target.value)}
          />
        </Col>
      )}

      {type === 'Self' && (
        <Col className="px-0 py-1">
          <div className="d-flex">
            <Button
              color={data.add_tax ? 'primary' : 'secondary'}
              onClick={canEdit ? toggleTax : null}
            >
              Tax
            </Button>
            <Input
              disabled={!canEdit}
              style={{ minWidth: 50 }}
              type="number"
              placeholder="Tax"
              value={data.tax_amount || ''}
              onChange={(e) => onChangeTaxAmount(index, e.target.value)}
            />
            <ButtonGroup>
              <Button
                disabled={!data.add_tax && !canEdit}
                size="sm"
                outline={Number(data.tax_percentage) !== 20}
                onClick={() => onSetPercentage(20)}
              >
                20%
              </Button>
              <Button
                disabled={!data.add_tax && !canEdit}
                size="sm"
                outline={Number(data.tax_percentage) !== 30}
                onClick={() => onSetPercentage(30)}
              >
                30%
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      )}

      {canEdit && index >= 1 && (
        <div style={{ position: 'absolute', left: -17, top: 9 }}>
          <Button
            style={{
              width: 20,
              minWidth: 20,
              height: 22,
              minHeight: 22,
              padding: 0,
            }}
            color="secondary"
            outline
            onClick={() => onRemoveItem(index)}
          >
            -
          </Button>
        </div>
      )}
    </Row>
  );
};

export default TaxIncomeNewItem;
