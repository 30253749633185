import { styled } from 'src/modules';

export const UserInformation = styled.button`
  background-color: transparent;
  border: none;

  img,
  svg {
    margin: 0 8px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const AvailableAssistant = styled.div`
  margin-left: 15px;
  padding-top: 2px;
`;
