import React from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffRow from '../StaffRow';
import StaffRowTotal from '../StaffRowTotal';

type Props = {
  dataIncome?: StaffChartData;
};

const IncomeTable: React.FC<Props> = ({ dataIncome }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        Signed Off / Checked
      </h1>
      <Table className="text-center" style={{ marginBottom: 100 }}>
        <thead>
          <tr>
            <td></td>
            {dataIncome?.byDate.map((item, index) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {dataIncome?.byGroup.map((item, index) => (
            <StaffRow
              index={index}
              item={item}
              key={`${item.assistant}-${index}`}
            />
          ))}
          <StaffRowTotal data={dataIncome as any}></StaffRowTotal>
        </tbody>
      </Table>
    </>
  );
};

export default IncomeTable;
