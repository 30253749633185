import React from 'react';

import {
  TabsHeader,
  Tab,
  TabSeparator,
  TabImage,
  TabTitle,
} from '../../../styles/pages/etoro/etoro-styles';

export default function TabsHeaderComponent({ activeTab, setActiveTab }) {
  return (
    <TabsHeader>
      <Tab isActive={activeTab === 0} onClick={() => setActiveTab(0)}>
        <TabImage src="/images/incomesTab/Stocks.png" alt="" />
        <TabTitle>All</TabTitle>
        {/* <TabSubtitle>£ 105,571.11</TabSubtitle> */}
      </Tab>
      <TabSeparator />
      <Tab isActive={activeTab === 1} onClick={() => setActiveTab(1)}>
        <TabImage src="/images/incomesTab/SelfEmployed.png" alt="" />
        <TabTitle>Self employed</TabTitle>
        {/* <TabSubtitle>£ 63,129.24</TabSubtitle> */}
      </Tab>
      <TabSeparator />
      <Tab isActive={activeTab === 2} onClick={() => setActiveTab(2)}>
        <TabImage src="/images/incomesTab/Property.png" alt="" />
        <TabTitle>Property</TabTitle>
        {/* <TabSubtitle>£ 9,200.00</TabSubtitle> */}
      </Tab>
      <TabSeparator />
      <Tab isActive={activeTab === 3} onClick={() => setActiveTab(3)}>
        <TabImage src="/images/incomesTab/capital-gains.png" alt="" />
        <TabTitle>Capital Gains</TabTitle>
        {/* <TabSubtitle>£ 33,241.00</TabSubtitle> */}
      </Tab>
    </TabsHeader>
  );
}
