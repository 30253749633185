import React from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffRowDoA from '../StaffRowDoA';
import StaffRowDoATotal from '../StaffRowDoATotal';

type Props = {
  dataDoA?: StaffChartData;
};

const DoATable: React.FC<Props> = ({ dataDoA }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        Deed of assignment
      </h1>
      <Table className="text-center" style={{ marginBottom: 100 }}>
        <thead>
          <tr>
            <td></td>
            {dataDoA?.byDate.map((item, index) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          {dataDoA?.byGroup.map((item, index) => (
            <StaffRowDoA
              item={item}
              index={index}
              key={`${item.assistant}-${index}`}
            />
          ))}
          <StaffRowDoATotal data={dataDoA as any}></StaffRowDoATotal>
        </tbody>
      </Table>
    </>
  );
};

export default DoATable;
