import React from 'react';

import { useQuery } from 'react-query';

import { fetchTemplates } from 'src/api/inbox/inbox-templates.api';
import Icon from 'src/components/Icon';
import { Loading } from 'src/components/ui';
import { Button } from 'src/modules';
import store from 'src/stores';

import {
  Body,
  BodyItem,
  Container,
  Header,
  HeaderItem,
  Modal,
  ModalContent,
  ModalHeader,
} from './styles';

type Props = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setMessageToSend: (message: string) => void;
};

const ModalInboxTemplate = ({
  openModal,
  setOpenModal,
  setMessageToSend,
}: Props) => {
  const themeStore = store.theme;

  const { data: templates, isLoading } = useQuery('templates', fetchTemplates);

  const onCopyMessage = (message: string) => {
    navigator.clipboard.writeText(message);

    setTimeout(() => {
      setOpenModal(false);
    }, 1000);
  };

  const onSendMessage = (message: string) => {
    setMessageToSend(message);

    setTimeout(() => {
      setOpenModal(false);
    }, 1000);
  };

  return (
    <Modal openModal={openModal}>
      <ModalContent className="mc-modal-content">
        <ModalHeader>
          <span className="title">Inbox Templates</span>
          <button
            onClick={() => setOpenModal(false)}
            className="close-modal-button"
          >
            <Icon
              name={
                themeStore.currentTheme === 'dark' ? 'white-close' : 'close'
              }
            />
          </button>
        </ModalHeader>

        <Container>
          <Header>
            <HeaderItem width="10%" className="mc-modal-header-item">
              #
            </HeaderItem>
            <HeaderItem width="30%" className="mc-modal-header-item">
              Title
            </HeaderItem>
            <HeaderItem width="60%" className="mc-modal-header-item">
              Description
            </HeaderItem>
          </Header>
          <Body className="mc-modal-body">
            {isLoading && <Loading />}

            {!isLoading &&
              templates?.map((template, index) => {
                return (
                  <div
                    key={template.id}
                    className="body-content"
                    style={{ background: index % 2 == 1 ? '#eee' : '#fff' }}
                  >
                    <BodyItem width="10%" className="mc-body-item">
                      {template.id}
                    </BodyItem>
                    <BodyItem width="30%">{template.title} </BodyItem>
                    <BodyItem width="60%" className="item-description">
                      <div className="body-item-container">
                        <span className="item-message">{template.message}</span>

                        <div className="buttons-body-item">
                          <Button
                            onClick={() => onSendMessage(template.message)}
                            color="primary"
                            style={{ marginBottom: 15 }}
                          >
                            Send
                          </Button>
                          <Button
                            onClick={() => onCopyMessage(template.message)}
                            color="primary"
                            style={{ marginTop: 15 }}
                          >
                            Copy
                          </Button>
                        </div>
                      </div>
                    </BodyItem>
                  </div>
                );
              })}
          </Body>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default ModalInboxTemplate;
