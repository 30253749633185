import request from '../request';

export const listStatusTags = async (): Promise<StatusTag[]> => {
  const { data } = await request.get(`/status-labels`);

  return data.data;
};

export const editStatusTag = async ({
  id,
  newTitle,
}: {
  id: number;
  newTitle: string;
}): Promise<Pick<StatusTag, 'id' | 'status_label_name'>> => {
  const { data } = await request.post(`/status-labels/edit`, {
    id,
    status_label_name: newTitle,
  });

  return data;
};

export const createStatusTag = async ({
  title,
}: {
  title: string;
}): Promise<StatusTag> => {
  const { data } = await request.post(`/status-labels/create`, {
    status_label_name: title,
  });

  return data.data;
};

export const deleteStatusTag = async ({
  id,
}: {
  id: number;
}): Promise<StatusTag> => {
  const { data } = await request.post(`/status-labels/remove`, {
    id,
  });

  return data.data;
};
