import { TaxStatus } from 'src/utils';
import {
  CustomerInformation,
  CustomerBasicData,
} from 'src/utils/types/Customer';

export const customerInitialValues: CustomerInformation = {
  id: 0,
  prefix: '',
  profile_pic: '',
  full_name: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  utr_no: '',
  email: '',
  status: '',
  employee_type: '',
  phone: '',
  insurance_no: '',
  requested_utr: '',
  dob: '',
  user_referral_code: '',
  company_name: '',
  company_logo: '',
  company_address: '',
  company_postal_code: '',
  vat_no: '',
  account_holder_name: '',
  account_sort_code: '',
  account_number: '',
  id_proof: '',
  id_proof_title: '',
  referred_by: '',
  postal_code: '',
  address: '',
  tax_year: 0,
  how_you_are_here: '',
  how_you_here_image: '',
  tax_assistant_image: '',
  tax_status: TaxStatus.InProgress,
  income_status: '',
  income_received_date: '',
  income_completed_date: '',
  expense_status: '',
  expense_received_date: '',
  expense_completed_date: '',
  is_called: false,
  is_paid_status: 'Blank',
  tax_code_complete: false,
  pre_payment_status: 0,
  refund_amount: 0,
  agent_fee: 0,
  notifications_count: 0,
  agent_fee_type: '',
  agent_fee_percentage: 0,
  vat_amount: 0,
  vat_percentage: 0,
  pre_pay_agent_fee: 0,
  pre_pay_agent_fee_type: '',
  pre_pay_agent_fee_percentage: 0,
  pre_pay_vat_percentage: 0,
  pre_pay_vat_amount: 0,
  paid_date: '',
  estimated_refund_date: '',
  send_notifications: false,
  send_sms: false,
  send_email: false,
  last_login: '',
  user_tax_modified_date: '',
  created_ip: '',
  tax_status_process_date: '',
  tax_status_paid_date: '',
  is_paye_employee: false,
  has_dual_employee: false,
  status_info: [],
  message: '',
  is_multiple_locations: false,
  is_pub_priv_travel: false,
  reach_by: '',
  is_estimated: false,
  has_button_expense: false,
  url_add_edit_expense: '',
  is_add_expense: false,
};

export const basicDataInitialValues: CustomerBasicData = {
  boot_expenses: [],
  cloth_expenses: [],
  customer_expenses_cashback: 0,
  doc_expenses: [],
  employers_list: [],
  expense_tab_cost_information: [],
  food_expenses: [],
  food_expenses_total: 0,
  hotel_dig_expenses: [],
  job_industry_expenses: [],
  job_industry_tools_categories: [],
  journey_expenses: [],
  journey_location: [],
  material_expenses: [],
  mileage_total: {
    all_employees_total_miles: 0,
    current_mileage_date_above: 0,
    first_tested_year: 0,
    mileage: 0,
    mileage_first_10k: 0,
    mileage_in_difference: 0,
    mileage_over_10k: '',
    mileage_percentage: 0,
    past_mileage_date_above: 0,
    second_tested_year: 0,
    total_miles: 0,
  },
  new_phone_expenses: [],
  new_phone_expenses_total: '',
  other_expenses: [],
  parking_expenses: [],
  payroll_admin_fee_expenses: [],
  private_vehicle_transport_expenses: {
    annual_car_insurance: 0,
    total_annual_car_insurance: 0,
    annual_mot: 0,
    total_annual_mot: 0,
    annual_service_repairs_cost: 0,
    total_annual_service_repairs_cost: 0,
    annual_road_side_recovery_cost: 0,
    total_annual_roadside_recovery_cost: 0,
    annual_car_cleaning_cost: 0,
    total_annual_car_cleaning_cost: 0,
    annual_hire_purchase_hire_car_cost: 0,
    total_annual_hire_purchase_hire_car_cost: 0,
    vehicle_used_percentage_work_use: 0,
  },
  receipt_expenses: [],
  repair_expenses: [],
  telephone: {
    cost_laptops_computers: 0,
    mobile_calculated_cost: 0,
    total_monthly_mobile_cost: 0,
    monthly_cost_notes: '',
    monthly_landline_broadband_cost: 0,
    monthly_mobile_cost: 0,
    monthly_mobile_insurance_cost: 0,
    monthly_mobile_insurance_cost_notes: '',
    new_mobile_cost: 0,
    new_phone_expenses: [],
    notes: '',
    percentage_landline_broadband: 0,
    percentage_use_laptop_computer: 0,
    percentage_use_mobile: 0,
    total_cost_laptops_computers: 0,
    total_landline_broadband_cost: 0,
    total_monthly_mobile_insurance_cost: 0,
    total_telephone_stationary_office: 0,
  },
  ticket_expenses: [],
  toll_expenses: [],
  tool_expenses: [],
  tool_repair_expenses: [],
  tool_storage_expenses: [],
  total_cloth_expenses: 0,
  total_public_expenses: 0,
  total_tool_expenses: 0,
  totals: {
    car_van_travel: 0,
    grand_total: 0,
    other: 0,
    private_cost_total: 0,
    rent_power_insurance: 0,
    repairs_maintenance: 0,
    telephone_fax_stationery: 0,
    ticket_expenses: 0,
    total_cloth_expenses: 0,
    total_landline: 0,
    business: 0,
  },
  training_expenses: [],
  users_detail_bank_info: [],
  vehicle_expenses: [],
  vehicle_other_expenses: [],
  washing_expenses: [],
};

export const expensesBasicDataInitial: ExpensesBasicData = {
  personal_information: {
    id: 0,
    full_name: '',
    email: '',
    utr: '',
    insurance_no: '',
    dob: '',
    address: '',
    postal_code: '',
    tax_year: '',
    is_paye_employee: 0,
    is_worked_more_sites: 0,
    is_pub_priv_travel: 0,
    how_get_to_work: '',
    is_journey_fuel: '',
    weekly_fuel_cost: 0,
  },
  public_transport_cost: {
    expenses: [
      {
        id: 0,
        form_id: 0,
        employer_id: 0,
        duration_type: '',
        duration: 0,
        duration_notes: '',
        cost: 0,
        disallowed_cost: 0,
        cost_note: '',
        status: '',
        created_date: '',
        modified_date: '',
        created_ip: 0,
        deleted_at: '',
        total: 0,
        ticket_notes: '',
        description_public_transport: '',
        cost_public_transport: '',
      },
    ],
    total: 0,
  },
  private_transport_cost: {
    vehicle: {
      id: 0,
      form_id: 0,
      is_use_for_work: true,
      purchase_date: '',
      cost: 0,
      disallowed_cost: 0,
      make: '',
      color: '',
      co_2_emissions: '',
      vehicle_class: '',
      mot_history: '',
      registration_plate: '',
      total: 0,
      last_mot: 0,
    },
    vehicle_other_exp: [],
    journeys: [],
    parking: [],
    mileage: {
      total: 0,
      first_10k: 0,
      over_10k: 0,
    },
    weekly_fuel_cost: 0,
    annual_car_costs: {
      annual_car_insurance: 0,
      annual_mot: 0,
      annual_service_repairs_cost: 0,
      annual_road_side_recovery_cost: 0,
      annual_car_cleaning_cost: 0,
      annual_hire_purchase_hire_car_cost: 0,
      vehicle_used_percentage_work_use: 0,
      total_annual_car_insurance: 0,
      total_annual_mot: 0,
      total_annual_service_repairs_cost: 0,
      total_annual_roadside_recovery_cost: 0,
      total_annual_car_cleaning_cost: 0,
      total_annual_hire_purchase_hire_car_cost: 0,
    },
    congestion_toll_cost_detail: [],
    total: 0,
  },
  tools_and_material_cost: {
    industry_expenses: [],
    tools: [],
    tools_repair: [],
    material: [],
    tools_storage: [],
    hotel_dig: [],
    total: 0,
  },
  clothes_cost: {
    cloths: [],
    boots: [],
    washings: [],
    cloths_repair: [],
    total: 0,
  },
  telephone_stationary_office: {
    monthly_landline: 0,
    percentage_use_landline: 0,
    total_landline: 0,
    monthly_mobile_cost: 0,
    total_monthly_mobile_cost: 0,
    mobile_disallowed_cost: 0,
    monthly_cost_notes: '',
    monthly_mobile_insurance_cost: 0,
    total_monthly_mobile_insurance_cost: 0,
    monthly_mobile_insurance_cost_notes: '',
    new_phone: [],
    percentage_mobile_for_work: 0,
    cost_new_laptops_computers: 0,
    total_cast_laptops_computers: 0,
    percentage_use_laptop_computer: 0,
    trainingExpense: [],
    total: 0,
  },
  food_other_expenses: {
    foods: [],
    public_liability_insurance: 0,
    payroll: [],
    other_expenses: [],
    total: 0,
  },

  estimated_figures: {
    contain_estimated_figures: '',
    images: [],
  },
  bank_details: {
    holder_name: '',
    sort_code: '',
    number: '',
  },
  receipts: [],
  customer_expenses_cashback: [],
  expenses_paye_employer: [],
  totals: {
    total_car_van_travel: 0,
    rent_power_insurance: 0,
    repairs_maintenance: 0,
    telephone_fax_stationery: 0,
    business: 0,
    other: 0,
    grand_total: 0,
  },
  message: '',
  income_amount: 0,
};
