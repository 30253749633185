import React, { useEffect, useState } from 'react';

import { useMutation } from 'react-query';

import { Title, CardContainer, Subtitle } from '../../styles';

import { calculateTax } from 'src/api/forms-tab';
import { Card, Table, Toastify } from 'src/modules';

import './styles.css';
import { SUCCESS } from 'src/utils';
import { formatCurrency } from 'src/utils/Utils';

type Props = {
  userData: any;
  taxReturnId: number;
};

export const TaxCalculator = ({ userData, taxReturnId }: Props) => {
  const [taxCalc, setTaxCalc] = useState<any>({});

  useEffect(() => {
    if (taxReturnId > 0) mutationCalculateTax(taxReturnId);
    //refetch();
  }, []);

  useEffect(() => {
    if (taxReturnId > 0) mutationCalculateTax(taxReturnId);
  }, [taxReturnId]);

  const { mutate: mutationCalculateTax } = useMutation(calculateTax, {
    onSuccess(resp) {
      setTaxCalc(calcDataView(resp.data));
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  function payPensionsProfitCalc(data: any, rates: any) {
    // `Basic rate £9.999,77 x 20% = 1.999,17`;
    let title0,
      title1,
      title2,
      title3,
      title4,
      title5 = '';
    let value1,
      value2,
      value3,
      value4,
      value5 = '';

    const taxable_pay_SSR = parseFloat(data['taxable-pay-SSR']);
    const taxable_pay_BR = parseFloat(data['taxable-pay-BR']);
    const taxable_pay_SIR = parseFloat(data['taxable-pay-SIR']);
    const taxable_pay_HR = parseFloat(data['taxable-pay-HR']);
    const taxable_pay_AHR = parseFloat(data['taxable-pay-AHR']);

    const income_tax_status = data['Income-tax-status'];
    const SSR_rate = rates['SSR_rate'];
    const tax_on_pay_SSR = parseFloat(data['tax-on-pay-SSR']);
    const tax_on_pay_BR = parseFloat(data['tax-on-pay-BR']);
    const tax_on_pay_SIR = parseFloat(data['tax-on-pay-SIR']);
    const tax_on_pay_AHR = parseFloat(data['tax-on-pay-AHR']);
    const tax_on_pay_HR = parseFloat(data['tax-on-pay-HR']);

    const SBR_rate = rates['SBR_rate'];
    const WBR_rate = rates['WBR_rate'];
    const BR_rate = rates['BR_rate'];
    const SIR_rate = rates['SIR_rate'];
    const SHR_rate = rates['SHR_rate'];
    const HR_rate = rates['HR_rate'];
    const SAHR_rate = rates['SAHR_rate'];
    const WAHR_rate = rates['WAHR_rate'];
    const AHR_rate = rates['AHR_rate'];

    if (
      taxable_pay_SSR +
        taxable_pay_BR +
        taxable_pay_SIR +
        taxable_pay_HR +
        taxable_pay_AHR >
      0
    ) {
      if (income_tax_status == '0002')
        title0 = 'Pay, pensions, profit etc. (Scottish income tax rate)';
      else if (income_tax_status == '0003')
        title0 = 'Pay, pensions, profit etc. (Welsh income tax rate)';
      else
        title0 =
          'Pay, pensions, profit etc. (UK rate for England and Northern Ireland)';

      if (
        income_tax_status == '0002' &&
        taxable_pay_SSR +
          taxable_pay_BR +
          taxable_pay_SIR +
          taxable_pay_HR +
          taxable_pay_AHR >
          0
      ) {
        title1 = 'Starter rate ';
        value1 = `${formatCurrency(
          taxable_pay_SSR,
        )} x ${SSR_rate}% = ${formatCurrency(tax_on_pay_SSR)}`;
      }

      if (
        taxable_pay_BR + taxable_pay_SIR + taxable_pay_HR + taxable_pay_AHR >
        0
      ) {
        let X_rate = '0';

        if (income_tax_status == '0002') X_rate = SBR_rate;
        else if (income_tax_status == '0003') X_rate = WBR_rate;
        else X_rate = BR_rate;

        title2 = 'Basic rate ';
        value2 = `${formatCurrency(taxable_pay_BR)} x ${
          X_rate * 100
        }% = ${formatCurrency(tax_on_pay_BR)}`;
      }

      if (
        income_tax_status == '0002' &&
        taxable_pay_SIR + taxable_pay_HR + taxable_pay_AHR > 0
      ) {
        title3 = 'Intermediate rate ';
        value3 = `${formatCurrency(
          taxable_pay_SIR,
        )} x ${SIR_rate}% = ${formatCurrency(tax_on_pay_SIR)}`;
      }

      if (taxable_pay_HR + taxable_pay_AHR > 0) {
        let X_rate = '0';

        if (income_tax_status == '0002') X_rate = SHR_rate;
        else if (income_tax_status == '0003') X_rate = WBR_rate;
        else X_rate = HR_rate;
        title4 = 'Higher rate ';
        value4 = `${formatCurrency(
          taxable_pay_HR,
        )} x ${X_rate}% = ${formatCurrency(tax_on_pay_HR)}`;
      }

      if (taxable_pay_AHR > 0) {
        if (income_tax_status == '0002') {
          title5 = 'Top rate ';
          value5 = `${formatCurrency(
            taxable_pay_AHR,
          )} x ${SAHR_rate}% = ${formatCurrency(tax_on_pay_AHR)}`;
        } else if (income_tax_status == '0003') {
          title5 = 'Additional rate ';
          value5 = `${formatCurrency(
            taxable_pay_AHR,
          )} x ${WAHR_rate}% = ${formatCurrency(tax_on_pay_AHR)}`;
        } else {
          title5 = 'Additional rate ';
          value5 = `${formatCurrency(
            taxable_pay_AHR,
          )} x ${AHR_rate}% = ${formatCurrency(tax_on_pay_AHR)}`;
        }
      }
    }

    return (
      <>
        <tr>
          <td>{title0}</td>
          <td>
            <span className="fr"></span>
          </td>
        </tr>
        {title1 || value1 ? (
          <tr>
            <td>{title1}</td>
            <td>
              <span className="fr">{value1}</span>
            </td>
          </tr>
        ) : (
          <></>
        )}
        {title2 || value2 ? (
          <tr>
            <td>{title2}</td>
            <td>
              <span className="fr">{value2}</span>
            </td>
          </tr>
        ) : (
          <></>
        )}
        {title3 || value3 ? (
          <tr>
            <td>{title3}</td>
            <td>
              <span className="fr">{value3}</span>
            </td>
          </tr>
        ) : (
          <></>
        )}
        {title4 || value4 ? (
          <tr>
            <td>{title4}</td>
            <td>
              <span className="fr">{value4}</span>
            </td>
          </tr>
        ) : (
          <></>
        )}
        {title5 || value5 ? (
          <tr>
            <td>{title5}</td>
            <td>
              <span className="fr">{value5}</span>
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  }

  function calcDataView({ data, rates }: any) {
    const pay_e = parseFloat(data['Pay-E']);
    const exps_e = parseFloat(data['Exps-E']);
    const total_all_e = pay_e - exps_e;
    const profits_se = parseFloat(data['income-D']);
    const total_income_received = pay_e - exps_e + profits_se;
    const Personal_allowance = parseFloat(data['Personal_allowance']);
    const taxable_income = parseFloat(data['taxable-income']);

    const pay_pensions_profit = payPensionsProfitCalc(data, rates);
    const total_income_taxable = parseFloat(data['taxable-income']);
    const income_tax_charge_after_allowances_and_refiefs = parseFloat(
      data['NIC-charge'],
    );

    const NIC_amount = parseFloat(data['NIC-amount']);
    const NIC_rate = parseFloat(rates['NIC_rate']);
    const NIC_charge = parseFloat(data['NIC-charge']);

    const class_4_contributions = `${formatCurrency(NIC_amount)} x ${
      NIC_rate * 100
    }% = ${formatCurrency(NIC_charge)}`;
    const class_2_contributions = parseFloat(data['NIC2-amount']);
    const class_2_and_4_due = parseFloat(data['NIC-total']);

    const income_tax_class_2_and_4_due = parseFloat(data['IT-plus-class4']);

    const paye = parseFloat(data['tax-paid-E']);
    const cis_and_trading_income = parseFloat(data['tax-paid-D']);
    const total_tax_deducted = parseFloat(data['total-tax-paid']);

    const income_tax_overpaid = parseFloat(data['pie-sa110-total-tax-etc-due']);

    const tax_on_pay_BR = parseFloat(data['tax-on-pay-BR']);
    const tax_on_pay_HR = parseFloat(data['tax-on-pay-HR']);
    const tax_on_pay_AHR = parseFloat(data['tax-on-pay-AHR']);
    const tax_on_pay_SSR = parseFloat(data['tax-on-pay-SSR']);
    const tax_on_pay_SIR = parseFloat(data['tax-on-pay-SIR']);

    const total_cgt_tax = parseFloat(data['CGTax']);
    const cg_at_lower_rate = parseFloat(data['CG-at-lower-rate']);
    const lower_cgt_rate = parseFloat(rates['Lower_CGT_rate']);
    const cgt_at_higher_rate = parseFloat(data['CG-at-higher-rate']);
    const upper_cgt_rate = parseFloat(rates['Upper_CGT_rate']);
    const taxable_cg = parseFloat(data['Taxable-CG']);
    const higher_rate_cgt = parseFloat(data['Higher-rate-CGT']);
    const lower_rate_cgt = parseFloat(data['Lower-rate-CGT']);

    const taxCalc = {
      pay_e,
      exps_e,
      total_all_e,
      profits_se,
      total_income_received,
      Personal_allowance,
      taxable_income,

      pay_pensions_profit,
      total_income_taxable,
      income_tax_charge_after_allowances_and_refiefs,

      class_4_contributions,
      class_2_contributions,
      class_2_and_4_due,
      income_tax_class_2_and_4_due,

      paye,
      cis_and_trading_income,
      total_tax_deducted,
      income_tax_overpaid,

      tax_on_pay_BR,
      tax_on_pay_HR,
      tax_on_pay_AHR,
      tax_on_pay_SSR,
      tax_on_pay_SIR,

      total_cgt_tax,
      cg_at_lower_rate,
      lower_cgt_rate,
      cgt_at_higher_rate,
      upper_cgt_rate,
      taxable_cg,
      higher_rate_cgt,
      lower_rate_cgt,
    };

    return taxCalc;
  }

  const calcIncomeTaxChargeAfterAllowancesAndReliefs = () => {
    return formatCurrency(
      taxCalc.tax_on_pay_BR +
        taxCalc.tax_on_pay_HR +
        taxCalc.tax_on_pay_AHR +
        taxCalc.tax_on_pay_SSR +
        taxCalc.tax_on_pay_SIR,
    );
  };

  const renderOtherGainsBasicRate = () => {
    const basicRatePercent = taxCalc.lower_cgt_rate * 100;
    const cgAtLowerRate = formatCurrency(taxCalc.cg_at_lower_rate);
    const lowerRateCgt = formatCurrency(taxCalc.lower_rate_cgt);

    return `${cgAtLowerRate} x ${basicRatePercent}% = ${lowerRateCgt}`;
  };

  const renderOtherGainsHigherRate = () => {
    const cgtAt_HigherRate = formatCurrency(taxCalc.cgt_at_higher_rate);
    const upperCgtRate = taxCalc.upper_cgt_rate * 100;
    const higherRateCgt = formatCurrency(taxCalc.higher_rate_cgt);

    return `${cgtAt_HigherRate} x ${upperCgtRate}% = ${higherRateCgt}`;
  };

  const taxableCapitalGains = formatCurrency(taxCalc.taxable_cg);

  const capitalGainsTax = formatCurrency(taxCalc.total_cgt_tax);

  return (
    <>
      <hr />
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <Card>
            <CardContainer>
              <Title>
                {userData.full_name} tax calculation {userData.tax_year}
              </Title>
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>Income received (before tax)</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!taxCalc.pay_e ||
                  !!taxCalc.exps_e ||
                  !!taxCalc.total_all_e ? (
                    <>
                      <tr>
                        <td>Pay from all employments</td>
                        <td>
                          <span className="fr">
                            {formatCurrency(taxCalc.pay_e)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Minus payable expenses</td>
                        <td>
                          <span className="fr">
                            {formatCurrency(taxCalc.exps_e)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Total from all employments</td>
                        <td>
                          <span className="fr">
                            {formatCurrency(taxCalc.total_all_e)}
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  {!!taxCalc.profits_se && (
                    <tr>
                      <td>Profits from self-employment</td>
                      <td>
                        <span className="fr">
                          {formatCurrency(taxCalc.profits_se)}
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Total Income received</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.total_income_received)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>Minus</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Personal allowance</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.Personal_allowance)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.Personal_allowance)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Income on which tax is due</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.taxable_income)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>How we calculate your income tax</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taxCalc.pay_pensions_profit}
                  <tr>
                    <td>Total Income Taxable</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.total_income_taxable)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Income tax charge after allowances and refiefs</td>
                    <td>
                      <span className="fr">
                        {calcIncomeTaxChargeAfterAllowancesAndReliefs()}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>National insurance</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>plus Class 4 NI contributions</td>
                    <td>
                      <span className="fr">
                        {taxCalc?.class_4_contributions}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>plus Class 2 NI contributions</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.class_2_contributions)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Class 2 and Class 4 due</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.class_2_and_4_due)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>Income and National Insurance</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Income tax, Class 2 and Class 4 NI due</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.income_tax_class_2_and_4_due)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />

              {taxCalc.total_cgt_tax > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <Subtitle>Capital Gains</Subtitle>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxCalc.lower_rate_cgt > 0 && (
                      <tr>
                        <td>Other gains basic rate</td>
                        <td>
                          <span className="fr">
                            {renderOtherGainsBasicRate()}
                          </span>
                        </td>
                      </tr>
                    )}

                    {taxCalc.higher_rate_cgt > 0 && (
                      <tr>
                        <td>Other gains higher rate</td>
                        <td>
                          <span className="fr">
                            {renderOtherGainsHigherRate()}
                          </span>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>Taxable capital gains</td>
                      <td>
                        <span className="fr">{taxableCapitalGains}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Capital gains tax</td>
                      <td>
                        <span className="fr">{capitalGainsTax}</span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}

              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>Minus tax deducted</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PAYE</td>
                    <td>
                      <span className="fr">{formatCurrency(taxCalc.paye)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>CIS and trading income</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.cis_and_trading_income)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total tax deducted</td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.total_tax_deducted)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <Subtitle>Final tax position</Subtitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {taxCalc.income_tax_overpaid > 0
                        ? 'Income tax Underpaid'
                        : 'Income tax Overpaid'}{' '}
                    </td>
                    <td>
                      <span className="fr">
                        {formatCurrency(taxCalc.income_tax_overpaid < 0
                          ? taxCalc.income_tax_overpaid * -1
                          : taxCalc.income_tax_overpaid
                          )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardContainer>
          </Card>
        </div>
      </div>
    </>
  );
};
