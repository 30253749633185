import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { getMessagesQuickReplies, updateMessage } from 'src/api/message-center';
import Avatar from 'src/components/Avatar';
import Icon, { IName } from 'src/components/Icon';
import ModalInboxTemplate from 'src/components/messages/ModalInboxTemplate';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { ChatType, MessageType, SUCCESS } from 'src/utils';
import { onChatTypeTheme } from 'src/utils/helpers/checkChatType';

import {
  ButtonIcon,
  ChatInputContainer,
  Container,
  FilesAttachedContainer,
  InputContainer,
  ItemFilesAttached,
  ListFilesAttached,
  OptionsContainer,
  QuickReplyContainer,
  QuickReplyItem,
  SectionQuickReply,
  SendButton,
  SourceContainer,
  TextArea,
} from './styles';

interface Props {
  onMessageSend(): void;
}

function getIconName(type: MessageType, currentTheme: string): IName {
  if (!type) {
    return 'in-app';
  }

  switch (type) {
    case MessageType.SMS:
      return currentTheme === 'dark' ? 'white-sms' : 'sms';
    case MessageType.WHATSAPP:
      return currentTheme === 'dark' ? 'white-whatsapp' : 'whatsapp';
    default:
      return currentTheme === 'dark' ? 'white-in-app' : 'in-app';
  }
}

function Footer({ onMessageSend }: Props) {
  const messageCenterStore = store.messageCenter;
  const auth = store.auth;
  const themeStore = store.theme;

  const [showSources, setShowSources] = useState(false);
  const [message, setMessage] = useState('');
  const [source, setSource] = useState<IName>(
    getIconName(messageCenterStore.lastMessageType, themeStore.currentTheme),
  );
  const [loading, setLoading] = useState(false);
  const [messageTypeId, setMessageTypeId] = useState(1);
  const [messagesReplyList, setMessagesReplyList] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState<File>();
  const [listFilesAttached, setListFilesAttached] = useState<File[]>([]);
  const [openTemplateMessages, setOpenTemplateMessages] = useState(false);
  const [messageToSend, setMessageToSend] = useState('');

  const { isLoading } = useQuery(
    'messages-quick-reply',
    getMessagesQuickReplies,
    {
      onSuccess: (result) => {
        setMessagesReplyList(result.data);
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  const { mutate: mutateUpdateMessage } = useMutation(updateMessage, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      messageCenterStore.getConversationMessages(1);
      setLoading(false);
      setMessage('');
    },
    onError: ({ message }) => {
      Toastify.toast.error(message);
      setLoading(false);
    },
  });

  useEffect(() => {
    setSource(
      getIconName(messageCenterStore.lastMessageType, themeStore.currentTheme),
    );
  }, [messageCenterStore.lastMessageType, themeStore.currentTheme]);

  const onQuickReply = (text: string): void => setMessage(text);

  function handleSourceClick(name: IName) {
    switch (name) {
      case ChatType['in-app']:
      case ChatType['white-in-app']:
        setMessageTypeId(MessageType.IN_APP);
        break;
      case ChatType['whatsapp']:
      case ChatType['white-whatsapp']:
        setMessageTypeId(MessageType.WHATSAPP);
        break;
      case ChatType['sms']:
      case ChatType['white-sms']:
        setMessageTypeId(MessageType.SMS);
        break;
      default:
        break;
    }

    setSource(name);
    setShowSources(false);
  }

  function handleSendMessage(e: any) {
    const keyCode = e.which || e.keyCode;

    if (keyCode === 13 && !e.shiftKey) {
      onSendMessage();
    }
  }

  async function onSendMessage() {
    setLoading(true);
    onMessageSend();

    if (messageCenterStore.editMessage.edit) {
      const { converstationBodyId, messageBody, underReview } =
        messageCenterStore.editMessage;

      mutateUpdateMessage({
        converstationBodyId,
        messageBody: message,
        underReview,
      });
      messageCenterStore.setEditMessage(
        converstationBodyId,
        messageBody,
        false,
        underReview,
      );
    } else {
      try {
        if (messageToSend.length > 0) {
          await messageCenterStore.sendMessage(
            messageToSend,
            messageTypeId,
            filesUploaded,
          );

          setOpenTemplateMessages(false);
        } else {
          await messageCenterStore.sendMessage(
            message,
            messageTypeId,
            filesUploaded,
          );

          setMessageToSend('');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setListFilesAttached([]);
        setFilesUploaded(undefined);
        setLoading(false);
        setMessage('');
      }
    }
  }

  const onUploadFile = (e: any) => {
    setListFilesAttached([...e.target.files]);
    setFilesUploaded(e.target.files);
  };

  useEffect(() => {
    if (messageCenterStore.editMessage.edit) {
      setMessage(messageCenterStore.editMessage.messageBody);
    }
  }, [messageCenterStore.editMessage]);

  const onRemoveFileAttached = (index: number) => {
    const dt = new DataTransfer();
    const input = document.getElementById('upload');
    const { files } = input;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file); // exclude the file. thus removing it.
    }

    input.files = dt.files; // Assign the updates list

    setListFilesAttached([...input.files]);
    setFilesUploaded(input.files);
  };

  const onOpenTemplateMessages = () => {
    setOpenTemplateMessages(!openTemplateMessages);
  };

  const hasSelected = !!(messageCenterStore.conversationSelectedId > 0);

  return (
    <Container
      style={{
        pointerEvents:
          hasSelected ||
          messageCenterStore.isNewMessage ||
          messageCenterStore.isInboxCmsMessages
            ? 'initial'
            : 'none',
        opacity:
          hasSelected ||
          messageCenterStore.isNewMessage ||
          messageCenterStore.isInboxCmsMessages
            ? 1
            : 0.5,
      }}
      className="footer-mc-container"
    >
      <SectionQuickReply>
        <QuickReplyContainer>
          {!isLoading &&
            messagesReplyList.map((item: any) => (
              <QuickReplyItem
                key={item.id}
                onClick={() => onQuickReply(item.title)}
                disabled={isLoading}
              >
                {item.title}
              </QuickReplyItem>
            ))}
        </QuickReplyContainer>
        <FilesAttachedContainer>
          {listFilesAttached.length > 0 &&
            listFilesAttached.map((file: File, index: number) => {
              return (
                <div key={index}>
                  <ListFilesAttached>
                    <ItemFilesAttached>
                      {file.name.substring(0, 10)}
                      <span
                        className="close"
                        onClick={() => onRemoveFileAttached(index)}
                      >
                        &times;
                      </span>
                    </ItemFilesAttached>
                  </ListFilesAttached>
                </div>
              );
            })}
        </FilesAttachedContainer>
      </SectionQuickReply>

      <ChatInputContainer>
        <Avatar src={auth.userData.image} size="sm" />
        <InputContainer className="mc-input-container">
          <TextArea
            onFocus={() => setShowSources(false)}
            placeholder="Write messages down here…"
            value={messageToSend.length > 0 ? messageToSend : message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleSendMessage}
            className="mc-textarea"
            rows={5}
          />
          <OptionsContainer>
            <SourceContainer showSources={showSources}>
              <ButtonIcon
                onClick={() =>
                  handleSourceClick(
                    onChatTypeTheme(
                      ChatType['in-app'],
                      ChatType['white-in-app'],
                    ),
                  )
                }
              >
                <Icon name="chat" />
              </ButtonIcon>
              <ButtonIcon
                onClick={() =>
                  handleSourceClick(
                    onChatTypeTheme(
                      ChatType.whatsapp,
                      ChatType['white-whatsapp'],
                    ),
                  )
                }
              >
                <Icon name="whatsapp" />
              </ButtonIcon>
              <ButtonIcon
                onClick={() =>
                  handleSourceClick(
                    onChatTypeTheme(ChatType.sms, ChatType['white-sms']),
                  )
                }
              >
                <Icon name="sms" />
              </ButtonIcon>
            </SourceContainer>

            <SendButton
              onClick={() => onSendMessage()}
              sendMessage={loading}
              className="mc-send-button"
            >
              {!messageCenterStore.editMessage.edit ? 'SEND' : 'UPDATE'}
            </SendButton>

            <ButtonIcon onClick={() => setShowSources(!showSources)}>
              <Icon name={source} />
            </ButtonIcon>
            <ButtonIcon>
              <Icon name="emoji" />
            </ButtonIcon>
            <ButtonIcon onClick={() => onOpenTemplateMessages()}>
              <Icon
                name={
                  themeStore.currentTheme === 'dark' ? 'white-pencil' : 'pencil'
                }
              />
            </ButtonIcon>
            <input
              type="file"
              id="upload"
              onChange={(e) => onUploadFile(e)}
              hidden
              multiple
            />
            <label htmlFor="upload" style={{ position: 'relative', top: 5 }}>
              <Icon name="clip" />
            </label>
          </OptionsContainer>
        </InputContainer>
      </ChatInputContainer>

      {openTemplateMessages && (
        <ModalInboxTemplate
          openModal={openTemplateMessages}
          setOpenModal={setOpenTemplateMessages}
          setMessageToSend={setMessageToSend}
        />
      )}
    </Container>
  );
}

export default observer(Footer);
