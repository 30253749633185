import React, { useState, useEffect } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import { listReferrals, fetchReferralsByName } from 'src/api/referrals';
import request from 'src/api/request';
import {
  Layout,
  TableHead,
  UserReferralsRow,
  CustomPagination,
} from 'src/components';
import { SearchBar } from 'src/components/common';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import { usePagination } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { Container, Row, Col, Card, Button, Toastify } from 'src/modules';
import { ERRORS } from 'src/utils';

const UserReferrals: React.FC = () => {
  const [data, setData] = useState<UserReferralsTypes[]>([]);
  const [hasSearch, setHasSearch] = useState<boolean>(false);
  const [referral, setReferral] = useState<UserReferralsTypes[]>([]);
  const [query, setQuery] = useState('');

  const { changePage, changePerPage, hasPagination, isLoading, pagRes } =
    usePagination(listReferrals, setData, 'listReferrals');

  const queryClient = useQueryClient();

  const onSetReferral = async (value: number | string) => {
    try {
      let response;

      console.log('HERE', value);
      if (typeof value === 'string') {
        response = await request.get(`${API.USERS_REFERRALS}?search=${value}`);
      } else {
        response = await request.get(`${API.USERS_REFERRALS}?user_id=${value}`);
      }

      setHasSearch(true);
      setReferral(response.data.data);
    } catch {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    }
  };

  const onClearSearch = () => {
    setReferral([]);
    setHasSearch(false);
  };

  const {
    data: users = [],
    refetch,
    isFetching,
  } = useQuery('searchUserReferrals', () => fetchReferralsByName(query), {
    enabled: false,
  });

  useEffect(() => {
    if (query) {
      queryClient.cancelQueries('searchUserReferrals');
      refetch();
    }
  }, [query]);

  return (
    <Layout>
      <Dashboard title="User Referrals">
        <Row className="justify-content-end mb-4">
          <Button
            color="primary"
            size="sm"
            onClick={onClearSearch}
            disabled={!hasSearch}
          >
            Clear
          </Button>

          <Col xs="4">
            <SearchBar
              data={users.map((item) => ({ ...item, name: item.full_name }))}
              onSelect={onSetReferral}
              isUserReferrals
              onChange={setQuery}
              isLoading={isFetching}
            />
          </Col>
        </Row>

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="4">Name - Email - Referral Code</Col>
              <Col xs="2 d-flex justify-content-center">Total Clients</Col>
              <Col xs="2 d-flex justify-content-center">Total Earning</Col>
              <Col xs="2 d-flex justify-content-center">Referred By</Col>
              <Col xs="2 d-flex justify-content-center">Action</Col>
            </TableHead>

            {isLoading && <Loading />}
            {hasSearch ? (
              <>
                {referral.length > 0 ? (
                  referral.map((item: UserReferralsTypes) => (
                    <UserReferralsRow
                      key={`searched-referral-${item.id}`}
                      item={item}
                    />
                  ))
                ) : (
                  <Container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '20%',
                    }}
                  >
                    <h2 className="text-center my-4 text-black-50">
                      This user has no referral
                    </h2>
                  </Container>
                )}
              </>
            ) : (
              <>
                {data.map((item: UserReferralsTypes) => (
                  <UserReferralsRow
                    key={`user-referrals-${item.id}`}
                    item={item}
                  />
                ))}
              </>
            )}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={changePage}
            position="center"
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default UserReferrals;
