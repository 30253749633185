import React, { useState, useMemo } from 'react';

import {
  faEye,
  faPen,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  observer,
  Col,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'src/modules';
import store from 'src/stores';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

type Props = {
  id: number;
};

const MailSettingsRow: React.FC<Props> = ({ id }) => {
  const [mailSettings] = useState(store.mailSettings);

  const currentMail = mailSettings.mails.filter((mail) => mail.id === id)[0];

  const [form, setForm] = useState<Mail>(currentMail);
  const [disabled, setDisabled] = useState(true);
  const [showEmail, setShowEmail] = useState(false);

  const formChanged = useMemo(
    () =>
      form.title !== currentMail.title ||
      form.module !== currentMail.module ||
      form.comment !== currentMail.comment ||
      form.subject !== currentMail.subject ||
      form.from_text !== currentMail.from_text ||
      form.from_email !== currentMail.from_email ||
      form.to_email !== currentMail.to_email,
    [form],
  );

  const handleUpdate = async () => {
    try {
      await mailSettings.updateMail(form);
    } catch {
      // showAlert({ message: ERRORS.GENERIC_ERROR });
    }
  };

  const handleEditButton = async () => {
    if (formChanged) {
      await handleUpdate();
    }

    setDisabled((pevState) => !pevState);
  };

  const handleChange = (key: string, value: string) =>
    setForm((prevState) => ({ ...prevState, [key]: value }));

  const toggleModal = () => setShowEmail((prevState) => !prevState);

  return (
    <TableRow>
      <Col xs="4" className="mt-2 pl-4 d-flex flex-column">
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>Title:</b>
          </p>
          <Input
            type="text"
            name="title"
            value={form.title}
            disabled={disabled}
            onChange={(e) => handleChange('title', e.target.value)}
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>Module:</b>
          </p>
          <Input
            type="text"
            name="module"
            value={form.module}
            disabled={disabled}
            onChange={(e) => handleChange('module', e.target.value)}
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>Comment:</b>
          </p>
          <Input
            type="text"
            name="comment"
            value={form.comment}
            disabled={disabled}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </div>
      </Col>
      <Col xs="3" className="mt-2 pl-4 d-flex flex-column">
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>Subject:</b>
          </p>
          <Input
            type="text"
            name="subject"
            value={form.subject}
            disabled={disabled}
            onChange={(e) => handleChange('subject', e.target.value)}
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>From:</b>
          </p>
          <Input
            type="text"
            name="from"
            value={form.from_text}
            disabled={disabled}
            onChange={(e) => handleChange('from_text', e.target.value)}
          />
        </div>
      </Col>
      <Col xs="3" className="mt-2 pl-4 d-flex flex-column">
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>From Email:</b>
          </p>
          <Input
            type="text"
            name="fromEmail"
            value={form.from_email}
            disabled={disabled}
            onChange={(e) => handleChange('from_email', e.target.value)}
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <p className="pt-1 m-0">
            <b>To Email:</b>
          </p>
          <Input
            type="text"
            name="toEmail"
            value={form.to_email}
            disabled={disabled}
            onChange={(e) => handleChange('to_email', e.target.value)}
          />
        </div>
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={handleEditButton}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon
              icon={disabled ? faPen : formChanged ? faCheck : faTimes}
            />
          </ButtonIcon>
          <ButtonIcon
            onClick={toggleModal}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon icon={faEye} />
          </ButtonIcon>
        </Row>
      </Col>
      <Modal className="modal-dark" isOpen={showEmail} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {form.from_email} - {form.subject}
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: form.content }} />
      </Modal>
    </TableRow>
  );
};

export default observer(MailSettingsRow);
