import { styled, css } from 'src/modules';

interface ContainerProps {
  isDragging: boolean;
}

interface Props {
  width?: number;
  bold?: boolean;
  topSpacing?: boolean;
  profileOnline?: boolean;
  background?: string;
  color?: string;
  position?: string;
  top?: number;
  zIndex?: number;
  isMinimized?: boolean;
  isDisabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white.default};
  align-items: center;
  overflow: hidden;
  position: relative;

  ${({ isDragging }) =>
    !isDragging &&
    css`
      transition: width 0.2s ease-in-out;
    `}
`;

export const UerInfoContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: ${(props) => (props.isMinimized ? '90px' : '230px')};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.l}px;
  border: 1px solid ${({ theme }) => theme.colors.black.default};
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  background: ${(props) => props.background};
  position: ${(props) => props.position};
`;

export const DraggableInfoContent = styled(UerInfoContent)`
  position: ${(props) => props.isMinimized && props.position};
  right: ${(props) => props.isMinimized && '10px'};
  top: ${(props) => props.isMinimized && '10px'};
  z-index: ${(props) => props.zIndex};
  transition: top 1s;
`;

export const ProfilePicture = styled.div<Props>`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.s}px;
  display: ${(props) => (props.isMinimized ? 'none' : 'block')};
`;

export const ProfilePictureStatus = styled.div<Props>`
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ profileOnline, theme }) =>
    profileOnline ? theme.colors.green.main : theme.colors.gray.ghost};
`;

export const UserName = styled.span<Props>`
  /* color: ${({ theme }) => theme.colors.black.default}; */
  color: ${(props) => props.color};
  font-size: ${({ theme }) => theme.fontSizes.title.l}px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  display: ${(props) => (props.isMinimized ? 'none' : 'block')};
`;

export const InfoText = styled.span<Props>`
  color: ${({ theme }) => theme.colors.black.default};
  font-size: ${({ theme }) => theme.fontSizes.title.s}px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-top: ${({ topSpacing, theme }) =>
    topSpacing ? theme.spacing.xs : 0}px;
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.m}px;
`;

export const ActionButton = styled.button<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  justify-content: center;
  /* background: none !important ; */
  background: ${(props) => props.background};
  border: none !important ;
  outline: none !important;

  img {
    transition: all 0.2s ease-in-out;
  }

  :hover img {
    transform: scale(1.2);
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.s}px;
  padding: 0 ${({ theme }) => theme.spacing.xxl}px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray.iron};
  }

  button {
    background: none !important ;
    border: none !important ;
    outline: none !important;
  }
`;

export const FileSectionText = styled.span`
  color: ${({ theme }) => theme.colors.black.default};
  font-size: ${({ theme }) => theme.fontSizes.title.m}px;
  margin: ${({ theme }) => theme.spacing.s}px 0;
  font-weight: 600;
`;

export const FileContainer = styled.div<Props>`
  display: flex;
  width: ${({ width = 0 }) => width - 104}px;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.s}px 0;
  cursor: pointer;
`;

export const FileName = styled.span`
  color: ${({ theme }) => theme.colors.black.default};
  font-size: ${({ theme }) => theme.fontSizes.title.m}px;
  padding: 0 ${({ theme }) => theme.spacing.s}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

export const ImagesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const Image = styled.img`
  height: 85px;
  width: 85px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(1.01);
    box-shadow: 2px 2px 8px ${({ theme }) => theme.colors.gray.regent};
  }
`;

export const ActionCallButton = styled.button<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: ${(props) => props.background}; */
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.gray.regent : props.background};
  border: none !important ;
  outline: none !important;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0 10px;

  img {
    transition: all 0.2s ease-in-out;
  }

  :hover img {
    transform: scale(1.2);
  }
`;

export const PopUpImage = styled.img`
  position: absolute;
  left: 15px;
  top: 10px;
`;

export const InvalidNumber = styled.span`
  position: relative;
  top: 10px;
  color: ${({ theme }) => theme.colors.red.main};
`;
