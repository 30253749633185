import React, { useEffect, useState, ChangeEvent } from 'react';

import { TbFileSpreadsheet } from 'react-icons/tb';
import { useQuery } from 'react-query';

import { getPersonalInformation } from 'src/api/users';
import {
  Toastify,
  Form,
  Button,
  Input,
  ModalHeader,
  ModalBody,
  Modal,
  Col,
  Row,
  styled,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { ERRORS, getValidData, ProofTitle } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';
import {
  CustomerInformation,
  CustomerBankInformation,
  CustomerProofId,
} from 'src/utils/types/Customer';

import { Loading } from '../../ui';

import BankInformation from './fragments/BankInformation';
import BankInformationPie from './fragments/BankInformationPie';
import OtherInformation from './fragments/OtherInformation';
import PersonalInformationPie from './fragments/PersonalInformationPie';
import { ProofAddress } from './fragments/ProofAddress';
import ProfPie from './fragments/ProofPie';
import FooterProofPie from './fragments/ProofPie/FooterProofPie';
import SubscriptionDetails from './fragments/SubscriptionDetails';
import UploadProof from './fragments/UploadProof';
import UploadProofAddress from './fragments/UploadProofAddress';
import {
  Wrapper,
  WrapperTabHeader,
  WrapperContent,
  SectionTitle,
  Content,
  TableData,
  AddLogoButton,
} from './styles';

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 60px;
`;

const footerProofMock = {
  id: 1,
  sanctionsList: 'Unapproved',
  dateSubmitted: '08/10/2021 - 13:19',
  personOfSignificantInterest: 'No',
  dateSubmittedPerson: '08/10/2021 - 10:08',
};

type Props = {
  userInfo: CustomerInformation;
  userId: number;
};

const UserDetailsTab: React.FC<Props> = ({ userInfo, userId }) => {
  // store
  const customer = store.customer;
  const { isPIE } = store.pieLayout;

  // states
  const [cannotSeeBankInfo] = useState<boolean>(false);
  const [cannotEditBankInfo, setCannotEditBankInfo] = useState<boolean>(true);
  const [data, setData] = useState<CustomerInformation>(userInfo);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [switchLabel, setSwitchLabel] = useState<string>(ProofTitle.VERIFIED);
  const [image, setImage] = useState<File>();
  const [password, setPassword] = useState<string>('');
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
  const [sendToCustomer, setSendToCustomer] = useState(true);
  const [banks, setBanks] = useState<CustomerBankInformation[]>([]);
  const [proofId, setProofId] = useState<CustomerProofId>();

  const { data: dataPersonalInfo, isLoading: isLoadingPersonalInfo } = useQuery(
    'getPersonalInformation',
    () => getPersonalInformation(userId),
  );

  const handlePageLoad = async () => {
    try {
      setLoading(true);
      const user = await Storage.getUser();

      const isSuperAdmin = handleSuperAdminRole(user?.role_id);

      if (isSuperAdmin) {
        setCannotEditBankInfo(false);
      }

      setSwitchLabel(data.id_proof_title || ProofTitle.VERIFIED);

      const customerProofIds = await customer.getUserProofId(userId);
      setProofId(customerProofIds);

      const customerBanks = await customer.getUserBanksInformation(userId);
      setBanks(customerBanks);
    } catch (error) {
      console.error('customer information tabs', error);
    } finally {
      setLoading(false);
    }
  };

  const editInfo = async (): Promise<void> => {
    if (!disabled) {
      const prefix: string = data.prefix || 'Mr';
      let payload = data;
      payload.prefix = prefix;

      const formData: FormData = new FormData();
      formData.append('prefix', prefix);
      formData.append('sendToCustomer', getValidData(sendToCustomer));
      formData.append('first_name', getValidData(data.first_name));
      formData.append('middle_name', getValidData(data.middle_name));
      formData.append('last_name', getValidData(data.last_name));
      formData.append('insurance_no', getValidData(data.insurance_no));
      formData.append('company_name', getValidData(data.company_name));
      formData.append('company_address', getValidData(data.company_address));
      formData.append(
        'company_postal_code',
        getValidData(data.company_postal_code),
      );
      formData.append('vat_no', getValidData(data.vat_no));
      formData.append(
        'account_holder_name',
        getValidData(data.account_holder_name),
      );
      formData.append(
        'account_sort_code',
        getValidData(data.account_sort_code),
      );
      formData.append('account_number', getValidData(data.account_number));
      formData.append('email', getValidData(data.email));
      formData.append('postal_code', getValidData(data.postal_code));
      formData.append('address', getValidData(data.address));
      formData.append('phone', getValidData(data.phone));
      formData.append('dob', getValidData(data.dob));
      formData.append('utr_no', getValidData(data.utr_no));
      formData.append('employee_type', getValidData(data.employee_type));

      if (password && password.length < 8) {
        Toastify.toast.error(ERRORS.PASSWORD_VALIDATIONS, { autoClose: 10000 });
        return;
      }

      if (password && password.length >= 8 && passwordsMatch) {
        formData.append('password', password);
      }

      if (image) {
        formData.append('company_logo', image);
      }

      payload = formData;

      try {
        await customer.updateUserDetails(userId, payload);
      } catch (error: any) {
        Toastify.toast.error(error.error, { autoClose: 10000 });
      } finally {
        setLoading(false);
      }
    }
    setDisabled(!disabled);
  };

  const updateInfo = (item: any) => {
    const target: keyof CustomerInformation =
      item.getAttribute('id') || item.getAttribute('name');

    const newValue =
      item.type === 'radio' || item.type === 'checkbox'
        ? item.nextSibling.textContent
        : item.value;

    const newData: CustomerInformation = data;

    newData[target as keyof CustomerInformation] = newValue;
    setData(newData);
  };

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = e.target.files;
    if (file) {
      if (file?.length < 1) return;

      const src: string = URL.createObjectURL(file[0]);

      setImage(file[0]);

      const newData: CustomerInformation = data;
      newData.company_logo = src;
      setData(newData);
    }
  };

  const toogleProofTitle = (checked: boolean) => {
    const newValue: string = checked
      ? ProofTitle.VERIFIED
      : ProofTitle.NOT_VERIFIED;

    setSwitchLabel(newValue);
    const newData: CustomerInformation = data;
    newData.id_proof_title = newValue;
    setData(newData);
  };

  const checkPasswordMatch = (value: string): void => {
    if (value === password) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const onCancel = () => setDisabled(true);

  const sendToCustomerValue = (item: boolean) => setSendToCustomer(item);

  const inputFileClick = () => document.getElementById('companyLogo')?.click();

  const onAddressModal = () => setShowAddressModal((oldValue) => !oldValue);

  const onFindAddress = async (): Promise<void> => {
    setShowAddressModal(true);

    let res: any = await fetch(
      `https://api.getAddress.io/find/${data.postal_code}?api-key=${process.env.GATSBY_GETADDRESS}`,
    );
    res = await res.json();

    const getAddressList: string[] = [];

    res.addresses.forEach((address: any) => {
      const a2 = address.split(',');
      let format = '';
      a2.forEach((item: string) => {
        item = item.trim();
        if (item.length > 0) format += item + ', ';
      });
      format = format.slice(0, -2);
      getAddressList.push(format);
    });

    setAddressList(getAddressList);
  };

  const onSelectAddress = (address: string) => {
    const newData: CustomerInformation = data;
    newData.address = address;
    setData(newData);
    setShowAddressModal(false);
  };

  useEffect(() => {
    handlePageLoad();
  }, []);

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <Wrapper className="tab-content printable">
          <WrapperTabHeader>
            <Button color="primary" onClick={editInfo}>
              {disabled ? 'Edit' : 'Update'}
            </Button>
            {!disabled && (
              <Button color="primary" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </WrapperTabHeader>

          <WrapperContent>
            <Form onSubmit={(e) => e.preventDefault()}>
              <SectionTitle>Personal Information</SectionTitle>
              <Content>
                <tbody
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 auto',
                  }}
                >
                  <PersonalInformationPie
                    customer={dataPersonalInfo}
                    isLoading={isLoadingPersonalInfo}
                    disabled={disabled}
                    updateInfo={updateInfo}
                    onFindAddress={onFindAddress}
                  />

                  {/* <PersonalInformation
                      data={data}
                      disabled={disabled}
                      updateInfo={updateInfo}
                      checkPasswordMatch={checkPasswordMatch}
                      sendToCustomerValue={sendToCustomerValue}
                      onFindAddress={onFindAddress}
                      passwordsMatch={passwordsMatch}
                      tooltipOpen={tooltipOpen}
                      toggle={toggle}
                      setPassword={setPassword}
                    /> */}

                  <tr>
                    <TableData colSpan={4}>
                      <SectionTitle>Subscription Details</SectionTitle>
                    </TableData>
                  </tr>

                  <SectionContainer>
                    <SubscriptionDetails personalInfo={dataPersonalInfo} />
                  </SectionContainer>

                  {!cannotSeeBankInfo && (
                    <>
                      <tr>
                        <TableData colSpan={4}>
                          <SectionTitle>Bank Information</SectionTitle>
                        </TableData>
                      </tr>

                      {isPIE ? (
                        <SectionContainer>
                          {banks.map((bank, index) => (
                            <div
                              key={`bank-${bank.bank_name}-${index}`}
                              style={{ marginRight: 100, width: '25%' }}
                            >
                              <BankInformationPie bank={bank} />
                            </div>
                          ))}
                        </SectionContainer>
                      ) : (
                        <BankInformation
                          data={data}
                          updateInfo={updateInfo}
                          disabled={disabled}
                          cannotEditBankInfo={cannotEditBankInfo}
                        />
                      )}
                    </>
                  )}

                  <tr>
                    <TableData colSpan={4} align="left">
                      <SectionTitle>Proof</SectionTitle>
                    </TableData>
                  </tr>

                  {isPIE && proofId && dataPersonalInfo ? (
                    <div style={{ width: '100%' }}>
                      <SectionContainer>
                        {proofId.has_proof_id ? (
                          <ProfPie
                            data={proofId}
                            updateInfo={updateInfo}
                            disabled={disabled}
                            userId={userId}
                          />
                        ) : (
                          <UploadProof />
                        )}
                        {proofId.has_address_proof ? (
                          <ProofAddress
                            data={proofId}
                            updateInfo={updateInfo}
                            disabled={disabled}
                            userId={userId}
                          />
                        ) : (
                          <UploadProofAddress />
                        )}
                      </SectionContainer>

                      <FooterProofPie
                        sanctioned={dataPersonalInfo?.sanctioned}
                        sanctionedPercentage={
                          dataPersonalInfo?.sanction_similarity_percentage
                        }
                        data={footerProofMock}
                        disabled={disabled}
                      />
                    </div>
                  ) : (
                    <OtherInformation
                      data={data}
                      disabled={disabled}
                      switchLabel={switchLabel}
                      toogleProofTitle={toogleProofTitle}
                    />
                  )}
                </tbody>
              </Content>
            </Form>
          </WrapperContent>
        </Wrapper>
      )}

      {showAddressModal && (
        <Modal centered isOpen={showAddressModal} toggle={onAddressModal}>
          <ModalHeader toggle={onAddressModal}>Find Address</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={3}>Postel Code</Col>

              <Col xs={6}>
                <Input
                  id="postal_code"
                  className="w-100"
                  type="text"
                  defaultValue={data.postal_code}
                  disabled={disabled}
                  onChange={(e) => updateInfo(e.target)}
                />
              </Col>

              <Col xs={3} className="d-flex justify-content-end">
                <button className="btn btn-primary" onClick={onFindAddress}>
                  Find Adress
                </button>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={3}>Select Address</Col>
              <Col xs={9}>
                {addressList.length < 1 && '...'}
                {addressList.map((item: string, index: number) => (
                  <div
                    key={index}
                    className="border p-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => onSelectAddress(item)}
                  >
                    {item}
                  </div>
                ))}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default UserDetailsTab;
