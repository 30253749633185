import styled from 'styled-components';

export const FooterSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SubmitSection = styled.div`
  margin-top: 30px;
`;

export const YearSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > label {
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    width: 100%;
    max-width: 150px;
  }
`;

export const PrevNextFields = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0 20px;
`;

export const CategoryMileageSection = styled.div`
  display: flex;
`;

export const CategorySection = styled.div`
  margin-right: 20px;
`;
