import React, { useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { Container, Input, Row, Col, Button, Form } from 'src/modules';

import { Loading } from '../ui';

const AddHowYouHere: React.FC = ({ onClose, updateData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [image, setImage] = useState(null);

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = e.target.files;
    if (file.length > 0) setImage(file[0]);
  };

  const submitBtn = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (name.length < 1 || !image) return;

      const formData: FormData = new FormData();
      formData.append('image', image);
      formData.append('name', name);
      const { data } = await request.post(
        `${API.PATH}${API.HOW_YOU_HERE_CREATE}`,
        formData,
      );

      const formattedData = { ...data, status: 'Enable' };
      updateData(formattedData);
      onClose();
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mw-100 mb-3">
      {loading && (
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      )}

      <Form>
        <Row className="align-items-center">
          <Col xs="1">Name</Col>
          <Col>
            <Input
              bsSize="lg"
              type="text"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Col>

          <Col xs="1">Image</Col>
          <Col xs="3">
            <Input type="file" onChange={imageHandle} />
          </Col>

          <Col xs="2">
            <div className="d-flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-4"
                onClick={submitBtn}
              >
                SUBMIT
              </Button>

              <Button
                type="reset"
                color="transparent"
                onClick={onClose}
                className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddHowYouHere;
