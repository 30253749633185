import { styled, css } from 'src/modules';

interface Props {
  size?: number;
  noResize?: boolean;
  isActive?: boolean;
}

export const IconContent = styled.img<Props>`
  opacity: ${(props) => (props.isActive ? 1 : 0.35)};
  cursor: pointer;

  ${({ noResize, size }) =>
    !noResize &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}
`;
