import React, { memo, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { TStatus } from 'src/models';
import { CustomInput } from 'src/modules';

type Props = {
  id: number;
  status?: TStatus;
};

const IconCustomerStatus: React.FC<Props> = ({ id, status }) => {
  const [local, setLocal] = useState<boolean>(status === 'Enable');

  const onToggle = async () => {
    const next = !local;
    setLocal(next);

    try {
      const { status } = await request.put(API.USERS_CHANGE_STATUS, {
        user_id: id,
      });

      if (status !== 200) {
        setLocal(!next);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  return (
    <CustomInput
      type="checkbox"
      id={`input${id}`}
      name="customSwitch"
      checked={local}
      onChange={onToggle}
    />
  );
};

export default memo(IconCustomerStatus);
