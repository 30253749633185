import React from 'react';

import { Input } from 'src/modules';
import { CustomerInformation } from 'src/utils/types/Customer';

import { TableData, TableRow, LabelData } from '../styles';

type Props = {
  data: CustomerInformation;
  updateInfo(item: any): void;
  disabled: boolean;
  cannotEditBankInfo: boolean;
};

const BankInformation: React.FC<Props> = ({
  data,
  updateInfo,
  disabled,
  cannotEditBankInfo,
}) => {
  return (
    <>
      <TableRow>
        <TableData alignRight>
          <LabelData for="account_holder_name">Account Holder Name :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="account_holder_name"
            type="text"
            defaultValue={data.account_holder_name}
            disabled={cannotEditBankInfo || disabled}
            onChange={(e) => updateInfo(e.target)}
          />
        </TableData>
        <TableData alignRight>
          <LabelData for="account_number">Account Number :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="account_number"
            type="text"
            defaultValue={data.account_number}
            disabled={cannotEditBankInfo || disabled}
            onChange={(e) => updateInfo(e.target)}
          />
        </TableData>
      </TableRow>
      <TableRow>
        <TableData alignRight>
          <LabelData for="vat_no"> VAT No :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="vat_no"
            type="text"
            defaultValue={data.vat_no || ''}
            disabled={cannotEditBankInfo || disabled}
            onChange={(e) => updateInfo(e.target)}
          />
        </TableData>
        <TableData alignRight>
          <LabelData for="account_sort_code"> Account Sort Code :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="account_sort_code"
            type="text"
            defaultValue={data.account_sort_code}
            disabled={cannotEditBankInfo || disabled}
            onChange={(e) => updateInfo(e.target)}
          />
        </TableData>
      </TableRow>
    </>
  );
};

export default BankInformation;
