import styled from 'styled-components';

export const PaymentDueContainer = styled.div`
  position: relative;
`;

export const CancelPaymentText = styled.span`
  font-size: 8px;
  text-transform: uppercase;
`;

export const CancelIcon = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  border: solid thin ${({ theme }) => theme.colors.gray.regent};
  border-radius: 50%;
  font-size: 0.8em;
  bottom: 0;
  color: ${({ theme }) => theme.colors.blue.fiord};
  background-color: ${({ theme }) => theme.colors.gray.ghost};
  text-align: center;
  top: -5px;
  right: 0;
  cursor: pointer;
`;
