import styled from 'styled-components';

export const PayeButton = styled.button`
  display: flex;
  background: #57e4cd;
  color: #191424;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 48px;
  padding: 6px 24px;
  margin-right: 30px;

  &:last-child {
    margin-right: initial;
  }
`;
