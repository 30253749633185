import React from 'react';

import { AssistantsData } from 'src/utils/staffDataset';

type Props = {
  item: AssistantsData;
  index: number;
  type: string;
};

const StaffRowHMRCSplit: React.FC<Props> = ({ item, index, type }: Props) => {
  const returnSubmit = (val: any, index: number) => {
    if (type == 'Submit') {
      return (
        <td key={`count-${val[0]['HMRC_SUBMIT']}-${index}`}>
          {val[0]['HMRC_SUBMIT'] ? (
            val[0]['HMRC_SUBMIT']
          ) : (
            <span className="opacity-2">{0}</span>
          )}
        </td>
      );
    }

    if (type == 'Modify') {
      return (
        <td key={`count-${val[0]['HMRC_MODIFY']}-${index}`}>
          {val[0]['HMRC_MODIFY'] ? (
            val[0]['HMRC_MODIFY']
          ) : (
            <span className="opacity-2">{0}</span>
          )}
        </td>
      );
    }
  };
  return (
    <>
      <tr>
        <td className="text-left">{item.assistant}</td>
        {item && item?.values.map((val, index) => returnSubmit(val, index))}
        <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
          <strong>
            {type == 'Submit' ? item.totalSubmit : item.totalModify}
          </strong>
        </td>
      </tr>
    </>
  );
};

export default StaffRowHMRCSplit;
