import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import moment from 'moment';
import { useDropzone } from 'react-dropzone';

import { faRedo, faCropAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import { Cropper } from 'src/components/common';
import API from 'src/data/API';
import { TIncomeFileType } from 'src/models';
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Toastify,
} from 'src/modules';
import {
  ERRORS,
  formatDate,
  getTaxYearEnd,
  getTaxYearStart,
  notNullOrUndefined,
  TaxIncomeTypes,
  validNumber,
} from 'src/utils';

import TaxIncomeNewItem from '../TaxIncomeNewItem';
import { Loading } from '../ui';

import { StyledRow, ImageContainer, Image } from './styles';

interface Props {
  document?: TaxDocumentTypes;
  user_id: number;
  year: number;
  onUpdateData: any;
  canEdit?: boolean;
  editMode?: boolean;
}

const getDate = (doc: TaxDocumentTypes, year: number) => {
  const df = 'YYYY-MM-DD';
  if (doc && doc.income_date) {
    return formatDate(doc.income_date, 'DD/MM/YYYY', df);
  }
  const date = getTaxYearEnd(year);
  return moment(date).format(df);
};

const TaxIncomeAddNew: React.FC<Props> = ({
  document,
  editMode,
  canEdit,
  user_id,
  year,
  onUpdateData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<TaxIncomeTypes | string>(
    TaxIncomeTypes.Other,
  );
  const [date, setDate] = useState(getDate(document, year));
  const [items, setItems] = useState<TaxDocumentTypes[]>([]);
  const [image, setImage] = useState<string>();
  const [fileType, setFileType] = useState<TIncomeFileType>();
  const [fileImage, setFileImage] = useState<File | null>(null);
  const [editImage, setEditImage] = useState(false);

  const cropperRef = useRef();

  const onDrop = useCallback((acceptedFiles) => {
    setFileImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: !!fileImage,
  });

  // @TODO: Move to mobx

  const hasDocument = useMemo(() => !!document?.type, [document]);

  const loadDocuments = async () => {
    try {
      setLoading(true);
      if (!document || !document.id) return;

      if (hasDocument) {
        const documentType = TaxIncomeTypes[document.type];
        setType(documentType);
      }

      const docURL = `${API.PATH}${API.USERS_TAX_INCOME_DATA}?id=${document.id}&tax_year=${year}&type=${document.type}`;

      const { data } = await request.get(docURL);
      const response = data?.data;

      if (response.income_doc_items && response.income_doc_items.length) {
        setItems(response.income_doc_items);
      }

      // @TODO: Use ENUM
      setFileType(response.file_type.toUpperCase() === 'PDF' ? 'PDF' : 'IMAGE');
      setImage(response.file_url);
      setDate(getDate(document, year));
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const onItemChange = (index: number, newData) => {
    if (type === TaxIncomeTypes.Paye) {
      delete newData.tax_percentage;
      delete newData.net_amount;
    }
    if (type === TaxIncomeTypes.Self) {
      delete newData.paye_ref;
    }

    const newItems = [...items];
    newItems[index] = newData;
    setItems(newItems);
  };

  const onAddNewItem = () => {
    const newItem = {} as TaxDocumentTypes;
    const all = [...items, newItem];
    setItems(all);
  };

  const onRemoveItem = (index: number) => {
    const all = [...items];
    all.splice(index, 1);
    setItems(all);
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      const postDate = moment(date).format('DD/MM/YYYY');

      const min = moment(getTaxYearStart(year), 'YYYY-MM-DD');
      const max = moment(getTaxYearEnd(year), 'YYYY-MM-DD');
      const inRange = moment(date).isBetween(min, max, undefined, '[]');

      if (!inRange) {
        Toastify.toast.error(ERRORS.DATE_OUT_OF_RANGE);
        return;
      }

      const formData: FormData = new FormData();
      formData.append('income_date', postDate);
      formData.append('type', type);

      const croppedImage = await cropperRef.current?.getCroppedImage();

      if (fileImage) {
        formData.append('image', fileImage);
      }

      if (croppedImage) {
        formData.append('image', croppedImage);
      }

      for (let index = 0; index < items.length; index++) {
        if (items[index].id) {
          formData.append(`item[${index}][id]`, String(items[index].id));
        }

        notNullOrUndefined(items[index].title) &&
          formData.append(`item[${index}][title]`, items[index].title);
        notNullOrUndefined(items[index].paye_ref) &&
          formData.append(`item[${index}][paye_ref]`, items[index].paye_ref);
        validNumber(items[index].gross_amount) &&
          formData.append(
            `item[${index}][gross_amount]`,
            String(items[index].gross_amount),
          );

        validNumber(items[index].net_amount) &&
          formData.append(
            `item[${index}][net_amount]`,
            String(items[index].net_amount),
          );
        validNumber(items[index].tax_amount) &&
          formData.append(
            `item[${index}][tax_amount]`,
            String(items[index].tax_amount),
          );

        if (type === TaxIncomeTypes.Self) {
          validNumber(items[index].tax_percentage) &&
            formData.append(
              `item[${index}][tax_percentage]`,
              String(items[index].tax_percentage),
            );
          notNullOrUndefined(items[index].add_tax) &&
            formData.append(
              `item[${index}][add_tax]`,
              String(items[index].add_tax),
            );
        }

        //   for(var pair of formData.entries()) {
        // 	console.log(pair[0]+ ', '+ pair[1], validNumber(pair[1]));
        //  }
      }
      // console.log(items)

      let postURL = `${API.PATH}${API.USERS_TAX_INCOME_ADD}?user_id=${user_id}&tax_year=${year}`;
      if (editMode)
        postURL = `${API.PATH}${API.USERS_TAX_INCOME_EDIT}?income_doc_id=${document.id}`;

      await request.post(postURL, formData);

      setFileImage(null);
      setImage(undefined);
      setItems([]);
      onUpdateData();
      loadDocuments();
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const showCropper = () => {
    if (fileType === 'PDF') return false;
    if (fileImage && fileImage?.path.includes('.pdf')) return false;
    if (!canEdit) return false;
    return true;
  };

  useEffect(() => {
    loadDocuments();
    onAddNewItem();
  }, [document]);

  return (
    <>
      {image &&
        fileType === 'IMAGE' &&
        (editImage ? (
          <Cropper
            ref={cropperRef}
            sourceImage={image}
            onCancel={() => setFileImage(null)}
          />
        ) : (
          <ImageContainer>
            <a href={image} target="_blank" rel="noreferrer">
              <Image
                className="w-100 mb-3"
                src={fileImage ? URL.createObjectURL(fileImage) : image}
                alt="Image"
              />
            </a>
          </ImageContainer>
        ))}
      {image && fileType === 'PDF' && (
        <div className="mb-3">
          <div
            className="w-100 position-relative"
            style={{ paddingBottom: '133%' }}
          >
            <iframe
              src={image}
              className="w-100 h-100 position-absolute"
              frameBorder="0"
            />
          </div>
          <a href={image} target="_blank" rel="noreferrer">
            Open in new tab
          </a>
        </div>
      )}

      {!editMode && !image && (
        <div
          className="bg-light border d-flex justify-content-center align-items-center overflow-hidden"
          style={fileImage?.path.includes('.pdf') ? {} : { minHeight: 200 }}
          {...getRootProps()}
        >
          {fileImage ? (
            fileImage?.path.includes('.pdf') ? (
              <iframe
                src={URL.createObjectURL(fileImage)}
                width="100%"
                height="500px"
              ></iframe>
            ) : editImage ? (
              <Cropper
                ref={cropperRef}
                sourceImage={fileImage}
                onCancel={() => setFileImage(null)}
                onSuccess={console.log}
              />
            ) : (
              <a
                href={image}
                target="_blank"
                rel="noreferrer"
                style={{ overflow: 'scroll' }}
              >
                <Image
                  className="w-100 mb-3"
                  src={URL.createObjectURL(fileImage)}
                  alt="Image"
                />
              </a>
            )
          ) : (
            'Drag & Drop Image Here'
          )}
        </div>
      )}

      {!editMode && showCropper() && (
        <StyledRow>
          <Button>
            <FontAwesomeIcon
              icon={faCropAlt}
              onClick={() => setEditImage((prevState) => !prevState)}
            />
          </Button>
          {editImage && (
            <Button>
              <FontAwesomeIcon
                icon={faRedo}
                onClick={() => cropperRef.current?.rotate()}
              />
            </Button>
          )}
          {fileImage && (
            <Button onClick={() => setFileImage(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </StyledRow>
      )}

      {editMode && image && canEdit && (
        <div
          {...getRootProps()}
          className="d-flex justify-content-center align-items-center overflow-hidden"
          style={{ marginBottom: 20 }}
        >
          <Button color="primary">
            <input {...getInputProps()} />

            <p>CHANGE IMAGE</p>
          </Button>
        </div>
      )}

      {loading && <Loading />}
      <div className="d-flex justify-content-center align-items-center mt-1">
        {canEdit && (
          <input
            disabled={!canEdit}
            min={getTaxYearStart(year)}
            max={getTaxYearEnd(year)}
            type="date"
            className="p-1 mr-2"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        )}

        <ListGroup horizontal className="mt-3">
          <ListGroupItem
            className={`px-2 py-1 ${type === 'Paye' ? 'active text-dark' : ''}`}
            style={canEdit ? { cursor: 'pointer' } : null}
            onClick={canEdit ? () => setType('Paye') : null}
          >
            Paye
          </ListGroupItem>
          <ListGroupItem
            className={`px-2 py-1 ${type === 'Self' ? 'active text-dark' : ''}`}
            style={canEdit ? { cursor: 'pointer' } : null}
            onClick={canEdit ? () => setType('Self') : null}
          >
            Self
          </ListGroupItem>
          <ListGroupItem
            className={`px-2 py-1 ${
              type === 'Other' ? 'active text-dark' : ''
            }`}
            style={canEdit ? { cursor: 'pointer' } : null}
            onClick={canEdit ? () => setType('Other') : null}
          >
            Other
          </ListGroupItem>
        </ListGroup>
      </div>

      {items.map((item, index) => (
        <TaxIncomeNewItem
          key={`item-${item.id || index}`}
          canEdit={canEdit}
          type={type}
          data={item}
          index={index}
          onChange={onItemChange}
          onRemoveItem={onRemoveItem}
        />
      ))}

      {canEdit && !loading && (
        <Row className="pr-2 justify-content-end">
          <Button color="secondary" outline onClick={onAddNewItem}>
            +
          </Button>
        </Row>
      )}

      {canEdit && (
        <Row className="mt-2">
          <Col>
            <Button
              style={{ minWidth: 120 }}
              color="primary"
              className="mb-4 mx-auto"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TaxIncomeAddNew;
