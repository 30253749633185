import React, { useState } from 'react';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Row, Col, Button, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const QuestionLibraryRow: React.FC = ({
  question,
  year,
  onStatusChange,
  active,
  value,
  updateQuestion,
  deleteJob,
  yearOptions,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [newQuestion, setNewQuestion] = useState(question);
  const [newYear, setNewYear] = useState(year);

  const toggle = () => {
    setDisabled((disabled) => !disabled);
  };

  const update = () => {
    toggle();
    updateQuestion(newQuestion, newYear);
  };

  return (
    <TableRow className="align-items-center">
      <Col className="d-flex align-items-center justify-content-center">
        <Input
          type="text"
          name="question"
          defaultValue={question}
          disabled={disabled}
          onChange={(e) => setNewQuestion(e.target.value)}
        />
      </Col>
      <Col xs="2" className="d-flex align-items-center justify-content-center">
        {disabled ? (
          <Input type="text" name="year" value={year} disabled={true} />
        ) : (
          <CustomInput
            type="select"
            name="year"
            defaultValue={year}
            onChange={(e) => setNewYear(e.target.value)}
          >
            {yearOptions.map((item, index) => {
              return (
                <option key={`opt${index}`} value={item}>
                  {item}
                </option>
              );
            })}
          </CustomInput>
        )}
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? () => toggle() : () => update()}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            onClick={deleteJob}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default QuestionLibraryRow;
