import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { getNPS, saveNPSComment, updateNPS } from 'src/api/nps.api';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { TextArea } from 'src/components/common';
import FilterSelect from 'src/components/FilterSelect';
import Icon from 'src/components/Icon';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import {
  CustomInput,
  Label,
  Modal,
  Toastify,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'src/modules';
import {
  WrapperContent,
  Content,
  StyledCol,
} from 'src/styles/pages/notifications/styles';
import { SelectContainer } from 'src/styles/pages/nps/styles';
import { ERRORS, formatDateFullDate } from 'src/utils';

const PLACEHOLDER_DATA = {
  data: [],
  total_entries: 0,
  total_pages: 0,
  last_page: 0,
  next_page: 0,
  has_more: false,
  next_page_url: '',
  previous_page_url: '',
  message: '',
};

type NPSItem = {
  id: number;
  user_id: number;
  email: string;
  full_name: string;
  rate: number;
  description: string;
  voucher_email: string;
  voucher_amount: number;
  is_checked: boolean;
  created_at: string;
  comment?: string;
};

const options = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const NPS: React.FC = () => {
  const [hasPagination, setHasPagination] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [npsListData, setNpsListData] = useState<NPSItem[]>([]);
  const [editingItemId, setEditingItemId] = useState<NPSItem | undefined>(
    undefined,
  );

  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);

  const { isLoading, isFetching, refetch } = useQuery(
    ['getNPS', nextPage, filter],
    getNPS,
    {
      placeholderData: PLACEHOLDER_DATA,
      onSuccess: ({
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
        data,
      }) => {
        setNpsListData(data);
        setHasPagination(total_pages > 1);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { mutate: mutationUpdateNPS } = useMutation(updateNPS, {
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const { mutate: saveComment } = useMutation(
    async ({ id, comment }: { id: number; comment: string }) =>
      await saveNPSComment(id, comment),
    {
      onSuccess() {
        setEditingItemId(undefined);
        refetch();
      },
    },
  );

  const onChangeChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number,
  ) => {
    const isChecked = e.target.checked;

    setNpsListData((prevState) =>
      prevState.map((item) =>
        item.id === itemId ? { ...item, is_checked: isChecked } : item,
      ),
    );

    mutationUpdateNPS(itemId);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Layout>
      <Dashboard title="NPS List">
        <SelectContainer>
          <Label style={{ fontWeight: 'bold' }}>Checked</Label>
          <FilterSelect
            selecting={handleChange}
            filter={filter}
            options={options}
          ></FilterSelect>
        </SelectContainer>

        <WrapperContent>
          <Content>
            <TableHead className="flex-nowrap py-2">
              <StyledCol xs="1 text-center">Full name</StyledCol>
              <StyledCol xs="2 text-center">Email</StyledCol>
              <StyledCol xs="1 text-center">Rate</StyledCol>
              <StyledCol xs="2 text-center">Description</StyledCol>
              <StyledCol xs="2 text-center">Comment</StyledCol>
              <StyledCol xs="2 text-center">Voucher email</StyledCol>
              <StyledCol xs="1 text-center">Created at</StyledCol>
              <StyledCol xs="1 text-center">Checked</StyledCol>
            </TableHead>

            {isFetching && <Loading />}

            {!isLoading &&
              npsListData.map((item) => (
                <TableRow key={item.id} className="flex-nowrap">
                  <StyledCol xs="1 text-center">{item.full_name}</StyledCol>
                  <StyledCol xs="2 text-center">{item.email}</StyledCol>
                  <StyledCol xs="1 text-center">{item.rate}</StyledCol>
                  <StyledCol xs="2 text-center">
                    {' '}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />{' '}
                  </StyledCol>
                  <StyledCol xs="2 text-center">
                    {item.comment}
                    <Icon
                      name="pencil"
                      onClick={() => setEditingItemId(item)}
                    />
                  </StyledCol>
                  <StyledCol xs="2 text-center">{item.voucher_email}</StyledCol>
                  <StyledCol xs="1 text-center">
                    {formatDateFullDate(item.created_at)}
                  </StyledCol>
                  <StyledCol xs="1 text-center">
                    <CustomInput
                      type="checkbox"
                      id={`input-${item.id}`}
                      checked={
                        npsListData[item.id]?.is_checked || item.is_checked
                      }
                      onChange={(e) => onChangeChecked(e, item.id)}
                    />
                  </StyledCol>
                </TableRow>
              ))}
          </Content>
        </WrapperContent>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
      <Modal
        isOpen={editingItemId !== undefined}
        toggle={() => setEditingItemId(undefined)}
      >
        <ModalHeader toggle={() => setEditingItemId(undefined)}>
          Edit Comment
        </ModalHeader>
        <ModalBody>
          <TextArea
            rows={3}
            value={editingItemId?.comment}
            style={{ width: '100%' }}
            onChange={(e) =>
              setEditingItemId({ ...editingItemId, comment: e.target.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            color="primary"
            onClick={() =>
              saveComment({
                id: editingItemId?.id,
                comment: editingItemId?.comment,
              })
            }
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default NPS;
