import React, { ChangeEvent, useState } from 'react';

import { GiChessQueen } from 'react-icons/gi';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { Col, Row } from 'reactstrap';

import { addSelfEmployed } from 'src/api/incomes';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { NumberParam, Toastify, useQueryParam } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  Benefits,
  Container,
  EmploymentDetails,
  Proof,
  ProofDetails,
  ProofImage,
  ProofItem,
  SectionCompanyDirector,
  Select,
  StudentLoan,
  SubmitSection,
  Title,
  SubmitButton,
  InputRadio,
  DocumentTypeLabel,
  AddProofButton,
  CancelButton,
  RenderErrorSpan,
} from './styles';

const mock = {
  employer_name: '',

  self_employment_type: 'freelancer',
  start_date: null,
  amount_child_benefits: 0,
  total_children_received_benefits: 0,
  has_child_benefits: false,
  has_student_loan: false,
  student_loan_type: '',
  refund_last_year: 0,
  still_receiving_benefits: false,
  document_type: '',
  documents: [],
  documents_list: [],
};

const filesList = [];

function AddSelfEmployment({
  setShowAddSelfEmployedPage,
  setIncomeTypeSelected,
}) {
  const [selfEmpInfos, setSelfEmpInfos] = useState(mock);
  const [proofDocuments, setProofDocuments] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const { mutate: mutationAddSelfEmployed } = useMutation(
    'addSelfEmployed',
    addSelfEmployed,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setShowAddSelfEmployedPage(false);
          setIncomeTypeSelected(false);
        }, 3000);
      },
      onError({ error }) {
        Toastify.toast.error(error);
        setSubmitButtonClicked(false);
      },
    },
  );

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      if (files.length > 1) {
        setSelfEmpInfos((prevState) => ({
          ...prevState,
          [name]: [files],
        }));
      } else {
        setSelfEmpInfos((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    }
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = value;

    if (value === 'true' || value === 'false') {
      newValue = JSON.parse(value);
    }

    setSelfEmpInfos((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  const validateFields = (fields, rules) => {
    const formErrors = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onSubmitSelfEmployed = () => {
    const formErrors = validateFields(selfEmpInfos, {
      self_employment_type: { required: true },
      employer_name: { required: true },
      start_date: { required: true },
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setSubmitButtonClicked(false);
    } else {
      setSubmitButtonClicked(true);

      const formData = new FormData();

      formData.append('customer_id', userId);
      formData.append('tax_year', year);
      formData.append(
        'self_employment_type',
        selfEmpInfos.self_employment_type,
      );
      formData.append('employer_name', selfEmpInfos.employer_name);
      formData.append('start_date', selfEmpInfos.start_date);
      formData.append('has_student_loan', selfEmpInfos.has_student_loan);
      formData.append('refund_last_year', selfEmpInfos.refund_last_year);
      formData.append('has_child_benefits', selfEmpInfos.has_child_benefits);
      formData.append('document', proofDocuments);
      formData.append(
        'still_receiving_benefits',
        selfEmpInfos.still_receiving_benefits,
      );

      if (proofDocuments.length > 0) {
        formData.append('document_type', selfEmpInfos.document_type);
      }

      if (selfEmpInfos.has_child_benefits) {
        formData.append(
          'amount_child_benefits',
          selfEmpInfos.amount_child_benefits,
        );
      }

      if (selfEmpInfos.has_student_loan === true) {
        formData.append('student_loan_type', selfEmpInfos.student_loan_type);
      }

      if (selfEmpInfos.has_child_benefits) {
        formData.append(
          'total_children_received_benefits',
          selfEmpInfos.total_children_received_benefits,
        );
      }

      mutationAddSelfEmployed(formData);
    }
  };

  const onCancelSubmit = () => {
    setSelfEmpInfos(mock);
    setShowAddSelfEmployedPage(false);
    setIncomeTypeSelected(false);
  };

  const renderError = (field: any) => (
    <RenderErrorSpan>{field}</RenderErrorSpan>
  );

  let newDocsList = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <Container>
      <EmploymentDetails>
        <Title>
          <span>Self employment Info</span>{' '}
          <GiChessQueen size={20} color="#57E4CD" />
        </Title>

        <Row>
          <Col>
            <SectionCompanyDirector>
              <div>
                <label htmlFor="self_employment_type" className="title">
                  Self Employment Type
                  <GiChessQueen
                    size={20}
                    color="#57E4CD"
                    style={{ position: 'relative', bottom: 2 }}
                  />
                </label>
                <Select
                  id="self_employment_type"
                  name="self_employment_type"
                  onChange={handleChangeField}
                >
                  <option value="freelancer" selected>
                    Freelancer
                  </option>
                  <option value="gig_worker">Gig Worker</option>
                  <option value="small_business">Small Business</option>
                  <option value="cis">Cis</option>
                  <option value="other">Other</option>
                </Select>
              </div>
            </SectionCompanyDirector>

            {errors.self_employment_type &&
              renderError(errors.self_employment_type)}
          </Col>

          <Col>
            <EditInputPie
              label="Name of self employment"
              id="employer_name"
              name="employer_name"
              type="text"
              value={selfEmpInfos.employer_name}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            {errors.employer_name && renderError(errors.employer_name)}
          </Col>

          <Col>
            <EditInputPie
              label="Start date"
              id="start_date"
              name="start_date"
              type="date"
              value={selfEmpInfos.start_date}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            {errors.start_date && renderError(errors.start_date)}
          </Col>
        </Row>
        <StudentLoan>
          <Title>
            <span>Student loan</span> <GiChessQueen size={20} color="#57E4CD" />
          </Title>

          <Row>
            <Col>
              <SectionCompanyDirector>
                <label htmlFor="clientHasStudentLoan" className="title">
                  Client has a student loan{' '}
                  <GiChessQueen
                    size={20}
                    color="#57E4CD"
                    style={{ position: 'relative', bottom: 2 }}
                  />
                </label>
                <label>
                  <InputRadio
                    type="radio"
                    id="clientHasStudentLoanYes"
                    name="has_student_loan"
                    value={true}
                    onChange={handleChangeField}
                  />{' '}
                  Yes
                </label>

                <label>
                  <InputRadio
                    type="radio"
                    id="clientHasStudentLoanNo"
                    name="has_student_loan"
                    value={false}
                    onChange={handleChangeField}
                  />{' '}
                  No
                </label>
              </SectionCompanyDirector>
            </Col>

            <Col>
              <SectionCompanyDirector>
                <div>
                  <label htmlFor="student_loan_type" className="title">
                    Student loan plan
                    <GiChessQueen
                      size={20}
                      color="#57E4CD"
                      style={{ position: 'relative', bottom: 2 }}
                    />
                  </label>
                  <Select
                    id="student_loan_type"
                    name="student_loan_type"
                    onChange={handleChangeField}
                    disabled={!selfEmpInfos.has_student_loan}
                    value={selfEmpInfos.student_loan_type}
                    addPage
                  >
                    <option value="plan_1">Plan 1</option>
                    <option value="plan_2">Plan 2</option>
                    <option value="both_plans">Both Plans</option>
                    <option value="unsure">Unsure</option>
                  </Select>
                </div>
              </SectionCompanyDirector>

              {errors.student_loan_type &&
                renderError(errors.student_loan_type)}
            </Col>
            <Col>
              <EditInputPie
                label="Amount paid"
                id="refund_last_year"
                name="refund_last_year"
                type="number"
                value={
                  !selfEmpInfos.has_student_loan
                    ? 0
                    : selfEmpInfos.refund_last_year
                }
                editMode
                onChange={(event) => handleChangeField(event)}
                maxWidth="366px"
                height="56px"
                fontSize="18px"
                padding="18px 18px 18px 28px"
                hasCrown
                disabled={!selfEmpInfos.has_student_loan}
              />
            </Col>
          </Row>
        </StudentLoan>

        <Benefits>
          <Title>
            <span>Self employment details</span>{' '}
            <GiChessQueen size={20} color="#57E4CD" />
          </Title>
          <Row>
            <Col>
              <SectionCompanyDirector>
                <label htmlFor="has_child_benefits" className="title">
                  Receives child benefits
                  <GiChessQueen
                    size={20}
                    color="#57E4CD"
                    style={{ position: 'relative', bottom: 2 }}
                  />
                </label>
                <label>
                  <InputRadio
                    type="radio"
                    id="has_child_benefitsYes"
                    name="has_child_benefits"
                    value={true}
                    onChange={handleChangeField}
                  />{' '}
                  Yes
                </label>

                <label>
                  <InputRadio
                    type="radio"
                    id="has_child_benefitsNo"
                    name="has_child_benefits"
                    value={false}
                    onChange={handleChangeField}
                  />{' '}
                  No
                </label>
              </SectionCompanyDirector>

              {errors.has_child_benefits &&
                renderError(errors.has_child_benefits)}
            </Col>
            <Col>
              <EditInputPie
                label="Amount in child benefits"
                id="amount_child_benefits"
                name="amount_child_benefits"
                type="number"
                value={
                  !selfEmpInfos.has_child_benefits
                    ? 0
                    : selfEmpInfos.amount_child_benefits
                }
                editMode
                onChange={(event) => handleChangeField(event)}
                maxWidth="366px"
                height="56px"
                fontSize="18px"
                padding="18px 18px 18px 28px"
                hasCrown
                disabled={!selfEmpInfos.has_child_benefits}
                addPage
              />
            </Col>
            <Col>
              <EditInputPie
                label="Total of children received benefits"
                id="total_children_received_benefits"
                name="total_children_received_benefits"
                type="number"
                value={
                  !selfEmpInfos.has_child_benefits
                    ? 0
                    : selfEmpInfos.total_children_received_benefits
                }
                editMode
                onChange={(event) => handleChangeField(event)}
                maxWidth="366px"
                height="56px"
                fontSize="18px"
                padding="18px 18px 18px 28px"
                hasCrown
                disabled={!selfEmpInfos.has_child_benefits}
                addPage
              />

              {errors.total_children_received_benefits &&
                renderError(errors.total_children_received_benefits)}
            </Col>
          </Row>
          <br /> <br />
          <Row>
            <Col>
              <SectionCompanyDirector>
                <label htmlFor="still_receiving_benefits" className="title">
                  Still receiving benefits
                  <GiChessQueen
                    size={20}
                    color="#57E4CD"
                    style={{ position: 'relative', bottom: 2 }}
                  />
                </label>
                <label>
                  <InputRadio
                    type="radio"
                    id="still_receiving_benefitsYes"
                    name="still_receiving_benefits"
                    value={true}
                    onChange={handleChangeField}
                  />{' '}
                  Yes
                </label>

                <label>
                  <InputRadio
                    type="radio"
                    id="still_receiving_benefitsNo"
                    name="still_receiving_benefits"
                    value={false}
                    onChange={handleChangeField}
                  />{' '}
                  No
                </label>
              </SectionCompanyDirector>

              {errors.still_receiving_benefits &&
                renderError(errors.still_receiving_benefits)}
            </Col>
          </Row>
        </Benefits>

        <Row style={{ alignItems: 'center' }}>
          <div>
            <DocumentTypeLabel htmlFor="document_type">
              Document type
            </DocumentTypeLabel>
            <Select
              id="document_type"
              name="document_type"
              onChange={handleChangeField}
            >
              <option value="" selected>
                Select an option
              </option>
              <option value="P45">P45</option>
              <option value="P60">P60</option>
              <option value="payslip">Payslip</option>
            </Select>
            <br /> <br />{' '}
            <span>Select a document type do enable Add proof button </span>
          </div>

          <AddProofButton
            htmlFor="documents_list"
            document_type={selfEmpInfos.document_type}
          >
            <TbFileSpreadsheet size={24} />
            <span>Add proof</span>
          </AddProofButton>
        </Row>
      </EmploymentDetails>

      <Proof>
        <Title>
          <span>Proof uploaded</span>
        </Title>

        <Row>
          {newDocsList.map((proof, i) => {
            return (
              <ProofItem key={i}>
                <ProofImage></ProofImage>
                <ProofDetails>
                  <div className="image-size">
                    <span>{proof.name}</span>
                    <span>{proof.size}KB </span>
                  </div>

                  <div>
                    <span className="remove" onClick={() => onRemoveProof(i)}>
                      Remove
                    </span>
                  </div>
                </ProofDetails>
              </ProofItem>
            );
          })}
        </Row>
        <Row>
          <input
            type="file"
            onChange={(event) => onUploadProof(event)}
            hidden
            id="documents_list"
            name="documents_list"
            multiple
            disabled={selfEmpInfos.document_type === ''}
          />
        </Row>
      </Proof>

      <SubmitSection>
        <SubmitButton
          onClick={() => onSubmitSelfEmployed()}
          disabled={submitButtonClicked}
        >
          <span>Submit</span>
        </SubmitButton>

        <CancelButton
          onClick={() => onCancelSubmit()}
          htmlFor="cancelSelfEmployed"
        >
          <span>Cancel</span>
        </CancelButton>
      </SubmitSection>
    </Container>
  );
}

export default AddSelfEmployment;
