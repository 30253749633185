import React, { useEffect, useState } from 'react';

import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { deleteTransaction, getAllTransactions } from 'src/api/incomes';
import { handleStatusColor } from 'src/components/CustomerInformationTabs/ExpensesTab';
import CustomPagination from 'src/components/CustomPagination';
import { useYear } from 'src/hooks';
import { NumberParam, observer, Toastify, useQueryParam } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import {
  Actions,
  Circle,
  Container,
  Select,
  TableBody,
  TableHeader,
} from './styles';

type Props = {
  keyword: string;
  date_range_start: string;
  date_range_end: string;
  reconciliation_type: string;
  reconciliation_status: string;
  type: string;
  onClick: (id: number) => void;
  filterSelected: string;
  setTransactionListState: (data: []) => void;
};

function AllTransactions({
  keyword,
  date_range_start,
  date_range_end,
  reconciliation_type,
  reconciliation_status,
  type,
  onClick,
  filterSelected,
  setTransactionListState,
}: Props) {
  const incomes = store.incomes;
  const expenses = store.expenses;

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const [transactionsListState, setTransactionsListState] = useState([]);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    if (filterSelected) {
      setNextPage(1);
    }
  }, [filterSelected]);

  const { isLoading, refetch } = useQuery(
    [
      'getAllTransactions',
      userId,
      year,
      keyword,
      date_range_start,
      date_range_end,
      reconciliation_type,
      reconciliation_status,
      type,
      nextPage,
    ],
    () =>
      getAllTransactions(
        userId,
        year,
        keyword,
        date_range_start,
        date_range_end,
        reconciliation_type,
        reconciliation_status,
        type,
        nextPage,
      ),
    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);

        if (type === 'credit') {
          incomes.setIsLoadingAllTransaction(false);
          incomes.setAllTotal(result.totals);
          incomes.setAllTransactions(result.data);
        } else {
          expenses.setIsLoadingAllTransaction(false);
          expenses.setAllTotal(result.totals);
          expenses.setAllTransactions(result.data);
          setTransactionListState(result.data);
        }

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more || total_pages !== 1);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
    incomes.setRefetchIncomeTransactions(refetch);
    expenses.setRefetchExpensesTransactions(refetch);
  }, []);

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        refetch();

        expenses.refetchTotals();
        expenses.refetchSummary();
      }, 1000);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  return (
    <Container>
      <TableHeader>
        <Row>
          <Col className="headerColumn"></Col>
          <Col className="headerColumn">Merchant name</Col>
          <Col className="headerColumn">Date</Col>
          <Col className="headerColumn">Gross</Col>
          <Col className="headerColumn">Net</Col>
          <Col className="headerColumn">Tax</Col>
          <Col className="headerColumn">Source</Col>
          <Col className="headerColumn" lg={2}></Col>
        </Row>
      </TableHeader>
      {!isLoading &&
        transactionsListState.map((transaction, index) => {
          return (
            <TableBody
              key={transaction.id}
              onClick={() => {
                onClick(transaction.id);
                incomes.setIndexTransactionSelected(index);
              }}
            >
              <Row>
                <Col
                  className="bodyColumn"
                  style={{ position: 'relative', left: 30 }}
                >
                  <Circle
                    background={handleStatusColor(transaction.status)}
                    border={!transaction.status ? '1px solid #999' : 'none'}
                  />
                </Col>
                <Col className="bodyColumn">
                  {transaction.merchant_name !== ''
                    ? transaction.merchant_name
                    : transaction.description}
                </Col>
                <Col className="bodyColumn">
                  {formatDate(transaction.created_at)}
                </Col>

                <Col
                  className="bodyColumn"
                  style={{ position: 'relative', left: 15 }}
                >
                  {formatCurrency(transaction.gross_amount)}
                </Col>

                <Col
                  className="bodyColumn"
                  style={{ position: 'relative', left: 15 }}
                >
                  {formatCurrency(transaction.amount)}
                </Col>

                <Col
                  className="bodyColumn"
                  style={{ position: 'relative', left: 20 }}
                >
                  {formatCurrency(transaction.tax_amount)}
                </Col>

                <Col className="bodyColumn">
                  <Select>
                    <option value="" disabled selected>
                      {transaction.income_name}
                    </option>
                  </Select>
                </Col>

                <Col lg={2} className="bodyColumn">
                  <Actions>
                    <BsFillTrashFill
                      color="red"
                      size="20"
                      onClick={() => onDeleteTransaction(transaction.id)}
                    />
                  </Actions>
                </Col>
              </Row>
            </TableBody>
          );
        })}
      {!isLoading && transactionsListState.length < 1 && (
        <span>No transactions</span>
      )}
      {hasPagination && (
        <CustomPagination
          res={pagRes}
          changePage={(page) => setNextPage(page)}
          position="center"
        />
      )}
    </Container>
  );
}

export default observer(AllTransactions);
