import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const CardName = styled.div`
  width: 65%;
  background: ${({ theme }) => theme.colors.white.default};
  padding: 5px 10px;

  .card-name-text {
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.title.m}px;
    font-weight: 500;
  }
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 35%;
  margin-left: 4px;
  background: ${({ theme }) => theme.colors.white.default};
  padding: 5px;
`;

export const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white.default};
  padding: 8px 10px;
  margin-bottom: 2px;

  .assistant-name {
    .assistant-name-text {
      font-size: ${({ theme }) => theme.fontSizes.body.m}px;
    }
  }

  .delete-assistant {
  }
`;

export const ImgAssistantPhoto = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const DeleteTeamButton = styled.button`
  border: none;
  background-color: transparent;
`;
