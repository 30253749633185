import styled from 'styled-components';

type Props = {
  status: string;
};

export const CardContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #57e4cd;
  border-radius: 46px;
  padding: 5px 15px 5px 15px;
  text-align: center;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .title {
    color: #191424;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Container1 = styled.div``;
