import AdminNotesStore from './admin-notes.store';
import AssistantStore from './assistants';
import AuthStore from './auth.store';
import CostSuggestionsStore from './cost-suggestions/cost-suggestions.store';
import CustomerStore from './customer.store';
import DashboardStore from './dashboard';
import DeletedAccountStores from './deleted-accounts.store';
import ESignatureStore from './e-signature.store';
import ExpensesTabStore from './expenses-tab/expenses-tab.store';
import ExpensesStore from './expenses/expenses.store';
import HmrcStore from './hmrc.store';
import InboxTemplatesStore from './inbox/inbox-templates.store';
import IncomesStore from './incomes/incomes.store';
import MailSettingsStore from './mail-settings.store';
import MessageCenter from './message-center/message-center.store';
import NotificationsStore from './notifications';
import NotificationTemplatesStore from './notifications/notification-templates.store';
import PieLayoutStore from './pie-layout.store';
import ReferralsStore from './referrals';
import ThemeStore from './theme.store';

class RootStore {
  auth: AuthStore;
  adminNotes: AdminNotesStore;
  eSignature: ESignatureStore;
  hmrc: HmrcStore;
  mailSettings: MailSettingsStore;
  customer: CustomerStore;
  deletedAccounts: DeletedAccountStores;
  notificationTemplates: NotificationTemplatesStore;
  costSuggestions: CostSuggestionsStore;
  incomes: IncomesStore;
  expenses: ExpensesStore;
  dashboard: DashboardStore;
  inboxTemplates: InboxTemplatesStore;
  referrals: ReferralsStore;
  assistants: AssistantStore;
  notifications: NotificationsStore;
  expensesTab: ExpensesTabStore;
  messageCenter: MessageCenter;
  theme: ThemeStore;
  pieLayout: PieLayoutStore;

  constructor() {
    this.auth = new AuthStore();
    this.adminNotes = new AdminNotesStore();
    this.eSignature = new ESignatureStore();
    this.hmrc = new HmrcStore();
    this.mailSettings = new MailSettingsStore();
    this.customer = new CustomerStore();
    this.deletedAccounts = new DeletedAccountStores();
    this.notificationTemplates = new NotificationTemplatesStore();
    this.costSuggestions = new CostSuggestionsStore();
    this.incomes = new IncomesStore();
    this.expenses = new ExpensesStore();
    this.dashboard = new DashboardStore();
    this.inboxTemplates = new InboxTemplatesStore();
    this.referrals = new ReferralsStore();
    this.assistants = new AssistantStore();
    this.notifications = new NotificationsStore();
    this.expensesTab = new ExpensesTabStore();
    this.messageCenter = new MessageCenter();
    this.theme = new ThemeStore();
    this.pieLayout = new PieLayoutStore();
  }
}

const store = new RootStore();

export {
  AuthStore,
  AdminNotesStore,
  ESignatureStore,
  HmrcStore,
  CustomerStore,
  MailSettingsStore,
  DeletedAccountStores,
  NotificationTemplatesStore,
  CostSuggestionsStore,
  DashboardStore,
  ThemeStore,
  PieLayoutStore,
};
export default store;
