import React, { useEffect, useState } from 'react';

import Icon from 'src/components/Icon';
import { Button } from 'src/modules';
import checkChatType from 'src/utils/helpers/checkChatType';

import { TSortType } from '../..';

import {
  Container,
  ShadowContainer,
  ModalContainer,
  Header,
  HeaderText,
  HeaderButton,
  FilterButtons,
  SectionSocialMedia,
  SocialMediaButton,
  FirstSection,
  ButtonText,
} from './styles';

type Props = {
  filterModalIsOpen: boolean;
  setFilterModalIsOpen(filterModalIsOpen: boolean): void;
  setSortType(sortType: TSortType): void;
  sortType: TSortType;
  setMessageTypeId: any;
  messageTypeId: any;
  sideMenuOpen: boolean;
  lastMessageFromUser: boolean;
  setLastMessageFromUser(lastMessageFromUser: boolean): void;
};

const FilterModal: React.FC<Props> = ({
  filterModalIsOpen,
  setFilterModalIsOpen,
  setSortType,
  sortType,
  setMessageTypeId,
  messageTypeId,
  sideMenuOpen,
  lastMessageFromUser,
  setLastMessageFromUser,
}) => {
  const onChangeAz = () => {
    const az = sortType === 'a-z' ? 'z-a' : 'a-z';
    setSortType(az);
  };

  const toggleType = (typeId: number) => {
    if (messageTypeId === typeId) {
      setMessageTypeId('');
    } else {
      setMessageTypeId(typeId);
    }
  };

  return (
    <Container filterModalIsOpen={filterModalIsOpen}>
      <ShadowContainer
        filterModalIsOpen={filterModalIsOpen}
        onClick={() => setFilterModalIsOpen(!setFilterModalIsOpen)}
      />
      <ModalContainer sideMenuOpen={sideMenuOpen}>
        <Header>
          <HeaderText>Filter </HeaderText>
          <HeaderButton onClick={() => setFilterModalIsOpen(false)}>
            <Icon name="close" />
          </HeaderButton>
        </Header>
        <FilterButtons>
          <FirstSection>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              onClick={() => setSortType('unread')}
              active={sortType === 'unread'}
            >
              Unread
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              onClick={() => onChangeAz()}
              active={sortType === 'a-z' || sortType === 'z-a'}
            >
              {sortType === 'a-z' ? 'Z-A' : 'A-Z'}
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              onClick={() => setSortType('earliest')}
              active={sortType === 'earliest'}
            >
              Earliest
            </Button>
          </FirstSection>

          <Button
            type="submit"
            color="primary"
            className="btn btn-primary"
            onClick={() => setLastMessageFromUser(!lastMessageFromUser)}
            active={lastMessageFromUser}
          >
            <ButtonText>Last message user</ButtonText>
          </Button>
        </FilterButtons>
        <SectionSocialMedia className="mc-section-social-media">
          <SocialMediaButton onClick={() => toggleType(3)}>
            <Icon name={checkChatType(3)} isActive={messageTypeId === 3} />
          </SocialMediaButton>
          <SocialMediaButton onClick={() => toggleType(2)}>
            <Icon name={checkChatType(2)} isActive={messageTypeId === 2} />
          </SocialMediaButton>

          <SocialMediaButton onClick={() => toggleType(1)}>
            <Icon name={checkChatType(1)} isActive={messageTypeId === 1} />
          </SocialMediaButton>
        </SectionSocialMedia>
      </ModalContainer>
    </Container>
  );
};

export default FilterModal;
