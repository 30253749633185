import React, { useEffect, useState } from 'react';

import { observer } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';

// Timeout in seconds to refresh user data
const TIMEOUT_IN_SECONDS = 30 * 60; // X minutes x 60 seconds

const Layout: React.FC = ({ children }) => {
  const auth = store.auth;

  const [once, setOnce] = useState(false);

  //   const passed30minutes = async () => {
  //     // Last time user data was refreshed - in ms
  //     const lastRefresh = await Storage.getRequestTime();

  //     // Time now - in ms
  //     const now = Date.now();

  //     // How long have passed since last refresh - in SECONDS
  //     const elapsed = (now - lastRefresh) / 1000;
  //     // console.log(`🚀 ~ elapsed since last refresh in seconds:`, elapsed, TIMEOUT_IN_SECONDS);

  //     // If last refresh was more than TIMEOUT_IN_SECONDS ago refresh user data and store Date.now() as new time
  //     if (!lastRefresh || elapsed > TIMEOUT_IN_SECONDS) {
  //       // console.log(`Refreshing user data! Timeout is set to ${TIMEOUT_IN_SECONDS} secs, Elapsed is ${elapsed} secs`);
  //       await auth.fetchMe();
  //       Storage.setRequestTime(now);
  //     }
  //   };

  useEffect(() => {
    if (!once) {
      setOnce(true);
      auth.fetchMe();
    }
  }, []);

  return <div>{children}</div>;
};

export default observer(Layout);
