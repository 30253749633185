import React from 'react';

export default function Logo({ box = false, color = '#000000', size = 100 }) {
  const boxStyle = {
    width: size,
    height: size,
  };

  const logoSize = box ? size - 10 : size;

  return (
    <div
      className="d-flex justify-content-center align-items-center rounded-2"
      style={boxStyle}
    >
      <img src="/pie-logo.png" alt="logo" style={{ width: logoSize }} />
    </div>
  );
}
