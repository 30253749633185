import React, { memo, useEffect, useState } from 'react';

import { User } from 'react-feather';
import { useMutation, useQuery } from 'react-query';
import Switch from 'react-switch';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  useQueryParam,
} from 'use-query-params';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getAdmin,
  changeAvailabilityStatus,
  getAdminById,
} from 'src/api/admin';
import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { useLocalStorage, useYear } from 'src/hooks';
import {
  ICustomersStats,
  EIncomeExpenseStatus,
  TIncomeExpenseStatus,
} from 'src/models';
import { Row, Col, Card, Button, Toastify, Tooltip } from 'src/modules';
import { Storage } from 'src/services';
import { ERRORS } from 'src/utils';

import { AvailableAssistant, UserInformation } from './styles';

interface CustomersPageHeaderProps {
  onApplyFilters(): void;
  loadData(clear: boolean, userId?: number): void;
  onClearFilters(): void;
}

const CustomersPageHeader = ({
  onApplyFilters,
  loadData,
  onClearFilters,
}: CustomersPageHeaderProps) => {
  const [year] = useYear();
  const [open, setOpen] = useLocalStorage('customers_header', false);

  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip((prevState) => !prevState);

  const [tax_assistant_id, setAssistant] = useQueryParam(
    'tax_assistant_id',
    NumberParam,
  );

  const [query, setQuery] = useQueryParams({
    income_expense_status: StringParam,
    tax_year: NumberParam,
  }); // TIncomeExpenseStatus

  const [local, setLocal] = useLocalStorage('localStats', '');
  const [user, setUser] = useState<User>();
  const [stats, setStats] = useState<ICustomersStats>(local);
  const [assistantIsAvailable, setAssistantIsAvailable] = useState(false);
  const [assistantLoggedId, setAssistantLoggedId] = useState(0);
  const [assistantFilterSelected, setAssistantFilterSelected] =
    useState<AdminData>();
  const [assistantFilterSelectedRoleId, setAssistantFilterSelectedRoleId] =
    useState(user && user.role_id);

  useQuery(['admin', user], () => getAdmin(user && user.email), {
    onSuccess(data) {
      setAssistantIsAvailable(data.available_status);
      setAssistantLoggedId(data.id);
    },
  });

  const { refetch } = useQuery(
    ['admin', tax_assistant_id],
    () => getAdminById(Number(tax_assistant_id)),
    {
      enabled: false,
      onSuccess(data) {
        setAssistantIsAvailable(data?.available_status);
        setAssistantFilterSelected(data);
        setAssistantFilterSelectedRoleId(data && Number(data.role_id));
      },
    },
  );

  useEffect(() => {
    if (tax_assistant_id) {
      refetch();
    }
  }, [tax_assistant_id]);

  const { mutate: mutateAvailableStatus } = useMutation(
    changeAvailabilityStatus,
    {
      onError() {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const loadPage = async () => {
    try {
      const userData = await Storage.getUser();
      setUser(userData);

      const { data } = await request.get(
        `${API.PATH}${API.STATS}/?year=${year}`,
      );

      setStats(data);
      setLocal(data);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const onClick = async () => {
    try {
      if (tax_assistant_id) {
        onClearFilters();
        setAssistant(undefined);
        return;
      }

      const userData = await Storage.getUser();
      setAssistant(userData.id);
      loadData(false, userData.id);

      const { data } = await request.get(
        `${API.PATH}${API.STATS}/?year=${year}
        ?tax_assistant_id=${userData.id}`,
      );
      setUser(userData);
      setStats(data);
      setLocal(data);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const setStatus = (status: TIncomeExpenseStatus, incomeYear: number) => {
    setQuery(
      { income_expense_status: status, tax_year: incomeYear },
      'replace',
    );
  };

  useEffect(() => {
    loadPage();
    setStats(local);
    if (query.income_expense_status || query.tax_year) {
      onApplyFilters && onApplyFilters();
    }
  }, []);

  const onChangeAvailableAssistant = () => {
    mutateAvailableStatus({ id: assistantLoggedId });
    setAssistantIsAvailable(!assistantIsAvailable);
  };

  const CheckAssistantSelected = () => {
    if (assistantFilterSelected && tax_assistant_id) {
      return <img src={assistantFilterSelected.image} />;
    } else if (user?.image && !tax_assistant_id) {
      return <img src={user.image} />;
    }

    return <User style={{ marginRight: 4 }} />;
  };

  const isNotAssistant =
    user?.role_id !== 3 && assistantFilterSelectedRoleId !== 3;

  return (
    <div className="mb-4">
      <Row noGutters className="align-items-center mb-3">
        <div>
          <Button
            onClick={() => {
              setOpen((open) => !open);
            }}
          >
            <span className="mr-3">
              All Clients: {stats?.count_customers?.all_costumers}
            </span>{' '}
            <FontAwesomeIcon
              icon={open ? faChevronUp : faChevronDown}
              size="sm"
            />
          </Button>
        </div>
        <div className="ml-3">
          <UserInformation onClick={onClick}>
            <CheckAssistantSelected />
            {assistantFilterSelected && tax_assistant_id ? (
              <span className="link">
                {assistantFilterSelected.name} - {year}
              </span>
            ) : (
              <span className="link">
                {user?.name} - {year}
              </span>
            )}
          </UserInformation>
        </div>
        <AvailableAssistant>
          <Tooltip
            placement="top"
            isOpen={showTooltip}
            toggle={toggleTooltip}
            target={`switch-item`}
          >
            Availability
          </Tooltip>

          {isNotAssistant ||
          (assistantFilterSelectedRoleId &&
            assistantFilterSelectedRoleId !== 3) ? (
            <div id={`switch-item`}>
              <Switch
                onChange={onChangeAvailableAssistant}
                checked={!!assistantIsAvailable}
                onColor="#57e4cd"
                width={44}
                height={20}
              />
            </div>
          ) : (
            ''
          )}
        </AvailableAssistant>
      </Row>

      {open && (
        <>
          <Row className="mb-3">
            <Col>
              <Card className="px-1 pt-3 mb-3 h-100 text-center">
                All Years
                <br />
                {/* Check: {stats?.count_check_sign_off?}<br/> */}
                Sign Off: {stats?.count_customers?.all_year_done}
                <br />
                Customers: {stats?.count_customers?.all_costumers}
              </Card>
            </Col>

            {stats?.count_years?.slice(0, 7).map((year) => (
              <Col key={`year${year.tax_year}`}>
                <Card className="px-3 pt-3 mb-3 h-100 text-center">
                  <span
                    className="link"
                    onClick={() => {
                      setStatus(undefined, year.tax_year);
                    }}
                  >
                    {year.tax_year}
                    <br />
                    {year.count}
                    <br />
                  </span>
                  <span>
                    <span
                      className="link"
                      onClick={() => {
                        setStatus(EIncomeExpenseStatus.DONE, year.tax_year);
                      }}
                    >
                      {year.done}
                    </span>
                    <span> / </span>
                    <span
                      className="link"
                      onClick={() => {
                        setStatus(EIncomeExpenseStatus.CHECKED, year.tax_year);
                      }}
                    >
                      {' '}
                      {year.checked}{' '}
                    </span>
                  </span>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="mb-2">
            <Col xs="12" md="2">
              <Card className="px-3 pt-3 mb-3 h-100 text-center">
                <h4 className="text-uppercase mb-2">
                  <i className="icon-title icon-signup mr-2" />
                  SignUp
                </h4>
                <p className="mb-0">Total: {stats?.count_signup}</p>
              </Card>
            </Col>
            <Col xs="12" md="2">
              <Card className="px-3 pt-3 mb-3 h-100 text-center">
                <h4 className="text-uppercase mb-2">
                  <i className="icon-title icon-income mr-2" />
                  Income
                </h4>
                <p className="mb-1">
                  Check: {stats?.count_income['checked']?.count}
                </p>
                <p className="mb-1">
                  Sign Off: {stats?.count_income['signed-off']?.count}
                </p>
                <p className="mb-1">
                  Submitted: {stats?.count_income['done']?.count}
                </p>
              </Card>
            </Col>
            <Col xs="12" md="2">
              <Card className="px-3 pt-3 mb-3 h-100 text-center">
                <h4 className="text-uppercase mb-2">
                  <i className="icon-title icon-expenses mr-2" />
                  Expense
                </h4>
                <p className="mb-1">
                  Check: {stats?.count_expenses['checked']?.count}
                </p>
                <p className="mb-1">
                  Sign Off: {stats?.count_expenses?.['signed-off']?.count}
                </p>
                <p className="mb-1">
                  Submitted: {stats?.count_expenses['done']?.count}
                </p>
              </Card>
            </Col>
            <Col xs="12" md="2">
              <Card className="px-3 pt-3 mb-3 h-100 text-center">
                <h4 className="text-uppercase mb-2">
                  <i className="icon-title icon-process mr-2" />
                  HMRC
                </h4>
                <p className="mb-0">{stats?.count_hmrc}</p>
              </Card>
            </Col>
            <Col xs="12" md="2">
              <Card className="px-3 pt-3 mb-3 h-100 text-center">
                <h4 className="text-uppercase mb-2">
                  <i className="icon-title icon-paid mr-2" />
                  Paid
                </h4>
                <p className="mb-0">{stats?.count_paid}</p>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default memo(CustomersPageHeader);
