import { QueryFunctionContext } from 'react-query';

import request from '../request';

export const editAdmin = async (
  editData: Omit<AdminData, 'available_status' | 'status' | 'image'> & {
    id: number;
    image?: File;
  },
): Promise<AdminData & { message: string }> => {
  const { id, ...rest } = editData;

  const formData = new FormData();

  Object.keys(rest).forEach((key) => formData.append(key, rest[key]));

  const { data } = await request.post(`/admin/edit?id=${id}`, formData);

  return data;
};

export const getAdmin = async (email: string): Promise<AdminData> => {
  const { data } = await request.get(`/admin/?search=${email}`);

  return data.data[0];
};

export const listAdmins = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<AdminData[]> => {
  const [, query] = queryKey;

  const pagination =
    query?.length > 0 ? '&page=1&per_page=200' : '?page=1&per_page=200';

  const { data } = await request.get(`/admin/${query}${pagination}`);

  return data.data;
};

export const addAdmin = async (adminData: AddAdminData) => {
  const formData = new FormData();

  Object.keys(adminData).forEach((key) => formData.append(key, adminData[key]));

  const { data } = await request.post(`/admin/create`, formData);

  return data;
};

export const deleteAdmin = async ({
  id,
}: {
  id: number;
}): Promise<{ id: number }> => {
  const { data } = await request.delete(`/admin/delete?id=${id}`);

  return data;
};

export const changeAvailabilityStatus = async ({
  id,
}: {
  id: number;
}): Promise<{ id: number; available_status: number }> => {
  const { data } = await request.put(`/admin/change-available-status?id=${id}`);

  return data;
};

export const changeLoginStatus = async ({
  id,
}: {
  id: number;
}): Promise<{ id: number; available_status: number }> => {
  const { data } = await request.put(`/admin/change-status?id=${id}`);

  return data;
};

export const getAdminById = async (id: number): Promise<AdminData> => {
  const { data } = await request.get(`/admin/show?id=${id}`);

  return data;
};

export const getAdminByName = async (name: any) => {
  const { data } = await request.get(`/admin/?search=${name}`);

  return data.data[0];
};

export const listAllAdmins = async ({
  queryKey,
}: QueryFunctionContext): Promise<AdminData[]> => {
  const [, nextPage] = queryKey;

  const { data } = await request.get(`/admin/?page=${nextPage}&per_page=${5}`);

  return data;
};
