import React, { useState } from 'react';

import { useQuery } from 'react-query';

import { listStatusTags } from 'src/api/status-tags';
import {
  Layout,
  TableHead,
  StatusTagRow,
  NewStatusTagForm,
} from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Button, Card, Col, Container, Row } from 'src/modules';

const StatusTagsPage: React.FC = () => {
  const [showAddTag, setShowAddTag] = useState(false);

  const { data: tags = [], isLoading } = useQuery(
    ['listStatusTags'],
    listStatusTags,
  );

  return (
    <Layout>
      <Dashboard title="Status Tags">
        <Row className="mb-4">
          <Col xs="2">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setShowAddTag(true);
              }}
              outline
            >
              Add New Tag
            </Button>
          </Col>
        </Row>
        {showAddTag && (
          <NewStatusTagForm onClose={() => setShowAddTag(false)} />
        )}
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Title</Col>
              <Col xs="2" className="d-flex justify-content-center ml-auto">
                Action
              </Col>
            </TableHead>
            {isLoading && <Loading />}
            {tags.map((item) => (
              <StatusTagRow
                key={`status-tag-${item.id}`}
                id={item.id}
                title={item.status_label_name}
              />
            ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default StatusTagsPage;
