const ReactDOM = require('react-dom');
import './src/assets/scss/corporate.scss';
import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-image-crop/dist/ReactCrop.css'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
