import React, { useState } from 'react';

import { faPen, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { Col, Input, Row, Button, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const HowYouHereRow: React.FC = ({
  id,
  name,
  imgSrc,
  alt,
  onStatusChange,
  onDeleteItem,
  active,
  value,
}) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [nameField, setNameField] = useState<string>(name);
  const [imageField, setImageField] = useState<string>(imgSrc);
  const [image, setImage] = useState(undefined);

  // @TODO: Move to mobx

  const toggleBtn = async (): Promise<void> => {
    try {
      if (!disabled) {
        const formData: FormData = new FormData();
        formData.append('name', nameField);
        if (image) formData.append('image', image);

        const { data } = await request.post(
          `${API.PATH}${API.HOW_YOU_HERE_EDIT}?id=${id}`,
          formData,
        );

        setNameField(data.name);
        setImageField(data.image);
      }
      setDisabled(!disabled);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  // @TODO: Move to mobx
  const deleteBtn = async (): Promise<void> => {
    try {
      await request.delete(`${API.PATH}${API.HOW_YOU_HERE_DELETE}?id=${id}`);
      onDeleteItem(id);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const nameFieldHandle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setNameField(e.target.value);
  };

  const imageHandle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file: FileList | null = e.target.files;
    if (file.length > 0) setImage(file[0]);
  };

  return (
    <TableRow className="align-items-center">
      <Col xs="1">
        {disabled ? (
          <img className="admin-image ml-4" alt={alt} src={imageField} />
        ) : (
          <Input type="file" onChange={imageHandle} />
        )}
      </Col>
      <Col>
        <Input
          type="text"
          name="name"
          value={nameField}
          disabled={disabled}
          onChange={nameFieldHandle}
        />
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          {/* <ButtonIcon
            onClick={toggleBtn}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon> */}
          {/* <ButtonIcon
            color={'primary'}
            className="table-button"
            onClick={deleteBtn}
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon> */}
        </Row>
      </Col>
    </TableRow>
  );
};

export default HowYouHereRow;
