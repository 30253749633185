import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';

import { Button, observer } from 'src/modules';
import store from 'src/stores';
import { formatDate, getYearsList } from 'src/utils';

import { Wrapper, StyledInput, StyledSelect, LabelInfo } from './styled';

const inputFormat = 'YYYY-MM-DD';
const outputFormat = 'YYYY-MM-DD';

const options = [
  { name: 'Day', id: 'day' },
  { name: 'Month', id: 'month' },
  { name: 'Quarter', id: 'quarter' },
  { name: 'Half year', id: 'half_year' },
  { name: 'Year', id: 'year' },
];

const optionsMonths = [
  { name: 'January', id: '01' },
  { name: 'February', id: '02' },
  { name: 'March', id: '03' },
  { name: 'April', id: '04' },
  { name: 'May', id: '05' },
  { name: 'June', id: '06' },
  { name: 'July', id: '07' },
  { name: 'August', id: '08' },
  { name: 'September', id: '09' },
  { name: 'October', id: '10' },
  { name: 'November', id: '11' },
  { name: 'December', id: '12' },
];

const optionsQuarters = [
  { name: 'Q1', id: '01' },
  { name: 'Q2', id: '04' },
  { name: 'Q3', id: '07' },
  { name: 'Q4', id: '10' },
];

const optionsHalf = [
  { name: 'Jan-Jun', id: '01' },
  { name: 'Jul-Dec', id: '07' },
];

type Props = {
  disabled?: boolean;
};

const Filters: React.FC<Props> = ({ disabled }) => {
  const inputElem = useRef<any>(null);
  const { dashboard } = store;
  const [startDate, setStartDate] = useState<any>(dashboard.filters.startDate);
  const [endDate, setEndDate] = useState<any>(dashboard.filters.endDate);
  const [period, setPeriod] = useState<any>(dashboard.filters.period);
  const [monthFrom, setMonthFrom] = useState<any>('01');
  const [monthTo, setMonthTo] = useState<any>('01');
  const [yearFrom, setYearFrom] = useState<any>('2022');
  const [yearTo, setYearTo] = useState<any>('2022');
  const [quarterFrom, setQuarterFrom] = useState<any>('01');
  const [quarterTo, setQuarterTo] = useState<any>('01');
  const [halfFrom, setHalfFrom] = useState<any>('01');
  const [halfTo, setHalfTo] = useState<any>('01');
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const [quickFilter, setQuickFilter] = useState<boolean>(false);
  const from = formatDate(startDate, inputFormat, outputFormat);
  const to = formatDate(endDate, inputFormat, outputFormat);

  const conditionQuartes = (quarter: any) => {
    let lastMonth = '03';
    if (quarter == '04') {
      lastMonth = '06';
    } else if (quarter == '07') {
      lastMonth = '09';
    } else if (quarter == '10') {
      lastMonth = '12';
    }

    return lastMonth;
  };

  const getQuarterByMonth = (month: any) => {
    const monthValue = parseInt(month);
    if (monthValue < 3) {
      return '10';
    } else if (monthValue > 3 && monthValue < 7) {
      return '01';
    } else if (monthValue > 7 && monthValue < 10) {
      return '04';
    } else {
      return '07';
    }
  };

  const conditionHalfYear = (half: any) => {
    let lastMonth = '03';
    if (half == '01') {
      lastMonth = '06';
    } else {
      lastMonth = '12';
    }
    return lastMonth;
  };

  const updateForm = () => {
    updateFunction();
    setEnableButton(false);
  };

  const updateFunction = () => {
    if (period == 'day') {
      dashboard.setStartDate(startDate);
      dashboard.setEndDate(endDate);
    } else if (period == 'month') {
      dashboard.setStartDate(`${yearFrom}-${monthFrom}-01`);
      dashboard.setEndDate(
        `${yearTo}-${moment(monthTo).endOf('month').format('MM-DD')}`,
      );
    } else if (period == 'quarter') {
      const lastMonth = conditionQuartes(quarterTo);
      dashboard.setStartDate(`${yearFrom}-${quarterFrom}-01`);
      dashboard.setEndDate(
        `${yearTo}-${moment(lastMonth).endOf('month').format('MM-DD')}`,
      );
    } else if (period == 'half_year') {
      const lastMonth = conditionHalfYear(halfTo);
      dashboard.setStartDate(`${yearFrom}-${halfFrom}-01`);
      dashboard.setEndDate(
        `${yearTo}-${moment(lastMonth).endOf('month').format('MM-DD')}`,
      );
    } else if (period == 'year') {
      dashboard.setStartDate(`${yearFrom}-01-01`);
      dashboard.setEndDate(`${yearTo}-12-01`);
    }
    dashboard.setPeriod(period);
  };

  const changeStart = (e: any) => {
    setStartDate(e);
    setEnableButton(true);
  };

  const changeEnd = (e: any) => {
    setEndDate(e);
    setEnableButton(true);
  };

  const changePeriod = (e: Period) => {
    setPeriod(e);
    setEnableButton(true);
  };

  const changeDays = (value: number) => {
    setQuickFilter(true);
    setPeriod('day');
    const now = moment().format(inputFormat);
    const period = moment().subtract(value, 'days').format(inputFormat);
    changeStart(period);
    changeEnd(now);
  };

  const nameLastMonth = () => {
    const month = moment().subtract('1', 'month').format('MM');
    const lastMonth = optionsMonths.filter((value) => {
      if (value.id === month) {
        return value;
      }
    });
    return lastMonth[0].name;
  };

  const getLastMonth = () => {
    setQuickFilter(true);
    setPeriod('month');
    const month = moment().subtract('1', 'month').format('MM');
    const year = moment().format('YYYY');
    setYearFrom(year);
    setYearTo(year);
    setMonthTo(month);
    setMonthFrom(month);
    setEnableButton(true);
  };

  const getLastQuarter = () => {
    setQuickFilter(true);
    setPeriod('quarter');
    const month = moment().format('MM');
    const quart = getQuarterByMonth(month);
    let year;
    if (quart == '10') {
      year = moment().subtract('1', 'year').format('YYYY');
    } else {
      year = moment().format('YYYY');
    }
    setYearFrom(year);
    setYearTo(year);
    setQuarterFrom(quart);
    setQuarterTo(quart);
    setEnableButton(true);
  };

  const getYear = (year: any) => {
    setQuickFilter(true);
    setPeriod('year');
    setYearFrom(year);
    setYearTo(year);
    updateForm();
  };

  useEffect(() => {
    updateFunction();
    setQuickFilter(false);
  }, [quickFilter]);

  return (
    <Wrapper>
      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => changeDays(7)}
      >
        Last 7 days
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => changeDays(15)}
      >
        Last 15 days
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => changeDays(30)}
      >
        Last 30 days
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => getLastMonth()}
      >
        {nameLastMonth()}
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => getLastQuarter()}
      >
        Last Quarter
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => getYear('2020')}
      >
        2020
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => getYear('2021')}
      >
        2021
      </Button>

      <Button
        color="primary"
        className="d-inline-block mr-2"
        onClick={() => getYear('2022')}
      >
        2022
      </Button>

      {period != 'day' && <LabelInfo>Start</LabelInfo>}

      {period == 'day' && (
        <>
          <StyledInput
            type="date"
            value={from}
            onChange={(e) => {
              changeStart(e.target.value);
            }}
          />
          <StyledInput
            type="date"
            value={to}
            onChange={(e) => {
              changeEnd(e.target.value);
            }}
            max={moment(startDate).add(30, 'days').format(inputFormat)}
            min={moment(startDate).add(1, 'days').format(inputFormat)}
          />
        </>
      )}

      {period == 'month' && (
        <StyledSelect
          className="mr-2"
          value={monthFrom}
          onChange={(e) => {
            setMonthFrom(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsMonths.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period == 'quarter' && (
        <StyledSelect
          className="mr-2"
          value={quarterFrom}
          onChange={(e) => {
            setQuarterFrom(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsQuarters.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period == 'half_year' && (
        <StyledSelect
          className="mr-2"
          value={halfFrom}
          onChange={(e) => {
            setHalfFrom(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsHalf.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period != 'day' && (
        <StyledSelect
          className="mr-2"
          value={yearFrom}
          onChange={(e) => {
            setYearFrom(e.target.value);
            setEnableButton(true);
          }}
        >
          {getYearsList().map((item, index) => {
            return <option key={`y${index}`}>{item}</option>;
          })}
        </StyledSelect>
      )}

      {period != 'day' && <LabelInfo>End</LabelInfo>}

      {period == 'month' && (
        <StyledSelect
          className="mr-2"
          value={monthTo}
          onChange={(e) => {
            setMonthTo(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsMonths.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period == 'quarter' && (
        <StyledSelect
          className="mr-2"
          value={quarterTo}
          onChange={(e) => {
            setQuarterTo(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsQuarters.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period == 'half_year' && (
        <StyledSelect
          className="mr-2"
          value={halfTo}
          onChange={(e) => {
            setHalfTo(e.target.value);
            setEnableButton(true);
          }}
        >
          {optionsHalf.map((item) => {
            return (
              <option key={`period-${item.id}`} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </StyledSelect>
      )}

      {period != 'day' && (
        <StyledSelect
          className="mr-2"
          value={yearTo}
          onChange={(e) => {
            setYearTo(e.target.value);
            setEnableButton(true);
          }}
        >
          {getYearsList().map((item, index) => {
            return <option key={`y${index}`}>{item}</option>;
          })}
        </StyledSelect>
      )}

      <StyledSelect
        className="mr-2"
        value={period}
        disabled={disabled}
        onChange={(e) => {
          changePeriod(e.target.value as Period);
        }}
      >
        {options.map((item) => {
          return (
            <option key={`period-${item.id}`} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </StyledSelect>
      <Button
        ref={inputElem}
        color="primary"
        className="d-inline-block px-4"
        disabled={!enableButton}
        onClick={() => updateForm()}
      >
        Update
      </Button>
    </Wrapper>
  );
};

export default observer(Filters);
