import styled from 'styled-components';

export const Item = styled.li`
  cursor: pointer;
  padding: 0.5rem 0.8rem;
  border-bottom: solid thin ${({ theme }) => theme.colors.gray.geyser};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.white.catskill};
  }

  &:hover {
    color: #000;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Email = styled.p`
  color: ${({ theme }) => theme.colors.gray.regent};
  margin: 0;
`;
