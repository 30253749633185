import { IName } from 'src/components/Icon';
import store from 'src/stores';

import { ChatType } from '../enums/messages.enum';

const themeStore = store.theme;

export const onChatTypeTheme = (chatType: IName, chatTypeDark: any) => {
  return themeStore.currentTheme === 'dark' ? chatTypeDark : chatType;
};

export default function checkChatType(type: number): IName {
  switch (type) {
    case 1:
      return onChatTypeTheme(ChatType['in-app'], ChatType['white-in-app']);
    case 2:
      return onChatTypeTheme(ChatType.sms, ChatType['white-sms']);
    case 3:
      return onChatTypeTheme(ChatType.whatsapp, ChatType['white-whatsapp']);
    default:
      return onChatTypeTheme(ChatType['in-app'], ChatType['white-in-app']);
  }
}
