import React from 'react';

import { Row } from 'src/modules';

import { LabelData } from '../../styles';

import { SectionInput, HiddenInput, SectionRow } from './styles';

export default function SubscriptionDetails({ personalInfo }) {
  return (
    <>
      <SectionRow>
        <SectionInput>
          <LabelData for="subscription_plan" isPIE>
            Subscription Plan
          </LabelData>

          <HiddenInput
            id="subscription_plan"
            type="text"
            defaultValue={personalInfo?.subscription_plan}
            disabled
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscription_renewable" isPIE>
            Type
          </LabelData>

          <HiddenInput
            id="subscription_renewable"
            type="text"
            defaultValue={personalInfo?.subscription_renewable}
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscriptions_price" isPIE>
            Price
          </LabelData>

          <HiddenInput
            id="subscriptions_price"
            type="text"
            defaultValue={personalInfo?.subscriptions_price}
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscriptions_provider" isPIE>
            Provider
          </LabelData>

          <HiddenInput
            id="subscriptions_provider"
            type="text"
            defaultValue={personalInfo?.subscriptions_provider}
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscriptions_status" isPIE>
            Status
          </LabelData>

          <HiddenInput
            id="subscriptions_status"
            type="text"
            defaultValue={personalInfo?.subscriptions_status}
            inputDisabled
          />
        </SectionInput>
      </SectionRow>
      <SectionRow>
        <SectionInput>
          <LabelData for="subscriptions_subscribed_at" isPIE>
            Subscription Date
          </LabelData>

          <HiddenInput
            id="subscriptions_subscribed_at"
            type="text"
            defaultValue={personalInfo?.subscriptions_subscribed_at}
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscription_last_payment_at" isPIE>
            Last Payment
          </LabelData>

          <HiddenInput
            id="subscription_last_payment_at"
            type="text"
            defaultValue={personalInfo?.subscriptions_last_payment_at}
            inputDisabled
          />
        </SectionInput>

        <SectionInput>
          <LabelData for="subscriptions_expiration_at" isPIE>
            Expire Date
          </LabelData>

          <HiddenInput
            id="subscriptions_expiration_at"
            type="text"
            defaultValue={personalInfo?.subscriptions_expiration_at}
            inputDisabled
          />
        </SectionInput>
      </SectionRow>
    </>
  );
}
