import styled, { DefaultTheme } from 'styled-components';

const InputStyles = (theme: DefaultTheme) => `
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${theme.colors.blue.fiord};
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid ${theme.colors.gray.ghost};
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const Input = styled.input`
  ${({ theme }) => InputStyles(theme)}
`;

const TextArea = styled.textarea`
  ${({ theme }) => InputStyles(theme)};
  height: auto;
`;

const Select = styled.select`
  ${({ theme }) => InputStyles(theme)}
  word-wrap: normal;
  display: inline-block;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231F2B36' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.7rem center/8px 10px;
  appearance: none;
`;

export { Input, Select, TextArea };
