import { CustomerInformation } from 'src/utils/types/Customer';

export const customerInitData: CustomerInformation = {
  id: 0,
  company_logo: '',
  prefix: '',
  profile_pic: '',
  full_name: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  utr_no: '',
  email: '',
  status: '',
  employee_type: '',
  phone: '',
  progress: [],
  insurance_no: '',
  requested_utr: '',
  dob: '',
  user_referral_code: '',
  company_name: null,
  company_address: null,
  company_postal_code: null,
  vat_no: null,
  account_holder_name: '',
  account_sort_code: '',
  account_number: '',
  id_proof: '',
  id_proof_title: null,
  referred_by: null,
  referred_by_name: null,
  postal_code: '',
  address: '',
  tax_year: 0,
  how_you_are_here: '',
  how_you_here_image: '',
  tax_assistant_image: '',
  tax_status: 'Pending',
  income_status: '',
  income_received_date: '',
  income_completed_date: '',
  expense_status: '',
  expense_received_date: '',
  expense_completed_date: '',
  is_called: false,
  is_paid_status: 'Blank',
  tax_code_complete: false,
  pre_payment_status: 0,
  refund_amount: 0,
  agent_fee: 0,
  agent_fee_type: '',
  agent_fee_percentage: 0,
  vat_amount: 0,
  vat_percentage: 0,
  pre_pay_agent_fee: 0,
  pre_pay_agent_fee_type: '',
  pre_pay_agent_fee_percentage: 0,
  pre_pay_vat_percentage: 0,
  pre_pay_vat_amount: 0,
  paid_date: '',
  estimated_refund_date: '',
  send_notifications: false,
  send_sms: false,
  send_email: false,
  last_login: '',
  user_tax_modified_date: '',
  created_ip: '',
  tax_status_process_date: '',
  tax_status_paid_date: '',
  is_paye_employee: false,
  has_dual_employee: false,
  is_multiple_locations: false,
  is_pub_priv_travel: false,
  reach_by: '',
  status_info: [],
  notifications_count: 0,
  message: '',
  created_date: '',
  is_estimated: false,
  has_button_expense: false,
  url_add_edit_expense: '',
  is_add_expense: false,
  self_income_gross: 0,
  other_income_gross: 0,
  self_expenses_total: 0,
  paye_income_gross: 0,
  benefits_added: 0,
  paye_expenses_total: 0,
  paye_expenses_new_total: 0,
  is_push_notification: false,
  is_sms: false,
  modified_date: '',
  ddi: 0,
  sanction_similarity_percentage: 0,
  sanctioned: false,
  device: '',
  how_you_here: '',
  service_type: '',
  is_accept_marketing_purposes: false,
  city: '',
  referral_code: '',
  device_log: {
    brand: '',
    deviceId: '',
    systemVersion: '',
    version: '',
  },
};
