import React, { useEffect } from 'react';

import { navigate } from 'gatsby';

import { Button, Card, CardBody, Toastify, observer } from 'src/modules';
import store from 'src/stores';
import { Routes } from 'src/utils';

type Props = {
  path: string;
};

const Setup2faPage: React.FC<Props> = () => {
  const auth = store.auth;

  const navigateToOTP = () => {
    navigate(Routes.OTP, {
      replace: true,
    });
  };

  const getQrCode = async () => {
    await auth.setup2fa();
  };

  useEffect(() => {
    getQrCode();
  }, []);

  useEffect(() => {
    const wrapper = document.getElementById('gatsby-focus-wrapper');
    wrapper &&
      wrapper.classList.add(
        'd-flex',
        'justify-content-center',
        'align-items-center',
      );

    return () => {
      wrapper &&
        wrapper.classList.remove(
          'd-flex',
          'justify-content-center',
          'align-items-center',
        );
    };
  }, []);

  return (
    <div className="w-50" style={{ maxWidth: '600px' }}>
      <Card>
        <CardBody>
          <div className="m-sm-4 align-items-center d-flex flex-column">
            <p className="text-center">
              Set up your two factor authentication by scanning the barcode
              bellow. Alternatively, you can user the code{' '}
              <span className="font-weight-bold">
                {auth.twoFAData.secret_key}
              </span>
              .
            </p>
            <img
              className="w-50"
              src={`data:image/svg+xml;base64,${auth.twoFAData.QR_image}`}
            />
            <p className="text-center">
              You must set up your 2FA app before continuing. You will be unable
              to log in otherwise.
            </p>
            <Button
              className="mt-3"
              color="primary"
              size="lg"
              onClick={navigateToOTP}
            >
              Complete Registration
            </Button>
          </div>
        </CardBody>
      </Card>
      <Toastify.ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default observer(Setup2faPage);
