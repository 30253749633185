import request from '../request';

export const getListTeams = async () => {
  const { data } = await request.get(`/admin/teams/list`);

  return data;
};

export const createTeam = async (teamLabel: string) => {
  const { data } = await request.post(`/admin/teams/create`, {
    team_label: teamLabel,
  });

  return data;
};

type EditTeam = {
  id: number;
  teamLabel: string;
};

export const editTeam = async ({ id, teamLabel }: EditTeam) => {
  const { data } = await request.post(`/admin/teams/edit`, {
    id,
    team_label: teamLabel,
  });

  return data;
};

type DeleteType = {
  id: number;
};

export const deleteTeam = async ({
  id,
}: DeleteType): Promise<{ id: number }> => {
  const { data } = await request.delete(`/admin/teams/delete`, {
    data: { id },
  });

  return data;
};

export const addAssistantToTeam = async ({
  assistant_id,
  team_id,
}: AssistantParamType) => {
  const { data } = await request.post(`/admin/teams/add-assistant`, {
    assistant_id,
    team_id,
  });

  return data;
};

export const removeAssistantTeam = async ({
  assistant_id,
  team_id,
}: AssistantParamType) => {
  const { data } = await request.delete(
    `/admin/teams/remove-assistant?assistant_id=${assistant_id}&team_id=${team_id}`,
  );

  return data;
};
