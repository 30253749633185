import React, { useState } from 'react';

import { AssistantsData } from 'src/utils/staffDataset';

import { TagsCell, TagsColName, TagsColValue, TagsRow } from './styles';

type Props = {
  item: AssistantsData;
  index: number;
};

const StaffRow: React.FC<Props> = ({ item, index }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRow = () => setIsOpen((isOpen) => !isOpen);

  const renderCols = () => {
    if (!item?.values) return;
    const arr: any = [];

    const cols = item.values.length;

    for (let i = 0; i < cols; i++) {
      arr[i] = (
        <TagsRow key={`col-${i}`}>
          {item.values[i].map((tag: any, ti: number) => {
            if (tag.quantity > 0)
              return (
                <TagsCell key={`cell-${i}-${ti}`}>
                  <TagsColName>{tag.name}:</TagsColName>
                  <TagsColValue>{tag.quantity}</TagsColValue>
                </TagsCell>
              );
            return <React.Fragment key={`cell-${i}-${ti}`}></React.Fragment>;
          })}
        </TagsRow>
      );
    }

    return arr;
  };

  return (
    <>
      <tr onClick={toggleRow}>
        <td className="text-left">{item.assistant}</td>
        {item &&
          item?.data.map((count, di) => (
            <td key={`count-${count}-${di}`}>
              {count ? count : <span className="opacity-2">{0}</span>}
            </td>
          ))}
        <td className={index % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}>
          <strong>{item.total}</strong>
        </td>
      </tr>
      {isOpen && (
        <tr>
          <td></td>
          {renderCols()}
          <td></td>
        </tr>
      )}
    </>
  );
};

export default StaffRow;
