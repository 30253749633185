import { styled } from 'src/modules';

export const Input = styled.input`
    border: 1px solid #222;
    border-radius: 4px;
    height: 30px;
    width: 250px;
    padding-left: 20px;
    font-size: 14px;
    margin-bottom: 10px;

    &:focus {
        outline: none;
    }

    &::placeholder {
			font-size: 14px;
    }
`;
