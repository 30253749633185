import React, { memo, useState } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loading } from "../ui";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/modules";
import API from "src/data/API";
import { TAgentFeeType, TTaxStatus } from "src/models";
import { formatCurrency } from "src/utils";
import request from "src/api/request";
import ResponseError from "src/api/ResponseError";

interface TaxRefundAmountProps {
  id: number;
  tax_id: number;
  year: number;
  amount: number;
  agent_fee: number;
  agent_fee_percentage: number;
  agent_fee_type: TAgentFeeType;
  tax_stauts: TTaxStatus;
}

// Tax Refund
// Popup - same as above
// /user-taxes/get-refunds?user_id=998&tax_year=2021
// change: /users/update-tax-detail
// tax_id - get from customer list

// agent_fee_type == “custom” then use agent_fee
// agent_fee_type == “Percentage” then use agente_fee_percentage

// CANNOT EDIT AGENT FEE IF:
// tax_status = ‘paid’ or agent_fee_type = ’“Percentage”

const TaxRefundAmount = ({
  id,
  tax_id,
  amount,
  year,
  agent_fee,
  agent_fee_percentage,
  agent_fee_type,
  tax_stauts,
}: TaxRefundAmountProps) => {
  const [local, setLocal] = useState<number>(amount);
  const [loading, setLoading] = useState(false);

  const disabledFeeEdit =
    tax_stauts === "Paid" || agent_fee_type === "Percentage";

  const [newAmount, setNewAmount] = useState(amount);
  const [newFees, setNewFees] = useState(
    agent_fee_type === "Percentage" ? `${agent_fee_percentage}%` : agent_fee
  );

  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const showChangeModal = () => {
    setModal(true);
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { data: res, status } = await request.post(
        API.USERS_UPDATE_TAX_AMOUNT,
        {
          tax_id,
          total_refund: newAmount,
          agent_fee: newFees,
        }
      );

      if (status === 200) {
        setLocal(res.total_refund);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="tax-refund-amount" onClick={showChangeModal}>
        <h4 className="m-0">
          {formatCurrency(local)} <FontAwesomeIcon size="sm" icon={faPen} />
        </h4>
      </div>
      {modal && (
        <Modal centered isOpen={true} toggle={modalToggle}>
          <ModalHeader toggle={modalToggle}>New Request Status</ModalHeader>
          <ModalBody className="d-flex flex-wrap">
            <Form>
              <FormGroup>
                <Label for="refund">Total Refund</Label>
                <Input
                  type="number"
                  name="refund"
                  value={newAmount}
                  onChange={(e) => setNewAmount(parseFloat(e.target.value))}
                />
              </FormGroup>
              <FormGroup>
                <Label for="fees">Agent Fees</Label>
                <Input
                  disabled={disabledFeeEdit}
                  type={agent_fee_type === "Percentage" ? "text" : "number"}
                  name="fees"
                  value={newFees}
                  onChange={(e) => setNewFees(parseFloat(e.target.value))}
                />
              </FormGroup>
            </Form>
            {loading && (
              <div className="w-100 mb-2 overflow-hidden">
                <Loading />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onSubmit}>
              Submit
            </Button>
            <Button color="secondary" onClick={modalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default memo(TaxRefundAmount);
