import moment from 'moment';

import { AssistantsTotalData } from './staffDataset';

export enum DashboardType {
  SIGNUPS = 'Signups',
  AGENT_FEE = 'Agent Fee',
  SUBMITTED = 'Submitted',
}

export type TDashboardDataset = {
  label: string;
  data: Array<number>;
  amounts: Array<number>;
  backgroundColor: string | Array<string>;
  total: number;
  totalAmounts: number;
  [key: string]: any;
};

export type TStaffDataset = {
  label: string;
  data: Array<number>;
  backgroundColor: string | Array<string>;
  total?: number;
  totalNormalised?: number;
};

type TStackedBarData = {
  periodTotals: Array<number>;
  periodAmountTotals: Array<number>;
  datasets: Array<TDashboardDataset>;
  days: Array<string>;
  labels: Array<string>;
  total: number;
  totalAmounts: number;
  [key: string]: any;
};

export type THorizontalBarChart = {
  labels: Array<string>;
  datasets: Array<TStaffDataset>;
};

type TPieData = {
  labels: Array<string>;
  datasets: Array<TDashboardDataset>;
};

type TLineData = {
  labels: Array<string>;
  datasets: Array<TDashboardDataset>;
};

export type TDashboardCharts = {
  stackedBar: TStackedBarData;
  pie: TPieData;
  line: TLineData;
};

export type TCategoriesCharts = {
  title: string;
  color: string;
};

const findIndex = (dates: Array<string>, day: string) => {
  return dates.findIndex((item) => item === day);
};

export const sortResults = (prop: string, asc: boolean, val: any[]) => {
  return val.sort((a, b) => {
    if (asc) {
      return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
    } else {
      return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
    }
  });
};

export const returnDataStaff = (
  values: AssistantsTotalData[],
  normalize: boolean,
): THorizontalBarChart => {
  const labels: Array<string> = [];
  const datasets: Array<TStaffDataset> = [];
  const data: Array<number> = [];
  const sortValue = normalize ? 'normalizeValue' : 'total';
  sortResults(sortValue, false, values);
  values.map((v: AssistantsTotalData) => {
    labels.push(v.assistant);
    if (!normalize) {
      data.push(v.total);
    } else {
      data.push(v.normalizeValue);
    }
  });
  const obj: TStaffDataset = {
    label: normalize ? 'Normalised' : 'Total',
    data: data,
    backgroundColor: '#FFDD05',
  };
  datasets.push(obj);

  const obj2: TStaffDataset = {
    label: normalize ? 'Normalised' : 'Total 2',
    data: data,
    backgroundColor: '#000000',
  };
  datasets.push(obj2);

  return {
    labels,
    datasets,
  };
};

export const returnDataStaffByCategories = (
  values: Array<Array<AssistantsTotalData>>,
  valuesTotal: AssistantsTotalData[],
  categories: Array<TCategoriesCharts>,
  normalize: boolean,
): THorizontalBarChart => {
  const labels: Array<string> = [];
  const datasets: Array<TStaffDataset> = [];
  const sortValue = normalize ? 'normalizeValue' : 'total';
  sortResults(sortValue, false, valuesTotal);
  valuesTotal.map((v: AssistantsTotalData) => {
    labels.push(v.assistant);
  });

  values.map((v: Array<AssistantsTotalData>, index: number) => {
    const data: Array<number> = [];
    v.map((v: AssistantsTotalData) => {
      const index = labels.indexOf(v.assistant);
      if (!normalize) {
        data[index] = v.total;
      } else {
        data[index] = v.normalizeValue;
      }
    });
    const obj: TStaffDataset = {
      label: categories[index].title,
      data: data,
      backgroundColor: categories[index].color,
      total: normalize
        ? valuesTotal[index].normalizeValue
        : valuesTotal[index].total,
    };
    datasets.push(obj);
  });

  return {
    labels,
    datasets,
  };
};

const getSourceColor = (index: number) => {
  const colours = [
    '#3498db',
    '#d2dae2',
    '#9a9a9a',
    '#808080',
    '#3b5998',
    '#ff5e57',
    '#4bcffa',
    '#ffa801',
    '#0e76a8',
    '#e67e22',
    '#d1d8e0',
    '#ffd32a',
    '#ff3f34',
  ];

  return colours[index];
};

// const getPeriodColor = (index: number) => {
//   const colorsArray = [
//     'rgb(240, 112, 74)',
//     'rgb(248, 142, 83)',
//     'rgb(252, 172, 99)',
//     'rgb(253, 198, 118)',
//     'rgb(254, 221, 141)',
//     'rgb(254, 238, 163)',
//     'rgb(251, 248, 176)',
//     'rgb(241, 249, 171)',
//     'rgb(224, 243, 161)',
//     'rgb(200, 233, 159)',
//     'rgb(169, 221, 162)',
//     'rgb(137, 207, 165)',
//     'rgb(105, 189, 169)',
//     'rgb(78, 164, 176)',
//     'rgb(66, 136, 181)',
//     'rgb(74, 108, 174)',
//   ];
//   return colorsArray[index];
// };

/**
 * Create an array of dates and an array of totals to be used with incoming data
 * @dates = arrays of dates to be used as labels and x-axis
 * @totals = totals calculation for each date, y-axis
 */
const prepareDataset = (length: number, from: Date, to: Date) => {
  const dates = [];
  const labels = [];
  for (let index = 0; index < length; index++) {
    dates[index] = moment(to).subtract(index, 'days').format('DD/MM/YYYY');
    labels[index] = moment(dates[index], 'DD/MM/YYYY').format('DD/MM');
  }

  // TODO:
  // workaround, this is ugly
  // the loop should take care of the right order
  dates.reverse();
  labels.reverse();

  return { dates, labels };
};

// This is only nedeed until the backed fixes the dates array
// and outputs empty days with 0
const sortDateforAxis = (
  backendDates: Array<any>,
  baseDates: Array<string>,
) => {
  // create am empty array with the expected dataset length
  const count = Array(baseDates.length).fill(0);
  const amounts = Array(baseDates.length).fill(0);
  // console.log(backendDates);
  // loop thru backend dates and add it to its place on the array
  for (let i = 0; i < backendDates.length; i++) {
    const date: string = backendDates[i].date;
    const users: number = backendDates[i].users || 0;
    const fee: number = backendDates[i].amount || 0;
    // console.log(`🚀 ~ `, date, i, users, backendDates[i].amount);
    const index = findIndex(baseDates, date);
    count[index] = users;
    amounts[index] = fee;
  }

  return { count, amounts };
};

const sortDateforAxisSubmitted = (
  backendDates: Array<any>,
  baseDates: Array<string>,
) => {
  // create am empty array with the expected dataset length
  const count = Array(baseDates.length).fill(0);
  const amounts = Array(baseDates.length).fill(0);
  const agentFee = Array(baseDates.length).fill(0);
  const userPayout = Array(baseDates.length).fill(0);
  const userYear = Array(baseDates.length).fill(0);
  // console.log(backendDates);
  // loop thru backend dates and add it to its place on the array
  for (let i = 0; i < backendDates.length; i++) {
    const date: string = backendDates[i].date;
    const users: number = backendDates[i].users || 0;
    const fee: number = backendDates[i].agent_fee_with_vat || 0;
    const agFee: number = backendDates[i].agent_fee || 0;
    const usPayout: number = backendDates[i].user_payout || 0;
    const usYear: any = backendDates[i].users_per_year || 0;
    // console.log(`🚀 ~ `, date, i, users, backendDates[i].amount);
    const index = findIndex(baseDates, date);
    count[index] = users;
    amounts[index] = fee;
    agentFee[index] = agFee;
    userPayout[index] = usPayout;
    userYear[index] = parseInt(usYear);
  }

  return { count, amounts, agentFee, userPayout, userYear };
};

export const getDashboardDataSet = (
  data: any,
  title: DashboardType,
): TDashboardCharts | undefined => {
  if (!data) return;
  const labels: any[] = [];
  data[0].dates.map((v: any) => {
    labels.push(v.date);
  });

  const datasets: Array<TDashboardDataset> = [];
  const colors: Array<string> = [];

  // lop through the data and add the count to the right date
  for (let i = 0; i < data.length; i++) {
    const values: any =
      title == DashboardType.SUBMITTED
        ? sortDateforAxisSubmitted(data[i].dates, labels)
        : sortDateforAxis(data[i].dates, labels);
    // console.log(`🚀 ~ file: dashboardDataset.ts ~ line 134 ~ count`, count);
    // console.log(`🚀 ~ file: dashboardDataset.ts ~ line 134 ~ amounts`, amounts);
    colors[i] = getSourceColor(i);

    datasets[i] = {
      label: data[i].name,
      data: values.count,
      amounts: values.amounts,
      agentFee: title == DashboardType.SUBMITTED ? values.agentFee : null,
      userPayout: title == DashboardType.SUBMITTED ? values.userPayout : null,
      usersYear: title == DashboardType.SUBMITTED ? values.userYear : null,
      total: values.count.reduce((x: any, y: any) => x + y, 0),
      totalAmounts: values.amounts.reduce((x: any, y: any) => x + y, 0),
      totalAgentFee:
        title == DashboardType.SUBMITTED
          ? values.agentFee.reduce((x: any, y: any) => x + y, 0)
          : null,
      totalUserPayout:
        title == DashboardType.SUBMITTED
          ? values.userPayout.reduce((x: any, y: any) => x + y, 0)
          : null,
      totalUserYear:
        title == DashboardType.SUBMITTED
          ? values.userYear.reduce(
              (x: any, y: any) => parseInt(x) + parseInt(y),
              0,
            )
          : null,
      backgroundColor: colors[i],
    };
  }

  // Daily totas for each day
  const days = labels.length;
  const periodTotals = Array(days).fill(0);
  const periodAmountTotals = Array(days).fill(0);
  const periodAgentFeeTotals = Array(days).fill(0);
  const periodUserPayoutTotals = Array(days).fill(0);
  const periodUserYearTotals = Array(days).fill(0);
  for (let i = 0; i < days; i++) {
    for (let j = 0; j < datasets.length; j++) {
      periodTotals[i] += datasets[j].data[i];
      periodAmountTotals[i] += datasets[j].amounts[i];
      periodAgentFeeTotals[i] +=
        title == DashboardType.SUBMITTED ? datasets[j].agentFee[i] : 0;
      periodUserPayoutTotals[i] +=
        title == DashboardType.SUBMITTED ? datasets[j].userPayout[i] : 0;
      periodUserYearTotals[i] +=
        title == DashboardType.SUBMITTED ? datasets[j].usersYear[i] : 0;
    }
  }
  // console.log(periodTotals);

  const labelsWithTotals = labels.map(
    (label, index) => `${label} (${periodTotals[index]})`,
  );

  const chartData: TStackedBarData = {
    labels: labelsWithTotals,
    days: labels,
    datasets,
    periodTotals,
    periodAmountTotals,
    periodAgentFeeTotals,
    periodUserPayoutTotals,
    periodUserYearTotals,
    total: periodTotals.reduce((x, y) => x + y, 0),
    totalAmounts: periodAmountTotals.reduce((x, y) => x + y, 0),
    totalAgentFee: periodAgentFeeTotals.reduce((x, y) => x + y, 0),
    totalUserPayout: periodUserPayoutTotals.reduce((x, y) => x + y, 0),
    totalUserYear: periodUserYearTotals.reduce(
      (x, y) => parseInt(x) + parseInt(y),
      0,
    ),
  };
  // console.log(chartData);
  const piePeriodData: TPieData = {
    labels: datasets.map((item) => item.label),
    datasets: [
      {
        label: title,
        data: datasets.map((dataset) => dataset.total),
        backgroundColor: colors,
        borderWidth: 0,
        total: 0,
        amounts: [0],
        totalAmounts: 0,
      },
    ],
  };

  const dataSetsLine: any = [];
  datasets.map((item, index) => {
    const obj = {
      label: item.label,
      data: item.data,
      borderColor: colors[index],
      backgroundColor: colors[index],
    };
    dataSetsLine.push(obj);
  });
  const linePeriodData: TLineData = {
    labels: labels.map((item) => item),
    datasets: dataSetsLine,
  };
  //   console.log(piePeriodData);

  return { stackedBar: chartData, pie: piePeriodData, line: linePeriodData };
};
