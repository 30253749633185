import { useState, useEffect } from 'react';

import { window } from 'browser-monads';

import { isValidData } from '../utils';

// @TODO: REMOVE ALL THIS FILE AND USE SERVICES

const useLocalStorage = (key: string, initValue: unknown) => {
  const getStored = (key: string) => {
    try {
      const item = window.localStorage.getItem(key);
      return item && isValidData(item) ? JSON.parse(item) : initValue;
    } catch (error) {
      console.log(error);
      return initValue;
    }
  };

  const setValue = (value: unknown) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  const [storedValue, setStoredValue] = useState();

  useEffect(() => {
    setStoredValue(getStored(key) || initValue);
  }, []);

  return [storedValue, setValue] as const;
};

export { useLocalStorage };
