import request from '../request';

export const fetchTemplates = async (): Promise<InboxTemplateTypes[]> => {
  const { data } = await request.get(`message-templates?per_page=100`);

  return data.data;
};

export const fetchById = async (id: number): Promise<InboxTemplateTypes> => {
  const { data } = await request.get(`message-templates/edit/?id=${id}`);

  return data.data;
};

export const updateById = async ({
  id,
  title,
  message,
}: InboxTemplateUpdate): Promise<void> => {
  await request.post(`message-templates/update/?id=${id}`, {
    title,
    message,
  });
};

export const deleteById = async (id: number): Promise<void> => {
  await request.delete(`message-templates/delete?id=${id}`);
};

export const create = async ({
  message,
  title,
}: InboxTemplateCreate): Promise<void> => {
  await request.post(`message-templates/create`, {
    title,
    message,
    admin_message: 'to_remove',
    dynamic_message_keywords: 'to_remove',
    dynamic_admin_message_keywords: 'to_remove',
    tag: 'to_remove',
    display_order: 1,
  });
};
