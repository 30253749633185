import React, { useEffect, useState } from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Row } from 'reactstrap';

import { TaxIncomeApi } from 'src/api';
import { getExpensesCategories } from 'src/api/users';
import { Toastify } from 'src/modules';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import {
  PrevNextSection,
  Button,
  PrevNextInputSection,
  Input,
  SubmitButton,
} from '../../../styles';

import {
  FooterTitle,
  SliptRange,
  SplitContainer,
  SplitSection,
  Select,
  PrevNextFields,
  TextArea,
  NotesAndSplitSection,
  CategorySection,
  CategoryIdLabel,
} from './styles';

function SelfEmpTransactionsForm(props: any) {
  const [transactionsListState, setTransactionsListState] = useState([]);
  const [selectedItem, setSelectedItem] = useState<undefined | {}>(undefined);
  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
  const [sourceId, setSourceId] = useState(props.selectedItem?.income_id);
  const [categorySelected, setCategorySelected] = useState(
    props.selectedItem?.category_id.id,
  );

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  useEffect(() => {
    setTransactionsListState(props.transactionsListState);
  }, [props.transactionsListState]);

  useEffect(() => {
    setSelectedItem(transactionsListState[currentTransactionIndex]);
  }, [currentTransactionIndex]);

  const { data: dataCategories, isLoading: isLoadingCategories } = useQuery(
    'getExpensesCategories',
    () => getExpensesCategories('self_employed'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate } = useMutation(
    async () => {
      const selectedIncome = props.allHeaderList.filter(
        (income) => income.id === props.incomeIdSelected,
      )[0];

      const incomeType = selectedIncome?.['self_employment_type']
        ? 'self_employed'
        : 'employment';

      return await TaxIncomeApi.editTransaction(
        props.userId,
        selectedItem?.id,
        props.year,
        sourceId || props.selectedItem?.income_id,
        incomeType,
        categorySelected || props.selectedItem?.category_id.id,
        selectedItem?.note || '',
        selectedItem?.work_percentage || 0,
        // selectedItem?.CIS,
      );
    },
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);
        props.refetch();
      },
      onError({ response }) {
        Toastify.toast.error(response?.data?.message);
      },
    },
  );

  const handleSelectedItemChange = (key: string, value: string) => {
    setSelectedItem((prevState) => ({ ...prevState, [key]: value }));
  };

  const handlePrevClick = () => {
    if (currentTransactionIndex > 0)
      setCurrentTransactionIndex(currentTransactionIndex - 1);
  };

  const handleNextClick = () => {
    if (currentTransactionIndex < transactionsListState.length - 1)
      setCurrentTransactionIndex(currentTransactionIndex + 1);
  };

  return (
    <>
      <PrevNextSection>
        <Button onClick={() => handlePrevClick()}>
          <BsArrowLeft size={18} />
          <span>Previous</span>
        </Button>

        <PrevNextFields>
          <PrevNextInputSection>
            <label htmlFor="payee">Merchant name</label>
            <Input
              id="payee"
              type="text"
              value={selectedItem?.merchant_name ?? ''}
              onChange={() => {}}
              disabled
            />
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="date">Date</label>
            <Input
              id="date"
              type="text"
              value={formatDate(selectedItem?.created_at) ?? ''}
              onChange={() => {}}
              disabled
            />
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="gross">Amount</label>
            <Input
              id="gross"
              type="text"
              value={formatCurrency(
                `${selectedItem?.amount + selectedItem?.CIS}`,
              )}
              onChange={() => {}}
              disabled
            />
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="gross">NET</label>
            <Input
              id="net"
              type="text"
              value={formatCurrency(`${selectedItem?.amount}`)}
              onChange={() => {}}
              disabled
            />
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="gross">TAX</label>
            <Input
              id="TAX"
              type="text"
              value={formatCurrency(`${selectedItem?.CIS}`)}
              onChange={() => {}}
              disabled
            />
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="year">Year</label>
            <Select id="year" disabled>
              <option value="2020">2020</option>
            </Select>
          </PrevNextInputSection>

          <PrevNextInputSection>
            <label htmlFor="source">Source</label>
            <Select
              id="source"
              onChange={(event) => setSourceId(Number(event.target.value))}
            >
              {props?.allHeaderList.map((item) => {
                return (
                  <>
                    <option
                      key={item.id}
                      value={item.id}
                      selected={item?.id === selectedItem?.income_id}
                      disabled={item?.id === selectedItem?.income_id}
                    >
                      {item.employer_name}
                    </option>
                  </>
                );
              })}
            </Select>
          </PrevNextInputSection>

          <CategorySection>
            <CategoryIdLabel htmlFor="category_id" className="title">
              Category
            </CategoryIdLabel>{' '}
            <br />
            <Select
              key={selectedItem?.category_id.id}
              id={`category_id_${selectedItem?.category_id?.id}`}
              name="category_id"
              onChange={(e) => setCategorySelected(Number(e.target.value))}
            >
              {selectedItem?.category_id === null && (
                <option>Select an option</option>
              )}

              {selectedItem?.category_id && (
                <option>{selectedItem?.category_id?.category}</option>
              )}

              {!isLoadingCategories &&
                dataCategories.data.map((category) => {
                  return (
                    <>
                      <option key={category.id} value={category.id}>
                        {category.category}
                      </option>
                    </>
                  );
                })}
            </Select>
          </CategorySection>
        </PrevNextFields>

        <Button onClick={() => handleNextClick()} style={{ marginLeft: 20 }}>
          <BsArrowRight size={18} />
          <span>Next</span>
        </Button>
      </PrevNextSection>

      <NotesAndSplitSection>
        <PrevNextInputSection>
          <label htmlFor="notes">Notes</label>
          <TextArea
            id="notes"
            value={selectedItem?.note || ''}
            onChange={(e) => handleSelectedItemChange('note', e.target.value)}
            rows={10}
          />
        </PrevNextInputSection>

        <PrevNextInputSection>
          <SplitSection>
            <Row>
              <FooterTitle>Split</FooterTitle>
            </Row>
            <Row className="justify-content-center">
              <FooterTitle>{selectedItem?.work_percentage || 0} %</FooterTitle>
            </Row>

            <SplitContainer>
              <SliptRange
                type="range"
                id="work-percentage"
                className="split-range-label"
                min={0}
                max={100}
                step={1}
                value={parseInt(selectedItem?.work_percentage || 0)}
                onChange={(e) =>
                  handleSelectedItemChange('work_percentage', e.target.value)
                }
              />
            </SplitContainer>

            <Row className="justify-content-between">
              <FooterTitle>
                Personal{' '}
                {formatCurrency(
                  `${
                    (parseInt(selectedItem?.work_percentage || 0) / 100) *
                    selectedItem?.amount
                  }`,
                )}
              </FooterTitle>
              <FooterTitle>
                {formatCurrency(
                  `${
                    (1 - parseInt(selectedItem?.work_percentage || 0) / 100) *
                    selectedItem?.amount
                  }`,
                )}{' '}
                Work{' '}
              </FooterTitle>
            </Row>
          </SplitSection>
        </PrevNextInputSection>
      </NotesAndSplitSection>
      <div>
        <SubmitButton disabled={false} onClick={() => mutate()}>
          Submit
        </SubmitButton>
      </div>
    </>
  );
}

export default SelfEmpTransactionsForm;
