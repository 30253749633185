import { AssistantsApi } from 'src/api';
import { action, observable } from 'src/modules';

export default class AssistantStore {
  @observable
  data: AssistantData[] = [];

  @action
  fetchAdmins = async (): Promise<AssistantData[]> => {
    const response = await AssistantsApi.fetchAssistants();
    this.data = response;
    return response;
  };
}
