import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { fetchAssistantsChanges } from 'src/api/assistants';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { TableColumn } from 'src/components/InboxTemplateRow/styles';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, Toastify } from 'src/modules';
import store from 'src/stores';

const AssistantChanges: React.FC = () => {
  const messageStore = store.assistants;

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);
  const [adminsList, setAdminsList] = useState<AdminData[]>([]);

  const { data: listAssistantChanges = [], isLoading } = useQuery(
    ['listAssistantChanges', nextPage],
    fetchAssistantsChanges,
    {
      onSuccess: ({
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },

      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    messageStore.fetchAdmins().then((response) => setAdminsList(response));
  }, []);

  const getName = (fieldID: number) => {
    if (adminsList.length > 0) {
      for (let index = 0; index < adminsList.length; index++) {
        const admin = adminsList[index];
        // console.log(index, admin.id, fieldID);
        if (admin.id === fieldID) return `${admin.name} (${fieldID})`;
      }
      return fieldID && fieldID > 0 ? `(${fieldID})` : '-';
      // return adminsList.map((admin) => admin.id === field && admin.name);
    }
  };

  return (
    <Layout>
      <Dashboard title="Assistant Changes">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="2" className="d-flex justify-content-center">
                New Assistant
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Previous Assistant
              </Col>
              <Col xs="3" className="d-flex justify-content-center">
                Customer
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Changed by
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Date
              </Col>
            </TableHead>

            {isLoading && <Loading />}

            {!isLoading &&
              adminsList.length &&
              listAssistantChanges.data.map((item: any, index) => {
                return (
                  <>
                    <TableRow>
                      <TableColumn
                        xs="2"
                        className="d-flex justify-content-center"
                      >
                        {getName(item.new_id)}
                      </TableColumn>
                      <TableColumn
                        xs="2"
                        className="d-flex justify-content-center"
                      >
                        {getName(item.previous_id)}
                      </TableColumn>
                      <TableColumn
                        xs="3"
                        className="d-flex justify-content-center"
                      >
                        <a
                          href={`/app/customer-information/?id=${item.customer_id}&tab=details&tax_year=${item.tax_year}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.customer_name}
                        </a>
                      </TableColumn>
                      <TableColumn
                        xs="2"
                        className="d-flex justify-content-center"
                      >
                        {getName(item.changed_by_id)}
                      </TableColumn>
                      <TableColumn
                        xs="2"
                        className="d-flex justify-content-center"
                      >
                        {item.time}
                      </TableColumn>
                    </TableRow>
                  </>
                );
              })}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default AssistantChanges;
