import React, { useEffect, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { deleteTransaction, getExpenseTransactions } from 'src/api/incomes';
import CustomPagination from 'src/components/CustomPagination';
import { Toastify } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import { handleStatusColor } from '../../..';
import { NewItemButton } from '../../../styles';
import TransactionForm from '../TransactionForm';

import {
  Actions,
  Circle,
  Container,
  SectionAddNewItem,
  TableBody,
  TableHeader,
} from './styles';

function TransactionsList({
  incomeType,
  incomeId,
  setOpenAddTransactionForm,
  openAddTransactionForm,
  allHeaderList,
  incomeIdSelected,
  userId,
  year,
  refetchList,
  isSuccess,
  isTabAll,
}) {
  const [transactionsListState, setTransactionsListState] = useState([]);
  const [selectedItem, setSelectedItem] = useState<undefined | {}>(undefined);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  const incomes = store.incomes;
  const expenses = store.expenses;

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
  }, []);

  const { isLoading, refetch } = useQuery(
    ['getExpenseTransactions', incomeType, incomeId, nextPage],
    () => getExpenseTransactions(incomeType, incomeId, nextPage),
    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);
        incomes.setIncomeTransactions(result.data);
        incomes.setPayeOrSelfEmployedTotal(result.totals);

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  useEffect(() => {
    if (!isTabAll) {
      refetchList(refetch);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (transactionsListState.length > 0)
      setSelectedItem(transactionsListState[0]);
  }, [transactionsListState]);

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        refetch();
        expenses.refetchTotals();
        expenses.refetchSummary();
      }, 1000);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  return (
    <>
      <Container>
        <TableHeader>
          <Row>
            <Col className="headerColumn"></Col>
            <Col className="headerColumn">Merchant name</Col>
            <Col className="headerColumn">Date</Col>
            <Col className="headerColumn">Gross</Col>
            <Col className="headerColumn" lg={2}></Col>
          </Row>
        </TableHeader>

        {!isLoading &&
          transactionsListState.map((item) => {
            return (
              <TableBody
                key={item.id}
                onClick={() => {
                  setSelectedItem(item);
                }}
              >
                <Row role="button">
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: 30 }}
                  >
                    <Circle background={handleStatusColor(item.status)} />
                  </Col>
                  <Col className="bodyColumn">
                    {item?.merchant_name ?? item?.description ?? ''}
                  </Col>
                  <Col className="bodyColumn">
                    {formatDate(item.created_at)}
                  </Col>
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: -20 }}
                  >
                    {formatCurrency(item.amount + item.CIS)}
                  </Col>
                  <Col lg={2} className="bodyColumn">
                    <Actions>
                      <BsFillTrashFill
                        color="red"
                        size="20"
                        onClick={() => onDeleteTransaction(item.id)}
                      />
                    </Actions>
                  </Col>
                </Row>
              </TableBody>
            );
          })}

        {!isLoading && transactionsListState.length < 1 && (
          <span>No transactions</span>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}
      </Container>

      {!isTabAll && (
        <SectionAddNewItem>
          <NewItemButton
            onClick={() => setOpenAddTransactionForm(!openAddTransactionForm)}
          >
            <AiOutlinePlus size={18} />
          </NewItemButton>
        </SectionAddNewItem>
      )}

      {transactionsListState.length > 0 ? (
        <TransactionForm
          selectedItem={selectedItem}
          transactionsListState={transactionsListState}
          allHeaderList={allHeaderList}
          incomeIdSelected={incomeIdSelected}
          userId={userId}
          year={year}
          refetch={refetch}
        />
      ) : null}
    </>
  );
}

export default TransactionsList;
