import React from 'react';

import { Image, Container } from './styles';

type Sizes = 'sm' | 'md' | 'lg' | 'xl';

interface Props {
  src: string;
  size?: Sizes;
  isOpen: boolean;
}

const sizes = {
  sm: 32,
  md: 36,
  lg: 45,
  xl: 57,
};

export default function Avatar({ src, size = 'md', isOpen }: Props) {
  return (
    <Container isOpen={isOpen}>
      <Image src={src} size={sizes[size]} />
    </Container>
  );
}
