import styled from 'styled-components';

type Props = {
  color?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row; /* Alinha os 'Body' lado a lado */
  background-color: #fff;
  border-radius: 6px;
  margin-left: 12px;
  padding: 10px; /* Ajusta o padding conforme necessário */
  position: relative;
  height: 93px;
`;

export const Title = styled.span`
  font-size: 14px;
  text-align: center; /* Centraliza o título */
`;

export const Body = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Adiciona espaçamento entre os 'Body' */
`;

export const Content = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 8px;
  left: 4px;
`;

export const ReconText = styled.span<Props>`
  position: relative;
  font-size: 16px;
  color: ${(props) => props.color};
  display: flex;
  justify-content: center;
`;

export const NonReconText = styled.span`
  position: relative;
  font-size: 14px;
  color: #929292;
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

export const TaxPercentage = styled.div`
  font-size: 16px;
  color: #209782;
  margin-left: 8px;
  text-align: right;
  white-space: nowrap;
`;
