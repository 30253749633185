import { action, observable } from 'mobx';
import { MailSettingsApi } from 'src/api';

export default class AdminNotesStore {
  @observable
  mails: Mail[] = [];

  @action
  getMails = async (): Promise<void> => {
    const response = await MailSettingsApi.getMails();

    this.mails = response;
  };

  @action
  updateMail = async (updatedMail: Mail): Promise<void> => {
    const response = await MailSettingsApi.updateMail(updatedMail);

    this.mails = this.mails.map((mail) =>
      mail.id === response.id ? response : mail,
    );
  };
}
