import { action, observable, makeObservable } from 'mobx';

import { CustomerApi } from 'src/api';
import {
  getUsersByAssistant,
  getTotalUsersInfoByAssistant,
  getUserBanksInformation,
  getUserProofId,
} from 'src/api/users';
import { customerInitData } from 'src/constants';
import { ICustomerData } from 'src/models';
import {
  CustomerCreateData,
  CustomerInformation,
  CustomerBankInformation,
  CustomerProofId,
} from 'src/utils/types/Customer';

export default class CustomerStore {
  @observable
  customers: ICustomerData = {
    data: [],
    has_more: false,
    total_pages: 0,
    total_entries: 0,
    next_page: 0,
    next_page_url: '',
    previous_page_url: '',
  };

  @observable
  customerData: CustomerInformation = customerInitData;

  @observable
  notificationsCountChanged = false;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchCustomers = async (search?: string): Promise<ICustomerData> => {
    const response = await CustomerApi.fetchCustomers(search);

    this.customers = response;
    return response;
  };

  @action
  setCustomerInformation = (info: CustomerInformation) => {
    this.customerData = info;
  };

  @action
  getCustomerInformation = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const response = await CustomerApi.getCustomerInformation(userId, taxYear);

    this.setCustomerInformation(response);
  };

  @action
  setTaxStatusBlank = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxStatusBlank(
      [userId],
      taxYear,
    );

    const newData = this.customerData;
    newData.is_paid_status = is_paid_status;

    this.setCustomerInformation(newData);
  };

  @action
  setTaxStatusPaid = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxStatusPaid(
      [userId],
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  setMultipleTaxStatusBlank = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxStatusBlank(userId);
  };

  @action
  setTaxPaymentDue = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxPaymentDue(
      [userId],
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  setMultipleTaxStatusPaid = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxStatusPaid(userId);
  };

  @action
  setMultipleTaxPaymentDue = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxPaymentDue(userId);
  };

  @action
  cancelPaymentDue = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.cancelPaymentDue(
      userId,
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  createCustomer = async (data: CustomerCreateData): Promise<void> => {
    await CustomerApi.createCustomer(data);
  };

  @action
  updateUserDetails = async (userId: number, data: CustomerInformation) => {
    await CustomerApi.updateUserDetails(userId, data);
  };

  @action
  getHowYouHere = () => {
    return CustomerApi.getHowYouHere();
  };

  @action
  onNotificationsCountChanged = () => {
    this.notificationsCountChanged = !this.notificationsCountChanged;

    return this.notificationsCountChanged;
  };

  @action
  getUsersByAssistant = (assistant_id: number) => {
    return getUsersByAssistant(assistant_id);
  };

  @action
  getTotalUsersInfoByAssistant = (assistant_id: number) => {
    return getTotalUsersInfoByAssistant(assistant_id);
  };

  @action
  getUserBanksInformation = async (
    userId: number,
  ): Promise<CustomerBankInformation[]> => {
    return getUserBanksInformation(userId);
  };

  @action
  getUserProofId = async (userId: number): Promise<CustomerProofId> => {
    return getUserProofId(userId);
  };
}
