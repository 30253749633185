import { styled } from 'src/modules';

interface Props {
  sequential: number;
}

export const Container = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray.ghost};
  margin-left: 10px;

  :last-child {
    margin-left: initial;
    margin-right: 10px;
  }
`;

export const Title = styled.div`
  margin: 0 25px;
`;

export const SectionChat = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl}px;
`;

export const SectionChatLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
`;

export const SectionChatInput = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 15px;
  align-self: flex-start;
  max-width: 75%;
`;

export const ChatInputContent = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  padding: ${(props) => (props.sequential === 1 ? '5px 9px' : '14px')};
  background: ${(props) => props.theme.colors.white.default};
  border: 1px solid ${(props) => props.theme.colors.gray.geyser};
  border-radius: 20px;
  position: relative;
  margin-left: ${(props) => (props.sequential === 1 ? 'initial' : '36px')};

  max-width: 600px;

  ::before {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.gray.geyser};
    border-width: 0 1px 0 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white.default};
    content: '';
    left: -25px;
    display: ${(props) => (props.sequential === 1 ? 'block' : 'none')};
  }
`;

export const ChatInputPictureContent = styled.div`
  z-index: 1;
  position: relative;
  left: 12px;
`;

export const ChatText = styled.span<Props>`
  position: relative;
  /* right: ${(props) => (props.sequential === 1 ? '30px' : 'initial')}; */
  border: none;
  color: #44444f;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  min-width: 100px;
  word-wrap: break-word;
`;

export const SectionMessageRead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 56px;
  margin: 0 12px;
  height: 40px;
`;

export const MessageSent = styled.div`
  display: flex;
  // justify-content: space-between;
`;

export const MessageUnread = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  cursor: pointer;
`;

export const MessageRead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Time = styled.span`
  color: #92929d;
  font-weight: 400;
  font-size: 8px;
  margin-left: 8px;
`;

export const InCallContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .in-call-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 15px;

    .in-call-img {
      width: 300px;
      margin-right: 40px;
    }

    .updated-at {
      font-size: 12px;
    }
  }
`;

export const RemoveButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.red.dark};
  background-color: ${({ theme }) => theme.colors.white.default};
  width: 80px;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.red.dark};
  font-size: 12px;
  margin: -10px 80px 20px auto;
`;
