import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;

  && .react-datepicker-wrapper {
    flex: 0;
    margin-right: 10px;
  }

  && .react-datepicker-popper {
    z-index: 2;
  }
`;

const StyledInput = styled.input`
  padding: 0.25rem 0.7rem;
  color: #3a4d5f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
`;

const StyledSelect = styled.select`
  padding: 0.25rem 0;
  color: #3a4d5f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
`;

const LabelInfo = styled.div`
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  display: flex;
`;

export { Wrapper, StyledInput, StyledSelect, LabelInfo };
