import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';

import { Layout, TableHead, InboxTemplateRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, Button } from 'src/modules';
import store from 'src/stores';
import { TopWrapper } from 'src/styles/pages/inbox-templates/styles';
import { Routes } from 'src/utils';

const InboxTemplates: React.FC = () => {
  // Mobx store
  const inboxTemplates = store.inboxTemplates;

  // States
  const [loading, setLoading] = useState<boolean>(false);

  const fetchNotificationTemplates = async (): Promise<void> => {
    try {
      setLoading(true);
      await inboxTemplates.fetchTemplates();
    } finally {
      setLoading(false);
    }
  };

  const onAddNew = () => navigate(`${Routes.INBOX_TEMPLATES_CREATE}`);

  const onEdit = (id: number) =>
    navigate(`${Routes.INBOX_TEMPLATES_EDIT}/?id=${id}`);

  const onDelete = async (id: number) => {
    await inboxTemplates.deleteById(id);
    fetchNotificationTemplates();
  };

  useEffect(() => {
    fetchNotificationTemplates();
  }, []);

  return (
    <Layout>
      <Dashboard title="Inbox Templates">
        <TopWrapper>
          <Button color="primary" type="button" onClick={onAddNew}>
            Add New template
          </Button>
        </TopWrapper>
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="1">#</Col>
              <Col xs="3">Title</Col>
              <Col xs="6">Description</Col>
              <Col xs="2">Actions</Col>
            </TableHead>

            {loading && <Loading />}

            {inboxTemplates.templates.map((item) => (
              <InboxTemplateRow
                key={`inbox-template-${item.id}`}
                data={item}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default InboxTemplates;
