import React from 'react';

import { TbFileSpreadsheet } from 'react-icons/tb';
import { Col, Row } from 'reactstrap';

import { ProofDetails, ProofItem } from '../AddPAYE/styles';
import { Select } from '../AddSelfEmployment/styles';
import {
  Title,
  TransactionInputContainer,
  UploadContainer,
} from '../TabProperty/styles';

import { AddTransactionSection } from './styles';
import {
  AddButton,
  AddCancelSection,
  AddLogoButton,
  CancelButton,
  ErrorMessage,
} from './styles';

function AddTransactionForm({
  openAddTransactionForm,
  item,
  index,
  onInputAddTransactionChange,
  errors,
  onAddTransaction,
  onCancelAddTransaction,
  onUploadProof,
  newDocsList,
  onRemoveProof,
}) {
  const renderError = (field: any) => <ErrorMessage>{field}</ErrorMessage>;

  const onClickAddTransaction = (
    index: number,
    id: number,
    income_type_id: number,
  ) => {
    onAddTransaction(index, id, income_type_id);
  };

  return (
    <AddTransactionSection openAddTransactionForm={openAddTransactionForm}>
      <Title>Add transaction</Title>
      <Row>
        <Col>
          <TransactionInputContainer>
            <label htmlFor="client_name">Client name</label>
            <input
              type="text"
              value={item.addTransactionFields.client_name}
              name="client_name"
              id="client_name"
              onChange={(event) => onInputAddTransactionChange(index, event)}
            />
          </TransactionInputContainer>

          {errors.client_name && renderError(errors.client_name)}

          <TransactionInputContainer>
            <label htmlFor="date">Date</label>
            <input
              type="date"
              value={item.addTransactionFields.date}
              name="date"
              id="date"
              onChange={(event) => onInputAddTransactionChange(index, event)}
            />
          </TransactionInputContainer>

          {errors.date && renderError(errors.date)}

          <TransactionInputContainer>
            <label htmlFor="amount">Amount(NET) </label>
            <input
              type="number"
              value={item.addTransactionFields.amount}
              name="amount"
              id="amount"
              onChange={(event) => onInputAddTransactionChange(index, event)}
            />
          </TransactionInputContainer>

          {errors.amount && renderError(errors.amount)}

          <TransactionInputContainer>
            <label htmlFor="cis">CIS</label> <br />
            <Select
              id="cis"
              name="cis"
              onChange={(event) => onInputAddTransactionChange(index, event)}
              value={item.addTransactionFields.cis}
            >
              <option>Select an option</option>
              <option value="0">0%</option>
              <option value="20">20%</option>
              <option value="30">30%</option>
            </Select>
          </TransactionInputContainer>

          <Row>
            <AddCancelSection>
              <AddButton
                onClick={() =>
                  onClickAddTransaction(index, item.id, item.income_type_id)
                }
              >
                <span>Add</span>
              </AddButton>
              <CancelButton onClick={() => onCancelAddTransaction(index)}>
                <span>Cancel</span>
              </CancelButton>
            </AddCancelSection>
          </Row>
        </Col>

        <Col>
          <input
            type="file"
            onChange={(event) => onUploadProof(event)}
            hidden
            id="documents_list"
            name="documents_list"
            multiple
          />
          <AddLogoButton htmlFor="documents_list">
            <TbFileSpreadsheet size={24} />
            <span>Add proof</span>
          </AddLogoButton>

          {errors.files && renderError(errors.files)}

          <UploadContainer>
            <Row>
              {newDocsList.length > 0 &&
                newDocsList.map((proof, i) => {
                  return (
                    <ProofItem key={i}>
                      <ProofDetails>
                        <div className="image-size">
                          <span>{proof.name}</span>
                          <span>{proof.size}KB </span>
                        </div>

                        <div>
                          <span
                            className="remove"
                            onClick={() => onRemoveProof(i)}
                          >
                            Remove
                          </span>
                        </div>
                      </ProofDetails>
                    </ProofItem>
                  );
                })}
            </Row>
          </UploadContainer>
        </Col>
      </Row>
    </AddTransactionSection>
  );
}

export default AddTransactionForm;
