import React, { ReactNode } from "react";
import { Row } from "reactstrap";

interface Props {
  children: ReactNode;
  noBorder?: boolean;
  className?: string;
}

const TableRow = ({ children, noBorder, className }: Props) => {
  let _class = "pt-1 pb-1 pl-1 ";
  if (className) _class += className;

  return (
    <Row className={_class} style={{ border: `${noBorder && "none"}` }}>
      {children}
    </Row>
  );
};

export default TableRow;
