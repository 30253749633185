import styled from 'styled-components';

type Props = {
  background?: string;
  color?: string;
  isActive?: boolean;
  showDetails?: boolean;
  width?: number;
  isSecondPage?: boolean;
};

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 277px;
  height: 194px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const UserHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
`;

export const UserPhotoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
`;

export const UserPhoto = styled.img`
  height: 59px;
  width: 59px;
  border: 1px solid #9c95ab;
  border-radius: 50%;
`;

export const CallButton = styled.button`
  border: none;
  background-color: #57e4cd;
  height: 39px;
  width: 39px;
  border-radius: 50%;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameText = styled.span`
  font-size: 18px;
`;

export const UserNameText = styled.span`
  font-size: 18px;
`;

export const HmrcContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
`;

export const HmrcText = styled.span`
  font-size: 14px;
  font-weight: 600px;
  color: #209782;
  margin-top: 4px;
  cursor: pointer;
`;

export const TabsHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  height: 130px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  width: ${(props) => props.width};
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 86px;
  width: 97px;
  margin: 22px;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${(props) => (props.isActive ? '#57e4cd' : 'initial')};
`;

export const TabImage = styled.img`
  height: 33px;
  width: 33px;
`;

export const TabTitle = styled.span`
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
`;

export const TabSubtitle = styled.span`
  font-size: 10px;
`;

export const TabSeparator = styled.div`
  height: 65px;
  width: 1px;
  background: #999;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UploadSection = styled.div`
  display: flex;
`;

export const List = styled.div<Props>`
  flex: 1;
  width: ${(props) => (props.isSecondPage ? '70%' : '100%')};
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 10px;

  > span {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    border-right: 1px solid #fff;
    color: #9c95ab;
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  > div {
    display: flex;
    padding-left: 10px;
    :nth-child(odd) {
      background-color: #fff;
    }

    > span {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 16px;
      width: 100%;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Loading = styled.div`
  padding: 20px;
  font-size: 18px;
`;

export const TypeItem = styled.div<Props>`
  background-color: ${(props) => props.background};
  border-radius: 15px;
  padding: 5px 10px;
`;

export const ProfitItem = styled.span<Props>`
  color: ${(props) => props.color};
`;

export const ContainerList = styled.div`
  display: flex;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 520px;
  background-color: #fff;
  margin-left: 25px;
  padding: 22px;
  width: 30%;
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsHeaderLogo = styled.div`
  height: 76px;
  width: 76px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 40px;
`;

export const DetailsBody = styled.div`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      padding-bottom: 16px;

      > span {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
`;

export const SpanPositionId = styled.span`
`;

export const SpanCloseDate = styled.span`
  left: -1px;
`;

export const SpanUnits = styled.span`
  left: -1px;
`;

export const GoBackContainer = styled.div`
  margin-bottom: 20px;
`;
