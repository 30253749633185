import React, { ChangeEvent, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import {
  enableUserAccount,
  getNotificationsList,
  listUsers,
} from 'src/api/users';
import { TableHead } from 'src/components';
import CustomerRowPie from 'src/components/CustomersRow/CustomerRowPie';
import { Loading } from 'src/components/ui';
import { usePagination } from 'src/hooks';
import { ICustomer } from 'src/models';
import {
  Card,
  Col,
  Container,
  CustomInput,
  observer,
  Button,
  Toastify,
} from 'src/modules';
import {
  ReEnableSectionContainer,
  ReEnableSection,
} from 'src/styles/pages/customers/styles';
import { ERRORS, SUCCESS } from 'src/utils';

const CustomersPieLayout: React.FC = () => {
  const [data, setData] = useState<ICustomer[]>([]);

  const [selectAll, setSelectAll] = useState(false);
  const [, setHasSelection] = useState(false);
  const [isLoadingEnableUser, setIsLoadingEnableUser] = useState(false);

  const {
    isLoading,

    loadData,

    selectedCustomers,
    setSelectedCustomers,
  } = usePagination(listUsers, setData, 'listUsers');

  const { data: notifications = [], refetch: getNotifications } = useQuery(
    ['notifications', data],
    () => getNotificationsList(data.map((user) => user.id)),
    { enabled: false },
  );

  const onSelectAll = () => {
    const newData = [...data];
    const newSelection = !selectAll;

    for (let index = 0; index < newData.length; index++) {
      newData[index].selected = newSelection;
    }

    if (newSelection) {
      setSelectedCustomers(newData.map((item) => item.id));
    } else {
      setSelectedCustomers([]);
    }

    setSelectAll(newSelection);
    setData(newData);
  };

  const onSelectCustomer = (item: ICustomer) => {
    const newData = [...data];

    for (let index = 0; index < newData.length; index++) {
      if (newData[index].id === item.id) {
        newData[index].selected = !newData[index].selected;
        break;
      }
    }
    setData(newData);

    const selected = new Set(selectedCustomers);
    selected.add(item.id);
    // setSelectedCustomers([...selected]);
  };

  const onSelectedCustomers = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const newList: number[] = selectedCustomers.filter(
      (el: number) => el !== id,
    );
    if (e.target.checked) newList.push(id);

    setSelectedCustomers(newList);
  };

  useEffect(() => {
    setHasSelection(selectedCustomers.length >= 1);
  }, [selectedCustomers]);

  useEffect(() => {
    if (data.length > 0) {
      getNotifications();
    }
  }, [data]);

  const { mutate: mutateEnableUserAccount } = useMutation(enableUserAccount, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      loadData();

      setTimeout(() => {
        setIsLoadingEnableUser(false);
      }, 4000);
    },
    onError: () => {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const onEnableUserAccount = (userId: number) => {
    mutateEnableUserAccount(userId);

    setTimeout(() => {
      setIsLoadingEnableUser(true);
    }, 2000);
  };

  return (
    <Card className="overflow-visible">
      <Container className="mw-100">
        <TableHead className="pt-1 pb-1 pl-1">
          <Col className="col-index text-center" style={{ width: '4%' }}>
            #
          </Col>
          <Col className="col-index" style={{ width: '1%' }}>
            <CustomInput
              type="checkbox"
              id="selectAllCustomersID"
              name="selectAllCustomers"
              checked={selectAll}
              onChange={() => onSelectAll()}
            />
          </Col>
          <Col className="col-name" style={{ width: '20%' }}>
            Name
          </Col>
          <Col className="col-progress" style={{ width: '20%' }}>
            Progress
          </Col>
          <Col className="col-status" style={{ width: '14%' }}>
            Sales Status
          </Col>
          <Col className="col-status" style={{ width: '9%' }}>
            Created on
          </Col>
          <Col
            className="col-tax d-flex justify-content-left"
            style={{ width: '9%' }}
          >
            Tax Balance
          </Col>
          <Col className="col-active" style={{ width: '15%' }}>
            Agent
          </Col>
        </TableHead>

        {(isLoading || isLoadingEnableUser) && (
          <Container
            className="position-relative mw-100"
            style={{ overflow: 'hidden' }}
          >
            <Loading />
          </Container>
        )}

        {data.map((item: ICustomer, index: number) => (
          <div key={item.id}>
            {item.status === 'Delete' ? (
              <ReEnableSectionContainer>
                <ReEnableSection />
                <Button
                  type="submit"
                  color="primary"
                  className="btn btn-primary mr-4 re-enable-button"
                  onClick={() => onEnableUserAccount(item.id)}
                >
                  Enable Account
                </Button>
                <CustomerRowPie
                  item={{
                    ...item,
                    notifications_count:
                      notifications.find(
                        (user: any) => item.id === user.conversation_user_id,
                      )?.notification_count ?? 0,
                  }}
                  index={index}
                  onSelectCustomer={onSelectCustomer}
                  onSelectedCustomers={onSelectedCustomers}
                />
              </ReEnableSectionContainer>
            ) : (
              <CustomerRowPie
                key={`customer-${item.id}`}
                item={{
                  ...item,
                  notifications_count:
                    notifications.find(
                      (user: any) => item.id === user.conversation_user_id,
                    )?.notification_count ?? 0,
                }}
                index={index}
                onSelectCustomer={onSelectCustomer}
                onSelectedCustomers={onSelectedCustomers}
              />
            )}
          </div>
        ))}
      </Container>
    </Card>
  );
};

export default observer(CustomersPieLayout);
