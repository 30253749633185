import React, { memo, useState } from 'react';

import { useMutation } from 'react-query';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ResponseError from 'src/api/ResponseError';
import { deleteUser /* disableUser */ } from 'src/api/users';
import { ICustomer } from 'src/models';
import { Button, Modal, ModalFooter, ModalHeader, Toastify } from 'src/modules';
import store from 'src/stores';
import { ERRORS, Routes, SUCCESS } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import { Loading } from '../ui';

interface IconDeleteCustomerProps {
  user: ICustomer;
  onRemoved?: any;
  isIconDelete: boolean;
  isDeleteButton?: boolean;
  isDisableButton?: boolean;
}

const IconDeleteCustomer = ({
  user,
  onRemoved,
  isIconDelete,
  isDeleteButton,
  isDisableButton,
}: IconDeleteCustomerProps) => {
  const { isPIE } = store.pieLayout;

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { mutate: mutateDeleteUser } = useMutation(deleteUser, {
    onSuccess: () => {
      if (isIconDelete) {
        onRemoved();
      }

      setLoading(false);
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        window.location.href = Routes.CUSTOMERS;
      }, 1000);
    },
    onError: (error: any) => {
      Toastify.toast.error(error.message);
    },
  });

  /* const { mutate: mutateDisableUser } = useMutation(disableUser, {
    onSuccess() {
      onRemoved();
      setLoading(false);
      window.location.href = Routes.CUSTOMERS;
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  }); */

  const onAskToDelete = () => {
    setModal(true);
  };

  const onDelete = async () => {
    setLoading(true);
    setModal(false);

    if (isDisableButton) {
      /* mutateDisableUser(user.id) */
    } else {
      mutateDeleteUser(user.id);
    }
  };

  return (
    <>
      {isIconDelete && (
        <ButtonIcon outline={true} color="secondary" onClick={onAskToDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </ButtonIcon>
      )}

      {isDisableButton && (
        <Button
          style={{
            background: isPIE ? '#F31B1B' : '#FFDD05',
            borderColor: isPIE ? '#F31B1B' : '#FFDD05',
            color: isPIE ? '#fff' : '#000',
          }}
          onClick={onAskToDelete}
        >
          Disable It
        </Button>
      )}

      {isDeleteButton && (
        <Button
          style={{
            background: isPIE ? '#F31B1B' : '#FFDD05',
            borderColor: isPIE ? '#F31B1B' : '#FFDD05',
            color: isPIE ? '#fff' : '#000',
          }}
          className="ml-3"
          onClick={onAskToDelete}
        >
          Delete It
        </Button>
      )}

      {modal && (
        <Modal centered isOpen={true} toggle={() => setModal(false)}>
          <ModalHeader toggle={() => setModal(false)}>
            {isDisableButton ? 'Disable ' : 'Delete '} customer:{' '}
            {user.full_name}?
          </ModalHeader>
          <ModalFooter>
            {loading && (
              <div className="w-100 mb-2 overflow-hidden">
                <Loading />
              </div>
            )}
            <Button
              style={{
                background: isPIE ? '#F31B1B' : '#FFDD05',
                borderColor: isPIE ? '#F31B1B' : '#FFDD05',
                color: isPIE ? '#fff' : '#000',
              }}
              onClick={onDelete}
            >
              {isDisableButton ? 'Disable ' : 'Delete '}
            </Button>
            <Button color="secondary" onClick={() => setModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default memo(IconDeleteCustomer);
