import React, { useEffect, useState } from 'react';

import Draggable from 'react-draggable';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { GrRefresh } from 'react-icons/gr';

import {
  Container,
  Image,
  ImageButton,
  ImagesListItem,
  SectionImage,
  SectionImageButtons,
  SectionImagesList,
} from './styles';

const zoomStep = 1;
const maxScale = 5;
const minScale = 1;
const defaultScale = minScale;
const defaultRotate = 0;

function ViewImage({ transactions }) {
  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const [imgSelected, setImgSelected] = useState(
    transactions && transactions.files[0],
  );

  useEffect(() => {
    if (transactions) {
      setImgSelected(transactions.files[0]);
    }
  }, [transactions]);

  const onZoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const onZoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale >= minScale ? newScale : minScale);
  };

  const onRotate = () => {
    setRotate(rotate === 270 ? 0 : rotate + 90);
  };

  const onSelectImage = (url: string) => {
    setImgSelected(url);
  };

  const isDraggable = scale > 1;

  return (
    <Container>
      {transactions && transactions.files.length < 1 && (
        <span>No transactions files</span>
      )}

      {transactions && transactions.files.length > 0 && (
        <>
          <SectionImage style={{ margin: '0 auto' }}>
            <Draggable disabled={!isDraggable} key={version}>
              <div style={{ cursor: isDraggable ? 'move' : null }}>
                <Image
                  draggable="false"
                  src={imgSelected}
                  scale={scale}
                  rotate={rotate}
                />
              </div>
            </Draggable>
          </SectionImage>

          <div style={{ marginLeft: 14 }}>
            <SectionImagesList>
              {transactions.files.map((file, i) => {
                console.log('file ', file);
                return (
                  <ImagesListItem key={i} onClick={() => onSelectImage(file)}>
                    <img src={file} alt="images list" key={i} />
                  </ImagesListItem>
                );
              })}

              {/* {transactions.files.length < 1 && <span> No files</span>} */}
            </SectionImagesList>

            <SectionImageButtons>
              <ImageButton onClick={() => onZoomIn()}>
                <AiOutlinePlus size={20} />
              </ImageButton>
              <ImageButton onClick={() => onZoomOut()}>
                <AiOutlineMinus size={20} />
              </ImageButton>
              <ImageButton onClick={() => onRotate()} style={{ marginTop: 30 }}>
                <GrRefresh size={20} />
              </ImageButton>
            </SectionImageButtons>
          </div>
        </>
      )}
    </Container>
  );
}

export default ViewImage;
