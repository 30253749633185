import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import { forgotPassword } from 'src/api/auth.api';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const { data, refetch, isLoading, isSuccess } = useQuery(
    'forgotPassword',
    () => forgotPassword(email),
    {
      enabled: false,
    },
  );

  return (
    <div className="w-100 vh-100 justify-content-center d-flex align-items-center">
      <div className="w-50" style={{ maxWidth: '600px' }}>
        <Card>
          <CardBody>
            <div className="text-center mt-4">
              <h1 className="h2">Reset password</h1>
              {!isSuccess && (
                <p className="lead">Enter your email to reset your password.</p>
              )}
            </div>
            <div className="m-sm-4">
              {isSuccess ? (
                <div className="text-center mt-4">
                  <p className="lead">{data?.message}</p>
                </div>
              ) : (
                <Form>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg" onClick={() => refetch()}>
                      {isLoading ? 'Loading...' : 'Send email'}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
