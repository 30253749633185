import React, { useState, useEffect } from 'react';

import { Layout, NotificationTemplatesRow, TableHead } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container } from 'src/modules';
import store from 'src/stores';

const NotificationTemplates: React.FC = () => {
  // Mobx store
  const notificationTemplates = store.notificationTemplates;

  // States
  const [loading, setLoading] = useState<boolean>(false);

  const fetchNotificationTemplates = async (): Promise<void> => {
    try {
      setLoading(true);
      await notificationTemplates.fetchTemplates();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationTemplates();
  }, []);

  return (
    <Layout>
      <Dashboard title="Notification Templates">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="1">#</Col>
              <Col xs="1">Title</Col>
              <Col xs="4">Push Notification</Col>
              <Col xs="3">Admin Push Notification</Col>
              <Col xs="1">Tag</Col>
              <Col xs="2">Action</Col>
            </TableHead>

            {loading && <Loading />}

            {notificationTemplates.templates.map(
              ({ id, title, message, admin_message, tag }, index) => (
                <NotificationTemplatesRow
                  key={`notification-template-${index}`}
                  id={id}
                  title={title}
                  message={message}
                  admin_message={admin_message}
                  tag={tag}
                />
              ),
            )}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default NotificationTemplates;
