import { action, observable, makeObservable } from 'mobx';

import { ExpensesTabApi } from 'src/api';
import { CustomerEmployment } from 'src/utils';
import { CustomerInformation, ReceiptExpenses } from 'src/utils/types/Customer';

import {
  customerInitialValues,
  expensesBasicDataInitial,
} from './expenses-tab.consts';

export default class ExpensesTabStore {
  @observable
  currentInformation = {
    userId: '',
    taxYear: '',
  };
  @observable
  customerData: CustomerInformation = customerInitialValues;

  @observable
  payeBasicData: ExpensesBasicData = expensesBasicDataInitial;

  @observable
  selfBasicData: ExpensesBasicData = expensesBasicDataInitial;

  @observable
  customBasicData: ExpensesBasicData = expensesBasicDataInitial;

  @observable
  activeTab = 'Expense Form';

  @observable
  whichEmployee = 0;

  @observable
  isMultipleEmployer = false;

  @observable
  multipleEmployerList: EmployerListTypes[] = [];

  @observable
  receiptsData: ReceiptExpenses[] = [];

  @observable
  employerSelectId = 0;

  @observable
  loadingData = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setCustomerInformation = (info: CustomerInformation) => {
    this.customerData = info;
  };

  @action
  getCurrentTaxYear = async (): Promise<number> => {
    return await ExpensesTabApi.getCurrentTaxYear();
  };

  @action
  getCustomerInformation = async (
    userId: string,
    taxYear: string,
  ): Promise<void> => {
    this.setLoadingData(true);
    const response = await ExpensesTabApi.getCustomerInformation(
      userId,
      taxYear,
    );

    this.currentInformation.userId = userId;
    this.currentInformation.taxYear = taxYear;

    this.setCustomerInformation(response);
    const isPayeEmployee = response.is_paye_employee;

    // If user is PAYE, it will be set by default
    const whichEmployeeNumber = isPayeEmployee
      ? CustomerEmployment.PAYE
      : CustomerEmployment.SELF;

    this.setWhichEmployee(whichEmployeeNumber);

    // If user is PAYE, we will define data here

    await this.getPayeBasicData(userId, taxYear);
    await this.getSelfBasicData(userId, taxYear);

    this.setLoadingData(false);
  };

  @action
  setActiveTab = (tab: string) => (this.activeTab = tab);

  @action
  setWhichEmployee = (type: number) => (this.whichEmployee = type);

  @action
  setCustomBasicData = (data: ExpensesBasicData) =>
    (this.customBasicData = data);

  @action
  setPayeBasicData = (data: ExpensesBasicData) => (this.payeBasicData = data);

  @action
  setSelfBasicData = (data: ExpensesBasicData) => (this.selfBasicData = data);

  @action
  setIsMultipleEmployer = (value: boolean) => (this.isMultipleEmployer = value);

  @action
  setReceiptsData = (data: ReceiptExpenses[]) => (this.receiptsData = data);

  @action
  onChangeWhichEmployer = (type: number): void => {
    this.setLoadingData(true);
    type === CustomerEmployment.PAYE
      ? this.setCustomBasicData(this.payeBasicData)
      : this.setCustomBasicData(this.selfBasicData);

    this.whichEmployee = type;
    this.setLoadingData(false);
  };

  @action
  onSetEmployerListSelected = async (
    userId: number,
    taxYear: number,
    id: number,
  ): Promise<void> => {
    this.setLoadingData(true);
    this.employerSelectId = id;
    this.isMultipleEmployer = true;
    const response = await ExpensesTabApi.getPayeEmployer(userId, taxYear, id);

    this.customBasicData = response;
    this.payeBasicData = response;
    this.setLoadingData(false);
  };

  @action
  getPayeBasicData = async (userId: string, taxYear: string): Promise<void> => {
    const response = await ExpensesTabApi.getPayeBasicData(userId, taxYear);

    this.customBasicData = response;
    this.payeBasicData = response;
    this.receiptsData = response.receipts;

    this.multipleEmployerList = response.expenses_paye_employer;
    // If user has more than one PAYE employer, them we will render the dropdown
    const hasDualEmployee = response.expenses_paye_employer.length > 0;

    if (hasDualEmployee) {
      this.isMultipleEmployer = true;
    }
  };

  @action
  getSelfBasicData = async (userId: string, taxYear: string): Promise<void> => {
    const response = await ExpensesTabApi.getSelfBasicData(userId, taxYear);

    this.selfBasicData = response;

    if (this.whichEmployee !== CustomerEmployment.PAYE) {
      this.customBasicData = response;
      this.receiptsData = response.receipts;
    }
  };

  @action
  disallowUpdate = async (id: number, module: string, value: number) => {
    this.setLoadingData(true);
    await ExpensesTabApi.disallowUpdate(id, module, value);

    this.getCustomerInformation(
      this.currentInformation.userId,
      this.currentInformation.taxYear,
    );
    this.setLoadingData(false);
  };

  @action
  updateTaxStatus = async (userId: string, taxYear: string): Promise<void> => {
    await ExpensesTabApi.updateTaxStatus(userId, taxYear);
  };

  @action
  setLoadingData = (status: boolean): void => {
    this.loadingData = status;
  };
}
