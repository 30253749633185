import React from 'react';

import moment from 'moment';

type Props = {
  values: any;
  startDate: any;
  endDate: any;
};

const ShiftBar: React.FC<Props> = ({ values, startDate, endDate }: Props) => {
  const barWindow = 120;
  const barHeight = 10;

  const getIdle = (values?: any, startDate?: any, endDate?: any) => {
    const startBar = moment(startDate, 'DD-MM-YYYY HH:mm:ss').format('HH');
    const positionBar = (parseInt(startBar) * 100) / 24;
    const start = moment(startDate, 'DD-MM-YYYY HH:mm:ss');
    const end = moment(endDate, 'DD-MM-YYYY HH:mm:ss');
    const duration: any = moment.duration(end.diff(start));
    const widthBar = (parseInt(duration.asHours().toFixed(0)) * barWindow) / 24;
    return (
      <div
        className="bar-gray mb-2"
        style={{
          width: `${barWindow}px`,
          height: `${barHeight}px`,
          backgroundColor: '#e3e3e3',
          position: 'relative',
          borderRadius: '30px',
        }}
      >
        <div
          className="bar-green"
          style={{
            width: `${widthBar}px`,
            position: 'absolute',
            left: `${positionBar}%`,
            height: `${barHeight}px`,
            backgroundColor: '#20c997',
          }}
        >
          {values && (
            <>
              {values.map((idles: any, ind: number) => {
                const startBarIdle = moment(
                  idles[0],
                  'DD-MM-YYYY HH:mm:ss',
                ).format('HH');
                const positionBarIdle = (parseInt(startBarIdle) * 100) / 24;
                const startIdle = moment(idles[0], 'DD-MM-YYYY HH:mm:ss');
                const endIdle = moment(idles[1], 'DD-MM-YYYY HH:mm:ss');
                const durationIdle: any = moment.duration(
                  endIdle.diff(startIdle),
                );
                const widthBarIdle =
                  (parseInt(durationIdle.asHours().toFixed(0)) * barWindow) /
                  24;
                return (
                  <div
                    key={ind}
                    className="bar-gray"
                    style={{
                      width: `${widthBarIdle}px`,
                      position: 'absolute',
                      left: `${positionBarIdle}%`,
                      height: `${barHeight}px`,
                      backgroundColor: '#8a8a8a',
                    }}
                  ></div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  };
  return <>{getIdle(values, startDate, endDate)}</>;
};

export default ShiftBar;
