import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import Draggable from 'react-draggable';
import { useQuery } from 'react-query';

import { getFilesAndImages } from 'src/api/message-center';
import Avatar from 'src/components/Avatar';
import { Input } from 'src/components/common';
import Icon from 'src/components/Icon';
import { Toastify } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { isValidPhoneNumber } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import DocumentPreview from './components/DocumentPreview';
import { files, images } from './mocks';
import {
  ActionButton,
  ActionsContent,
  Container,
  UerInfoContent,
  ProfilePicture,
  ProfilePictureStatus,
  UserName,
  FilesContainer,
  FileSectionText,
  FileContainer,
  FileName,
  ImagesContent,
  Image,
  ActionCallButton,
  PopUpImage,
  InvalidNumber,
  DraggableInfoContent,
} from './styles';
import { getFileName } from './utils';

interface IProps {
  width: number;
  isOpenMenu: boolean;
  isDragging: boolean;
}

// check if the phone number start with 44, if not add +44 to it
// if the number starts with 44, add + to it
function getInitialPhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith('+44')) {
    return phoneNumber;
  }

  if (phoneNumber.startsWith('44')) {
    return `+${phoneNumber}`;
  }

  return `+44${phoneNumber}`;
}

function FilesContent({ width, isOpenMenu, isDragging }: IProps) {
  const { messageCenter } = store;

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [fileType, setFileType] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [call, setCall] = useState(false);
  const [numberIsValid, setNumberIsvalid] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [microphoneIsMuted, setMicrophoneIsMuted] = useState(false);
  const [soundIsMuted, setSoundIsMuted] = useState(false);
  const [callBtnOrChangeFlagClicked, setCallBtnOrChangeFlagClicked] =
    useState(false);

  const [dragPosition, setDragPosition] = React.useState(null);
  const [filesAndImagesList, setFilesAndImagesList] = useState([]);
  const [photosList, setPhotosList] = useState<FilesOrPhotosType[]>([]);
  const [filesList, setFilesList] = useState<FilesOrPhotosType[]>([]);
  const [itemSelected, setItemSelected] = useState<FilesOrPhotosType>({});

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    'getFilesAndImages',
    () => getFilesAndImages(messageCenter.conversationSelectedId),
    {
      enabled: false,
      onSuccess: (result) => {
        setFilesAndImagesList(result.data);
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    const loadUser = async () => {
      const user = await Storage.getUser();

      const permissions = handleSuperAdminRole(user?.role_id);
      setIsSuperAdmin(permissions);
    };

    loadUser();
  }, []);

  useEffect(() => {
    if (messageCenter.conversationSelectedId !== -1) {
      refetch();
    }
  }, [messageCenter.conversationSelectedId]);

  useEffect(() => {
    if (filesAndImagesList.length > 0) {
      const photosList: FilesOrPhotosType[] = [];
      const filesList: FilesOrPhotosType[] = [];

      filesAndImagesList.map((item: FilesOrPhotosType) => {
        if (item.file_type === 'Photo') photosList.push(item);
        else filesList.push(item);
      });

      setPhotosList(photosList);
      setFilesList(filesList);
    }
  }, [filesAndImagesList]);

  function handleOpenPreview(
    type: string,
    item: FilesOrPhotosType,
    index: number,
  ) {
    setIsOpenPreview(true);
    setCurrentFileIndex(index);
    setFileType(type);
    setItemSelected(item);
  }

  const onCall = () => {
    setCallBtnOrChangeFlagClicked(true);

    if (isValidPhoneNumber(phoneValue)) {
      setNumberIsvalid(true);
      setCall(true);
      setCallBtnOrChangeFlagClicked(false);

      messageCenter.callCustomer(phoneValue);
    } else setNumberIsvalid(false);
  };

  const onEndCall = () => {
    messageCenter.hangUp();

    setCall(false);
    setIsMinimized(false);

    if (!isSuperAdmin) {
      messageCenter.setInCall(false);
    }
  };

  const onMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const onMuteMicrophone = () => {
    setMicrophoneIsMuted(!microphoneIsMuted);
  };

  const onMuteSound = () => {
    setSoundIsMuted(!soundIsMuted);
    // messageCenter.device.activeConnection().mute(soundIsMuted);
  };

  const choiceHandler = () => {
    setDragPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    const phone = getInitialPhoneNumber(
      messageCenter.customerSelected?.phone_number,
    );
    setPhoneValue(phone);

    if (isValidPhoneNumber(phone)) setNumberIsvalid(true);
    else setNumberIsvalid(false);
  }, [messageCenter.customerSelected?.phone_number]);

  return (
    <Container
      isDragging={isDragging}
      style={{ width: isOpenMenu ? width : 0 }}
      className="mc-filescontent-container"
    >
      <UerInfoContent className="mc-uerInfo-content">
        <ProfilePicture>
          <Avatar src={messageCenter.customerSelected.avatar} />
          <ProfilePictureStatus profileOnline />
        </ProfilePicture>
        <UserName>{messageCenter.customerSelected.name} </UserName>
        <ActionsContent>
          <Input
            onChange={(e) => setPhoneValue(e.target.value)}
            value={phoneValue}
            placeholder="+44 7800 123 456"
          />
        </ActionsContent>

        {!numberIsValid && callBtnOrChangeFlagClicked && (
          <InvalidNumber>Invalid Number</InvalidNumber>
        )}
        <ActionsContent>
          <ActionCallButton onClick={() => onCall()} background="green">
            <Icon name="white-phone" />
          </ActionCallButton>
        </ActionsContent>
      </UerInfoContent>
      {call && (
        <Draggable
          onStop={choiceHandler}
          position={!isMinimized && dragPosition}
        >
          <DraggableInfoContent
            background="#212529"
            position="fixed"
            zIndex={10}
            isMinimized={isMinimized}
          >
            <PopUpImage
              src="/icons/message-center/minimize.svg"
              onClick={() => onMinimize()}
            />
            <ProfilePicture isMinimized={isMinimized}>
              <Avatar src={messageCenter.customerSelected.avatar} size="xl" />
            </ProfilePicture>
            <UserName color="#fff" isMinimized={isMinimized}>
              {messageCenter.customerSelected.name}
            </UserName>
            <ActionsContent>
              <ActionCallButton
                onClick={() => onMuteMicrophone()}
                background="#55606A"
                isDisabled={microphoneIsMuted}
              >
                <Icon name="microphone" size="sm" />
              </ActionCallButton>
              <ActionCallButton
                onClick={() => onMuteSound()}
                background="#55606A"
                isDisabled={soundIsMuted}
              >
                <Icon name="muted" size="sm" />
              </ActionCallButton>
              <ActionCallButton
                onClick={() => onEndCall()}
                background="#BF4141"
              >
                <Icon name="horizontal-phone" />
              </ActionCallButton>
              <ActionCallButton onClick={() => {}} background="#55606A">
                <Icon name="dots" />
              </ActionCallButton>
            </ActionsContent>
          </DraggableInfoContent>
        </Draggable>
      )}

      <FilesContainer>
        <FileSectionText className="shared-files-text">
          Shared Files
        </FileSectionText>
        {!isLoading &&
          filesAndImagesList.map((item: any, index: number) => {
            item.file_type === 'Pdf' && (
              <FileContainer
                key={item.id}
                onClick={() => handleOpenPreview('Pdf', item, index)}
                width={width}
              >
                <Icon name="file" size="xl" />
                <FileName>{getFileName(item.path)}</FileName>
                <Icon name="close" />
              </FileContainer>
            );
          })}

        <FileSectionText className="shared-images-text">
          Shared Images
        </FileSectionText>
        <ImagesContent>
          {!isLoading &&
            filesAndImagesList.map((item: any, index: number) => {
              return (
                item.file_type === 'Photo' && (
                  <Image
                    key={index}
                    src={item.path}
                    onClick={() => handleOpenPreview('Photo', item, index)}
                  />
                )
              );
            })}
        </ImagesContent>
      </FilesContainer>
      <DocumentPreview
        isOpen={isOpenPreview}
        contentWidth={width}
        fileType={fileType}
        currentFileIndex={currentFileIndex}
        setIsOpen={setIsOpenPreview}
        photosList={photosList}
        filesList={filesList}
        itemSelected={itemSelected}
      />
    </Container>
  );
}

export default observer(FilesContent);
