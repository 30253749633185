import React, { useEffect, useState } from 'react';

import Avatar from 'src/components/Avatar';
import Icon from 'src/components/Icon';
import { Button } from 'src/modules';

import { HeaderSearch } from '../../styles';

import {
  Container,
  ShadowContainer,
  ModalContainer,
  ModalTitle,
  ModalTitleText,
  ModalTitleButton,
  AssistantListContainer,
  AssistantList,
  AssistantName,
  ButtonsContainer,
} from './styles';

type Props = {
  modalIsOpen: boolean;
  setModalIsOpen(modalIsOpen: boolean): void;
  assistantList: any;
  setAssistantSelected(value: any): void;
  clearSelectedAssistant(): void;
  sideMenuOpen: boolean;
};

const AssistantModal: React.FC<Props> = ({
  modalIsOpen,
  setModalIsOpen,
  assistantList,
  setAssistantSelected,
  clearSelectedAssistant,
  sideMenuOpen,
}) => {
  const [searchedAssistant, setSearchedAssistant] = useState([]);
  const [searchedAssistantInput, setSearchedAssistantInput] = useState('');
  const [expand, setExpand] = useState(false);

  const onSelectAssistant = (id: number) => {
    const selected = assistantList.filter(
      (assistantList: any) => assistantList.id === id,
    );

    setAssistantSelected({ ...selected[0] });
    setModalIsOpen(false);
  };

  const onSearchAssistant = (value: string) => {
    setSearchedAssistantInput(value);
    const results: any = [];

    assistantList.forEach((item: any) => {
      if (value && item.name.toLowerCase().includes(value)) {
        results.push(item);
      }
    });

    setSearchedAssistant(results);
  };

  useEffect(() => {
    if (!modalIsOpen) {
      setSearchedAssistantInput('');
      setSearchedAssistant(assistantList);
    }

    setTimeout(() => setExpand(modalIsOpen), 200);
  }, [modalIsOpen]);

  return (
    <Container modalIsOpen={modalIsOpen}>
      <ShadowContainer
        modalIsOpen={modalIsOpen}
        onClick={() => setModalIsOpen(!modalIsOpen)}
      />
      <ModalContainer modalIsOpen={expand} sideMenuOpen={sideMenuOpen}>
        <ModalTitle>
          <ModalTitleText>Change Tax Assistant </ModalTitleText>

          <ButtonsContainer>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary mr-4"
              style={{ maxHeight: 20 }}
              onClick={() => clearSelectedAssistant()}
            >
              ALL
            </Button>
            <ModalTitleButton onClick={() => setModalIsOpen(false)}>
              <Icon name="close" />
            </ModalTitleButton>
          </ButtonsContainer>
        </ModalTitle>
        <HeaderSearch
          isOpen
          placeholder="Search name of assistant"
          onChange={(e) => onSearchAssistant(e.target.value)}
          value={searchedAssistantInput}
          style={{ width: '100%', maxWidth: 312 }}
        />
        <AssistantListContainer>
          {searchedAssistant.length < 1
            ? assistantList.map((item: any) => (
                <AssistantList
                  key={item.id}
                  onClick={() => onSelectAssistant(item.id)}
                >
                  <Avatar src={item.image} size="lg" />
                  <AssistantName>{item.name}</AssistantName>
                </AssistantList>
              ))
            : searchedAssistant.map((item: any) => (
                <AssistantList
                  key={item.id}
                  onClick={() => onSelectAssistant(item.id)}
                >
                  <Avatar src={item.image} size="lg" />
                  <AssistantName>{item.name}</AssistantName>
                </AssistantList>
              ))}
        </AssistantListContainer>
      </ModalContainer>
    </Container>
  );
};

export default AssistantModal;
