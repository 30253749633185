import React, { useEffect, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { Card, Col, Container } from 'src/modules';

import TableHead from '../TableHead';
import TableRow from '../TableRow';
import { Loading } from '../ui';

// TODO: notification model
interface Props {
  item: UserReferralsTypes;
  onSelectUser(id: number): void;
}

const UserReferralsRowDetails: React.FC<Props> = ({ item, onSelectUser }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<UserReferralsTypes>();

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await request.get(
        `${API.PATH}${API.USERS_REFERRALS}/?user_id=${item.id}`,
      );

      setDetails(data?.data || []);
      console.log(`🚀 ~ file: index.tsx ~ line 29 ~ loadData ~ data`, data);
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card className="w-100 mt-3">
      <TableHead>
        <Col xs="3" className="pl-2">
          Referred Customers
        </Col>
        <Col xs="2">Registered Clients: {item.total_clients}</Col>
        <Col xs="3">Referred Earning: {item.referred_earning}</Col>
        <Col xs="2" className="text-center">
          Referred Payment
        </Col>
        <Col xs="2" className="text-center">
          Status
        </Col>
      </TableHead>

      {loading && <Loading />}
      <Container
        className="mw-100"
        style={{ maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}
      >
        {details?.map((detail: ReferralsTypes, index: number) => (
          <div
            key={`details_${detail.user_id}_${index}`}
            onClick={() => onSelectUser(detail.id)}
            className="link"
          >
            <TableRow>
              <Col xs="8" className="mt-3 pl-4">
                {detail.full_name}
              </Col>
              <Col xs="2" className="mt-3 text-center">
                {detail.referred_payment}
              </Col>
              <Col xs="2" className="mt-2 text-center">
                {detail.referral_status}
              </Col>
            </TableRow>
          </div>
        ))}
      </Container>
    </Card>
  );
};

export default UserReferralsRowDetails;
