import { Input, Select, TextArea, Row } from 'src/components/common';
import { styled } from 'src/modules';

const StyledRow = styled(Row)`
  align-items: center;
  margin-bottom: 5px;
`;

const Label = styled.label`
  margin: 0;
  flex: 1;
`;

const StyledInput = styled(Input)`
  flex: 6;
`;

const StyledTextArea = styled(TextArea)`
  flex: 6;
`;

const StyledSelect = styled(Select)`
  flex: 6;
`;

const ImageInput = styled.input`
  flex: 6;
`;

const Wrapper = styled.form`
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3a4d5f;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin: 0 auto;
  box-shadow: 1px 1px 5px 0 rgba(33, 37, 41, 0.06);
  overflow: hidden;
  border: thin solid #e9ecef;
  padding: 20px;
`;

const Submit = styled.button`
  border: 1px solid transparent;
  color: #000;
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
  min-width: 40px;
  min-height: 1.8125rem;
`;

export {
  Wrapper,
  StyledInput,
  StyledRow,
  Label,
  StyledSelect,
  ImageInput,
  StyledTextArea,
  Submit,
};
