import React, { memo, useEffect, useState } from 'react';

import { useMutation } from 'react-query';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleRead } from 'src/api/notifications';
import { Toastify } from 'src/modules';
import store from 'src/stores';
import { ERRORS, isTruthy } from 'src/utils';

import ButtonIcon from '../ButtonIcon';

type Props = {
  initStatus: boolean;
  id: number;
  userId: number;
  onUpdate?: (value: boolean) => void;
};

const NotificationRead: React.FC<Props> = ({
  initStatus,
  id,
  userId,
  onUpdate = () => null,
}) => {
  const customer = store.customer;

  const { mutate, isLoading } = useMutation(toggleRead, {
    onSuccess: () => {
      setActive((prevState) => !prevState);
      customer.onNotificationsCountChanged();
    },
    onError: () => {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
    onSettled: () => {
      onUpdate(active);
    },
  });

  const [active, setActive] = useState<boolean>(initStatus);

  useEffect(() => {
    setActive(initStatus);
  }, [initStatus]);

  const handleClick = () => {
    mutate({ taskHistoryId: id, userId: userId });
  };

  return (
    <ButtonIcon
      className="table-button"
      color={isTruthy(active) ? 'secondary' : 'primary'}
      outline={isTruthy(active)}
      onClick={handleClick}
      disabled={isLoading}
    >
      {!isLoading && (
        <FontAwesomeIcon color={active ? '' : 'white'} icon={faStar} />
      )}
      {isLoading && <div className="spinner-border spinner-border-sm" />}
    </ButtonIcon>
  );
};

export default memo(NotificationRead);
