import { CustomerInformation } from 'src/utils/types/Customer';

import request from '../request';
import ResponseError from '../ResponseError';

export const getCurrentTaxYear = async (): Promise<number> => {
  try {
    const { data } = await request.get('/settings/current-tax-year');

    return data.current_tax_year;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getCustomerInformation = async (
  userId: string,
  taxYear: string,
): Promise<CustomerInformation> => {
  try {
    const { data } = await request.get(
      `/v2/users/get-user-information?user_id=${userId}&tax_year=${taxYear}`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getPayeBasicData = async (
  userId: string,
  taxYear: string,
): Promise<ExpensesBasicData> => {
  try {
    const { data } = await request.get(
      `/expenses-form/v2/get-paye-info?user_id=${userId}&tax_year=${taxYear}`,
    );

    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getSelfBasicData = async (
  userId: string,
  taxYear: string,
): Promise<ExpensesBasicData> => {
  const { data } = await request.get(
    `/expenses-form/v2/get-self-employed-info?user_id=${userId}&tax_year=${taxYear}`,
  );

  return data.data;
};

export const getPayeEmployer = async (
  userId: number,
  taxYear: number,
  id: number,
): Promise<ExpensesBasicData> => {
  try {
    const { data } = await request.get(
      `/expenses-form/v2/get-paye-info?user_id=${userId}&tax_year=${taxYear}&employer_id=${id}`,
    );

    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const disallowUpdate = async (
  id: number,
  module: string,
  value: number,
): Promise<void> => {
  try {
    await request.post(`/customer-expenses/disallow-update`, {
      id,
      module,
      value,
    });
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateTaxStatus = async (
  userId: string,
  taxYear: string,
): Promise<void> => {
  try {
    await request.post(`/user-taxes/update-expense-status`, {
      user_id: userId,
      tax_year: taxYear,
    });
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateExpensesNewTotalAndRatio = async (
  userId: number,
  taxYear: number,
  expensesNewTotal: number,
  expensesRatioValue: number,
  expensesRatioType: string,
) => {
  try {
    await request.post(`/user-taxes/v2/update-expenses-new-total-and-ratio`, {
      user_id: userId,
      tax_year: taxYear,
      expenses_new_total: expensesNewTotal,
      expenses_ratio_value: expensesRatioValue,
      expenses_ratio_type: expensesRatioType,
    });
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const editAccountantFee = async ({
  userId,
  taxYear,
  accountantFee,
}: EditAccountantFeeParams) => {
  const response = await request.post(`/expenses-form/v2/edit-accountant-fee`, {
    user_id: userId,
    tax_year: taxYear,
    accountant_fee: accountantFee,
  });

  return response;
};
