import styled from 'styled-components';

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 20px;
  width: 280px;
`;

export const AssistantImage = styled.img`
  height: 70px;
  width: 70;
  border-radius: 50%;
`;

export const AssistantText = styled.span`
  font-weight: bold;
`;

export const FirstTableHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  background-color: #ddd;
  margin-top: 20px;
  margin-left: -12px;
`;

export const FirstTableRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
  margin-left: -12px;
`;
