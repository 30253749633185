import { QuickRepliesCenter } from 'src/utils';

export const QUICK_REPLIES = [
  {
    text: QuickRepliesCenter.THANKS_FOR_MESSAGING,
  },
  {
    text: QuickRepliesCenter.DOCUMENT_RECEIVED,
  },
  {
    text: QuickRepliesCenter.ID_CONFIRMED,
  },
  {
    text: QuickRepliesCenter.NEXT_STEPS,
  },
];

export const conversationData: MessageCenterConversation = {
  data: [],
  current_page: 0,
  per_page: 0,
  total_unread: 0,
  total_unread_by_tax_assistant: 0,
  has_more: false,
  next_page: 0,
  total_entries: 0,
  total_pages: 0,
};
