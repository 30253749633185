import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import { autorun } from 'mobx';
import moment from 'moment';
import { useQuery } from 'react-query';

import { getConversationByUserId } from 'src/api/message-center';
import Avatar from 'src/components/Avatar';
import Icon from 'src/components/Icon';
import { Loading } from 'src/components/ui';
import { observer, StringParam, Toastify, useQueryParam } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { conversationTime, createMarkup } from 'src/utils';
import checkChatType from 'src/utils/helpers/checkChatType';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import MessagesAssistant from '../MessagesAssistant';
import PhotoOrFileImage from '../PhotoOrFileImage';

import {
  Container,
  SectionChatInput,
  ChatInputPictureContent,
  ChatInputContent,
  ChatText,
  SectionMessageRead,
  MessageUnread,
  MessageSent,
  MessageRead,
  Time,
  SectionChat,
  SectionChatLoading,
  InCallContainer,
  RemoveButton,
} from './styles';

type Props = {
  handleEditMessage: (conversationId: number, message: string) => void;
};

const MessagesCustomer: React.FC<Props> = ({ handleEditMessage }) => {
  const messageCenterStore = store.messageCenter;

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const listViewRef = useRef<HTMLDivElement>(null);

  // track scrolling direction to avoid data fetch on first load
  const lastPositionY = useRef(0);

  // track is can fetch more data, scroll debounce
  const canFetchMore = useRef(true);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [userId] = useQueryParam('id', StringParam);

  useEffect(() => {
    const loadUser = async () => {
      const user = await Storage.getUser();

      const permissions = handleSuperAdminRole(user?.role_id);
      setIsSuperAdmin(permissions);
    };

    loadUser();
  }, []);

  const onMarkAsRead = (conversation_body_id: number) => {
    messageCenterStore.markMessageAsRead(conversation_body_id);
  };

  const onConversationChanged = (items: number) => {
    // if it's the first page and messages are loaded
    // scroll to the bottom
    if (messageCenterStore.currentPage === 1 && items > 0) {
      lastPositionY.current = 0;
      canFetchMore.current = true;
      // console.log('------ clear scroll');
      // scroll to bottom straight after render using 0 timeout
      setTimeout(() => {
        scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  // detect when a new conversation selected and messages are loaded
  useEffect(
    () =>
      autorun(() => {
        onConversationChanged(messageCenterStore.conversationMessages.length);
      }),
    [],
  );

  const loadMoreMessages = async () => {
    // block fetch
    clearTimeout(timeoutRef.current as unknown as number);
    canFetchMore.current = false;

    // load more messages
    const success = await messageCenterStore.loadNextPage();

    if (success) {
      // scroll down a few pixels to avoid loading the same page again
      setTimeout(() => {
        // scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
        const listTop = listViewRef.current?.getBoundingClientRect().top;
        const markerTop = scrollViewRef.current?.getBoundingClientRect().top;
        const top = markerTop && listTop ? markerTop - listTop - 500 : 0;
        // scroll listViewRef to top
        listViewRef.current?.scrollTo({
          top,
          behavior: 'smooth',
        });
      }, 66);
    }

    timeoutRef.current = setTimeout(() => {
      canFetchMore.current = true;
    }, 1000);
  };

  const onScroll = (e: any) => {
    if (!canFetchMore.current) return;

    // current componenet scroll position
    const y = e.target.scrollTop;

    // check laoding
    const loading = messageCenterStore.loadingMessages;

    // there's more pages to load
    const hasMorePages = messageCenterStore.hasMorePages;

    // messages list height
    // this ensures there are messages on screen
    const len = messageCenterStore.conversationMessages.length;

    // if scroll direction is up and it's near the top, load more messages
    if (y < lastPositionY.current) {
      if (hasMorePages && !loading && y < 50 && len > 0) {
        loadMoreMessages();
      }
    }

    // we don't need to do anything on scroll down
    // else {
    //   console.log('down', y, lastPositionY.current);
    // }

    // zero check, no negative values needed (webkit bounce)
    lastPositionY.current = y <= 0 ? 0 : y;
  };

  const { refetch: refetchConversationById } = useQuery(
    'getConversationByUserId',
    () => getConversationByUserId(userId),
    {
      enabled: false,
      onSuccess: (result) => {
        if (result.data.length > 0) {
          messageCenterStore.setConversationSelectedId(result.data[0].id);
          messageCenterStore.setConversationCustomerId(
            result.data[0].customer.id,
          );

          messageCenterStore.getConversationMessages(1);
        } else {
          messageCenterStore.setConversationCustomerId(Number(userId));
          messageCenterStore.setIsNewMessage(true);
          Toastify.toast.info('This customer has no messages');
        }
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    if (messageCenterStore.isInboxCmsMessages) {
      refetchConversationById();
    }
  }, [messageCenterStore.isInboxCmsMessages]);

  // TODO: fix any[] by a proper type
  // TODO: fix dummy avatar
  const renderList = (list: any[]) => {
    const data = [];

    for (let index = 0; index < list.length; index++) {
      const item = list[index];

      if (!item.is_assistant && !item.is_ai) {
        data.push(
          <>
            {item.files.length > 0 && (
              <PhotoOrFileImage key={`chat-message-${index}-file`} item={item} />
            )}
    
            {item.message_body && (
              <SectionChatInput key={`chat-message-${index}-message`}>
                {item.sequential === 1 && (
                  <ChatInputPictureContent>
                    <Avatar
                      src={
                        !item.sender_avatar.length
                          ? '/images/avatars/john.png'
                          : item.sender_avatar
                      }
                      size="sm"
                    />
                  </ChatInputPictureContent>
                )}
                
                <ChatInputContent sequential={item.sequential}>
                  <ChatText
                    sequential={item.sequential}
                    dangerouslySetInnerHTML={createMarkup(item.message_body)}
                  />
                  <Icon name={checkChatType(item.message_type_id)} />
                </ChatInputContent>
                <SectionMessageRead>
                  <MessageSent>
                    <Icon
                      name="sent"
                      size="sm"
                      onClick={() => onMarkAsRead(item.conversation_body_id)}
                    />

                    <Time>{conversationTime(item.sent_at)}</Time>
                  </MessageSent>
                  {!item.is_read && (
                    <MessageUnread
                      onClick={() => onMarkAsRead(item.conversation_body_id)}
                    />
                  )}

                  {item.is_read && (
                    <MessageRead>
                      <Icon name="read" noResize />
                      <Time>
                        {item.read_by && (
                            <span>Ready by {item.read_by} - </span>
                          )}{' '}
                        {conversationTime(item.read_at)}
                      </Time>
                    </MessageRead>
                  )}
                </SectionMessageRead>
              </SectionChatInput>
            )}
    
            {item.is_ai && !item.is_read && (
              <RemoveButton
                onClick={() => handleRemove(item.conversation_body_id)}
              >
                Remove
              </RemoveButton>
            )}
          </>
        );
      } else {
        item.files.length > 0
          ? data.push(
              <>
                <PhotoOrFileImage key={`chat-message-${index}-file`} item={item} />
    
                {item.message_body && (
                  <MessagesAssistant
                    key={`chat-message-${index}-message`}
                    item={item}
                    handleEditMessage={handleEditMessage}
                  />
                )}
              </>
            )
          : data.push(
              <MessagesAssistant
                key={`chat-message-${index}`}
                item={item}
                handleEditMessage={handleEditMessage}
              />
            );
      }
    
      if (isSuperAdmin && item.voice_call_link) {
        data.push(
          <InCallContainer key={`call-link-${index}`}>
            <div className="in-call-item">
              <span className="updated-at">
                {conversationTime(item.sent_at)}
              </span>
              <Link to={item.voice_call_link} target="_blank">
                <img
                  src="/images/sound-waves.png"
                  alt="sound waves image"
                  className="in-call-img"
                />
              </Link>
            </div>
          </InCallContainer>
        );
      }
    
      if (item.scrollerPosition) {
        // add a scroll position marker
        data.push(
          <div
            key={`chat-divider-${index}`}
            ref={scrollViewRef}
            className="mb-2"
          >
            <hr />
          </div>
        );
      }
    }
    
    if (messageCenterStore.inCall) {
      data.push(
        <InCallContainer key="in-call">
          <div className="in-call-item">
            <span className="updated-at">
              {moment(new Date()).format('D/MM - H:mm')}
            </span>
            <img
              src="/images/sound-waves.png"
              alt="sound waves image"
              className="in-call-img"
            />
          </div>
        </InCallContainer>
      );
    }
    
    return data;
      };

  return (
    <>
      <Container onScroll={onScroll} ref={listViewRef}>
        {messageCenterStore.loadingMessages && (
          <SectionChatLoading>
            <Loading />
          </SectionChatLoading>
        )}
        <SectionChat>
          {renderList(
            !messageCenterStore.isNewMessage
              ? messageCenterStore.conversationMessages
              : [],
          )}
        </SectionChat>
      </Container>
    </>
  );
};

export default observer(MessagesCustomer);
