export type TTaxStatus =
  | 'Pending'
  | 'Signed'
  | 'IncomeReceived'
  | 'ExpenseReceived'
  | 'IncomeCompleted'
  | 'ExpenseCompleted'
  | 'InProgress'
  | 'Paid';

// TODO:
// Tix this ugly inheritance
// inside the DB pre_payment_status = 0, 1, 2
// it merges 2 params:
// (tax_status = ‘pending’ + pre_payment_status = (0 or 1 or 2))

export enum TaxStatus {
  'Pending' = 'Pending',
  'Signed' = 'Signed Up',
  'IncomeReceived' = 'Income Received',
  'ExpenseReceived' = 'Expense Received',
  // 'IncomeCompleted' = "FIX: IncomeCompleted",
  // 'ExpenseCompleted' = "FIX: ExpenseCompleted",
  'InProgress' = 'HMRC In Progress',
  'Paid' = 'Paid',
}

// "Pending" = "Signed Up",
// "IncomeReceived" = "Income Received",
// "ExpenseReceived" = "Expense Received",
// "InProgress" = "HMRC In Progress",
// "0" = "HMRC Early Payout Pending",
// "1" = "HMRC Early Payout Interested",
// "2" = "HMRC Early Payout Not Interested",
// "Paid" = "Paid"

// Pending: Signed Up
// IncomeReceived: Income Received
// ExpenseReceived: Expense Received
// InProgress: HMRC In Progress
// 0: HMRC Early Payout Pending
// 1: HMRC Early Payout Interested
// 2: HMRC Early Payout Not Interested
// Paid: Paid
