import { styled } from 'src/modules';

export const TagsRow = styled.td`
  vertical-align: initial !important;
`;

export const TagsCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 2px;
  color:#5d6452;
  font-size: 13px;
  background: #fadd4b;
`;

export const Details = styled.div`
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffdd05;
  border-radius:30px;
  text-align: center;
  font-size: 11px;
  width:200px;
`;

export const TagsColName = styled.div`
  flex: 2;
  display: flex;
  padding:5px;
`;

export const TagsColValue = styled.div`
  flex: 1;
  text-align: left;
  padding: 5px;
  background-color:#dee1e5;
  color: #4d5c6a;
  max-width: 36px;
  min-width: 35px;
  text-align:center;
  border-radius: 0px 5px 5px 0px;
`;
