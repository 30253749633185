import { action, observable } from "mobx";
import { ESignatureApi } from "src/api";

export default class ESignatureStore {
  @observable
  eSignatures: ESignatureTypes[] = [];

  @action
  getESignatures = async (userId: number, taxYear: number): Promise<void> => {
    const response = await ESignatureApi.getESignatures(userId, taxYear);

    this.eSignatures = response;
  };
}
