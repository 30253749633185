import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Input, Row, Col, Button } from 'src/modules';

const AddQuickReply: React.FC = ({
  onClose,
  createReply,
  setReplyValue,
  replyValue,
}) => {
  return (
    <Container className="mw-100 mb-3">
      <Row className="align-items-center">
        <Col xs="1">Reply</Col>
        <Col>
          <Input
            bsSize="lg"
            type="textarea"
            name="question"
            onChange={(e) => setReplyValue(e.target.value)}
            value={replyValue}
          />
        </Col>
        <Col xs="2">
          <div className="d-flex flex-row">
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary mr-4"
              onClick={() => createReply()}
            >
              SUBMIT
            </Button>
            <Button
              type="reset"
              color="transparent"
              onClick={onClose}
              className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddQuickReply;
