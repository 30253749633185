import React, { useState, useEffect, useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCustomerInformation } from 'src/api/customer.api';
import { enableUserDisabled } from 'src/api/users';
import { Layout, CustomerInformationHeaderPie } from 'src/components';
import {
  BackgroundStatus,
  StatusContainer,
  TextStatus,
} from 'src/components/CustomerInformation/styles';
import { Loading } from 'src/components/ui';
import { CUSTOMER_TAB_ITEMS } from 'src/constants';
import { useYear } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { Badge, Button, observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { CustomerTabs, SUCCESS } from 'src/utils';
import { CustomerTabsTypes } from 'src/utils/types/Customer';

const CustomerInformation: React.FC = () => {
  const theme = useTheme();
  const tabRef = useRef(null);

  // store
  const customer = store.customer;
  const eSignature = store.eSignature;
  const { isPIE } = store.pieLayout;

  // params
  const [queryTab, setQueryTab] = useQueryParam('tab', StringParam);
  const [id] = useQueryParam('id', NumberParam);
  const [userId] = useQueryParam<number>('id');
  const [taxYear] = useQueryParam<number>('tax_year');

  // states
  const [activeTab, setActiveTab] = useState<string>(queryTab || 'details');
  const [activeTabContent, setActiveTabContent] = useState<JSX.Element | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(false);
  const [customerError, setCustomerError] = useState('');
  const [clientHeight, setClientHeight] = useState(0);

  const [year] = useYear();

  const {
    isLoading: loading,
    refetch,
    isRefetching,
  } = useQuery('customer', () => getCustomerInformation(id!, year), {
    onSuccess(data) {
      customer.setCustomerInformation(data);
    },
    onError({ message }) {
      setCustomerError(message);
    },
  });

  const { mutate: mutateEnableUserDisabled } = useMutation(enableUserDisabled, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      refetch();
    },
    onError: (message: any) => {
      Toastify.toast.error(message);
    },
  });

  const fetchSignatures = async () => {
    await eSignature.getESignatures(userId, taxYear);

    const showTick = !!(eSignature?.eSignatures?.length >= 1);

    setHasDocuments(showTick);
  };

  const toggleTab = (tab: string): void => {
    if (tab === activeTab) return;
    setActiveTabContent(null);
    setActiveTab(tab);
    setQueryTab(tab);
  };

  const onSelectTab = (tab: string) => setActiveTab(tab);

  const loadTab = async (): Promise<void> => {
    if (!id || Object.keys(customer.customerData).length < 1) return;
    setActiveTabContent(null);
    let tab: CustomerTabsTypes;
    for (const t of CUSTOMER_TAB_ITEMS) {
      if (t.tab === activeTab) {
        tab = t;
        break;
      }
    }
    const tabContent = await tab.component;

    setActiveTabContent(
      <tabContent.default
        data={customer.customerData}
        userId={id}
        userInfo={customer.customerData}
        taxYear={year}
        tabHeight={clientHeight}
      />,
    );
  };

  useEffect(() => {
    setClientHeight(tabRef.current.clientHeight);
  }, [tabRef]);

  useEffect(() => {
    fetchSignatures();
  }, [userId, taxYear]);

  useEffect(() => {
    loadTab();
  }, [queryTab, year, taxYear, customer.customerData]);

  useEffect(() => {
    refetch();
  }, [id, year]);

  const { status, full_name } = customer.customerData;

  const onEnableUserDisabled = (userId: number) => {
    mutateEnableUserDisabled(userId);
  };

  useEffect(() => {
    refetch();
  }, [customer.notificationsCountChanged]);

  const { progress } = customer.customerData;

  return (
    <Layout>
      {(status === 'Disable' || customerError.length > 0) && (
        <BackgroundStatus />
      )}

      <StatusContainer>
        {status === 'Disable' && (
          <>
            <TextStatus>{`${full_name} is ${status}`}</TextStatus>

            <Button
              type="submit"
              color="primary"
              style={{ position: 'absolute', top: 100 }}
              onClick={() => onEnableUserDisabled(userId)}
            >
              Enable User
            </Button>
          </>
        )}

        {customerError.length > 0 && <TextStatus>{customerError}</TextStatus>}
      </StatusContainer>
      <Dashboard title="Customer Information">
        {isLoading ? (
          <div className="mw-100 mb-2">
            <Loading />
          </div>
        ) : (
          <>
            <CustomerInformationHeaderPie
              item={customer.customerData}
              taxYear={year}
              onSelectTab={onSelectTab}
              isLoadingInformation={loading}
              refetchCustomerInformation={refetch}
              progress={progress}
            />

            <div className={'tab mt-4 ' + (loading ? 'opacity-2' : '')}>
              <ul className="nav nav-tabs" ref={tabRef}>
                {CUSTOMER_TAB_ITEMS.map(({ tab, label }, index) => (
                  <li
                    key={`tab-header-${index}`}
                    className="nav-item position-relative"
                  >
                    <a
                      onClick={() => toggleTab(tab)}
                      className={`nav-link ${
                        activeTab === tab ? 'active' : ''
                      }`}
                    >
                      {label}{' '}
                      {tab === CustomerTabs.INBOX &&
                        customer.customerData?.notifications_count > 0 && (
                          <Badge color="danger" pill>
                            {customer.customerData.notifications_count}
                          </Badge>
                        )}
                      {tab === CustomerTabs.ESIGNATURE && hasDocuments && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={theme.colors.green.main}
                          style={{
                            marginLeft: 4,
                            marginBottom: -3,
                          }}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>

              {isRefetching ? <Loading /> : <>{activeTabContent}</>}
            </div>
          </>
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(CustomerInformation);
