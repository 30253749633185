import styled from 'styled-components';

interface Props {
  employeeType?: string;
  backgroundColor?: string;
  visible?: boolean;
}

export const EmployeeTypeLabel = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  left: 10px;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white.default};
  background-color: ${(props) =>
    props.employeeType === 'Employed'
      ? props.theme.colors.green.main
      : props.theme.colors.pink.main};
`;

export const CurrentYearStatusLabel = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.red.main};
  border-radius: 5px;
  background: transparent;
  padding: 1px 10px;
  position: relative;
  left: 20px;
  color: ${({ theme }) => theme.colors.red.main};
  font-size: 14px;
`;

export const YearsLabel = styled.div<Props>`
  cursor: default;
  position: relative;
  padding: 1px 10px;
  margin-left: 3px;
  border-radius: 5px;
  background: ${(props) => props.backgroundColor};
  color: ${({ theme }) => theme.colors.white.default};
  max-width: 50px;
`;

export const YearsToolTip = styled.div<Props>`
  position: absolute;
  bottom: 24px;
  left: 0;
  padding: 1px 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray.regent};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
`;

export const SanctionedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red.main};
  height: 40px;
  width: 200px;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  left: 16px;
`;

export const Sanctioned = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: x-large;
  font-weight: bold;
`;
