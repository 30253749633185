export const handleLabels = (employee_type: string) => {
  switch (employee_type) {
    case 'Employed':
      return 'Paye';
    case 'SelfEmployed':
      return 'Self Employed';
    default:
      break;
  }
};
