import React from 'react';

import { StaffChartData } from 'src/utils/staffDataset';
import { arraySumParam } from 'src/utils/Utils';

type Props = {
  data: StaffChartData;
};

const StaffRowTotal: React.FC<Props> = ({ data }: Props) => {
  return (
    <tr>
      <td></td>
      {data?.byDate.map((item, index) => (
        <td key={index}>{arraySumParam(item.assistants, 'total')}</td>
      ))}
      <td
        className={data.byGroup.length % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}
      >
        {arraySumParam(data?.byGroup as any, 'total')}
      </td>
    </tr>
  );
};

export default StaffRowTotal;
