import React, { useState, useEffect } from 'react';

import { Download } from 'react-feather';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useMutation, useQuery } from 'react-query';
import Switch from 'react-switch';

import { changeProofIdStatus } from 'src/api/users';
import { ProgressBar } from 'src/components/common';
import { Col, Row, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';
import { CustomerProofId } from 'src/utils/types/Customer';

import { LabelData } from '../../styles';
import { HiddenInput, SectionInput } from '../PersonalInformationPie/styles';

import { Container, LogoBankContainer, PassportLink } from './styles';

type Props = {
  data: CustomerProofId;
  disabled: boolean;
  updateInfo(item: any): void;
  userId: number;
};

const ProofPie = ({ data, disabled, updateInfo, userId }: Props) => {
  const [approved, setApproved] = useState(false);
  const [zoomPhoto, setZoomPhoto] = useState(false);
  const [zoomDocument, setZoomDocument] = useState(false);

  const { mutate: mutationChangeProofIdStatus } = useMutation(
    changeProofIdStatus,
    {
      onSuccess: () => {
        Toastify.toast.success(SUCCESS.GENERIC);
      },
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onChangeApproved = () => {
    setApproved((oldState) => !oldState);
    mutationChangeProofIdStatus(userId);
  };

  useEffect(() => {
    const isApproved = data?.proof_id_status === 'Approved';
    setApproved(isApproved);
  }, [data]);

  return (
    <Container>
      <Row>
        <LogoBankContainer>
          <a href={data?.proof_id_live_photo} target="blank">
            <img
              className={`img-thumbnail ${zoomPhoto && 'clicked'}`}
              width={200}
              src={data?.proof_id_live_photo}
              alt="Proof ID Image"
              // onClick={() => setZoomPhoto(!zoomPhoto)}
            />
          </a>
          <AiFillCheckCircle size={20} color="#209782" />
        </LogoBankContainer>
        <LogoBankContainer>
          <a href={data?.proof_id_document} target="blank">
            <img
              className={`img-thumbnail ${zoomDocument && 'clicked'}`}
              width={200}
              src={data?.proof_id_document}
              alt="Proof ID Image"
              // onClick={() => setZoomDocument(!zoomDocument)}
            />
          </a>
          <AiFillCheckCircle size={20} color="#209782" />
        </LogoBankContainer>
      </Row>
      <Row>
        <Col>
          <SectionInput>
            <LabelData for="id-proof" isPIE>
              ID Proof:
            </LabelData>

            <PassportLink
              href={data?.proof_id_id_proof}
              target="_blank"
              download
              rel="noreferrer"
            >
              <span>{data?.proof_id_id_proof}</span>
              <Download size={20} className="text-muted" color="#209782" />
            </PassportLink>
          </SectionInput>

          <SectionInput>
            <LabelData for="approved-by" isPIE>
              Approved by:
            </LabelData>

            <HiddenInput
              id="approved-by"
              type="text"
              defaultValue={data?.proof_id_approved_by}
              disabled={disabled}
              onChange={(e) => updateInfo(e.target)}
              inputDisabled={disabled}
            />
          </SectionInput>
          <SectionInput>
            <LabelData for="id-approved" isPIE>
              ID approved:
            </LabelData>

            <HiddenInput
              id="id-approved"
              type="text"
              defaultValue={data?.proof_id_approved}
              disabled={disabled}
              onChange={(e) => updateInfo(e.target)}
              inputDisabled={disabled}
            />
          </SectionInput>

          <SectionInput style={{ display: 'flex', flexDirection: 'column' }}>
            <LabelData for="id-expires" isPIE>
              ID Expires:
            </LabelData>

            <HiddenInput
              id="id-expires"
              type="text"
              defaultValue={data?.proof_id_expires}
              disabled={disabled}
              onChange={(e) => updateInfo(e.target)}
              inputDisabled={disabled}
            />
          </SectionInput>
        </Col>

        <Col>
          <SectionInput>
            <LabelData for="approved" isPIE>
              Approved:
            </LabelData>

            <div id={`switch-item`}>
              <Switch
                onChange={onChangeApproved}
                checked={approved}
                onColor="#209782"
                width={44}
                height={24}
              />
            </div>
          </SectionInput>

          <SectionInput>
            <LabelData for="ai-process" isPIE>
              AI Process:{' '}
              {(Number(data?.proof_id_face_match || 0) * 100).toFixed(2)}%
            </LabelData>

            <ProgressBar value={Number(data?.proof_id_face_match || 0) * 100} />
          </SectionInput>
        </Col>
      </Row>
    </Container>
  );
};

export default ProofPie;
