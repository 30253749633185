import React, { ChangeEvent, useEffect, useState } from 'react';

import { GiChessQueen } from 'react-icons/gi';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { Button, Col, Row } from 'reactstrap';

import { addPAYE } from 'src/api/incomes';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { NumberParam, Toastify, useQueryParam } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  AddLogoButton,
  Benefits,
  Container,
  EmployerInfo,
  EmployerNameSection,
  EmploymentDetails,
  Pension,
  Proof,
  ProofDetails,
  ProofImage,
  ProofItem,
  SectionCompanyDirector,
  Select,
  StudentLoan,
  SubmitSection,
  Title,
} from './styles';

const payeMock = {
  employer_name: '',
  // logo: null,
  ref: null,
  car: 0,
  fuel: 0,
  private_insurance: 0,
  mileage: 0,
  goods_assets: 0,
  accommodation: 0,
  other: 0,
  document: [],
  document_type: '',
  documents_list: [],
  flow_type: '',
  is_company_director: false,
  gross_amount: null,
  tax_amount: null,
  has_benefits: false,
  addtional_earnings: null,
};

const filesList = [];

function AddPAYE({ setShowAddPayePage, setIncomeTypeSelected }) {
  const [payeInfos, setPayeInfos] = useState(payeMock);
  const [errors, setErrors] = useState({});
  const [proofDocuments, setProofDocuments] = useState([]);

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const { mutate: mutationAddPAYE } = useMutation('addPAYE', addPAYE, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        setShowAddPayePage(false);
        setIncomeTypeSelected(false);
      }, 3000);
    },
    onError({ error }) {
      Toastify.toast.error(error);
    },
  });

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      if (files.length > 1) {
        setPayeInfos((prevState) => ({
          ...prevState,
          [name]: [files],
        }));
      } else {
        setPayeInfos((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    }
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  /* useEffect(() => {
    if (payeInfos.documents_list) {
      setProofDocuments([payeInfos.documents_list]);
    }
  }, [payeInfos.documents_list]); */

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = value;

    if (value === 'true' || value === 'false') {
      newValue = JSON.parse(value);
    }

    setPayeInfos((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  const validateFields = (fields, rules) => {
    const formErrors = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onSubmitPAYE = () => {
    const formErrors = validateFields(payeInfos, {
      employer_name: { required: true },
      flow_type: { required: true },
      gross_amount: { required: true },
      tax_amount: { required: true },
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const formData = new FormData();

      formData.append('customer_id', userId);
      formData.append('tax_year', year);
      formData.append('income_type', 'employment');
      formData.append('employer_name', payeInfos.employer_name);
      // formData.append('logo', payeInfos.logo);
      formData.append('ref', payeInfos.ref);
      formData.append('gross_amount', payeInfos.gross_amount);
      formData.append('tax_amount', payeInfos.tax_amount);
      formData.append('car', payeInfos.car);
      formData.append('fuel', payeInfos.fuel);
      formData.append('private_insurance', payeInfos.private_insurance);
      formData.append('mileage', payeInfos.mileage);
      formData.append('goods_assets', payeInfos.goods_assets);
      formData.append('accommodation', payeInfos.accommodation);
      formData.append('other', payeInfos.other);
      formData.append('document', proofDocuments);
      formData.append('is_company_director', payeInfos.is_company_director);
      formData.append('flow_type', payeInfos.flow_type);
      formData.append('has_benefits', payeInfos.has_benefits);

      if (proofDocuments.length > 0) {
        formData.append('document_type', payeInfos.document_type);
      }

      mutationAddPAYE(formData);
    }
  };

  const onCancelSubmit = () => {
    setPayeInfos(payeMock);
    setShowAddPayePage(false);
    setIncomeTypeSelected(false);
  };

  const renderError = (field: any) => (
    <span style={{ color: 'red' }}>{field}</span>
  );

  let newDocsList = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <Container>
      <EmployerInfo>
        <Title>
          <span>Employer Info</span> <GiChessQueen size={20} color="#57E4CD" />
        </Title>
        <EmployerNameSection>
          <EditInputPie
            label="Employer name:"
            id="employer_name"
            name="employer_name"
            type="text"
            value={payeInfos.employer_name}
            editMode
            onChange={(event) => handleChangeField(event)}
            maxWidth="366px"
            height="56px"
            fontSize="18px"
            padding="18px"
          />

          {errors.employer_name && renderError(errors.employer_name)}

          {/* <input
            type="file"
            onChange={(event) => handleFileUpload(event)}
            hidden
            id="logo"
            name="logo"
          />
          <AddLogoButton htmlFor="logo">
            <HiOutlinePhotograph size={24} />
            <span>Add logo</span>
          </AddLogoButton> */}
        </EmployerNameSection>
      </EmployerInfo>

      <EmploymentDetails>
        <Title>
          <span>Employment Details</span>{' '}
          <GiChessQueen size={20} color="#57E4CD" />
        </Title>

        <Row>
          <Col>
            <EditInputPie
              label="PAYE number"
              id="ref"
              name="ref"
              type="text"
              value={payeInfos.ref}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />
          </Col>

          <Col>
            <EditInputPie
              label="Pay received"
              id="gross_amount"
              name="gross_amount"
              type="number"
              value={payeInfos.gross_amount}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            {errors.gross_amount && renderError(errors.gross_amount)}
          </Col>

          <Col>
            <EditInputPie
              label="Tax amount"
              id="tax_amount"
              name="tax_amount"
              type="number"
              value={payeInfos.tax_amount}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            {errors.tax_amount && renderError(errors.tax_amount)}
          </Col>
        </Row>
        <Row>
          <Col>
            <SectionCompanyDirector>
              <div>
                <label htmlFor="flow_type" className="title">
                  Flow type
                  <GiChessQueen
                    size={20}
                    color="#57E4CD"
                    style={{ position: 'relative', bottom: 2 }}
                  />
                </label>
                <Select
                  id="flow_type"
                  name="flow_type"
                  onChange={handleChangeField}
                >
                  <option value="">Select an option</option>
                  <option value="automatic">Automatic</option>
                  <option value="p45">P45</option>
                  <option value="p60">P60</option>
                  <option value="payslip">payslip</option>
                </Select>
              </div>

              {errors.flow_type && renderError(errors.flow_type)}
            </SectionCompanyDirector>
          </Col>

          <Col>
            <EditInputPie
              label="Adittional earnings"
              id="addtional_earnings"
              name="addtional_earnings"
              type="number"
              value={payeInfos.addtional_earnings}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />
          </Col>

          <Col>
            <SectionCompanyDirector>
              <label htmlFor="is_company_director" className="title">
                Company director
              </label>
              <label>
                <input
                  type="radio"
                  id="is_company_directorYes"
                  name="is_company_director"
                  value={true}
                  onChange={handleChangeField}
                  checked={payeInfos.is_company_director}
                  style={{ marginRight: 6 }}
                />{' '}
                Yes
              </label>

              <label>
                <input
                  type="radio"
                  id="is_company_directorNo"
                  name="is_company_director"
                  value={false}
                  onChange={handleChangeField}
                  checked={!payeInfos.is_company_director}
                  style={{ marginRight: 6 }}
                />{' '}
                No
              </label>
            </SectionCompanyDirector>
          </Col>

          <Col>
            <SectionCompanyDirector>
              <label htmlFor="has_benefits" className="title">
                Has benefits
              </label>
              <label>
                <input
                  type="radio"
                  id="has_benefitsYes"
                  name="has_benefits"
                  value={true}
                  onChange={handleChangeField}
                  checked={payeInfos.has_benefits}
                  style={{ marginRight: 6 }}
                />{' '}
                Yes
              </label>

              <label>
                <input
                  type="radio"
                  id="has_benefitsNo"
                  name="has_benefits"
                  value={false}
                  onChange={handleChangeField}
                  checked={!payeInfos.has_benefits}
                  style={{ marginRight: 6 }}
                />{' '}
                No
              </label>
            </SectionCompanyDirector>
          </Col>
        </Row>
      </EmploymentDetails>

      <Benefits>
        <Title>
          <span>Benefits</span> <GiChessQueen size={20} color="#57E4CD" />
        </Title>

        <Row>
          <Col>
            <EditInputPie
              label="Company car/van"
              id="car"
              name="car"
              type="number"
              value={payeInfos.car}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            <EditInputPie
              label="Mileage"
              id="mileage"
              name="mileage"
              type="number"
              value={payeInfos.mileage}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            <EditInputPie
              label="Other benefits"
              id="other"
              name="other"
              type="number"
              value={payeInfos.other}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />
          </Col>

          <Col>
            <EditInputPie
              label="Fuel"
              id="fuel"
              name="fuel"
              type="number"
              value={payeInfos.fuel}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            <EditInputPie
              label="Goods"
              id="goods_assets"
              name="goods_assets"
              type="number"
              value={payeInfos.goods_assets}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />
          </Col>

          <Col>
            <EditInputPie
              label="Private insurance"
              id="private_insurance"
              name="private_insurance"
              type="number"
              value={payeInfos.private_insurance}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />

            <EditInputPie
              label="Accommodation"
              id="accommodation"
              name="accommodation"
              type="number"
              value={payeInfos.accommodation}
              editMode
              onChange={(event) => handleChangeField(event)}
              maxWidth="366px"
              height="56px"
              fontSize="18px"
              padding="18px 18px 18px 28px"
              hasCrown
            />
          </Col>
        </Row>
      </Benefits>

      <Proof>
        <Row style={{ alignItems: 'center' }}>
          <div style={{ marginRight: 20 }}>
            <label
              htmlFor="document_type"
              style={{ fontSize: 16, fontWeight: '600' }}
            >
              Document type
            </label>
            <Select
              id="document_type"
              name="document_type"
              onChange={handleChangeField}
            >
              <option value="" selected>
                Select an option
              </option>
              <option value="P45">P45</option>
              <option value="P60">P60</option>
              <option value="payslip">Payslip</option>
            </Select>
            <br /> <br />{' '}
            <span>Select a document type do enable Add proof button </span>
          </div>

          <input
            type="file"
            onChange={(event) => onUploadProof(event)}
            hidden
            id="documents_list"
            name="documents_list"
            multiple
          />
          <AddLogoButton htmlFor="documents_list" style={{ marginBottom: 40 }}>
            <TbFileSpreadsheet size={24} />
            <span>Add proof</span>
          </AddLogoButton>
        </Row>
        <br />
        <br />
        <Title>
          <span>Proof uploaded</span>
        </Title>

        <Row>
          {newDocsList.length > 0 &&
            newDocsList.map((proof, i) => {
              return (
                <ProofItem key={i}>
                  <ProofImage></ProofImage>
                  <ProofDetails>
                    <div className="image-size">
                      <span>{proof.name}</span>
                      <span>{proof.size}KB </span>
                    </div>

                    <div>
                      <span className="remove" onClick={() => onRemoveProof(i)}>
                        Remove
                      </span>
                    </div>
                  </ProofDetails>
                </ProofItem>
              );
            })}
        </Row>
        <Row>
          <input
            type="file"
            onChange={(event) => handleFileUpload(event)}
            hidden
            id="addMoreProof"
            name="addMoreProof"
          />
        </Row>
      </Proof>

      <SubmitSection style={{ gap: 20 }}>
        <AddLogoButton onClick={() => onSubmitPAYE()} htmlFor="submitPaye">
          <span>Submit</span>
        </AddLogoButton>

        <AddLogoButton
          onClick={() => onCancelSubmit()}
          htmlFor="cancelPaye"
          style={{ background: 'red', color: '#fff' }}
        >
          <span>Cancel</span>
        </AddLogoButton>
      </SubmitSection>
    </Container>
  );
}

export default AddPAYE;
