import { CustomerTabs } from 'src/utils';
import { CustomerTabsTypes } from 'src/utils/types/Customer';

export const CUSTOMER_TAB_ITEMS: CustomerTabsTypes[] = [
  {
    tab: CustomerTabs.TASK_HISTORY,
    label: 'Task History',
    component: import(
      'src/components/CustomerInformationTabs/CustomerInformationTaskHistoryTab'
    ),
  },
  // {
  //   tab: CustomerTabs.TAX_INCOME,
  //   label: 'Tax Income',
  //   component: import(
  //     'src/components/CustomerInformationTabs/CustomerInformationTaxIncomeTab'
  //   ),
  // },
  {
    tab: CustomerTabs.INCOMES,
    label: 'Incomes',
    component: import('src/components/CustomerInformationTabs/IncomesTab'),
  },
  {
    tab: CustomerTabs.EXPENSES,
    label: 'Expenses',
    component: import('src/components/CustomerInformationTabs/ExpensesTab'),
  },
  // {
  //   tab: CustomerTabs.TAX_EXPENSE,
  //   label: 'Tax Expense',
  //   component: import(
  //     'src/components/CustomerInformationTabs/CustomerInformationTaxExpenseTab'
  //   ),
  // },
  // {
  //   //@TODO: Remove above tab and rename the tab bellow.
  //   tab: CustomerTabs.TAX_EXPENSE_NEW,
  //   label: 'New Tab Expense',
  //   component: import('src/components/CustomerTabs/TaxExpense'),
  // },
  // {
  //   tab: CustomerTabs.HMRC,
  //   label: 'HMRC',
  //   component: import(
  //     'src/components/CustomerInformationTabs/CustomerInformationHmrcTab'
  //   ),
  // },
  {
    tab: CustomerTabs.INBOX,
    label: 'Inbox',
    component: import('src/components/CustomerInformationTabs/InboxTab'),
  },
  {
    tab: CustomerTabs.REFERRALS,
    label: 'Referrals',
    component: import('src/components/CustomerInformationTabs/ReferralsTab'),
  },
  {
    tab: CustomerTabs.ADMIN_NOTES,
    label: 'Admin Notes',
    component: import(
      'src/components/CustomerInformationTabs/CustomerInformationAdminNotesTab'
    ),
  },
  {
    tab: CustomerTabs.ESIGNATURE,
    label: 'E-Signature',
    component: import(
      'src/components/CustomerInformationTabs/CustomerInformationEsignatureTab/CustomerInformationESignatureTab'
    ),
  },
  {
    tab: CustomerTabs.DETAILS,
    label: 'User Detail',
    component: import('src/components/CustomerInformationTabs/UserDetailsTab'),
  },
  // {
  //   tab: CustomerTabs.TAX_CALC,
  //   label: 'Tax Calc',
  //   component: import('src/components/CustomerInformationTabs/TaxCalc'),
  // },
  {
    tab: CustomerTabs.FORMS,
    label: 'Tax Calc',
    component: import('src/components/CustomerInformationTabs/FormsTab'),
  },
  {
    tab: CustomerTabs.TAX_HISTORY,
    label: 'Tax History',
    component: import('src/components/CustomerInformationTabs/TaxHistory'),
  },
];
