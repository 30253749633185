import React, { useState, useEffect } from 'react';

import { Download, LogIn } from 'react-feather';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useMutation } from 'react-query';
import Switch from 'react-switch';

import { changeProofAddressStatus } from 'src/api/users';
import { Col, Row, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';
import { CustomerProofId } from 'src/utils/types/Customer';

import { LabelData } from '../../styles';
import { HiddenInput, SectionInput } from '../PersonalInformationPie/styles';

import { Container, LogoBankContainer, PassportLink } from './styles';

type Props = {
  data: CustomerProofId;
  disabled: boolean;
  updateInfo(item: any): void;
  userId: number;
};

export const ProofAddress = ({ data, disabled, updateInfo, userId }: Props) => {
  const [aproved, setAproved] = useState(false);
  const [zoom, setZoom] = useState(false);

  const { mutate: mutationChangeProofAddressStatus } = useMutation(
    changeProofAddressStatus,
    {
      onSuccess: () => {
        Toastify.toast.success(SUCCESS.GENERIC);
      },
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onChangeAproved = () => {
    setAproved((oldState) => !oldState);
    mutationChangeProofAddressStatus(userId);
  };

  useEffect(() => {
    const isApproved = data?.address_proof_status === 'Approved' ? true : false;
    setAproved(isApproved);
  }, [data]);

  return (
    <Container>
      <Row>
        <LogoBankContainer>
          {data?.extension === 'pdf' ? (
            <>
              <SectionInput>
                <div>
                  <PassportLink
                    href={data?.address_proof_document}
                    target="_blank"
                  >
                    <span>Click to open file</span>
                    <LogIn size={20} className="text-muted" color="#209782" />
                  </PassportLink>
                </div>
              </SectionInput>
            </>
          ) : (
            <>
              <a href={data?.address_proof_document} target="blank">
                <img
                  className={`img-thumbnail ${zoom && 'clicked'}`}
                  width={200}
                  src={data?.address_proof_document}
                  alt="Proof Address Image"
                  // onClick={() => setZoom(!zoom)}
                />
              </a>
              <AiFillCheckCircle size={20} color="#209782" />
            </>
          )}
        </LogoBankContainer>
      </Row>
      <Row>
        <Col>
          <SectionInput>
            <LabelData for="id-proof" isPIE>
              Address Proof:
            </LabelData>

            <PassportLink
              href={data?.proof_id_id_proof}
              target="_blank"
              download
              rel="noreferrer"
            >
              <span>Document</span>
              <Download size={20} className="text-muted" color="#209782" />
            </PassportLink>
          </SectionInput>

          <SectionInput>
            <LabelData for="aproved-by" isPIE>
              Aproved by:
            </LabelData>

            <HiddenInput
              id="aproved-by"
              type="text"
              defaultValue={data?.proof_id_approved_by}
              disabled={disabled}
              onChange={(e) => updateInfo(e.target)}
              inputDisabled={disabled}
            />
          </SectionInput>
          <SectionInput>
            <LabelData for="aproved" isPIE>
              Approved:
            </LabelData>

            <HiddenInput
              id="id-aproved"
              type="text"
              defaultValue={data?.proof_id_approved}
              disabled={disabled}
              onChange={(e) => updateInfo(e.target)}
              inputDisabled={disabled}
            />
          </SectionInput>
        </Col>

        <Col>
          <SectionInput>
            <LabelData for="aproved" isPIE>
              Aproved:
            </LabelData>

            <div id={`switch-item`}>
              <Switch
                onChange={onChangeAproved}
                checked={aproved}
                onColor="#209782"
                width={44}
                height={24}
              />
            </div>
          </SectionInput>
        </Col>
      </Row>
    </Container>
  );
};
