import React, { useEffect } from 'react';

import ChatContent from 'src/components/messages/ChatContent';
import store from 'src/stores';
// import { Container } from './styles';

type Props = {
  tabHeight: number;
};

const InboxTab = ({ tabHeight }: Props) => {
  const messageCenterStore = store.messageCenter;

  useEffect(() => {
    messageCenterStore.setIsInboxCmsMessages(true);
  }, []);

  return (
    <ChatContent
      isInboxCmsMessages={messageCenterStore.isInboxCmsMessages}
      tabHeight={tabHeight}
    />
  );
};

export default InboxTab;
