import styled from 'styled-components';

export const OtherInformationsContainer = styled.div`
  display: flex;
  width: 50%;
  margin-top: 100px;
`;

export const EmploymentLogo = styled.img`
  width: 70px;
  border-radius: 5px;
`;

export const FlowTypeLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

export const UpdateCancelSection = styled.div`
  display: flex;
`;

export const AddCancelSection = styled.div`
  display: flex;
  margin-left: 15px;
  width: 100%;
`;

export const CancelTransactionButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #f31b1b;
  height: 38px;
  border: none;
  border-radius: 48px;
  padding: 0 24px;
  color: #fff;
  margin-left: 20px;

  > span {
    font-weight: 600;
  }
`;

export const CategoryIdLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

export const AddLogoButton = styled.label`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 18px;
  max-width: 200px;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const AddProofContainer = styled.div`
  margin-top: 50px;
`;
