import React, { useState } from 'react';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Row, Col, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const QuestionLibraryRow: React.FC = ({
  reply,
  onChangeStatus,
  active,
  value,
  updateReply,
  deleteReply,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [newReply, setNewReply] = useState(reply);

  const toggle = () => {
    setDisabled((disabled) => !disabled);
  };

  const update = () => {
    toggle();
    updateReply(newReply);
  };

  return (
    <TableRow className="align-items-center">
      <Col className="d-flex align-items-center justify-content-center">
        <Input
          type="text"
          name="question"
          defaultValue={reply}
          disabled={disabled}
          onChange={(e) => setNewReply(e.target.value)}
        />
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onChangeStatus}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? () => toggle() : () => update()}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            onClick={deleteReply}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default QuestionLibraryRow;
