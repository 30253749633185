import React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
};

const Main: React.FC<Props> = ({ className, children }) => (
  <div className={classNames("main", className)}>{children}</div>
);

export default Main;
