import React, { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { listCategories } from 'src/api/categories';
import {
  editExpense,
  listExpenses,
  deleteExpense,
  createExpense,
} from 'src/api/expenses';
import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import {
  Layout,
  ExpensesRow,
  TableHead,
  CustomPagination,
  AddExpense,
} from 'src/components';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Card,
  Col,
  Container,
  CustomInput,
  Row,
  Toastify,
} from 'src/modules';
import { ERRORS, SUCCESS } from 'src/utils/enums/messages.enum';

const PLACEHOLDER_DATA = {
  data: [],
  total_entries: 0,
  total_pages: 0,
  last_page: 0,
  next_page: 0,
  has_more: false,
  next_page_url: '',
  previous_page_url: '',
  message: '',
};

const ExpensesPage: React.FC = () => {
  const queryClient = useQueryClient();

  const [selectedCategory, setSelectedCategory] = useState('');
  const [showAddcategory, setShowAddCategory] = useState(false);
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  const { data: { data: expenses } = { data: [] }, isLoading } = useQuery(
    ['listExpenses', nextPage],
    listExpenses,
    {
      placeholderData: PLACEHOLDER_DATA,
      onSuccess: ({
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
    },
  );

  const { mutate: update } = useMutation(editExpense, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const { mutate: deleteMutation } = useMutation(deleteExpense, {
    onSuccess: (data) => {
      queryClient.setQueryData<ListExpensesResponse>(
        ['listExpenses', nextPage],
        (cache) =>
          cache
            ? {
                ...cache,
                data: cache.data.filter((item) => item.id !== data.id),
              }
            : PLACEHOLDER_DATA,
      );
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const { mutate: createMutation, isLoading: isAdding } = useMutation(
    createExpense,
    {
      onSettled: () => {
        setShowAddCategory(false);
      },
      onSuccess: (data) => {
        queryClient.setQueryData<ListExpensesResponse>(
          ['listExpenses', nextPage],
          (cache) =>
            cache
              ? {
                  ...cache,
                  data: [data, ...cache.data],
                }
              : PLACEHOLDER_DATA,
        );
      },
      onError: ({ error }) => {
        Toastify.toast.error(error);
      },
    },
  );

  const { data: categories = [] } = useQuery('listCategories', listCategories, {
    onError: () => {
      Toastify.toast.error(ERRORS.CATEGORIES);
    },
  });

  const statusChangeFetch = async (url) => {
    try {
      await request.put(url);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };
  const onStatusChange = async (item) => {
    await statusChangeFetch(`${API.EXPENSES_STATUS_CHANGE}/${item.id}`);
    const expensesUpdate = [];
    expenses.map((expense) => {
      if (expense.id === item.id) {
        expense.status = expense.status === 'Enable' ? 'Disable' : 'Enable';
      }
      expensesUpdate.push(expense);
    });
  };

  return (
    <Layout>
      <Dashboard title="Expenses">
        <Row className="justify-content-end mb-4">
          <Col xs="8">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setShowAddCategory(true);
              }}
              outline
            >
              Add New Expense
            </Button>
          </Col>
          <Col xs="4">
            <CustomInput
              type="select"
              id="expenseSelect"
              name="customSelect"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select...</option>
              {categories.map((item) => (
                <option key={`category-option-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </CustomInput>
          </Col>
        </Row>

        {showAddcategory && (
          <AddExpense
            optionsSelect={categories}
            onClose={() => {
              setShowAddCategory(false);
            }}
            createExpense={(categoryId, description) =>
              createMutation({ categoryId, description })
            }
            loading={isAdding}
          />
        )}
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Type</Col>
              <Col xs="2">Category</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {isLoading && <Loading />}

            {expenses
              .filter((item) =>
                selectedCategory
                  ? item.cat_id.toString() === selectedCategory
                  : true,
              )
              .map((item) => (
                <ExpensesRow
                  key={`expense-row-${item.id}`}
                  active={item.status === 'Enable'}
                  value={item.id}
                  onStatusChange={() => onStatusChange(item)}
                  type={item.description}
                  category={item.cat_id}
                  categoryOptions={categories}
                  updateExpense={(description, categoryId) =>
                    update({ id: item.id, categoryId, description })
                  }
                  deleteJob={() => deleteMutation({ id: item.id })}
                />
              ))}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default ExpensesPage;
