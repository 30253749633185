import { styled, Table, Label } from 'src/modules';

type TableDataProps = {
  alignRight?: boolean;
};

type Props = {
  isPIE?: boolean;
};

export const DateRangeInput = styled.input`
  width: 100%;
  max-width: 190px;
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-bottom: 40px;
  padding: 18px;
  font-size: 18px;

  &::placeholder {
    font-size: 18px;
  }
`;

export const Select = styled.select`
  height: 36px;
  width: 100%;
  max-width: 366px;
  margin-bottom: 40px;
`;

export const Wrapper = styled.div``;

export const WrapperTabHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 20px;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const SectionTitle = styled.legend`
  margin: 0 0 16px 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
`;

export const Content = styled(Table).attrs({
  borderless: true,
})``;

export const LabelData = styled(Label)<Props>`
  font-weight: ${(props) => (props.isPIE ? '600' : 'initial')};
  font-size: ${(props) => (props.isPIE ? '16px' : 'initial')};
`;

export const TableRow = styled.tr``;

export const TableData = styled.td<TableDataProps>`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
`;

export const EmployeeType = styled.p``;

export const EmployeeCheckbox = styled.div`
  display: flex;
`;

export const AddressWrapper = styled.div`
  display: flex;

  button {
    padding-top: 4px;
    color: ${({ theme }) => theme.colors.blue.cyan};
    border: none;
    background-color: transparent;
  }
`;

export const AddLogoButton = styled.label`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 18px;
  margin-bottom: 40px;
  cursor: pointer;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;
