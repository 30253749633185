import React, { useState } from 'react';

import { useMutation } from 'react-query';

import { deleteMessage, updateMessage } from 'src/api/message-center';
import Avatar from 'src/components/Avatar';
import Icon from 'src/components/Icon';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Toastify,
  observer,
} from 'src/modules';
import store from 'src/stores';
import { conversationTime, createMarkup, SUCCESS } from 'src/utils';
import checkChatType from 'src/utils/helpers/checkChatType';

import {
  SectionChatInput,
  ChatInputPictureContent,
  SectionChatAssistantInfo,
  ChatInputContent,
  ChatText,
  SectionMessageRead,
  MessageSent,
  MessageRead,
  Time,
  AssistantInfoDetails,
  AssistantName,
  ReadBy,
  SectionNotSentActions,
  NotSentAction,
  ActionButton,
  Wrapper,
  ActionsWrapper,
  SentBySection,
} from './styles';

type Props = {
  item: any;
  handleEditMessage: (converstationId: number, imessage: string) => void;
};

const MessagesAssistant: React.FC<Props> = ({ item, handleEditMessage }) => {
  // store
  const storeMessageCenter = store.messageCenter;
  const themeStore = store.theme;

  // states
  const [openActions, setOpenActions] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  // mutations
  const { mutate: mutateDeleteMessage } = useMutation(deleteMessage, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      storeMessageCenter.getConversationMessages(1);
      setShowRemoveModal(false);
    },
    onError: ({ message }) => {
      Toastify.toast.error(message);
    },
  });

  const { isLoading, mutate: mutateUpdateMessage } = useMutation(
    updateMessage,
    {
      onSuccess: () => {
        storeMessageCenter.getConversationMessages(1);
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  // general functions
  const toggleRemoveModal = () => setShowRemoveModal((prevState) => !prevState);

  const onEditMessage = (
    converstationBodyId: number,
    messageBody: string,
    underReview: boolean,
  ) => {
    storeMessageCenter.setEditMessage(
      converstationBodyId,
      messageBody,
      true,
      underReview,
    );
    setOpenActions(!openActions);
  };

  const onEditUnderReviewMessage = (item: any) => {
    handleEditMessage(item.conversation_body_id, item.message_body);
  };

  const onApproveUnderReviewMessage = (item: any) => {
    mutateUpdateMessage({
      converstationBodyId: item.conversation_body_id,
      messageBody: item.message_body,
      underReview: false,
    });
  };

  const onDeleteMessage = (conversation_body_id: number) => {
    mutateDeleteMessage({ conversationBodyId: conversation_body_id });
    setOpenActions(!openActions);
  };

  return (
    <Wrapper>
      <SectionChatInput underReview={item.under_review}>
        <SectionMessageRead>
          <MessageSent>
            <Time>{conversationTime(item.sent_at)}</Time>
            {themeStore.currentTheme === 'dark' ? (
              <Icon name="white-sent" size="sm" />
            ) : (
              <Icon name="sent" size="sm" />
            )}
          </MessageSent>

          {item.is_read && (
            <MessageRead>
              <Time>{conversationTime(item.read_at)}</Time>
              <Icon name="read" noResize />
            </MessageRead>
          )}

          {item.is_ai && (
            <SentBySection>
              From AI <br />
            </SentBySection>
          )}

          <SentBySection>Sent by {item.sent_as}</SentBySection>
        </SectionMessageRead>

        <ChatInputContent
          underReview={item.under_review}
          sequential={item.sequential}
        >
          {item.is_read ? (
            <Icon name={checkChatType(item.message_type_id)} />
          ) : (
            <Icon
              name="not-sent"
              noResize
              onClick={() => setOpenActions(!openActions)}
            />
          )}

          <ChatText
            sequential={item.sequential}
            dangerouslySetInnerHTML={createMarkup(item.message_body)}
          />

          {!item.is_read && openActions && (
            <SectionNotSentActions>
              <NotSentAction
                onClick={() =>
                  onEditMessage(
                    item.conversation_body_id,
                    item.message_body,
                    item.under_review,
                  )
                }
              >
                Edit message
              </NotSentAction>
              <NotSentAction
                onClick={() => onDeleteMessage(item.conversation_body_id)}
              >
                Delete message
              </NotSentAction>
            </SectionNotSentActions>
          )}
        </ChatInputContent>

        <SectionChatAssistantInfo>
          {item.sequential === 1 && (
            <ChatInputPictureContent>
              <Avatar src={item.sender_avatar} size="sm" />
            </ChatInputPictureContent>
          )}

          <AssistantInfoDetails>
            {item.sequential === 1 && (
              <>
                <AssistantName>{item.sent_by} </AssistantName>
                {item.read_by && <ReadBy>(Read by {item.read_by})</ReadBy>}
              </>
            )}
          </AssistantInfoDetails>
        </SectionChatAssistantInfo>
      </SectionChatInput>

      <ActionsWrapper>
        <ActionButton onClick={toggleRemoveModal} variant="remove">
          REMOVE
        </ActionButton>
        <ActionButton onClick={() => onEditUnderReviewMessage(item)}>
          EDIT
          <Icon name="pencil" size="md" />
        </ActionButton>

        {item.under_review && (
          <ActionButton onClick={() => onApproveUnderReviewMessage(item)}>
            APPROVE
            {isLoading ? (
              <div className="spinner-border spinner-border-sm text-dark" />
            ) : (
              <Icon name="sent" size="sm" />
            )}
          </ActionButton>
        )}
      </ActionsWrapper>

      <Modal centered isOpen={showRemoveModal} onClose={toggleRemoveModal}>
        <ModalHeader toggle={toggleRemoveModal}>
          Are you sure you want to remove this message?
        </ModalHeader>
        <ModalBody className="d-flex flex-wrap justify-content-end">
          <Button
            color="danger"
            onClick={() =>
              mutateDeleteMessage({
                conversationBodyId: item.conversation_body_id,
              })
            }
            className="mr-2"
          >
            Remove
          </Button>
          <Button color="secondary" onClick={toggleRemoveModal}>
            Cancel
          </Button>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default observer(MessagesAssistant);
