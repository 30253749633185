import React, { useEffect, useState } from 'react';

import { AiOutlinePaperClip, AiOutlinePlus } from 'react-icons/ai';
import { BsFillTrashFill, BsBank2, BsUpload } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { deleteTransaction, getIncomeTransactions } from 'src/api/incomes';
import CustomPagination from 'src/components/CustomPagination';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { formatDate, SUCCESS } from 'src/utils';

import { NewItemButton } from '../../styles';

import {
  Actions,
  Circle,
  Container,
  SectionAddNewItem,
  TableBody,
  TableHeader,
} from './styles';

function TransactionsListAll({
  incomeType,
  incomeId,
  setOpenAddTransactionForm,
  openAddTransactionForm,
  keyword,
  date_range_start,
  date_range_end,
  reconciliation_status,
}) {
  const incomes = store.incomes;

  const [transactionsListState, setTransactionsListState] = useState([]);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
  }, []);

  const { isLoading, refetch } = useQuery(
    [
      'getIncomeTransactions',
      incomeType,
      incomeId,
      keyword,
      date_range_start,
      date_range_end,
      reconciliation_status,
      nextPage,
    ],
    () =>
      getIncomeTransactions(
        incomeType,
        incomeId,
        keyword,
        date_range_start,
        date_range_end,
        reconciliation_status,
        nextPage,
      ),
    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);
        incomes.setIncomeTransactions(result.data);
        incomes.setIsLoadingTransaction(false);
        incomes.setPayeOrSelfEmployedTotal(result.totals);
        incomes.setRefetchIncomeTransactions(refetch);

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      refetch();
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  return (
    <>
      <Container>
        <TableHeader>
          <Row>
            <Col className="headerColumn"></Col>
            <Col className="headerColumn">Merchant name</Col>
            <Col className="headerColumn">Date</Col>
            <Col className="headerColumn">Amount</Col>
            <Col className="headerColumn" lg={2}></Col>
          </Row>
        </TableHeader>

        {!isLoading &&
          transactionsListState.map((transaction, index) => {
            return (
              <TableBody
                key={transaction.id}
                onClick={() => incomes.setIndexTransactionSelected(index)}
              >
                <Row>
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: 30 }}
                  >
                    {/* <Circle
                      background={transaction.approved ? '#209782' : '#9C95AB'}
                    /> */}
                    <Circle background="#209782" />
                  </Col>
                  <Col className="bodyColumn">
                    {transaction.merchant_name !== ''
                      ? transaction.merchant_name
                      : transaction.description}
                  </Col>
                  <Col className="bodyColumn">
                    {formatDate(transaction.created_at)}
                  </Col>

                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: -20 }}
                  >
                    {transaction.amount}
                  </Col>

                  <Col lg={2} className="bodyColumn">
                    <Actions>
                      {/* <AiOutlinePaperClip
                        color="#209782"
                        size="24"
                        onClick={() => {}}
                      /> */}

                      <BsFillTrashFill
                        color="red"
                        size="20"
                        onClick={() => onDeleteTransaction(transaction.id)}
                      />
                      {/* <BsBank2 color="#00C0E0" size="20" /> */}
                    </Actions>
                  </Col>
                </Row>
              </TableBody>
            );
          })}

        {!isLoading && transactionsListState.length < 1 && (
          <span>No transactions</span>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}
      </Container>

      <SectionAddNewItem>
        <NewItemButton
          onClick={() => setOpenAddTransactionForm(!openAddTransactionForm)}
        >
          <AiOutlinePlus size={18} />
        </NewItemButton>
      </SectionAddNewItem>
    </>
  );
}

export default observer(TransactionsListAll);
