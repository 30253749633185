import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { Col, Input, Row, Button, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const JobSubCategoryRow = ({
  idCategory,
  subCategoryName,
  onStatusChange,
  active,
  value,
  updateJob,
  deleteJob,
  categoryOptions,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [nameCategory, setNameCategory] = useState('');
  const [name, setName] = useState(subCategoryName);
  const [categoryId, setCategoryId] = useState(idCategory);

  const toggle = () => {
    setDisabled((disabled) => !disabled);
  };

  const update = () => {
    toggle();
    updateJob(name, categoryId);
  };

  const getJobCategorie = async (url: string) => {
    try {
      const { data } = await request.get(url);

      setNameCategory(data.name);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  useEffect(() => {
    getJobCategorie(`${API.JOB_CATEGORIES}/edit/?id=${idCategory}`);
  }, [idCategory]);

  return (
    <TableRow className="align-items-center">
      <Col>
        <Input
          type="text"
          name="sub_category"
          defaultValue={subCategoryName}
          disabled={disabled}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs="3">
        {disabled ? (
          <Link to={'/job-categories'}>
            <strong>{nameCategory}</strong>
          </Link>
        ) : (
          <CustomInput
            value={categoryId}
            type="select"
            name="category_expenses"
            id="category_expenses"
            onChange={(e) => setCategoryId(e.target.value)}
          >
            {categoryOptions.map((item, index) => {
              return (
                <option value={item.id} key={`opt${index}`}>
                  {item.name}
                </option>
              );
            })}
          </CustomInput>
        )}
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? () => toggle() : () => update()}
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon onClick={deleteJob} className="table-button">
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default JobSubCategoryRow;
