import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImage = styled.img<{ rotate: number }>`
  transform: rotate(${({ rotate }) => rotate}deg);
  max-height: 500px;
  object-fit: contain;
`;

export { Wrapper, StyledImage };
