import { action, observable, makeObservable } from 'mobx';

export default class PieLayoutStore {
  @observable
  isPIE = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setIsPIE = (isPIE: boolean) => {
    this.isPIE = isPIE;
  };
}
