import React, { useRef, useState } from 'react';

import { Popover, PopoverBody, Row } from 'reactstrap';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import {
  ICustomer,
  IUserRefunds,
  EIncomeExpenseStatus,
  TIsPaidStatus,
} from 'src/models';
import { observer } from 'src/modules';
import { isTruthy, CustomerTabs } from 'src/utils';

type Props = {
  item: ICustomer;
  onSelectTab(tab: string): void;
  paidStatus: TIsPaidStatus;
};

const getIncomeClass = (item: ICustomer) => {
  if (item && item.year_closed)
    return 'customer-progress-item customer-progress-item-red';

  if (
    item &&
    isTruthy(item.income_received_date || item.income_completed_date)
  ) {
    if (item.income_status === EIncomeExpenseStatus.DONE)
      return 'customer-progress-item customer-progress-item-yellow';

    if (item.income_status === EIncomeExpenseStatus.CHECKED)
      return 'customer-progress-item customer-progress-item-orange';

    if (item.income_status === EIncomeExpenseStatus.SIGNED_OFF)
      return 'customer-progress-item customer-progress-item-green';
  }

  return 'customer-progress-item';
};

const getExpenseClass = (item: ICustomer) => {
  if (item && item.year_closed) {
    return 'customer-progress-item customer-progress-item-red';
  }

  if (item && isTruthy(item.expense_received_date)) {
    if (item.expense_status === EIncomeExpenseStatus.DONE)
      return 'customer-progress-item customer-progress-item-yellow';

    if (item.expense_status === EIncomeExpenseStatus.CHECKED)
      return 'customer-progress-item customer-progress-item-orange';

    if (item.expense_status === EIncomeExpenseStatus.SIGNED_OFF)
      return 'customer-progress-item customer-progress-item-green';
  }

  return 'customer-progress-item';
};

const getHMRCClass = (item: ICustomer) => {
  if (item && item.year_closed) {
    return 'customer-progress-item customer-progress-item-red';
  }

  if (item) {
    if (item.tax_status === 'InProgress' || item.tax_status === 'Paid')
      return 'customer-progress-item customer-progress-item-green';
  }

  return 'customer-progress-item';
};

const CustomersProgress: React.FC<Props> = ({
  item,
  onSelectTab,
  paidStatus,
}) => {
  // States
  const [refunds, setRefunds] = useState<IUserRefunds>();

  // @TODO: Move to mobx
  const loadData = async () => {
    try {
      const { data } = await request.get(
        `${API.PATH}${API.USERS_REFUNDS}?user_id=${item.id}?tax_year=${item.tax_year}`,
      );
      setRefunds(data);
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  const showRefunds = (show: boolean) => {
    setPopover(show);

    if (show) {
      loadData();
    }
  };

  const getPaidClass = () => {
    if (item && item.year_closed) {
      return 'customer-progress-item customer-progress-item-red';
    }

    if (paidStatus === 'Paid') {
      return 'customer-progress-item customer-progress-item-green';
    }

    return 'customer-progress-item';
  };

  const buttonRef: any = useRef();
  const [popover, setPopover] = useState(false);

  const getYears = (json: any) => {
    if (!json)
      return [
        {
          year: '----',
          refund_amount: '£____',
          agent_fee: '£____',
          refund_client: '£____',
        },
      ];

    const list = [];
    for (const key in json) {
      const item = {
        year: key,
        refund_amount: json[key].refund_amount,
        agent_fee: json[key].agent_fee,
        refund_client: json[key].refund_client,
      };
      list.push(item);
    }

    return list;
  };

  return (
    <div className="customer-progress">
      <button
        onClick={() => onSelectTab(CustomerTabs.TAX_INCOME)}
        className={getIncomeClass(item)}
      >
        <div className="customer-progress-item-index">1</div>
        <div className="customer-progress-item-title">Income</div>
        <div className="customer-progress-item-date">
          {item.income_received_date || item.income_completed_date || '-'}
        </div>
      </button>

      <button
        onClick={() => onSelectTab(CustomerTabs.TAX_EXPENSE)}
        className={getExpenseClass(item)}
      >
        <div className="customer-progress-item-index">2</div>
        <div className="customer-progress-item-title">Expense</div>
        <div className="customer-progress-item-date">
          {item.expense_received_date || '-'}
        </div>
      </button>

      <button
        onClick={() => onSelectTab(CustomerTabs.HMRC)}
        className={getHMRCClass(item)}
        ref={buttonRef}
        onMouseEnter={() => {
          showRefunds(true);
        }}
        onMouseLeave={() => {
          showRefunds(false);
        }}
      >
        <div className="customer-progress-item-index">3</div>
        <div className="customer-progress-item-title">
          HMRC
          {item.hmrc_has_warning_flag && (
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          )}
        </div>
        <div className="customer-progress-item-date">
          {item.tax_status_process_date || '-'}
        </div>
      </button>
      <button
        onClick={() => onSelectTab(CustomerTabs.HMRC)}
        className={getPaidClass()}
        ref={buttonRef}
        onMouseEnter={() => {
          showRefunds(true);
        }}
        onMouseLeave={() => {
          showRefunds(false);
        }}
      >
        <div className="customer-progress-item-index">4</div>
        <div className="customer-progress-item-title">Paid</div>
        <div className="customer-progress-item-date">
          {item?.tax_status_paid_date || '-'}
        </div>
      </button>

      {buttonRef.current && (
        <Popover
          fade={false}
          placement="top"
          isOpen={popover}
          target={buttonRef.current}
        >
          <PopoverBody className="px-4 progress-popover">
            {getYears(refunds?.all_year).map((item) => (
              <div key={`refunds_${item?.year}`} className="mb-4">
                <h5 className="text-center">{item?.year}</h5>
                <Row>
                  <div className="flex-fill mr-4">Total Refund</div>
                  <div>{item?.refund_amount}</div>
                </Row>
                <Row>
                  <div className="flex-fill mr-4">Agent Fees (incl VAT)</div>
                  <div>{item?.agent_fee}</div>
                </Row>
                <Row>
                  <div className="flex-fill mr-4">Refund to Client</div>
                  <div>{item?.refund_client}</div>
                </Row>
              </div>
            ))}
            <div>
              <h5 className="text-center">All years</h5>
              <Row>
                <div className="flex-fill mr-4">Grand Total Refunds</div>
                <div>{refunds?.total_refund_amount}</div>
              </Row>
              <Row>
                <div className="flex-fill mr-4">Agent Fees (incl VAT)</div>
                <div>{refunds?.total_agent_fee}</div>
              </Row>
              <Row>
                <div className="flex-fill mr-4">Total Refund to Client</div>
                <div>{refunds?.total_refund_client}</div>
              </Row>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default observer(CustomersProgress);
