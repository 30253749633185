import React, { forwardRef, useState, useImperativeHandle } from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import { useYear } from 'src/hooks';
import { observer } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate } from 'src/utils';

import {
  PrevNextSection,
  Button,
  PrevNextFields,
  PrevNextInputSection,
  Input,
  Select,
} from '../../styles';

type Props = {
  item: object;
  index: number;
  onChangeFieldTransaction: (event, index, item) => void;
  isIncomeTabSelfEmployed?: boolean;
};

export type AllEditTransactionFormHandle = {
  setSelectedTransaction(id: number): void;
};

const EditTransactionForm = forwardRef<AllEditTransactionFormHandle, Props>(
  (
    { item, index, onChangeFieldTransaction, isIncomeTabSelfEmployed }: Props,
    ref,
  ) => {
    const [year] = useYear();

    const incomes = store.incomes;

    const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
    const [editTransaction, setEditTransaction] = useState({});

    const handlePrevClick = () => {
      if (currentTransactionIndex > 0) {
        setCurrentTransactionIndex(currentTransactionIndex - 1);
        setEditTransaction(
          incomes.incomeTransactions[currentTransactionIndex - 1],
        );
      }
    };

    const handleNextClick = () => {
      if (currentTransactionIndex < incomes.incomeTransactions.length - 1) {
        setCurrentTransactionIndex(currentTransactionIndex + 1);
        setEditTransaction(
          incomes.incomeTransactions[currentTransactionIndex + 1],
        );
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        setSelectedTransaction(id: number) {
          const transactionIndex = incomes.incomeTransactions.findIndex(
            (transaction) => transaction.id === id,
          );

          setCurrentTransactionIndex(transactionIndex);
        },
      }),
      [incomes.incomeTransactions],
    );

    const currentCis = item.editTransaction[currentTransactionIndex]?.CIS;

    return (
      <PrevNextSection>
        <Button
          onClick={() => handlePrevClick()}
          disabled={item.editTransaction.length < 2}
        >
          <BsArrowLeft size={18} />
          <span>Previous</span>
        </Button>

        <PrevNextFields>
          {/* {item.openContent && !incomes.isLoadingTransaction && ( */}
          {item.openContent && (
            <>
              <PrevNextInputSection>
                <label htmlFor="merchant_name">Merchant name</label>
                <Input
                  id="merchant_name"
                  name="merchant_name"
                  type="text"
                  value={
                    item.editTransaction[currentTransactionIndex]
                      ?.description ||
                    item.editTransaction[currentTransactionIndex]
                      ?.merchant_name ||
                    ''
                  }
                  onChange={(event) =>
                    onChangeFieldTransaction(
                      event,
                      index,
                      item.editTransaction[currentTransactionIndex]?.id,
                    )
                  }
                  readOnly
                  disabled
                />
              </PrevNextInputSection>

              <PrevNextInputSection>
                <label htmlFor="created_at">Date</label>
                <Input
                  id="created_at"
                  name="created_at"
                  type="text"
                  value={formatDate(
                    item.editTransaction[currentTransactionIndex]?.created_at,
                  )}
                  readOnly
                  disabled
                  onChange={(event) =>
                    onChangeFieldTransaction(
                      event,
                      index,
                      item.editTransaction[currentTransactionIndex]?.id,
                    )
                  }
                />
              </PrevNextInputSection>

              <PrevNextInputSection>
                <label htmlFor="gross">Gross</label>
                <Input
                  id="gross"
                  name="amount"
                  type="text"
                  value={formatCurrency(
                    item.editTransaction[currentTransactionIndex]?.amount || 0,
                  )}
                  onChange={(event) =>
                    onChangeFieldTransaction(
                      event,
                      index,
                      item.editTransaction[currentTransactionIndex]?.id,
                    )
                  }
                  readOnly
                  disabled
                />
              </PrevNextInputSection>
            </>
          )}

          <PrevNextInputSection>
            <label htmlFor="year">Year</label>
            <Select id="year" disabled>
              <option value="2020"> {year} </option>
            </Select>
          </PrevNextInputSection>

          {isIncomeTabSelfEmployed && (
            <PrevNextInputSection>
              <label htmlFor="CIS">Cis</label>
              <Select
                id="CIS"
                name="CIS"
                value={currentCis || null}
                onChange={(event) =>
                  onChangeFieldTransaction(event, index, currentCis)
                }
              >
                <option value={currentCis}>{currentCis} %</option>
                <option value="0">0%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
              </Select>
            </PrevNextInputSection>
          )}
        </PrevNextFields>

        <Button
          onClick={() => handleNextClick()}
          disabled={item.editTransaction.length < 2}
        >
          <BsArrowRight size={18} />
          <span>Next</span>
        </Button>
      </PrevNextSection>
    );
  },
);

export default observer(EditTransactionForm);
