import { MoreHorizontal, PhoneCall } from 'react-feather';

import { styled } from 'src/modules';

interface Props {
  profileOnline?: boolean;
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  background: white;
  justify-content: space-between;
  max-height: 70px;
  padding: 0 ${({ theme }) => theme.spacing.xl}px;

  & button {
    outline: 0 !important;
    border: 0 !important;
    cursor: pointer;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileMessagesStatus = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProfilePicture = styled.div`
  position: relative;
`;

export const ProfilePictureStatus = styled.div<Props>`
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) =>
    props.profileOnline
      ? props.theme.colors.green.main
      : props.theme.colors.gray.ghost};
`;

export const ProfileName = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.black.russian};
  margin: 0 10px;
`;

export const Pipe = styled.span`
  height: 20px;
  width: 1px;
  background: ${(props) => props.theme.colors.gray.gainsboro};
`;

export const ProfileStatusText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray.comet};
  margin: 0 10px;
`;

export const SocialMediaFilters = styled.div`
  display: flex;
  margin-left: 70px;
`;

export const SocialMediaButton = styled.button`
  background: transparent;
`;

export const RightSide = styled.div`
  display: flex;
`;

export const IconButton = styled.button`
  background: transparent !important;
  border: 0 !important;
  height: 35px;
  width: 35px;
  margin: 5px;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  outline: none !important;

  :hover {
    background: ${({ theme }) => theme.colors.gray.light} !important;
  }
`;

export const IconPhone = styled(PhoneCall).attrs({
  size: 20,
})`
  color: ${({ theme }) => theme.colors.gray.regent};
`;

export const IconMenu = styled(MoreHorizontal).attrs({
  size: 20,
})`
  color: ${({ theme }) => theme.colors.gray.regent};
`;

export const TitleModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .title-modal {
    display: block;
    position: absolute;
    bottom: 20px;
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSizes.title.l}px;
  }
`;
