import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import {
  addSanctionFile,
  getHistorySanctions,
} from 'src/api/users/sanction.api';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { TableColumn } from 'src/components/InboxTemplateRow/styles';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Button, Card, Col, Container, Toastify } from 'src/modules';
import {
  SelectTypeSanction,
  UploadFileContainer,
} from 'src/styles/pages/sanction/styles';
import { formatDateFullDate } from 'src/utils';

function SanctionList() {
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);
  const [typeSanctionSelected, setTypeSanctionSelected] = useState('EU');
  const [file, setFile] = useState<File>();

  const { data: sanctionList = [], isLoading } = useQuery(
    ['sanctionList', nextPage],
    getHistorySanctions,
    {
      onSuccess: ({
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },

      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationAddSanctionfile } = useMutation(addSanctionFile, {
    onSuccess: () => {
      Toastify.toast.success('file uploaded');
    },
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onUploadFile = () => {
    const formData = new FormData();

    formData.append('type', typeSanctionSelected);
    formData.append('file', file);

    mutationAddSanctionfile(formData);
  };

  return (
    <Layout>
      <Dashboard title="Sanction List">
        <UploadFileContainer style={{ gap: 6 }}>
          <SelectTypeSanction
            name="list-type-sanction"
            id="list-type-sanction"
            onChange={(e) => setTypeSanctionSelected(e.target.value)}
          >
            <option value="EU" selected>
              EU
            </option>
            <option value="UK">UK</option>
            <option value="US">US</option>
            <option value="UN">UN</option>
          </SelectTypeSanction>

          <input type="file" onChange={(e) => handleChange(e)} />

          <Button
            color="primary"
            className="mr-2"
            onClick={() => onUploadFile()}
            disabled={!typeSanctionSelected || !file}
            style={{ marginLeft: -100 }}
          >
            UPLOAD
          </Button>
        </UploadFileContainer>

        <Card>
          {!isLoading &&
            sanctionList.data.length > 0 &&
            sanctionList.data.map((item) => {
              return (
                <div key={item.id}>
                  <Container className="mw-100">
                    <TableHead>
                      <Col xs="2" className="d-flex justify-content-center">
                        Date: {formatDateFullDate(item.date)}
                      </Col>
                      <Col xs="2" className="d-flex justify-content-center">
                        Upload File: &nbsp;{' '}
                        <a href={item.url} target="_blank" rel="noreferrer">
                          (File Link)
                        </a>
                      </Col>
                      <Col xs="3" className="d-flex justify-content-center">
                        Type: {item.type} (type of the file from the data)
                      </Col>
                    </TableHead>
                    <TableHead>
                      <Col xs="2" className="d-flex justify-content-center">
                        Dob
                      </Col>
                      <Col xs="2" className="d-flex justify-content-center">
                        Name
                      </Col>
                      <Col xs="2" className="d-flex justify-content-center">
                        Percents
                      </Col>
                      <Col xs="2" className="d-flex justify-content-center">
                        Sanction Id
                      </Col>
                    </TableHead>

                    {isLoading && <Loading />}

                    {!isLoading &&
                      item.matches.map((match) => {
                        return (
                          <div key={match.sanction_id}>
                            <TableRow>
                              <TableColumn
                                xs="2"
                                className="d-flex justify-content-center"
                              >
                                {match.dob}
                              </TableColumn>

                              <TableColumn
                                xs="2"
                                className="d-flex justify-content-center"
                              >
                                <a
                                  href={`/app/customer-information/?id=${match.user_id}&tab=details&tax_year=${item.tax_year}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {match.name}
                                </a>
                              </TableColumn>
                              <TableColumn
                                xs="2"
                                className="d-flex justify-content-center"
                              >
                                {match.percents}%
                              </TableColumn>
                              <TableColumn
                                xs="2"
                                className="d-flex justify-content-center"
                              >
                                {match.sanction_id}
                              </TableColumn>
                            </TableRow>
                          </div>
                        );
                      })}
                  </Container>
                  <br />
                  <br />
                </div>
              );
            })}
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
}

export default SanctionList;
