import React, { useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { editStatusTag, deleteStatusTag } from 'src/api/status-tags';
import { ButtonIcon, TableRow } from 'src/components';
import { Input, Row, Col, Toastify } from 'src/modules';
import { ERRORS, SUCCESS } from 'src/utils';

type Props = {
  title: string;
  id: number;
};

const TagRow: React.FC<Props> = ({ title, id }) => {
  const [disabled, setDisabled] = useState(true);
  const [newTitle, setNewTitle] = useState('');

  const queryClient = useQueryClient();

  const toggle = () => {
    setDisabled((disabled) => !disabled);
  };

  const { mutate: update } = useMutation(editStatusTag, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
    onSettled() {
      toggle();
    },
  });

  const { mutate: deleteTag } = useMutation(deleteStatusTag, {
    onSuccess() {
      queryClient.invalidateQueries(['listStatusTags']);
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  return (
    <TableRow className="align-items-center">
      <Col xs="7">
        <Input
          type="text"
          name="type_expenses"
          defaultValue={title}
          disabled={disabled}
          onChange={(e) => setNewTitle(e.target.value)}
        />
      </Col>
      <Col xs="2" className="ml-auto">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? toggle : () => update({ id, newTitle })}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            onClick={() => deleteTag({ id })}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default TagRow;
