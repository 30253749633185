import React, { useState } from 'react';

import { Link } from 'gatsby';
import { Eye } from 'react-feather';
import { useQuery, useQueryClient } from 'react-query';

import { getTaskHistory } from 'src/api/users';
import { Badge, Table } from 'src/modules';
import { conversationTimeYear, isTruthy } from 'src/utils';

import { NotificationRead, Loading } from '../ui';

import FilterSelect from 'src/components/FilterSelect';

const options = [
  {'label': 'All Messages', 'value': 'all'},
  {'label': 'Payments and Purchase', 'value': 'payment'},
  {'label': 'HMRC & submission related notifications', 'value': 'hmrc'},
  {'label': 'ID and Proof ID Verification', 'value': 'proof_id'},
  {'label': 'Openbanking Connection and Transaction', 'value': 'openbanking'},
  {'label': 'Subscription Plan history', 'value': 'subscription'}
];

type Props = {
  userId: number;
};

const CustomerInformationTaskHistoryTab: React.FC<Props> = ({ userId }) => {
  const [filter, setFilter] = useState<string>("all");

  const queryClient = useQueryClient();

  const { data, isLoading: loading } = useQuery(
    ['taskHistory', filter],
    () => getTaskHistory(userId, filter),
    {
      onSuccess() {
        queryClient.invalidateQueries('notificationsCounter');
      },
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  const renderMessage = (text: string) => {
    const hasSpan = text.includes('<span class');

    if (hasSpan) {
      const [message, spanContent] = text.split('<');
      const [, badgeText] = spanContent.split('>');

      return (
        <>
          {message} <Badge color="info">{badgeText}</Badge>
        </>
      );
    } else {
      return text;
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="tab-content">
          <FilterSelect selecting={handleChange} filter={filter} options={options}></FilterSelect>
          <div className="tab-pane active">
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Notification</th>
                  <th>Year</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr
                      key={`information-task-row-${item.id}`}
                      className="text-lg"
                    >
                      <td>{index + 1}</td>
                      <td>
                        <div
                          style={{
                            maxWidth: '800px',
                            overflowWrap: 'break-word',
                            overflow: 'flow',
                          }}
                        >
                          {renderMessage(item.message)}{' '}
                          {item.tag !== '' && (
                            <Badge color="info">{item.tag}</Badge>
                          )}
                        </div>
                      </td>
                      <td>{item.year}</td>
                      <td>{conversationTimeYear(item.sent_datetime)}</td>

                      <td>
                        <div className="d-flex align-items-center">
                          {/* {isTruthy(item.url) && (
                            <Link
                              to={item.url}
                              className="btn-icon mx-1  btn btn-outline-secondary btn-lg"
                            >
                              <Eye size={14} className="text-muted" />
                            </Link>
                          )} */}
                          <NotificationRead
                            initStatus={Boolean(item.is_read)}
                            id={item.id}
                            userId={item.sender_id}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerInformationTaskHistoryTab;
