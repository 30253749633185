import { styled, Col } from 'src/modules';

export const TableColumn = styled(Col)``;

export const WrapperActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 30px;
`;
