import React, { useEffect, useState } from 'react';

import { Table, Card } from 'src/modules';
import { Title } from '../../styles';

import { TaxReturnRow } from './TaxReturnRow';


type Props = {
    onClick?: ( e: any) => void,
    dataList: any
};

export const TaxReturnList = ( { 
    onClick,
    dataList
 }:Props ) => {

    const[ data, setData ] = useState([]);

    useEffect(() => {        
        if(dataList) setData(dataList);
      }, [dataList]);

    function onActionClick( event:any )
    {
        if( onClick ) onClick(event);
    }

    return (
        <>
            <div className="formsList">        
                <Title>Tax Return List</Title>
                <Card>
                    <Table>
                        <thead>
                            <tr>
                                <th>Created Date</th>
                                <th>Request Status</th>
                                <th>Amended Return</th>
                                <th>Welsh Return</th>
                                <th>Declaration</th>
                                <th>Last Request Date</th>
                                <th>Edit / View</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data && data.length > 0 && data.map( (row:any) => <TaxReturnRow key={row.id} row={row} onClick={onActionClick}/> )}
                        </tbody>
                    </Table>
                </Card>
            </div>
        </>
    );
}
