import styled from 'styled-components';

type Props = {
  width: string;
  justifyContent: string;
};

export const SendLetterTitle = styled.span`
  font-weight: bold;
`;

export const SendLetterContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;

  .select-send-letter {
    width: 200px;
    height: 30px;
    margin-right: 15px;
  }
`;

export const LettersSentContainer = styled.div`
  margin-top: 20px;
`;

export const LetterSentTitle = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const LetterSentItem = styled.div<Props>`
  display: flex;
  width: ${(props) => props.width};
  justify-content: ${(props) => props.justifyContent};
`;

export const TitleItemList = styled.span`
  font-weight: bold;
`;
