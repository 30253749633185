import React, { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { listStatusTags } from 'src/api/status-tags';
import {
  addContactStatus,
  getCurrentStatus,
  deleteContactStatus,
} from 'src/api/users';
import {
  ModalBody,
  ModalFooter,
  Modal,
  Toastify,
  useQueryParam,
  NumberParam,
} from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  CurrentStatusContainer,
  StatusButton,
  Button,
  ModalTitle,
  SectionButton,
} from './styles';

type Props = {
  modalToggle: () => void;
  userId: number;
  refetchUserData?: () => void;
};

export default function ModalStatusPie({
  modalToggle,
  userId,
  refetchUserData,
}: Props) {
  const [tax_year] = useQueryParam('tax_year', NumberParam);
  const queryClient = useQueryClient();

  const [currentStatusSelected, setCurrentStatusSelected] = useState([]);
  const [statusAvailableSelected, setStatusAvailableSelected] = useState([]);

  const { data: tags = [] } = useQuery(['listStatusTags'], listStatusTags);

  const {
    data: currentStatus = [],
    isLoading: isLoadingCurrentStatus,
    refetch: refetchCurrentStatus,
  } = useQuery(['listCurrentStatus'], () => getCurrentStatus(userId, tax_year));

  const { mutate: mutationAddContactStatus } = useMutation(addContactStatus, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      queryClient.invalidateQueries(['listUsers']);
      if (refetchUserData) refetchUserData();
      refetchCurrentStatus();
      modalToggle();
    },
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  const { mutate: MutateDeleteContactStatus } = useMutation(
    deleteContactStatus,
    {
      onSuccess: () => {
        Toastify.toast.success(SUCCESS.GENERIC);
        queryClient.invalidateQueries(['listUsers']);
        if (refetchUserData) refetchUserData();
        refetchCurrentStatus();
        modalToggle();
      },
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onSelectStatus = (obj) => {
    setCurrentStatusSelected([obj]);
  };

  const onSelectedAvailableStatus = (id: number) => {
    if (statusAvailableSelected.includes(id)) {
      setStatusAvailableSelected(
        statusAvailableSelected.filter((item) => item !== id),
      );
    } else setStatusAvailableSelected([...statusAvailableSelected, id]);
  };

  const onDeleteButtonsSelected = () => {
    if (currentStatusSelected.length > 0) {
      MutateDeleteContactStatus(currentStatusSelected[0].id);
    }
  };

  const onAddButtonsSelected = () => {
    const status_label_ids = statusAvailableSelected.filter((s) => s);
    if (status_label_ids.length > 0) {
      mutationAddContactStatus({ user_id: userId, tax_year, status_label_ids });
    }
  };

  return (
    <Modal centered isOpen toggle={modalToggle}>
      <ModalBody>
        <ModalTitle>Current Status</ModalTitle>
        <br /> <br />
        <CurrentStatusContainer>
          {!isLoadingCurrentStatus &&
            currentStatus.data.map((item, index) => (
              <StatusButton
                onClick={() => onSelectStatus(item)}
                key={index}
                name={item.status_label}
                id={item.id}
                className={
                  currentStatusSelected.includes(item) ? 'selected' : ''
                }
              >
                {item.status_label}
              </StatusButton>
            ))}
        </CurrentStatusContainer>
        <SectionButton>
          <Button
            onClick={onDeleteButtonsSelected}
            color="#fff"
            background="#F31B1B"
          >
            Delete
          </Button>
        </SectionButton>
      </ModalBody>

      <ModalBody>
        <ModalTitle>Status Available</ModalTitle>
        <br /> <br />
        <CurrentStatusContainer>
          {tags.length > 0 && tags.map((item, index) => (
            <StatusButton
              onClick={() => onSelectedAvailableStatus(item.id)}
              key={index}
              name={item.status_label_name}
              id={item.id}
              className={
                statusAvailableSelected.includes(item.id) ? 'selected' : ''
              }
            >
              {item.status_label_name}
            </StatusButton>
          ))}
        </CurrentStatusContainer>
        <SectionButton>
          <Button
            onClick={onAddButtonsSelected}
            color="#191424"
            background="#57E4CD"
          >
            Add
          </Button>
        </SectionButton>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={modalToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}