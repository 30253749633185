import React, { useEffect, useState } from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useMutation } from 'react-query';
import { Row } from 'reactstrap';

import { TaxIncomeApi } from 'src/api';
import { TextArea } from 'src/components/common';
import { Toastify } from 'src/modules';
import { formatCurrency, SUCCESS } from 'src/utils';

import {
  PrevNextSection,
  Button,
  PrevNextFields,
  PrevNextInputSection,
  Input,
  Select,
  SubmitButton,
} from '../../../styles';

import {
  FooterTitle,
  MileageItem,
  MileageSection,
  NotesContainer,
  NotesSplitSection,
  SliptRange,
  SplitContainer,
  SplitSection,
  SubmitSection,
} from './styles';

function TransactionsForm(props: any) {
  const [transactionsListState, setTransactionsListState] = useState([]);
  const [selectedItem, setSelectedItem] = useState<undefined | {}>(undefined);
  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
  const [sourceId, setSourceId] = useState(0);

  useEffect(() => {
    if (
      props.selectedItem?.work_percentage &&
      props.selectedItem?.work_percentage < 1
    )
      props.selectedItem.work_percentage = 100;
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  useEffect(() => {
    setTransactionsListState(props.transactionsListState);
  }, [props.transactionsListState]);

  useEffect(() => {
    setSelectedItem(transactionsListState[currentTransactionIndex]);
  }, [currentTransactionIndex]);

  const { mutate } = useMutation(
    async () => {
      const selectedIncome = props.allHeaderList.filter(
        (income) => income.id === props.incomeIdSelected,
      )[0];

      const incomeType = selectedIncome?.['self_employment_type']
        ? 'self_employed'
        : 'employment';

      const categoryId =
        incomeType === 'employment'
          ? selectedItem?.category_id.id
          : selectedItem?.category_id;

      return await TaxIncomeApi.editTransaction(
        props.userId,
        selectedItem?.id,
        props.year,
        sourceId,
        incomeType,
        categoryId,
        selectedItem?.note || '',
        selectedItem?.work_percentage || 0,
      );
    },
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);
        props.refetch();
      },
      onError({ response }) {
        Toastify.toast.error(response?.data?.message);
      },
    },
  );

  const handleSelectedItemChange = (key: string, value: string) => {
    setSelectedItem((prevState) => ({ ...prevState, [key]: value }));
  };

  const handlePrevClick = () => {
    if (currentTransactionIndex > 0)
      setCurrentTransactionIndex(currentTransactionIndex - 1);
  };

  const handleNextClick = () => {
    if (currentTransactionIndex < transactionsListState.length - 1)
      setCurrentTransactionIndex(currentTransactionIndex + 1);
  };

  return (
    <>
      <PrevNextSection>
        <Button onClick={() => handlePrevClick()}>
          <BsArrowLeft size={18} />
          <span>Previous</span>
        </Button>

        <Row>
          <PrevNextFields>
            <PrevNextInputSection>
              <label htmlFor="payee">Merchant name</label>
              <Input
                id="payee"
                type="text"
                value={
                  selectedItem?.merchant_name ?? selectedItem?.description ?? ''
                }
                readOnly
                disabled
              />
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="date">Date</label>
              <Input
                id="date"
                type="text"
                value={selectedItem?.created_at ?? ''}
                onChange={() => {}}
                readOnly
                disabled
              />
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="gross">Amount</label>
              <Input
                id="gross"
                type="text"
                value={formatCurrency(selectedItem?.amount + selectedItem?.CIS)}
                readOnly
                disabled
              />
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="gross">NET</label>
              <Input
                id="net"
                type="text"
                value={formatCurrency(`${selectedItem?.amount}`)}
                readOnly
                disabled
              />
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="gross">TAX</label>
              <Input
                id="TAX"
                type="text"
                value={formatCurrency(`${selectedItem?.CIS}`)}
                readOnly
                disabled
              />
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="source">Source</label>
              <Select
                id="source"
                onChange={(event) => setSourceId(Number(event.target.value))}
              >
                {props?.allHeaderList.map((item) => {
                  return (
                    <>
                      <option
                        key={item.id}
                        value={item.id}
                        selected={item?.id === selectedItem?.income_id}
                        disabled={item?.id === selectedItem?.income_id}
                      >
                        {item.employer_name}
                      </option>
                    </>
                  );
                })}
              </Select>
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="year">Year</label>
              <Select id="year">
                <option value="2020">2020</option>
              </Select>
            </PrevNextInputSection>
          </PrevNextFields>

          {selectedItem?.mileage && (
            <MileageSection>
              <MileageItem>
                <label htmlFor="address-from">Address From</label>
                <Input
                  value={
                    selectedItem.mileage.from_address +
                    ' - ' +
                    selectedItem.mileage.from_postcode
                  }
                  readOnly
                  disabled
                />
              </MileageItem>

              <MileageItem>
                <label htmlFor="address-to">Address To</label>
                <Input
                  value={
                    selectedItem.mileage.to_address +
                    ' - ' +
                    selectedItem.mileage.to_postcode
                  }
                  readOnly
                  disabled
                />
              </MileageItem>
            </MileageSection>
          )}
        </Row>

        <Button onClick={() => handleNextClick()} style={{ marginLeft: 20 }}>
          <BsArrowRight size={18} />
          <span>Next</span>
        </Button>
      </PrevNextSection>

      <NotesSplitSection>
        <NotesContainer className="w-50">
          <label htmlFor="notes">Notes</label>
          <TextArea
            id="notes"
            value={selectedItem?.note || ''}
            onChange={(e) => handleSelectedItemChange('note', e.target.value)}
            rows={10}
          />
        </NotesContainer>

        <PrevNextInputSection className="w-50">
          <SplitSection>
            <Row>
              <FooterTitle>Split</FooterTitle>
            </Row>
            <Row className="justify-content-center">
              <FooterTitle>{selectedItem?.work_percentage || 0} %</FooterTitle>
            </Row>

            <SplitContainer>
              <SliptRange
                type="range"
                id="work-percentage"
                className="split-range-label"
                min={0}
                max={100}
                step={1}
                value={parseInt(selectedItem?.work_percentage || 0)}
                onChange={(e) =>
                  handleSelectedItemChange('work_percentage', e.target.value)
                }
              />
            </SplitContainer>

            <Row className="justify-content-between">
              <FooterTitle>
                Personal{' '}
                {formatCurrency(
                  `${
                    (1 - parseInt(selectedItem?.work_percentage || 0) / 100) *
                    selectedItem?.amount
                  }`,
                )}{' '}
              </FooterTitle>
              <FooterTitle>
                {formatCurrency(
                  `${
                    (parseInt(selectedItem?.work_percentage || 0) / 100) *
                    selectedItem?.amount
                  }`,
                )}{' '}
                Work
              </FooterTitle>
            </Row>
          </SplitSection>
        </PrevNextInputSection>
      </NotesSplitSection>
      <SubmitSection>
        <SubmitButton disabled={false} onClick={() => mutate()}>
          Submit
        </SubmitButton>
      </SubmitSection>
    </>
  );
}

export default TransactionsForm;
