import styled from 'styled-components';

type Props = {
  width?: string;
  openModal?: boolean;
};

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  background-color: #eee;
  padding: 8px;
  border-radius: 5px;
`;

export const HeaderItem = styled.div<Props>`
  width: ${(props) => props.width};
`;

export const Body = styled.div`
  padding: 8px;

  .body-content {
    display: flex;
    padding: 8px 0;

    .item-description {
      padding: 0 5px;
      max-width: 1024px;
      overflow: auto;
    }

    .item-message {
      padding: 20px;
    }
  }
`;

export const BodyItem = styled.div<Props>`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  padding: 6px;

  .body-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .buttons-body-item {
    display: flex;
    flex-direction: column;
  }
`;

export const Modal = styled.div<Props>`
  display: ${(props) => (props.openModal ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .close-modal-button {
    border: none;
    background-color: transparent;
  }
`;

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
`;
