import React, { useEffect, useState } from 'react';

import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Table } from 'src/modules';
import { formatCurrency } from 'src/utils';
import { sortList } from 'src/utils/helpers/sortList';

import TaxIncomeDocument from '../TaxIncomeDocument';
import { Loading } from '../ui';

import {
  SectionTotal,
  LoadingContainer,
  Headercontainer,
  SectionLabelButtons,
  ToggleButton,
} from './styles';

//@TODO: (Glauber) Refactor with correct types
const TaxincomeList: React.FC = ({
  amountToText,
  data,
  selectItem,
  moveToYear,
  grossTotal,
  taxTotal,
  selectedID,
  loading,
  setReloadCustomerIncomes,
}) => {
  const [activeRefunded, setActiveRefunded] = useState(true);
  const [activeOtherFiles, setActiveOtherFiles] = useState(true);
  const [activePiechartPaye, setActivePiechartPaye] = useState(true);
  const [activePiechartSelf, setActivePiechartSelf] = useState(true);

  const [descOrder, setDescOrder] = useState(false);

  const onSortData = (descOrder: boolean, list: any, fieldName: string) => {
    sortList(descOrder, list, fieldName);
    setDescOrder(descOrder);
  };

  return (
    <>
      <Headercontainer className="text-lg">
        <p>Self Employment</p>
        <SectionTotal>
          <p>
            <span>Gross Total:</span>
            <strong className="ml-1 d-block">
              {formatCurrency(grossTotal)}
            </strong>
          </p>
          <p className="ml-4">
            <span>Tax Total:</span>
            <strong className="ml-1 d-block">{formatCurrency(taxTotal)}</strong>
          </p>
        </SectionTotal>
        <SectionLabelButtons>
          <ToggleButton
            onClick={() => setActiveRefunded(!activeRefunded)}
            backgroundColor={activeRefunded ? '#bda400' : '#ced4da'}
          >
            Refunded
          </ToggleButton>
          <ToggleButton
            onClick={() => setActiveOtherFiles(!activeOtherFiles)}
            backgroundColor={activeOtherFiles ? '#ee1c1c' : '#ced4da'}
          >
            Other
          </ToggleButton>
          <ToggleButton
            onClick={() => setActivePiechartPaye(!activePiechartPaye)}
            backgroundColor={activePiechartPaye ? '#1abc1a' : '#ced4da'}
          >
            Paye
          </ToggleButton>
          <ToggleButton
            onClick={() => setActivePiechartSelf(!activePiechartSelf)}
            backgroundColor={activePiechartSelf ? '#e83ab4' : '#ced4da'}
          >
            Self
          </ToggleButton>
        </SectionLabelButtons>
      </Headercontainer>

      <Table hover={data.length > 0 ? true : false}>
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSortData(!descOrder, data, 'title')}>
              Employer{' '}
              <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
            </th>
            <th>PAYE Ref</th>
            <th onClick={() => onSortData(!descOrder, data, 'gross_amount')}>
              Amount{' '}
              <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
            </th>
            <th onClick={() => onSortData(!descOrder, data, 'income_date')}>
              Date <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
            </th>
            <th
              onClick={() => onSortData(!descOrder, data, 'is_add_in_income')}
            >
              Add To Income{' '}
              <FontAwesomeIcon icon={faSort} style={{ marginLeft: 15 }} />
            </th>
            <th className="text-center" style={{ width: 100 }}>
              Move
            </th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {loading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}

          {data &&
            data.map((item, index: number) => (
              <TaxIncomeDocument
                key={`tax-income-${item.id}`}
                item={item}
                index={index}
                active={item.id === selectedID}
                selectItem={selectItem}
                amountToText={amountToText}
                moveToYear={moveToYear}
                setReloadCustomerIncomes={setReloadCustomerIncomes}
                activeRefunded={activeRefunded}
                activeOtherFiles={activeOtherFiles}
                activePiechartPaye={activePiechartPaye}
                activePiechartSelf={activePiechartSelf}
              />
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default TaxincomeList;
