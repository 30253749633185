import React, { useEffect, useState } from 'react';

import Draggable from 'react-draggable';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { GrRefresh } from 'react-icons/gr';

import {
  Container,
  IframeWrapper,
  Image,
  ImageButton,
  ImagesListItem,
  PDFContainer,
  PDFItemContainer,
  SectionImage,
  SectionImageButtons,
  SectionImagesList,
} from './styles';

const zoomStep = 1;
const maxScale = 5;
const minScale = 1;
const defaultScale = minScale;
const defaultRotate = 0;

function ViewDocs({ docs }) {
  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const [imgSelected, setImgSelected] = useState(docs && docs[0]);
  const [imageError, setImageError] = useState(false);
  const [pdfURL, setPdfURL] = useState('');

  useEffect(() => {
    pdfURL.length < 1 && setPdfURL(docs[0]);
  }, []);

  const onZoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const onZoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale >= minScale ? newScale : minScale);
  };

  const onRotate = () => {
    setRotate(rotate === 270 ? 0 : rotate + 90);
  };

  const onSelectImage = (url: string) => {
    setImgSelected(url);
  };

  const onImageError = () => {
    setImageError(true);
  };

  const selectPDF = (url) => {
    setPdfURL(url);
  };

  const isDraggable = scale > 1;

  return (
    <Container>
      {docs && docs.length < 1 && <span>No docs files</span>}

      {docs && docs.length > 0 && (
        <>
          <SectionImage>
            <Draggable disabled={!isDraggable} key={version}>
              <div style={{ cursor: isDraggable ? 'move' : null }}>
                {!imageError && (
                  <Image
                    draggable="false"
                    src={imgSelected}
                    scale={scale}
                    rotate={rotate}
                  />
                )}
              </div>
            </Draggable>
          </SectionImage>

          {imageError && (
            <PDFContainer>
              <iframe src={pdfURL} height={800} width="100%" />
            </PDFContainer>
          )}

          <div style={{ marginLeft: 14 }}>
            <SectionImagesList>
              {docs.map((file, i) => {
                return (
                  <ImagesListItem key={i} onClick={() => onSelectImage(file)}>
                    {!imageError && (
                      <img
                        src={file}
                        alt="images list"
                        key={i}
                        onError={onImageError}
                      />
                    )}

                    {imageError && (
                      <ImagesListItem key={i}>
                        <PDFItemContainer>
                          <iframe src={file} width="110px" height={72} />
                          <IframeWrapper onClick={() => selectPDF(file)} />
                        </PDFItemContainer>
                      </ImagesListItem>
                    )}
                  </ImagesListItem>
                );
              })}
            </SectionImagesList>

            {!imageError && (
              <SectionImageButtons>
                <ImageButton onClick={() => onZoomIn()}>
                  <AiOutlinePlus size={20} />
                </ImageButton>
                <ImageButton onClick={() => onZoomOut()}>
                  <AiOutlineMinus size={20} />
                </ImageButton>
                <ImageButton
                  onClick={() => onRotate()}
                  style={{ marginTop: 30 }}
                >
                  <GrRefresh size={20} />
                </ImageButton>
              </SectionImageButtons>
            )}
          </div>
        </>
      )}
    </Container>
  );
}

export default ViewDocs;
