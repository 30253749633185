import React, { useEffect, useState } from 'react';

import { NumberParam, useQueryParam } from 'use-query-params';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import {
  Layout,
  TableHead,
  AddJobCategory,
  CustomPagination,
  JobCategoriesRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import { useFilters } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, Row, Button, Alert } from 'src/modules';

// markup
const JobCategories: React.FC = () => {
  const [jobcategories, setJobcategories] = useState([]);

  const [page, setPage] = useQueryParam('page', NumberParam);
  const [, setCurrentPage] = useState<number>(page || 1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [showAddcategory, setShowAddCategory] = useState(false);

  const { query } = useFilters();

  const getDataFetch = async (url = `${API.JOB_CATEGORIES}/${query}`) => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.get(url);

      if (status === 200) {
        setJobcategories(res.data);
        setPagRes(res);
        setHasPagination(res.has_more);
        setTotalPages(res.total_pages);
        setPagRes({
          next_page: res.next_page,
          total_pages: res.total_pages,
          next_page_url: '',
          previous_page_url: '',
        });
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataFetch();
  }, [page]);

  const changePage = (pageNumber: number) => {
    setPage(pageNumber);
    setCurrentPage(pageNumber);
    setPagRes({
      next_page: pageNumber + 1,
      total_pages: totalPages,
      next_page_url: '',
      previous_page_url: '',
    });
  };

  const deleteJob = async (id: number): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      await request.delete(`${API.JOB_CATEGORIES_DELETE}?id=${id}`);

      const jobCategoriesUpdate = jobcategories.filter((el) => el.id !== id);
      setJobcategories(jobCategoriesUpdate);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateName = async (name: string, id: number): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      await request.post(`${API.JOB_CATEGORIES_UPDATE}?id=${id}`, { name });

      const jobCategoriesUpdate = jobcategories.map((item) => {
        if (id === item.id) item.name = name;
        return item;
      });

      setJobcategories(jobCategoriesUpdate);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const statusChangeFetch = async (url: string) => {
    setLoading(true);
    setError(null);

    try {
      await request.put(url);

      const jobCategoriesUpdate = jobcategories.map((item) => {
        if (id === item.id) item.name = name;
        return item;
      });

      setJobcategories(jobCategoriesUpdate);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const changeStatus = async (item) => {
    await statusChangeFetch(`${API.JOB_CATEGORIES_STATUS_CHANGE}/${item.id}`);
    const jobCategoriesUpdate = [];
    jobcategories.map((job) => {
      if (job.id === item.id) {
        job.status = job.status === 'Enable' ? 'Disable' : 'Enable';
      }
      jobCategoriesUpdate.push(job);
    });
    setJobcategories(jobCategoriesUpdate);
  };

  const createFetch = async (url, body) => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.post(url, body);

      if (status === 200) {
        const jobCategoriesUpdate = [res];
        jobcategories.map((item) => {
          jobCategoriesUpdate.push(item);
        });
        setJobcategories(jobCategoriesUpdate);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  function create(e) {
    e.preventDefault();
    const formChildrens = [...e.target];
    const newJob = {
      name: '',
    };
    formChildrens.map((item) => {
      if (item.getAttribute('type') === 'text') {
        if (item.getAttribute('name') === 'name') {
          newJob.name = item.value;
        }
      }
    });
    createFetch(`${API.JOB_CATEGORIES_CREATE}`, newJob);
  }

  return (
    <Layout>
      <Dashboard title="Job Categories">
        <Row className="justify-content-end mb-4">
          <Col>
            <Button
              color="secondary"
              onClick={() => {
                setShowAddCategory(true);
              }}
              type="button"
              outline
            >
              Add New Job Category
            </Button>
          </Col>
          <Col xs="4">{/* <SearchBar /> */}</Col>
        </Row>

        {showAddcategory && (
          <AddJobCategory
            onClose={() => {
              setShowAddCategory(false);
            }}
            createJob={(e) => create(e)}
          />
        )}

        {error && <Alert color="danger">Error: {error.message}</Alert>}

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Name</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {loading && <Loading />}

            {/* <JobCategoriesRow nameJobCategory="Accounting" onStatusChange={null} active={null}/> */}

            {jobcategories.length > 0 &&
              jobcategories.map((item, index) => (
                <JobCategoriesRow
                  key={`${item.name}_${index}`}
                  value={index}
                  active={item.status === 'Enable'}
                  onStatusChange={() => changeStatus(item)}
                  nameJobCategory={item.name}
                  updateName={(value) => updateName(value, item.id)}
                  deleteJob={() => deleteJob(item.id)}
                />
              ))}

            {hasPagination && (
              <CustomPagination res={pagRes} changePage={changePage} />
            )}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default JobCategories;
