import React, { useEffect, useRef, useState } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { getTaxIncomeListingAll } from 'src/api/users';
import AllTransactions from 'src/components/CustomerInformationTabs/IncomesTab/Components/TabAll/Components/AllTransactions';
import Filters from 'src/components/CustomerInformationTabs/IncomesTab/Components/TabAll/Components/Filters';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeHeaderButtons,
  IncomeInputsContainer,
  HeaderSelectInput,
  TransactionsSection,
  ViewImageSection,
  OpenContentButton,
  OpenContentContainer,
  ContentContainer,
  OtherInformationsInputSection,
} from '../../styles';
import TransactionsList from '../Transactions/TransactionsList';
import ViewImage from '../ViewImage';

import {
  default as AllEditTransactionForm,
  AllEditTransactionFormHandle,
} from './Components/AllEditTransactionForm';
import { EmployerNameSection, Logo } from './styles';

type HeaderPayeType = {
  id: number;
  employer_name: string;
  logo: string;
  ref: string;
  gross_amount: string;
  tax_amount: string;
  is_company_director: number;
  has_benefits: number;
  benefits_amount: string;
  created_at: string;
  open_form_transaction: boolean;
};

function TabAll() {
  const editTransactionRef = useRef<AllEditTransactionFormHandle>(null);

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const expenses = store.expenses;

  const [allHeaderList, setAllHeaderList] = useState<HeaderPayeType[]>([]);
  const [incomeIdSelected, setIncomeIdSelected] = useState(0);
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);

  const [searchInputsValue, setSearchInputsValue] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [applyFilters, setApplyFilters] = useState(false);
  const [searchTransactions, setSearchTransactions] = useState(false);
  const [searchByDebit, setSearchByDebit] = useState(false);
  const [searchByCredit, setSearchByCredit] = useState(false);
  const [searchByReconciled, setSearchByReconciled] = useState('');
  const [filterSelected, setFilterSelected] = useState(false);
  const [transactionListState, setTransactionListState] = useState([]);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListingAll(userId, year),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      list.forEach((item, index) => {
        list[index].editMode = false;
      });

      setAllHeaderList(list);
    }
  }, [data]);

  /* const onUpdatePayeHeader = (editMode: boolean, index: number) => {
    const newPayeHeaderList = allHeaderList;

    if (editMode) {
      newPayeHeaderList[index].editMode = false;
      setAllHeaderList([...newPayeHeaderList]);
    } else {
      newPayeHeaderList[index].editMode = true;
      setAllHeaderList([...newPayeHeaderList]);
    }
  }; */

  const onOpenContent = (
    index: number,
    openContent: boolean,
    income_id: number,
  ) => {
    const newPayeHeaderList = allHeaderList;
    const newOpenContent = !openContent && false;

    newPayeHeaderList[index].openContent = !newOpenContent;
    setAllHeaderList([...newPayeHeaderList]);
    setIncomeIdSelected(income_id);
  };

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const newPayeHeaderList = [...allHeaderList];

    newPayeHeaderList[index][target.name] = target.value;
    setAllHeaderList(newPayeHeaderList);
  };

  const onIncomeType = (income_type_id: number) => {
    switch (income_type_id) {
      case 1:
        return 'employment';
      case 3:
        return 'self_employed';
      default:
        break;
    }
  };

  const onSearchByDebit = () => {
    setSearchByDebit(true);
    setSearchByCredit(false);
  };

  const onSearchByCredit = () => {
    setSearchByCredit(true);
    setSearchByDebit(false);
  };

  const onSearchByReconciled = (value: string) => {
    setSearchByReconciled(value);
  };

  return (
    <div>
      <IncomeContainer>
        <Filters
          setSearchTransactions={setSearchTransactions}
          setSearchInputsValue={setSearchInputsValue}
          setApplyFilters={setApplyFilters}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          onSearchByDebit={onSearchByDebit}
          setSearchByDebit={setSearchByDebit}
          onSearchByCredit={onSearchByCredit}
          setSearchByCredit={setSearchByCredit}
          onSearchByReconciled={onSearchByReconciled}
          setSearchByReconciled={setSearchByReconciled}
          setFilterSelected={setFilterSelected}
        />

        <AllTransactions
          openAddTransactionForm={openAddTransactionForm}
          setOpenAddTransactionForm={setOpenAddTransactionForm}
          keyword={searchTransactions && searchInputsValue.toString()}
          date_range_start={applyFilters && initialDate.toString()}
          date_range_end={applyFilters && finalDate.toString()}
          type={'debit'}
          reconciliation_type="expense"
          reconciliation_status={
            searchByReconciled !== '' && searchByReconciled
          }
          filterSelected={filterSelected}
          onClick={(id) =>
            editTransactionRef?.current?.setSelectedTransaction(id)
          }
          setTransactionListState={setTransactionListState}
        />

        <ViewImageSection>
          {!expenses.isLoadingAllTransaction && (
            <ViewImage
              transactions={
                expenses.allTransactions[expenses.indexTransactionSelected]
              }
            />
          )}
        </ViewImageSection>

        <AllEditTransactionForm
          item={transactionListState}
          sourceList={data}
          ref={editTransactionRef}
        />
      </IncomeContainer>

      {!isLoading &&
        allHeaderList.map((item: any, index: number) => {
          return (
            <IncomeContainer key={index}>
              <IncomeHeader>
                <div>
                  <Logo
                    src={handleLogo(
                      onIncomeType(item.income_type_id),
                      item.self_employment_type,
                    )}
                    alt="Logo Image"
                  />
                </div>
                <IncomeInputsContainer>
                  <IncomeHeaderFields>
                    <Row>
                      <Col>
                        <EmployerNameSection>
                          <EditInputPie
                            id="employer_name"
                            label="Employer name:"
                            name="employer_name"
                            type="text"
                            value={item.employer_name || ''}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </EmployerNameSection>

                        <EditInputPie
                          id="ref"
                          label="Employer Paye"
                          name="ref"
                          type="text"
                          value={item.ref || ''}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Start date:"
                          id="created_at"
                          name="created_at"
                          type="text"
                          value={item.created_at || ''}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />

                        <EditInputPie
                          label="Gross total:"
                          id="gross_amount"
                          name="gross_amount"
                          type="text"
                          value={item.gross_amount || ''}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Tax total:"
                          id="tax_amount"
                          name="tax_amount"
                          type="text"
                          value={item.tax_amount || ''}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>
                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="director-position">
                            Director position:
                          </label>
                          <HeaderSelectInput
                            id="is_company_director"
                            name="is_company_director"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value={1}
                              selected={item.is_company_director}
                            >
                              Yes
                            </option>

                            <option
                              value={0}
                              selected={item.is_company_director}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>
                    </Row>
                  </IncomeHeaderFields>
                  <IncomeHeaderButtons></IncomeHeaderButtons>
                </IncomeInputsContainer>
              </IncomeHeader>

              <ContentContainer openContent={item.openContent}>
                {item.openContent && item.id === incomeIdSelected && (
                  <TransactionsSection>
                    <h3>Transactions</h3>
                    <TransactionsList
                      incomeType={onIncomeType(item.income_type_id)}
                      incomeId={item.id}
                      setOpenAddTransactionForm={setOpenAddTransactionForm}
                      openAddTransactionForm={openAddTransactionForm}
                      allHeaderList={allHeaderList}
                      incomeIdSelected={incomeIdSelected}
                      userId={userId}
                      year={year}
                      isTabAll
                    />
                  </TransactionsSection>
                )}

                <ViewImageSection>
                  <ViewImage
                    transactions={
                      expenses.expensesTransactions[
                        expenses.indexTransactionSelected
                      ]
                    }
                  />
                </ViewImageSection>
              </ContentContainer>

              <OpenContentContainer>
                <OpenContentButton
                  onClick={() =>
                    onOpenContent(index, item.openContent, item.id)
                  }
                >
                  {item.openContent ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}
                </OpenContentButton>
              </OpenContentContainer>
            </IncomeContainer>
          );
        })}
    </div>
  );
}

export default observer(TabAll);
