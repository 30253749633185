import React, { memo, useEffect, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import { TUtrStatus, UTRSTatusLabels } from 'src/models';

import ButtonIcon, { TButtonIconColors } from '../ButtonIcon';

type Props = {
  id: number;
  requested_utr: TUtrStatus;
  year: number;
  iconDisabled?: boolean;
};

const IconUTR: React.FC<Props> = ({
  id,
  requested_utr,
  iconDisabled = false,
}) => {
  const [local, setLocal] = useState<TUtrStatus>(requested_utr);

  const [color, setColor] = useState<TButtonIconColors>('secondary');
  const [outline, setOutline] = useState(true);
  const [crossed, setCrossed] = useState(false);
  const [check, setCheck] = useState(true);

  const onToggle = async () => {
    try {
      const { data: res, status } = await request.post(
        API.USERS_SET_UTR_STATUS,
        {
          user_id: id,
        },
      );

      if (status === 200) {
        setLocal(res.requested_utr || res.utr_status);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
    }
  };

  useEffect(() => {
    setLocal(requested_utr);
  }, [requested_utr]);

  useEffect(() => {
    let utrColor: TButtonIconColors = 'secondary';
    let utrOutline = true;
    let utrCrossed = false;
    let utrCheck = false;

    switch (local) {
      case 'Provided':
        utrColor = 'primary';
        utrOutline = false;
        break;

      case 'NeedUTR':
        //   case 'NotRequested':
        utrColor = 'secondary';
        utrOutline = false;
        break;

      case 'RequestCanceled':
        utrColor = 'secondary';
        utrOutline = true;
        utrCrossed = true;
        break;

      case 'Requested':
        utrColor = 'secondary';
        utrOutline = true;
        utrCheck = true;
        break;

      // default: //NotRequested
    }

    setColor(utrColor);
    setOutline(utrOutline);
    setCrossed(utrCrossed);
    setCheck(utrCheck);
  }, [local]);

  return (
    <ButtonIcon
      tooltip={UTRSTatusLabels[local]}
      id={`utr_status_${id}`}
      color={color}
      outline={outline}
      crossed={crossed}
      checkmark={check}
      onClick={onToggle}
      disabled={iconDisabled}
    >
      <i className="icon-customers-row icon-process" />
    </ButtonIcon>
  );
};

export default memo(IconUTR);
