import styled from 'styled-components';

interface Props {
  employeeType?: string;
  backgroundColor?: string;
  visible?: boolean;
  isDIY?: boolean;
}

type SubscriptionStatusProps = {
  background?: string;
  color?: string;
  selected?: boolean;
};

export const SubscriptionStatus = styled.span<SubscriptionStatusProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  border-radius: 10%;
  background-color: ${(props) => props.background};
  margin-left: 5px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 4px;
`;

export const EmployeeTypeLabel = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  top: 6px;
  //left: 10px;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white.default};
  background-color: ${(props) =>
    props.employeeType === 'Employed'
      ? '#209782'
      : props.theme.colors.pink.main};
`;

export const CurrentYearStatusLabel = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.red.main};
  border-radius: 5px;
  background: transparent;
  padding: 1px 10px;
  position: relative;
  //left: 20px;
  color: ${({ theme }) => theme.colors.red.main};
  font-size: 14px;
`;

export const YearsLabel = styled.div<Props>`
  cursor: default;
  position: relative;
  padding: 1px 10px;
  //margin-left: 3px;
  border-radius: 5px;
  background: ${(props) => props.backgroundColor};
  color: ${({ theme }) => theme.colors.white.default};
  max-width: 50px;
`;

export const YearsToolTip = styled.div<Props>`
  position: absolute;
  bottom: 24px;
  left: 0;
  padding: 1px 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray.regent};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
`;

export const SanctionedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red.main};
  height: 40px;
  width: 200px;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  left: 16px;
`;

export const Sanctioned = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: x-large;
  font-weight: bold;
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 6px;

  .fullName {
    display: flex;
    font-size: 16px;
  }
`;

export const CustomerProgressPieContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  gap: 6px;
`;

export const StatusYearsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  max-width: 16%;
  height: 45%;
`;

export const DeleteAndCloseYearContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

export const JobCategorySection = styled.div`
  position: absolute;
  top: -40px;
  background-color: #333;
  color: #fff;
  border-radius: 6px;
  padding: 2px 6px;
  cursor: pointer;
`;

export const JobCategorySpan = styled.span`
  background-color: #333;
  color: #fff;
  border-radius: 6px;
  padding: 2px 6px;
  margin-right: 10px;
  margin-top: 10px;
`;

export const ServiceType = styled.div<Props>`
  display: flex;
  justify-content: center;
  padding: 0 4px;
  background-color: ${(props) => (props.isDIY ? '#b3b300' : '#ffa500')};
  color: #fff;
  border-radius: 4px;
  margin-top: 4px;
`;

export const SubAndServiceTypeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusInfoContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  width: 84%;
`;

export const StatusInfoPie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #191424;
  border-radius: 46px;
  padding: 6px 14px;
  cursor: pointer;
`;

export const ActionsStatusButton = styled.div`
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  background-color: #57e4cd;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  .text {
    position: relative;
    bottom: 6px;
  }
`;

export const CurrentStatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .selected {
    background-color: #191424;
    color: #fff;
    border: none;
  }
`;
