import React, { useState, useMemo, useEffect, useRef } from 'react';

import { useQuery, useMutation } from 'react-query';

import { editAdmin, getAdmin } from 'src/api/admin';
import { Layout, TableRow, ButtonLoader } from 'src/components';
import { Loading } from 'src/components/ui';
import { useForm } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Toastify,
  observer,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';

// markup
const AdminUserEdit: React.FC = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    userData: { email },
  } = store.auth;

  const { data, isLoading, refetch } = useQuery(
    'admin',
    () => getAdmin(email),
    {
      placeholderData: {
        name: undefined,
        email: undefined,
        phone_number: undefined,
        whatsapp_no: undefined,
        address: undefined,
        note: undefined,
        password: undefined,
      },
      enabled: false,
      onSuccess: (data) => {
        setForm({
          name: data?.name,
          email: data?.email,
          address: data?.address,
          note: data?.note,
          phone_number: data?.phone_number,
          whatsapp_no: data?.whatsapp_no,
        });
      },
    },
  );

  useEffect(() => {
    if (email) refetch();
  }, [email]);

  useEffect(() => {
    const getUser = async () => {
      const user = await Storage.getUser();
      store.auth.setUser(user);
    };

    getUser();
  }, []);

  const { mutate, isLoading: isLoadingMutation } = useMutation(editAdmin, {
    onSuccess: (data) => {
      Toastify.toast.success('Successfully updated!');

      const updatedUser = { ...store.auth.userData };

      if (data?.name) updatedUser.name = data.name;
      if (data?.email) updatedUser.email = data.email;
      if (data?.image) updatedUser.image = data.image;

      store.auth.setUser(updatedUser);
    },
  });

  const { form, handleChange, hasChanged, setForm } = useForm<AdminData>(
    {} as AdminData,
  );

  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');

  const isSamePassword = useMemo(() => {
    if (password) {
      return confirmPassword !== '' && confirmPassword === password;
    }
  }, [password, confirmPassword]);

  const submit = () => {
    if (data?.id) {
      const variables = { ...form, id: data.id };

      if (password) variables.password = password;

      mutate(variables);
    }
  };

  return (
    <Layout>
      <Dashboard title="admins-edit">
        {isLoading ? (
          <Loading />
        ) : (
          <Card>
            <Container className="mw-100 p-4">
              <form autoComplete="off">
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Name
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      value={form.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                      bsSize="lg"
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Email
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      value={form.email}
                      onChange={(e) => handleChange('email', e.target.value)}
                      bsSize="lg"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Display Photo
                  </Col>
                  <Col>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-outline-primary mr-1"
                      onClick={() => inputRef.current?.click()}
                    >
                      BROWSE PICTURE
                    </Button>
                    <input
                      type="file"
                      className="d-none"
                      ref={inputRef}
                      onChange={(e) => handleChange('image', e.target.files[0])}
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Phone Number
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      value={form.phone_number}
                      onChange={(e) =>
                        handleChange('phone_number', e.target.value)
                      }
                      bsSize="lg"
                      type="number"
                      name="phone"
                      placeholder="Enter your phone number"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Whatsapp Number
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      value={form.whatsapp_no}
                      onChange={(e) =>
                        handleChange('whatsapp_no', e.target.value)
                      }
                      bsSize="lg"
                      type="number"
                      name="whatsapp"
                      placeholder="Enter your whatsapp number"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Address
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      rows="2"
                      value={form.address}
                      onChange={(e) => handleChange('address', e.target.value)}
                      type="textarea"
                      name="textarea"
                      placeholder="Enter your address"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Note
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      rows="2"
                      bsSize="lg"
                      type="textarea"
                      value={form.note}
                      onChange={(e) => handleChange('note', e.target.value)}
                      placeholder="Type a note"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Password
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      bsSize="lg"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="pwd"
                      autoComplete="new-password"
                      placeholder="Enter your password"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3" className="ml-3">
                    Confirm Password
                  </Col>
                  <Col>
                    <Input
                      className="w-50"
                      bsSize="lg"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      name="pwd"
                      autoComplete="new-password"
                      placeholder="Confirm your password"
                    />
                  </Col>
                </TableRow>
                <TableRow>
                  <Col xs="3"></Col>
                  <Col xs="3" className="d-flex ml-3">
                    <Button
                      type="button"
                      color="transparent"
                      className="btn-outline-primary mr-3"
                      disabled={password ? !isSamePassword : !hasChanged}
                      onClick={submit}
                    >
                      {isLoadingMutation ? <ButtonLoader /> : 'SUBMIT'}
                    </Button>
                    <Button
                      type="button"
                      color="transparent"
                      className="btn-outline-secondary"
                    >
                      BACK
                    </Button>
                  </Col>
                </TableRow>
              </form>
            </Container>
          </Card>
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(AdminUserEdit);
