import { AxiosError } from 'axios';

import { Storage } from '../services';

import { refreshToken } from './auth.api';

const defaultErrorMessage = 'Somenthing went wrong.';

const expiredValues = [
  'token_expired',
  'token_blacklisted',
  'token_not_found',
  'invalid_token',
];

export default class ResponseError {
  message = '';
  error: unknown;
  code = 0;

  constructor({ response }: AxiosError) {
    this.init(response);

    const errors = response?.data.errors;

    if (errors) {
      // If exists errors get the first error from array to show.
      const value = errors[Object.keys(errors)[0]];
      this.error = value[0];
    } else {
      this.error = defaultErrorMessage;
    }

    this.message = response?.data.message || defaultErrorMessage;
    this.code = response?.status || 0;
  }

  private async init(response: AxiosError['response']) {
    const expiredToken = !!expiredValues.find(
      (value) => value === response?.data,
    );

    const previousLogged = await Storage.getToken();

    if (expiredToken && !!previousLogged) {
      const { token } = await refreshToken();
      await Storage.setToken(token);
    } else if (expiredToken && !previousLogged) {
      window.location.href = '/login';
    }
  }
}
