import request from '../request';
import ResponseError from '../ResponseError';

export const fetchCustomerIncomes = async (
  userId: number,
  taxYear: number,
): Promise<TaxIncomeListTypes> => {
  const { data } = await request.get(
    `/users/tax-income/listing/?user_id=${userId}&tax_year=${taxYear}`,
  );
  return data;
};

export const submitIncome = async (
  userId: number,
  taxYear: number,
): Promise<void> => {
  const data = {
    user_id: userId,
    tax_year: taxYear,
  };
  await request.post(`/users/tax-income/submit`, data);
};

export const exportIncomes = async (userId: number, taxYear: number) => {
  const { data } = await request.get(
    `/users/tax-income/export?user_id=${userId}&tax_year=${taxYear}`,
  );

  return data.data;
};

export const deleteIncome = async ({ id }: { id: number }): Promise<void> => {
  try {
    await request.delete(`/users/tax-income/remove?id=${id}`);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const addSelfEmployed = async (dataForm: any) => {
  try {
    await request.post(`/income/self-employments/create`, dataForm);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const updateSelfEmployed = async (dataForm: any) => {
  try {
    await request.put(`/income/self-employments/update`, dataForm);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const addPAYE = async (dataForm: any) => {
  try {
    await request.post(`/income/employments`, dataForm);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const updatePAYE = async ({ id, formData }) => {
  try {
    await request.post(`/income/employments/${id}`, formData);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getIncomeTransactions = async (
  income_type: string,
  income_id: number,
  keyword?: string,
  date_range_start?: string,
  date_range_end?: string,
  reconciliation_status?: string,
  nextPage?: number,
) => {
  const params = {};

  if (keyword) params.keyword = keyword;

  if (date_range_start && date_range_end) {
    params.date_range_start = date_range_start;
    params.date_range_end = date_range_end;
  }

  // if (type) params.type = type;

  if (reconciliation_status)
    params.reconciliation_status = reconciliation_status;

  const { data } = await request.get(
    `/users/tax-income/transactions?income_type=${income_type}&incomes[]=${income_id}
  &type=credit&page=${nextPage}`,
    {
      params,
    },
  );

  return data;
};

export const getExpenseTransactions = async (
  income_type: string,
  income_id: number,
  nextPage?: number,
) => {
  const { data } = await request.get(
    `/users/tax-income/transactions?income_type=${income_type}&incomes[]=${income_id}&type=debit&page=${nextPage}`,
  );

  return data;
};

export const getAllTransactions = async (
  user_id: number,
  tax_year: number,
  keyword?: string,
  date_range_start?: string,
  date_range_end?: string,
  reconciliation_type: string,
  reconciliation_status?: string,
  type?: string,
  nextPage: number,
) => {
  const params = {};

  if (keyword) params.keyword = keyword;

  if (date_range_start && date_range_end) {
    params.date_range_start = date_range_start;
    params.date_range_end = date_range_end;
  }

  // if (type) params.type = type;

  if (reconciliation_status)
    params.reconciliation_status = reconciliation_status;

  const { data } = await request.get(
    `/users/tax-income/transactions?user_id=${user_id}&tax_year=${tax_year}&
  type=${type}&page=${nextPage}`,
    {
      params,
    },
  );

  return data;
};

export const getAllIncomeTransactions = async (
  user_id: number,
  tax_year: number,
) => {
  const { data } = await request.get(
    `/users/tax-income/transactions?user_id=${user_id}&tax_year=${tax_year}&
  type=credit`,
  );

  return data;
};

export const addTransaction = async (formData) => {
  try {
    await request.post(`/users/tax-income/transactions`, formData);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const deleteTransaction = async (id: number) => {
  try {
    await request.delete(`/users/tax-income/transactions/${id}/delete`);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const updateTransaction = async ({ transaction_id, formData }) => {
  try {
    await request.post(
      `users/tax-income/transactions/${transaction_id}/edit`,
      formData,
    );
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getTotals = async (user_id: number, tax_year: number) => {
  const { data } = await request.get(
    `/users/tax-income/transactions/totals?user_id=${user_id}&tax_year=${tax_year}`,
  );

  return data;
};
