import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { Link } from 'gatsby';
import { getPurchaseHistory, updatePurchaseContacted } from 'src/api/users';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { observer, NumberParam, useQueryParam, Toastify, CustomInput } from 'src/modules';
import {
  WrapperContent,
  Content,
  StyledCol,
  StyledAlert,
} from 'src/styles/pages/notifications/styles';
import { ERRORS, formatCurrency, formatDateFullDate } from 'src/utils';

import PurchasesInputText from 'src/components/PurchasesInputText';
import FilterSelect from 'src/components/FilterSelect';
import { Container, SelectContainer, Label } from 'src/components/FilterSelect/styles';

const pageDefaultValues: PaginationResponse = {
  next_page: 0,
  total_pages: 0,
  next_page_url: '',
  previous_page_url: '',
};

const serviceOption = [
  {'label': 'All', 'value': ''},
  {'label': 'Subscriptions', 'value': 'all_subscription'},
  {'label': 'One-off', 'value': 'all_one_off'},
];

const productOptions = [
  {'label': 'All', 'value': ''},
  {'label': 'Subscription Essential Monthly', 'value': 'essential_monthly'},
  {'label': 'Subscription Essential Year', 'value': 'essential_year'},
  {'label': 'Subscription Premium Monthly', 'value': 'premium_monthly'},
  {'label': 'Subscription Premium Year', 'value': 'premium_year'},
  {'label': 'Final Check', 'value': 'final_check'},
  {'label': 'Do It For You', 'value': 'dify'}
];

const statusOptions = [
  {'label': 'All', 'value': ''},
  {'label': 'Purchased', 'value': 'purchased'},
  {'label': 'Pending', 'value': 'pending'},
  {'label': 'Canceled', 'value': 'canceled'},
  {'label': 'Renewed', 'value': 'renewed'},
  {'label': 'Expired', 'value': 'expired'},
  {'label': 'Hold', 'value': 'hold'},
  {'label': 'Grace period', 'value': 'grace_period'},
  {'label': 'Refund', 'value': 'refund'},
  {'label': 'Revoked', 'value': 'revoked'},
];

const contactedOptions = [
  {'label': 'All', 'value': ''},
  {'label': 'Yes', 'value': 'true'},
  {'label': 'No', 'value': 'false'},
];

const isActiveOptions = [
  {'label': 'All', 'value': ''},
  {'label': 'Yes', 'value': 'true'},
  {'label': 'No', 'value': 'false'},
];

type PurchaseData = {
  id: number;
  full_name: string;
  url: string;
  service_date: string;
  service_value: number;
  product_name: string;
  status: string;
  is_purchase_contacted: boolean;
  is_active: boolean;
}

const Purchases: React.FC = () => {
  const [, setPage] = useQueryParam('page', NumberParam);

  const [pagRes, setPagRes] = useState<PaginationResponse>(pageDefaultValues);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<any>(null);
  const [nextPage, setNextPage] = useState(1);
  const [searchName, setSearchName] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [status, setStatus] = useState('');
  const [isPurchaseContacted, setIsPurchaseContacted] = useState('');
  const [active, setActive] = useState('');
  const [purchaseData, setPurchaseData] = useState<PurchaseData[]>([]);
  const [service, setService] = useState('');

  let { isLoading } = useQuery(
    ['purchasesHistory', nextPage, searchName, filter, isPurchaseContacted, status, active, service],
    () => getPurchaseHistory(nextPage, searchName, filter, isPurchaseContacted, status, active, service),
    {
      onSuccess(response) {
        setTotalPages(response.total_pages);
        setPage(nextPage);
        setPurchaseData(response.data);

        setPagRes({
          next_page: nextPage + 1,
          total_pages: response.total_pages,
          next_page_url: response.next_page_url,
          previous_page_url: response.previous_page_url,
        });
      },
      onError(error) {
        setError(error);
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    }
  );

  const { mutate: mutationChangedChecked } = useMutation(updatePurchaseContacted, {
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  const onChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, itemId: number) => {
    const isChecked = e.target.checked;

    setPurchaseData(prevState =>
      prevState.map(item =>
        item.id === itemId ? { ...item, is_purchase_contacted: isChecked } : item
      )
    );

    mutationChangedChecked(itemId);
  };

  return (
    <Layout>
      <Dashboard title="Purchases and subscriptions">
        {error && (
          <StyledAlert color="danger">Error: {error?.message}</StyledAlert>
        )}

        <Container>
          <SelectContainer>
            <Label>Service</Label>
            <FilterSelect selecting={(e) => setService(e.target.value)} filter={filter} options={serviceOption}></FilterSelect>
          </SelectContainer>

          <SelectContainer>
            <Label>Product name</Label>
            <FilterSelect selecting={handleChange} filter={filter} options={productOptions}></FilterSelect>
          </SelectContainer>

          <SelectContainer>
            <Label>Status</Label>
            <FilterSelect selecting={(e) => setStatus(e.target.value)} filter={status} options={statusOptions}></FilterSelect>
          </SelectContainer>

          <SelectContainer>
            <Label>Checked</Label>
            <FilterSelect selecting={(e) => setIsPurchaseContacted(e.target.value)} filter={isPurchaseContacted} options={contactedOptions}></FilterSelect>
          </SelectContainer>

          <SelectContainer>
            <Label>Active</Label>
            <FilterSelect selecting={(e) =>  setActive(e.target.value)} filter={active} options={isActiveOptions}></FilterSelect>
          </SelectContainer>
        </Container>

        <SelectContainer>
          <Label>Search by name</Label>
          <PurchasesInputText placeholder={'Search name'} onWriting={(event: React.ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)} />
        </SelectContainer>

        <WrapperContent>
          <Content>
            <TableHead>
              <StyledCol xs="2">User name</StyledCol>
              <StyledCol xs="2 text-center">Date</StyledCol>
              <StyledCol xs="2 text-center">Price</StyledCol>
              <StyledCol xs="2 text-center">Product name</StyledCol>
              <StyledCol xs="2 text-center">Status</StyledCol>
              <StyledCol xs="1 text-center">Checked</StyledCol>
              <StyledCol xs="1 text-center">Active</StyledCol>
            </TableHead>

            {isLoading && <Loading />}

            {(!isLoading && purchaseData.length > 0) &&
              purchaseData.map((item) => (
                <TableRow key={item.id}>
                  <StyledCol xs="2">
                    <Link to={item.url} style={{ marginLeft: 10 }}>{item.full_name}</Link>
                  </StyledCol>
                  <StyledCol xs="2 text-center">
                    {formatDateFullDate(item.service_date)}
                  </StyledCol>
                  <StyledCol xs="2 text-center">
                    {formatCurrency(item.service_value)}
                  </StyledCol>
                  <StyledCol xs="2 text-center">{item.product_name}</StyledCol>
                  <StyledCol xs="2 text-center">{item.status}</StyledCol>
                  <StyledCol xs="1 text-center">
                  <CustomInput
                    type="checkbox"
                    id={`input-${item.id}`}
                    checked={purchaseData[item.id]?.is_purchase_contacted || item.is_purchase_contacted}
                    onChange={(e) => onChangeChecked(e, item.id)}
                  />
                  </StyledCol>
                  <StyledCol xs="1 text-center">{item.is_active ? 'Yes' : 'No'}</StyledCol>
                </TableRow>
              ))}
          </Content>
        </WrapperContent>

        {totalPages > 1 && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(Purchases);
