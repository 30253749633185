import React, { useState } from 'react';

import { Link } from 'gatsby';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Row, Col, Button, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const ExpensesRow: React.FC = ({
  type,
  category,
  active,
  onStatusChange,
  value,
  updateExpense,
  deleteJob,
  categoryOptions,
}) => {
  const [disabled, setDisabled] = useState(true);

  const toggle = () => {
    setDisabled((disabled) => !disabled);
  };

  const [name, setName] = useState(type);
  const [idCategory, setIdCategory] = useState(category);

  function update() {
    setDisabled(!disabled);
    updateExpense(name, idCategory);
  }

  return (
    <TableRow className="align-items-center">
      <Col>
        <Input
          type="text"
          name="type_expenses"
          defaultValue={type}
          disabled={disabled}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs="2">
        {disabled ? (
          <Link to={'/categories'}>
            <strong>
              {categoryOptions.map((item) => {
                return item.id === idCategory && item.name;
              })}
            </strong>
          </Link>
        ) : (
          <CustomInput
            value={idCategory}
            type="select"
            name="category_expenses"
            id="category_expenses"
            onChange={(e) => setIdCategory(e.target.value)}
          >
            {categoryOptions.map((item, index: number) => {
              return (
                <option value={item.id} key={`opt${index}`}>
                  {item.name}
                </option>
              );
            })}
          </CustomInput>
        )}
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? toggle : update}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            onClick={deleteJob}
            color="primary"
            className="table-button"
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default ExpensesRow;
