import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { StringParam, useQueryParam } from 'use-query-params';

import { faUserPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getHmrc } from 'src/api/hmrc.api';
import {
  addClient,
  deleteClient,
  fetchReferrals,
  fetchReferralsByName,
} from 'src/api/referrals';
import ResponseError from 'src/api/ResponseError';
import { ButtonIcon } from 'src/components';
import { SearchBar } from 'src/components/common';
import { useYear } from 'src/hooks';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Toastify,
  Row,
} from 'src/modules';
import store from 'src/stores';
import { formatCurrency, Routes, ERRORS, SUCCESS } from 'src/utils';

import { Loading } from '../../ui';

import { Wrapper, LoadingContainer } from './styles';

const ReferralsTab: React.FC = () => {
  // store
  const referrals = store.referrals;
  const hmrc = store.hmrc;

  // params
  const [userId] = useQueryParam('id', StringParam);
  const [year] = useYear();

  /* const [data, setData] = useState<UserReferralsTypes>(); */
  const [data, setData] = useState<UserReferralsTypes[]>([]);

  const [modal, setModal] = useState<boolean>(false);
  const [clients, setClients] = useState<string>('');
  const [earnings, setEarnings] = useState<string>('');
  const [showReferral, setShowReferral] = useState(false);
  const [referralCode, setReferralCode] = useState('');

  // Referral Payout States
  const [referredId, setReferredId] = useState<number>(0);
  const [payoutOpen, setPayoutOpen] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [editRefundPayout, setEditRefundPayout] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [referralsData, setReferralsData] = useState<ReferralsFetchTypes>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientSelected, setClientSelected] = useState({ id: 0, name: '' });
  const [query, setQuery] = useState('');
  const [userReferralSelected, setUserReferralSelected] =
    useState<UserReferralsTypes>();

  const {
    data: { last_page } = { last_page: 1 },
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    'fetchReferrals',
    () => fetchReferrals(Number(userId), currentPage),
    {
      onSuccess(response) {
        setReferralsData(response);

        if (!editRefundPayout && !payoutOpen) {
          const date = moment().format('DD/MM/YYYY');
          setDate(date);
        }

        if (response.total_entries > data.length) {
          setData((prevState) => [...prevState, ...response.data]);
          getHmrc(Number(userId), year);
        } else {
          setData(response.data);
          getHmrc(Number(userId), year);
        }
      },
      onError(error: any) {
        throw new ResponseError(error);
      },
    },
  );

  const {
    data: dataReferrals,
    refetch: refetchReferrals,
    isFetching: isFetchingReferrals,
  } = useQuery('fetchReferralsByName', () => fetchReferralsByName(query), {
    enabled: false,
    onSuccess(data) {
      console.log(data);
    },
    onError: (error: any) => {
      Toastify.toast.error(error.message);
    },
  });

  useEffect(() => {
    refetchReferrals();
  }, [query]);

  const onSubmitPayout = async () => {
    const data: ReferralsAddPayout = {
      user_tax_id: hmrc.hmrcData.user_tax_id,
      user_id: Number(userId),
      refund: amount,
      paid_amount: amount,
      referred_by_id: referredId,
      paid_date: date,
    };

    editRefundPayout
      ? await referrals.editRefundPayout(data)
      : await referrals.addTaxRefund(data);

    refetch();
    setPayoutOpen(false);
  };

  const openPayout = (value: number) => {
    setReferredId(value);

    togglePayout();
  };

  const togglePayout = () => setPayoutOpen((oldState) => !oldState);

  const toggle = () => setModal((oldState) => !oldState);

  const toggleReferral = () => setShowReferral((oldState) => !oldState);

  const toggleDeleteModal = () => setShowDeleteModal((oldState) => !oldState);

  const { mutate } = useMutation(
    () => addClient(Number(userId), referralCode),
    {
      onSettled: () => {
        toggleReferral();
      },
      onSuccess: () => {
        Toastify.toast.success('Sucessfully added');

        refetch();
      },
      onError: ({ response }) => {
        console.log('response', response);
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutateDeleteClient } = useMutation(deleteClient, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      refetch();
    },
    onError: (error: any) => {
      Toastify.toast.error(error.message);
    },
  });

  const handleDeleteRefundPayout = (userId: number) => {
    referrals
      .deleteRefundPayout(userId)
      .then(() => {
        Toastify.toast.success('Sucessfully edited');

        refetch();
      })
      .catch(() => Toastify.toast.error(ERRORS.GENERIC_ERROR));
  };

  const onAddReferral = async () => {
    const data = {
      user_id: Number(userId),
      clients: Number(clients),
      earning: Number(earnings),
    };

    await referrals.addClientEarning(data);

    toggle();
  };

  useEffect(() => {
    refetch();

    if (editRefundPayout) {
      const referral = data?.filter((referral) => referral.id === referredId);

      setAmount(referral[0].referred_payment);
      setDate(removeTimeFromDate(referral[0].paid_date));
    } else {
      setAmount(0);
      setDate(moment().format('DD/MM/YYYY'));
    }
  }, [editRefundPayout]);

  const removeTimeFromDate = (date: string) => {
    return date.slice(0, date.length - 5);
  };

  useEffect(() => {
    refetch();
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom && currentPage < last_page) {
        setCurrentPage(currentPage + 1);
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [data]);

  const handleDeleteClient = (clientId: number) => {
    mutateDeleteClient({ userId: Number(userId), clientId });
    setShowDeleteModal(false);
  };

  const onShowDeleteModal = (id: number, name: string) => {
    setShowDeleteModal(!showDeleteModal);
    setClientSelected({ id, name });
  };

  const handleSelect = (id: number) => {
    const selected = dataReferrals.filter((item: any) => item.id === id);

    setUserReferralSelected(selected[0]);
    setReferralCode(selected[0]?.referral_code);
  };

  return (
    <>
      {isLoading && <Loading />}

      {!isLoading && (
        <Wrapper className="tab-content printable">
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={toggle} color="primary">
              Add Clients
            </Button>
            <Button
              onClick={toggleReferral}
              color="primary"
              style={{ marginLeft: 10 }}
            >
              Add Referral
            </Button>
          </div>
          <Table>
            <tbody>
              <tr>
                <td className="border-0">
                  <p>
                    <strong>Total Clients: </strong>
                    {referralsData?.total_entries}
                  </p>
                </td>
                <td className="border-0">
                  <p>
                    <strong>Total Earning: </strong>
                    {formatCurrency(referralsData?.total_earning)}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Registered Clients:</strong>{' '}
                    {referralsData?.total_referring}
                  </p>
                </td>
                <td colSpan={2}>
                  {/* <p>
                    <strong>Referred Earning: </strong>
                    {formatCurrency(referrals.fetchData?.total_referring)}
                  </p> */}
                </td>
              </tr>
            </tbody>
          </Table>

          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Referred Payment</th>
                <th style={{ width: '215px' }}>Status</th>
                <th>Action</th>
                <th>Paid Date</th>
              </tr>
            </thead>

            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={`referrals-row-${item?.id}`}>
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to={`${Routes.CUSTOMER_INFORMATION}/?id=${item?.id}&tab=referrals`}
                        className="text-info"
                      >
                        {item?.full_name}
                      </Link>
                    </td>
                    <td>
                      <span className="hide-print">
                        {formatCurrency(item?.referred_payment)}
                      </span>
                    </td>
                    <td>{item?.referral_status}</td>
                    <td>
                      {item?.referral_status === 'Process' && (
                        <Row className="d-flex justify-content-center">
                          <ButtonIcon color="secondary">
                            <FontAwesomeIcon
                              icon={faUserPlus}
                              onClick={() => {
                                setEditRefundPayout(false);
                                openPayout(item.id);
                              }}
                            />
                          </ButtonIcon>

                          <ButtonIcon
                            color="secondary"
                            className="table-button"
                            onClick={() => {
                              setEditRefundPayout(true);
                              openPayout(item.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </ButtonIcon>

                          <ButtonIcon
                            color="secondary"
                            className="table-button"
                            onClick={() =>
                              onShowDeleteModal(item.id, item.full_name)
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </ButtonIcon>
                        </Row>
                      )}

                      {item.referral_status === 'Register' && (
                        <ButtonIcon
                          color="secondary"
                          className="table-button"
                          onClick={() =>
                            onShowDeleteModal(item.id, item.full_name)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      )}
                    </td>
                    <td>
                      {item.paid_date
                        ? removeTimeFromDate(item.paid_date)
                        : '-'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {isLoading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Client</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup row>
                  <Label for="clients" sm={2}>
                    Clients
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="clients"
                      id="clients"
                      onChange={(e) => setClients(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="earnings" sm={2}>
                    Earnings
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="earnings"
                      id="earnings"
                      onChange={(e) => setEarnings(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" onClick={onAddReferral}>
                Add
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={payoutOpen} toggle={togglePayout}>
            <ModalHeader toggle={togglePayout}>Referral Payout</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup row>
                  <Label for="date" sm={2}>
                    Date
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="date"
                      id="date"
                      value={date}
                      placeholder="dd/MM/AAAA"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="amount" sm={2}>
                    Amount
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      onChange={(e) => setAmount(Number(e.target.value))}
                      value={amount}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={togglePayout}>
                Cancel
              </Button>
              <Button color="primary" onClick={onSubmitPayout}>
                {editRefundPayout ? 'Update' : 'Add'}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={showReferral} toggle={toggleReferral}>
            <ModalHeader>Add referral</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="2">
                  <Label className="mv-1 mr-2">Referral code:</Label>
                </Col>
                <Col className="d-flex flex-row align-items-center">
                  <Input
                    onChange={(e) => setReferralCode(e.target.value)}
                    value={
                      userReferralSelected
                        ? userReferralSelected.referral_code
                        : referralCode
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col xs="2">
                  <Label>Search</Label>
                </Col>
                <Col xs="10">
                  <SearchBar
                    data={
                      dataReferrals &&
                      dataReferrals.map((item: any) => ({
                        ...item,
                        name: item.full_name,
                      }))
                    }
                    onSelect={handleSelect}
                    onChange={setQuery}
                    isLoading={isFetchingReferrals}
                    showReferralCode
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleReferral} size="sm" color="secondary">
                Cancel
              </Button>
              <Button onClick={() => mutate()} size="sm" color="primary">
                Add
              </Button>
            </ModalFooter>
          </Modal>

          <Modal centered isOpen={showDeleteModal} toggle={toggleDeleteModal}>
            <ModalHeader>Delete {clientSelected.name}?</ModalHeader>
            <ModalFooter>
              {isLoading && (
                <div className="w-100 mb-2 overflow-hidden">
                  <Loading />
                </div>
              )}
              <Button
                color="primary"
                onClick={() => handleDeleteClient(clientSelected.id)}
              >
                Delete
              </Button>
              <Button
                color="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Wrapper>
      )}
    </>
  );
};

export default ReferralsTab;
