import React from 'react';

import { observer } from 'src/modules';

import { Input } from './styles';

type Props = {
    placeholder: string;
    onWriting: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PurchasesInputText: React.FC<Props> = ({ placeholder, onWriting }: Props) => {
    return (
        <div className="input-group mb-3">
            <Input type="text" name="input" placeholder={placeholder} aria-label={placeholder} onChange={onWriting}/>
        </div>
    );
}

export default observer(PurchasesInputText);