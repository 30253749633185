import { DefaultTheme } from 'styled-components';

interface Colors {
  button: string;
  hoveredButton: string;
  activeButton: string;
  hoveredButtonBorder: string;
  activeButtonBorder: string;
  primary: string;
  pink: {
    light: string;
    main: string;
    dark: string;
  };
  red: {
    light: string;
    main: string;
    dark: string;
    punch: string;
    cosmos: string;
  };
  green: {
    light: string;
    main: string;
    dark: string;
    ocean: string;
    algae: string;
  };
  white: {
    default: string;
    catskill: string;
  };
  gray: {
    ghost: string;
    geyser: string;
    regent: string;
    iron: string;
    shark: string;
  };
  blue: {
    fiord: string;
    cyan: string;
  };
  yellow: {
    main: string;
  };
  orange: {
    main: string;
  };
}

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: Colors;
  }
}

const defaultTheme: DefaultTheme = {
  colors: {
    button: '#ffdd05',
    hoveredButton: '#dec000',
    activeButton: '#d1b500',
    hoveredButtonBorder: '#d1b500',
    activeButtonBorder: '#c4aa00',
    primary: '#57e4cd',
    pink: {
      light: '#e366be',
      main: '#e83ab4',
      dark: '#dc3ead',
    },
    red: {
      light: '#e84c4c',
      main: '#ee1c1c',
      dark: '#df0000',
      punch: '#DD3C27',
      cosmos: '#FFD7DA',
    },
    green: {
      light: '#1ed61e',
      main: '#57E4CD',
      dark: '#148f14',
      ocean: '#33A176',
      algae: '#92DDC0',
    },
    white: {
      default: '#ffffff',
      catskill: '#f4f6fa',
    },
    gray: {
      ghost: '#CED4DA',
      geyser: '#dee2e6',
      regent: '#818c97',
      iron: '#E0E3E6',
      shark: '#212529',
    },
    blue: {
      fiord: '#3a4d5f',
      cyan: '#1F9BCF',
    },
    yellow: {
      main: '#FFE600',
    },
    orange: {
      main: '#FF9900',
    },
  },
};

export default defaultTheme;
