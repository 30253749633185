import styled from 'styled-components';

type Props = {
  document_type: string;
};

export const Container = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 28px;
`;

export const EmployerInfo = styled.div`
  margin-bottom: 80px;
`;

export const EmployerNameSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const AddLogoButton = styled.label`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 18px;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #57e4cd;
  border: none;
  border-radius: 48px;
  height: 40px;
  padding: 0 24px;
  column-gap: 10px;
  position: relative;
  top: 18px;

  > span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const EmploymentDetails = styled.div`
  margin-bottom: 80px;
`;

export const SectionCompanyDirector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-left: 10px;
  gap: 10px;
  font-size: 18px;
  //margin-top: 20px;

  .title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const Benefits = styled.div`
  margin-bottom: 80px;
`;

export const StudentLoan = styled.div`
  margin-bottom: 80px;
`;

export const Pension = styled.div`
  margin-bottom: 80px;
`;

export const Proof = styled.div`
  margin-bottom: 80px;
`;

export const SubmitSection = styled.div`
  display: flex;
  position: relative;
  bottom: 30px;
  gap: 20px;
`;

export const Select = styled.select`
  height: 56px;
  width: 100%;
  max-width: 366px;
`;

export const ProofImage = styled.div`
  height: 86px;
  width: 86px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

export const ProofItem = styled.div`
  display: flex;
  margin-right: 70px;
  margin-bottom: 12px;
`;

export const ProofDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;

  .image-size {
    display: flex;
    flex-direction: column;
  }

  .remove {
    color: red;
    cursor: pointer;
  }
`;

export const InputRadio = styled.input`
  margin-right: 6px;
`;

export const DocumentTypeLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

export const AddProofButton = styled(AddLogoButton)<Props>`
  background-color: ${(props) =>
    props.document_type === '' ? '#ddd' : '#57e4cd'};
  color: ${(props) => (props.document_type === '' ? '#999' : 'initial')};
  margin: 0 0 40px 20px;
`;

export const CancelButton = styled(AddLogoButton)`
  background-color: red;
  color: #fff;
`;

export const RenderErrorSpan = styled.span`
  color: red;
`;
