import React, { useEffect, useState } from 'react';

import { Script } from 'gatsby';
import { isEmpty } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'use-debounce';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { listAdmins } from 'src/api/admin';
import {
  getConversationByUserId,
  getConversations,
} from 'src/api/message-center';
import { searchUsers } from 'src/api/users';
import Avatar from 'src/components/Avatar';
import { SearchBar } from 'src/components/common';
import Icon from 'src/components/Icon';
import { Loading } from 'src/components/ui';
import {
  Toastify,
  observer,
  useQueryParam,
  NumberParam,
  Modal,
  ModalBody,
  Button,
} from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { ERRORS } from 'src/utils';
import { CustomerSelected } from 'src/utils/types/MessageCenter';

import { TitleModalContainer } from '../ChatContent/components/Header/styles';
import ConversationCard from '../ConversationCard';
import { NumberChatUnread } from '../ConversationCard/styles';

import AssistantModal from './components/AssistantModal';
import FilterModal from './components/FilterModal';
import {
  Container,
  Header,
  HeaderButtonsContent,
  HeaderSearch,
  HeaderFilters,
  AssistantCardContainer,
  AssistantAvatarContent,
  FilterButton,
  Conversations,
  LoadingContainer,
  AssistantSelectedData,
  Headerbutton,
  PlusIcon,
} from './styles';

interface Props {
  isOpen: boolean;
}

export type TSortType = 'earliest' | 'a-z' | 'z-a' | 'unread';

const ChatList: React.FC<Props> = ({ isOpen }) => {
  const storeMessageCenter = store.messageCenter;
  const authStore = store.auth;
  const themeStore = store.theme;

  const scrollRef = React.useRef<HTMLDivElement>(null);

  // params
  const [id, setID] = useQueryParam('id', NumberParam);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [assistantSelected, setAssistantSelected] = useState<AdminData>();
  const [userLogged, setUserLogged] = useState<User>();
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<TSortType>('unread');
  const [messageTypeId, setMessageTypeId] = useState();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch] = useDebounce(searchText, 300);

  const [showAllButton, setShowAllButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState('');

  const [lastMessageFromUser, setLastMessageFromUser] = useState(true);

  const queryClient = useQueryClient();

  const { isLoading, refetch } = useQuery(
    [
      'conversationsList',
      assistantSelected?.id,
      page,
      sortType,
      messageTypeId,
      debouncedSearch,
      lastMessageFromUser,
    ],
    getConversations,
    {
      onSuccess: (result) => {
        storeMessageCenter.setConversationsList(result);
        storeMessageCenter.setTotalUnread(result?.total_unread);
        storeMessageCenter.setSelecteAssistantlUnread(
          result?.total_unread_by_tax_assistant,
        );
        setShowAllButton(false);
      },
      onError: () => {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  useEffect(() => {
    storeMessageCenter.setTotalUnread(0);
    storeMessageCenter.setConversationsList([]);
    setPage(1);
    setShowAllButton(false);
  }, [assistantSelected, sortType, messageTypeId, debouncedSearch]);

  const { data: assistantList = [] } = useQuery(
    ['listAdmins', ''],
    listAdmins,
    {
      onError: () => {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const loadConversationById = async (id: number) => {
    queryClient.cancelQueries('conversationsList');
    const result = await getConversationByUserId(id);
    storeMessageCenter.setConversationsList(result);
    storeMessageCenter.setTotalUnread(result?.total_unread);
    storeMessageCenter.setSelecteAssistantlUnread(
      result?.total_unread_by_tax_assistant,
    );

    const show = id && storeMessageCenter.conversationsList.length <= 3;
    setShowAllButton(!!show);

    return result;
  };

  useEffect(() => {
    async function getUser() {
      const userData = await Storage.getUser();
      setUserLogged(userData);

      if (id) {
        loadConversationById(id);
      }
    }

    getUser();
  }, []);

  const showAllConversations = () => {
    setID(undefined);
    storeMessageCenter.setConversationsList([]);
    setPage(1);
    refetch();
  };

  const startVoiceCall = async () => {
    storeMessageCenter.startVoiceCall();
  };

  const assistantData = isEmpty(assistantSelected)
    ? userLogged
    : assistantSelected;

  const onClickConversationCard = (
    id: number,
    customer: CustomerSelected,
    isGuest: boolean,
    is_new_message: boolean,
  ) => {
    if (!is_new_message) {
      setID(customer.id);
      storeMessageCenter.setConversationMessageSelected(id);
      storeMessageCenter.setIsNewMessage(false);
    } else {
      storeMessageCenter.setIsNewMessage(true);
    }

    storeMessageCenter.setConversationCustomerId(customer.id);
    storeMessageCenter.setCustomerSelected(customer);
    storeMessageCenter.setIsGuest(isGuest);
  };

  useEffect(() => {
    if (storeMessageCenter.messageSent) {
      // storeMessageCenter.setConversationsList([]);
      setPage(1);
      // refetch();
      storeMessageCenter.setMessageSent(false);
    }
  }, [storeMessageCenter.messageSent]);

  useEffect(() => {
    if (storeMessageCenter.linkedToUser) {
      storeMessageCenter.setConversationsList([]);
      setPage(1);
      storeMessageCenter.setLinkedToUser(false);
      refetch();
    }
  }, [storeMessageCenter.linkedToUser]);

  const onScrollConversations = async (e: any) => {
    const target = e.target;
    if (isLoading) {
      return;
    }
    //2420 1858.18
    // 561
    const scroll = Math.ceil(target.scrollHeight - target.scrollTop);
    const height = target.clientHeight;
    // check if scroll is == to height and +- 1 as well
    const hitEnd =
      scroll === height || scroll === height - 1 || scroll === height + 1;
    if (hitEnd) {
      const hasMorePages = storeMessageCenter.conversation.has_more;
      if (hasMorePages) {
        if (
          storeMessageCenter.conversation.current_page <
          storeMessageCenter.conversation.total_pages
        ) {
          // scrollRef corrent position - 100
          if (scrollRef.current && scrollRef.current.scrollTop > 100) {
            scrollRef.current.scrollTop = scrollRef.current.scrollTop - 100;
          }
          setPage(storeMessageCenter.conversation.next_page);
        }
      }
    }
  };

  const clearSelectedAssistant = () => {
    storeMessageCenter.setConversationsList([]);
    setAssistantSelected(undefined);
    setPage(1);
    refetch();
    setModalIsOpen(false);
  };

  useEffect(() => {
    async function getSideMenuOpen() {
      const isOpen = await Storage.getItem('menuOpen');
      setSideMenuOpen(isOpen);
    }

    getSideMenuOpen();
  }, [sideMenuOpen, filterModalIsOpen, modalIsOpen]);

  const {
    data: users = [],
    refetch: refetchUsers,
    isFetching,
  } = useQuery('searchUsers', () => searchUsers(query), {
    enabled: false,
  });

  useEffect(() => {
    if (query) {
      queryClient.cancelQueries('searchUsers');
      refetchUsers();
    }
  }, [query]);

  const onSearchUsers = (text: string) => {
    setSearchText(text);
  };

  const modalToggle = () => setModal(!modal);

  const handleSelect = async (id: number) => {
    const user = users.find((item) => item.id === id);

    if (user?.id) {
      const userData = await loadConversationById(user.id);
      // console.log(`🚀 ~ file: index.tsx:267 ~ handleSelect`, userData);
      if (!userData?.data?.length) {
        const conversationData: any = {
          customer: {
            avatar: null,
            id: user.id,
            is_guest: false,
            name: user.full_name,
            phone_number: user.phone,
          },
          tax_assistant: {
            avatar: user.tax_assistant_image,
            id: user.tax_assistant_id,
            name: user.tax_assistant_name,
          },
          is_new_message: true,
        };

        storeMessageCenter.setConversationsList({ data: [conversationData] });
      }
      setModal(false);
    }
  };

  return (
    <>
      <Script
        id="twilio-api"
        src="/twilio.min.js"
        onLoad={startVoiceCall}
        onError={() => console.log('error loading twilio sdk')}
      />
      <Container isOpen={isOpen} className="mc-chatlist-container">
        <Header>
          <HeaderButtonsContent isOpen={isOpen}>
            {/* <Headerbutton>Messages</Headerbutton> */}
            <Headerbutton onClick={modalToggle}>
              <PlusIcon />
              New
            </Headerbutton>
          </HeaderButtonsContent>
          <HeaderSearch
            isOpen={isOpen}
            placeholder="Search name, contact, etc"
            onChange={(e) => onSearchUsers(e.target.value)}
          />
          <HeaderFilters>
            <AssistantCardContainer>
              <AssistantAvatarContent
                onClick={() => setModalIsOpen(!modalIsOpen)}
              >
                <Avatar src={assistantData?.image || ''} size="xl" />
              </AssistantAvatarContent>
              <NumberChatUnread className="mc-number-chat-unread">
                {storeMessageCenter.totalUnread}
              </NumberChatUnread>
            </AssistantCardContainer>
            <AssistantSelectedData className="mc-assistant-selected-data">
              {`(${storeMessageCenter.selectedAssistantUnread}) ${assistantData?.name}`}
            </AssistantSelectedData>
            <FilterButton
              isOpen={isOpen}
              onClick={() => setFilterModalIsOpen(!filterModalIsOpen)}
            >
              {themeStore.currentTheme === 'dark' ? (
                <Icon name="white-filter" noResize />
              ) : (
                <Icon name="filter" noResize />
              )}
            </FilterButton>
          </HeaderFilters>
        </Header>

        {isLoading && <Loading />}

        <Conversations onScroll={onScrollConversations} ref={scrollRef}>
          {storeMessageCenter.conversationsList.map((item: any, index) => {
            return (
              <ConversationCard
                key={index}
                isOpen={isOpen}
                srcAvatarImage={item.customer.avatar}
                avatarName={item.customer.name}
                avatarChat={
                  !item.is_new_message ? item.last_message.excerpt : ''
                }
                chatTime={
                  !item.is_new_message ? item.last_message.date : new Date()
                }
                chatType={!item.is_new_message ? item.last_message.type : ''}
                numberUnreadMessages={
                  !item.is_new_message ? item.unread_count_by_conversation : ''
                }
                isRead={!item.is_new_message ? !!item.last_message.is_read : ''}
                onContainerClick={() =>
                  onClickConversationCard(
                    item.id,
                    item.customer,
                    item.customer.is_guest,
                    item.is_new_message,
                  )
                }
                cardSelected={
                  item.id === storeMessageCenter?.conversationSelectedId
                }
                subscriptionPlan={item.customer.subscription_plan}
              />
            );
          })}
          {showAllButton && (
            <Button
              color="primary"
              onClick={() => showAllConversations()}
              className="mt-3"
            >
              Show All
            </Button>
          )}
        </Conversations>
        {isLoading && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}
      </Container>
      {filterModalIsOpen && (
        <FilterModal
          filterModalIsOpen={filterModalIsOpen}
          setFilterModalIsOpen={setFilterModalIsOpen}
          setSortType={setSortType}
          sortType={sortType}
          setMessageTypeId={setMessageTypeId}
          messageTypeId={messageTypeId}
          sideMenuOpen={sideMenuOpen}
          lastMessageFromUser={lastMessageFromUser}
          setLastMessageFromUser={setLastMessageFromUser}
        />
      )}
      {modalIsOpen && (
        <AssistantModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          assistantList={assistantList}
          setAssistantSelected={(value) => setAssistantSelected(value)}
          clearSelectedAssistant={clearSelectedAssistant}
          sideMenuOpen={sideMenuOpen}
        />
      )}

      <Modal centered isOpen={modal} toggle={modalToggle}>
        <TitleModalContainer>
          <span className="title-modal">Search user</span>
        </TitleModalContainer>
        <ModalBody>
          <SearchBar
            data={users?.map((item) => ({ ...item, name: item.full_name }))}
            onChange={setQuery}
            onSelect={handleSelect}
            isLoading={isFetching}
            placeholder="Name, email or UTR"
            icon={faChevronRight}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default observer(ChatList);
