import React, { memo } from 'react';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IStatusInfo } from 'src/models';

type Props = {
  item?: IStatusInfo;
  onRemove?: any;
  onAdd?: any;
  inline?: boolean;
};

const StatusInfoItem: React.FC<Props> = ({ item, onRemove, onAdd, inline }) => {
  return (
    <div
      className={
        'status-info-item mb-1 d-flex justify-content-center ' +
        (inline && 'status-info-item-inline')
      }
    >
      {item && (
        <div className={'status-info-item-label bg-primary rounded-left px-2'}>
          <span className="text-truncate">
            {item?.status_label} {item?.days && <small>({item?.days})</small>}
          </span>
        </div>
      )}
      <div
        className={
          'status-info-item-remove bg-muted rounded-right ' +
          (!item && 'rounded-left')
        }
        onClick={item ? onRemove : onAdd}
      >
        <FontAwesomeIcon icon={item ? faTrash : faPlus} size="sm" />
      </div>
    </div>
  );
};

export default memo(StatusInfoItem);
