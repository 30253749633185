import React, { useState, useEffect } from 'react';

import { Check, Edit2 } from 'react-feather';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import API from 'src/data/API';
import {
  Table,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  FormGroup,
  ModalBody,
  ModalFooter,
} from 'src/modules';
import store from 'src/stores';

import ButtonIcon from '../ButtonIcon';
import { Loading } from '../ui';

type Props = {
  userId: number;
};

const CustomerInformationAdminNotesTab: React.FC<Props> = ({ userId }) => {
  const [notes, setNotes] = useState<AdminNotesTypes[]>([]);
  const [message, setMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal((oldValue) => !oldValue);
  const adminNotes = store.adminNotes;

  const fetchAdminNotes = async () => {
    await adminNotes.getAdminNotesById(userId);
    setNotes(adminNotes.adminNotes.data);
  };

  const onCreateNote = async () => {
    try {
      const note = {
        user_id: userId,
        notes: message,
      };

      await adminNotes.createAdminNote(note);
      setMessage('');
      toggle();
      fetchAdminNotes();
    } catch {
      // showAlert({ message: ERRORS.GENERIC_ERROR });
    }
  };

  useEffect(() => {
    fetchAdminNotes();
  }, []);

  // TODO: FINISH EDIT REFACTORING
  // this is neither performance efficient nor easy to manage
  const edit = async (item: AdminNotesTypes) => {
    if (item.isEditing) {
      const newValue = {
        id: item.id,
        notes: item.notes,
      };

      setLoading(true);

      try {
        await request.post(`${API.USERS_ADMIN_NOTES_UPDATE}`, newValue);

        fetchAdminNotes();
      } catch (error: any) {
        throw new ResponseError(error);
      } finally {
        setLoading(false);
      }
    } else {
      const newData: AdminNotesTypes[] = [];
      notes.map((dataItem) => {
        if (dataItem.id === item.id) {
          dataItem.isEditing = true;
        }
        newData.push(dataItem);
      });
      setNotes(newData);
    }
  };

  function updateValue(noteId: number, value: string) {
    const newArray: AdminNotesTypes[] = [];

    notes.map((dataItem) => {
      if (dataItem.id === noteId) {
        dataItem.notes = value;
      }
      newArray.push(dataItem);
    });
    setNotes(newArray);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="tab-content">
          <div className="tab-pane active">
            <div className="d-flex justify-content-end mb-3">
              <Button color="primary" onClick={toggle}>
                Add New Note
              </Button>
            </div>

            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Note</th>
                  <th>Noted By</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((item, index) => {
                  return (
                    <tr key={`an${item?.id}`} className="text-lg">
                      <td>{index + 1}</td>
                      <td style={{ width: '65%', paddingRight: 50 }}>
                        <Form>
                          {item.isEditing && (
                            <Input
                              type="textarea"
                              rows={8}
                              value={item?.notes}
                              onChange={(e) =>
                                updateValue(item?.id, e.target.value)
                              }
                            />
                          )}
                          {!item.isEditing && <p>{item?.notes}</p>}
                        </Form>
                      </td>
                      <td>{item?.admin_name}</td>
                      <td>{item?.created_date}</td>
                      <td>
                        <ButtonIcon
                          color="secondary"
                          outline
                          onClick={() => edit(item)}
                        >
                          {item?.isEditing === true ? (
                            <Check size={14} className="text-muted" />
                          ) : (
                            <Edit2 size={14} className="text-muted" />
                          )}
                        </ButtonIcon>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Note</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="textarea"
                id="message"
                name="message"
                value={message}
                rows={15}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" onClick={onCreateNote}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomerInformationAdminNotesTab;
