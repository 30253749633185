import React from "react";
import { Card, Col, Container } from "src/modules";
import { Layout, TableHead, ExternalExpenseFormsRow } from "src/components";
import Dashboard from "src/layouts/Dashboard";

// markup
const ExternalExpenseForms: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="External Expense Forms">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">User Name</Col>
              <Col xs="2">UTR</Col>
              <Col xs="2">Email</Col>
              <Col xs="2">Tax Year</Col>
              <Col xs="2">PAYE/Self</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>
            <ExternalExpenseFormsRow
              username="Jason Worboyes"
              email="jasonw609@gmail.com"
              taxYear="2021"
              payeSelf="Self"
            />
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default ExternalExpenseForms;
