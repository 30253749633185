import React from 'react';

import { observer } from 'mobx-react';

import Layout from 'src/components/Layout';
import Dashboard from 'src/layouts/Dashboard';

const DashboardPage: React.FC = () => {
  return (
    <Layout>
      <Dashboard title="Dashboard">
        <div style={{ maxWidth: 1440 }}>
          <a
            href="https://metadash.pietech.uk/"
            target="_blank"
            rel="noreferrer"
          >
            Click here to open the Dashboard
          </a>
        </div>
      </Dashboard>
    </Layout>
  );
};

export default observer(DashboardPage);
