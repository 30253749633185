import React from 'react';

import { AiFillInfoCircle, AiFillCheckCircle } from 'react-icons/ai';

import { ProgressBar } from 'src/components/common';
import { Col, Row } from 'src/modules';

import { LabelData } from '../../styles';
import { HiddenInput, SectionInput } from '../PersonalInformationPie/styles';

import { Container, SanctionListSection } from './styles';

type FooterProofProps = {
  sanctionsList: string;
  dateSubmitted: string;
  personOfSignificantInterest: string;
  dateSubmittedPerson: string;
};

type Props = {
  sanctioned: boolean;
  sanctionedPercentage: number;
  data: FooterProofProps;
  disabled: boolean;
};

const FooterProofPie = ({
  sanctioned,
  sanctionedPercentage,
  data,
  disabled,
}: Props) => {
  const sanctionedText = !sanctioned ? 'Approved' : 'Unapproved';
  return (
    <Container>
      <Row>
        <Col>
          <SanctionListSection>
            <LabelData for="sanctions-list" isPIE>
              Sanctions list:
              {!sanctioned && <AiFillInfoCircle size={20} color="red" />}
            </LabelData>

            <HiddenInput
              id="sanctions-list"
              type="text"
              defaultValue={sanctionedText}
              disabled={disabled}
              inputDisabled={disabled}
            />
          </SanctionListSection>

          <SectionInput>
            <LabelData for="date-submitted" isPIE>
              Similarity: {sanctionedPercentage} %
            </LabelData>

            <ProgressBar value={sanctionedPercentage} />
          </SectionInput>
        </Col>

        {/* <Col>
          <SectionInput>
            <LabelData for="person-of-significant-interest" isPIE>
              Person of significant interest :{' '}
              <AiFillCheckCircle size={20} color="#209782" />
            </LabelData>

            <HiddenInput
              id="person-of-significant-interest"
              type="text"
              defaultValue={data.personOfSignificantInterest}
              disabled={disabled}
              inputDisabled={disabled}
            />
          </SectionInput>

          <SectionInput>
            <LabelData for="date-submitted-person" isPIE>
              Date submitted :
            </LabelData>

            <HiddenInput
              id="date-submitted-person"
              type="text"
              defaultValue={data.dateSubmittedPerson}
              disabled={disabled}
              inputDisabled={disabled}
            />
          </SectionInput>
        </Col> */}
      </Row>
    </Container>
  );
};

export default FooterProofPie;
