import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.75rem;
  font-weight: 300;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Wrapper = styled.div``;

export { Title, Content, Wrapper, Header };
