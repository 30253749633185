import React, { useState, useEffect } from 'react';

import { Download } from 'react-feather';
import { useMutation, useQuery } from 'react-query';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { analyticsDeedDownload } from 'src/api/dashboard';
import { getLetters, getTemplates, saveToPostLetter } from 'src/api/letters';
import {
  Table,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  Toastify,
} from 'src/modules';
import store from 'src/stores';
import { formatDateFullDate, SUCCESS } from 'src/utils';

import { Loading } from '../../ui';

import {
  LetterSentItem,
  LetterSentTitle,
  LettersSentContainer,
  SendLetterContainer,
  SendLetterTitle,
  TitleItemList,
} from './styles';

type Props = {
  userId: number;
  taxYear: number;
};

const CustomerInformationESignatureTab: React.FC<Props> = ({
  userId,
  taxYear,
}) => {
  const eSignature = store.eSignature;

  const [data, setData] = useState<ESignatureTypes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [modalImg, setModalImg] = useState<string>('');
  const [templateSelected, setTemplateSelected] = useState('');
  const [addressSelected, setAddressSelected] = useState('');

  const toggle = () => setModal((oldState) => !oldState);

  const { data: listLetters, isLoading } = useQuery(
    'getLetters ',
    () => getLetters(),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { data: listTemplates, isLoading: isLoadingTemplates } = useQuery(
    'getTemplates',
    () => getTemplates(),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationSaveToPostLetter } = useMutation(saveToPostLetter, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  const fetchSignatures = async () => {
    setLoading(true);
    try {
      await eSignature.getESignatures(userId, taxYear);
    } finally {
      setLoading(false);
    }
  };

  function check(item: ESignatureTypes) {
    const newData: ESignatureTypes[] = [];
    data.map((dataItem) => {
      if (dataItem === item) {
        dataItem.isChecked = !dataItem.isChecked;
      }
      newData.push(dataItem);
    });
    setData(newData);
  }

  function checkAll() {
    const newData: ESignatureTypes[] = [];
    data.map((item) => {
      item.isChecked = !selectAll;
      newData.push(item);
    });
    setSelectAll(!selectAll);
    setData(newData);
  }

  const openImg = (signature_big: string) => {
    setModalImg(signature_big);
    toggle();
  };

  const downloadFetch = () => {
    // TODO
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const onAddToSendList = () => {
    mutationSaveToPostLetter({
      template_id: templateSelected,
      customer_id: userId,
      year: taxYear,
    });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {selectAll && (
            <div className="mb-3">
              <Button color="primary" onClick={downloadFetch} className="ml-2">
                Download as zip
              </Button>
            </div>
          )}

          <Table>
            <thead>
              <tr>
                <th>
                  <CustomInput
                    type="checkbox"
                    id="e-signatureAll"
                    defaultChecked={selectAll}
                    onChange={checkAll}
                  />
                </th>
                <th>#</th>
                <th>Document</th>
                <th>Sign Status</th>
                <th>Status</th>
                <th>Signature</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {eSignature.eSignatures.map((item, index) => {
                return (
                  <tr key={`e-signature-${index}`} className="text-lg">
                    <td>
                      <CustomInput
                        type="checkbox"
                        id={`d${index}`}
                        checked={item?.isChecked}
                        onChange={() => check(item)}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{item?.document_name}</td>
                    <td>{item?.document_status}</td>
                    <td>{item?.status}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <a
                          target="_blank"
                          href={item?.url_document}
                          onClick={analyticsDeedDownload}
                          className="btn-icon mr-2 btn btn-outline-secondary btn-lg"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                        <img
                          src={item?.signature_url}
                          alt=""
                          className="avatar rounded-circle mr-1"
                          onClick={() => openImg(item?.signature_big)}
                        />
                        <a
                          download={true}
                          target="_blank"
                          href={item?.signature_url}
                          className="btn-icon mx-1  btn btn-outline-secondary btn-lg"
                          rel="noreferrer"
                        >
                          <Download size={14} />
                        </a>
                      </div>
                    </td>
                    <td>--</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      <SendLetterTitle>Send letter: </SendLetterTitle>
      <SendLetterContainer>
        <select
          name="template-send-letter"
          id="template-send-letter"
          className="select-send-letter"
          onChange={(e) => setTemplateSelected(e.target.value)}
        >
          <option value=""></option>
          {!isLoadingTemplates &&
            listTemplates.templates.map((template) => {
              return (
                <option key={template.id} value={template.id}>
                  {template.name}{' '}
                </option>
              );
            })}
        </select>

        <select
          name="adress-send-letter"
          id="adress-send-letter"
          className="select-send-letter"
          disabled={!templateSelected}
          onChange={(e) => setAddressSelected(e.target.value)}
        >
          <option value={templateSelected && templateSelected}>
            {templateSelected && templateSelected}
          </option>
          {!isLoadingTemplates &&
            listTemplates.addresses.map((address) => {
              return (
                <option key={address.id} value={address.id}>
                  {address.address}{' '}
                </option>
              );
            })}
        </select>

        <Button
          color="primary"
          className="mr-auto"
          onClick={() => onAddToSendList()}
          disabled={!templateSelected}
        >
          Add to Send list
        </Button>
      </SendLetterContainer>

      <LettersSentContainer>
        <LetterSentTitle>Letters Sent:</LetterSentTitle>
        {!isLoading &&
          listLetters.map((letter) => {
            return (
              <div key={letter.id} style={{ display: 'flex' }}>
                <LetterSentItem width="130px" justifyContent="flex-start">
                  <TitleItemList>{letter.template}</TitleItemList>
                </LetterSentItem>
                |
                <LetterSentItem width="180px" justifyContent="center">
                  <TitleItemList>Address:</TitleItemList> {letter.address}
                </LetterSentItem>
                |
                <LetterSentItem width="180px" justifyContent="center">
                  <a href={letter.file} target="_blank" rel="noreferrer">
                    <TitleItemList> Link to PDF </TitleItemList>
                  </a>
                </LetterSentItem>
                |
                <LetterSentItem width="130px" justifyContent="flex-end">
                  <TitleItemList>
                    Sent: {formatDateFullDate(letter.created_at)}{' '}
                  </TitleItemList>
                </LetterSentItem>
              </div>
            );
          })}
      </LettersSentContainer>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Image Preview</ModalHeader>
        <ModalBody>
          <img src={modalImg} style={{ width: '100%' }} alt="" />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CustomerInformationESignatureTab;
