import React, { useState, useMemo } from 'react';

import { navigate } from 'gatsby';
import { useQuery } from 'react-query';

import { resetPassword } from 'src/api/auth.api';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Toastify,
} from 'src/modules';
import { Routes, ERRORS } from 'src/utils';

type Props = {
  params: {
    token: string;
  };
};

const ResetPassword = ({ params }: Props) => {
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);

  const disabled = useMemo(
    () => !password || password !== confirmPassword,
    [password, confirmPassword],
  );

  const { data, refetch, isLoading, isSuccess } = useQuery(
    'resetPassword',
    () => resetPassword(password!, params.token),
    {
      enabled: false,
      onSuccess: () => navigate(Routes.LOGIN),
      onError: () => Toastify.toast.error(ERRORS.PASSWORD_VALIDATIONS),
      retry: false,
    },
  );

  return (
    <div className="w-100 vh-100 justify-content-center d-flex align-items-center">
      <div className="w-50" style={{ maxWidth: '600px' }}>
        <Card>
          <CardBody>
            <div className="text-center mt-4">
              <h1 className="h2">Reset password</h1>
              {!isSuccess && <p className="lead">Enter your new password</p>}
            </div>
            <div className="m-sm-4">
              {isSuccess ? (
                <div className="text-center mt-4">
                  <p className="lead">{data?.message}</p>
                </div>
              ) : (
                <Form>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Enter your new password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => refetch()}
                      disabled={disabled}
                    >
                      {isLoading ? 'Loading...' : 'Reset password'}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
      <Toastify.ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ResetPassword;
