import React, { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MessageCenterApi } from 'src/api';
import {
  ButtonIcon,
  CustomPagination,
  Layout,
  TableHead,
  TableRow,
} from 'src/components';
import { TextArea } from 'src/components/common';
import { PaginationContainer } from 'src/components/ContingencyList/styles';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  observer,
} from 'src/modules';
import { formatToScreenDate } from 'src/utils';

type Message = {
  id: number;
  content: string;
};

const PLACEHOLDER_DATA = {
  data: [],
  total_entries: 0,
  total_pages: 0,
  last_page: 0,
  next_page: 0,
  has_more: false,
  next_page_url: '',
  previous_page_url: '',
  message: '',
};

const MessagesUnderReview: React.FC = () => {
  // queryClient
  const queryClient = useQueryClient();

  // state
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [editedMessage, setEditedMessage] = useState<string | undefined>(
    undefined,
  );
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  // queries
  const { data: messagesUnderReview = { data: [] }, isLoading } = useQuery(
    ['getMessagesUnderReview', nextPage],
    MessageCenterApi.getMessagesUnderReview,
    {
      placeholderData: PLACEHOLDER_DATA,
      onSuccess: ({
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
    },
  );

  // mutations
  const { mutate: deleteMessage, isLoading: isDeleting } = useMutation(
    MessageCenterApi.deleteMessage,
  );

  const { mutate: editMessage, isLoading: isEditing } = useMutation(
    MessageCenterApi.updateMessage,
  );

  const handleDelete = () => {
    deleteMessage(
      { conversationBodyId: message!.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['getMessagesUnderReview']);
          setMessage(undefined);
        },
      },
    );
  };

  const openDeleteModal = (id: number, content: string) => {
    setMessage({
      id,
      content,
    });
  };

  const handleEdit = () => {
    editMessage(
      {
        converstationBodyId: message!.id,
        messageBody: editedMessage!,
        underReview: false,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['getMessagesUnderReview']);
          setMessage(undefined);
          setEditedMessage(undefined);
        },
        onError(err) {
          console.log(`err`, err);
        },
      },
    );
  };

  const openEditModal = (id: number, content: string) => {
    setEditedMessage(content);
    setMessage({
      id,
      content,
    });
  };

  return (
    <Layout>
      <Dashboard
        title="Under Review"
        noPadding
        // mainClassName="height100vh"
        contentClassName="overflow-hidden"
      >
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3" className="d-flex justify-content-center">
                Tax assistant
              </Col>
              <Col xs="3" className="d-flex justify-content-center">
                Message
              </Col>
              <Col xs="3" className="d-flex justify-content-center">
                Date
              </Col>
              <Col xs="3" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>
            {messagesUnderReview.data.map((message) => (
              <TableRow key={message.conversation_body_id}>
                <Col
                  xs="3"
                  className="d-flex justify-content-center text-center"
                >
                  {message.sent_as}
                </Col>
                <Col
                  xs="3"
                  className="d-flex justify-content-center text-center"
                >
                  {message.message_body}
                </Col>
                <Col
                  xs="3"
                  className="d-flex justify-content-center text-center"
                >
                  {formatToScreenDate(message.sent_at)}
                </Col>
                <Col xs="3 d-flex justify-content-center align-items-center">
                  <Row className="d-flex justify-content-center">
                    <ButtonIcon
                      color={'primary'}
                      className="table-button"
                      onClick={() =>
                        openEditModal(
                          message.conversation_body_id,
                          message.message_body,
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </ButtonIcon>
                    <ButtonIcon
                      color={'primary'}
                      className="table-button"
                      onClick={() =>
                        openDeleteModal(
                          message.conversation_body_id,
                          message.message_body,
                        )
                      }
                    >
                      <FontAwesomeIcon color="" icon={faTrash} />
                    </ButtonIcon>
                  </Row>
                </Col>
              </TableRow>
            ))}
            {isLoading && <Loading />}
            <Modal isOpen={!!message && !editedMessage}>
              <ModalHeader>
                Are you sure you want to delete this message?
              </ModalHeader>
              <ModalBody>
                <p>{message?.content}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setMessage(undefined)}>
                  Cancel
                </Button>
                <Button
                  disabled={isDeleting}
                  color="primary"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={!!message && !!editedMessage}>
              <ModalHeader>Please, edit the message bellow</ModalHeader>
              <ModalBody>
                <TextArea
                  value={editedMessage}
                  onChange={(e) => setEditedMessage(e.target.value)}
                  className="w-100"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setMessage(undefined)}>
                  Cancel
                </Button>
                <Button
                  disabled={isEditing || message?.content === editedMessage}
                  color="primary"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </Card>

        <PaginationContainer>
          {hasPagination && (
            <CustomPagination
              res={pagRes}
              changePage={(page) => setNextPage(page)}
              position="center"
            />
          )}
        </PaginationContainer>
      </Dashboard>
    </Layout>
  );
};

export default observer(MessagesUnderReview);
