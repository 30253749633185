import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import { useQuery } from 'react-query';

import {
  faBell,
  faChartPie,
  faCog,
  faList,
  faUser,
  faUserFriends,
  faUserTie,
  faCommentDots,
  faChartArea,
  faExchangeAlt,
  faFileExport,
  faTag,
  faReply,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';

import { getConversations } from 'src/api/message-center';
import { getNotificationsCounter } from 'src/api/notifications';
import { useYear } from 'src/hooks';
import Logo from 'src/images/pie-black-teal-wordmark.svg';
import LogoIcon from 'src/images/pie-rounded.svg';
import { TTaxAssistantRole } from 'src/models';
import { Badge, motion, observer, Toastify } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { ERRORS, Routes } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import SideBarItem from '../SideBarItem';

const barAni = {
  open: { width: 260 },
  close: { width: 30 },
};

type Props = {
  isOpen: boolean;
};

const Sidebar: React.FC<Props> = ({ isOpen }) => {
  const auth = store.auth;
  const storeMessageCenter = store.messageCenter;

  const [user, setUser] = useState<User>(auth.userData);
  const messagesRef = useRef<HTMLLIElement>(null);
  const mcRef = useRef<HTMLLIElement>(null);
  const [notificationsPosY, setNotificationsPosY] = useState(0);
  const [mcPosY, setMCPosY] = useState(0);

  const isSuperAdmin = handleSuperAdminRole(user?.role_id);

  const [year] = useYear();

  const { data: counter = { total: '' } } = useQuery(
    'notificationsCounter',
    getNotificationsCounter,
  );

  const lastMessageFromUser = true;

  useQuery(
    ['conversationsList', , , , , , lastMessageFromUser],
    getConversations,
    {
      onSuccess: (result) => {
        storeMessageCenter.setTotalUnread(result?.total_unread);
      },
      onError: () => {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const getLoggedUser = async () => {
    const user = await Storage.getUser();

    setUser(user);
  };

  useEffect(() => {
    getLoggedUser();
  }, []);

  useEffect(() => {
    if (messagesRef.current) {
      const pos = messagesRef.current.getBoundingClientRect();
      setNotificationsPosY(pos.y);
    }
  }, [messagesRef]);

  useEffect(() => {
    if (mcRef.current) {
      const pos = mcRef.current.getBoundingClientRect();
      setMCPosY(pos.y);
    }
  }, [mcRef]);

  const userIsNotMarketing =
    user && user.role_id !== TTaxAssistantRole.MARKETING;

  return (
    <>
      {isOpen === undefined ? (
        <div></div>
      ) : (
        <motion.nav
          className="sidebar"
          variants={barAni}
          initial={isOpen ? 'open' : 'close'}
          animate={isOpen ? 'open' : 'close'}
        >
          <div className="sidebar-content">
            <div className="sidebar-logo">
              <Link to={Routes.CUSTOMERS}>
                <div
                  className={isOpen ? 'sidebar-tt-open' : 'sidebar-tt-close'}
                >
                  <img src={isOpen ? Logo : LogoIcon} />
                </div>
              </Link>
            </div>

            <ul
              className={'sidebar-nav ' + (isOpen ? '' : ' sidebar-nav-closed')}
            >
              {user?.role_id !== TTaxAssistantRole.MARKETING && (
                <>
                  <li className="sidebar-li">
                    {/* <Link
                      to={`${Routes.CUSTOMERS_CONTINGENCY}`}
                      activeClassName="link-active"
                    >
                      <div className="d-flex flex-row justify-content-start">
                        <div className="p-2 sidebar-icon">
                          <FontAwesomeIcon icon={faUserTie} />
                        </div>
                        {isOpen && (
                          <motion.div
                            animate={{ opacity: 1 }}
                            transition={textAni}
                            className="p-2 opacity-0"
                          >
                            Contingency
                          </motion.div>
                        )}
                      </div>
                    </Link> */}
                  </li>

                  <SideBarItem
                    linkTo={`${Routes.CUSTOMERS}/?tax_year=${year}`}
                    icon={faUserTie}
                    isOpen={isOpen}
                    title="Customers"
                  />
                  <SideBarItem
                    linkTo={`${Routes.MESSAGES}`}
                    icon={faCommentDots}
                    isOpen={isOpen}
                    title="Message Center"
                    ref={mcRef}
                    badgeCount={storeMessageCenter.totalUnread}
                    hasBadge
                  />
                  <SideBarItem
                    linkTo={`${Routes.MESSAGES_UNDER_REVIEW}`}
                    icon={faCommentDots}
                    isOpen={isOpen}
                    title="Messages Under Review "
                  />
                  <SideBarItem
                    linkTo={`${Routes.NOTIFICATIONS}`}
                    icon={faBell}
                    isOpen={isOpen}
                    title="Notifications"
                    ref={messagesRef}
                    badgeCount={Number(counter.total)}
                    hasBadge
                  />
                  <SideBarItem
                    linkTo={`${Routes.PURCHASES}`}
                    icon={faShoppingCart}
                    isOpen={isOpen}
                    title="Purchases"
                  />
                  <SideBarItem
                    linkTo={`${Routes.FEEDBACK}`}
                    icon={faUserFriends}
                    isOpen={isOpen}
                    title="Users Feedback"
                  />
                  <SideBarItem
                    linkTo={`${Routes.INBOX_TEMPLATES}`}
                    icon={faCommentDots}
                    isOpen={isOpen}
                    title="Inbox Templates"
                  />
                </>
              )}

              {/* {isSuperAdmin && (
                <>
                  <SideBarItem
                    linkTo={`${Routes.DASHBOARD_STAFF}`}
                    icon={faChartArea}
                    isOpen={isOpen}
                    title="Performance"
                  />
                  <SideBarItem
                    linkTo={`${Routes.STATUS_TAGS}`}
                    icon={faTag}
                    isOpen={isOpen}
                    title="Status Tag"
                  />
                </>
              )} */}

              {user?.role_id !== TTaxAssistantRole.TAX_ASSISTANT && (
                <>
                  <SideBarItem
                    linkTo={`/app/dashboard`}
                    icon={faChartPie}
                    isOpen={isOpen}
                    title="Dashboard"
                  />
                  {userIsNotMarketing && (
                    <>
                      <SideBarItem
                        linkTo={Routes.ADMINS}
                        icon={faUser}
                        isOpen={isOpen}
                        title="Admins/Tax Assistants"
                      />
                      <SideBarItem
                        linkTo={Routes.USER_REFERRALS}
                        icon={faUserFriends}
                        isOpen={isOpen}
                        title="User Referrals"
                      />
                      <SideBarItem
                        linkTo={Routes.HOW_YOU_HERE}
                        icon={faList}
                        isOpen={isOpen}
                        title="How You Here"
                      />
                      <SideBarItem
                        linkTo={Routes.SETTINGS}
                        icon={faCog}
                        isOpen={isOpen}
                        title="Settings"
                      />
                      <SideBarItem
                        linkTo={Routes.USER_ACCOUNT_DELETES}
                        icon={faUserTie}
                        isOpen={isOpen}
                        title="User Account Deletes"
                      />
                      <SideBarItem
                        linkTo={Routes.NPS}
                        icon={faUserTie}
                        isOpen={isOpen}
                        title="NPS List"
                      />
                      <SideBarItem
                        linkTo={Routes.QUICK_REPLIES}
                        icon={faReply}
                        isOpen={isOpen}
                        title="Quick Replies"
                      />
                    </>
                  )}
                </>
              )}
              {user?.role_id !== TTaxAssistantRole.MARKETING && (
                <>
                  <SideBarItem
                    linkTo={Routes.CHANGELOG}
                    icon={faExchangeAlt}
                    isOpen={isOpen}
                    title="Changelog"
                  />
                  <SideBarItem
                    linkTo={Routes.UTILS}
                    icon={faFileExport}
                    isOpen={isOpen}
                    title="Utils"
                  />
                </>
              )}
            </ul>
          </div>
        </motion.nav>
      )}
      {!isOpen && (
        <div
          className="position-absolute no-pointer-events"
          style={{ top: notificationsPosY, left: 5, zIndex: 999 }}
        >
          <Badge color="danger" pill>
            {counter.total}
          </Badge>
        </div>
      )}
      {!isOpen && (
        <div
          className="position-absolute no-pointer-events"
          style={{ top: mcPosY, left: 5, zIndex: 999 }}
        >
          <Badge color="danger" pill>
            {storeMessageCenter.totalUnread}
          </Badge>
        </div>
      )}
    </>
  );
};

export default observer(Sidebar);
