import moment from 'moment';

import { action, observable, makeObservable } from 'src/modules';

const DEFAULT_LENGTH = 15;

const START_DATE = moment('2022-04-01').toDate();

export default class DashboardStore {
  @observable
  filters: Filters = {
    startDate: moment()
      .subtract(DEFAULT_LENGTH - 1, 'days')
      .toDate(),
    endDate: new Date(),
    period: 'day',
    length: DEFAULT_LENGTH,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  setStartDate(date: string) {
    const startDate = moment(date);
    const endDate = moment(this.filters.endDate);
    this.filters.startDate = startDate.toDate();

    const length = endDate.diff(startDate, 'days') || DEFAULT_LENGTH;
    this.filters.length = length;
  }

  @action
  setEndDate(date: string) {
    const endDate = moment(date);
    const startDate = moment(this.filters.startDate);
    this.filters.endDate = endDate.toDate();

    const length = endDate.diff(startDate, 'days') || DEFAULT_LENGTH;
    this.filters.length = length;
  }

  @action
  setPeriod(value: Period) {
    this.filters.period = value;
  }
}
