import { useState } from 'react';

import { useQuery } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';

import { useFilters } from 'src/hooks';
import { Toastify } from 'src/modules';
import { ERRORS } from 'src/utils';

const usePagination = (handleFetchApi, setData, queryName: string) => {
  const [per_page, setPerPage] = useQueryParam('per_page', NumberParam);
  const [, setPage] = useQueryParam('page', NumberParam);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [pagRes, setPagRes] = useState<any>(null);
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);

  const { query, clearQuery } = useFilters();

  const { refetch: loadData, isLoading } = useQuery(
    [queryName, query],
    () => handleFetchApi(query),
    {
      onSuccess(data) {
        setData(data.data);
        setHasPagination(data.total_pages > 1);
        setTotalPages(data.total_pages);
        setTotalEntries(data.total_entries);
        setSelectedCustomers([]);
        setPagRes({
          next_page: data.next_page,
          total_pages: data.total_pages,
          per_page: data.per_page,
          next_page_url: '',
          previous_page_url: '',
        });
      },
      onError() {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const changePage = (pageNumber: number) => {
    setData([]);
    setPage(pageNumber);
    setPagRes({
      next_page: pageNumber + 1,
      per_page,
      total_pages: totalPages,
      next_page_url: '',
      previous_page_url: '',
    });
  };

  const changePerPage = (count: number) => {
    setData([]);
    setPerPage(count);
    setPage(undefined);
  };

  return {
    selectedCustomers,
    setSelectedCustomers,
    changePage,
    changePerPage,
    hasPagination,
    pagRes,
    loadData,
    clearQuery,
    totalEntries,
    isLoading,
  };
};

export default usePagination;
