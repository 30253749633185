import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  useEffect,
} from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useMutation } from 'react-query';

import { updateTransaction } from 'src/api/incomes';
import {
  PrevNextInputSection,
  Button,
  Input,
} from 'src/components/CustomerInformationTabs/IncomesTab/styles';
import { useYear } from 'src/hooks';
import { NumberParam, observer, Toastify, useQueryParam } from 'src/modules';
import store from 'src/stores';
import { formatDate, SUCCESS } from 'src/utils';

import {
  AlertMessage,
  PrevNextFields,
  PrevNextSection,
  Select,
  SubmitSection,
} from './styles';

type Props = {
  transactions: Transaction[];
  sourceList: any;
  onChangeFieldTransaction: (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
};

export type AllEditTransactionFormHandle = {
  setSelectedTransaction(id: number): void;
};

const AllEditTransactionForm = forwardRef<AllEditTransactionFormHandle, Props>(
  ({ transactions, sourceList, onChangeFieldTransaction }, ref) => {
    const [year] = useYear();
    const [userId] = useQueryParam('id', NumberParam);

    const incomes = store.incomes;

    const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
    const [incomeSourceSelected, setIncomeSourceSelected] = useState(
      transactions[currentTransactionIndex]?.income_id,
    );
    const [submited, setSubmited] = useState(false);
    const [cisSelected, setCisSelected] = useState(
      transactions[currentTransactionIndex]?.CIS,
    );

    useEffect(() => {
      setIncomeSourceSelected(transactions[currentTransactionIndex]?.income_id);
    }, [currentTransactionIndex]);

    const { mutate: mutationUpdateTransaction, isLoading } = useMutation(
      'updateTransaction',
      updateTransaction,
      {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
          incomes.refetchIncomeTransactions();
        },
        onError({ error }) {
          Toastify.toast.error(error);
        },
      },
    );

    const handlePrevClick = () => {
      if (currentTransactionIndex > 0) {
        setCurrentTransactionIndex(currentTransactionIndex - 1);
      }
    };

    const handleNextClick = () => {
      if (currentTransactionIndex < incomes.allTransactions.length - 1) {
        setCurrentTransactionIndex(currentTransactionIndex + 1);
      }
    };

    const onUpdateTransaction = () => {
      setSubmited(true);

      const formData = new FormData();

      const incomeType = sourceList.find(
        (src) => src.id === incomeSourceSelected,
      )?.['self_employment_type']
        ? 'self_employed'
        : 'employment';

      formData.append('_method', 'PUT');
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('income_id', incomeSourceSelected);
      formData.append('income_type', incomeType);
      formData.append('reconciliation_type', 'income');
      formData.append('cis', cisSelected);

      mutationUpdateTransaction({
        transaction_id: transactions[currentTransactionIndex].id,
        formData,
      });
    };

    const calcGross = (cis: number, amount: number) => {
      return (1 + cis / (100 - cis)) * amount;
    };

    useImperativeHandle(
      ref,
      () => ({
        setSelectedTransaction(id: number) {
          const transactionIndex = incomes.allTransactions.findIndex(
            (transaction) => transaction.id === id,
          );

          setCurrentTransactionIndex(transactionIndex);
        },
      }),
      [incomes.allTransactions],
    );

    return (
      <>
        <PrevNextSection>
          <Button onClick={() => handlePrevClick()}>
            <BsArrowLeft size={18} />
            <span>Prev</span>
          </Button>

          <PrevNextFields>
            {!incomes.isLoadingAllTransaction && (
              <>
                <PrevNextInputSection>
                  <label htmlFor="merchant_name">Merchant</label>
                  <Input
                    id="merchant_name"
                    name="merchant_name"
                    type="text"
                    value={
                      transactions[currentTransactionIndex]?.description ||
                      transactions[currentTransactionIndex]?.merchant_name ||
                      ''
                    }
                    onChange={() => {}}
                    readOnly
                    disabled
                  />
                </PrevNextInputSection>

                <PrevNextInputSection>
                  <label htmlFor="created_at">Date</label>
                  <Input
                    id="created_at"
                    name="created_at"
                    type="text"
                    value={formatDate(
                      transactions[currentTransactionIndex]?.created_at,
                    )}
                    readOnly
                    disabled
                    onChange={() => {}}
                  />
                </PrevNextInputSection>

                <PrevNextInputSection>
                  <label htmlFor="gross">Gross</label>
                  <Input
                    id="gross"
                    name="amount"
                    type="text"
                    value={
                      calcGross(
                        transactions[currentTransactionIndex]?.CIS,
                        transactions[currentTransactionIndex]?.amount,
                      ).toFixed(2) || 0
                    }
                    readOnly
                    disabled
                  />
                </PrevNextInputSection>

                <PrevNextInputSection>
                  <label htmlFor="net">Net</label>
                  <Input
                    id="net"
                    name="net"
                    type="text"
                    value={transactions[currentTransactionIndex]?.amount}
                    readOnly
                    disabled
                  />
                </PrevNextInputSection>

                <PrevNextInputSection>
                  <label htmlFor="tax">Tax</label>
                  <Input
                    id="tax"
                    name="tax"
                    type="text"
                    value={(
                      calcGross(
                        transactions[currentTransactionIndex]?.CIS,
                        transactions[currentTransactionIndex]?.amount,
                      ) - transactions[currentTransactionIndex]?.amount
                    ).toFixed(2)}
                    readOnly
                    disabled
                  />
                </PrevNextInputSection>

                <PrevNextInputSection>
                  <label htmlFor="source">Source</label>
                  <Select
                    id="source"
                    value={!incomeSourceSelected ? '' : incomeSourceSelected}
                    onChange={(e) => {
                      setIncomeSourceSelected(Number(e.target.value));
                      setSubmited(false);
                    }}
                  >
                    {/* {[
                    ...sourceList,
                    {
                      id: 0,
                      employer_name: '',
                      self_employment_type: '',
                      income_id: null,
                    },
                  ] */}
                    {!incomeSourceSelected && <option>Select an option</option>}
                    {sourceList?.map((src) => {
                      return (
                        src.income_type_id !== 1 && (
                          <>
                            <option key={src.id} value={src.id}>
                              {src.employer_name}
                            </option>
                          </>
                        )
                      );
                    })}
                  </Select>

                  {!incomeSourceSelected && (
                    <AlertMessage>Select this field to submit</AlertMessage>
                  )}
                </PrevNextInputSection>
              </>
            )}

            <PrevNextInputSection>
              <label htmlFor="year">Year</label>
              <Select id="year" disabled>
                <option value="2020"> {year} </option>
              </Select>
            </PrevNextInputSection>

            <PrevNextInputSection>
              <label htmlFor="cis">CIS</label>
              <Select
                id="CIS"
                name="CIS"
                value={cisSelected}
                onChange={(e) => setCisSelected(e.target.value)}
              >
                <option selected>Select an option</option>
                <option value="0">0%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
              </Select>
            </PrevNextInputSection>
          </PrevNextFields>

          <Button
            onClick={() => handleNextClick()}
            disabled={transactions.length < 2}
          >
            <BsArrowRight size={18} />
            <span>Next</span>
          </Button>

          <SubmitSection>
            <Button
              onClick={() => onUpdateTransaction()}
              style={{ fontWeight: 'bold' }}
              disabled={!incomeSourceSelected}
            >
              {isLoading ? 'Submitting' : 'Submit'}
            </Button>
          </SubmitSection>
        </PrevNextSection>
      </>
    );
  },
);

export default observer(AllEditTransactionForm);
