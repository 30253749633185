import { action, observable } from 'mobx';

import { InboxTemplatesApi } from 'src/api';

export default class InboxTemplatesStore {
  @observable
  templates: InboxTemplateTypes[] = [];

  @action
  fetchTemplates = async (): Promise<InboxTemplateTypes[]> => {
    const response = await InboxTemplatesApi.fetchTemplates();
    this.templates = response;

    return response;
  };

  @action
  fetchById = async (id: number): Promise<InboxTemplateTypes> => {
    return await InboxTemplatesApi.fetchById(id);
  };

  @action
  updateById = async (data: InboxTemplateUpdate): Promise<void> => {
    await InboxTemplatesApi.updateById(data);
    this.fetchTemplates();
  };

  @action
  deleteById = async (id: number): Promise<void> => {
    await InboxTemplatesApi.deleteById(id);
  };

  @action
  create = async (data: InboxTemplateCreate): Promise<void> => {
    await InboxTemplatesApi.create(data);
    this.fetchTemplates();
  };
}
