import React, { useEffect, useState } from 'react';

import { navigate, Link } from 'gatsby';
import { useQuery, useQueryClient } from 'react-query';
import { Row } from 'reactstrap';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { searchUsers, searchUsersAutoComplete } from 'src/api/users';
import { Layout } from 'src/components';
import { SearchBar } from 'src/components/common';
import {
  GotoCustomersButton,
  SearchbarContainer,
} from 'src/components/Home/styles';
import Dashboard from 'src/layouts/Dashboard';
import { Routes, openOnNewTab } from 'src/utils';

// import { Container } from './styles';

const home: React.FC = () => {
  const queryClient = useQueryClient();

  const [query, setQuery] = useState('');

  const {
    data: users = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    'searchUsersAutoComplete',
    () => searchUsersAutoComplete(query),
    {
      enabled: false,
    },
  );

  const handleSelect = (id: number) => {
    const user = users.find((item) => item.id === id);

    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${user?.id}&tax_year=2022&tab=details`,
    );
  };

  const handleSubmit = (query: string) => {
    navigate(`${Routes.CUSTOMERS}/?search=${query}`);
  };

  useEffect(() => {
    if (query) {
      queryClient.cancelQueries('searchUsersAutoComplete');
      refetch();
    }
  }, [query]);

  return (
    <Layout>
      <Dashboard title="Home">
        <Row className="justify-content-end mb-4">
          <Link to={`${Routes.CUSTOMERS}/?tax_year=2022`}>
            <GotoCustomersButton>
              Customers page (soon to be removed)
            </GotoCustomersButton>
          </Link>
        </Row>
        <Row className="justify-content-center">
          <SearchbarContainer>
            <SearchBar
              data={users?.map((item) => ({ ...item, name: item.full_name }))}
              onSubmit={handleSubmit}
              onChange={setQuery}
              onSelect={handleSelect}
              isLoading={isLoading || isFetching}
              placeholder="Name, email or UTR"
              icon={faChevronRight}
            />
          </SearchbarContainer>
        </Row>
      </Dashboard>
    </Layout>
  );
};

export default home;
