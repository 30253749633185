export const FormTaxReturn = {
  amended_return: null,
  welsh_return: null,
  taxpayer_name: '',
  individual_declaration: true,
  request_status:'',
  id:0
};

export const FormPersonal = {
  sa100_form_id: 0,
  date_of_birth: '',
  new_address_line_1: '',
  new_address_line_2: '',
  new_address_line_3: '',
  new_address_line_4: '',
  new_postcode: '',
  new_address_effective_from: '',
  telephone_number: '',
  national_insurance_number: '',
  taxpayer_status: '',
  employment_schedule: false,
  number_of_employment_schedules: '',
  minister_of_religion_schedule: false,
  number_of_minister_of_religion_schedules: '',
  full_self_employment_schedule: false,
  number_of_full_self_employment_schedules: '',
  short_self_employment_schedule: false,
  number_of_short_self_employment_schedules: null,
  lloyds_underwriter_schedule: false,
  full_partnership_schedule: false,
  number_of_full_partnership_schedules: '',
  short_partnership_schedule: false,
  number_of_short_partnership_schedules: '',
  uk_property_schedule: false,
  foreign_schedule: false,
  trusts_schedule: false,
  capital_gains_schedule: false,
  capital_gains_computation_attached: false,
  resident_remittance_schedule: false,
  additional_information_schedule: false,
  income_contingent_student_loan_notification: false,
  student_loan_repayment_deducted_amount: '',
  postgraduate_loan_repayment_deducted_amount: '',
  repayment_plan_type: '',
  postgraduate_loan_plan_type: '',
  taxed_bank_building_society_etc_interest: '',
  untaxed_uk_interest_etc: '',
  untaxed_foreign_interest: '',
  company_dividends: '',
  unit_trust_etc_dividends: '',
  foreign_dividends: '',
  tax_taken_off_foreign_dividends: '',
  annual_state_pension: '',
  state_pension_lump_sum: '',
  tax_taken_off_pension_lump_sum: '',
  other_pensions_and_retirement_annuities: '',
  tax_taken_off_pensions_and_retirement_annuities: '',
  incapacity_benefit: '',
  tax_taken_off_incapacity_benefit: '',
  jobseekers_allowance: '',
  other_state_pensions_and_benefits: '',
  other_taxable_income: '',
  tax_taken_off_other_taxable_income: '',
  allowable_expenses: '',
  deemed_income_or_benefits: '',
  description_of_other_income: '',
  payments_to_registered_pension_schemes: '',
  one_off_registered_pension_schemes_payments:'', 
  retirement_annuity_contract_payments: '',
  employer_pension_scheme_payments: '',
  non_uk_overseas_pension_scheme_payments: '',
  gift_aid_payments_made_in_year: '',
  one_off_gift_aid_payments: '',
  gift_aid_payments_carried_back_to_previous_year: '',
  gift_aid_payments_brought_back_from_later_year: '',
  shares_gifted_to_charity: '',
  land_and_buildings_gifted_to_charity: '',
  investments_gifted_to_non_uk_charities: '',
  donations_to_non_uk_charities: '',
  registered_blind: false,
  surplus_blind_persons_allowance_to_spouse: false,
  local_authority_name: '',
  surplus_blind_persons_allowance_from_spouse: false,
  child_benefit_amount_received: '',
  number_of_children: '',
  date_stopped_receiving_all_child_benefit_payments: '',
  cjrs_charge: '',
  seiss_charge: '',
  spouse_first_name: '',
  spouse_last_name: '',
  spouse_nino: '',
  spouse_date_of_birth: '',
  date_of_marriage_or_civil_partnership: '',
  marriage_allowance_transferred_in: false,
  marriage_allowance_transferred_out: false,
  tax_refunded_or_set_off: '',
  tax_owed_not_to_be_coded_out: false,
  non_paye_income_not_to_be_coded_out: false,
  bank_or_building_society_name: '',
  account_holder_or_nominee_name: '',
  branch_sort_code: '',
  account_number: '',
  building_society_reference_number: '',
  nominee_name_given: false,
  nominee_is_tax_adviser: false,
  nominee_address_line_1: '',
  nominee_address_line_2: '',
  nominee_address_line_3: '',
  nominee_address_short_line: '',
  nominee_address_postcode: '',
  no_bank_or_building_society_account: false,
  tax_adviser: '',
  tax_adviser_phone_number: '',
  tax_adviser_address_line_1: '',
  tax_adviser_address_line_2: '',
  tax_adviser_address_line_3: '',
  tax_adviser_address_short_line: '',
  tax_adviser_address_postcode: '',
  tax_advisers_reference: '',
  other_information_space: '',
  provisional_figures: false,
  coronavirus_support_payments_included: null,
  capacity_of_person_signing: '',
  name_of_person_signed_for: '',
  name_of_person_signing: '',
  address_of_person_signing_line_1: '',
  address_of_person_signing_line_2: '',
  address_of_person_signing_line_3: '',
  address_of_person_signing_short_line: '',
  address_of_person_signing_postcode: '',
  chargeable_event_gains: '',
};

export const FormEmployment = {
  pay_from_employment: '',
  tax_taken_off_pay: '',
  total_class_1_nicable_earnings: '',
  tips_and_other_payments: '',
  employer_paye_reference: '',
  employers_name: '',
  company_director: 'no',
  date_ceased_being_a_director: '',
  close_company: null,
  disguised_remuneration_included_in_pay: null,
  off_payroll_working: null,
  company_cars_and_vans_benefit: '',
  fuel_for_cars_and_vans: '',
  private_medical_dental_insurance: '',
  vouchers_credit_cards_excess_mileage_allowance: '',
  goods_etc_provided_by_employer: '',
  accommodation_provided_by_employer: '',
  other_benefits: '',
  expenses_payments_received: '',
  business_travel_and_subsistence: '',
  fixed_expenses_deductions: '',
  professional_fees_and_subscriptions: '',
  other_expenses_and_capital_allowances: '',
};

export const FormBusiness = {
  business_name: '',
  business_description: '',
  business_address_first_line: '',
  business_address_postcode: '',
  change_of_business_details: null,
  date_business_started: '',
  date_business_ceased: '',
  date_accounting_period_starts: '',
  date_accounting_period_ends: '',
  cash_basis: null,
  permanent_accounting_date_change: null,
  multiple_accounting_date_changes: null,
  special_arrangements_apply: null,
  information_provided_last_year: null,
  turnover: '',
  other_business_income: '',
  trading_income_allowance: '',
  cost_of_goods: '',
  subcontractor_costs: '',
  wages_salaries_and_staff_costs: '',
  car_van_and_travel_expenses: '',
  rent_and_other_property_costs: '',
  repairs_and_maintenance_costs: '',
  phone_and_other_office_costs: '',
  advertising_and_entertainment_costs: '',
  bank_and_loan_interest: '',
  other_finance_charges: '',
  debts_written_off: '',
  accountancy_and_legal_fees: '',
  depreciation_and_loss_profit_on_sale: '',
  other_business_expenses: '',
  total_expenses: '',
  disallowable_cost_of_goods: '',
  disallowable_subcontractor_costs: '',
  disallowable_staff_costs: '',
  disallowable_car_and_travel_expenses: '',
  disallowable_rent_and_other_property_costs: '',
  disallowable_repairs_and_maintenance_costs: '',
  disallowable_phone_and_other_office_costs: '',
  disallowable_advertising_and_entertainment_costs: '',
  disallowable_bank_and_loan_interest: '',
  disallowable_other_finance_charges: '',
  disallowable_debts_written_off: '',
  disallowable_accountancy_and_legal_fees: '',
  disallowable_depreciation_and_loss_profit_on_sale: '',
  disallowable_other_business_expenses: '',
  total_disallowable_expenses: '',
  net_profit_loss: '',
  annual_investment_allowance: '',
  annual_allowances_at_higher_rate: '',
  annual_allowances_at_lower_rate: '',
  zero_emission_goods_vehicle_allowance: '',
  zero_emission_car_allowance: '',
  the_structures_and_buildings_allowance: '',
  freeport_structures_and_buildings_allowance: '',
  electric_charge_point_allowance: '',
  other_capital_allowances: '',
  balancing_allowances_on_sale_or_cessation: '',
  total_capital_allowances: '',
  total_balancing_charges: '',
  own_goods_and_services: '',
  additions_to_net_profit_deductions_from_net_loss: '',
  non_taxable_business_income: '',
  deductions_from_net_profit_additions_to_net_loss: '',
  net_business_profit_loss_for_tax: '',
  date_basis_period_began: '',
  date_basis_period_ended: '',
  basis_period_adjustment: '',
  overlap_relief_used: '',
  overlap_profit_carried_forward: '',
  seiss_grant: '',
  change_of_accounting_practice_adjustment: '',
  averaging_adjustment: '',
  loss_brought_forward: '',
  any_other_business_income: '',
  total_taxable_business_profits: '',
  adjusted_loss_for_the_year: '',
  loss_of_year_set_against_other_income: '',
  loss_to_carry_back: '',
  total_loss_to_carry_forward: '',
  sub_contractors_tax_deduction: '',
  other_tax_taken_off_trading_income: '',
  equipment_machinery_vehicles: '',
  other_fixed_assets: '',
  stock_and_work_in_progress: '',
  trade_debtors: '',
  bank_etc_balances: '',
  cash_in_hand: '',
  other_current_assets: '',
  total_business_assets: '',
  trade_creditors: '',
  loans_and_overdrafts: '',
  other_liabilities: '',
  net_business_assets: '',
  capital_account_balance_at_start: '',
  net_profit_or_loss: '',
  capital_introduced: '',
  drawings: '',
  pay_class_2_nic_voluntarily: null,
  class_2_nic_amount: '',
  class_4_nic_exempt: '',
  adjustment_to_class_4_nic_profits: '',
  other_information_space: '',
};

export const FormSA103s = {
  tax_return_id: 0,
  business_description: '',
  business_address_postcode: '',
  change_of_business_details: null,
  foster_etc_carer_indicator: null,
  date_business_started: '',
  date_business_ceased: '',
  date_business_books_are_made_up_to: '',
  cash_basis: null,
  source_reference: '',
  turnover: '',
  other_business_income: '',
  trading_income_allowance: '',
  cost_of_goods: '',
  car_van_and_travel_expenses: '',
  wages_salaries_and_staff_costs: '',
  rent_and_other_property_costs: '',
  repairs_and_maintenance_costs: '',
  accountancy_and_legal_fees: '',
  interest_and_finance_charges: '',
  phone_and_other_office_costs: '',
  other_allowable_business_expenses: '',
  total_allowable_expenses: '',
  net_profit_or_loss: '',
  annual_investment_allowance: '',
  allowance_for_small_balance_of_unrelieved_expenditure: '',
  zero_emission_car_allowance: '',
  other_capital_allowances: '',
  the_structures_and_buildings_allowance: '',
  freeport_structures_and_buildings_allowance: '',
  total_balancing_charges: '',
  own_goods_and_services: '',
  seiss_grant: '',
  net_business_profit_for_tax: '',
  loss_brought_forward: '',
  any_other_business_income: '',
  total_taxable_business_profits: '',
  net_business_loss_for_tax: '',
  loss_of_year_set_against_other_income: '',
  loss_to_carry_back: '',
  total_loss_to_carry_forward: '',
  pay_class_2_nic_voluntarily: null,
  class_2_nic_amount: '',
  class_4_nic_exempt: null,
  sub_contractors_tax_deduction: '',
};

export const FormSA110 = {
  total_tax_etc_due: '',
  student_loan_repayment_due: '',
  postgraduate_loan_repayment_due: '',
  class_4_nics_due: '',
  class_2_nics_due: '',
  capital_gains_tax_due: '',
  pension_charges_due: '',
  underpaid_tax_for_earlier_years_included_in_code: '',
  underpaid_tax_for_year_included_in_future_code: '',
  outstanding_debt_coded_out_amount: '',
  claim_to_reduce_payments_on_account: null,
  first_payment_on_account: '',
  surplus_blind_persons_allowance: '',
  surplus_married_couples_allowance: '',
  increase_in_tax_from_adjustment_to_earlier_years: '',
  decrease_in_tax_from_adjustment_to_earlier_years: '',
  next_years_repayment_claimed_now: '',
  any_other_information_space: '',
  tax_return_id: '',
};

export const FormSA101 = {
    tax_return_id: null,
    net_gilt_interest: null,
    tax_taken_off_gilt_interest: null,
    gross_gilt_interest: null,
    life_insurance_gains_tax_treated_as_paid_amount_of_gain: null,
    life_insurance_gains_tax_treated_as_paid_number_of_years: null,
    life_insurance_gains_no_tax_treated_as_paid_amount_of_gain: null,
    life_insurance_gains_no_tax_treated_as_paid_number_of_years: null,
    life_insurance_gains_from_voided_isas_amount_of_gain: null,
    life_insurance_gains_from_voided_isas_number_of_years: null,
    tax_taken_off_gains_from_voided_isas: null,
    deficiency_relief: null,
    stock_dividends: null,
    bonus_issues_of_securities_and_redeemable_shares: null,
    close_company_loans_written_off_or_released: null,
    post_cessation_or_other_business_receipts: null,
    tax_year_income_to_be_taxed: null,
    share_schemes_taxable_amount: null,
    lump_sum_salaries_and_other_payments: null,
    lump_sums_or_benefits_from_retirement_schemes: null,
    redundancy_and_other_compensation_payments: null,
    tax_taken_off: null,
    tax_taken_off_lump_sums_left_blank: null,
    retirement_and_other_exemptions: null,
    compensation_and_lump_sum_exemption: null,
    disability_and_foreign_service_deduction: null,
    seafarers_earnings_deduction: null,
    non_uk_taxable_foreign_earnings: null,
    foreign_tax_no_foreign_tax_credit_relief_claim: null,
    overseas_pension_exempt_employer_contributions: null,
    uk_patent_royalty_payments_made: null,
    venture_capital_trust_share_subscriptions: null,
    enterprise_investment_scheme_share_subscriptions: null,
    community_investment_trust_relief: null,
    annuities_and_annual_payments: null,
    qualifying_loan_interest: null,
    post_cessation_and_other_losses: null,
    maintenance_or_alimony_payments: null,
    trade_union_etc_death_benefit_payments: null,
    bonus_security_redemption_distribution_relief: null,
    seed_enterprise_investment_scheme_amount: null,
    social_investment_tax_relief: null,
    nondeductible_loan_interest: null,
    higher_earner_spouses_name: null,
    higher_earner_spouses_date_of_birth: null,
    higher_earner_half_minimum_allowance_transfer_to_other: null,
    higher_earner_all_minimum_allowance_transfer_to_other: null,
    higher_earner_previous_spouses_date_of_birth: null,
    lower_earner_half_minimum_allowance_transfer_to_you: null,
    lower_earner_all_minimum_allowance_transfer_to_you: null,
    lower_earner_spouses_name: null,
    date_of_marriage_or_civil_partnership: null,
    surplus_allowance_from_spouse: null,
    surplus_allowance_to_spouse: null,
    earlier_years_income_tax_losses: null,
    unused_income_tax_losses_carried_forward: null,
    next_years_trading_and_capital_losses_relief: null,
    next_years_uncapped_loss_relief: null,
    year_for_which_relief_claimed: null,
    amount_of_payroll_giving: null,
    lump_sum_pension_benefits_in_excess_of_ala: null,
    non_lump_sum_pension_benefits_exceeding_ala: null,
    lifetime_allowance_tax_paid: null,
    amount_saved_exceeding_annual_allowance: null,
    annual_allowance_tax_paid_by_pension_scheme: null,
    pension_benefit_transferred_subject_to_otc: null,
    tax_paid_by_pension_scheme_on_otc: null,
    pension_scheme_tax_ref: null,
    unauthorised_payment_not_subject_to_surcharge: null,
    unauthorised_payment_subject_to_surcharge: null,
    foreign_tax_paid_on_unauthorised_payment: null,
    overseas_pension_contribution_short_service_refund: null,
    foreign_tax_paid: null,
    tax_avoidance_schemes: []
}