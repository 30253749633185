import { QueryFunctionContext } from 'react-query';

import request from './request';

export const getNPS = async ({ queryKey }: QueryFunctionContext) => {
  const [, nextPage, is_checked] = queryKey;
  let url = `/nps?page=${nextPage}`;

  if (is_checked) url += `&is_checked=${is_checked}`;

  const { data } = await request.get(url);
  return data;
};

export const updateNPS = async (id: number) => {
  const { data } = await request.put(`/nps/${id}`);

  return data;
};

export const saveNPSComment = async (id: number, comment: string) => {
  const { data } = await request.post(`/nps/${id}/comment`, { comment });

  return data;
};
