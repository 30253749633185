import React, { memo, useEffect, useState } from 'react';

import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';

import API from '../../data/API';
import ButtonIcon from '../ButtonIcon';

type Props = {
  id: number;
  called?: boolean;
  iconDisabled: boolean;
};

const IconCalled: React.FC<Props> = ({ id, called, iconDisabled }) => {
  const [local, setLocal] = useState<boolean>(!!called);

  useEffect(() => {
    setLocal(!!called);
  }, [called]);

  const onToggle = async () => {
    // toggle to faster visual effect
    const next = !local;
    setLocal(next);

    try {
      const { status } = await request.post(`${API.USERS_SET_CALLED}`, {
        user_id: id,
      });

      if (status !== 200) {
        setLocal(!next);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
    }
  };

  return (
    <ButtonIcon
      tooltip={local ? 'Called' : 'Not yet'}
      id="Called"
      outline={!local}
      color={!local ? 'secondary' : 'primary'}
      onClick={onToggle}
      disabled={iconDisabled}
    >
      <FontAwesomeIcon icon={faPhone} />
    </ButtonIcon>
  );
};

export default memo(IconCalled);
