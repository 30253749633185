import { TTaxAssistantRole } from 'src/models';

export const handleSuperAdminRole = (role: number): boolean => {
  if (role === TTaxAssistantRole.SUPER_ADMIN || role === TTaxAssistantRole.DEVS)
    return true;

  return false;
};

export const handleExcludeDev = (role: number): boolean => {
  if (role === TTaxAssistantRole.DEVS) return true;

  return false;
};

export const filterDevsOut = (data: any[]): any[] => {
  const removeDevs = data?.filter(
    (user) => user.role_id !== TTaxAssistantRole.DEVS,
  );

  return removeDevs || data;
};
