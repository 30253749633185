import React, { memo, ReactNode, useState } from 'react';

import { Button, Tooltip } from 'reactstrap';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { validCount } from 'src/utils';

export type TButtonIconColors = 'primary' | 'secondary' | 'transparent';
export type TButtonVariant = undefined | 'outline';

type Props = {
  children: ReactNode;
  color?: TButtonIconColors;
  onClick?: any;
  outline?: boolean;
  className?: string;
  crossed?: boolean;
  checkmark?: boolean;
  small?: boolean;
  badge?: number;
  tooltip?: string;
  id?: string;
  disabled?: boolean;
};

const ButtonIcon: React.FC<Props> = ({
  color = 'primary',
  children,
  onClick,
  outline,
  className,
  crossed,
  checkmark,
  badge,
  tooltip,
  small,
  id,
  disabled,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => setShowTooltip((prevState) => !prevState);

  return (
    <>
      <Button
        type="button"
        color={color}
        size="lg"
        className={
          'btn-icon mx-1 ' +
          (className ? className : '') +
          (small ? ' btn-icon-small mx-2' : '')
        }
        onClick={onClick}
        outline={outline}
        id={`button-${id}`}
        disabled={disabled}
      >
        {children}
        {crossed && <div className="btn-icon-crossed"></div>}
        {validCount(badge) && <div className="btn-icon-badge">{badge}</div>}

        {checkmark && (
          <div
            className={
              'btn-icon-checkmark ' +
              (color === 'primary' && ' bg-primary border-0')
            }
          >
            <FontAwesomeIcon icon={faCheck} size="sm" />
          </div>
        )}
      </Button>
      {tooltip && (
        <Tooltip
          placement="top"
          isOpen={showTooltip}
          toggle={toggleTooltip}
          target={`button-${id}`}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

export default memo(ButtonIcon);
