import React from 'react';

import { formatCurrency } from 'src/utils';

import {
  Body,
  Container,
  NonReconText,
  ReconText,
  Title,
  TaxPercentage,
  Content,
} from './styles';

function ExpensesTabTotals({ totals, grossColor }) {
  const { total_expenses } = totals;
  const { total_incomes } = totals;
  const taxPercentage =
    total_expenses.gross.reconciled !== 0
      ? ((total_expenses.gross.reconciled * -1) /
          total_incomes.gross.reconciled) *
        100
      : 0;

  return (
    <Container>
      <Body>
        <Title>Expenses</Title>
        <Content>
          <ReconText color={grossColor}>
            {formatCurrency(total_expenses.gross.reconciled)}
          </ReconText>
          <NonReconText>
            {formatCurrency(total_expenses.gross.non_reconciled)}
          </NonReconText>
        </Content>
      </Body>
      <Body>
        <Title>Expenses ratio</Title>
        <Content>
          <TaxPercentage>{Math.round(taxPercentage)}%</TaxPercentage>
        </Content>
      </Body>
    </Container>
  );
}

export default ExpensesTabTotals;
