import React, { useEffect, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import { getIncomeTransactions, deleteTransaction } from 'src/api/incomes';
import CustomPagination from 'src/components/CustomPagination';
import { Toastify } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import { NewItemButton } from '../../styles';

import {
  Actions,
  Circle,
  Container,
  SectionAddNewItem,
  TableBody,
  TableHeader,
} from './styles';

function SelfEmployedTransactionsList({
  incomeType,
  incomeId,
  setOpenAddTransactionForm,
  openAddTransactionForm,
  successAddTransaction,
  onClick,
  setCurrentTransactionIndex,
}) {
  const incomes = store.incomes;

  const [transactionsListState, setTransactionsListState] = useState([]);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
  }, []);

  const { isLoading, refetch } = useQuery(
    ['getIncomeTransactions', incomeType, incomeId, , , , , nextPage],
    () =>
      getIncomeTransactions(
        incomeType,
        incomeId,
        undefined,
        undefined,
        undefined,
        undefined,
        nextPage,
      ),
    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);
        incomes.setIncomeTransactions(result.data);
        incomes.setPayeOrSelfEmployedTotal(result.totals);

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more || total_pages !== 1);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        refetch();
        incomes.refetchTotals();
        incomes.refetchSummary();
      }, 1000);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  useEffect(() => {
    if (successAddTransaction) {
      refetch();
    }
  }, [successAddTransaction]);

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  useEffect(() => {
    const transactionsList = transactionsListState;

    if (!isLoading) {
      transactionsList.forEach(
        (item, index) => (transactionsList[index].checked = false),
      );

      setTransactionsListState(transactionsList);
    }
  }, [isLoading]);

  /* const onCheckboxChange = (event: any, index: number) => {
    const list = [...transactionsList];

    list[index].checked = event.target.checked;
    setTransactionsListState(list);
  }; */

  return (
    <>
      <Container>
        <TableHeader>
          <Row>
            <Col className="headerColumn"></Col>
            <Col className="headerColumn">Merchant</Col>
            <Col className="headerColumn">Date</Col>
            <Col className="headerColumn">Gross</Col>
            <Col className="headerColumn">Net</Col>
            <Col className="headerColumn">Tax</Col>
            <Col className="headerColumn">Category</Col>
            <Col className="headerColumn"></Col>
          </Row>
        </TableHeader>

        {!isLoading &&
          transactionsListState.map((item, i) => {
            return (
              <TableBody
                key={i}
                onClick={() => {
                  onClick(item.id);
                  incomes.setIndexTransactionSelected(i);
                  setCurrentTransactionIndex(i);
                }}
              >
                <Row>
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: 30 }}
                  >
                    <Circle background="#209782" />
                  </Col>
                  <Col className="bodyColumn">
                    {item.merchant_name !== ''
                      ? item.merchant_name
                      : item.description}
                  </Col>
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: -10 }}
                  >
                    {formatDate(item.created_at)}
                  </Col>
                  <Col className="bodyColumn">
                    {formatCurrency(item.gross_amount)}
                  </Col>
                  <Col className="bodyColumn">
                    {formatCurrency(item.amount)}
                  </Col>
                  <Col className="bodyColumn">
                    {formatCurrency(item.tax_amount)}
                  </Col>
                  <Col className="bodyColumn">{item.transaction_category}</Col>
                  <Col className="bodyColumn">
                    <Actions>
                      <BsFillTrashFill
                        color="red"
                        size="20"
                        onClick={() => onDeleteTransaction(item.id)}
                      />
                    </Actions>
                  </Col>
                </Row>
              </TableBody>
            );
          })}

        {!isLoading && transactionsListState.length < 1 && (
          <span>No transactions</span>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}
      </Container>

      <SectionAddNewItem>
        <NewItemButton
          onClick={() => setOpenAddTransactionForm(!openAddTransactionForm)}
        >
          <AiOutlinePlus size={18} />
        </NewItemButton>
      </SectionAddNewItem>
    </>
  );
}

export default SelfEmployedTransactionsList;
