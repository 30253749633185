import React, { useState } from "react";
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, Input, Row, Col, Button, Form } from "src/modules";
import JobTools from "../JobTools";

// markup
const AddJobCategory: React.FC = ({ onClose, createJob }) => {
  const [addJobTools, setAddJobTools] = useState([<JobTools />]);

  const addNewJobTools = () =>
    setAddJobTools((addJobTools) => [...addJobTools, <JobTools />]);

  const removeJobTools = (index: number) => {
    const newJobTools = addJobTools;
    newJobTools.splice(index, 1);
    setAddJobTools([...newJobTools]);
  };

  return (
    <Container className="mw-100 mb-3">
      <Form onSubmit={(e) => createJob(e)}>
        <Row className="align-items-center">
          <Col xs="1">Name</Col>
          <Col xs="3">
            <Input
              bsSize="lg"
              type="text"
              name="name"
              placeholder="Enter the name"
            />
          </Col>
          <Col xs="6" className="d-flex flex-row align-items-end border-top-0">
            <div>
              {addJobTools.map((_, index) => (
                <JobTools key={index} />
              ))}
            </div>
            <div className="ml-2 mb-1">
              <Button
                type="button"
                color="transparent"
                onClick={addNewJobTools}
                className="btn btn-outline-primary rounded-circle btn-icon pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            <div className="ml-2 mb-1">
              <Button
                type="button"
                color="transparent"
                onClick={removeJobTools}
                className="btn btn-outline-primary rounded-circle btn-icon pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </div>
          </Col>
          <Col xs="2">
            <div className="d-flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-4"
              >
                SUBMIT
              </Button>
              <Button
                type="reset"
                color="transparent"
                onClick={onClose}
                className="btn btn-outline-secondary rounded-circle btn-icon mt-1 pl-3 pr-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddJobCategory;
