import React, { useRef, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { onInputChange, onCheckboxChange } from '../../utils';

import {
  createTaxReturn,
  showTaxReturn,
  updateTaxReturn,
} from 'src/api/forms-tab';

import { Button, Input, Label, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import {
  CheckboxLabel,
  FormContainer,
  InputForm,
  SubmitButton,
  Subtitle,
  Title,
} from '../../styles';

import { FormTaxReturn } from '../../FormState';

type Props = {
    userId: number,
    taxYear: number,
    taxReturnId: number,
    onClick?: ( e: any) => void,

};

export const TaxReturnForm = ({
    userId, taxYear, taxReturnId,
    onClick
 }: Props) => {

  // form state
  const [formTaxReturnState, setformTaxReturnState] = useState(FormTaxReturn);

  const btnSave = useRef<HTMLButtonElement>(null);

  const { data, isLoading, refetch } = useQuery(
    'showTaxReturn',
    () => showTaxReturn(taxReturnId),
    {
      enabled: false,
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  useEffect(() => {
    if (taxReturnId > 0) {
        if(data && data.data) setformTaxReturnState(data.data);
        refetch();
    }
    else
    {
        setformTaxReturnState(FormTaxReturn);
    }
  }, [taxReturnId]);

  useEffect(() => {
    if (taxReturnId > 0) {
        if(data && data.data) setformTaxReturnState(data.data);
        refetch();
    }
    else
    {
        setformTaxReturnState(FormTaxReturn);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformTaxReturnState(data.data);
      } else {
        setformTaxReturnState(FormTaxReturn);
      }
    }
  }, [isLoading, data]);

  // disable button save
  useEffect(() => {
    btnSave.current!.disabled = ((formTaxReturnState.individual_declaration && !formTaxReturnState.agent_declaration) || (!formTaxReturnState.individual_declaration && formTaxReturnState.agent_declaration)) ? false: true;
  }, [formTaxReturnState.individual_declaration, formTaxReturnState.agent_declaration])

  const { mutate: MutationCreateTaxReturn } = useMutation(createTaxReturn, {
    onSuccess(response) {
        if(response && response.data) setformTaxReturnState(response.data);
        if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
        Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ message }) {
        Toastify.toast.error(message);
    },
  });

  const { mutate: MutationUpdateTaxReturn   } = useMutation(updateTaxReturn, {
    onSuccess(response) {
        if(response && response.data) setformTaxReturnState(response.data);
        if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
        Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError({ message }) {
        Toastify.toast.error(message);
    },
  });

    const onSubmitForm = async () => {
        if (taxReturnId == 0) {
            const formData = {
                user_id: userId,
                tax_year: taxYear,
                amended_return: formTaxReturnState.amended_return,
                welsh_return: formTaxReturnState.welsh_return,
                taxpayer_name: formTaxReturnState.taxpayer_name,
                individual_declaration: formTaxReturnState.individual_declaration,
                agent_declaration: formTaxReturnState.agent_declaration,
            };
            MutationCreateTaxReturn(formData);
        }

        if (formTaxReturnState.request_status === 'draft') {
                const formData = {
                tax_return_id: formTaxReturnState.id,
                amended_return: formTaxReturnState.amended_return,
                welsh_return: formTaxReturnState.welsh_return,
                taxpayer_name: formTaxReturnState.taxpayer_name,
                individual_declaration: formTaxReturnState.individual_declaration,
                agent_declaration: formTaxReturnState.agent_declaration,
            };
            MutationUpdateTaxReturn(formData);

        }

    };

  const onCheckboxSpecialChange = (
    e: any,
    state: object,
    setState: (state: object) => void,
  ) => {
    const { name, checked } = e.target;
    if( name === 'individual_declaration')
    {
        setState({
        ...state,
        individual_declaration: checked,
        agent_declaration: !checked,
        });
    }
    else if( name === 'agent_declaration')
    {
        setState({
        ...state,
        individual_declaration: !checked,
        agent_declaration: checked,
        });
    }

  };

 /* function onActionClick( action:string, id:number )
  {
      if( onClick ) onClick({ target:{ id:action, value: id }});
  }*/


  return (
    <>
      <Title>Tax Return - Create / Update form </Title>
      <FormContainer>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formTaxReturnState.amended_return || ''}
            checked={formTaxReturnState.amended_return}
            onChange={(e) =>
              onCheckboxChange(e, formTaxReturnState, setformTaxReturnState)
            }
            name="amended_return"
            id="amended_return"
          />
          <Label lg>Amended return</Label>
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formTaxReturnState.welsh_return || ''}
            checked={formTaxReturnState.welsh_return}
            onChange={(e) =>
              onCheckboxChange(e, formTaxReturnState, setformTaxReturnState)
            }
            name="welsh_return"
            id="welsh_return"
          />
          <Label lg>Welsh return</Label>
        </CheckboxLabel>
        <InputForm>
          <Label>Taxpayer Name</Label>
          <Input
            type="text"
            value={formTaxReturnState.taxpayer_name || ''}
            checked={formTaxReturnState.taxpayer_name}
            onChange={(e) =>
              onInputChange(e, formTaxReturnState, setformTaxReturnState)
            }
            bsSize="lg"
            id="taxpayer_name"
            name="taxpayer_name"
            maxlength="56"
            pattern="[A-Za-z0-9 &'\(\)\*,\-\./@£]*"
          />
        </InputForm>

        <Title>Declaration (required)</Title>
        <Subtitle>
        <strong>Individual Declaration:</strong> The customer has agreed:<br />
        I declare that the information I've given on this tax return and any supplementary pages is correct and complete to the best of my knowledge and belief.<br />
        I understand that I may have to pay financial penalties and face prosecution if I give false information.
        </Subtitle>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formTaxReturnState.individual_declaration || ''}
            checked={formTaxReturnState.individual_declaration}
            onChange={(e) =>
                onCheckboxSpecialChange(e, formTaxReturnState, setformTaxReturnState)
            }
            name="individual_declaration"
            id="individual_declaration"
          />
          <Label lg>Individual Declaration</Label>
        </CheckboxLabel>
        <Subtitle>
          <strong>Agent Declaration:</strong><br />
          I confirm that my client has received a copy of the information contained in this return and approved the information as being correct and complete to the best of their knowledge and belief.
        </Subtitle>
        <CheckboxLabel>
          <input
            type="checkbox"
            value={formTaxReturnState.agent_declaration || ''}
            checked={formTaxReturnState.agent_declaration}
            onChange={(e) =>
              onCheckboxSpecialChange(e, formTaxReturnState, setformTaxReturnState)
            }
            name="agent_declaration"
            id="agent_declaration"
          />
          <Label lg>Agent Declaration</Label>
        </CheckboxLabel>
      </FormContainer>
      <SubmitButton>
        {/* if request_status !== 'draft' so disable submit button */}
        <button ref={btnSave} onClick={() => onSubmitForm()} type="button" className="btn btn-primary">Save</button>
      </SubmitButton>
    </>
  );
};
