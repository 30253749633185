import { AxiosError } from 'axios';

const defaultErrorMessage =
  'Somenthing went wrong. Verify your internet connection and try again.';

export default class ResponseError {
  message = '';

  code = 0;

  constructor({ response }: AxiosError) {
    this.message = response?.data.message || defaultErrorMessage;
    this.code = response?.status || 0;
  }
}
