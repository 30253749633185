import React, { useEffect } from 'react';

import { Table } from 'src/modules';
import { StaffChartData } from 'src/utils/staffDataset';

import StaffShiftsRow from '../StaffShiftsRow';

type Props = {
  dataShifts?: StaffChartData;
};

const ShiftsTable: React.FC<Props> = ({ dataShifts }) => {
  return (
    <>
      <h1
        style={{ padding: '20px', paddingLeft: '0px', boxSizing: 'border-box' }}
      >
        Shifts
      </h1>
      <Table className="text-center" style={{ marginBottom: 100 }}>
        <thead>
          <tr>
            <td></td>
            {dataShifts?.byDate.map((item: any, index: any) => (
              <td key={`${item.label}-${index}`}>{item.label}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataShifts?.byGroup.map((item, index) => (
            <StaffShiftsRow
              item={item}
              index={index}
              key={`${item.assistant}-${index}`}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ShiftsTable;
