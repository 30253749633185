import React from "react";
import { Container, Spinner } from "src/modules";

type Props = {
  color?: string;
  size?: string;
};

const ButtonLoader: React.FC<Props> = ({ color = "light", size = "sm" }) => (
  <Container fluid>
    <Spinner color={color} size={size} />
  </Container>
);

export default ButtonLoader;
