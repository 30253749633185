export enum TaxStatus {
  Pending = 'Pending',
  Signed = 'Signed',
  IncomeReceived = 'IncomeReceived',
  ExpenseReceived = 'ExpenseReceived',
  IncomeCompleted = 'IncomeCompleted',
  ExpenseCompleted = 'ExpenseCompleted',
  InProgress = 'InProgress',
  Paid = 'Paid',
}

export enum TaxIncomeTypes {
  Paye = 'Paye',
  Self = 'Self',
  Other = 'Other',
  Piechart = 'Piechart',
  Refunded = 'Refunded',
  Bookkeeping = 'Bookkeeping',
}

export enum TaxIncomeColor {
  Self = '#e83ab4',
  Other = '#ee1c1c',
  Paye = '#1abc1a',
  Refunded = '#bda400'
}
