import { useState, useEffect } from 'react';

function useColors(colorID:string) {
	const [color, setColor] = useState('transparent');

	useEffect(() => {
		const cssColor = getComputedStyle(document.documentElement).getPropertyValue(`--${colorID}`);
		setColor(cssColor);
	})

	return color;
}

export default useColors;