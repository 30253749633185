import React from 'react';

import { Spinner } from 'reactstrap';

import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonIcon from '../ButtonIcon';

type Props = {
  onClick(): void;
  editMode?: boolean;
  loading?: boolean;
};

const ButtonEdit: React.FC<Props> = ({ onClick, editMode, loading }) => {
  return (
    <ButtonIcon
      onClick={loading ? null : onClick}
      color={'primary'}
      className="table-button"
    >
      {!loading && <FontAwesomeIcon icon={editMode ? faCheck : faPen} />}
      {loading && <Spinner color="danger" />}
    </ButtonIcon>
  );
};

export default ButtonEdit;
