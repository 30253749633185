import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';

import { canvasPreview } from './canvasPreview';
import { Wrapper, StyledImage } from './styles';
import { useDebounceEffect } from './useDebounceEffect';

type Props = {
  sourceImage: File | string;
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const Cropper = forwardRef(({ sourceImage }: Props, ref) => {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [rotate, setRotate] = useState(0);

  function onSelectFile(source: File) {
    if (source) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      );
      reader.readAsDataURL(source);
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      getCroppedImage: () =>
        new Promise((resolve) => {
          previewCanvasRef.current?.toBlob(
            (blob) => {
              blob.name = sourceImage.name;
              resolve(blob);
            },
            'image/jpeg',
            1,
          );
        }),
      rotate: () => setRotate((prevState) => prevState + 90),
    }),
    [previewCanvasRef],
  );

  useEffect(() => {
    if (typeof sourceImage !== 'string') {
      onSelectFile(sourceImage);
    }
  }, [sourceImage]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          undefined,
          rotate,
        );
      }
    },
    100,
    [completedCrop, rotate],
  );

  return (
    <Wrapper>
      {Boolean(completedCrop) && (
        <canvas
          ref={previewCanvasRef}
          style={{
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
            display: 'none',
          }}
        />
      )}
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <StyledImage
          ref={imgRef}
          alt="Crop me"
          src={typeof sourceImage === 'string' ? sourceImage : imgSrc}
          onLoad={onImageLoad}
          rotate={rotate}
        />
      </ReactCrop>
    </Wrapper>
  );
});

export default Cropper;
