import React from 'react';

import { Wrapper, Progress } from './styles';

type Props = {
  value: number;
};

export const ProgressBar = ({ value }: Props) => {
  return (
    <Wrapper>
      <Progress rangeValue={Number(value) * 100} />
    </Wrapper>
  );
};
