import React from 'react';

import { StaffChartData } from 'src/utils/staffDataset';
import { arraySumParam, arraySumParamSplit } from 'src/utils/Utils';

type Props = {
  data: StaffChartData;
  type: string;
};

const StaffRowTotalSplit: React.FC<Props> = ({ data, type }: Props) => {
  const getParam = () => {
    if (type == 'Submit') {
      return 'totalSubmit';
    }

    if (type == 'Modify') {
      return 'totalModify';
    }
  };

  const getParamSum = () => {
    if (type == 'Submit') {
      return 'HMRC_SUBMIT';
    }

    if (type == 'Modify') {
      return 'HMRC_MODIFY';
    }
  };
  return (
    <tr>
      <td></td>
      {data?.byDate.map((item, index) => (
        <td key={index}>
          {arraySumParamSplit(item.assistants, getParamSum())}
        </td>
      ))}
      <td
        className={data.byGroup.length % 2 === 0 ? 'bg-primary' : 'bg-tertiary'}
      >
        {arraySumParam(data?.byGroup as any, getParam())}
      </td>
    </tr>
  );
};

export default StaffRowTotalSplit;
