import React from "react";
import { Row } from "reactstrap";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const TableHead: React.FC<Props> = ({ children, className }) => {
  return (
    <Row className={"row-head " + (className ? className : "pt-2 pb-2 pl-3")}>
      {children}
    </Row>
  );
};

export default TableHead;
