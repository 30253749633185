import { action, observable, makeObservable } from 'mobx';

import { HmrcApi } from 'src/api';
import { TIsPaidStatus } from 'src/models';
import { TaxStatus, PaymentStatus, getCurrentFiscalYear } from 'src/utils';
import { CustomerHmrcTypes } from 'src/utils/types/Customer';

export default class HmrcStore {
  @observable
  hmrcData: CustomerHmrcTypes = {
    id: 0,
    user_tax_id: 0,
    profile_pic: '',
    full_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    utr_no: '',
    email: '',
    status: '',
    employee_type: '',
    phone: '',
    insurance_no: '',
    requested_utr: '',
    dob: '',
    user_referral_code: '',
    company_name: '',
    company_logo: '',
    company_address: '',
    company_postal_code: '',
    vat_no: '',
    account_holder_name: '',
    account_sort_code: '',
    account_number: '',
    id_proof: '',
    id_proof_title: '',
    referred_by: '',
    postal_code: '',
    address: '',
    tax_year: 0,
    how_you_are_here: '',
    how_you_here_image: '',
    tax_assistant_image: '',
    tax_status: TaxStatus.Pending,
    income_status: '',
    income_received_date: '',
    income_completed_date: '',
    expense_status: '',
    expense_received_date: '',
    expense_completed_date: '',
    is_called: false,
    is_paid_status: 'Blank',
    tax_code_complete: false,
    pre_payment_status: 0,
    refund_amount: 0,
    agent_fee: 0,
    agent_fee_type: '',
    agent_fee_percentage: 0,
    vat_amount: 0,
    vat_percentage: 0,
    pre_pay_agent_fee: 0,
    pre_pay_agent_fee_type: '',
    pre_pay_agent_fee_percentage: 0,
    pre_pay_vat_percentage: 0,
    pre_pay_vat_amount: 0,
    paid_date: '',
    estimated_refund_date: '',
    send_notifications: false,
    send_sms: false,
    send_email: false,
    last_login: '',
    user_tax_modified_date: '',
    created_ip: '',
    tax_status_process_date: '',
    tax_status_paid_date: '',
    is_paye_employee: false,
    has_dual_employee: false,
    status_info: [],
    message: '',
    pre_payment_status_label: '',
    taxReturnAgentFeeCustom: {
      class: '',
      checked: '',
      status: '',
      style: '',
    },
    taxReturnAgentFeePercentage: {
      class: '',
      checked: '',
      style: '',
    },
    agentFeesClassStatus: '',
    agentFeesInputReadOnly: '',
    agent_fee_display_input: 0,
    values_bt_tax_return_agent_fee: {
      minimum_label: '',
      minimum_value: 0,
      cis_label: '',
      cis_value: 0,
      paye_label: '',
      paye_value: 0,
    },
    no_early_payout: 0,
    total_amount: 0,
    pre_pay_agent_fee_type_checkbox_custom: '',
    pre_pay_agent_fee_type_checkbox_percentage: '',
    prepay_fees_per: 0,
    prepay_agent_fees: {
      checked: '',
      read_only: '',
    },
    with_early_payout: 0,
    grand_total_refund_client: '',
    grand_total_fees: '',
    grand_total_refund_amount: '',
    allYears: [],
    user_referral: [],
    is_multiple_locations: false,
    is_pub_priv_travel: false,
    reach_by: '',
    notifications_count: 0,
    do_not_submit_tag: false,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  getHmrc = async (
    userId: number,
    taxYear: number,
  ): Promise<CustomerHmrcTypes> => {
    let year = taxYear;
    if (!year || isNaN(year)) {
      year = getCurrentFiscalYear();
    }
    const response = await HmrcApi.getHmrc(userId, taxYear);

    this.hmrcData = response;
    return response;
  };

  @action
  setPayUnpay = async (
    userId: number,
    taxYear: number,
    action: string,
  ): Promise<void> => {
    const isPaidAction = action === 'paid';
    this.hmrcPaidIcon = isPaidAction ? PaymentStatus.PAID : PaymentStatus.UNPAY;

    await HmrcApi.setPayUnpay(userId, taxYear, action);
    await this.getHmrc(userId, taxYear);
  };

  @action
  updateHmrc = async (
    userId: number,
    taxYear: number,
    data: Omit<UpdateHMRC, 'all_years' | 'action'>,
  ): Promise<void> => {
    await HmrcApi.updateHmrc(userId, taxYear, data);
  };

  @action
  setPayAll = async (
    userId: number,
    taxYear: number,
    all_years: boolean,
    notification: boolean,
    email: boolean,
  ): Promise<void> => {
    await HmrcApi.setPayAll(userId, taxYear, all_years, notification, email);
  };
}
