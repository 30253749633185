import React, { ReactNode, useEffect, useState } from 'react';

import { Sidebar, Wrapper, Main, Navbar, Content } from 'src/components';
import { useLocalStorage } from 'src/hooks';
import { TTaxAssistantRole } from 'src/models';
import { Toastify, observer } from 'src/modules';
import { Storage } from 'src/services';

type Props = {
  children: ReactNode;
  title: string;
  noPadding?: boolean;
  mainClassName?: string;
  contentClassName?: string;
};

const Dashboard = ({
  children,
  title,
  mainClassName,
  contentClassName,
  noPadding = false,
}: Props) => {
  const [user, setUser] = useState<User>();
  const [open, setOpen] = useLocalStorage('menuOpen', false);

  const onToggle = () => {
    const next = !open;
    setOpen(next);
  };

  useEffect(() => {
    const userData = Storage.getUser();
    setUser(userData);
  }, []);

  return (
    <Wrapper>
      {user && user?.role_id !== TTaxAssistantRole.MARKETING && (
        <Sidebar isOpen={open} />
      )}

      <Main className={mainClassName}>
        <Navbar onToggle={onToggle} title={title} />
        <Content className={contentClassName} noPadding={noPadding}>
          {children}
        </Content>
      </Main>
      <Toastify.ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Wrapper>
  );
};
export default observer(Dashboard);
