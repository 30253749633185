import React, { useState } from 'react';

import { navigate } from 'gatsby';

import { Layout, TableHead, TableRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Container, Input, Button, Col, Toastify } from 'src/modules';
import store from 'src/stores';
import { ERRORS, SUCCESS } from 'src/utils';

import { CharactersLeft } from 'src/styles/pages/inbox-templates/styles';

const CHARS_LIMIT = 1000;

const InboxTemplateCreate: React.FC = () => {
  // Mobx store
  const inboxTemplates = store.inboxTemplates;

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [charsLeft, setCharsLeft] = useState(CHARS_LIMIT);

  const onGoBack = () => navigate(-1);

  const onChangeMessage = (message: string) => {
    setMessage(message);
    setCharsLeft(CHARS_LIMIT - message.length);
  };

  const onSave = async (): Promise<void> => {
    try {
      setLoading(true);
      const data: InboxTemplateCreate = {
        title: title,
        message: message,
      };

      await inboxTemplates.create(data);
      onGoBack();
    } catch {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    } finally {
      Toastify.toast.success(SUCCESS.GENERIC);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Dashboard title="Inbox Templates">
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">Create Inbox Template</Col>
            </TableHead>
            {loading ? (
              <Loading />
            ) : (
              <>
                <TableRow className="align-items-center">
                  <Col xs="3">Title</Col>
                  <Col xs="4">
                    <Input
                      type="text"
                      name="title"
                      placeholder="Enter the template title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Col>
                </TableRow>

                <TableRow className="align-items-center">
                  <Col xs="3">Message</Col>
                  <Col xs="4">
                    <Input
                      type="textarea"
                      rows={20}
                      name="message"
                      placeholder="Enter the message"
                      value={message}
                      onChange={(e) => onChangeMessage(e.target.value)}
                    />
                    <CharactersLeft>
                      Characters left: {charsLeft}
                    </CharactersLeft>
                  </Col>
                </TableRow>

                <TableRow className="align-items-center">
                  <Col xs="3"></Col>
                  <Col xs="3" className="d-flex">
                    <Button
                      type="button"
                      color="primary"
                      className="mr-3"
                      onClick={onSave}
                    >
                      CREATE
                    </Button>
                    <Button type="button" color="primary" onClick={onGoBack}>
                      CANCEL
                    </Button>
                  </Col>
                </TableRow>
              </>
            )}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default InboxTemplateCreate;
