import React, { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { editCategory } from 'src/api/categories';
import API from 'src/data/API';
import useForm from 'src/hooks/useForm';
import useKeyPress from 'src/hooks/useKeyPress';
import { Col, Input, Row } from 'src/modules';

import TableRow from '../TableRow';
import { ButtonEdit, StatusToggle, ButtonDelete } from '../ui';

interface CategoryRowProps {
  item: Category;
  onRefresh?: () => void;
}

const CategoryRow = ({ item, onRefresh }: CategoryRowProps) => {
  const queryClient = useQueryClient();

  const [disabled, setDisabled] = useState(true);

  const { form, handleChange, setForm } = useForm({
    name: item.name,
    description: item.description,
  });

  const { mutate, isLoading } = useMutation(
    () => editCategory(item.id, form.name, form.description),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<Category[]>('listCategories', (cache) =>
          cache
            ? cache.map((category) =>
                category.id === data.id ? data : category,
              )
            : [data],
        );

        setDisabled(true);
      },
    },
  );

  const onEsc = useCallback(() => {
    setForm({ name: item.name, description: item.description });
    setDisabled(true);
  }, [disabled]);

  useKeyPress('Escape', onEsc);

  const onEdit = () => {
    if (disabled) {
      setDisabled(false);
    } else {
      mutate();
    }
  };

  return (
    <TableRow className="align-items-center">
      <Col xs="3">
        <Input
          type="text"
          name="name"
          value={form.name}
          disabled={disabled}
          onChange={({ target }) => handleChange('name', target.value)}
        />
      </Col>
      <Col>
        <Input
          type="textarea"
          name="description"
          rows="3"
          value={form.description}
          disabled={disabled}
          onChange={({ target }) => handleChange('description', target.value)}
        />
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <StatusToggle
          url={API.CATEGORIES_STATUS_CHANGE}
          id={item.id}
          initStatus={item.status}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonEdit
            loading={isLoading}
            onClick={onEdit}
            editMode={!disabled}
          />
          <ButtonDelete
            url={API.CATEGORIES_DELETE}
            id={item.id}
            onDelete={onRefresh}
          />
        </Row>
      </Col>
    </TableRow>
  );
};

export default CategoryRow;
