import React, { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SearchResultItem, SearchResultItemType } from 'src/components/common';
import useKeyPress from 'src/hooks/useKeyPress';
import store from 'src/stores';

import {
  Wrapper,
  StyledInput,
  Submit,
  ResultsContainer,
  Spinner,
} from './styled';

type Props = {
  placeholder?: string;
  icon?: IconDefinition;
  data?: SearchResultItemType[];
  onSubmit?: (query: string) => void;
  onChange?: (query: string) => void;
  isLoading?: boolean;
  onSelect: (value: number | string) => void;
  isUserReferrals: boolean;
  showReferralCode: boolean;
};

const SearchBar = ({
  icon = faSearch,
  placeholder = 'Search for...',
  data,
  onSubmit = (query) => onChange(query),
  onChange = () => null,
  isLoading = false,
  onSelect,
  isUserReferrals,
  showReferralCode,
}: Props) => {
  const { isPIE } = store.pieLayout;

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery) {
      onChange(debouncedQuery);
    }
  }, [debouncedQuery]);

  useKeyPress('Escape', () => setQuery(''));

  const handleSelect = (value: number | string) => {
    setQuery('');
    onSelect(value);
  };

  /* const showItem = (item: SearchResultItemType) =>
    item?.name?.match(new RegExp(debouncedQuery.replace(' ', '.*'), 'i')) ||
    item?.email?.match(new RegExp(debouncedQuery.replace(' ', '.*'), 'i')) ||
    item?.utr_no?.match(new RegExp(debouncedQuery.replace(' ', '.*'), 'i')); */

  return (
    <Wrapper className="search-bar">
      <StyledInput
        onChange={(e) => setQuery(e.target.value)}
        {...{ placeholder }}
      />

      {isLoading ? (
        <Submit disabled>
          <Spinner />
        </Submit>
      ) : (
        <Submit
          onClick={() => onSubmit(query)}
          style={{ background: isPIE ? '#57E4CD' : '#ffdd05' }}
        >
          <FontAwesomeIcon {...{ icon }} />
        </Submit>
      )}
      {query && (
        <ResultsContainer>
          {data?.map((item) => (
            <SearchResultItem
              key={`result-${item.id}`}
              onSelect={handleSelect}
              isUserReferrals={isUserReferrals}
              showReferralCode={showReferralCode}
              {...{ item, query }}
            />
          ))}
        </ResultsContainer>
      )}
    </Wrapper>
  );
};

export default SearchBar;
