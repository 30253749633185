import { ExpensesApi } from 'src/api';
import { action, observable, makeObservable } from 'src/modules';

export default class ExpensesStore {
  @observable
  data: TaxIncomeListTypes = {
    data: [],
    editable: false,
    gross_total: '',
    message: '',
    tax_total: '',
  };

  @observable
  expensesTransactions = [];
  allTransactions = [];

  @observable
  indexTransactionSelected = 0;
  isLoadingTransaction = true;
  isLoadingAllTransaction = true;

  @observable
  keyword = '';
  date_range_start = '';
  date_range_end = '';

  @observable
  allTotal = undefined;

  @observable
  refetchTotals = () => {};
  refetchSummary = () => {};
  refetchExpensesTransactions = () => {};

  constructor() {
    makeObservable(this);
  }

  @action
  fetchCustomerIncomes = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const response = await ExpensesApi.fetchCustomerExpenses(userId, taxYear);

    this.data = response;
  };

  @action
  submitIncome = async (userId: number, taxYear: number): Promise<void> => {
    await ExpensesApi.submitExpense(userId, taxYear);
  };

  @action
  setIncomeTransactions = (incomeTransactions: any) => {
    this.expensesTransactions = incomeTransactions;
  };

  @action
  setAllTransactions = (allTransactions: any) => {
    this.allTransactions = allTransactions;
  };

  @action
  setIndexTransactionSelected = (indexTransactionSelected: number) => {
    this.indexTransactionSelected = indexTransactionSelected;
  };

  @action
  setIsLoadingTransaction = (isLoading: boolean) => {
    this.isLoadingTransaction = isLoading;
  };

  @action
  setIsLoadingAllTransaction = (isLoadingAllTransaction: boolean) => {
    this.isLoadingAllTransaction = isLoadingAllTransaction;
  };

  @action
  setKeyword = (keyword: string) => {
    this.keyword = keyword;
  };

  @action
  setDateRangeStart = (date_range_start: string) => {
    this.date_range_start = date_range_start;
  };

  @action
  setDateRangeEnd = (date_range_end: string) => {
    this.date_range_end = date_range_end;
  };

  @action
  setAllTotal = (allTotal: string) => {
    this.allTotal = allTotal;
  };

  @action
  setRefetchTotals = (refetchTotals: () => void) => {
    this.refetchTotals = refetchTotals;
  };

  @action
  setRefetchSummary = (refetchSummary: () => void) => {
    this.refetchSummary = refetchSummary;
  };

  @action
  setRefetchExpensesTransactions = (
    refetchExpensesTransactions: () => void,
  ) => {
    this.refetchExpensesTransactions = refetchExpensesTransactions;
  };
}
