import React, { useEffect, useState } from 'react';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import {
  Layout,
  TableHead,
  CustomPagination,
  AddQuestion,
  QuestionLibraryRow,
} from 'src/components';
import { Loading } from 'src/components/ui';
import API from 'src/data/API';
import Dashboard from 'src/layouts/Dashboard';
import {
  Card,
  Container,
  Row,
  Col,
  CustomInput,
  Button,
  Alert,
} from 'src/modules';

// markup
const QuestionLibrary: React.FC = () => {
  const [yearOptions, setYearOptions] = useState([]);
  useEffect(() => {
    const options = [];
    for (let i = 2013; i <= new Date().getFullYear() + 1; i++) {
      options.push(i);
    }
    setYearOptions(options);
  }, []);

  const [questionlibrary, setQuestionlibrary] = useState([]);
  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getDataFetch = async (url = `${API.QUESTIONS}/`) => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.get(url);

      if (status === 200) {
        setQuestionlibrary(res.data);
        setPagRes(res);
        setHasPagination(res.has_more);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataFetch();
  }, []);

  const statusChangeFetch = async (url) => {
    setLoading(true);
    setError(null);

    try {
      await request.put(url);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };
  const onStatusChange = async (item) => {
    await statusChangeFetch(`${API.QUESTIONS_STATUS_CHANGE}/${item.id}`);
    const questionsUpdate = [];
    questionlibrary.map((question) => {
      if (question.id === item.id) {
        question.status = question.status === 'Enable' ? 'Disable' : 'Enable';
      }
      questionsUpdate.push(question);
    });
    setQuestionlibrary(questionsUpdate);
  };

  function changePage(page) {
    getDataFetch(`${API.QUESTIONS}?page=${page}`);
  }

  const updateFetch = async (url, body) => {
    setLoading(true);
    setError(null);

    try {
      await request.post(url, body);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };
  const updateQuestion = async (question, year, id) => {
    const updateQuestion = {
      question: question,
      year: year,
    };
    await updateFetch(`${API.QUESTIONS_UPDATE}/${id}`, updateQuestion);
    const questionsUpdate = [];
    questionlibrary.map((item) => {
      if (id === item.id) {
        item.question = question;
        item.year = year;
      }
      questionsUpdate.push(item);
    });
    setQuestionlibrary(questionsUpdate);
  };

  const deleteFetch = async (url) => {
    setLoading(true);
    setError(null);

    try {
      await request.delete(url);
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };
  const deleteJob = async (id) => {
    await deleteFetch(`${API.QUESTIONS_DELETE}/${id}`);
    const questionsUpdate = [];
    questionlibrary.map((question) => {
      if (question.id !== id) {
        questionsUpdate.push(question);
      }
    });
    setQuestionlibrary(questionsUpdate);
  };

  const [showAddcategory, setShowAddCategory] = useState(false);

  const createFetch = async (url, body) => {
    setLoading(true);
    setError(null);

    try {
      const { data: res, status } = await request.post(url, body);

      if (status === 200) {
        const questionsUpdate = [res];
        questionlibrary.map((item) => {
          questionsUpdate.push(item);
        });
        setQuestionlibrary(questionsUpdate);
      }
    } catch (error: any) {
      setError(error);
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };
  function create(e) {
    e.preventDefault();
    const formChildrens = [...e.target];
    const newQuestion = {
      question: '',
      year: '',
    };
    formChildrens.map((item, index) => {
      if (item.value !== '') {
        if (item.getAttribute('name') === 'question') {
          newQuestion.question = item.value;
        }
        if (item.getAttribute('name') === 'year') {
          newQuestion.year = item.value;
        }
      }
    });
    createFetch(`${API.QUESTIONS_CREATE}`, newQuestion);
  }

  return (
    <Layout>
      <Dashboard title="Question Library">
        {/* <Row className="d-flex justify-content-end mb-4">
					<Col xs="3">
						<Select
							className="select"
							defaultValue={selectedOption}
							onChange={setSelectedOption}
							options={options}
							theme={theme => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: '#d2d6dd',
									primary: '#FFDD05',
								},
							})}
						/>
					</Col>
					<SearchBar/>
                </Row> */}
        <Row className="justify-content-end mb-4">
          <Col xs="8">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setShowAddCategory(true);
              }}
              outline
            >
              Add New Question Library
            </Button>
          </Col>
          <Col xs="4">
            <CustomInput type="select" id="expenseSelect" name="customSelect">
              <option value="">Select...</option>
              {yearOptions.map((item, index) => (
                <option key={`yearOtion${index}`} value={item}>
                  {item}
                </option>
              ))}
            </CustomInput>
          </Col>
        </Row>

        {showAddcategory && (
          <AddQuestion
            onClose={() => {
              setShowAddCategory(false);
            }}
            createQuestion={(e) => create(e)}
            yearsOptions={yearOptions}
          />
        )}

        {error && <Alert color="danger">Error: {error.message}</Alert>}

        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col>Question</Col>
              <Col xs="2" className="pl-3">
                Year
              </Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>

            {loading && <Loading />}

            {questionlibrary.map((item, index) => (
              <QuestionLibraryRow
                key={`${item.question}_${index}`}
                active={item.status === 'Enable'}
                value={item.id}
                onStatusChange={() => onStatusChange(item)}
                year={item.year}
                yearOptions={yearOptions}
                question={item.question}
                updateQuestion={(question, year) =>
                  updateQuestion(question, year, item.id)
                }
                deleteJob={() => deleteJob(item.id)}
              />
            ))}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => changePage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default QuestionLibrary;
