import styled from 'styled-components';

export const UploadFileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5;
  margin: 20px 0 50px;
`;

export const SelectTypeSanction = styled.select`
  width: 200px;
  height: 28px;
`;
