import {
  styled,
  CustomInput,
  Card,
  Button,
  Container,
  Col,
  Alert,
} from 'src/modules';

export const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  select {
    width: 150px;
    margin-right: 12px;
  }

  button + button {
    margin-left: 12px;
  }
`;

export const StyledSelect = styled(CustomInput)``;

export const WrapperContent = styled(Card)``;

export const Styledbutton = styled(Button)``;

export const Content = styled(Container)`
  max-width: 100% !important;
  overflow-x: scroll;
`;

export const StyledCol = styled(Col)`
  flex-wrap: wrap;
  word-break: break-all;
`;

export const StyledAlert = styled(Alert)``;
