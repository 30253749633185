import React, { useState } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { createCategory, listCategories } from 'src/api/categories';
import { CategoryRow, TableHead, Layout } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  Row,
  Col,
  Toastify,
  Container,
  Spinner,
} from 'src/modules';
import { ERRORS } from 'src/utils/enums/messages.enum';

// markup
const Categories: React.FC = () => {
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');

  const { data, isLoading } = useQuery('listCategories', listCategories, {
    onError: () => {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const { mutate, isLoading: loadingMutation } = useMutation(
    () => createCategory(newName, newDescription),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<Category[]>('listCategories', (cache) =>
          cache ? [data, ...cache] : [data],
        );

        setModal(false);
      },
      onError: () => {
        Toastify.toast.error(ERRORS.GENERIC_ERROR);
      },
    },
  );

  const modalToggle = () => setModal((modal) => !modal);

  return (
    <Layout>
      <Dashboard title="Categories">
        <Row className="justify-content-end mb-4">
          <Col>
            <Button color="primary" type="button" onClick={modalToggle}>
              Add New Category
            </Button>
          </Col>
        </Row>
        <Card>
          <Container className="mw-100">
            <TableHead>
              <Col xs="3">Name</Col>
              <Col>Description</Col>
              <Col xs="1" className="d-flex justify-content-center">
                Status
              </Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>
            {isLoading && <Loading />}
            {data?.map((item) => (
              <CategoryRow key={`category_${item.id}`} item={item} />
            ))}
          </Container>
        </Card>
      </Dashboard>
      <Modal isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>Add a new category</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={newName}
                onChange={(event) => setNewName(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Description</Label>
              <Input
                type="textarea"
                rows="4"
                name="description"
                value={newDescription}
                onChange={(event) => setNewDescription(event.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {loadingMutation ? (
            <Spinner />
          ) : (
            <Button
              color="primary"
              type="button"
              onClick={() => mutate()}
              size="lg"
              disabled={!newName || !newDescription}
            >
              Create
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default Categories;
