import React, { useEffect, useState } from 'react';

import { Trash } from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';

import { deleteIncome } from 'src/api/incomes';
import { Form, Input, Toastify } from 'src/modules';
import { getYearsList, formatCurrency, ERRORS, SUCCESS } from 'src/utils';

import ButtonIcon from '../ButtonIcon';
import TaxIncomeAddToIncome from '../TaxIncomeAddToIncome';

import { Badge } from './styles';

const TaxIncomeDocument: React.FC = ({
  item,
  index,
  selectItem,
  moveToYear,
  active,
  setReloadCustomerIncomes,
  activeOtherFiles,
  activePiechartPaye,
  activePiechartSelf,
  activeRefunded,
}) => {
  const queryClient = useQueryClient();

  const [refunded, setRefunded] = useState('Refunded');
  const [othersFiles, setOthersFiles] = useState('Other');
  const [piechartPaye, setPiechartPaye] = useState('Paye');
  const [piechartSelf, setPiechartSelf] = useState('Self');

  useEffect(() => {
    setRefunded(activeRefunded ? 'Refunded' : '');
    setOthersFiles(activeOtherFiles ? 'Other' : '');
    setPiechartPaye(activePiechartPaye ? 'Paye' : '');
    setPiechartSelf(activePiechartSelf ? 'Self' : '');
  }, [
    activeRefunded,
    activeOtherFiles,
    activePiechartPaye,
    activePiechartSelf,
  ]);

  const incomeTypeList = [refunded, othersFiles, piechartPaye, piechartSelf];

  const { mutate: remove } = useMutation(deleteIncome, {
    onSuccess: () => {
      queryClient.invalidateQueries('incomes');

      setReloadCustomerIncomes(true);
      Toastify.toast.success(SUCCESS.GENERIC);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  const onRemove = (event) => {
    event.stopPropagation();
    remove({ id: item.id });
  };

  const onMoveYear = (event) => {
    event.stopPropagation();
    moveToYear(event.target.value, item);
  };

  return incomeTypeList.map((itemSelected) => {
    if (itemSelected === item.type) {
      return (
        <tr
          onClick={() => selectItem(item)}
          className={active ? 'bg-muted' : ''}
        >
          <td>{index + 1}</td>
          <td>
            <Badge type={item.type}>{item.type}</Badge>
            <br />
            {item.title}
          </td>
          <td>{item.paye_ref === '' ? '--' : item.paye_ref}</td>
          <td>{formatCurrency(item.gross_amount)}</td>
          <td>{item.income_date}</td>
          <td className="textr-center">
            <TaxIncomeAddToIncome
              id={item.id}
              status={item.is_add_in_income}
              type={item.type}
            />
          </td>
          <td style={{ width: 100 }}>
            <Form>
              <Input
                type="select"
                name="select"
                id={`moveYear${index}`}
                onChange={onMoveYear}
                value=""
              >
                <option>Year</option>
                {getYearsList().map((el: number, i: number) => (
                  <option key={i} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </Form>
          </td>
          <td>
            <ButtonIcon color="secondary" outline onClick={onRemove}>
              <Trash size={14} className="text-muted" />
            </ButtonIcon>
          </td>
        </tr>
      );
    }
  });
};

export default TaxIncomeDocument;
