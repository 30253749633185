import React from "react";
import { Input } from "src/modules";

const JobTools: React.FC = () => {
  return (
    <div className="d-flex flex-row align-items-center mt-2 mb-2">
      <div className="pl-4 mr-4">Job Tools</div>
      <div className="mr-3">
        <Input
          className=""
          bsSize="lg"
          type="text"
          name="toolname"
          placeholder="Tool Name"
        />
      </div>
      <div className="">
        <Input
          className="mr-2"
          bsSize="lg"
          type="text"
          name="toolcost"
          placeholder="Tool Cost"
        />
      </div>
    </div>
  );
};

export default JobTools;
