import { styled } from 'src/modules';

interface Props {
  size?: number;
  isOpen?: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  /* right: ${(props) => (!props.isOpen ? '6px' : 'initial')}; */
`;

export const Image = styled.img<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`;
