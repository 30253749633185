import React, { memo, useState } from "react";

import { TStatus } from "src/models";
import { CustomInput } from "src/modules";
import request from "src/api/request";
import ResponseError from "src/api/ResponseError";

interface StatusToggleProps {
  initStatus: TStatus;
  url: string;
  id: number;
}

const StatusToggle = ({ initStatus, url, id }: StatusToggleProps) => {
  const [active, setActive] = useState<boolean>(initStatus === "Enable");

  const onToggleStatus = async (event: any) => {
    const { checked } = event.target;
    setActive(checked);

    try {
      const { status } = await request.put(`${url}/${id}`);

      if (status !== 200) {
        setActive(!checked);
      }
    } catch (error: any) {
      throw new ResponseError(error);
    }
  };

  return (
    <CustomInput
      type="checkbox"
      id={`status_${id}`}
      name={`status_${id}`}
      checked={active}
      onChange={onToggleStatus}
    />
  );
};

export default memo(StatusToggle);
