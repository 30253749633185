import React, { useState } from 'react';

import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Row, Col, Button, CustomInput } from 'src/modules';

import ButtonIcon from '../ButtonIcon';
import TableRow from '../TableRow';

const JobCategoriesRow: React.FC = ({
  nameJobCategory,
  onStatusChange,
  active,
  value,
  updateName,
  deleteJob,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(nameJobCategory);

  function toggle() {
    setDisabled(!disabled);
  }

  function update() {
    setDisabled(!disabled);
    updateName(name);
  }

  return (
    <TableRow className="align-items-center">
      <Col>
        <Input
          type="text"
          name="name_category"
          defaultValue={nameJobCategory}
          disabled={disabled}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs="1" className="d-flex align-items-center justify-content-center">
        <CustomInput
          type="checkbox"
          id={`input${value}`}
          name="customSwitch"
          checked={active}
          onChange={onStatusChange}
        />
      </Col>
      <Col xs="2 d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center">
          <ButtonIcon
            onClick={disabled ? () => toggle() : () => update()}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon icon={disabled ? faPen : faCheck} />
          </ButtonIcon>
          <ButtonIcon
            onClick={deleteJob}
            color={'primary'}
            className="table-button"
          >
            <FontAwesomeIcon color="" icon={faTrash} />
          </ButtonIcon>
        </Row>
      </Col>
    </TableRow>
  );
};

export default JobCategoriesRow;
