import request from '../request';

export const getHistorySanctions = async () => {
  const { data } = await request.get(`/sanction/history-of-sanctions`);

  return data.data;
};

export const addSanctionFile = async (formData) => {
  const { data } = await request.post(`/sanction/add-file-sanction`, formData);

  return data.data;
};
