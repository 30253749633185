import request from './request';

export const getImportedFile = async (
  name: string,
): Promise<TaskHistoryData[]> => {
  request;
  const { data } = await request.get(`/etoro/list-accounts?per_page=15`);

  return data.data;
};

export const getClosePositions = async (id: number) => {
  request;
  const { data } = await request.get(
    `/etoro/list-closed-positions?account_summary_id=${id}&per_page=500`,
  );

  return data.data;
};

export const uploadToroFile = async (formData: any) => {
  const { data } = await request.post(`/etoro/import`, formData);
  return data.data;
};
