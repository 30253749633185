import React, { useEffect } from 'react';

import { useResizable } from 'react-resizable-layout';

import { Layout } from 'src/components';
import ChatContent from 'src/components/messages/ChatContent';
import ChatList from 'src/components/messages/ChatList';
import FilesContent from 'src/components/messages/FilesContent';
import { useLocalStorage } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { observer } from 'src/modules';
import { Container, Wrapper, DragBar } from 'src/styles/pages/messages/styles';

const POSITION_DEFAULT = 380;

const Messages: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useLocalStorage('isOpenMenu', false);

  const { isDragging, position, splitterProps } = useResizable({
    axis: 'x',
    initial: POSITION_DEFAULT,
    min: POSITION_DEFAULT,
    max: 600,
    reverse: true,
  });

  const [positionMenu, setPositionMenu] = useLocalStorage(
    'positionMenu',
    position,
  );

  useEffect(() => {
    if (position !== POSITION_DEFAULT) {
      setPositionMenu(position);
    }
  }, [position]);

  const { onMouseDown } = splitterProps;

  return (
    <Layout>
      <Dashboard
        title="Message Center"
        noPadding
        mainClassName="height100vh"
        contentClassName="overflow-hidden"
      >
        <Container>
          <ChatList isOpen={!isOpenMenu} />
          <Wrapper>
            <ChatContent
              isOpenMenu={!!isOpenMenu}
              handleOpenMenu={setIsOpenMenu}
            />
            <DragBar
              isOpenMenu={!!isOpenMenu}
              isDragging={isDragging}
              onMouseDown={splitterProps.onMouseDown}
            />
            <FilesContent
              isOpenMenu={!!isOpenMenu}
              isDragging={isDragging}
              width={positionMenu || POSITION_DEFAULT}
            />
          </Wrapper>
        </Container>
      </Dashboard>
    </Layout>
  );
};

export default observer(Messages);
