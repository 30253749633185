export type TStatusInfoLabel =
  | 'None'
  | 'Income'
  | 'Expenses'
  | 'OlderYears'
  | 'UtrNumber'
  | 'CodeOrdered'
  | 'CodeKeyed'
  | 'Quote'
  | 'Other'
  | 'ReadyToSubmit'
  | 'DeedOfAssignment'
  | 'QuoteAccepted'
  | 'QuoteGiven'
  | 'OpenYears'
  | 'YearsOpened'
  | 'TookRefund'
  | 'EarlyPayoutStillOwed'
  | 'SecurityChecks'
  | 'QuoteRejected'
  | 'IntroCallDone'
  | 'ChaseRefund'
  | 'NotEligible'
  | 'TaxBill'
  | 'Paid'
  | 'Penalty'
  | 'NeedsToPay'
  | 'MoreInfo'
  | 'Sales'
  | 'Open2022'
  | 'TaxCode'
  | 'PayeHistoryWaiting';

// for looping purposes only
export const StatusInfoLabels: TStatusInfoLabel[] = [
  'None',
  'Income',
  'Expenses',
  'OlderYears',
  'UtrNumber',
  'CodeOrdered',
  'CodeKeyed',
  'Quote',
  'Other',
  'ReadyToSubmit',
  'DeedOfAssignment',
  'QuoteAccepted',
  'QuoteGiven',
  'OpenYears',
  'YearsOpened',
  'TookRefund',
  'EarlyPayoutStillOwed',
  'SecurityChecks',
  'QuoteRejected',
  'IntroCallDone',
  'ChaseRefund',
  'NotEligible',
  'TaxBill',
  'Paid',
  'Penalty',
  'NeedsToPay',
  'MoreInfo',
  'Sales',
  'Open2022',
  'PayeHistoryWaiting',
  'TaxCode',
];
