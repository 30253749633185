import { INotificationItem } from 'src/models';

import request from '../request';

export const getNotifications = async (
  amount = '',
): Promise<INotificationItem[]> => {
  const { data } = await request.get(`/notifications?per_page=${amount}`);

  return data.data;
};

export const getNotificationsCounter =
  async (): Promise<NotificationsCounter> => {
    const { data } = await request.get(`/notifications/counter`);

    return data;
  };

export const toggleRead = async ({
  taskHistoryId,
  userId,
}: {
  taskHistoryId: number;
  userId: number;
}): Promise<INotificationItem> => {
  const { data } = await request.post(
    `/users/${userId}/task-history/${taskHistoryId}/read-unread`,
  );

  return data.data;
};

export const fetchNotifications = async (
  query?: string,
): Promise<NotificationsTypes> => {
  if (query) {
    const { data } = await request.get(`/notifications${query}`);
    return data;
  } else {
    const { data } = await request.get(`/notifications`);
    return data;
  }
};
