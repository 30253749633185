import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { createSA103S, getSA103S, updateSA103S } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import { onInputChange, onCheckboxChange, onCurrencyInputChange } from '../../utils';

import {
  CheckboxLabel,
  FormContainer,
  InputForm,
  SubmitButton,
  Title,
} from '../../styles';

import CurrencyInput from 'react-currency-masked-input';

import { FormSA103s } from '../../FormState';

type Props = {
  sa103SformId: number;
  taxYear: number;
  taxReturnId: number;
  view:boolean;
  onClick?: ( e: any) => void,
};

export const SA103s = ({
  sa103SformId,
  taxYear,
  taxReturnId,
  view,
  onClick
}: Props) => {
  const [formSA103SState, setformSA103SState] =
    useState<FormSA103sType>(FormSA103s);

  const { data, isLoading, refetch } = useQuery(
    'getSA103S',
    () => getSA103S(sa103SformId),
    {
      enabled: false,
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (sa103SformId) {
      refetch();
    }
  }, [sa103SformId]);

  const { mutate: mutationCreate103S } = useMutation(createSA103S, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  const { mutate: mutationUpdateSA103S } = useMutation(updateSA103S, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformSA103SState(data.data);
      } else {
        setformSA103SState(FormSA103s);
      }
    }
  }, [isLoading]);

  const onSubmitForm = () => {
    if (sa103SformId === 0) {
      const formData = {
          ...formSA103SState,
          tax_return_id: taxReturnId,
      };

      mutationCreate103S(formData);
    } else {
      const formData = {
          ...formSA103SState,
          sa103s_form_id: sa103SformId,
      };

      mutationUpdateSA103S(formData);
    }
  };

  return (
    <>
        <div className="row">
            <div className="col-lg-6 col-md-12">
                <Title>SA103S
                    {(view) ? '- View' : ''}
                </Title>
                {/* <Subtitle>
                    * Hidden input: tax return id for create form / sa103s_form_id for
                    update form.
                </Subtitle> */}
                <FormContainer>
                    <Title>Bussiness Details</Title>
                    <InputForm>
                    <Label>Business Description (required)</Label>
                    <Input
                        type="text"
                        bsSize="lg"
                        value={formSA103SState.business_description}
                        onChange={(e) =>
                        onInputChange(e, formSA103SState, setformSA103SState)
                        }
                        id="business_description"
                        name="business_description"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Business Address Postcode</Label>
                    <Input
                        type="text"
                        bsSize="lg"
                        value={formSA103SState.business_address_postcode}
                        onChange={(e) =>
                        onInputChange(e, formSA103SState, setformSA103SState)
                        }
                        id="business_address_postcode"
                        name="business_address_postcode"
                    />
                    </InputForm>
                    <CheckboxLabel>
                    <input
                        type="checkbox"
                        value={formSA103SState.change_of_business_details}
                        checked={formSA103SState.change_of_business_details}
                        onChange={(e) =>
                        onCheckboxChange(e, formSA103SState, setformSA103SState)
                        }
                        name="change_of_business_details"
                        id="change_of_business_details"
                    />
                    <Label lg>Change Of Business Details</Label>
                    </CheckboxLabel>
                    <CheckboxLabel>
                    <input
                        type="checkbox"
                        value={formSA103SState.foster_etc_carer_indicator}
                        checked={formSA103SState.foster_etc_carer_indicator}                        
                        onChange={(e) =>
                        onCheckboxChange(e, formSA103SState, setformSA103SState)
                        }
                        name="foster_etc_carer_indicator"
                        id="foster_etc_carer_indicator"
                    />
                    <Label lg>Foster Etc Carer Indicator</Label>
                    </CheckboxLabel>
                    <InputForm>
                    <Label>Date Business Started</Label>
                    <Input
                        id="date_business_started"
                        name="date_business_started"
                        value={formSA103SState.date_business_started}
                        onChange={(e) =>
                        onInputChange(e, formSA103SState, setformSA103SState)
                        }
                        type="date"
                        bsSize="lg"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Date Business Ceased</Label>
                    <Input
                        id="date_business_ceased"
                        name="date_business_ceased"
                        value={formSA103SState.date_business_ceased}
                        onChange={(e) =>
                        onInputChange(e, formSA103SState, setformSA103SState)
                        }
                        type="date"
                        bsSize="lg"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Date Business Books Are Made Up To</Label>
                    <Input
                        id="date_business_books_are_made_up_to"
                        name="date_business_books_are_made_up_to"
                        value={formSA103SState.date_business_books_are_made_up_to}
                        onChange={(e) =>
                        onInputChange(e, formSA103SState, setformSA103SState)
                        }
                        type="date"
                        bsSize="lg"
                    />
                    </InputForm>
                    <CheckboxLabel>
                    <input
                        type="checkbox"
                        value={formSA103SState.cash_basis}
                        checked={formSA103SState.cash_basis}
                        onChange={(e) =>
                        onCheckboxChange(e, formSA103SState, setformSA103SState)
                        }
                        name="cash_basis"
                        id="cash_basis"
                    />
                    <Label lg>Cash Basis</Label>
                    </CheckboxLabel>
                    <InputForm>
                    <Label>Source Reference</Label>
                    <CurrencyInput
                        value={formSA103SState.source_reference}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="source_reference"
                        name="source_reference"
                    />
                    </InputForm>
                    <Title>Bussiness Income</Title>
                    <InputForm>
                    <Label>Turnover</Label>
                    <CurrencyInput
                        value={formSA103SState.turnover}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="turnover"
                        name="turnover"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Other Business Income</Label>
                    <CurrencyInput
                        value={formSA103SState.other_business_income}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="other_business_income"
                        name="other_business_income"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Trading Income Allowance</Label>
                    <CurrencyInput
                        value={formSA103SState.trading_income_allowance}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="trading_income_allowance"
                        name="trading_income_allowance"
                    />
                    </InputForm>
                    <Title>Allowable Business Expenses</Title>
                    <InputForm>
                    <Label>Cost Of Goods</Label>
                    <CurrencyInput
                        value={formSA103SState.cost_of_goods}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="cost_of_goods"
                        name="cost_of_goods"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Car Van And Travel Expenses</Label>
                    <CurrencyInput
                        value={formSA103SState.car_van_and_travel_expenses}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="car_van_and_travel_expenses"
                        name="car_van_and_travel_expenses"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Wages Salaries And Staff Costs</Label>
                    <CurrencyInput
                        value={formSA103SState.wages_salaries_and_staff_costs}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="wages_salaries_and_staff_costs"
                        name="wages_salaries_and_staff_costs"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Rent And Other Property Costs</Label>
                    <CurrencyInput
                        value={formSA103SState.rent_and_other_property_costs}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="rent_and_other_property_costs"
                        name="rent_and_other_property_costs"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Repairs And Maintenance Costs</Label>
                    <CurrencyInput
                        value={formSA103SState.repairs_and_maintenance_costs}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="repairs_and_maintenance_costs"
                        name="repairs_and_maintenance_costs"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Accountancy And Legal Fees</Label>
                    <CurrencyInput
                        value={formSA103SState.accountancy_and_legal_fees}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="accountancy_and_legal_fees"
                        name="accountancy_and_legal_fees"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Interest And Finance Charges</Label>
                    <CurrencyInput
                        value={formSA103SState.interest_and_finance_charges}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="interest_and_finance_charges"
                        name="interest_and_finance_charges"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Phone And Other Office Costs</Label>
                    <CurrencyInput
                        value={formSA103SState.phone_and_other_office_costs}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="phone_and_other_office_costs"
                        name="phone_and_other_office_costs"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Other Allowable Business Expenses</Label>
                    <CurrencyInput
                        value={formSA103SState.other_allowable_business_expenses}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="other_allowable_business_expenses"
                        name="other_allowable_business_expenses"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Total Allowable Expenses</Label>
                    <CurrencyInput
                        value={formSA103SState.total_allowable_expenses}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="total_allowable_expenses"
                        name="total_allowable_expenses"
                    />
                    </InputForm>
                    <Title>Net Profit Or Loss</Title>
                    <InputForm>
                    <Label>Net Profit Or Loss</Label>
                    <CurrencyInput
                        value={formSA103SState.net_profit_or_loss}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="net_profit_or_loss"
                        name="net_profit_or_loss"
                    />
                    </InputForm>
                    <Title>Capital Allowances</Title>
                    <InputForm>
                    <Label>Annual Investment Allowance</Label>
                    <CurrencyInput
                        value={formSA103SState.annual_investment_allowance}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="annual_investment_allowance"
                        name="annual_investment_allowance"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Allowance For Small Balance Of Unrelieved Expenditure</Label>
                    <CurrencyInput
                        value={
                        formSA103SState.allowance_for_small_balance_of_unrelieved_expenditure}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="allowance_for_small_balance_of_unrelieved_expenditure"
                        name="allowance_for_small_balance_of_unrelieved_expenditure"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Zero Emission Car Allowance</Label>
                    <CurrencyInput
                        value={formSA103SState.zero_emission_car_allowance}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="zero_emission_car_allowance"
                        name="zero_emission_car_allowance"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Other Capital Allowances</Label>
                    <CurrencyInput
                        value={formSA103SState.other_capital_allowances}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="other_capital_allowances"
                        name="other_capital_allowances"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>The Structures And Buildings Allowance</Label>
                    <CurrencyInput
                        value={formSA103SState.the_structures_and_buildings_allowance}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="the_structures_and_buildings_allowance"
                        name="the_structures_and_buildings_allowance"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Freeport Structures And Buildings Allowance</Label>
                    <CurrencyInput
                        value={formSA103SState.freeport_structures_and_buildings_allowance}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="freeport_structures_and_buildings_allowance"
                        name="freeport_structures_and_buildings_allowance"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Total Balancing Charges</Label>
                    <CurrencyInput
                        value={formSA103SState.total_balancing_charges}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="total_balancing_charges"
                        name="total_balancing_charges"
                    />
                    </InputForm>
                    <Title>Taxable Profits</Title>
                    <InputForm>
                    <Label>Own Goods And Services</Label>
                    <CurrencyInput
                        value={formSA103SState.own_goods_and_services}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="own_goods_and_services"
                        name="own_goods_and_services"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>SEISS Grant</Label>
                    <CurrencyInput
                        value={formSA103SState.seiss_grant}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="seiss_grant"
                        name="seiss_grant"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Net Business Profit For Tax</Label>
                    <CurrencyInput
                        value={formSA103SState.net_business_profit_for_tax}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="net_business_profit_for_tax"
                        name="net_business_profit_for_tax"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Loss Brought Forward</Label>
                    <CurrencyInput
                        value={formSA103SState.loss_brought_forward}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="loss_brought_forward"
                        name="loss_brought_forward"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Any Other Business Income</Label>
                    <CurrencyInput
                        value={formSA103SState.any_other_business_income}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="any_other_business_income"
                        name="any_other_business_income"
                    />
                    </InputForm>
                    <Title>Profits, Losses, NICs And CIS</Title>
                    <InputForm>
                    <Label>Total Taxable Business Profits</Label>
                    <CurrencyInput
                        value={formSA103SState.total_taxable_business_profits}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="total_taxable_business_profits"
                        name="total_taxable_business_profits"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Net Business Loss For Tax</Label>
                    <CurrencyInput
                        value={formSA103SState.net_business_loss_for_tax}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="net_business_loss_for_tax"
                        name="net_business_loss_for_tax"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Loss Of Year Set Against Other Income</Label>
                    <CurrencyInput
                        value={formSA103SState.loss_of_year_set_against_other_income}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="loss_of_year_set_against_other_income"
                        name="loss_of_year_set_against_other_income"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Loss To Carry Back</Label>
                    <CurrencyInput
                        value={formSA103SState.loss_to_carry_back}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="loss_to_carry_back"
                        name="loss_to_carry_back"
                    />
                    </InputForm>
                    <InputForm>
                    <Label>Total Loss To Carry Forward</Label>
                    <CurrencyInput
                        value={formSA103SState.total_loss_to_carry_forward}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="total_loss_to_carry_forward"
                        name="total_loss_to_carry_forward"
                    />
                    </InputForm>
                    <CheckboxLabel>
                    <input
                        type="checkbox"
                        value={formSA103SState.pay_class_2_nic_voluntarily}
                        checked={formSA103SState.pay_class_2_nic_voluntarily}
                        onChange={(e) =>
                        onCheckboxChange(e, formSA103SState, setformSA103SState)
                        }
                        name="pay_class_2_nic_voluntarily"
                        id="pay_class_2_nic_voluntarily"
                    />
                    <Label lg>Pay Class 2 NIC Voluntarily</Label>
                    </CheckboxLabel>
                    <InputForm>
                    <Label>Class 2 NIC Amount</Label>
                    <CurrencyInput
                        value={formSA103SState.class_2_nic_amount}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="class_2_nic_amount"
                        name="class_2_nic_amount"
                    />
                    </InputForm>
                    <CheckboxLabel>
                    <input
                        type="checkbox"
                        value={formSA103SState.class_4_nic_exempt}
                        checked={formSA103SState.class_4_nic_exempt}
                        onChange={(e) =>
                        onCheckboxChange(e, formSA103SState, setformSA103SState)
                        }
                        name="class_4_nic_exempt"
                        id="class_4_nic_exempt"
                    />
                    <Label lg>Class 4 NIC Exempt</Label>
                    </CheckboxLabel>
                    <InputForm>
                    <Label>Sub Contractors Tax Deduction</Label>
                    <CurrencyInput
                        value={formSA103SState.sub_contractors_tax_deduction}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA103SState, setformSA103SState)}
                        className="form-control-lg form-control"
                        id="sub_contractors_tax_deduction"
                        name="sub_contractors_tax_deduction"
                    />
                    </InputForm>
                </FormContainer>

                    {(view) ?
                    <></> 
                    : <SubmitButton>
                            <Button color="primary" onClick={() => onSubmitForm()} disabled>
                            Save
                            </Button>
                        </SubmitButton>
                    }
            </div>    
        </div>    
    </>
  );
};
