import React from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { editSettings, getSettings } from 'src/api/settings';
import { Layout, TableHead, SettingsRow } from 'src/components';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container } from 'src/modules';

// markup
const Settings: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery('settings', getSettings);

  const { mutate: edit } = useMutation(editSettings, {
    onSuccess: (data) => {
      queryClient.setQueryData<SettingsValue[] | undefined>(
        'settings',
        (cache) =>
          cache?.map((item) =>
            item.id === data.id ? { ...item, ...data } : item,
          ),
      );
    },
  });

  return (
    <Layout>
      <Dashboard title="Settings">
        <Card>
          <Container className="mw-100">
            {isLoading && <Loading />}
            <TableHead>
              <Col>Title</Col>
              <Col>Key</Col>
              <Col>Value</Col>
              <Col xs="2" className="d-flex justify-content-center">
                Action
              </Col>
            </TableHead>
            {data?.map(({ id, field_name, key_text, value }) => (
              <SettingsRow
                id={id}
                key={key_text}
                title={field_name}
                dbKey={key_text}
                value={value}
                onEdit={edit}
              />
            ))}
          </Container>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default Settings;
