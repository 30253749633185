import React from 'react';

import { window } from 'browser-monads';
import { navigate } from 'gatsby';

import { RouteComponentProps } from '@reach/router';

import { Storage } from 'src/services';

type Props = {
  Component:
    | React.FC<RouteComponentProps<any> | undefined>
    | React.ComponentClass<RouteComponentProps<any> | undefined>;
  path: string;
};

const PrivateRoute: React.FC<Props> = ({ Component, path, ...rest }) => {
  const isLogged = Storage.getToken();

  if (!isLogged) {
    const fullPath = window.location.href;
    const [, url] = fullPath.split('/app/');
    Storage.setRedirectPath(url);
    navigate(`/login`);
    return null;
  }

  return <Component path={path} {...rest} />;
};

export default PrivateRoute;
