import React, { memo, useState } from 'react';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import request from 'src/api/request';
import ResponseError from 'src/api/ResponseError';
import { Spinner } from 'src/modules';

import ButtonIcon from '../ButtonIcon';

type Props = {
  url: string;
  id: number;
  onDelete?(): void;
};

const ButtonDelete: React.FC<Props> = ({ url, id, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      const { status } = await request.delete(`${url}/${id}`);

      if (status === 200 && onDelete) {
        onDelete();
      }
    } catch (error: any) {
      throw new ResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonIcon color="primary" className="table-button" onClick={handleDelete}>
      {!loading && <FontAwesomeIcon icon={faTrash} />}
      {loading && <Spinner color="danger" />}
    </ButtonIcon>
  );
};

export default memo(ButtonDelete);
