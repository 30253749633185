import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { listAllAdmins } from 'src/api/admin';
import { Layout, TableHead, CustomPagination, TableRow } from 'src/components';
import { TableColumn } from 'src/components/InboxTemplateRow/styles';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Col, Container, Toastify } from 'src/modules';
import store from 'src/stores';
import {
  AssistantImage,
  AssistantText,
  FirstTableHeader,
  FirstTableRow,
  LeftSideContainer,
} from 'src/styles/pages/customers/customers-per-page.styles';

const CustomersPerPage = () => {
  const customerStore = store.customer;

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);
  const [admins, setAdmins] = useState([]);
  const [totalUsersByAssistantList, setTotalUsersByAssistantList] = useState<
    Array<object>
  >([]);

  // const [assistantsList, setAssistantsList] = useState<AdminData[]>([]);

  const { isLoading: isLoadingAdmins } = useQuery(
    ['listAllAdmins', nextPage],
    listAllAdmins,
    {
      onSuccess: ({
        data,
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setAdmins(data);
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError({ response }) {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (!isLoadingAdmins) {
      const resultTotalUsersInfoByAssistant = admins.map((admin) => {
        return customerStore.getTotalUsersInfoByAssistant(admin.id);
      });

      const resultUsersByAssistant = admins.map((admin) => {
        return customerStore.getUsersByAssistant(admin.id);
      });

      let usersByAssistantList: Array<object>;

      Promise.all([...resultUsersByAssistant]).then((result) => {
        usersByAssistantList = result;
      });

      Promise.all([...resultTotalUsersInfoByAssistant]).then((result) => {
        const resultList = result;

        if (usersByAssistantList) {
          resultList.map((item, index) => {
            resultList[index].usersByAssistant = usersByAssistantList[index];
          });

          setTotalUsersByAssistantList(resultList);
        } else {
          setTotalUsersByAssistantList(resultList);
        }
      });
    }
  }, [isLoadingAdmins]);

  return (
    <Layout>
      <Dashboard title="Customers per page">
        <Card>
          <Container className="mw-100">
            {isLoadingAdmins && <Loading />}

            {!isLoadingAdmins &&
              totalUsersByAssistantList &&
              totalUsersByAssistantList.map((user) => {
                return (
                  <div key={user.id}>
                    <div
                      style={{
                        display: 'flex',
                        borderBottom: '1px solid #ddd',
                        paddingBottom: 20,
                      }}
                    >
                      <LeftSideContainer>
                        <AssistantImage
                          src={user.image}
                          alt="tax assistant image"
                        />
                        <AssistantText>{user.name} </AssistantText>
                        <div>
                          <AssistantText>Customers:</AssistantText>{' '}
                          {user.total_users}{' '}
                        </div>
                      </LeftSideContainer>

                      <div style={{ width: '100%' }}>
                        <Container>
                          <FirstTableHeader>
                            <Col xs="9" className="d-flex justify-content-left">
                              Customer Name
                            </Col>
                            <Col xs="3" className="d-flex justify-content-left">
                              Assigned
                            </Col>
                          </FirstTableHeader>

                          {user.usersByAssistant &&
                            user.usersByAssistant.map((item) => {
                              return (
                                <div key={item.id}>
                                  <FirstTableRow>
                                    <TableColumn
                                      xs="9"
                                      className="d-flex justify-content-left"
                                    >
                                      {item.full_name}
                                    </TableColumn>
                                    <TableColumn
                                      xs="3"
                                      className="d-flex justify-content-left"
                                    >
                                      {item.tax_assistant_assigned}
                                    </TableColumn>
                                  </FirstTableRow>
                                </div>
                              );
                            })}
                        </Container>

                        <Container>
                          <TableHead>
                            <Col xs="3" className="d-flex justify-content-left">
                              Previous Assistant
                            </Col>
                            <Col xs="3" className="d-flex justify-content-left">
                              Customer
                            </Col>
                            <Col xs="3" className="d-flex justify-content-left">
                              Changed by
                            </Col>
                            <Col xs="3" className="d-flex justify-content-left">
                              Date
                            </Col>
                          </TableHead>
                          {user.usersByAssistant &&
                            user.usersByAssistant.map((item) => {
                              return (
                                <TableRow key={item.id}>
                                  <TableColumn
                                    xs="3"
                                    className="d-flex justify-content-left"
                                  >
                                    {
                                      item.tax_assistant_last_changed.previous
                                        .name
                                    }
                                  </TableColumn>
                                  <TableColumn
                                    xs="3"
                                    className="d-flex justify-content-left"
                                  >
                                    {item.full_name}
                                  </TableColumn>
                                  <TableColumn
                                    xs="3"
                                    className="d-flex justify-content-left"
                                  >
                                    {item.tax_assistant_last_changed.by.name}
                                  </TableColumn>
                                  <TableColumn
                                    xs="3"
                                    className="d-flex justify-content-left"
                                  >
                                    {item.tax_assistant_last_changed.date}
                                  </TableColumn>
                                </TableRow>
                              );
                            })}
                        </Container>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Container>
        </Card>

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default CustomersPerPage;
