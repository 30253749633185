import { action, observable } from 'mobx';

import { DeletedAccountsApi } from 'src/api';

export default class DeletedAccountsStores {
  @observable
  deletedAccounts: CustomerAccountDeletesAPI = {
    data: [],
    total_entries: 0,
    total_pages: 0,
    last_page: 0,
    next_page: 0,
    has_more: false,
    next_page_url: '',
    previous_page_url: '',
    message: '',
  };

  @action
  getDeletedAccounts = async (
    page: number,
    tax_year: number,
  ): Promise<void> => {
    const response = await DeletedAccountsApi.getDeletedAccounts(
      page,
      tax_year,
    );
    this.deletedAccounts = response;
  };
}
