import axios from 'axios';

import {
  addAuthHeaders,
  verifyExpiredToken,
  successResponse,
} from './interceptors';

export const request = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 30000,
});

request.interceptors.response.use(successResponse, verifyExpiredToken);
request.interceptors.request.use(addAuthHeaders);

export default request;
