import React, { useState } from 'react';

import { useMutation } from 'react-query';

import { updateMessage } from 'src/api/message-center';
import { observer, Modal, ModalBody, Toastify } from 'src/modules';
import store from 'src/stores';

import Footer from './components/Footer';
import Header from './components/Header';
import MessagesCustomer from './components/MessagesCustomer';
import {
  Buttom,
  ButtonsWrapper,
  Container,
  Content,
  ModalInput,
} from './styles';

interface Props {
  isOpenMenu: boolean;
  handleOpenMenu(isOpen: boolean): void;
  isInboxCmsMessages: boolean;
  tabHeight: number;
}

function ChatContent({
  isOpenMenu,
  handleOpenMenu,
  isInboxCmsMessages,
  tabHeight,
}: Props) {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [conversationId, setConversationId] = useState<number>();

  const { isLoading, mutate: mutateUpdateMessage } = useMutation(
    updateMessage,
    {
      onSuccess: () => {
        store.messageCenter.getConversationMessages(1);
        setEditModalVisible(false);
      },
      onError: ({ message }) => {
        Toastify.toast.error(message);
      },
    },
  );

  const onSendMessage = () => {
    // contentRef?.current?.scroll({ top: contentRef.current.scrollHeight });
    // messageCenterStore.setMessageSent('');
    // messageCenterStore.setConversationEmpty();
    // messageCenterStore.getConversation(DEFAULT_PAGE);
  };

  const handleEditMessage = (conversationId: number, message: string) => {
    setConversationId(conversationId);
    setMessage(message);
    setEditModalVisible(true);
  };

  const handleApprove = () => {
    mutateUpdateMessage({
      converstationBodyId: conversationId as number,
      messageBody: message,
      underReview: false,
    });
  };

  return (
    <Container tabHeight={tabHeight}>
      {!isInboxCmsMessages && (
        <Header isOpenMenu={isOpenMenu} handleOpenMenu={handleOpenMenu} />
      )}
      <Content className="mc-chat-content-container">
        <MessagesCustomer handleEditMessage={handleEditMessage} />
      </Content>
      <Footer onMessageSend={onSendMessage} />
      <Modal centered isOpen={editModalVisible}>
        <ModalBody style={{ padding: 25 }}>
          <ModalInput
            value={message}
            onChange={({ target }) => setMessage(target.value)}
          />
          <ButtonsWrapper>
            <Buttom onClick={() => setEditModalVisible(false)}>CANCEL</Buttom>
            <Buttom contained onClick={handleApprove}>
              {isLoading ? (
                <div className="spinner-border spinner-border-sm text-dark" />
              ) : (
                'APPROVE'
              )}
            </Buttom>
          </ButtonsWrapper>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default observer(ChatContent);
