import React from "react";
import { Container, Row, Col } from "src/modules";

const Footer: React.FC = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a className="text-muted" href="#">
                Support
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted" href="#">
                Help Center
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted" href="#">
                Privacy
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted" href="#">
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <a href="/" className="text-muted">
              AppStack
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
