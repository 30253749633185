import React, { useEffect, useState } from 'react';

import { Button } from 'src/modules';
import { Subtitle } from '../../styles';
import { TaxAvoidanceCard } from './TaxAvoidanceCard';

type Props = {
    id:string,
    name:string,
    value:any,  
    onChange? : (e:any) => void,
};

type tTaxAvoidance = {
    order: number;
    id: number|null;
    tax_avoidance_scheme_reference_number: string;
    expected_advantage_tax_year:string;
}

const DEFAULT_TAX_AVOIDANCE = {
    order: 0,
    id: null,
    tax_avoidance_scheme_reference_number: '',
    expected_advantage_tax_year:''
}

export const TaxAvoidanceScheme = ( { id, name, value, onChange } :Props ) => {

    const [ listTaxAvoidance, setListTaxAvoidance ] = useState<tTaxAvoidance[]>([DEFAULT_TAX_AVOIDANCE]);

  /* useEffect(() => {
        
        if(value && value.length > 0 && listTaxAvoidance != value) setListTaxAvoidance(value);
    }, [value])*/

    useEffect(() => {
        if(listTaxAvoidance != value)
        {
            const event = {
                target: {
                    id,
                    name,
                    value: [...listTaxAvoidance]
                }
            }

            if(onChange) onChange(event);
        }

    }, [listTaxAvoidance])

    function onRemove( taxAvoidance: tTaxAvoidance)
    {
        const newList = listTaxAvoidance.filter( (item:tTaxAvoidance) => {
            return item.order != taxAvoidance.order;
        })
        //reorder
        for (let i = 0; i < newList.length; i++) {
            newList[i].order = i;            
        }
        setListTaxAvoidance([...newList]);
    }

    function onAdd( e:any )
    {
        const list = [... listTaxAvoidance ];
        if( list.length < 3){
            let initial = {...DEFAULT_TAX_AVOIDANCE};
            initial.order = list.length;
            list.push(initial);
            setListTaxAvoidance(list);
        }
    }

    function onUpdate( itemUpdated:tTaxAvoidance )
    {
        const list = [... listTaxAvoidance ];
        const newList = list.map((item:tTaxAvoidance) => {

            if( itemUpdated.order == item.order )
                return itemUpdated;
            else
                return item;
        })
        setListTaxAvoidance(newList);   
        console.log(newList);
    }

    return (
        <>

            {
                listTaxAvoidance && listTaxAvoidance.length > 0 ?
                listTaxAvoidance.map( ( item:any, index:number ) => (
                    <TaxAvoidanceCard key={index} item={item} onRemove={onRemove} onUpdate={onUpdate}/>
                ) ):<></>
            }
            

            <Button onClick={onAdd} color="success">Add Tax Avoidance Scheme</Button>
            <Subtitle>* There can be 0 to 3 Tax Avoidance Schemes</Subtitle>

        </>
    )

}