import React from 'react';

import { Layout } from 'src/components';

import Dashboard from '../layouts/Dashboard';

const IndexPage = () => {
  return (
    <Layout>
      <Dashboard title="Dashboard" />
    </Layout>
  );
};

export default IndexPage;
