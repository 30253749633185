import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { getStaffShift } from 'src/api/dashboard';
import { Layout } from 'src/components';
import { Filters } from 'src/components/dashboard';
import ShiftsTable from 'src/components/ShiftsTable';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { observer } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { formatDate } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';
import { getStaffDataShift, StaffChartData } from 'src/utils/staffDataset';

const Shift: React.FC = () => {
  const [dataShifts, setDataShifts] = useState<StaffChartData>();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const TABS = [{ label: 'Shifts', value: 'shifts', active: true }];

  const [activeTab, setActiveTab] = useState('');

  const loadUser = async () => {
    const userData = await Storage.getUser();
    const isSuperAdmin = handleSuperAdminRole(userData.role_id);

    setIsSuperAdmin(isSuperAdmin);
  };

  const { isLoading } = useQuery(
    [
      'getStaffShift',
      formatDate(store.dashboard.filters.startDate, 'DD/MM/YYYY'),
      formatDate(store.dashboard.filters.endDate, 'DD/MM/YYYY'),
      store.dashboard.filters.period,
    ],
    getStaffShift,
    {
      retry: false,
      onSuccess: (res: any) => {
        const charDataShifts: any = getStaffDataShift(res);
        setDataShifts(charDataShifts);
        if (!activeTab) setActiveTab('shifts');
      },
    },
  );

  const getTabContent = () => {
    switch (activeTab) {
      case 'shifts':
        return <ShiftsTable dataShifts={dataShifts}></ShiftsTable>;

      default:
        return null;
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Layout>
      <Dashboard title="Staff Shift">
        <Filters />
        {isLoading && <Loading />}
        {isSuperAdmin && dataShifts && (
          <>
            <div className="tab mt-4">
              <ul className="nav nav-tabs">
                {TABS.map((item) => (
                  <li key={item.value} className="nav-item position-relative">
                    <a
                      onClick={() => item.active && setActiveTab(item.value)}
                      className={`nav-link ${
                        activeTab === item.value ? 'active' : ''
                      } ${!item.active && 'disabled-tab'}`}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
              {getTabContent()}
            </div>
          </>
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(Shift);
