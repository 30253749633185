import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { SUCCESS } from 'src/utils';

import { createSA101, updateSA101, getSA101 } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify, CustomInput } from 'src/modules';

import {
    Card,
    CheckboxLabel,
    FormContainer,
    InputForm,
    SubmitButton,
    Subtitle,
    Title,
    Select
  } from '../../styles';

import CurrencyInput from 'react-currency-masked-input';

import { FormSA101 } from '../../FormState';

import { onInputChange, onCheckboxChange, onCurrencyInputChange } from '../../utils';
import { TaxAvoidanceScheme } from './TaxAvoidanceScheme';

type Props = {
    sa101formId: number;
    taxYear: number,
    taxReturnId: number,
    onClick?: ( e: any) => void,
  };
  
  export const SA101 = ({
    sa101formId,
    taxYear,
    taxReturnId, 
    onClick
  }: Props) => {
    const [formSA101State, setformSA101State] = useState<FormSA101Type>(FormSA101);
  
    const { data, isLoading, refetch } = useQuery(
      'getSA101',
      () => getSA101(sa101formId),
      {
        enabled: false,
        onError: ({ response }) => {
          Toastify.toast.error(response.data.message);
        },
      },
    );

    useEffect(() => {
        if (sa101formId) {
          refetch();
        }
      }, [sa101formId]);
    
      const { mutate: mutationCreate101 } = useMutation(createSA101, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
          if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
      });
    
      const { mutate: mutationUpdateSA101 } = useMutation(updateSA101, {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
          if(onClick) onClick({ target:{ id:'listFormsTaxReturn', value: taxReturnId }});
        },
        onError({ response }) {
          Toastify.toast.error(response?.data?.message);
        },
      });
    
      useEffect(() => {
        if (!isLoading) {
          if (data) {
            setformSA101State(data.data);
          } else {
            setformSA101State(FormSA101);
          }
        }
      }, [isLoading]);
    
      const onSubmitForm = () => {
        if (sa101formId === 0) {
          const formData = {
              ...formSA101State,
              tax_return_id: taxReturnId,
            };
    
          mutationCreate101(formData);
        } else {
          const formData = {
                ...formSA101State,
                sa101_form_id: sa101formId,
                tax_return_id: taxReturnId,
          };
    
          mutationUpdateSA101(formData);
        }
      };
    

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <Title>SA101</Title>
                    <Subtitle>
                        * Hidden input: tax_return_id for create form / sa101_form_id for update form.
                    </Subtitle>
                    <FormContainer>
                        <Title>Gilt Edge Securities Interest</Title>
                        <InputForm>
                            <Label>Net Gilt Interest</Label>
                            <CurrencyInput
                                type="number"
                                bsSize="lg"
                                id="net_gilt_interest"
                                name="net_gilt_interest"
                                value={formSA101State.net_gilt_interest}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Tax Taken Off Gilt Interest</Label>
                            <CurrencyInput
                                type="number"
                                bsSize="lg"
                                id="tax_taken_off_gilt_interest"
                                name="tax_taken_off_gilt_interest"
                                value={formSA101State.tax_taken_off_gilt_interest}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Gross Gilt Interest</Label>
                            <CurrencyInput
                                type="number"
                                bsSize="lg"
                                id="gross_gilt_interest"
                                name="gross_gilt_interest"
                                value={formSA101State.gross_gilt_interest}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>




                        <Title>Life Insurance Gains</Title>
                        <Card>
                            <Title>Life Insurance Gains Tax Treated As Paid</Title>
                            <InputForm>
                                <Label>Amount Of Gain</Label>
                                <CurrencyInput
                                id="life_insurance_gains_tax_treated_as_paid_amount_of_gain"
                                name="life_insurance_gains_tax_treated_as_paid_amount_of_gain"
                                value={formSA101State.life_insurance_gains_tax_treated_as_paid_amount_of_gain}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Number Of Years</Label>
                                <Input
                                id="life_insurance_gains_tax_treated_as_paid_number_of_years"
                                name="life_insurance_gains_tax_treated_as_paid_number_of_years"
                                value={formSA101State.life_insurance_gains_tax_treated_as_paid_number_of_years}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                        </Card>
                
                        <Card>
                            <Title>Life Insurance Gains No Tax Treated As Paid</Title>
                            <InputForm>
                                <Label>Amount Of Gain</Label>
                                <CurrencyInput
                                id="life_insurance_gains_no_tax_treated_as_paid_amount_of_gain"
                                name="life_insurance_gains_no_tax_treated_as_paid_amount_of_gain"
                                value={formSA101State.life_insurance_gains_no_tax_treated_as_paid_amount_of_gain}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Number Of Years</Label>
                                <Input
                                id="life_insurance_gains_no_tax_treated_as_paid_number_of_years"
                                name="life_insurance_gains_no_tax_treated_as_paid_number_of_years"
                                value={formSA101State.life_insurance_gains_no_tax_treated_as_paid_number_of_years}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                        </Card>

                        <Card>
                            <Title>Life Insurance Gains From Voided ISAs</Title>
                            <InputForm>
                                <Label>Amount Of Gain</Label>
                                <CurrencyInput
                                id="life_insurance_gains_from_voided_isas_amount_of_gain"
                                name="life_insurance_gains_from_voided_isas_amount_of_gain"
                                value={formSA101State.life_insurance_gains_from_voided_isas_amount_of_gain}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Number Of Years</Label>
                                <Input
                                id="life_insurance_gains_from_voided_isas_number_of_years"
                                name="life_insurance_gains_from_voided_isas_number_of_years"
                                value={formSA101State.life_insurance_gains_from_voided_isas_number_of_years}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                        </Card>

                        <InputForm>
                            <Label>Tax Taken Off Gains From Voided ISAs</Label>
                            <CurrencyInput
                                id="tax_taken_off_gains_from_voided_isas"
                                name="tax_taken_off_gains_from_voided_isas"
                                value={formSA101State.tax_taken_off_gains_from_voided_isas}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Deficiency Relief</Label>
                            <CurrencyInput
                                id="deficiency_relief"
                                name="deficiency_relief"
                                value={formSA101State.deficiency_relief}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>


                        <Title>Stock Distributions And Loans Written Off</Title>

                        <InputForm>
                            <Label>Stock Dividends</Label>
                            <CurrencyInput
                                id="stock_dividends"
                                name="stock_dividends"
                                value={formSA101State.stock_dividends}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Bonus Issues Of Securities And Redeemable Shares</Label>
                            <CurrencyInput
                                id="bonus_issues_of_securities_and_redeemable_shares"
                                name="bonus_issues_of_securities_and_redeemable_shares"
                                value={formSA101State.bonus_issues_of_securities_and_redeemable_shares}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Close Company Loans Written Off Or Released</Label>
                            <CurrencyInput
                                id="close_company_loans_written_off_or_released"
                                name="close_company_loans_written_off_or_released"
                                value={formSA101State.close_company_loans_written_off_or_released}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>


                        <Title>Business Taxed Income</Title>

                        <InputForm>
                            <Label>Post Cessation Or Other Business Receipts</Label>
                            <CurrencyInput
                                id="post_cessation_or_other_business_receipts"
                                name="post_cessation_or_other_business_receipts"
                                value={formSA101State.post_cessation_or_other_business_receipts}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Tax Year Income To Be Taxed</Label>
                            <Input
                                type="text"
                                bsSize="lg"
                                id="tax_year_income_to_be_taxed"
                                name="tax_year_income_to_be_taxed"
                                value={formSA101State.tax_year_income_to_be_taxed}
                                onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                                }
                            />
                        </InputForm>


                        <Title>Shares Employment Compensations And Deductions</Title>

                        <InputForm>
                            <Label>Share Schemes Taxable Amount</Label>
                            <CurrencyInput
                                id="share_schemes_taxable_amount"
                                name="share_schemes_taxable_amount"
                                value={formSA101State.share_schemes_taxable_amount}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>

                        <Card>
                            <Title>Lump Sums</Title>
                            <InputForm>
                                <Label>Lump Sum Salaries And Other Payments</Label>
                                <CurrencyInput
                                id="lump_sum_salaries_and_other_payments"
                                name="lump_sum_salaries_and_other_payments"
                                value={formSA101State.lump_sum_salaries_and_other_payments}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Lump Sums Or Benefits From Retirement Schemes</Label>
                                <CurrencyInput
                                id="lump_sums_or_benefits_from_retirement_schemes"
                                name="lump_sums_or_benefits_from_retirement_schemes"
                                value={formSA101State.lump_sums_or_benefits_from_retirement_schemes}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Redundancy And Other Compensation Payments</Label>
                                <CurrencyInput
                                id="redundancy_and_other_compensation_payments"
                                name="redundancy_and_other_compensation_payments"
                                value={formSA101State.redundancy_and_other_compensation_payments}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Tax Taken Off</Label>
                                <CurrencyInput
                                id="tax_taken_off"
                                name="tax_taken_off"
                                value={formSA101State.tax_taken_off}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                                />
                            </InputForm>
                        </Card>




                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                value={formSA101State.tax_taken_off_lump_sums_left_blank}
                                checked={formSA101State.tax_taken_off_lump_sums_left_blank}
                                onChange={(e) =>
                                    onCheckboxChange(e, formSA101State, setformSA101State)
                                }
                                name="tax_taken_off_lump_sums_left_blank"
                                id="tax_taken_off_lump_sums_left_blank"
                            />
                            <Label lg>Tax Taken Off Lump Sums Left Blank</Label>
                        </CheckboxLabel>

                        <InputForm>
                            <Label>Retirement And Other Exemptions</Label>
                                <CurrencyInput
                                id="retirement_and_other_exemptions"
                                name="retirement_and_other_exemptions"
                                value={formSA101State.retirement_and_other_exemptions}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Compensation And Lump Sum Exemption</Label>
                                <CurrencyInput
                                id="compensation_and_lump_sum_exemption"
                                name="compensation_and_lump_sum_exemption"
                                value={formSA101State.compensation_and_lump_sum_exemption}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Disability And Foreign Service Deduction</Label>
                                <CurrencyInput
                                id="disability_and_foreign_service_deduction"
                                name="disability_and_foreign_service_deduction"
                                value={formSA101State.disability_and_foreign_service_deduction}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Seafarers Earnings Deduction</Label>
                                <CurrencyInput
                                id="seafarers_earnings_deduction"
                                name="seafarers_earnings_deduction"
                                value={formSA101State.seafarers_earnings_deduction}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Non UK Taxable Foreign Earnings</Label>
                                <CurrencyInput
                                id="non_uk_taxable_foreign_earnings"
                                name="non_uk_taxable_foreign_earnings"
                                value={formSA101State.non_uk_taxable_foreign_earnings}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Foreign Tax No Foreign Tax Credit Relief Claim</Label>
                                <CurrencyInput
                                id="foreign_tax_no_foreign_tax_credit_relief_claim"
                                name="foreign_tax_no_foreign_tax_credit_relief_claim"
                                value={formSA101State.foreign_tax_no_foreign_tax_credit_relief_claim}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Overseas Pension Exempt Employer Contributions</Label>
                                <CurrencyInput
                                id="overseas_pension_exempt_employer_contributions"
                                name="overseas_pension_exempt_employer_contributions"
                                value={formSA101State.overseas_pension_exempt_employer_contributions}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>UK Patent Royalty Payments Made</Label>
                                <CurrencyInput
                                id="uk_patent_royalty_payments_made"
                                name="uk_patent_royalty_payments_made"
                                value={formSA101State.uk_patent_royalty_payments_made}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>






                        <Title>Other Tax Reliefs</Title>
                        <InputForm>
                            <Label>Venture Capital Trust Share Subscriptions</Label>
                                <CurrencyInput
                                id="venture_capital_trust_share_subscriptions"
                                name="venture_capital_trust_share_subscriptions"
                                value={formSA101State.venture_capital_trust_share_subscriptions}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Enterprise Investment Scheme Share Subscriptions</Label>
                                <CurrencyInput
                                id="enterprise_investment_scheme_share_subscriptions"
                                name="enterprise_investment_scheme_share_subscriptions"
                                value={formSA101State.enterprise_investment_scheme_share_subscriptions}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Community Investment Trust Relief</Label>
                                <CurrencyInput
                                id="community_investment_trust_relief"
                                name="community_investment_trust_relief"
                                value={formSA101State.community_investment_trust_relief}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Annuities And Annual Payments</Label>
                                <CurrencyInput
                                id="annuities_and_annual_payments"
                                name="annuities_and_annual_payments"
                                value={formSA101State.annuities_and_annual_payments}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Qualifying Loan Interest</Label>
                                <CurrencyInput
                                id="qualifying_loan_interest"
                                name="qualifying_loan_interest"
                                value={formSA101State.qualifying_loan_interest}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Post Cessation And Other Losses</Label>
                                <CurrencyInput
                                id="post_cessation_and_other_losses"
                                name="post_cessation_and_other_losses"
                                value={formSA101State.post_cessation_and_other_losses}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Maintenance Or Alimony Payments</Label>
                                <CurrencyInput
                                id="maintenance_or_alimony_payments"
                                name="maintenance_or_alimony_payments"
                                value={formSA101State.maintenance_or_alimony_payments}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Trade Union Etc Death Benefit Payments</Label>
                                <CurrencyInput
                                id="trade_union_etc_death_benefit_payments"
                                name="trade_union_etc_death_benefit_payments"
                                value={formSA101State.trade_union_etc_death_benefit_payments}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Bonus Security Redemption Distribution Relief</Label>
                                <CurrencyInput
                                id="bonus_security_redemption_distribution_relief"
                                name="bonus_security_redemption_distribution_relief"
                                value={formSA101State.bonus_security_redemption_distribution_relief}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Seed Enterprise Investment Scheme Amount</Label>
                                <CurrencyInput
                                id="seed_enterprise_investment_scheme_amount"
                                name="seed_enterprise_investment_scheme_amount"
                                value={formSA101State.seed_enterprise_investment_scheme_amount}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Social Investment Tax Relief</Label>
                            <CurrencyInput
                                id="social_investment_tax_relief"
                                name="social_investment_tax_relief"
                                value={formSA101State.social_investment_tax_relief}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Non-deductible Loan Interest</Label>
                            <CurrencyInput
                                id="nondeductible_loan_interest"
                                name="nondeductible_loan_interest"
                                value={formSA101State.nondeductible_loan_interest}
                                onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                                className="form-control-lg form-control"
                            />
                        </InputForm>

                    <Title>Age Related Married Couples Allowance</Title>
                    <Subtitle>
                        * Can only input HigherEarner or LowerEarner but not both.
                    </Subtitle>

                    <Card>
                        <Title>Lump Sums</Title>
                        <InputForm>
                            <Label>Spouse's Name</Label>
                            <Input
                            id="higher_earner_spouses_name"
                            name="higher_earner_spouses_name"
                            value={formSA101State.higher_earner_spouses_name}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="text"
                            bsSize="lg"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Spouse's Date Of Birth if before 6 Apr 1935</Label>
                            <Input
                            id="higher_earner_spouses_date_of_birth"
                            name="higher_earner_spouses_date_of_birth"
                            value={formSA101State.higher_earner_spouses_date_of_birth}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="date"
                            />
                        </InputForm>
                        <Subtitle>
                            * Can only input higher_earner_half_minimum_allowance_transfer_to_other or higher_earner_all_minimum_allowance_transfer_to_other but not both.
                        </Subtitle>

                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                value={formSA101State.higher_earner_half_minimum_allowance_transfer_to_other}
                                checked={formSA101State.higher_earner_half_minimum_allowance_transfer_to_other}
                                onChange={(e) =>
                                    onCheckboxChange(e, formSA101State, setformSA101State)
                                }
                                name="higher_earner_half_minimum_allowance_transfer_to_other"
                                id="higher_earner_half_minimum_allowance_transfer_to_other"
                            />
                            <Label lg>Half Minimum Allowance Transfer To Other</Label>
                        </CheckboxLabel>
                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                value={formSA101State.higher_earner_all_minimum_allowance_transfer_to_other}
                                checked={formSA101State.higher_earner_all_minimum_allowance_transfer_to_other}
                                onChange={(e) =>
                                    onCheckboxChange(e, formSA101State, setformSA101State)
                                }
                                name="higher_earner_all_minimum_allowance_transfer_to_other"
                                id="higher_earner_all_minimum_allowance_transfer_to_other"
                            />
                            <Label lg>All Minimum Allowance Transfer To Other</Label>
                        </CheckboxLabel>
                        <InputForm>
                            <Label>Previous Spouse's Date Of Birth</Label>
                            <Input
                            id="higher_earner_previous_spouses_date_of_birth"
                            name="higher_earner_previous_spouses_date_of_birth"
                            value={formSA101State.higher_earner_previous_spouses_date_of_birth}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="date"
                            bsSize="lg"
                            />
                        </InputForm>
                    </Card>

                    <Card>
                        <Title>Lower Earner</Title>
                        <Subtitle>
                            * Can only input lower_earner_half_minimum_allowance_transfer_to_you or lower_earner_all_minimum_allowance_transfer_to_you but not both.
                        </Subtitle>

                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                value={formSA101State.lower_earner_half_minimum_allowance_transfer_to_you}
                                checked={formSA101State.lower_earner_half_minimum_allowance_transfer_to_you}
                                onChange={(e) =>
                                    onCheckboxChange(e, formSA101State, setformSA101State)
                                }
                                name="lower_earner_half_minimum_allowance_transfer_to_you"
                                id="lower_earner_half_minimum_allowance_transfer_to_you"
                            />
                            <Label lg>Half Minimum Allowance Transfer To You</Label>
                        </CheckboxLabel>
                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                value={formSA101State.lower_earner_all_minimum_allowance_transfer_to_you}
                                checked={formSA101State.lower_earner_all_minimum_allowance_transfer_to_you}
                                onChange={(e) =>
                                    onCheckboxChange(e, formSA101State, setformSA101State)
                                }
                                name="lower_earner_all_minimum_allowance_transfer_to_you"
                                id="lower_earner_all_minimum_allowance_transfer_to_you"
                            />
                            <Label lg>All Minimum Allowance Transfer To You</Label>
                        </CheckboxLabel>
                        <InputForm>
                            <Label>Spouse's Name</Label>
                            <Input
                            id="lower_earner_spouses_name"
                            name="lower_earner_spouses_name"
                            value={formSA101State.lower_earner_spouses_name}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="text"
                            bsSize="lg"
                            />
                        </InputForm>
                    </Card>


                    <InputForm>
                        <Label>Date Of Marriage Or Civil Partnership</Label>
                        <Input
                        id="date_of_marriage_or_civil_partnership"
                        name="date_of_marriage_or_civil_partnership"
                        value={formSA101State.date_of_marriage_or_civil_partnership}
                        onChange={(e) =>
                            onInputChange(e, formSA101State, setformSA101State)
                        }
                        type="date"
                        bsSize="lg"
                        />
                    </InputForm>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            value={formSA101State.surplus_allowance_from_spouse}
                            checked={formSA101State.surplus_allowance_from_spouse}
                            onChange={(e) =>
                                onCheckboxChange(e, formSA101State, setformSA101State)
                            }
                            name="surplus_allowance_from_spouse"
                            id="surplus_allowance_from_spouse"
                        />
                        <Label lg>Surplus Allowance From Spouse</Label>
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            value={formSA101State.surplus_allowance_to_spouse}
                            checked={formSA101State.surplus_allowance_to_spouse}
                            onChange={(e) =>
                                onCheckboxChange(e, formSA101State, setformSA101State)
                            }
                            name="surplus_allowance_to_spouse"
                            id="surplus_allowance_to_spouse"
                        />
                        <Label lg>Surplus Allowance To Spouse</Label>
                    </CheckboxLabel>

                    <Title>Other Information</Title>
                    <Card>
                        <Title>Income Tax Losses</Title>
                        <InputForm>
                            <Label>Earlier Year's Income Tax Losses</Label>
                            <CurrencyInput
                            id="earlier_years_income_tax_losses"
                            name="earlier_years_income_tax_losses"
                            value={formSA101State.earlier_years_income_tax_losses}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Unused Income Tax Losses Carried Forward</Label>
                            <CurrencyInput
                            id="unused_income_tax_losses_carried_forward"
                            name="unused_income_tax_losses_carried_forward"
                            value={formSA101State.unused_income_tax_losses_carried_forward}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Next Year's Trading And Capital Losses Relief</Label>
                            <CurrencyInput
                            id="next_years_trading_and_capital_losses_relief"
                            name="next_years_trading_and_capital_losses_relief"
                            value={formSA101State.next_years_trading_and_capital_losses_relief}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Next Year's Uncapped Loss Relief</Label>
                            <CurrencyInput
                            id="next_years_uncapped_loss_relief"
                            name="next_years_uncapped_loss_relief"
                            value={formSA101State.next_years_uncapped_loss_relief}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Year For Which Relief Claimed</Label>
                            <Input
                            id="year_for_which_relief_claimed"
                            name="year_for_which_relief_claimed"
                            value={formSA101State.year_for_which_relief_claimed}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="text"
                            bsSize="lg"
                            />
                        </InputForm>
                    </Card>

                    <InputForm>
                        <Label>Amount Of Payroll Giving</Label>
                        <CurrencyInput
                        id="amount_of_payroll_giving"
                        name="amount_of_payroll_giving"
                        value={formSA101State.amount_of_payroll_giving}
                        onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                        className="form-control-lg form-control"
                        />
                    </InputForm>




                    <Card>
                        <Title>Pension Tax And Lump Sums</Title>
                        <InputForm>
                            <Label>Lump Sum Pension Benefits In Excess Of ALA</Label>
                            <CurrencyInput
                            id="lump_sum_pension_benefits_in_excess_of_ala"
                            name="lump_sum_pension_benefits_in_excess_of_ala"
                            value={formSA101State.lump_sum_pension_benefits_in_excess_of_ala}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Non Lump Sum Pension Benefits Exceeding ALA</Label>
                            <CurrencyInput
                            id="non_lump_sum_pension_benefits_exceeding_ala"
                            name="non_lump_sum_pension_benefits_exceeding_ala"
                            value={formSA101State.non_lump_sum_pension_benefits_exceeding_ala}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Lifetime Allowance Tax Paid</Label>
                            <CurrencyInput
                            id="lifetime_allowance_tax_paid"
                            name="lifetime_allowance_tax_paid"
                            value={formSA101State.lifetime_allowance_tax_paid}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Amount Saved Exceeding Annual Allowance</Label>
                            <CurrencyInput
                            id="amount_saved_exceeding_annual_allowance"
                            name="amount_saved_exceeding_annual_allowance"
                            value={formSA101State.amount_saved_exceeding_annual_allowance}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>

                        <InputForm>
                            <Label>Pension Benefit Transferred Subject To OTC</Label>
                            <CurrencyInput
                            id="pension_benefit_transferred_subject_to_otc"
                            name="pension_benefit_transferred_subject_to_otc"
                            value={formSA101State.pension_benefit_transferred_subject_to_otc}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Tax Paid By Pension Scheme On OTC</Label>
                            <CurrencyInput
                            id="tax_paid_by_pension_scheme_on_otc"
                            name="tax_paid_by_pension_scheme_on_otc"
                            value={formSA101State.tax_paid_by_pension_scheme_on_otc}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Pension Scheme Tax Ref</Label>
                            <Input
                            id="pension_scheme_tax_ref"
                            name="pension_scheme_tax_ref"
                            value={formSA101State.pension_scheme_tax_ref}
                            onChange={(e) =>
                                onInputChange(e, formSA101State, setformSA101State)
                            }
                            type="text"
                            bsSize="lg"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Unauthorised Payment Not Subject To Surcharge</Label>
                            <CurrencyInput
                            id="unauthorised_payment_not_subject_to_surcharge"
                            name="unauthorised_payment_not_subject_to_surcharge"
                            value={formSA101State.unauthorised_payment_not_subject_to_surcharge}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Unauthorised Payment Subject To Surcharge</Label>
                            <CurrencyInput
                            id="unauthorised_payment_subject_to_surcharge"
                            name="unauthorised_payment_subject_to_surcharge"
                            value={formSA101State.unauthorised_payment_subject_to_surcharge}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Foreign Tax Paid On Unauthorised Payment</Label>
                            <CurrencyInput
                            id="foreign_tax_paid_on_unauthorised_payment"
                            name="foreign_tax_paid_on_unauthorised_payment"
                            value={formSA101State.foreign_tax_paid_on_unauthorised_payment}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Overseas Pension Contribution Short Service Refund</Label>
                            <CurrencyInput
                            id="overseas_pension_contribution_short_service_refund"
                            name="overseas_pension_contribution_short_service_refund"
                            value={formSA101State.overseas_pension_contribution_short_service_refund}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                        <InputForm>
                            <Label>Foreign Tax Paid</Label>
                            <CurrencyInput
                            id="foreign_tax_paid"
                            name="foreign_tax_paid"
                            value={formSA101State.foreign_tax_paid}
                            onChange={(e, valueAsCurrency) => onCurrencyInputChange(e, valueAsCurrency, formSA101State, setformSA101State)}
                            className="form-control-lg form-control"
                            />
                        </InputForm>
                    </Card>

                    <TaxAvoidanceScheme
                        value={formSA101State.tax_avoidance_schemes}
                        onChange={(e) => onInputChange(e, formSA101State, setformSA101State) }
                        id="tax_avoidance_schemes"
                        name="tax_avoidance_schemes"
                    />

                    {
                        ( taxYear == 2020 || taxYear == 2021 ) ?
                        <Card>
                            <Title>Disguised Remuneration</Title>
                            <InputForm>
                                <Label>Non PAYEDR Employment Income</Label>
                                <Input
                                id="non_payedr_employment_income"
                                name="non_payedr_employment_income"
                                value={formSA101State.non_payedr_employment_income}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>DR Ceased Self-Employment & Partnership Income</Label>
                                <Input
                                id="dr_ceased_selfemp_and_pship_income"
                                name="dr_ceased_selfemp_and_pship_income"
                                value={formSA101State.dr_ceased_selfemp_and_pship_income}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>DR Ceased Self-Employment & Partnership Income Carried Back</Label>
                                <Input
                                id="dr_ceased_selfemp_and_pship_income_carried_back"
                                name="dr_ceased_selfemp_and_pship_income_carried_back"
                                value={formSA101State.dr_ceased_selfemp_and_pship_income_carried_back}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="number"
                                bsSize="lg"
                                />
                            </InputForm>
                            <InputForm>
                                <Label>Tax Year Income To Be Taxed</Label>
                                <Input
                                id="dr_tax_year_income_to_be_taxed"
                                name="dr_tax_year_income_to_be_taxed"
                                value={formSA101State.dr_tax_year_income_to_be_taxed}
                                onChange={(e) =>
                                    onInputChange(e, formSA101State, setformSA101State)
                                }
                                type="text"
                                bsSize="lg"
                                />
                            </InputForm>

                        </Card>
                        :<></>

                    }

                    </FormContainer >

                    <SubmitButton>
                        <Button color="primary" onClick={() => onSubmitForm()} disabled>
                            Save
                        </Button>
                    </SubmitButton>
                </div>
            </div>

        </>);

  }