import React, { useEffect, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Link } from 'gatsby';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import { getStaffDashboard } from 'src/api/dashboard';
import { Layout } from 'src/components';
import { Filters } from 'src/components/dashboard';
import DoATable from 'src/components/DoATable';
import HMRCSplitTable from 'src/components/HMRCSplitTable';
import IncomeTable from 'src/components/IncomeTable';
import MessagesTable from 'src/components/MessagesTable';
import TagTable from 'src/components/TagTable';
import { Loading } from 'src/components/ui';
import Dashboard from 'src/layouts/Dashboard';
import { Button, CustomInput, observer } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { formatDate, Routes } from 'src/utils';
import {
  returnDataStaffByCategories,
  sortResults,
  TCategoriesCharts,
  THorizontalBarChart,
} from 'src/utils/dashboardDataset';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';
import {
  getStaffDataset,
  getStaffHMRC,
  getStaffIncome,
  getStaffMessages,
  getStaffDoA,
  getTotalForChart,
  normalizeDataChart,
  normalizeDataChartSplit,
  StaffChartData,
} from 'src/utils/staffDataset';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Staff: React.FC = () => {
  const [data, setData] = useState<StaffChartData>();
  const [result, setResult] = useState<any>();
  const [height, setHeight] = useState<number>(1200);
  const [active, setActive] = useState<boolean>(false);
  const [everyone, setEveryone] = useState<boolean>(false);
  const [dataHMRC, setDataHMRC] = useState<StaffChartData>();
  const [dataIncome, setDataIncome] = useState<StaffChartData>();
  const [dataDoA, setDataDoA] = useState<StaffChartData>();
  const [dataMessages, setDataMessages] = useState<StaffChartData>();
  const [chartData, setChartData] = useState<THorizontalBarChart | any>();
  const [totalData, setTotalData] = useState<THorizontalBarChart | any>();
  const [normalizeData, setNormalizeData] = useState<
    THorizontalBarChart | any
  >();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const TABS = [
    { label: 'Tags', value: 'tags', active: true },
    { label: 'HMRC Submit', value: 'hmrcSubmit', active: true },
    { label: 'HMRC Modify', value: 'hmrcModify', active: true },
    { label: 'Messages', value: 'messages', active: true },
    { label: 'Income/Expenses', value: 'income', active: true },
    { label: 'DoA', value: 'doa', active: true },
    { label: 'Tax Code', value: 'taxCode', active: false },
    { label: 'UTR', value: 'utr', active: false },
  ];

  const [activeTab, setActiveTab] = useState('');

  const loadUser = async () => {
    const userData = await Storage.getUser();
    const isSuperAdmin = handleSuperAdminRole(userData.role_id);

    setIsSuperAdmin(isSuperAdmin);
  };

  useEffect(() => {
    if (result) {
      getResult(result);
    }
  }, [everyone]);

  const { isLoading } = useQuery(
    [
      'getStaffDashboard',
      formatDate(store.dashboard.filters.startDate, 'DD/MM/YYYY'),
      formatDate(store.dashboard.filters.endDate, 'DD/MM/YYYY'),
      store.dashboard.filters.period,
    ],
    getStaffDashboard,
    {
      retry: false,
      onSuccess: (res) => {
        setResult(res);
        getResult(res);
      },
    },
  );

  const getResult = (res: any) => {
    res.everyone = everyone;
    const charData = getStaffDataset(res);
    const normalizeChartData = normalizeDataChart(charData.byGroup);
    setData(charData);

    const charDataHMRC = getStaffHMRC(res);

    charDataHMRC.byGroup.map((c: any) => {
      let totalSubmit = 0;
      let totalModify = 0;
      c.values.map((v: any) => {
        totalSubmit = totalSubmit + v[0]['HMRC_SUBMIT'];
        totalModify = totalModify + v[0]['HMRC_MODIFY'];
      });
      c.totalModify = totalModify;
      c.totalSubmit = totalSubmit;
    });
    const normalizeCharDataHMRCSubmit = normalizeDataChartSplit(
      charDataHMRC.byGroup,
      'totalSubmit',
    );
    const normalizeCharDataHMRCModify = normalizeDataChartSplit(
      charDataHMRC.byGroup,
      'totalModify',
    );
    sortResults('totalModify', false, charDataHMRC.byGroup);
    setDataHMRC(charDataHMRC);

    const charDataIncome = getStaffIncome(res);
    const normalizeCharDataIncome = normalizeDataChart(charDataIncome.byGroup);
    setDataIncome(charDataIncome);
    const charDataMessages = getStaffMessages(res);
    const normalizeCharDataMessages = normalizeDataChart(
      charDataMessages.byGroup,
    );
    setDataMessages(charDataMessages);

    const charDataDoA = getStaffDoA(res);
    const normalizeCharDataDoA = normalizeDataChart(charDataDoA.byGroup);
    setDataDoA(charDataDoA);

    const totalNormalize = getTotalForChart([
      normalizeChartData,
      normalizeCharDataHMRCSubmit,
      normalizeCharDataHMRCModify,
      normalizeCharDataMessages,
      normalizeCharDataIncome,
    ]);
    const categories: TCategoriesCharts[] = [
      { title: 'Tags', color: '#FFDD05' },
      { title: 'HMRC Submit', color: '#3B82EC' },
      { title: 'HMRC Modify', color: '#114390' },
      { title: 'Messages', color: '#20c997' },
      { title: 'Income/Expenses', color: '#CF8800' },
      { title: 'Deed of assignment', color: '#F77921' },
    ];

    const chartData = returnDataStaffByCategories(
      [
        normalizeChartData,
        normalizeCharDataHMRCSubmit,
        normalizeCharDataHMRCModify,
        normalizeCharDataMessages,
        normalizeCharDataIncome,
        normalizeCharDataDoA,
      ],
      totalNormalize,
      categories,
      false,
    );

    const chartDataNormal = returnDataStaffByCategories(
      [
        normalizeChartData,
        normalizeCharDataHMRCSubmit,
        normalizeCharDataHMRCModify,
        normalizeCharDataMessages,
        normalizeCharDataIncome,
        normalizeCharDataDoA,
      ],
      totalNormalize,
      categories,
      true,
    );

    setNormalizeData(chartDataNormal);
    setTotalData(chartData);
    setChartData(chartData);
    const height = chartData.labels.length * 20;
    setHeight(height);
    if (!activeTab) setActiveTab('tags');
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 'tags':
        return <TagTable data={data}></TagTable>;

      case 'hmrcSubmit':
        return (
          <HMRCSplitTable type="Submit" dataHMRC={dataHMRC}></HMRCSplitTable>
        );

      case 'hmrcModify':
        return (
          <HMRCSplitTable type="Modify" dataHMRC={dataHMRC}></HMRCSplitTable>
        );

      case 'messages':
        return <MessagesTable dataMessages={dataMessages}></MessagesTable>;

      case 'income':
        return <IncomeTable dataIncome={dataIncome}></IncomeTable>;

      case 'doa':
        return <DoATable dataDoA={dataDoA}></DoATable>;

      default:
        return null;
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  const normalizeChart = (e: any) => {
    const isChecked: boolean = e.target.checked;

    if (isChecked) {
      setChartData(normalizeData);
    } else {
      setChartData(totalData);
    }

    setActive(isChecked);
  };

  return (
    <Layout>
      <Dashboard title="Staff Performance">
        <Filters />
        {isLoading && <Loading />}
        {chartData && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '200px',
              }}
            >
              <CustomInput
                type="checkbox"
                label="Normalised"
                id={`input1`}
                name="customSwitch"
                checked={active}
                onChange={(e) => {
                  normalizeChart(e);
                }}
              />

              <CustomInput
                type="checkbox"
                label="Everyone"
                id={`input2`}
                name="customSwitch2"
                checked={everyone}
                onChange={(e) => {
                  setEveryone(e.target.checked);
                }}
              />
            </div>

            <div style={{ position: 'relative', height: `${height}px` }}>
              <Bar options={options} data={chartData} />
            </div>
          </>
        )}
        {isLoading && <Loading />}
        {data && (
          <>
            <div className="tab mt-4">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ul className="nav nav-tabs">
                  {TABS.map((item) => (
                    <li key={item.value} className="nav-item position-relative">
                      <a
                        onClick={() => item.active && setActiveTab(item.value)}
                        className={`nav-link ${
                          activeTab === item.value ? 'active' : ''
                        } ${!item.active && 'disabled-tab'}`}
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
                {isSuperAdmin && (
                  <Link to={Routes.DASHBOARD_SHIFT}>
                    <Button color="secondary" type="button" outline>
                      Shift Log
                    </Button>
                  </Link>
                )}
              </div>
              {getTabContent()}
            </div>
          </>
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(Staff);
