import React from 'react';

import { Download } from 'react-feather';

import { Label, Input, CustomInput } from 'src/modules';
import { ProofTitle } from 'src/utils';
import { CustomerInformation } from 'src/utils/types/Customer';

import { TableData, TableRow, LabelData } from '../styles';

type Props = {
  data: CustomerInformation;
  disabled: boolean;
  switchLabel: string;
  toogleProofTitle(checked: boolean): void;
};

const OtherInformation: React.FC<Props> = ({
  data,
  disabled,
  switchLabel,
  toogleProofTitle,
}) => {
  return (
    <>
      <TableRow>
        <TableData alignRight>
          <LabelData for="isPushNotification">Is PushNotification :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="isPushNotification"
            type="text"
            defaultValue={data.is_push_notification ? 'Yes' : 'No'}
            disabled={true}
          />
        </TableData>
        <TableData alignRight>
          <LabelData for="isSms">Is SMS :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="isSms"
            type="text"
            defaultValue={data.is_sms ? 'Yes' : 'No'}
            disabled={true}
          />
        </TableData>
      </TableRow>
      <TableRow>
        <TableData alignRight>
          <LabelData for="id_proof_title" className="mb-0 mr-3">
            Id Proof Title :
          </LabelData>
        </TableData>
        <TableData>
          <div className="d-flex">
            <p>{switchLabel}</p>
            <p
              className={`${
                switchLabel === ProofTitle.VERIFIED
                  ? 'text-success'
                  : 'text-danger'
              } mb-0 ${disabled === false ? 'd-none' : ''}`}
            >
              <small>({switchLabel})</small>
            </p>
            <CustomInput
              className={`${disabled ? 'd-none' : ''}`}
              type="switch"
              id="id_proof_title"
              name="customSwitch"
              label={switchLabel}
              defaultChecked={switchLabel === ProofTitle.VERIFIED}
              onChange={(e) => toogleProofTitle(e.target.checked)}
            />
          </div>
        </TableData>
        <TableData alignRight>
          <Label for="id_proof">Id Proof :</Label>
        </TableData>
        <TableData>
          <div className="d-flex">
            <img
              className="avatar img-fluid rounded-circle"
              src={data.id_proof}
              alt={`Id Proof Image`}
            />
            <a
              href={data.id_proof}
              target="_blank"
              download
              className={`avatar rounded-circle border d-flex align-items-center justify-content-center ml-2`}
              rel="noreferrer"
            >
              <Download size={16} className="text-muted" />
            </a>
          </div>
        </TableData>
      </TableRow>
      <TableRow>
        <TableData alignRight>
          <LabelData for="status">Status :</LabelData>
        </TableData>
        <TableData>
          <Input
            id="status"
            type="text"
            defaultValue={data.status}
            disabled={true}
          />
        </TableData>
        <TableData alignRight>
          <LabelData for="createdOn" className="mb-0 mr-2">
            Created On :
          </LabelData>
        </TableData>
        <TableData>
          <Input
            id="createdOn"
            type="text"
            defaultValue={data.created_date}
            disabled={true}
          />
        </TableData>
      </TableRow>
      <TableRow>
        <TableData alignRight>
          <LabelData for="updatedOn" className="mb-0 mr-2">
            Updated On :
          </LabelData>
        </TableData>
        <TableData>
          <Input
            id="updatedOn"
            type="text"
            defaultValue={data.modified_date}
            disabled={true}
          />
        </TableData>
        <TableData alignRight>
          <LabelData for="last_login" className="mb-0 mr-2">
            Last Access :
          </LabelData>
        </TableData>
        <TableData>{data.last_login}</TableData>
      </TableRow>
      <TableRow>
        <TableData alignRight>
          <LabelData for="ipAddress" className="mb-0 mr-2">
            IP Address :
          </LabelData>
        </TableData>
        <TableData>{data.created_ip}</TableData>

        <TableData colSpan={2} />
      </TableRow>
    </>
  );
};

export default OtherInformation;
