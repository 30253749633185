import { TaxHistoryResponse, TaxHistoryBenefitsReponse } from 'src/models';

import request from '../request';
import ResponseError from '../ResponseError';

export const getTaxHistory = async (
  userId: number,
): Promise<TaxHistoryResponse> => {
  try {
    const { data } = await request.get(
      `/v2/users/tax-history?user_id=${userId}`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getTaxHistoryBenefits = async (
  userId: number,
): Promise<TaxHistoryBenefitsReponse> => {
  try {
    const { data } = await request.get(
      `/v2/users/tax-history-benefits?user_id=${userId}`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
