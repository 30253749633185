import React, { useEffect, useState } from 'react';

import { BiDownload } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { Card, Col } from 'reactstrap';

import {
  getContingencyDownloadLink,
  getCustomersContingency,
  getExportAll,
  updateContingencyFlags,
} from 'src/api/customer.api';
import { TableHead, CustomPagination } from 'src/components';
import { Container, Toastify } from 'src/modules';
import { Storage } from 'src/services';
import { conversationTime, SUCCESS } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import { Loading } from '../ui';

import {
  DetailsContainer,
  DetailsContent,
  DetailsItem,
  DetailsTitle,
  DocContainer,
  DocContent,
  DocItem,
  ExportAllLinkButton,
  FileUploaded,
  PaginationContainer,
  TableBody,
  TableRow,
} from './styles';

const PLACEHOLDER_DATA = {
  data: [],
  total_entries: 0,
  total_pages: 0,
  last_page: 0,
  next_page: 0,
  has_more: false,
  next_page_url: '',
  previous_page_url: '',
  message: '',
};

function ContingencyList() {
  let dataList: any[] = [];

  const [superAdmin, setSuperAdmin] = useState(false);
  const [dataListState, setDataListState] = useState([]);
  const [urlDownloadLink, setUrlDownloadLink] = useState('');

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  const { data, isLoading } = useQuery(
    ['customersContingency', nextPage],
    getCustomersContingency,
    {
      placeholderData: PLACEHOLDER_DATA,
      onSuccess: ({
        has_more,
        next_page,
        total_pages,
        next_page_url,
        previous_page_url,
      }) => {
        setHasPagination(has_more);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      dataList = data.data;

      setDataListState(dataList);
    }
  }, [data]);

  const { refetch } = useQuery(
    'getContingencyDownloadLink',
    () => getContingencyDownloadLink(urlDownloadLink),
    {
      enabled: false,
      onSuccess: (result) => {
        const downloadLinkEl = document.createElement('a');

        downloadLinkEl.href = result.url;
        downloadLinkEl.download = 'file';
        downloadLinkEl.click();
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { data: dataExportAll } = useQuery('get-export-all', getExportAll, {
    onError: ({ response }) => {
      Toastify.toast(response.data.message);
    },
  });

  const { mutate: mutationUpdateContingencyFlags } = useMutation(
    updateContingencyFlags,
    {
      onSuccess: () => {
        Toastify.toast.success(SUCCESS.GENERIC);
      },
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onOpenDetails = (isOpen: boolean, index: number, event: any) => {
    if (event.target.tagName === 'INPUT') {
      return;
    }

    dataList = data.data;
    dataList.forEach((item, index) => (dataList[index].isOpen = false));

    dataList[index].isOpen = !isOpen;

    setDataListState([...dataList]);
  };

  const onDownloadFile = (url: string) => {
    setUrlDownloadLink(url);
  };

  useEffect(() => {
    if (urlDownloadLink.length > 0) {
      refetch();
    }
  }, [urlDownloadLink]);

  const onChangeStatus = (
    started: boolean,
    deleted: boolean,
    contingency_user_id: number,
    ready_to_submit: boolean,
    submitted: boolean,
  ) => {
    const newDataList = [...dataListState];

    newDataList.map((list) => {
      if (list.id === contingency_user_id) {
        list.started = started;
        list.deleted = deleted;
        list.ready_to_submit = ready_to_submit;
        list.submitted = submitted;
      }
    });

    setDataListState(newDataList);

    mutationUpdateContingencyFlags({
      started,
      deleted,
      contingency_user_id,
      ready_to_submit,
      submitted,
    });
  };

  useEffect(() => {
    const getUser = async () => {
      const user = await Storage.getUser();
      const isSuperAdmin = handleSuperAdminRole(user?.role_id);

      setSuperAdmin(isSuperAdmin);
    };

    getUser();
  }, []);

  const onExportAll = () => {};

  return (
    <>
      <Card>
        <Container>
          <TableHead>
            <Col xs="1">#</Col>
            <Col xs="2">Name</Col>
            <Col xs="2">Email</Col>
            <Col xs="2">Phone</Col>
            <Col xs="1">UTR Number</Col>
            <Col xs="1">Started</Col>
            <Col xs="1">Deleted</Col>
            <Col xs="1">Ready</Col>
            <Col xs="1">Submitted</Col>
          </TableHead>
          <TableBody>
            {isLoading && <Loading />}

            {!isLoading &&
              dataListState.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      opacity: !!item.deleted ? 0.5 : 1,
                      backgroundColor: !!item.deleted ? '#f2f2f2' : '#fff',
                    }}
                  >
                    <TableRow
                      onClick={(event) =>
                        onOpenDetails(item.isOpen, index, event)
                      }
                      style={{ borderBottom: '1px solid #ddd' }}
                    >
                      <Col xs="1">{item.id}</Col>
                      <Col xs="2">{item.name} </Col>
                      <Col xs="2">{item.email} </Col>
                      <Col xs="2">{item.phone} </Col>
                      <Col xs="1" style={{ position: 'relative' }}>
                        {item.utr}{' '}
                      </Col>
                      <Col
                        xs="1"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          value={item.started}
                          checked={item.started}
                          onChange={() =>
                            onChangeStatus(
                              !item.started,
                              item.deleted,
                              item.id,
                              item.ready_to_submit,
                              item.submitted,
                            )
                          }
                        />
                      </Col>
                      <Col
                        xs="1"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          checked={item.deleted}
                          onChange={() =>
                            onChangeStatus(
                              item.started,
                              !item.deleted,
                              item.id,
                              item.ready_to_submit,
                              item.submitted,
                            )
                          }
                        />
                      </Col>
                      <Col
                        xs="1"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          checked={item.ready_to_submit}
                          onChange={() =>
                            onChangeStatus(
                              item.started,
                              item.deleted,
                              item.id,
                              !item.ready_to_submit,
                              item.submitted,
                            )
                          }
                        />
                      </Col>
                      <Col
                        xs="1"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          checked={item.submitted}
                          onChange={() =>
                            onChangeStatus(
                              item.started,
                              item.deleted,
                              item.id,
                              item.ready_to_submit,
                              !item.submitted,
                            )
                          }
                        />
                      </Col>
                    </TableRow>

                    {item.isOpen && (
                      <>
                        <DetailsContainer>
                          <DetailsTitle>Personal Information</DetailsTitle>

                          <DetailsContent>
                            <Col>
                              <DetailsItem>
                                <strong>Full name</strong>
                                <span>{item.name}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>Phone Number</strong>
                                <span>{item.phone}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>UTR Number</strong>
                                <span>{item.utr}</span>
                              </DetailsItem>
                            </Col>

                            <Col>
                              <DetailsItem>
                                <strong>Email</strong>
                                <span>{item.email}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>Address</strong>
                                <span>{item.address}</span>
                              </DetailsItem>
                            </Col>

                            <Col>
                              <DetailsItem>
                                <strong>Created on</strong>
                                <span>{conversationTime(item.created_at)}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>Bank Sortcode</strong>
                                <span>{item?.bank_sort_code}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>Bank Account</strong>
                                <span>{item?.bank_account}</span>
                              </DetailsItem>

                              <DetailsItem>
                                <strong>Notes</strong>
                                <span>{item?.notes}</span>
                              </DetailsItem>
                            </Col>
                          </DetailsContent>

                          <DetailsTitle>Documentation</DetailsTitle>

                          <DocContainer>
                            <DocItem>
                              <DocContent>
                                <span className="title">Income</span>
                                {item.incomes.map((income, index) => (
                                  <FileUploaded
                                    key={index}
                                    onClick={() =>
                                      onDownloadFile(income.download_link)
                                    }
                                  >
                                    File {index + 1}{' '}
                                    <BiDownload size={20} color="#209782" />
                                  </FileUploaded>
                                ))}
                              </DocContent>
                            </DocItem>

                            <DocItem>
                              <DocContent>
                                <span className="title">Expenses</span>
                                {item.expenses.map((expense, index) => (
                                  <FileUploaded
                                    key={index}
                                    onClick={() =>
                                      onDownloadFile(expense.download_link)
                                    }
                                  >
                                    <div>File {index + 1}</div>
                                    <BiDownload size={20} color="#209782" />
                                  </FileUploaded>
                                ))}
                              </DocContent>
                            </DocItem>
                          </DocContainer>
                        </DetailsContainer>
                      </>
                    )}
                  </div>
                );
              })}
          </TableBody>
        </Container>
      </Card>

      <PaginationContainer>
        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}

        {superAdmin && (
          <ExportAllLinkButton href={dataExportAll?.url} download>
            Export all
          </ExportAllLinkButton>
        )}
      </PaginationContainer>
    </>
  );
}

export default ContingencyList;
