import styled from 'styled-components';

type Props = {
  isAssistant: boolean;
};

export const Container = styled.div<Props>`
  display: flex;
  align-self: ${(props) => (props.isAssistant ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin: 30px 40px 40px 0;

  .attached-img {
    max-height: 300px;
    max-width: 250px;
    object-fit: contain;
  }
`;
