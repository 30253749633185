import { styled } from 'src/modules';

type Props = {
  tabHeight?: number;
  contained?: boolean;
};

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background: ${({ theme }) => theme.colors.white.catskill}; */
  margin-left: ${({ theme }) => theme.spacing.s}px;

  position: relative;
  top: 10px;
  left: -9px;
  height: calc(100vh - (260px + ${(props) => props.tabHeight}px));
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 0px;
  background: ${({ theme }) => theme.colors.white.default};
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.xs}px;
  padding: 0;
  overflow: hidden;
`;

export const ModalInput = styled.textarea`
  width: 100%;
  height: 200px;
  border: 2px solid gray;
  outline: 0 !important;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.gray.shark};
  resize: none;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

export const Buttom = styled.button<Props>`
  background: ${({ theme, contained }) =>
    contained ? theme.colors.button : theme.colors.white.default};
  padding: 4px 0px;
  width: 100px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  margin-left: 30px;
  outline: 0 !important;
  transition: 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;
