import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useQuery } from 'react-query';
import { Button } from 'reactstrap';

import { getLinks } from 'src/api/documents';
import request from 'src/api/request';
import { Layout } from 'src/components';
import API from 'src/data/API';
import Dashboard from 'src/layouts/Dashboard';
import { Card, Container, Toastify } from 'src/modules';
import { Storage } from 'src/services';
import { ERRORS } from 'src/utils';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

const Exports: React.FC = () => {
  const [exportFrom, setExportFrom] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [exportTo, setExportTo] = useState(moment().format('YYYY-MM-DD'));
  const [exporting, setExporting] = useState(false);

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const { data: link, isLoading } = useQuery('links', getLinks);

  const loadUser = async () => {
    const userData = await Storage.getUser();
    const credentials = handleSuperAdminRole(userData.role_id);

    setIsSuperAdmin(credentials);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const onExportStatus = async (): Promise<void> => {
    if (exporting) return;

    setExporting(true);

    try {
      const from = moment(exportFrom).format('DD/MM/YYYY');
      const to = moment(exportTo).format('DD/MM/YYYY');

      const { data } = await request.get(
        `${API.PATH}${API.USERS_EXPORT_ALL_PAID}/?from=${from}&to=${to}`,
      );

      (window as any).open(data.url, 'exported_status');
    } catch (error) {
      Toastify.toast.error(
        `${ERRORS.GENERIC_ERROR} - Try again with a shorter date range`,
      );
    } finally {
      setExporting(false);
    }
  };

  return (
    <Layout>
      <Dashboard title="Admin Utils">
        {isSuperAdmin && (
          <Card className="overflow-visible p-4">
            <h3 className="mb-3">Export for VAT</h3>
            <Container className="mw-100" style={{ display: 'flex' }}>
              <div>
                <span className="mr-1">From: </span>
                <input
                  min={moment().subtract(1, 'month').format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                  type="date"
                  className="p-1 mr-2"
                  value={exportFrom}
                  onChange={(e) => setExportFrom(e.target.value)}
                />
              </div>

              <div>
                <span className="mr-1">To: </span>
                <input
                  min={moment().subtract(1, 'month').format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                  type="date"
                  className="p-1 mr-2"
                  value={exportTo}
                  onChange={(e) => setExportTo(e.target.value)}
                />
              </div>

              <Button color="primary" onClick={onExportStatus}>
                {exporting ? 'Exporting...' : `Export`}
              </Button>
            </Container>
          </Card>
        )}

        <Card className="overflow-visible p-4">
          <h3 className="mb-3">Documents</h3>
          <ul>
            <li>
              <a
                href="https://www.notion.so/CMS-Documentation-317b5eeb85ce418aaacb7e3f774e0452"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developers Guide
              </a>
            </li>
            <li>
              <a
                href={!isLoading && link.url_training_manual}
                target="_blank"
                rel="noopener noreferrer"
              >
                Assistants Training Manual
              </a>
            </li>
          </ul>
          <br />
        </Card>
        <Card className="overflow-visible p-4">
          <h3 className="mb-3">UTR Extension Install</h3>
          <a href="/app/utr-install" className="text-decoration-none">
            <p className="mb-2">Click here to see the instructions</p>
            <img src="/images/utr-bot-thumb.png" style={{ width: '500px' }} />
          </a>
        </Card>
        <Card className="overflow-visible p-4">
          <h3 className="mb-3">Links</h3>
          <a
            href="/app/logs/assistant-changes"
            className="text-decoration-none"
          >
            <p className="mb-2">Assistant Changes</p>
          </a>
          <a href="/app/categories" className="text-decoration-none">
            <p className="mb-2">Categories</p>
          </a>
          <a href="/app/expenses" className="text-decoration-none">
            <p className="mb-2">Expenses</p>
          </a>
          {/* <a href="/app/job-categories" className="text-decoration-none">
            <p className="mb-2">Job Categories</p>
          </a>
          <a href="/app/job-sub-categories" className="text-decoration-none">
            <p className="mb-2">Job Sub Categories</p>
          </a>
          <a href="/app/question-library" className="text-decoration-none">
            <p className="mb-2">Question Library</p>
          </a> */}
          <a
            href="/app/notification-templates"
            className="text-decoration-none"
          >
            <p className="mb-2">Notification Templates</p>
          </a>
          <a href="/app/cost-suggestion" className="text-decoration-none">
            <p className="mb-2">Cost Suggestion</p>
          </a>
          <a href="/app/mail-settings" className="text-decoration-none">
            <p className="mb-2">Mail Settings</p>
          </a>
        </Card>
        <Card className="overflow-visible p-4">
          <h3 className="mb-3">Logs</h3>
          <a href="/app/sanction-list" className="text-decoration-none">
            <p className="mb-2">Sanction List</p>
          </a>
          <a
            href="/app/admins/customers-per-page"
            className="text-decoration-none"
          >
            <p className="mb-2">Customers per page</p>
          </a>
          <a href="/app/etoro" className="text-decoration-none">
            <p className="mb-2">eToro</p>
          </a>
        </Card>
      </Dashboard>
    </Layout>
  );
};

export default Exports;
