import React, { useEffect, useState } from 'react';

import {
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

type Props = {
  changePage: (page: number) => void;
  changePerPage?: any;
  res: null | {
    next_page: number;
    total_pages: number;
    per_page?: number;
    next_page_url: string;
    previous_page_url: string;
  };
  position?: 'start' | 'end' | 'center';
};

const CustomPagination: React.FC<Props> = ({
  changePage,
  changePerPage,
  res,
  position,
}) => {
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(res?.per_page || 20);

  const PaginationArray = () => {
    const arrElem: JSX.Element[] = [];

    let startNo: number = activePage - 3;
    if (startNo < 1) startNo = 1;

    let endNo: number = activePage + 5;
    if (endNo > totalPages) endNo = totalPages;

    for (let i = startNo; i <= endNo; i++) {
      arrElem.push(
        <PaginationItem key={`p${i}`} active={i === activePage}>
          <PaginationLink onClick={() => change(i)}>{i}</PaginationLink>
        </PaginationItem>,
      );
    }
    return <>{arrElem}</>;
  };

  useEffect(() => {
    if (!res) return;

    setActivePage(res.next_page - 1);
    setTotalPages(res.total_pages);
    setPerPage(res.per_page);
  }, [res]);

  const change = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setActivePage(page);
    changePage(page);
  };

  const onPerPage = (count: number) => {
    changePerPage(count);
  };

  return (
    <div
      className={`d-flex px-4 pt-4 pb-2 justify-content-${
        position || 'center'
      }`}
    >
      <Pagination>
        <PaginationItem disabled={activePage === 1}>
          <PaginationLink first onClick={() => change(1)} />
        </PaginationItem>
        <PaginationItem disabled={activePage === 1}>
          <PaginationLink previous onClick={() => change(activePage - 1)} />
        </PaginationItem>
        <PaginationArray />
        <PaginationItem disabled={activePage === totalPages}>
          <PaginationLink next onClick={() => change(activePage + 1)} />
        </PaginationItem>
        <PaginationItem disabled={activePage === totalPages}>
          <PaginationLink last onClick={() => change(totalPages)} />
        </PaginationItem>
      </Pagination>
      {changePerPage && (
        <FormGroup className="ml-4">
          <Input
            type="select"
            name="perPage"
            value={perPage}
            onChange={(event) => onPerPage(Number(event.target.value))}
          >
            <option disabled value="">
              Per page
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </Input>
        </FormGroup>
      )}
    </div>
  );
};

export default CustomPagination;
