import request from "./request";
import ResponseError from "./ResponseError";

export const getESignatures = async (
  userId: number,
  taxYear: number
): Promise<ESignatureTypes[]> => {
  try {
    const { data } = await request.get(
      `/electronic-signature/?user_id=${userId}&tax_year=${taxYear}`
    );

    return data.data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
