import React from 'react';

import { CardContainer, CardDetails } from './styles';

type Props = {
  status: string;
};

function NewCustomerProgressPie({ status }: Props) {
  return (
    <CardContainer status={status}>
      <CardDetails>
        <span className="title">{status}</span>
      </CardDetails>
    </CardContainer>
  );
}

export default NewCustomerProgressPie;
