import React, { useEffect, useState } from 'react';

import { Phone } from 'react-feather';
import { useMutation, useQuery } from 'react-query';
import { Button } from 'reactstrap';

import {
  getClosePositions,
  getImportedFile,
  uploadToroFile,
} from 'src/api/etoro';
import { Layout } from 'src/components';
import Dashboard from 'src/layouts/Dashboard';
import { Toastify } from 'src/modules';
import { ERRORS, formatDate } from 'src/utils';

import {
  Container,
  List,
  ListBody,
  ListHeader,
  Loading,
  UploadSection,
  TypeItem,
  ProfitItem,
  DetailsContainer,
  ContainerList,
  DetailsHeader,
  DetailsBody,
  DetailsHeaderLogo,
  SpanPositionId,
  SpanCloseDate,
  SpanUnits,
  Header,
  UserHeader,
  UserPhoto,
  CallButton,
  UserPhotoContainer,
  NameContainer,
  NameText,
  UserNameText,
  HmrcText,
  HmrcContainer,
  GoBackContainer,
} from '../../../styles/pages/etoro/etoro-styles';

import TabsHeaderComponent from './TabsHeader';

function Etoro() {
  const [file, setFile] = useState<File>();
  const [showDetails, setShowDetails] = useState(false);
  const [idSelected, setIdSelected] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [indexSelected, setindexSelected] = useState(-1);
  const [activeTab, setActiveTab] = useState(3);

  const { isLoading, data, refetch } = useQuery('list-imported-file', () =>
    getImportedFile(''),
  );

  const {
    isLoading: isLoadingClosePositions,
    data: dataClosePositions,
    refetch: refetchClosePositions,
  } = useQuery('list-close-positions', () => getClosePositions(idSelected), {
    enabled: false,
  });

  // sum of profit prop in dataClosePositions
  const totalProfit = dataClosePositions?.data.reduce(
    (acc, item) => acc + item.profit,
    0,
  );

  const { mutate: mutationUploadTorofile } = useMutation(uploadToroFile, {
    onSuccess() {
      setUploading(false);
      Toastify.toast.success('File uploaded');
      refetch();
    },
    onError() {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  useEffect(() => {
    if (idSelected > 0) {
      refetchClosePositions();
    }
  }, [idSelected]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = e.target.files;

    if (file.length > 0) setFile(file[0]);
  };

  const onUploadFile = () => {
    setFile(user);
    setUploading(true);

    const formData = new FormData();

    formData.append('spreadsheet', file);

    mutationUploadTorofile(formData);
  };

  const onShowDetail = (id: number) => {
    setIdSelected(id);
    setShowDetails(true);
  };

  const checkType = (type: string) => {
    switch (type) {
      case 'CFD':
        return '#57E4CD';
      case 'Stock':
        return '#00C0E0';
      case 'ETF':
        return '#FF6E29';
      case 'TRS':
        return '#A05AF9';
      case 'Crypto':
        return '#0047FF';
      case 'CFD':
        return '#57E4CD';
      default:
        break;
    }
  };

  const checkProfitColor = (profit: number) =>
    profit >= 0 ? '#209782' : '#FC1F49';

  return (
    <Layout>
      <Dashboard title="eToro">
        {showDetails && (
          <GoBackContainer>
            <Button onClick={() => setShowDetails(false)} color="primary">
              Go back
            </Button>
          </GoBackContainer>
        )}

        {!showDetails && (
          <GoBackContainer>
            <UploadSection>
              <input type="file" onChange={handleFile} />
              <Button color="primary" onClick={() => onUploadFile()}>
                {uploading ? 'Uploading...' : 'Upload'}
              </Button>
            </UploadSection>
          </GoBackContainer>
        )}

        <Header>
          <UserHeader>
            <UserPhotoContainer>
              <UserPhoto src="/images/avatars/tommy.png" alt="user image" />
              <CallButton color="primary" onClick={() => {}}>
                <Phone size={20} />
              </CallButton>
            </UserPhotoContainer>
            <NameContainer>
              <NameText>{!isLoading && data && data.data[0].name}</NameText>
              <UserNameText>
                {!isLoading && data && data.data[0].username}
              </UserNameText>
            </NameContainer>
            <HmrcContainer>
              <img src="/images/crown.png" alt="" />

              <HmrcText>HMRC connect</HmrcText>
            </HmrcContainer>
          </UserHeader>
        </Header>

        {!showDetails && (
          <>
            <TabsHeaderComponent
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <Container>
              <List>
                <ListHeader>
                  <span>User name</span>
                  <span>Import date</span>
                  <span></span>
                </ListHeader>
                <ListBody>
                  {isLoading ? (
                    <Loading>Loading...</Loading>
                  ) : (
                    !isLoading &&
                    data &&
                    data.data.map((item) => {
                      return (
                        <div key={item.id}>
                          <span>{item.username} </span>
                          <span>{item.date_created} </span>
                          <span>
                            <Button
                              color="primary"
                              onClick={() => onShowDetail(item.id)}
                            >
                              Show
                            </Button>
                          </span>
                        </div>
                      );
                    })
                  )}
                </ListBody>
              </List>
            </Container>
          </>
        )}

        {showDetails && (
          <Container>
            <ContainerList>
              <List isSecondPage>
                <TabsHeaderComponent
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                <ListHeader>
                  <span>Position ID</span>
                  <span>Action</span>
                  <span>Type</span>
                  <span>Close Date</span>
                  <span>units</span>
                  <span>
                    Gain/Loss
                    <br />
                    <ProfitItem color={checkProfitColor(totalProfit)}>
                      ${totalProfit && totalProfit.toFixed(2)}
                    </ProfitItem>
                  </span>
                </ListHeader>
                <ListBody>
                  {isLoadingClosePositions && <Loading>Loading...</Loading>}

                  {!isLoadingClosePositions &&
                    dataClosePositions &&
                    dataClosePositions.data.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          onClick={() => setindexSelected(index)}
                        >
                          <SpanPositionId>{item.position_id} </SpanPositionId>
                          <span>{item.action} </span>
                          <span>
                            <TypeItem background={checkType(item.type)}>
                              {item.type}
                            </TypeItem>
                          </span>
                          <SpanCloseDate>
                            {formatDate(item.close_date)}
                          </SpanCloseDate>
                          <SpanUnits>{item.units} </SpanUnits>
                          <ProfitItem color={checkProfitColor(item.profit)}>
                            ${item.profit}
                          </ProfitItem>
                        </div>
                      );
                    })}
                </ListBody>
              </List>
              {indexSelected > -1 && (
                <DetailsContainer>
                  <DetailsHeader>
                    <DetailsHeaderLogo />
                  </DetailsHeader>
                  <DetailsBody>
                    <div>
                      <div>
                        <span>Position ID:</span>{' '}
                        {dataClosePositions.data[indexSelected].position_id}
                      </div>
                      <div>
                        <span>Action:</span>{' '}
                        {dataClosePositions.data[indexSelected].action}
                      </div>

                      <div>
                        <span>Position ID:</span>{' '}
                        {dataClosePositions.data[indexSelected].position_id}
                      </div>

                      <div>
                        <span>Open Date:</span>{' '}
                        {dataClosePositions.data[indexSelected].open_date}
                      </div>

                      <div>
                        <span>Close Date:</span>{' '}
                        {dataClosePositions.data[indexSelected].close_date}
                      </div>

                      <div>
                        <span>Units:</span>{' '}
                        {dataClosePositions.data[indexSelected].units}
                      </div>

                      <div>
                        <span>Profit:</span>{' '}
                        {dataClosePositions.data[indexSelected].profit}
                      </div>

                      <div>
                        <span>Roller Fees:</span>{' '}
                        {
                          dataClosePositions.data[indexSelected]
                            .rollover_fees_and_dividends
                        }
                      </div>

                      <div>
                        <span>Type:</span>{' '}
                        {dataClosePositions.data[indexSelected].type}
                      </div>
                    </div>
                  </DetailsBody>
                </DetailsContainer>
              )}
            </ContainerList>
          </Container>
        )}
      </Dashboard>
    </Layout>
  );
}

export default Etoro;
