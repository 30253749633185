import React from 'react';

import { GiChessQueen } from 'react-icons/gi';

import { Container, Input } from './styles';

type Props = {
  id: string;
  name: string;
  label: string;
  value: any;
  onChange: (event: any) => void;
  disabled?: boolean;
  editMode: boolean;
  type: string;
  maxWidth?: string;
  height?: string;
  fontSize?: string;
  padding?: string;
  hasCrown?: boolean;
  addPage?: boolean;
};

function EditInputPie({
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  editMode,
  type,
  maxWidth,
  height,
  fontSize,
  padding,
  hasCrown,
  addPage,
}: Props) {
  return (
    <Container>
      <label htmlFor={id}>
        {label}{' '}
        {hasCrown && (
          <GiChessQueen
            size={20}
            color="#57E4CD"
            style={{ position: 'relative', left: 10, bottom: 2 }}
          />
        )}{' '}
      </label>
      <Input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        editMode={editMode}
        maxWidth={maxWidth}
        height={height}
        fontSize={fontSize}
        padding={padding}
        // placeholder={type === 'number' && '£'}
        style={{
          background: disabled && addPage ? '#eee' : 'initial',
        }}
      />

      {type === 'number' && <span className="pound">£</span>}
    </Container>
  );
}

export default EditInputPie;
