import styled from 'styled-components';

export const PrevNextSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 60px 0;
  position: relative;
`;

export const PrevNextFields = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  width: 78%;
`;

export const Select = styled.select`
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 16px;
`;

export const SubmitSection = styled.div`
  position: absolute;
  bottom: -40px;
`;

export const AlertMessage = styled.span`
  color: red;
`;
