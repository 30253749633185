import { TStatus } from './TStatus';

export enum TTaxAssistantRole {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  TAX_ASSISTANT = 3,
  MARKETING = 4,
  DEVS = 5,
  MANAGER = 6,
}

export interface ITaxAssistant {
  id: number;
  role_id: TTaxAssistantRole;
  is_default: boolean;
  email: string;
  salt: string;
  name: string;
  image: string;
  imaphone_numberge: string;
  whatsapp_no: string;
  address: string;
  longitude: number;
  latitude: number;
  note: string;
  status: TStatus;
  available_status: number;
}
