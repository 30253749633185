import styled from 'styled-components';

export const TableBody = styled.div``;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  height: 86px;

  &:hover {
    background-color: #bdfff1;
  }

  &:nth-child(even) {
    background-color: #f6f9fc;
  }
`;

export const DetailsBar = styled.div`
  height: 20px;
  background-color: #ddd;
  margin: 20px 0;
`;

export const DetailsContainer = styled.div`
  padding: 48px 50px;
  border-bottom: 1px solid #ddd;
`;

export const DetailsTitle = styled.h1`
  font-size: 22px;
`;

export const DetailsContent = styled.div`
  display: flex;
  margin: 40px 0;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DocContainer = styled.div`
  display: flex;
  margin: 10px 0;
  column-gap: 180px;
`;

export const DocItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocImage = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const DocContent = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 16px;
    color: #191424;
    font-weight: 600px;
    padding: 8px 0;
  }
`;

export const FileUploaded = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 15px;
  margin-bottom: 10px;
  background-color: #ddd;
  border-radius: 20px;
  width: 150px;

  :hover {
    opacity: 0.5;
  }

  > svg {
    margin-left: 10px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExportAllLinkButton = styled.a`
  background: rgb(87, 228, 205);
  border: none;
  border-radius: 4px;
  padding: 5px 8px;
`;
