import React, { useEffect, useState } from 'react';

import { IoMdSearch } from 'react-icons/io';

import {
  IncomeHeader,
  Select,
} from 'src/components/CustomerInformationTabs/IncomesTab/styles';
import { PayeButton } from 'src/styles/pages/components/PayeButton';

import { Title } from '../../../AddPAYE/styles';
import {
  DateRangeInput,
  FilterButton,
  FilterButtonContainer,
  FiltersInputSection,
  FiltersSection,
  SearchInput,
} from '../../styles';

import {
  ApplyFiltersButton,
  Container,
  DebitCreditButton,
  DebitCreditButtonSection,
  ReconciledOptionsSection,
} from './styles';

function Filters({
  setSearchTransactions,
  setSearchInputsValue,
  setApplyFilters,
  setInitialDate,
  setFinalDate,
  onSearchByDebit,
  setSearchByDebit,
  onSearchByCredit,
  setSearchByCredit,
  onSearchByReconciled,
  setSearchByReconciled,
  setFilterSelected,
}) {
  const [filterClicked, setFilterClicked] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [dateRangeStart, setDateRangeStart] = useState('');
  const [dateRangeEnd, setDateRangeEnd] = useState('');

  useEffect(() => {
    setSearchInputsValue('');
    setInitialDate('');
    setFinalDate('');
    setApplyFilters(false);
    setSearchTransactions(false);
    setSearchByDebit(false);
    setSearchByCredit(false);
    setSearchByReconciled('');

    setFilterSelected(true);

    setTimeout(() => {
      setFilterSelected(false);
    }, 2000);
  }, [filterClicked]);

  const onSearchTransactions = (value: string) => {
    setSearchTransactions(true);
    setSearchInputsValue(value);
  };

  const onSearchByDateRangeTransactions = () => {
    setApplyFilters(true);
    setInitialDate(dateRangeStart);
    setFinalDate(dateRangeEnd);
  };

  return (
    <Container>
      <IncomeHeader>
        <Title>Filters</Title>
      </IncomeHeader>

      <FiltersSection>
        <FilterButton onClick={() => setFilterClicked('search')}>
          <IoMdSearch size={20} />
          Search
        </FilterButton>
        <FilterButton onClick={() => setFilterClicked('date-range')}>
          Date range
        </FilterButton>

        <FilterButton onClick={() => setFilterClicked('reconciled')}>
          Reconciled
        </FilterButton>
      </FiltersSection>

      <FiltersInputSection>
        {filterClicked === 'search' && (
          <FilterButtonContainer>
            <SearchInput
              placeholder="Type a keyword"
              id="searchInput"
              name="searchInput"
              type="text"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            />
            <div>
              <PayeButton onClick={() => onSearchTransactions(searchInput)}>
                Search
              </PayeButton>
            </div>
          </FilterButtonContainer>
        )}

        {filterClicked === 'date-range' && (
          <FilterButtonContainer>
            <DateRangeInput
              id="initialDate"
              name="initialDate"
              type="date"
              value={dateRangeStart}
              onChange={(event) => setDateRangeStart(event.target.value)}
            />
            <DateRangeInput
              id="finalDate"
              name="finalDate"
              type="date"
              value={dateRangeEnd}
              onChange={(event) => setDateRangeEnd(event.target.value)}
            />

            <div>
              <ApplyFiltersButton
                onClick={() => onSearchByDateRangeTransactions()}
              >
                Apply filters
              </ApplyFiltersButton>
            </div>
          </FilterButtonContainer>
        )}

        {filterClicked === 'transaction_type' && (
          <DebitCreditButtonSection>
            <DebitCreditButton onClick={() => onSearchByDebit()}>
              Debit
            </DebitCreditButton>

            <DebitCreditButton onClick={() => onSearchByCredit()}>
              Credit
            </DebitCreditButton>
          </DebitCreditButtonSection>
        )}

        {filterClicked === 'reconciled' && (
          <ReconciledOptionsSection>
            <Select
              id="reconciled"
              onChange={(e) => onSearchByReconciled(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="income_marked">Income marked</option>
              <option value="expense_marked">Expense marked</option>
              <option value="expense_marked_with_receipt">
                Expense marked with receipt
              </option>
            </Select>
          </ReconciledOptionsSection>
        )}
      </FiltersInputSection>
    </Container>
  );
}

export default Filters;
