import styled, { css } from 'styled-components';

interface Props {
  sequential?: number;
  underReview?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const SectionChatInput = styled.div<Props>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: ${({ underReview }) => (underReview ? 8 : 15)}px;
  align-self: flex-end;
  max-width: 75%;
`;

export const ChatInputContent = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  padding: ${(props) => (props.sequential === 1 ? '5px 16px' : '14px')};
  background: ${({ underReview, theme }) =>
    underReview ? theme.colors.red.cosmos : theme.colors.gray.light};
  border: 1px solid
    ${({ underReview, theme }) =>
      underReview ? theme.colors.red.main : theme.colors.gray.geyser};
  border-radius: 20px;
  position: relative;
  margin-right: ${(props) => (props.sequential === 1 ? 'initial' : '36px')};

  /* ::after {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.gray.geyser};
    border-width: 0 0 0 1px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white.default};
    content: '';
    right: -25px;
    bottom: 11px;
    display: ${(props) => (props.sequential === 1 ? 'block' : 'none')};
  } */
`;

export const SectionNotSentActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -135px;
  top: 40px;
  height: 80px;
  width: 155px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.gray.geyser};
  z-index: 1;
`;

export const NotSentAction = styled.div`
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const ChatInputPictureContent = styled.div`
  z-index: 1;
  position: relative;
  right: 12px;
  bottom: 5px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
`;

export const SectionChatAssistantInfo = styled.div`
  position: relative;
`;

export const AssistantInfoDetails = styled.div`
  position: absolute;
  right: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
`;

export const AssistantName = styled.span`
  font-size: 8px;
`;

export const ReadBy = styled.span`
  font-size: 8px;
`;

export const ChatText = styled.span<Props>`
  width: 100%;
  margin-left: 10px;
  border: none;
  color: #44444f;
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px;
`;

export const SectionMessageRead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 56px;
  margin: 0 12px;
  height: 30px;
`;

export const MessageSent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageUnread = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red.main};
  margin-top: 5px;
  margin-left: 45px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

export const ActionButton = styled.button<{ variant?: 'default' | 'remove' }>`
  background: ${({ theme }) => theme.colors.white.default};
  border: 1px solid
    ${({ theme, variant }) =>
      variant === 'remove' ? theme.colors.red.dark : theme.colors.gray.geyser};
  color: ${({ theme, variant }) =>
    variant === 'remove' ? theme.colors.red.dark : theme.colors.gray.regent};
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 25px;
  margin-left: 6px;
  font-size: 10px;
  transition: 0.2s;
  outline: 0 !important;

  img,
  div {
    margin-left: 5px;
    margin-right: -5px;
  }

  &:hover {
    ${({ theme, variant }) =>
      variant === 'remove'
        ? css`
            background: ${theme.colors.red.cosmos};
          `
        : css`
            border: 1px solid ${theme.colors.gray.shark};
          `}
  }
`;

export const MessageRead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Time = styled.span`
  color: #92929d;
  font-weight: 400;
  font-size: 8px;
  margin-right: 8px;
`;

export const SentBySection = styled.span`
  color: #92929d;
  font-size: 8px;
  margin-top: 6px;
  min-width: 100px;
`;
