import { Plus } from 'react-feather';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  display: flex;
  width: ${({ isOpen }) => (isOpen ? 380 : 95)}px;
  height: 100%;
  background: white;
  transition: all 0.2s ease-in-out;
  flex-direction: column;
  padding: 0 0 0
    ${({ theme, isOpen }) => (isOpen ? theme.spacing.s : theme.spacing.s)}px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

export const Conversations = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
`;

export const HeaderButtonsContent = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.m}px 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in-out;
`;

export const Headerbutton = styled.button`
  font-size: ${({ theme }) => theme.fontSizes.body.m}px;
  font-weight: 600;
  height: 38px;
  border: 0;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.s}px;
  padding: 0 ${({ theme }) => theme.spacing.m}px;
  margin-right: ${({ theme }) => theme.spacing.s}px;
`;

export const PlusIcon = styled(Plus)`
  margin-right: ${({ theme }) => theme.spacing.s}px;
`;

export const HeaderSearch = styled.input<Props>`
  font-size: ${({ theme }) => theme.fontSizes.body.m}px;
  height: 50px;
  display: flex;
  border: solid 1px black;
  padding: 15px 50px;
  border-radius: ${({ theme }) => theme.radius.s}px;
  background-color: ${({ theme }) => theme.colors.inputBackground} !important;
  color: ${({ theme }) => theme.colors.gray.shark} !important;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in-out;
  margin-right: ${({ theme }) => theme.spacing.s}px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray.shark} !important;
    font-size: ${({ theme }) => theme.fontSizes.body.m}px;
    opacity: 0.4;
  }
`;

export const HeaderFilters = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const AssistantCardContainer = styled.div`
  position: relative;
`;

export const AssistantSelectedData = styled.div`
  position: absolute;
  font-size: 0.8em;
  bottom: 0;
  left: 40px;
  padding: 2px 8px;
  border-radius: 15px;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AssistantAvatarContent = styled.div`
  position: relative;
  height: 57px;
  width: 57px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const FilterButton = styled.button<Props>`
  border: none;
  background: transparent;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.05);
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  padding-top: 140px;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 170px;
`;
