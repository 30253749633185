import React from 'react';

import { formatDateFullDate } from 'src/utils';
import { AssistantsData } from 'src/utils/staffDataset';

import ShiftBar from '../ShiftBar';

type Props = {
  item: AssistantsData;
  index: number;
};

const StaffShiftsRow: React.FC<Props> = ({ item, index }: Props) => {
  return (
    <>
      <tr>
        <td
          className="text-left"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {item.assistant}
        </td>
        {item &&
          item?.data.map((count, di) => (
            <td key={`count-${count}-${di}`} style={{ verticalAlign: 'top' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span
                  style={{
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <ShiftBar
                    values={item.values[di][0]?.idle}
                    startDate={item.values[di][0]?.start}
                    endDate={item.values[di][0]?.end}
                  ></ShiftBar>
                  {formatDateFullDate(
                    item.values[di][0]?.start,
                    'YYYY-MM-DD HH:mm:ss',
                    'HH:mm',
                  )}{' '}
                  -{' '}
                  {formatDateFullDate(
                    item.values[di][0]?.end,
                    'YYYY-MM-DD HH:mm:ss',
                    'HH:mm',
                  )}
                </span>
                {item.values[di][0]?.start && (
                  <span style={{ fontSize: '12px' }}>
                    Pages: {item.times[di]} <br />
                  </span>
                )}

                {item.values[di][0]?.idle?.map((id: any, index: any) => {
                  return (
                    <span
                      key={index}
                      style={{
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      idle:
                      {formatDateFullDate(
                        id[0],
                        'YYYY-MM-DD HH:mm:ss',
                        'HH:mm',
                      )}{' '}
                      -{' '}
                      {formatDateFullDate(
                        id[1],
                        'YYYY-MM-DD HH:mm:ss',
                        'HH:mm',
                      )}
                    </span>
                  );
                })}
              </div>
            </td>
          ))}
      </tr>
    </>
  );
};

export default StaffShiftsRow;
