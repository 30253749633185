import React, { ChangeEvent, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import {
  enableUserAccount,
  getNotificationsList,
  getTotalCustomers,
  listUsers,
} from 'src/api/users';
import {
  Layout,
  TableHead,
  CustomersRow,
  CustomersPageHeader,
  CustomersPageFilters,
  CustomPagination,
} from 'src/components';
import CustomersPieLayout from 'src/components/customers/CustomersPieLayout';
import { Loading } from 'src/components/ui';
import { usePagination } from 'src/hooks';
import Dashboard from 'src/layouts/Dashboard';
import { ICustomer } from 'src/models';
import {
  Card,
  Col,
  Container,
  CustomInput,
  observer,
  Button,
  Toastify,
} from 'src/modules';
import store from 'src/stores';
import {
  PageHeader,
  LeftSide,
  ReEnableSectionContainer,
  ReEnableSection,
} from 'src/styles/pages/customers/styles';
import { ERRORS, SUCCESS } from 'src/utils';

const CustomersPage: React.FC = () => {
  const customer = store.customer;
  const { isPIE } = store.pieLayout;

  const [data, setData] = useState<ICustomer[]>([]);

  const [selectAll, setSelectAll] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [isLoadingEnableUser, setIsLoadingEnableUser] = useState(false);

  const {
    changePage,
    changePerPage,
    hasPagination,
    isLoading,
    pagRes,
    loadData,
    clearQuery,
    totalEntries,
    selectedCustomers,
    setSelectedCustomers,
  } = usePagination(listUsers, setData, 'listUsers');

  const { data: notifications = [], refetch: getNotifications } = useQuery(
    ['notifications', data],
    () => getNotificationsList(data.map((user) => user.id)),
    { enabled: false },
  );

  const { data: totalCustomers } = useQuery(
    'getTotalCustomers',
    getTotalCustomers,
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onApplyFilters = () => {
    setSelectedCustomers([]);
    loadData();
  };

  const onClearFilters = () => clearQuery();

  const onSelectAll = () => {
    const newData = [...data];
    const newSelection = !selectAll;

    for (let index = 0; index < newData.length; index++) {
      newData[index].selected = newSelection;
    }

    if (newSelection) {
      setSelectedCustomers(newData.map((item) => item.id));
    } else {
      setSelectedCustomers([]);
    }

    setSelectAll(newSelection);
    setData(newData);
  };

  const onSelectCustomer = (item: ICustomer) => {
    const newData = [...data];

    for (let index = 0; index < newData.length; index++) {
      if (newData[index].id === item.id) {
        newData[index].selected = !newData[index].selected;
        break;
      }
    }
    setData(newData);

    const selected = new Set(selectedCustomers);
    selected.add(item.id);
    // setSelectedCustomers([...selected]);
  };

  const onSelectedCustomers = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const newList: number[] = selectedCustomers.filter(
      (el: number) => el !== id,
    );
    if (e.target.checked) newList.push(id);

    setSelectedCustomers(newList);
  };

  const onPaidSelectedItems = async () => {
    await customer.setMultipleTaxStatusPaid(selectedCustomers);
    setSelectedCustomers([]);
    loadData();
  };

  useEffect(() => {
    setHasSelection(selectedCustomers.length >= 1);
  }, [selectedCustomers]);

  useEffect(() => {
    if (data.length > 0) {
      getNotifications();
    }
  }, [data]);

  const { mutate: mutateEnableUserAccount } = useMutation(enableUserAccount, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);
      loadData();

      setTimeout(() => {
        setIsLoadingEnableUser(false);
      }, 4000);
    },
    onError: () => {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    },
  });

  const onEnableUserAccount = (userId: number) => {
    mutateEnableUserAccount(userId);

    setTimeout(() => {
      setIsLoadingEnableUser(true);
    }, 2000);
  };

  return (
    <Layout>
      <Dashboard
        title={`Customers (${totalEntries} / ${totalCustomers?.total})`}
      >
        <PageHeader>
          <LeftSide>
            <CustomersPageHeader
              onApplyFilters={onApplyFilters}
              loadData={loadData}
              onClearFilters={onClearFilters}
            />
          </LeftSide>
        </PageHeader>
        <CustomersPageFilters
          showActionButtons={hasSelection}
          selectedCustomers={selectedCustomers}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
          onPaidSelectedItems={onPaidSelectedItems}
          totalEntries={data.length}
          isPIE={isPIE}
        />

        {isPIE ? (
          <CustomersPieLayout />
        ) : (
          <Card className="overflow-visible">
            <Container className="mw-100">
              <TableHead className="pt-1 pb-1 pl-1 no-gutters">
                <Col className="col-index text-center">#</Col>
                <Col className="col-index">
                  <CustomInput
                    type="checkbox"
                    id="selectAllCustomersID"
                    name="selectAllCustomers"
                    checked={selectAll}
                    onChange={() => onSelectAll()}
                  />
                </Col>
                <Col className="col-name">Name</Col>
                <Col className="col-progress text-center">Progress</Col>
                <Col className="col-status text-center">Status</Col>
                <Col className="col-auto"></Col>
                <Col className="col-tax text-center">Tax Refund</Col>
                <Col className="col-active text-center text-truncate">
                  Active
                </Col>
                <Col className="col-action text-center d-flex justify-content-center">
                  Action
                </Col>
              </TableHead>

              {(isLoading || isLoadingEnableUser) && (
                <Container
                  className="position-relative mw-100"
                  style={{ overflow: 'hidden' }}
                >
                  <Loading />
                </Container>
              )}

              {data.map((item: ICustomer, index: number) => (
                <div key={item.id}>
                  {item.status === 'Delete' ? (
                    <ReEnableSectionContainer>
                      <ReEnableSection />
                      <Button
                        type="submit"
                        color="primary"
                        className="btn btn-primary mr-4 re-enable-button"
                        onClick={() => onEnableUserAccount(item.id)}
                      >
                        Enable Account
                      </Button>
                      <CustomersRow
                        item={{
                          ...item,
                          notifications_count:
                            notifications.find(
                              (user: any) =>
                                item.id === user.conversation_user_id,
                            )?.notification_count ?? 0,
                        }}
                        index={index}
                        onSelectCustomer={onSelectCustomer}
                        onSelectedCustomers={onSelectedCustomers}
                      />
                    </ReEnableSectionContainer>
                  ) : (
                    <CustomersRow
                      key={`customer-${item.id}`}
                      item={{
                        ...item,
                        notifications_count:
                          notifications.find(
                            (user: any) =>
                              item.id === user.conversation_user_id,
                          )?.notification_count ?? 0,
                      }}
                      index={index}
                      onSelectCustomer={onSelectCustomer}
                      onSelectedCustomers={onSelectedCustomers}
                    />
                  )}
                </div>
              ))}
            </Container>
          </Card>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={changePage}
            changePerPage={changePerPage}
            position="center"
          />
        )}
      </Dashboard>
    </Layout>
  );
};

export default observer(CustomersPage);
