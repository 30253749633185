import request from '../request';

export const getSettings = async (): Promise<SettingsValue[]> => {
  const { data } = await request.get('/settings');

  return data.data;
};

export const editSettings = async (
  setting: EditSettingsParams,
): Promise<EditSettingsParams> => {
  const { data } = await request.post('/settings/edit/', setting);

  return data;
};
