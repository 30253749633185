import { QueryFunctionContext } from 'react-query';

import { ICustomerData } from 'src/models';
import {
  CustomerCreateData,
  CustomerInformation,
  TaxStatusResponse,
} from 'src/utils/types/Customer';

import request from './request';
import ResponseError from './ResponseError';

export const fetchCustomers = async (
  search?: string,
): Promise<ICustomerData> => {
  try {
    const { data } = await request.get(`users${search}`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getCustomerInformation = async (
  userId: number,
  taxYear: number,
): Promise<CustomerInformation> => {
  try {
    const { data } = await request.get(
      `/v2/users/get-user-information?user_id=${userId}&tax_year=${taxYear}`,
    );

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setTaxStatusBlank = async (
  userId: number[],
  taxYear: number,
): Promise<TaxStatusResponse> => {
  try {
    const { data } = await request.post(`/user-taxes/set-blank`, {
      user_id: userId,
      tax_year: taxYear,
      all_years: false,
    });

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setTaxPaymentDue = async (
  userId: number[],
  taxYear: number,
): Promise<TaxStatusResponse> => {
  try {
    const response: any = await request.post(`/users/set-payment-due`, {
      user_id: userId,
      tax_year: taxYear,
    });

    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setTaxStatusPaid = async (
  userId: number[],
  taxYear: number,
): Promise<TaxStatusResponse> => {
  try {
    const response: any = await request.post(`/user-taxes/set-paid`, {
      user_id: userId,
      tax_year: taxYear,
      all_years: false,
    });

    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setMultipleTaxStatusBlank = async (
  userId: number[],
): Promise<TaxStatusResponse> => {
  try {
    const { data } = await request.post(`/user-taxes/set-blank`, {
      user_id: userId,
      all_years: true,
    });

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setMultipleTaxStatusPaid = async (
  userId: number[],
): Promise<TaxStatusResponse> => {
  try {
    const data = {
      user_id: userId,
    };
    const response: any = await request.post(
      `users/overview/set-paid-all-years`,
      data,
    );
    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const setMultipleTaxPaymentDue = async (
  userId: number[],
): Promise<TaxStatusResponse> => {
  try {
    const response: any = await request.post(
      `users/overview/set-payment-due-all-years`,
      { user_id: userId },
    );

    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const cancelPaymentDue = async (
  userId: number,
  taxYear: number,
): Promise<TaxStatusResponse> => {
  try {
    const data = {
      user_id: userId,
      tax_year: taxYear,
    };

    const response: any = await request.post(
      '/user-taxes/cancel-payment-due',
      data,
    );

    return response;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const createCustomer = async (data: CustomerCreateData) => {
  try {
    await request.post('users/create', data);
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const updateUserDetails = async (
  userId: number,
  data: CustomerInformation,
): Promise<void> => {
  try {
    await request.post(`/users/edit?id=${userId}`, data);
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getHowYouHere = async () => {
  try {
    const { data } = await request.get(`/how-you-here`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getCustomersContingency = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, nextPage] = queryKey;

  try {
    const { data } = await request.get(`/contingency?page=${nextPage}`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getContingencyDownloadLink = async (url: string) => {
  try {
    const { data } = await request.get(`${url}`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

type Flags = {
  started: boolean;
  deleted: boolean;
  contingency_user_id: number;
  ready_to_submit: boolean;
  submitted: boolean;
};

export const updateContingencyFlags = async ({
  started,
  deleted,
  contingency_user_id,
  ready_to_submit,
  submitted,
}: Flags) => {
  try {
    const { data } = await request.post(`/contingency/update`, {
      started,
      deleted,
      contingency_user_id,
      ready_to_submit,
      submitted,
    });

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getExportAll = async () => {
  try {
    const { data } = await request.get('/contingency/export');

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
