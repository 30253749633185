import request from '../request';

export const listCategories = async (): Promise<Category[]> => {
  const { data } = await request.get(`/categories`);

  return data.data;
};

export const createCategory = async (
  name: string,
  description: string,
): Promise<Category> => {
  const { data } = await request.post(`/categories/create`, {
    name,
    description,
  });

  return data;
};

export const editCategory = async (
  id: string,
  name: string,
  description: string,
): Promise<Category> => {
  const { data } = await request.post(`/categories/edit?id=${id}`, {
    name,
    description,
  });

  return data;
};
