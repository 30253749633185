import request from "../request";
import ResponseError from "../ResponseError";

export const getSuggestions = async (): Promise<GetSuggestionsResponse> => {
  try {
    const { data } = await request.get(`/cost-suggestions`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const editSuggestions = async (suggestions: SuggestionValues[]): Promise<EditSuggestionsResponse> => {
  try {
    const { data } = await request.post(`/cost-suggestions/edit`, suggestions);

    return data
  } catch (error: any) {
    throw new ResponseError(error)
  }
}
