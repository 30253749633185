import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-left: 10px;
  width: 100%;
`;

export const LogoBankContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  margin-bottom: 30px;

  div {
    width: 70px;
    height: 70px;
    border-radius: 45px;
    border: 1px solid #191424;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

export const BankInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-weight: 600;
    font-size: 16px;
    color: #191424;
    margin-bottom: 0px;
    width: 100%;
  }

  span {
    font-weight: 400;
  }
`;
