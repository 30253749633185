import React, { useEffect, useState } from 'react';

import { StringParam, useQueryParam } from 'use-query-params';

import { useYear } from 'src/hooks';
import { TIsPaidStatus, TTaxStatus } from 'src/models';
import { Toastify, observer } from 'src/modules';
import { Storage } from 'src/services';
import store from 'src/stores';
import { PaidStatusPage } from 'src/utils';
import { ERRORS } from 'src/utils/enums/messages.enum';
import { handleSuperAdminRole } from 'src/utils/helpers/handleSuperAdminRole';

import ButtonIcon from '../../ButtonIcon';

import { CancelIcon, CancelPaymentText, PaymentDueContainer } from './styles';

type Props = {
  id: number;
  year: number;
  is_paid_status?: TIsPaidStatus;
  tax_status: TTaxStatus;
  type: PaidStatusPage;
  allYears?: boolean;
  iconDisabled?: boolean;
  onClickPaidStatus?(value: TIsPaidStatus): void;
};

const IconPaidStatus: React.FC<Props> = ({
  id,
  year,
  is_paid_status,
  tax_status,
  type,
  allYears,
  iconDisabled = false,
  onClickPaidStatus,
}) => {
  const customer = store.customer;
  const hmrc = store.hmrc;

  const [userId] = useQueryParam('id', StringParam);
  const [userTaxYear] = useYear();
  const [local, setLocal] = useState<TIsPaidStatus>(is_paid_status);
  const [clickDisable, setClickDisable] = useState<boolean>(false);

  // Only role_id = TTaxAssistantRole.SUPER_ADMIN can change the status to Paid
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  let theYear = year;
  if (!theYear || isNaN(theYear)) {
    theYear = userTaxYear;
  }

  const onLoad = async () => {
    if (type === PaidStatusPage.INTERNAL && !allYears) {
      const response = await hmrc.getHmrc(Number(userId), Number(theYear));
      setLocal(response.is_paid_status);
    } else {
      setLocal(is_paid_status);
    }
  };

  const loadUser = async () => {
    const userData = await Storage.getUser();
    const credentials = handleSuperAdminRole(userData.role_id);

    setIsSuperAdmin(credentials);
  };

  const changeNotAllowed = () =>
    alert(`Change not allowed. Because tax status is: ${tax_status}`);

  // Blank -> PD (tax_status == inProgress)
  // PD -> Paid (tax_status == inProgress)
  // Paid -> Blank (tax_status == Paid)

  const setBlank = async () => {
    if (tax_status !== 'Paid') {
      changeNotAllowed();
      return;
    }

    try {
      if (type === PaidStatusPage.OVERVIEW) {
        await customer.setMultipleTaxStatusBlank([id]);
      } else {
        await customer.setTaxStatusBlank(id, theYear);
      }
      onClickPaidStatus && onClickPaidStatus('Blank');
      setLocal('Blank');
    } catch (error) {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    } finally {
      setClickDisable(false);
    }
  };

  const setPaymentDue = async () => {
    if (tax_status !== 'InProgress') {
      changeNotAllowed();
      return;
    }

    try {
      setClickDisable(true);
      if (type === PaidStatusPage.OVERVIEW) {
        await customer.setMultipleTaxPaymentDue([id]);
      } else {
        await customer.setTaxPaymentDue(id, year);
      }
      onClickPaidStatus && onClickPaidStatus('PaymentDue');
      setLocal('PaymentDue');
    } catch (error) {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    } finally {
      setClickDisable(false);
    }
  };

  const setPaid = async () => {
    if (!isSuperAdmin) {
      Toastify.toast.error(ERRORS.USER_DONT_HAVE_CREDTENTIALS);
      return;
    }

    if (tax_status !== 'InProgress') {
      changeNotAllowed();
      return;
    }

    try {
      setClickDisable(true);
      if (type === PaidStatusPage.OVERVIEW) {
        await customer.setMultipleTaxStatusPaid([id]);
      } else {
        await customer.setTaxStatusPaid(id, year);
      }
      onClickPaidStatus && onClickPaidStatus('Paid');
      setLocal('Paid');
    } catch (error) {
      Toastify.toast.error(ERRORS.GENERIC_ERROR);
    } finally {
      setClickDisable(false);
    }
  };

  const onCancelPaymentDue = async () => {
    try {
      setClickDisable(true);
      await customer.cancelPaymentDue(id, year);
      setLocal('Blank');
      onClickPaidStatus && onClickPaidStatus('Blank');
    } catch (error) {
      Toastify.toast.error(error.message);
    } finally {
      setClickDisable(false);
    }
  };

  const onChange = async () => {
    switch (local) {
      case 'Paid':
        setBlank();
        break;

      case 'Blank':
        setPaymentDue();
        break;

      case 'PaymentDue':
        setPaid();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    onLoad();
  }, [theYear]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <PaymentDueContainer>
      <ButtonIcon
        color={local === 'Paid' ? 'primary' : 'secondary'}
        outline={local === 'Blank'}
        onClick={onChange}
        tooltip={local}
        disabled={clickDisable || iconDisabled}
        id={`paid-status-${id}-${year}`}
      >
        <i className="icon-customers-row icon-pound" />
      </ButtonIcon>
      {local === 'PaymentDue' && (
        <CancelIcon onClick={onCancelPaymentDue}>X</CancelIcon>
      )}
    </PaymentDueContainer>
  );
};

export default observer(IconPaidStatus);
