import React from 'react';

type Props = {
    active: string;
    type:string;
    children:any
};

export const SectionContainer = ( { active, type, children }:Props ) => {

    return (
        <>
            { (active === type) ? <div id={type}>{children}</div>: <></> }
        </>
    )
}