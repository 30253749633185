import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { createSA103F, getSA103F, updateSA103F } from 'src/api/forms-tab';
import { Button, Input, Label, Toastify } from 'src/modules';
import { SUCCESS } from 'src/utils';

import { FormBusiness } from '../../FormState';

import {
  onInputChange,
  onCheckboxChange,
  onCurrencyInputChange,
} from '../../utils';
import CurrencyInput from 'react-currency-masked-input';

import {
  Card,
  CheckboxLabel,
  FormContainer,
  InputForm,
  SubmitButton,
  Subtitle,
  Title,
} from '../../styles';

type Props = {
  sa103FformId: number;
  taxYear: number;
  taxReturnId: number;
  view: boolean;
  onClick?: (e: any) => void;
};

export const SA103f = ({
  sa103FformId,
  taxYear,
  taxReturnId,
  view,
  onClick,
}: Props) => {
  const [formSA103FState, setformSA103FState] =
    useState<FormBusinessType>(FormBusiness);

  const { data, isLoading, refetch } = useQuery(
    'getSA103F',
    () => getSA103F(sa103FformId),
    {
      enabled: false,
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (sa103FformId) {
      refetch();
    }
  }, [sa103FformId]);

  const { mutate: mutationCreate103F } = useMutation(createSA103F, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if (onClick)
        onClick({ target: { id: 'listFormsTaxReturn', value: taxReturnId } });
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  const { mutate: mutationUpdateSA103F } = useMutation(updateSA103F, {
    onSuccess() {
      Toastify.toast.success(SUCCESS.GENERIC);
      if (onClick)
        onClick({ target: { id: 'listFormsTaxReturn', value: taxReturnId } });
    },
    onError({ response }) {
      Toastify.toast.error(response?.data?.message);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setformSA103FState(data.data);
      } else {
        setformSA103FState(FormBusiness);
      }
    }
  }, [isLoading]);

  const onSubmitForm = () => {
    if (sa103FformId === 0) {
      const formData = {
        ...formSA103FState,
        tax_return_id: taxReturnId,
      };

      mutationCreate103F(formData);
    } else {
      const formData = {
        ...formSA103FState,
        sa103f_form_id: sa103FformId,
      };

      mutationUpdateSA103F(formData);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <Title>
            SA103F
            {view ? '- View' : ''}
          </Title>
          <FormContainer>
            <Title>Business details</Title>
            <InputForm>
              <Label>[FSE1] Business name (required)</Label>
              <Input
                type="text"
                bsSize="lg"
                value={formSA103FState.business_name}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                id="business_name"
                name="business_name"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE2] Description of business (required)</Label>
              <Input
                type="text"
                bsSize="lg"
                value={formSA103FState.business_description}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                id="business_description"
                name="business_description"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE3] First line of your business address – unless you work
                from home
              </Label>
              <Input
                type="text"
                bsSize="lg"
                value={formSA103FState.business_address_first_line}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                id="business_address_first_line"
                name="business_address_first_line"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE4] Postcode of your business address</Label>
              <Input
                type="text"
                bsSize="lg"
                value={formSA103FState.business_address_postcode}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                id="business_address_postcode"
                name="business_address_postcode"
              />
            </InputForm>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.change_of_business_details}
                checked={formSA103FState.change_of_business_details}
                onChange={(e) =>
                  onCheckboxChange(e, formSA103FState, setformSA103FState)
                }
                name="change_of_business_details"
                id="change_of_business_details"
              />
              <Label lg>
                [FSE5] If the details in boxes [FSE1], [FSE2], [FSE3] or [FSE4]
                have changed in the last 12 months, check the box and give
                details in the 'Any other information' box
              </Label>
            </CheckboxLabel>
            <InputForm>
              <Label>
                [FSE6] If your business started after 5 April {taxYear - 1},
                enter the start date
              </Label>
              <Input
                id="date_business_started"
                name="date_business_started"
                value={formSA103FState.date_business_started}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                type="date"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE7] If your business ceased after 5 April {taxYear - 1} but
                before 6 April {taxYear}, enter the final date of trading
              </Label>
              <Input
                id="date_business_ceased"
                name="date_business_ceased"
                value={formSA103FState.date_business_ceased}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                type="date"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE8] Date your books or accounts start – the beginning of your
                accounting period
              </Label>
              <Input
                id="date_accounting_period_starts"
                name="date_accounting_period_starts"
                value={formSA103FState.date_accounting_period_starts}
                type="date"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE9] Date your books or accounts are made up to or the end of
                your accounting period – read the notes if you have filled in
                boxes [FSE6] or [FSE7]
              </Label>
              <Input
                id="date_accounting_period_ends"
                name="date_accounting_period_ends"
                value={formSA103FState.date_accounting_period_ends}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                type="date"
                bsSize="lg"
              />
            </InputForm>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.cash_basis}
                checked={formSA103FState.cash_basis}
                onChange={(e) =>
                  onCheckboxChange(e, formSA103FState, setformSA103FState)
                }
                name="cash_basis"
                id="cash_basis"
              />
              <Label lg>
                [FSE10] If you used cash basis, money actually received and paid
                out, to calculate your income and expenses, check the box
              </Label>
            </CheckboxLabel>
            <Title>Other information</Title>

            {taxYear <= 2023 ? (
              <>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value={formSA103FState.permanent_accounting_date_change}
                    checked={formSA103FState.permanent_accounting_date_change}
                    onChange={(e) =>
                      onCheckboxChange(e, formSA103FState, setformSA103FState)
                    }
                    name="permanent_accounting_date_change"
                    id="permanent_accounting_date_change"
                  />
                  <Label lg>
                    [FSE11] If your accounting date has changed permanently,
                    check the box
                  </Label>
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value={formSA103FState.multiple_accounting_date_changes}
                    checked={formSA103FState.multiple_accounting_date_changes}
                    onChange={(e) =>
                      onCheckboxChange(e, formSA103FState, setformSA103FState)
                    }
                    name="multiple_accounting_date_changes"
                    id="multiple_accounting_date_changes"
                  />
                  <Label lg>
                    [FSE12] If your accounting date has changed more than once
                    since {taxYear - 6}, check the box
                  </Label>
                </CheckboxLabel>
              </>
            ) : (
              <></>
            )}

            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.special_arrangements_apply}
                checked={formSA103FState.special_arrangements_apply}
                onChange={(e) =>
                  onCheckboxChange(e, formSA103FState, setformSA103FState)
                }
                name="special_arrangements_apply"
                id="special_arrangements_apply"
              />
              <Label lg>
                [FSE13] If special arrangements apply, check the box
              </Label>
            </CheckboxLabel>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.information_provided_last_year}
                checked={formSA103FState.information_provided_last_year}
                onChange={(e) =>
                  onCheckboxChange(e, formSA103FState, setformSA103FState)
                }
                name="information_provided_last_year"
                id="information_provided_last_year"
              />
              <Label lg>
                [FSE14] If you provided the information about your {taxYear - 1}
                –{taxYear.toString().substring(-2)}
                profit on last year's tax return, check the box
              </Label>
            </CheckboxLabel>

            <Title>Business income</Title>
            <InputForm>
              <Label>
                [FSE15] Your turnover – the takings, fees, sales or money earned
                by your business
                {taxYear == 2022
                  ? '. Do not include Self-Employment Income Support Scheme (SEISS) grants in this box, put them in [FSE70.1]'
                  : ''}
              </Label>
              <CurrencyInput
                value={formSA103FState.turnover}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="turnover"
                name="turnover"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE16]
                {taxYear >= 2023
                  ? 'Any other business income not included in box [FSE15] – also include any COVID support payments such as CJRS'
                  : taxYear == 2022
                  ? 'Any other business income (do not include SEISS, but include other COVID support payments such as CJRS)'
                  : taxYear == 2021
                  ? 'Any other business income (include coronavirus support payments such as CJRS, but not SEISS)'
                  : taxYear == 2020
                  ? 'Any other business income not included in box [FSE15]'
                  : ''}
              </Label>
              <CurrencyInput
                value={formSA103FState.other_business_income}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="other_business_income"
                name="other_business_income"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE16.1] Trading income allowance – read the notes</Label>
              <CurrencyInput
                value={formSA103FState.trading_income_allowance}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="trading_income_allowance"
                name="trading_income_allowance"
              />
            </InputForm>
            <Title>Business expenses</Title>
            <Subtitle>
              Please read the 'Self-employment (full) notes' before filling in
              this section.
            </Subtitle>
            <Card>
              <Title>Total Expenses</Title>
              <Subtitle>
                If annual turnover was below £85,000, you may just put total
                expenses in [FSE31]
              </Subtitle>
              <InputForm>
                <Label>
                  [FSE17] Cost of goods bought for resale or goods used
                </Label>
                <CurrencyInput
                  value={formSA103FState.cost_of_goods}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="cost_of_goods"
                  name="cost_of_goods"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE18] Construction industry – payments to subcontractors
                </Label>
                <CurrencyInput
                  value={formSA103FState.subcontractor_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="subcontractor_costs"
                  name="subcontractor_costs"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE19] Wages, salaries and other staff costs</Label>
                <CurrencyInput
                  value={formSA103FState.wages_salaries_and_staff_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="wages_salaries_and_staff_costs"
                  name="wages_salaries_and_staff_costs"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE20] Car, van and travel expenses</Label>
                <CurrencyInput
                  value={formSA103FState.car_van_and_travel_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="car_van_and_travel_expenses"
                  name="car_van_and_travel_expenses"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE21] Rent, rates, power and insurance costs</Label>
                <CurrencyInput
                  value={formSA103FState.rent_and_other_property_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="rent_and_other_property_costs"
                  name="rent_and_other_property_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE22] Repairs and maintenance of property and equipment
                </Label>
                <CurrencyInput
                  value={formSA103FState.repairs_and_maintenance_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="repairs_and_maintenance_costs"
                  name="repairs_and_maintenance_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE23] Phone, fax, stationery and other office costs
                </Label>
                <CurrencyInput
                  value={formSA103FState.phone_and_other_office_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="phone_and_other_office_costs"
                  name="phone_and_other_office_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE24] Advertising and business entertainment costs
                </Label>
                <CurrencyInput
                  value={formSA103FState.advertising_and_entertainment_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="advertising_and_entertainment_costs"
                  name="advertising_and_entertainment_costs"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE25] Interest on bank and other loans</Label>
                <CurrencyInput
                  value={formSA103FState.bank_and_loan_interest}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="bank_and_loan_interest"
                  name="bank_and_loan_interest"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE26] Bank, credit card and other financial charges
                </Label>
                <CurrencyInput
                  value={formSA103FState.other_finance_charges}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="other_finance_charges"
                  name="other_finance_charges"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE27] Irrecoverable debts written off</Label>
                <CurrencyInput
                  value={formSA103FState.debts_written_off}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="debts_written_off"
                  name="debts_written_off"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE28] Accountancy, legal and other professional fees
                </Label>
                <CurrencyInput
                  value={formSA103FState.accountancy_and_legal_fees}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="accountancy_and_legal_fees"
                  name="accountancy_and_legal_fees"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE29] Depreciation and loss or profit on sale of assets
                </Label>
                <CurrencyInput
                  value={formSA103FState.depreciation_and_loss_profit_on_sale}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="depreciation_and_loss_profit_on_sale"
                  name="depreciation_and_loss_profit_on_sale"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE30] Other business expenses</Label>
                <CurrencyInput
                  value={formSA103FState.other_business_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="other_business_expenses"
                  name="other_business_expenses"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE31] Total expenses (total of boxes [FSE17] to [FSE30])
                </Label>
                <CurrencyInput
                  value={formSA103FState.total_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="total_expenses"
                  name="total_expenses"
                />
              </InputForm>
            </Card>

            <Card>
              <Title>Disallowable Expenses</Title>
              <Subtitle>
                Use this column if the figures in [FSE17] to [FSE30] include
                disallowable amounts
              </Subtitle>
              <InputForm>
                <Label>
                  [FSE32] Disallowable cost of goods bought for resale or goods
                  used
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_cost_of_goods}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_cost_of_goods"
                  name="disallowable_cost_of_goods"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE33] Disallowable construction industry – payments to
                  subcontractors
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_subcontractor_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_subcontractor_costs"
                  name="disallowable_subcontractor_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE34] Disallowable wages, salaries and other staff costs
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_staff_costs}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_staff_costs"
                  name="disallowable_staff_costs"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE35] Disallowable car, van and travel expenses</Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_car_and_travel_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_car_and_travel_expenses"
                  name="disallowable_car_and_travel_expenses"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE36] Disallowable rent, rates, power and insurance costs
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_rent_and_other_property_costs
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_rent_and_other_property_costs"
                  name="disallowable_rent_and_other_property_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE37] Disallowable repairs and maintenance of property and
                  equipment
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_repairs_and_maintenance_costs
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_repairs_and_maintenance_costs"
                  name="disallowable_repairs_and_maintenance_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE38] Disallowable phone, fax, stationery and other office
                  costs
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_phone_and_other_office_costs
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_phone_and_other_office_costs"
                  name="disallowable_phone_and_other_office_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE39] Disallowable advertising and business entertainment
                  costs
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_advertising_and_entertainment_costs
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_advertising_and_entertainment_costs"
                  name="disallowable_advertising_and_entertainment_costs"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE40] Disallowable interest on bank and other loans
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_bank_and_loan_interest}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_bank_and_loan_interest"
                  name="disallowable_bank_and_loan_interest"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE41] Disallowable bank, credit card and other financial
                  charges
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_other_finance_charges}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_other_finance_charges"
                  name="disallowable_other_finance_charges"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE42] Disallowable irrecoverable debts written off
                </Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_debts_written_off}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_debts_written_off"
                  name="disallowable_debts_written_off"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE43] Disallowable accountancy, legal and other professional
                  fees
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_accountancy_and_legal_fees
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_accountancy_and_legal_fees"
                  name="disallowable_accountancy_and_legal_fees"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE44] Disallowable depreciation and loss or profit on sale
                  of assets
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disallowable_depreciation_and_loss_profit_on_sale
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_depreciation_and_loss_profit_on_sale"
                  name="disallowable_depreciation_and_loss_profit_on_sale"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE45] Disallowable other business expenses</Label>
                <CurrencyInput
                  value={formSA103FState.disallowable_other_business_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disallowable_other_business_expenses"
                  name="disallowable_other_business_expenses"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE46] Total disallowable expenses (total of boxes [FSE32] to
                  [FSE45])
                </Label>
                <CurrencyInput
                  value={formSA103FState.total_disallowable_expenses}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="total_disallowable_expenses"
                  name="total_disallowable_expenses"
                />
              </InputForm>
            </Card>
            <Title>Net profit or loss</Title>
            <InputForm>
              <Label>
                [FSE47/FSE48] Net profit or loss ([FSE15] + [FSE16] - [FSE31])
              </Label>
              <CurrencyInput
                value={formSA103FState.net_profit_loss}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="net_profit_loss"
                name="net_profit_loss"
              />
            </InputForm>
            <Title>
              Tax allowances for vehicles and equipment (capital allowances)
            </Title>
            <Subtitle>
              There are 'capital' tax allowances for vehicles, equipment and
              certain buildings used in your business (do not include the cost
              of these in your business expenses). Please read the
              'Self-employment (full) notes' and use the examples to work out
              your capital allowances.
            </Subtitle>
            <InputForm>
              <Label>[FSE49] Annual Investment Allowance</Label>
              <CurrencyInput
                value={formSA103FState.annual_investment_allowance}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="annual_investment_allowance"
                name="annual_investment_allowance"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE50] Capital allowances at 18% on equipment, including cars
                with lower CO2 emissions
              </Label>
              <CurrencyInput
                value={formSA103FState.annual_allowances_at_higher_rate}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="annual_allowances_at_higher_rate"
                name="annual_allowances_at_higher_rate"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE51] Capital allowances at 6% on equipment, including cars
                with higher CO2 emissions
              </Label>
              <CurrencyInput
                value={formSA103FState.annual_allowances_at_lower_rate}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="annual_allowances_at_lower_rate"
                name="annual_allowances_at_lower_rate"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE52] Zero-emission goods vehicle allowance</Label>
              <CurrencyInput
                value={formSA103FState.zero_emission_goods_vehicle_allowance}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="zero_emission_goods_vehicle_allowance"
                name="zero_emission_goods_vehicle_allowance"
              />
            </InputForm>

            {taxYear >= 2022 ? (
              <InputForm>
                <Label>[FSE52.1] Zero-emission car allowance</Label>
                <CurrencyInput
                  value={formSA103FState.zero_emission_car_allowance}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="zero_emission_car_allowance"
                  name="zero_emission_car_allowance"
                />
              </InputForm>
            ) : (
              <></>
            )}

            <InputForm>
              <Label>[FSE53] The Structures and Buildings Allowance</Label>
              <CurrencyInput
                value={formSA103FState.the_structures_and_buildings_allowance}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="the_structures_and_buildings_allowance"
                name="the_structures_and_buildings_allowance"
              />
            </InputForm>

            {taxYear >= 2022 ? (
              <InputForm>
                <Label>
                  [FSE53.1]
                  {taxYear >= 2024
                    ? 'Freeport and Investment Zones Structures and Buildings Allowance'
                    : 'Freeport Structures and Buildings Allowance'}
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.freeport_structures_and_buildings_allowance
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="freeport_structures_and_buildings_allowance"
                  name="freeport_structures_and_buildings_allowance"
                />
              </InputForm>
            ) : (
              <></>
            )}

            <InputForm>
              <Label>[FSE54] Electric charge-point allowance</Label>
              <CurrencyInput
                value={formSA103FState.electric_charge_point_allowance}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="electric_charge_point_allowance"
                name="electric_charge_point_allowance"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE55] 100% and other enhanced capital allowances</Label>
              <CurrencyInput
                value={formSA103FState.other_capital_allowances}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="other_capital_allowances"
                name="other_capital_allowances"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE56] Allowances on sale or cessation of business use (where
                you've disposed of assets for less than their tax value)
              </Label>
              <CurrencyInput
                value={
                  formSA103FState.balancing_allowances_on_sale_or_cessation
                }
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="balancing_allowances_on_sale_or_cessation"
                name="balancing_allowances_on_sale_or_cessation"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE57] Total capital allowances (total of boxes [FSE49] to
                [FSE56])
              </Label>
              <CurrencyInput
                value={formSA103FState.total_capital_allowances}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="total_capital_allowances"
                name="total_capital_allowances"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE59] Balancing charge on sales of assets or on the cessation
                of business use (including where Business Premises Renovation
                Allowance has been claimed) for example, where you've disposed
                of assets for more than their tax value
              </Label>
              <CurrencyInput
                value={formSA103FState.total_balancing_charges}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="total_balancing_charges"
                name="total_balancing_charges"
              />
            </InputForm>
            <Title>Calculating your taxable profit or loss</Title>
            <Subtitle>
              You may have to adjust your net profit or loss for disallowable
              expenses or capital allowances to arrive at your taxable profit or
              your loss for tax purposes. Please read the 'Self-employment
              (full) notes' and fill in the boxes below that apply.
            </Subtitle>
            <InputForm>
              <Label>[FSE60] Goods and services for your own use</Label>
              <CurrencyInput
                value={formSA103FState.own_goods_and_services}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="own_goods_and_services"
                name="own_goods_and_services"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE61] Total additions to net profit or deductions from net
                loss ([FSE46] + [FSE59] + [FSE60])
              </Label>
              <CurrencyInput
                value={
                  formSA103FState.additions_to_net_profit_deductions_from_net_loss
                }
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="additions_to_net_profit_deductions_from_net_loss"
                name="additions_to_net_profit_deductions_from_net_loss"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE62] Income, receipts and other profits included in business
                income or expenses but not taxable as business profits
              </Label>
              <CurrencyInput
                value={formSA103FState.non_taxable_business_income}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="non_taxable_business_income"
                name="non_taxable_business_income"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE63] Total deductions from net profit or additions to net
                loss ([FSE57] + [FSE62])
              </Label>
              <CurrencyInput
                value={
                  formSA103FState.deductions_from_net_profit_additions_to_net_loss
                }
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="deductions_from_net_profit_additions_to_net_loss"
                name="deductions_from_net_profit_additions_to_net_loss"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE64/FSE65] Net business profit or loss for tax purposes
                ([FSE47/FSE48] + [FES61] - [FSE63])
              </Label>
              <CurrencyInput
                value={formSA103FState.net_business_profit_loss_for_tax}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="net_business_profit_loss_for_tax"
                name="net_business_profit_loss_for_tax"
              />
            </InputForm>

            <Title>Calculating your taxable profit or loss (continued)</Title>
            <Subtitle>
              If you start or finish self-employment and your accounting period
              is not the same as your basis period (or there are overlaps or
              gaps in your basis periods) or in certain situations or trades or
              professions, you may need to make further tax adjustments – read
              the 'Self-employment (full) notes'. In all cases, please complete
              boxes [FSE73] and [FSE76], or box [FSE77], as applicable.
            </Subtitle>
            <Subtitle>
              If your total profits from all Self-employments and Partnerships
              for {taxYear - 1}–{taxYear.toString().substring(-2)} are less than
              {taxYear >= 2023
                ? '£6,725,'
                : taxYear == 2022
                ? '£6,515,'
                : taxYear == 2021
                ? '£6,475,'
                : taxYear == 2020
                ? '£6,365,'
                : ''}
              you do not have to pay Class 2 National Insurance contributions,
              but you may want to pay voluntarily (box [FSE100]) to protect your
              rights to certain benefits.
            </Subtitle>
            <InputForm>
              <Label>[FSE66] Date your basis period began</Label>
              <Input
                id="date_basis_period_began"
                name="date_basis_period_began"
                value={formSA103FState.date_basis_period_began}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                type="date"
                bsSize="lg"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE67] Date your basis period ended</Label>
              <Input
                id="date_basis_period_ended"
                name="date_basis_period_ended"
                value={formSA103FState.date_accounting_period_ends}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                type="date"
                bsSize="lg"
              />
            </InputForm>

            <InputForm>
              <Label>
                [FSE68] If your basis period is not the same as your accounting
                period, enter the adjustment needed to arrive at the profit or
                loss for the basis period – if the adjustment needs to be taken
                off the profit figure, put a negative number in the box
              </Label>
              <CurrencyInput
                value={formSA103FState.basis_period_adjustment}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="basis_period_adjustment"
                name="basis_period_adjustment"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE69]
                {taxYear >= 2024
                  ? 'Overlap relief not previously deducted'
                  : 'Overlap relief used this year'}
              </Label>
              <CurrencyInput
                value={formSA103FState.overlap_relief_used}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="overlap_relief_used"
                name="overlap_relief_used"
              />
            </InputForm>

            {taxYear <= 2023 ? (
              <InputForm>
                <Label>[FSE70] Overlap profit carried forward</Label>
                <CurrencyInput
                  value={formSA103FState.overlap_profit_carried_forward}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="overlap_profit_carried_forward"
                  name="overlap_profit_carried_forward"
                />
              </InputForm>
            ) : (
              <></>
            )}

            {taxYear == 2021 || taxYear == 2022 ? (
              <InputForm>
                <Label>
                  [FSE70.1] Self-Employment Income Support Scheme grant
                  {taxYear == 2022
                    ? 'received in the year to 5 April 2022'
                    : ''}
                </Label>
                <CurrencyInput
                  value={formSA103FState.seiss_grant}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="seiss_grant"
                  name="seiss_grant"
                />
              </InputForm>
            ) : (
              <></>
            )}

            <InputForm>
              <Label>
                [FSE71] Adjustment for change of accounting practice
              </Label>
              <CurrencyInput
                value={formSA103FState.change_of_accounting_practice_adjustment}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="change_of_accounting_practice_adjustment"
                name="change_of_accounting_practice_adjustment"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE72] Averaging adjustment (only for farmers, market gardeners
                and creators of literary or artistic works) – if the adjustment
                needs to be taken off the profit figure, put a negative number
                in the box
              </Label>
              <CurrencyInput
                value={formSA103FState.averaging_adjustment}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="averaging_adjustment"
                name="averaging_adjustment"
              />
            </InputForm>

            <InputForm>
              <Label>
                [FSE73] Adjusted profit for {taxYear - 1}–{taxYear.toString().substring(-2)}{' '}
                (see the working sheet in the notes) – if a loss, enter it in
                box [FSE77]
              </Label>
              <CurrencyInput
                value={formSA103FState.adjusted_profit_for_the_year}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="adjusted_profit_for_the_year"
                name="adjusted_profit_for_the_year"
              />
            </InputForm>

            {taxYear >= 2024 ? (
              <>
                <InputForm>
                  <Label>[FSE73.1] Profit or loss of the transition part</Label>
                  <CurrencyInput
                    value={
                      formSA103FState.profit_or_loss_of_the_transition_part
                    }
                    onChange={(e, valueAsCurrency) =>
                      onCurrencyInputChange(
                        e,
                        valueAsCurrency,
                        formSA103FState,
                        setformSA103FState,
                      )
                    }
                    className="form-control-lg form-control"
                    id="profit_or_loss_of_the_transition_part"
                    name="profit_or_loss_of_the_transition_part"
                  />
                </InputForm>
                <InputForm>
                  <Label>[FSE73.2] Overlap relief of the transition part</Label>
                  <CurrencyInput
                    value={
                      formSA103FState.overlap_relief_of_the_transition_part
                    }
                    onChange={(e, valueAsCurrency) =>
                      onCurrencyInputChange(
                        e,
                        valueAsCurrency,
                        formSA103FState,
                        setformSA103FState,
                      )
                    }
                    className="form-control-lg form-control"
                    id="overlap_relief_of_the_transition_part"
                    name="overlap_relief_of_the_transition_part"
                  />
                </InputForm>
                <InputForm>
                  <Label>
                    [FSE73.3] Spread transition profit treated as arising
                  </Label>
                  <CurrencyInput
                    value={
                      formSA103FState.spread_transition_profit_treated_as_arising
                    }
                    onChange={(e, valueAsCurrency) =>
                      onCurrencyInputChange(
                        e,
                        valueAsCurrency,
                        formSA103FState,
                        setformSA103FState,
                      )
                    }
                    className="form-control-lg form-control"
                    id="spread_transition_profit_treated_as_arising"
                    name="spread_transition_profit_treated_as_arising"
                  />
                </InputForm>
                <InputForm>
                  <Label>
                    [FSE73.4] Loss brought forward used against spread
                    transition profit
                  </Label>
                  <CurrencyInput
                    value={
                      formSA103FState.loss_brought_forward_used_against_spread_transition_profit
                    }
                    onChange={(e, valueAsCurrency) =>
                      onCurrencyInputChange(
                        e,
                        valueAsCurrency,
                        formSA103FState,
                        setformSA103FState,
                      )
                    }
                    className="form-control-lg form-control"
                    id="loss_brought_forward_used_against_spread_transition_profit"
                    name="loss_brought_forward_used_against_spread_transition_profit"
                  />
                </InputForm>
              </>
            ) : (
              <></>
            )}

            <InputForm>
              <Label>
                [FSE74] Loss brought forward from earlier years set off against
                this year's profits
              </Label>
              <CurrencyInput
                value={formSA103FState.loss_brought_forward}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="loss_brought_forward"
                name="loss_brought_forward"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE75] Any other business income not included in boxes [FSE15],
                [FSE16] or [FSE60]
              </Label>
              <CurrencyInput
                value={formSA103FState.any_other_business_income}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="any_other_business_income"
                name="any_other_business_income"
              />
            </InputForm>

            {taxYear == 2020 || taxYear == 2021 ? (
              <InputForm>
                <Label>
                  [FSE75.1] Disguised remuneration additions to profits – read
                  the notes
                </Label>
                <CurrencyInput
                  value={
                    formSA103FState.disguised_remuneration_additions_to_profits
                  }
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="disguised_remuneration_additions_to_profits"
                  name="disguised_remuneration_additions_to_profits"
                />
              </InputForm>
            ) : (
              <></>
            )}

            <InputForm>
              <Label>
                [FSE76] Total taxable profits from this business
                {taxYear == 2020 || taxYear == 2021
                  ? '([FSE73] + [FSE75] + [FSE75.1] minus [FSE74])'
                  : '([FSE73] + [FSE75] minus [FSE74])'}
                – or use the working sheet in the notes
              </Label>
              <CurrencyInput
                value={formSA103FState.total_taxable_business_profits}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="total_taxable_business_profits"
                name="total_taxable_business_profits"
              />
            </InputForm>
            <Title>Losses</Title>
            <Subtitle>
              If you've made a net loss for tax purposes (in box [FSE65]), or if
              you've losses from previous years, read the 'Self-employment
              (full) notes' and fill in boxes [FSE77] to [FSE80], as
              appropriate.
            </Subtitle>
            <InputForm>
              <Label>
                [FSE77] Adjusted loss for {taxYear - 1}–{taxYear.toString().substring(-2)}{' '}
                (see the working sheet in the notes)
              </Label>
              <CurrencyInput
                value={formSA103FState.adjusted_loss_for_the_year}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="adjusted_loss_for_the_year"
                name="adjusted_loss_for_the_year"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE78] Loss from this tax year set off against other income for{' '}
                {taxYear - 1}–{taxYear.toString().substring(-2)}
              </Label>
              <CurrencyInput
                value={formSA103FState.loss_of_year_set_against_other_income}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="loss_of_year_set_against_other_income"
                name="loss_of_year_set_against_other_income"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE79] Loss to be carried back to previous year(s) and set off
                against income (or capital gains)
              </Label>
              <CurrencyInput
                value={formSA103FState.loss_to_carry_back}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="loss_to_carry_back"
                name="loss_to_carry_back"
              />
            </InputForm>
            <InputForm>
              <Label>
                [FSE80] Total loss to carry forward after all other set-offs –
                including unused losses brought forward
              </Label>
              <CurrencyInput
                value={formSA103FState.total_loss_to_carry_forward}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="total_loss_to_carry_forward"
                name="total_loss_to_carry_forward"
              />
            </InputForm>
            <Title>CIS deductions and tax taken off</Title>
            <InputForm>
              <Label>
                [FSE81] Total Construction Industry Scheme (CIS) deductions
                taken from your payments by contractors – CIS subcontractors
                only
              </Label>
              <CurrencyInput
                value={formSA103FState.sub_contractors_tax_deduction}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="sub_contractors_tax_deduction"
                name="sub_contractors_tax_deduction"
              />
            </InputForm>
            <InputForm>
              <Label>[FSE82] Other tax taken off trading income</Label>
              <CurrencyInput
                value={formSA103FState.other_tax_taken_off_trading_income}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="other_tax_taken_off_trading_income"
                name="other_tax_taken_off_trading_income"
              />
            </InputForm>
            <Title>Balance sheet</Title>
            <Card>
              <Title>Assets</Title>
              <Subtitle>
                If your business accounts include a balance sheet showing the
                assets, liabilities and capital of the business, fill in the
                relevant boxes below. If you do not have a balance sheet, go to
                box [FSE100]. Read the 'Self-employment (full) notes' for more
                information.
              </Subtitle>
              <InputForm>
                <Label>[FSE83] Equipment, machinery and vehicles</Label>
                <CurrencyInput
                  value={formSA103FState.equipment_machinery_vehicles}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="equipment_machinery_vehicles"
                  name="equipment_machinery_vehicles"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE84] Other fixed assets</Label>
                <CurrencyInput
                  value={formSA103FState.other_fixed_assets}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="other_fixed_assets"
                  name="other_fixed_assets"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE85] Stock and work in progress</Label>
                <CurrencyInput
                  value={formSA103FState.stock_and_work_in_progress}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="stock_and_work_in_progress"
                  name="stock_and_work_in_progress"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE86] Trade debtors</Label>
                <CurrencyInput
                  value={formSA103FState.trade_debtors}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="trade_debtors"
                  name="trade_debtors"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE87] Bank or building society balances</Label>
                <CurrencyInput
                  value={formSA103FState.bank_etc_balances}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="bank_etc_balances"
                  name="bank_etc_balances"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE88] Cash in hand</Label>
                <CurrencyInput
                  value={formSA103FState.cash_in_hand}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="cash_in_hand"
                  name="cash_in_hand"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE89] Other current assets and prepayments</Label>
                <CurrencyInput
                  value={formSA103FState.other_current_assets}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="other_current_assets"
                  name="other_current_assets"
                />
              </InputForm>
              <InputForm>
                <Label>
                  [FSE90] Total assets (total of boxes [FSE83] to [FSE89])
                </Label>
                <CurrencyInput
                  value={formSA103FState.total_business_assets}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="total_business_assets"
                  name="total_business_assets"
                />
              </InputForm>
            </Card>
            <Card>
              <Title>Liabilities</Title>
              <InputForm>
                <Label>[FSE91] Trade creditors</Label>
                <CurrencyInput
                  value={formSA103FState.trade_creditors}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="trade_creditors"
                  name="trade_creditors"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE92] Loans and overdrawn bank account balances</Label>
                <CurrencyInput
                  value={formSA103FState.loans_and_overdrafts}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="loans_and_overdrafts"
                  name="loans_and_overdrafts"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE93] Other liabilities and accruals</Label>
                <CurrencyInput
                  value={formSA103FState.other_liabilities}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="other_liabilities"
                  name="other_liabilities"
                />
              </InputForm>
            </Card>
            <InputForm>
              <Label>
                [FSE94] Net business assets ([FSE90] minus ([FSE91] to [FSE93]))
              </Label>
              <CurrencyInput
                value={formSA103FState.net_business_assets}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="net_business_assets"
                name="net_business_assets"
              />
            </InputForm>
            <Card>
              <Title>Capital account</Title>
              <InputForm>
                <Label>[FSE95] Balance at start of period</Label>
                <CurrencyInput
                  value={formSA103FState.capital_account_balance_at_start}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="capital_account_balance_at_start"
                  name="capital_account_balance_at_start"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE96] Net profit or loss (box [FSE47/FSE48])</Label>
                <CurrencyInput
                  value={formSA103FState.net_profit_or_loss}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="net_profit_or_loss"
                  name="net_profit_or_loss"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE97] Capital introduced</Label>
                <CurrencyInput
                  value={formSA103FState.capital_introduced}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="capital_introduced"
                  name="capital_introduced"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE98] Drawings</Label>
                <CurrencyInput
                  value={formSA103FState.drawings}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="drawings"
                  name="drawings"
                />
              </InputForm>
              <InputForm>
                <Label>[FSE99] Balance at end of period</Label>
                <CurrencyInput
                  value={formSA103FState.capital_account_balance_at_end}
                  onChange={(e, valueAsCurrency) =>
                    onCurrencyInputChange(
                      e,
                      valueAsCurrency,
                      formSA103FState,
                      setformSA103FState,
                    )
                  }
                  className="form-control-lg form-control"
                  id="capital_account_balance_at_end"
                  name="capital_account_balance_at_end"
                />
              </InputForm>
            </Card>

            <Title>
              Class 2 and Class 4 National Insurance contributions (NICs)
            </Title>
            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.pay_class_2_nic_voluntarily}
                checked={formSA103FState.pay_class_2_nic_voluntarily}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                name="pay_class_2_nic_voluntarily"
                id="pay_class_2_nic_voluntarily"
              />
              <Label lg>
                [FSE100] If your total profits for {taxYear - 1}–
                {taxYear.toString().substring(-2)} are less than
                {taxYear >= 2023
                  ? '£6,725,'
                  : taxYear == 2022
                  ? '£6,515,'
                  : taxYear == 2021
                  ? '£6,475,'
                  : taxYear == 2020
                  ? '£6,365,'
                  : ''}
                and you choose to pay Class 2 NICs voluntarily, check the box
              </Label>
            </CheckboxLabel>
            <InputForm>
              <Label>[FSECL2] Class 2 NIC amount</Label>
              <CurrencyInput
                value={formSA103FState.class_2_nic_amount}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="class_2_nic_amount"
                name="class_2_nic_amount"
              />
            </InputForm>
            <Subtitle>
              * Can only input one of class_4_nic_exempt or
              adjustment_to_class_4_nic_profits.
            </Subtitle>

            <CheckboxLabel>
              <input
                type="checkbox"
                value={formSA103FState.class_4_nic_exempt}
                checked={formSA103FState.class_4_nic_exempt}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                name="class_4_nic_exempt"
                id="class_4_nic_exempt"
              />
              <Label lg>
                [FSE101] If you're exempt from paying Class 4 NICs, check the
                box
              </Label>
            </CheckboxLabel>

            <InputForm>
              <Label>
                [FSE102] Adjustment to profits chargeable to Class 4 NICs
              </Label>
              <CurrencyInput
                value={formSA103FState.adjustment_to_class_4_nic_profits}
                onChange={(e, valueAsCurrency) =>
                  onCurrencyInputChange(
                    e,
                    valueAsCurrency,
                    formSA103FState,
                    setformSA103FState,
                  )
                }
                className="form-control-lg form-control"
                id="adjustment_to_class_4_nic_profits"
                name="adjustment_to_class_4_nic_profits"
              />
            </InputForm>
            <Title>Any other information</Title>
            <InputForm>
              <Label>
                [FSE103] Please give any other information in this space
              </Label>
              <Input
                type="textarea"
                bsSize="lg"
                value={formSA103FState.other_information_space}
                onChange={(e) =>
                  onInputChange(e, formSA103FState, setformSA103FState)
                }
                id="other_information_space"
                name="other_information_space"
              />
            </InputForm>
          </FormContainer>
          {view ? (
            <></>
          ) : (
            <SubmitButton>
              <Button color="primary" onClick={() => onSubmitForm()} disabled>
                Save
              </Button>
            </SubmitButton>
          )}
        </div>
      </div>
    </>
  );
};
