import React, { useEffect, useState, ChangeEvent } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { addTransaction, updateSelfEmployed } from 'src/api/incomes';
import { getExpensesCategories, getTaxIncomeListing } from 'src/api/users';
import { TextArea } from 'src/components/common';
import {
  calcPersonal,
  calcWork,
} from 'src/components/CustomerInformationTabs/Common';
import ViewDocs from 'src/components/CustomerInformationTabs/IncomesTab/Components/ViewDocs';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { PayeButton } from 'src/styles/pages/components/PayeButton';
import { formatCurrency, SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeHeaderButtons,
  IncomeInputsContainer,
  HeaderSelectInput,
  TransactionsSection,
  ViewImageSection,
  OpenContentButton,
  OpenContentContainer,
  ContentContainer,
  Button,
  Select,
  OtherInformationsInputSection,
} from '../../styles';
import { ProofDetails, ProofItem, Title } from '../AddPAYE/styles';
import SelfEmployedTransactionsList from '../SelfEmpTransactions/TransactionsList';
import { EmployerNameSection } from '../TabAll/styles';
import {
  AddTransactionSection,
  TransactionInputContainer,
  UploadContainer,
} from '../TabProperty/styles';
import ViewImage from '../ViewImage';

import {
  FooterTitle,
  NotesSection,
  SplitSection,
  SliptRange,
  SplitContainer,
  ErrorMessage,
  IncomeHeaderImage,
  StartDateLabel,
  HeaderSelectSpan,
  IncomeHeaderButtonsSection,
  AddCancelSection,
  CancelButton,
  Label,
  AddProofButton,
  StartDateSection,
  InputDate,
} from './styles';

const addTransactionFields = {
  client_name: '',
  date: null,
  amount: '',
  files: [],
  category_id: 0,
  work_percentage: 100,

  note: '',
};

const filesList = [];

let refetchList: () => void;

function TabSelfEmployed() {
  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const incomes = store.incomes;
  const expenses = store.expenses;

  const [headerSelfEmployedList, setHeaderSelfEmployedList] = useState([]);
  const [incomeIdSelected, setIncomeIdSelected] = useState(0);
  const [proofDocuments, setProofDocuments] = useState([]);
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);
  const [errors, setErrors] = useState({});

  const [transactionsListState, setTransactionsListState] = useState([]);
  const [selectedItem, setSelectedItem] = useState<undefined | {}>(undefined);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListing',
    () => getTaxIncomeListing(userId, year, 'self_employed'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { data: dataCategories, isLoading: isLoadingCategories } = useQuery(
    'getExpensesCategories',
    () => getExpensesCategories('self_employed'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const { mutate: mutationUpdateSelfEmployed } = useMutation(
    'updateSelfEmployed',
    updateSelfEmployed,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        expenses.refetchTotals();
        expenses.refetchSummary();
      },
      onError({ error }) {
        Toastify.toast.error(error);
      },
    },
  );

  const { mutate: mutationAddTransaction, isSuccess } = useMutation(
    'addTransaction',
    addTransaction,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setOpenAddTransactionForm(false);
          refetchList();
          expenses.refetchTotals();
          expenses.refetchSummary();
        }, 1000);
      },
      onError({ error }) {
        Toastify.toast.error(error);
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      const list = data;

      list.forEach((item, index) => {
        list[index].editMode = false;
        list[index].addTransactionFields = { ...addTransactionFields };
        list[index].transactionList = [...incomes.incomeTransactions];
      });

      setHeaderSelfEmployedList(list);
    }
  }, [data, incomes.incomeTransactions]);

  const onInputChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...headerSelfEmployedList];

    list[index][target.name] = target.value;
    setHeaderSelfEmployedList(list);
  };

  const onUpdateSelfImploymentHeaderInfos = (
    editMode: boolean,
    index: number,
    id: number,
  ) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    if (editMode) {
      newHeaderSelfEmployedList[index].editMode = false;
      setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);

      if (newHeaderSelfEmployedList[index].id === id) {
        const itemEdited = newHeaderSelfEmployedList[index];

        let formData = {};

        if (!itemEdited.has_student_loan) {
          formData = {
            income_type_self_employment_id: itemEdited.id,
            self_employment_type: itemEdited.self_employment_type,
            employer_name: itemEdited.employer_name,
            start_date: itemEdited.start_date,
            has_student_loan: itemEdited.has_student_loan,
            refund_last_year: itemEdited.refund_last_year,
            has_child_benefits: itemEdited.has_child_benefits,
            still_receiving_benefits: itemEdited.still_receiving_benefits,
          };
        } else {
          formData = {
            income_type_self_employment_id: itemEdited.id,
            self_employment_type: itemEdited.self_employment_type,
            employer_name: itemEdited.employer_name,
            start_date: itemEdited.start_date,
            has_student_loan: itemEdited.has_student_loan,
            refund_last_year: itemEdited.refund_last_year,
            student_loan_type: itemEdited.student_loan_type,
            has_child_benefits: itemEdited.has_child_benefits,
            still_receiving_benefits: itemEdited.still_receiving_benefits,
          };
        }

        mutationUpdateSelfEmployed(formData);
      }
    } else {
      newHeaderSelfEmployedList[index].editMode = true;
      setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
    }
  };

  const onCancelAddTransaction = (index: number) => {
    setOpenAddTransactionForm(false);

    const list = [...headerSelfEmployedList];

    list[index].addTransactionFields.client_name = '';
    list[index].addTransactionFields.date = new Date();
    list[index].addTransactionFields.amount = 0;

    setProofDocuments([]);
    setHeaderSelfEmployedList(list);
  };

  const onAddTransaction = (index: number, income_id: number) => {
    const fields = headerSelfEmployedList[index];

    const formErrors = validateFields(
      headerSelfEmployedList[index].addTransactionFields,
      {
        client_name: { required: true },
        amount: { required: true },
        date: { required: true },
      },
    );

    const formData = new FormData();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('client_name', fields.addTransactionFields.client_name);
      formData.append('income_id', income_id);
      formData.append('income_type', 'self_employed');
      formData.append('reconciliation_type', 'expense');
      formData.append('date', fields.addTransactionFields.date);
      formData.append('amount', fields.addTransactionFields.amount);
      formData.append('category_id', fields.addTransactionFields.category_id);
      formData.append(
        'work_percentage',
        fields.addTransactionFields.work_percentage,
      );

      formData.append('files', proofDocuments);

      formData.append('note', fields.addTransactionFields.note);

      mutationAddTransaction(formData);
      setOpenAddTransactionForm(false);

      const list = [...headerSelfEmployedList];

      list[index].addTransactionFields.client_name = '';
      list[index].addTransactionFields.date = new Date();
      list[index].addTransactionFields.amount = 0;
    }
  };

  const validateFields = (fields, rules) => {
    const formErrors = {};

    for (const fieldName in rules) {
      if (rules[fieldName].required && !fields[fieldName]) {
        formErrors[fieldName] =
          rules[fieldName].errorMessage || 'This field is required';
      }
    }

    return formErrors;
  };

  const onOpenContent = (
    index: number,
    openContent: boolean,
    income_id: number,
  ) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].openContent = !openContent;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
    setIncomeIdSelected(income_id);
  };

  const onShowDocuments = (index: number, showDocuments: boolean) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].showDocuments = !showDocuments;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  };

  const onChangeSplitRange = (value: string, index: number) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].split = Number(value);
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  };

  const onCancelUpdateItem = (index: number) => {
    const newHeaderSelfEmployedList = headerSelfEmployedList;

    newHeaderSelfEmployedList[index].editMode = false;
    setHeaderSelfEmployedList([...newHeaderSelfEmployedList]);
  };

  const onUploadProof = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;

    if (files) {
      filesList.push(files);

      setProofDocuments(filesList);
    }
  };

  const onRemoveProof = (index: number) => {
    let newFileList = [];

    const listWithFileRemoved = proofDocuments.map((proof) => {
      newFileList = [...proof];

      return newFileList.filter((f, i) => i !== index);
    });

    setProofDocuments(listWithFileRemoved);
  };

  const onInputAddTransactionChange = (index: number, event: any) => {
    const target = event.target;
    const list = [...headerSelfEmployedList];

    list[index].addTransactionFields[target.name] = target.value;
    setHeaderSelfEmployedList(list);
  };

  const renderError = (field: any) => <ErrorMessage>{field}</ErrorMessage>;

  let newDocsList = [];

  proofDocuments.map((proof) => {
    newDocsList = [...proof];
  });

  return (
    <div>
      {!isLoading &&
        headerSelfEmployedList.map((item, index) => {
          const workResult = calcWork(
            item.addTransactionFields.amount,
            item.addTransactionFields.work_percentage,
          );

          const personalResult = calcPersonal(
            item.addTransactionFields.amount,
            workResult,
          );

          return (
            <IncomeContainer key={index}>
              <IncomeHeader>
                <div>
                  <IncomeHeaderImage
                    src={handleLogo('self_employed', item.self_employment_type)}
                    alt=""
                  />
                </div>
                <IncomeInputsContainer>
                  <IncomeHeaderFields>
                    <Row>
                      <Col>
                        <EmployerNameSection>
                          <EditInputPie
                            id="employer_name"
                            label="Employer name:"
                            name="employer_name"
                            type="text"
                            value={item.employer_name}
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          />
                        </EmployerNameSection>

                        <StartDateSection>
                          <StartDateLabel htmlFor="start_date">
                            Start Date
                          </StartDateLabel>
                          <InputDate
                            type="date"
                            name="start_date"
                            id="start_date"
                            max="2023-05-17"
                            value={item.start_date}
                            defaultValue={item.start_date}
                            onChange={(event) => onInputChange(index, event)}
                            disabled={!item.editMode}
                          />
                        </StartDateSection>
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="studentLoan">Has student loan</label>
                          <HeaderSelectInput
                            id="has_student_loan"
                            name="has_student_loan"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value={true}
                              selected={item.benefits.has_student_loan}
                            >
                              Yes
                            </option>

                            <option
                              value={false}
                              selected={!item.benefits.has_student_loan}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Student loan paid:"
                          id="refund_last_year"
                          name="refund_last_year"
                          type="text"
                          value={item.benefits.refund_last_year || 0}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                        />
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="childBenefits">Child Benefits:</label>
                          <HeaderSelectInput
                            id="has_child_benefits"
                            name="has_child_benefits"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                          >
                            <option
                              value={true}
                              selected={item.benefits.has_child_benefits}
                            >
                              Yes
                            </option>

                            <option
                              value={false}
                              selected={!item.benefits.has_child_benefits}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>

                        <EditInputPie
                          label="Number of children:"
                          id="total_children_received_benefits"
                          name="total_children_received_benefits"
                          type="text"
                          value={
                            item.benefits.total_children_received_benefits || 0
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={item.editMode}
                          disabled={!item.editMode}
                          maxWidth="160px"
                        />
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="stillReceivingBenefits">
                            Still receiving benefits:
                          </label>
                          <HeaderSelectInput
                            id="still_receiving_benefits"
                            name="still_receiving_benefits"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value={true}
                              selected={item.benefits.still_receiving_benefits}
                            >
                              Yes
                            </option>

                            <option
                              value={false}
                              selected={!item.benefits.still_receiving_benefits}
                            >
                              No
                            </option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Total expenses:"
                          id="total_expenses"
                          name="total_expenses"
                          type="text"
                          value={formatCurrency(item?.total_expenses || 0)}
                          onChange={(event) => onInputChange(index, event)}
                          editMode={false}
                          disabled
                          maxWidth="160px"
                        />
                      </Col>

                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="self_employment_type">
                            Self Employment Type
                          </label>
                          <HeaderSelectInput
                            id="self_employment_type"
                            name="self_employment_type"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={!item.editMode}
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value={true}
                              selected={item.self_employment_type}
                            >
                              {item.self_employment_type}
                            </option>

                            <option value="freelancer" selected>
                              Freelancer
                            </option>
                            <option value="gig_worker">Gig Worker</option>
                            <option value="small_business">
                              Small Business
                            </option>
                            <option value="cis">Cis</option>
                            <option value="other">Other</option>
                          </HeaderSelectInput>
                        </OtherInformationsInputSection>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <OtherInformationsInputSection>
                          <label htmlFor="student_loan_type">
                            Student loan type
                          </label>
                          <HeaderSelectInput
                            id="student_loan_type"
                            name="student_loan_type"
                            onChange={(event) => onInputChange(index, event)}
                            editMode={item.editMode}
                            disabled={
                              !item.editMode ||
                              !headerSelfEmployedList[index].has_student_loan
                            }
                            style={{ maxWidth: 180 }}
                          >
                            <option
                              value={true}
                              selected={item.benefits.student_loan_type}
                            >
                              {item.benefits.student_loan_type}
                            </option>

                            <option value="plan_1">Plan 1</option>
                            <option value="plan_2">Plan 2</option>
                            <option value="both_plans">Both Plans</option>
                            <option value="unsure">Unsure</option>
                          </HeaderSelectInput>
                          {!headerSelfEmployedList[index].has_student_loan && (
                            <HeaderSelectSpan>
                              this field will only be enabled if the field "has
                              student loan" is set to Yes
                            </HeaderSelectSpan>
                          )}
                        </OtherInformationsInputSection>
                      </Col>

                      <Col>
                        <EditInputPie
                          label="Status:"
                          id="status"
                          name="status"
                          type="text"
                          value={
                            !item.status ? (item.status = '') : item.status
                          }
                          onChange={(event) => onInputChange(index, event)}
                          editMode={false}
                          disabled
                        />
                      </Col>
                    </Row>
                  </IncomeHeaderFields>
                  <IncomeHeaderButtons>
                    <IncomeHeaderButtonsSection>
                      <PayeButton
                        onClick={() =>
                          onUpdateSelfImploymentHeaderInfos(
                            item.editMode,
                            index,
                            item.id,
                          )
                        }
                      >
                        {item.editMode ? 'Save' : 'Edit'}
                      </PayeButton>
                      <PayeButton onClick={() => onCancelUpdateItem(index)}>
                        Cancel
                      </PayeButton>
                    </IncomeHeaderButtonsSection>

                    <PayeButton
                      onClick={() => onShowDocuments(index, item.showDocuments)}
                    >
                      Show Documents
                    </PayeButton>
                  </IncomeHeaderButtons>
                </IncomeInputsContainer>
              </IncomeHeader>

              <div>
                {item.showDocuments && (
                  <div>
                    <ViewImageSection>
                      <ViewDocs docs={item.document} />
                    </ViewImageSection>
                  </div>
                )}
              </div>

              <ContentContainer openContent={item.openContent}>
                <TransactionsSection>
                  {item.openContent && item.id === incomeIdSelected && (
                    <>
                      <TransactionsSection>
                        <h3>Transactions</h3>
                        <SelfEmployedTransactionsList
                          incomeType="self_employed"
                          incomeId={item.id}
                          setOpenAddTransactionForm={setOpenAddTransactionForm}
                          openAddTransactionForm={openAddTransactionForm}
                          allHeaderList={headerSelfEmployedList}
                          incomeIdSelected={incomeIdSelected}
                          userId={userId}
                          year={year}
                          transactionsListState={transactionsListState}
                          setTransactionsListState={setTransactionsListState}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                          isSuccess={isSuccess}
                          refetchList={(refetch: () => void) =>
                            (refetchList = refetch)
                          }
                        />
                      </TransactionsSection>

                      <ViewImageSection>
                        <ViewImage
                          transactions={
                            incomes.incomeTransactions &&
                            incomes.incomeTransactions[
                              incomes.indexTransactionSelected
                            ]
                          }
                        />
                      </ViewImageSection>
                    </>
                  )}
                </TransactionsSection>

                <AddTransactionSection
                  style={{ display: openAddTransactionForm ? 'block' : 'none' }}
                >
                  <Title>Add transaction</Title>
                  <Row>
                    <Col>
                      <TransactionInputContainer>
                        <label htmlFor="client_name">Client name</label>
                        <input
                          type="text"
                          value={item.addTransactionFields.client_name}
                          name="client_name"
                          id="client_name"
                          onChange={(event) =>
                            onInputAddTransactionChange(index, event)
                          }
                        />
                      </TransactionInputContainer>

                      {errors.client_name && renderError(errors.client_name)}

                      <TransactionInputContainer>
                        <label htmlFor="date">Date</label>
                        <input
                          type="date"
                          value={item.addTransactionFields.date}
                          name="date"
                          id="date"
                          onChange={(event) =>
                            onInputAddTransactionChange(index, event)
                          }
                        />
                      </TransactionInputContainer>

                      {errors.date && renderError(errors.date)}

                      <TransactionInputContainer>
                        <label htmlFor="amount">Amount</label>
                        <input
                          type="number"
                          value={item.addTransactionFields.amount}
                          name="amount"
                          id="amount"
                          onChange={(event) =>
                            onInputAddTransactionChange(index, event)
                          }
                        />
                      </TransactionInputContainer>

                      <SplitSection>
                        <Row>
                          <FooterTitle>Split</FooterTitle>
                        </Row>
                        <Row className="justify-content-center">
                          <FooterTitle>
                            {item.addTransactionFields.work_percentage || 0} %
                          </FooterTitle>
                        </Row>

                        <SplitContainer>
                          <SliptRange
                            type="range"
                            id="work_percentage"
                            name="work_percentage"
                            className="split-range-label"
                            min={0}
                            max={100}
                            value={
                              item.addTransactionFields.work_percentage || 0
                            }
                            onChange={(event) =>
                              onInputAddTransactionChange(index, event)
                            }
                          />
                        </SplitContainer>
                        <Row className="justify-content-between">
                          <FooterTitle>
                            Personal £
                            {item.addTransactionFields.amount
                              ? personalResult.toFixed(2)
                              : 0}{' '}
                          </FooterTitle>
                          <FooterTitle>
                            £
                            {item.addTransactionFields.amount
                              ? workResult.toFixed(2)
                              : 0}{' '}
                            Work
                          </FooterTitle>
                        </Row>
                      </SplitSection>

                      {errors.amount && renderError(errors.amount)}

                      <Row>
                        <AddCancelSection>
                          <Button
                            onClick={() => onAddTransaction(index, item.id)}
                            style={{ position: 'relative' }}
                          >
                            <span>Add</span>
                          </Button>
                          <CancelButton
                            onClick={() => onCancelAddTransaction(index)}
                          >
                            <span>Cancel</span>
                          </CancelButton>
                        </AddCancelSection>
                      </Row>
                    </Col>

                    <Col>
                      <div>
                        <Label htmlFor="category_id" className="title">
                          Category
                        </Label>{' '}
                        <br />
                        <Select
                          id="category_id"
                          name="category_id"
                          onChange={(event) =>
                            onInputAddTransactionChange(index, event)
                          }
                        >
                          <option>Select an option</option>
                          {!isLoadingCategories &&
                            dataCategories.data.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.category}{' '}
                              </option>
                            ))}
                        </Select>
                      </div>

                      <NotesSection>
                        <Label htmlFor="note" className="title">
                          Notes
                        </Label>{' '}
                        <TextArea
                          id="note"
                          name="note"
                          value={item.addTransactionFields.note || ''}
                          onChange={(event) =>
                            onInputAddTransactionChange(index, event)
                          }
                          rows={4}
                          cols={40}
                        />
                      </NotesSection>
                    </Col>

                    <Col>
                      <input
                        type="file"
                        onChange={(event) => onUploadProof(event)}
                        hidden
                        id="documents_list"
                        name="documents_list"
                        multiple
                      />
                      <AddProofButton htmlFor="documents_list">
                        <TbFileSpreadsheet size={24} />
                        <span>Add proof</span>
                      </AddProofButton>

                      {errors.files && renderError(errors.files)}

                      <UploadContainer>
                        <Row>
                          {newDocsList.length > 0 &&
                            newDocsList.map((proof, i) => {
                              return (
                                <ProofItem key={i}>
                                  <ProofDetails>
                                    <div className="image-size">
                                      <span>{proof.name}</span>
                                      <span>{proof.size}KB </span>
                                    </div>

                                    <div>
                                      <span
                                        className="remove"
                                        onClick={() => onRemoveProof(i)}
                                      >
                                        Remove
                                      </span>
                                    </div>
                                  </ProofDetails>
                                </ProofItem>
                              );
                            })}
                        </Row>
                      </UploadContainer>
                    </Col>
                  </Row>
                </AddTransactionSection>
              </ContentContainer>

              <OpenContentContainer>
                <OpenContentButton
                  onClick={() =>
                    onOpenContent(index, item.openContent, item.id)
                  }
                >
                  {item.openContent ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}
                </OpenContentButton>
              </OpenContentContainer>
            </IncomeContainer>
          );
        })}
    </div>
  );
}

export default observer(TabSelfEmployed);
