import React from 'react';

type Props = {
  value: any;
};

const RowDetailsRefunds: React.FC<Props> = ({ value }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        float: 'left',
        padding: '10px',
        boxSizing: 'border-box',
        border: '1px solid #CCC',
        width: '200px',
        marginBottom: '10px',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: '1',
          fontSize: '12px',
        }}
      >
        <div>Agent Fee</div>
        <div>{value.agent_fee}</div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: '1',
          fontSize: '12px',
        }}
      >
        <div>Agent Fee with Vat</div>
        <div>{value.agent_fee_with_vat}</div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: '1',
          fontSize: '12px',
        }}
      >
        <div>Refund Amount</div>
        <div>{value.refund_amount}</div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: '1',
          fontSize: '12px',
        }}
      >
        <div>User Payout</div>
        <div>{value.user_payout}</div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: '1',
          fontSize: '12px',
        }}
      >
        <div>Date</div>
        <div>
          <a
            href={`/app/customer-information/?id=${value.user_id}&tab=hmrc&tax_year=${value.year}`}
            target="_blank"
            className="text-decoration-none"
            rel="noreferrer"
          >
            {value.date}
          </a>
        </div>
      </div>
    </div>
  );
};

export default RowDetailsRefunds;
