import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://288b9bbb70cb45f5a2664aaead4c5c82@o1087107.ingest.sentry.io/6099880',

    // integrations: [new BrowserTracing()],

    tracesSampleRate: 0.3,

    ignoreErrors: [
      'top.GLOBALS',
      '<unknown>',
      'Non-Error promise rejection captured',
      'ChunkLoadError',
      'Loading chunk',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}
