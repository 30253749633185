import styled from 'styled-components';

type Props = {
  active: string;
  types: string;
};

export const Title = styled.h1`
  margin: 10px 0;
`;

export const Subtitle = styled.h4`
  margin-bottom: 10px;
`;

export const SectionContainer = styled.div<Props>`
  display: ${(props) => (props.active === props.types ? 'block' : 'none')};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

export const CardContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 5px 2px;
`;

export const SectionSubmitButton = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const FormSelectedButton = styled.button<Props>`
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin-right: 4px;
  margin-top: 24px;
  background-color: ${(props) =>
    props.active === props.types
      ? props.theme.colors.gray.geyser
      : props.theme.colors.white.default};
`;

export const CheckboxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const InputForm = styled.div`
  margin-top: 15px;
`;

export const Card = styled.div`
  padding: 10px;
  margin: 15px 0;
  border: solid 1px lightgray;
  border-radius: 5px;
`;

export const TextBody = styled.p`
  margin-top: 10px;
`;

export const SubmitButton = styled.div`
  margin-top: 15px;
  margin-left: 10px;
`;

export const Select = styled.select`
  padding: 2px 10px;
`;
