import React from 'react';

import Avatar from 'src/components/Avatar';
import { SubscriptionStatus } from 'src/components/CustomerInformationHeaderPie/styles';
import Icon from 'src/components/Icon';
import { observer } from 'src/modules';
import { conversationTime, createMarkup, capitalize } from 'src/utils';
import checkChatType from 'src/utils/helpers/checkChatType';

import {
  Container,
  RightSide,
  AvatarChat,
  AvatarDetails,
  AvatarName,
  ChatTime,
  NumberChatUnread,
  Content,
  LeftContent,
  CustomerContent,
  AvatarAndSubscriptionSection,
} from './styles';

const SUBSCRIPTION_COLOR_BG = {
  trial: '#930303',
  premium: '#209782',
  essential: '#FAD02C',
};

type PropsConversationCard = {
  srcAvatarImage: string;
  avatarName: string;
  avatarChat?: string;
  chatTime?: string;
  chatType?: string;
  numberUnreadMessages?: number;
  isRead?: boolean;
  isOpen: boolean;
  onContainerClick(): void;
  cardSelected: boolean;
  subscriptionPlan: string;
};

const ConversationCard: React.FC<PropsConversationCard> = ({
  srcAvatarImage,
  avatarName,
  avatarChat,
  chatTime,
  chatType,
  numberUnreadMessages,
  isRead,
  isOpen,
  onContainerClick,
  cardSelected,
  subscriptionPlan,
}) => {
  return (
    <Container
      onClick={() => onContainerClick()}
      selected={cardSelected}
      isRead={isRead}
      numberUnreadMessages={numberUnreadMessages}
    >
      <NumberChatUnread
        className="mc-number-chat-unread"
        isRead={isRead}
        style={{
          display: !isRead && numberUnreadMessages > 0 ? 'flex' : 'none',
        }}
      >
        {numberUnreadMessages}
      </NumberChatUnread>

      <Content>
        <LeftContent>
          <Avatar
            src={srcAvatarImage}
            size={isOpen ? 'lg' : 'sm'}
            isOpen={isOpen}
          />
          <CustomerContent isOpen={isOpen}>
            <AvatarDetails>
              <AvatarAndSubscriptionSection>
                <AvatarName selected={cardSelected} isRead={isRead}>
                  {avatarName}
                </AvatarName>

                <SubscriptionStatus
                  id={`subscription`}
                  title={capitalize(subscriptionPlan ?? '')}
                  background={SUBSCRIPTION_COLOR_BG[subscriptionPlan]}
                >
                  {subscriptionPlan}
                </SubscriptionStatus>
              </AvatarAndSubscriptionSection>

              <AvatarChat
                selected={cardSelected}
                isRead={isRead}
                dangerouslySetInnerHTML={createMarkup(avatarChat)}
              />
            </AvatarDetails>
          </CustomerContent>
        </LeftContent>
        <RightSide isOpen={isOpen}>
          <Icon name={checkChatType(chatType)} />
          <ChatTime selected={cardSelected} isRead={isRead}>
            {conversationTime(chatTime)}
          </ChatTime>
        </RightSide>
      </Content>
    </Container>
  );
};

export default observer(ConversationCard);
