import styled from 'styled-components';

type Props = {
  editMode?: boolean;
  openContent?: boolean;
};

export const Container = styled.div`
  padding: 12px;
`;

export const TabsHeader = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 12px;
  margin-bottom: 28px;
`;

export const TabsHeaderContent = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 12px;
`;

export const Tabs = styled.div``;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;

  background: #fff;
  width: 103px;
  height: 93px;
  border-radius: 6px;

  &:active {
    border: 1px solid #333;
  }
`;

export const TabDetails = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 12px;
    font-weight: 600;
  }

  .value {
    font-size: 12px;
  }
`;

export const NewItemButton = styled.button`
  height: 32px;
  width: 32px;
  background-color: #57e4cd;
  border: none;
  border-radius: 6px;
`;

export const IncomeContainer = styled.div`
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 6px;
  padding: 28px;
`;

export const IncomeHeader = styled.div`
  display: flex;
`;

export const IncomeInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const IncomeHeaderFields = styled.div`
  margin-left: 48px;
`;

export const HeaderSelectInput = styled.select<Props>`
  border: ${(props) => (props.editMode ? '1px solid #222' : 'none')};
  border-radius: 6px;
  max-width: 150px;
  padding: 3px 0;

  &:disabled {
    background-color: #fff;
  }
`;

export const IncomeHeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TransactionsSection = styled.div`
  margin-top: 50px;

  > h3 {
    font-size: 22px;
    font-weight: 600;
  }
`;

export const ViewImageSection = styled.div``;

export const ContentContainer = styled.div<Props>`
  display: ${(props) => (props.openContent ? 'block' : 'none')};
`;

export const OpenContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OpenContentButton = styled.button`
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 6px;
  background-color: #57e4cd;
`;

export const PrevNextSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //max-width: 1015px;
  margin: 60px 0;
`;

export const PrevNextFields = styled.div`
  display: flex;
  // column-gap: 20px;
  margin-bottom: 30px;
  padding-right: 20px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #57e4cd;
  height: 38px;
  border: none;
  border-radius: 48px;
  padding: 0 24px;

  > span {
    font-weight: 600;
  }
`;

export const PrevNextInputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 20px;

  > label {
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    width: 100%;
    max-width: 150px;
  }
`;

export const Input = styled.input`
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 16px;
`;

export const Select = styled.select`
  height: 56px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 16px;
`;

export const OtherInformations = styled.div``;

export const OtherInformationsInputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > label {
    font-weight: 600;
    font-size: 16px;
  }

  > input {
    max-width: 150px;
  }
`;

export const SubmitButton = styled.button`
  border: none;
  border-radius: 48px;
  background-color: #57e4cd;
  height: 38px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 600;
  :disabled {
    background-color: #f7f7f7;
  }
`;

export const TotalsContainer = styled.div`
  display: flex;
  position: relative;
`;
